import { Page } from '../model/Elements';
import { ChildRegistered, Family, FamilyMember, ParentEvent } from '../model/Entities';
import dateService from '../services/DateService';
import apiAxios from './CustomAxios';

class FamilyApi {
    get = async (familyId: number): Promise<Family> => {
        const response = await apiAxios.get<Family>(`/v1/families/${familyId}`);
        return response.data;
    };

    addParent = async (familyId: number, parent: FamilyMember): Promise<FamilyMember> => {
        const response = await apiAxios.post<FamilyMember>(`/v1/families/${familyId}/parents`, parent);
        return response.data;
    };

    addPlayer = async (familyId: number, player: FamilyMember): Promise<FamilyMember> => {
        const response = await apiAxios.post<FamilyMember>(`/v1/families/${familyId}/children`, player);
        return response.data;
    };

    updatePlayer = async (familyId: number, userId: string, player: ChildRegistered): Promise<void> => {
        player.dateOfBirth = player.dateOfBirth ? Math.round(player.dateOfBirth / 1000) : undefined;
        const response = await apiAxios.put<void>(`/v1/families/${familyId}/children/${userId}`, player);
        return response.data;
    };

    addExistingParent = async (familyId: number, parent: FamilyMember): Promise<FamilyMember> => {
        const response = await apiAxios.put<FamilyMember>(`/v1/families/${familyId}/parents`, {
            userId: parent.userId,
        });
        return response.data;
    };

    addExistingPlayer = async (familyId: number, player: FamilyMember): Promise<FamilyMember> => {
        const response = await apiAxios.put<FamilyMember>(`/v1/families/${familyId}/children`, {
            userId: player.userId,
        });
        return response.data;
    };

    addOwner = async (familyId: number, owner: FamilyMember): Promise<FamilyMember> => {
        const response = await apiAxios.put<FamilyMember>(`/v1/families/${familyId}/owner`, {
            newOwner: owner.userId,
        });
        return response.data;
    };

    deleteParent = async (familyId: number, parent: FamilyMember): Promise<void> => {
        await apiAxios.delete(`/v1/families/${familyId}/parents/${parent.userId}`);
    };

    deletePlayer = async (familyId: number, player: FamilyMember): Promise<void> => {
        await apiAxios.delete(`/v1/families/${familyId}/children/${player.userId}`);
    };

    listEvents = async (familyId: number): Promise<ParentEvent[]> => {
        const response = await apiAxios.get<Page<ParentEvent>>(`/v1/families/${familyId}/events`);
        response.data.items.forEach((i) =>
            i.event?.startDate ? (i.event.startDate = dateService.toLocal(i.event?.startDate * 1000)) : undefined,
        );
        return response.data.items;
    };
}
const familyApi: FamilyApi = new FamilyApi();
export default familyApi;
