import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Card, Col, Dropdown, FormInstance, List, Menu, message, Modal, Row } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import familyApi from '../../apis/FamilyApi';
import CustomContext from '../../context/CustomContext';
import { Family, FamilyMember, ParentEvent, Sport, UserAccount } from '../../model/Entities';
import logo from '../../resources/images/top100-logo-p.png';
import notificationService from '../../services/NotificationService';
import stringService from '../../services/StringService';
import styles from './UserAccountComponent.module.scss';
import avatar from '../../resources/images/profile-placeholder-bp.png';

class UserAccountComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();
    readonly pageSize = 10;

    constructor(props: Props) {
        super(props);
        this.state = { parentEvents: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/
    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            const parentEvents = await familyApi.listEvents(this.props.family?.id!);

            this.setState({ parentEvents });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteParent = async (parent: FamilyMember) => {
        const { family } = this.props;

        try {
            this.setState({ loading: 'loading' });

            await familyApi.deleteParent(family?.id!, parent);
            message.success(this.props.intl.formatMessage({ id: 'status.action' }));

            this.props.onUpdate();

            this.showDeleteModalConfirmation(false);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    convertToOwner = async (parent: FamilyMember) => {
        const { family } = this.props;

        try {
            this.setState({ loading: 'loading' });

            await familyApi.addOwner(family?.id!, parent);
            this.props.onUpdate();
            const auth = Object.assign({}, this.context.auth);
            auth.authorities = auth.authorities.filter((e) => e !== 'ROLE_PARENT_OWNER');
            this.context.updateAuth(auth);
            message.success(this.props.intl.formatMessage({ id: 'status.action' }));

            this.showOwnerModalConfirmation(false);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showDeleteModalConfirmation = (modalDeleteConfirmationVisible: boolean, parent?: FamilyMember) => {
        this.setState({ modalDeleteConfirmationVisible, parent });
    };

    showOwnerModalConfirmation = (modalOwnerConfirmationVisible: boolean, parent?: FamilyMember) => {
        this.setState({ modalOwnerConfirmationVisible, parent });
    };

    /*** COMPONENTS ***/

    renderChildrenRank = (familyMember: FamilyMember): React.ReactElement | undefined => {
        if (familyMember.nationalRank !== undefined) {
            return (
                <>
                    #{familyMember.nationalRank} <FormattedMessage id="parent.profile.nationalRank" />
                </>
            );
        } else {
            return <span>-</span>;
        }
    };

    renderChildren = (): React.ReactElement | undefined => {
        const { sports, family } = this.props;
        const players = family && family.members ? family.members.filter((m) => m.memberType === 'Child') : [];

        return (
            <div className={styles.children}>
                <h2>
                    <FormattedMessage id="parent.profile.children" />
                </h2>
                <List
                    dataSource={players}
                    className={styles.childrenGrid}
                    renderItem={(player) => (
                        <List.Item key={player.userId} className={styles.child}>
                            <Card
                                cover={
                                    <div className={styles.imageHigh}>
                                        <Link
                                            to={`/players/${player.userId}?sportId=${player.sportId}&familyId=${family?.id}`}
                                        >
                                            <img
                                                src={player.photoUrl || avatar}
                                                alt={avatar}
                                                className={styles.image}
                                            />
                                        </Link>
                                    </div>
                                }
                            >
                                <Card.Meta
                                    title={
                                        <Link
                                            to={`/players/${player.userId}?sportId=${player.sportId}&familyId=${family?.id}`}
                                        >
                                            <div>
                                                {player.givenName} {player.familyName}
                                            </div>
                                        </Link>
                                    }
                                    description={
                                        <div className={styles.description}>
                                            <div className={styles.misc}>{this.renderChildrenRank(player)}</div>

                                            <ul className={styles.stats}>
                                                <li>
                                                    {player.sportId && (
                                                        <img
                                                            src={sports.find((s) => s.id === player.sportId)?.iconUrl}
                                                            alt={logo}
                                                            className={styles.sportLogo}
                                                        ></img>
                                                    )}
                                                    {sports.find((s) => s.id === player.sportId)?.name}
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                />
                            </Card>
                        </List.Item>
                    )}
                />
            </div>
        );
    };

    renderContact = (): React.ReactElement | undefined => {
        const { user } = this.props;

        return (
            <div className={styles.contact}>
                <Row className={styles.header}>
                    <Col span={24}>
                        <h2>
                            <FormattedMessage id="parent.profile.contact" />
                        </h2>
                    </Col>
                </Row>
                <Row className={styles.field}>
                    <Col span={24}>
                        <h3>
                            <FormattedMessage id="parent.profile.email" />
                        </h3>
                    </Col>
                    <Col span={24}>
                        <p>{user?.email}</p>
                    </Col>
                </Row>
                <Row className={styles.field}>
                    <Col span={24}>
                        <h3>
                            <FormattedMessage id="parent.profile.phone" />
                        </h3>
                    </Col>
                    <Col span={24}>
                        <p>{user?.phone}</p>
                    </Col>
                </Row>
            </div>
        );
    };

    renderDeleteConfirmationModal = (): React.ReactElement | undefined => {
        const { modalDeleteConfirmationVisible, loading, parent } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="parent.profile.modalDelete.title" />}
                visible={modalDeleteConfirmationVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={() => this.deleteParent(parent!)}
                okButtonProps={{ loading: loading === 'loading' }}
                onCancel={() => this.showDeleteModalConfirmation(false)}
                className={styles.modal}
            >
                <FormattedMessage id="parent.profile.modalDelete.description" />
            </Modal>
        );
    };

    renderOwnerConfirmationModal = (): React.ReactElement | undefined => {
        const { modalOwnerConfirmationVisible, loading, parent } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="parent.profile.modalOwner.title" />}
                visible={modalOwnerConfirmationVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={() => this.convertToOwner(parent!)}
                okButtonProps={{ loading: loading === 'loading' }}
                onCancel={() => this.showOwnerModalConfirmation(false)}
                className={styles.modal}
            >
                <FormattedMessage id="parent.profile.modalOwner.description" />
            </Modal>
        );
    };

    renderActionsColumn = (): ColumnsType<FamilyMember> => {
        if (
            this.context.auth?.authorities.includes('ROLE_ADMIN') ||
            this.context.auth?.authorities.includes('ROLE_PARENT_OWNER')
        ) {
            const actionsColumn: ColumnsType<FamilyMember> = [
                {
                    title: <FormattedMessage id="parent.actions" />,
                    width: 90,
                    render: (value: string, parent: FamilyMember) =>
                        parent.memberType !== 'Owner' && (
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item
                                            key="delete"
                                            onClick={() => this.showDeleteModalConfirmation(true, parent)}
                                        >
                                            <FormattedMessage id="parent.profile.delete" />
                                        </Menu.Item>
                                        <Menu.Item
                                            key="owner"
                                            onClick={() => this.showOwnerModalConfirmation(true, parent)}
                                        >
                                            <FormattedMessage id="parent.profile.owner" />
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button type="text" className={styles.actions}>
                                    <EllipsisOutlined />
                                </Button>
                            </Dropdown>
                        ),
                },
            ];
            return actionsColumn;
        } else {
            return [];
        }
    };

    renderParentsTable = (): React.ReactElement | undefined => {
        const { family } = this.props;
        const parents = family?.members ? family?.members.filter((m) => m.memberType !== 'Child') : [];

        const columns: ColumnsType<FamilyMember> = [
            {
                title: <FormattedMessage id="parent.name" />,
                dataIndex: 'givenName',
                key: 'givenName',
                width: 160,
                render: (value: string, parent: FamilyMember) =>
                    this.context.auth?.id !== parent.userId ? (
                        <Link to={`/parents/${parent.userId}`} className={styles.name}>
                            {stringService.getName(true, value, parent.familyName)}
                        </Link>
                    ) : (
                        <span className={styles.name}>{stringService.getName(true, value, parent.familyName)}</span>
                    ),
            },
            {
                title: <FormattedMessage id="parent.username" />,
                dataIndex: 'userName',
                key: 'userName',
                width: 160,
                render: (value: string) => value,
            },
            {
                title: <FormattedMessage id="parent.email" />,
                dataIndex: 'email',
                key: 'email',
                render: (value: string, parent: FamilyMember) => value,
            },
            {
                title: <FormattedMessage id="parent.role" />,
                dataIndex: 'memberType',
                key: 'memberType',
                width: 130,
                render: (value: string, parent: FamilyMember) => value,
            },

            ...this.renderActionsColumn(),
        ];

        return (
            <div className={styles.parents}>
                <h3>
                    <FormattedMessage id="parent.profile.parents" />
                </h3>
                <Table
                    dataSource={parents}
                    columns={columns}
                    pagination={false}
                    rowKey="userId"
                    className={styles.table}
                />
            </div>
        );
    };

    renderEventsTable = (): React.ReactElement | undefined => {
        const { parentEvents } = this.state;

        const columns: ColumnsType<ParentEvent> = [
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventName" />,
                dataIndex: ['event', 'name'],
                key: 'name',
                width: 350,
                render: (value: string, parentEvent: ParentEvent) => (
                    <Link className={styles.name} to={`/events/${parentEvent.event?.id}/summary`}>
                        {value}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventDate" />,
                dataIndex: ['event', 'startDate'],
                key: 'startDate',
                width: 200,
                render: (value: number, parentEvent: ParentEvent) => [
                    <FormattedDate value={value} weekday="short" />,
                    ', ',
                    <FormattedDate value={value} month="short" />,
                    ' ',
                    <FormattedDate value={value} day="2-digit" />,
                    ', ',
                    <FormattedDate value={value} hour="2-digit" minute="2-digit" />,
                ],
            },
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventPlayersRegistered" />,
                dataIndex: ['event', 'players'],
                key: 'players',
                render: (value: number, parentEvent: ParentEvent) =>
                    parentEvent.players?.map((p) => {
                        return (
                            <div key={p.userId}>{['· ', stringService.getName(true, p.givenName, p.familyName)]}</div>
                        );
                    }),
            },
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventStatus" />,
                dataIndex: ['event', 'status'],
                key: 'status',
                width: 130,
                render: (value: string, parentEvent: ParentEvent) =>
                    value === 'Live' || value === 'Upcoming' ? (
                        <span className={styles.live}>{value}</span>
                    ) : (
                        <span>{value}</span>
                    ),
            },
        ];

        return (
            <div className={styles.events}>
                <h3>
                    <FormattedMessage id="parent.profile.registeredEvents" />
                </h3>
                <Table
                    dataSource={parentEvents}
                    columns={columns}
                    pagination={false}
                    rowKey="id"
                    className={styles.table}
                />
            </div>
        );
    };

    render() {
        return (
            <>
                <div className={styles.flex}>
                    {this.renderChildren()}
                    {this.renderContact()}
                </div>

                {this.renderParentsTable()}
                {this.renderEventsTable()}
                {this.renderDeleteConfirmationModal()}
                {this.renderOwnerConfirmationModal()}
            </>
        );
    }
}
export default injectIntl(UserAccountComponent);

interface Props extends WrappedComponentProps {
    user: UserAccount;
    family?: Family;
    sports: Sport[];
    onUpdate: () => Promise<void>;
}

interface State {
    loading?: 'loading';
    modalDeleteConfirmationVisible?: boolean;
    modalOwnerConfirmationVisible?: boolean;
    parent?: FamilyMember;
    parentEvents: ParentEvent[];
}
