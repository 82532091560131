import Icon from '@ant-design/icons';
import EditOutlined from '@ant-design/icons/lib/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { Button, Space } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import CustomContext from '../../../../context/CustomContext';
import { Division, OrganizationGroupCoach, Team, TeamMemberFromTeam } from '../../../../model/Entities';
import { DisplayType, GenderType, SortingPlayerType } from '../../../../model/Types';
import { ReactComponent as editSvg } from '../../../../resources/images/ico-edit.svg';
import notificationService from '../../../../services/NotificationService';
import rolesService from '../../../../services/RolesService';
import styles from '../../OrganizationsPage.module.scss';
import AddExistingPlayerModal from '../AddExistingPlayerModal/AddExistingPlayerModal';
import AddTeamCoachesModal from '../AddTeamCoachesModal/AddTeamCoachesModal';
import AddTeamModal from '../AddTeamModal/AddTeamModal';
import stylesTeam from './DivisionComponent.module.scss';
import TeamComponent from './TeamComponent/TeamComponent';

class DivisionComponent extends React.Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    refresh = async () => {
        const teamEditable = undefined;
        const teamCoachesEditable = undefined;
        this.showAddExistingPlayerModal(false);
        await this.props.onUpdate();
        this.setState({ teamEditable, teamCoachesEditable });
    };

    changeTeam = (teamId?: string) => {
        this.setState({ teamId });
    };

    changeTeamEditable = (teamEditable?: Team) => {
        this.setState({ teamEditable });
    };

    changeTeamCoachesEditable = (teamCoachesEditable?: Team) => {
        this.setState({ teamCoachesEditable });
    };

    showAddExistingPlayerModal = (addExistingPlayerModalVisible: boolean, teamIdToAddPlayer?: string) => {
        this.setState({ addExistingPlayerModalVisible, teamIdToAddPlayer });
    };

    /*** COMPONENTS ***/

    renderTeamsHeader = (): React.ReactElement | undefined => {
        const { division, sportId } = this.props;

        return (
            <div className={styles.teams}>
                <Space className={styles.navi}>
                    <h3 className={styles.teamName}>{division.name}</h3>
                    <div className={styles.teamsButtons}>{this.renderTeamsButtons()}</div>
                    <Link
                        to={`/rosters/${this.props.organizationId}/management?sportId=${sportId}&divisionId=${division.id}`}
                    >
                        <Button
                            className={styles.manage}
                            icon={<Icon component={editSvg} />}
                            hidden={this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')}
                        >
                            <FormattedMessage id="roster.team.manageRoster" />
                        </Button>
                    </Link>
                </Space>
            </div>
        );
    };

    renderTeamsButtons = (): React.ReactElement | undefined => {
        const { teams } = this.props;
        const { teamId } = this.state;

        const team = teams.find((t) => t.id === teamId);
        const sortedTeams = teams.sort((x, y) => {
            return x.primary === y.primary ? 0 : x.primary ? 1 : -1;
        });
        return (
            <>
                <Button
                    size="large"
                    shape="round"
                    onClick={() => this.changeTeam()}
                    className={team ? stylesTeam.unselected : stylesTeam.selected}
                >
                    <FormattedMessage id="button.all" />
                </Button>
                {sortedTeams.map((t) => (
                    <Button
                        key={t.id}
                        size="large"
                        shape="round"
                        onClick={() => this.changeTeam(t.id)}
                        className={t.id === team?.id ? stylesTeam.selected : stylesTeam.unselected}
                    >
                        {t.name}
                    </Button>
                ))}
            </>
        );
    };

    renderModals = (): React.ReactElement | undefined => {
        const { divisions, organizationId, sportId } = this.props;
        const { teamEditable, teamCoachesEditable } = this.state;

        if (teamEditable) {
            return (
                <AddTeamModal
                    onCancel={() => this.changeTeamEditable()}
                    divisions={divisions}
                    organizationId={organizationId}
                    sportId={sportId}
                    onUpdate={() => this.refresh()}
                    team={teamEditable}
                />
            );
        } else if (teamCoachesEditable) {
            return (
                <AddTeamCoachesModal
                    onCancel={() => this.changeTeamCoachesEditable()}
                    divisions={divisions}
                    organizationId={organizationId}
                    sportId={sportId}
                    onUpdate={() => this.refresh()}
                    team={teamCoachesEditable}
                />
            );
        }
    };

    renderTeamHeader = (team: Team): React.ReactElement | undefined => {
        const { auth } = this.context;

        const isTeamCoachesEditable =
            rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_ORGANIZATION_OWNER', 'ROLE_ORGANIZATION_STAFF']) ||
            rolesService.hasAllRoles(auth, ['ROLE_ORGANIZATION_COACH']);

        return (
            <h1 key={team.id} className={stylesTeam.teamName}>
                {team.name}{' '}
                {team.name !== 'Free Agents' && (
                    <>
                        <Button
                            shape="round"
                            icon={<EditOutlined />}
                            onClick={() => this.changeTeamEditable(team)}
                            hidden={
                                !rolesService.hasAnyRole(auth, [
                                    'ROLE_ADMIN',
                                    'ROLE_ORGANIZATION_OWNER',
                                    'ROLE_ORGANIZATION_STAFF',
                                ])
                            }
                        ></Button>
                        <Button
                            shape="round"
                            icon={<PlusOutlined />}
                            onClick={() => this.changeTeamCoachesEditable(team)}
                            hidden={!isTeamCoachesEditable}
                        >
                            <FormattedMessage id="roster.addTeamCoaches.add" tagName="span" />
                        </Button>
                        <Button
                            shape="round"
                            icon={<PlusOutlined />}
                            hidden={!rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])}
                            onClick={() => this.showAddExistingPlayerModal(true, team.id)}
                        >
                            <FormattedMessage id="roster.roster.addPlayer" tagName="span" />
                        </Button>
                    </>
                )}
            </h1>
        );
    };

    renderTeams = (): React.ReactElement | undefined => {
        const {
            display,
            organizationId,
            division,
            positionIds,
            genders,
            grades,
            sortField,
            searchText,
            teams,
            desktop,
            coaches,
        } = this.props;
        const { teamId } = this.state;

        const team = teams.find((t) => t.id === teamId);

        if (team) {
            return (
                <>
                    {this.renderTeamHeader(team)}
                    <TeamComponent
                        id={division.id!}
                        key={division.id}
                        organizationId={organizationId}
                        team={team}
                        teams={teams}
                        coaches={coaches}
                        positionIds={positionIds}
                        genders={genders}
                        grades={grades}
                        searchText={searchText}
                        sortField={sortField}
                        display={display}
                        onUpdate={this.refresh}
                        desktop={desktop}
                    />
                </>
            );
        } else {
            return (
                <>
                    {teams
                        .filter((t) => t.teamMembers?.length! > 0)
                        .map((t) => (
                            <>
                                {this.renderTeamHeader(t)}
                                <TeamComponent
                                    id={division.id!}
                                    key={division.id}
                                    organizationId={organizationId}
                                    team={t}
                                    teams={teams}
                                    coaches={coaches}
                                    positionIds={positionIds}
                                    genders={genders}
                                    grades={grades}
                                    searchText={searchText}
                                    sortField={sortField}
                                    display={display}
                                    onUpdate={this.refresh}
                                    desktop={desktop}
                                />
                            </>
                        ))}
                </>
            );
        }
    };

    renderContent = (): React.ReactElement | undefined => {
        const { addExistingPlayerModalVisible, teamIdToAddPlayer } = this.state;
        return (
            <>
                {this.renderModals()}
                {this.renderTeamsHeader()}
                {this.renderTeams()}
                {addExistingPlayerModalVisible && (
                    <AddExistingPlayerModal
                        organizationId={this.props.organizationId}
                        divisionId={this.props.id}
                        teamId={teamIdToAddPlayer!}
                        sportId={this.props.sportId}
                        onCancel={() => this.showAddExistingPlayerModal(false)}
                        onUpdate={this.refresh}
                    />
                )}
            </>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(DivisionComponent);

interface Props extends WrappedComponentProps {
    id: number;
    organizationId: string;
    division: Division;
    divisions: Division[];
    display: DisplayType;
    positionIds: number[];
    genders: GenderType[];
    grades: number[];
    teams: Team[];
    coaches: OrganizationGroupCoach[];
    sportId: number;
    searchText?: string;
    sortField?: SortingPlayerType;
    onUpdate: () => Promise<void>;
    desktop: boolean;
}

interface State {
    teamMember?: TeamMemberFromTeam;
    teamId?: string;
    teamEditable?: Team;
    teamCoachesEditable?: Team;
    loading?: boolean;
    addExistingPlayerModalVisible?: boolean;
    teamIdToAddPlayer?: string;
}
