import { Select, Table, PageHeader, Dropdown, Menu, Button, Modal, message, Empty } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import groupApi from '../../apis/GroupApi';
import organizationApi from '../../apis/OrganizationApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Page } from '../../model/Elements';
import { Group, Organization } from '../../model/Entities';
import { roles, RoleType, userStatuses, UserStatusType } from '../../model/Types';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import stringService from '../../services/StringService';
import tableService from '../../services/TableService';
import styles from './GroupsPage.module.scss';
import { EllipsisOutlined } from '@ant-design/icons';
import userApi from '../../apis/UserApi';

class GroupsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;

    constructor(props: Props) {
        super(props);
        this.state = { organizations: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const responses = await Promise.all([groupApi.list(this.pageSize, 1), organizationApi.listAll()]);
            const groupsPage = responses[0];
            const organizations = responses[1].sort((a, b) => stringService.sort(a.name, b.name));

            this.setState({ groupsPage, organizations });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    list = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Group> | SorterResult<Group>[],
    ) => {
        const { searchText, userStatus, role, organizationId } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            let sortField, sortOrder;
            if (!Array.isArray(sorter)) {
                sortField = sorter.field as string;
                sortOrder = sorter.order as string;
            }
            const groupsPage = await groupApi.list(
                pageSize,
                page,
                sortField,
                sortOrder,
                searchText,
                userStatus,
                role,
                organizationId,
            );

            this.setState({ groupsPage, sortField, sortOrder });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    search = async (searchText: string) => {
        const { sortField, sortOrder, userStatus, role, organizationId } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const groupsPage = await groupApi.list(
                this.pageSize,
                1,
                sortField,
                sortOrder,
                searchText,
                userStatus,
                role,
                organizationId,
            );

            this.setState({ groupsPage, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByUserStatus = async (userStatus?: UserStatusType) => {
        const { sortField, sortOrder, searchText, role, organizationId } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const groupsPage = await groupApi.list(
                this.pageSize,
                1,
                sortField,
                sortOrder,
                searchText,
                userStatus,
                role,
                organizationId,
            );

            this.setState({ groupsPage, userStatus });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByRole = async (role?: RoleType) => {
        const { sortField, sortOrder, searchText, userStatus, organizationId } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const groupsPage = await groupApi.list(
                this.pageSize,
                1,
                sortField,
                sortOrder,
                searchText,
                userStatus,
                role,
                organizationId,
            );

            this.setState({ groupsPage, role });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByOrganization = async (organizationId?: string) => {
        const { sortField, sortOrder, searchText, userStatus, role } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const groupsPage = await groupApi.list(
                this.pageSize,
                1,
                sortField,
                sortOrder,
                searchText,
                userStatus,
                role,
                organizationId,
            );

            this.setState({ groupsPage, organizationId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    inviteUser = async () => {
        const { sortField, sortOrder, userStatus, role, organizationId, searchText, user } = this.state;
        try {
            this.setState({ loading: 'inviting' });

            const users: string[] = [];
            users.push(user?.id!);
            await userApi.sendInvites(users);

            this.showInviteModal(false);
            message.success(this.props.intl.formatMessage({ id: 'status.invitesSent' }));

            const groupsPage = await groupApi.list(
                this.pageSize,
                1,
                sortField,
                sortOrder,
                searchText,
                userStatus,
                role,
                organizationId,
            );

            this.setState({ groupsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    reInviteUser = async () => {
        const { sortField, sortOrder, userStatus, role, organizationId, searchText, user } = this.state;
        try {
            this.setState({ loading: 'inviting' });

            const users: string[] = [];
            users.push(user?.id!);
            await userApi.sendReInvites(users);

            this.showReInviteModal(false);
            message.success(this.props.intl.formatMessage({ id: 'status.invitesSent' }));

            const groupsPage = await groupApi.list(
                this.pageSize,
                1,
                sortField,
                sortOrder,
                searchText,
                userStatus,
                role,
                organizationId,
            );

            this.setState({ groupsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showInviteModal = (inviteModalVisible: boolean, user?: Group) => {
        this.setState({ inviteModalVisible, user });
    };

    showReInviteModal = (reInviteModalVisible: boolean, user?: Group) => {
        this.setState({ reInviteModalVisible, user });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return (
                <>
                    <div className={styles.header}>
                        <div className={styles.headerBackground}>
                            <div className={styles.headerBackgroundGradient}></div>
                        </div>
                        <PageHeader
                            title={
                                <h1>
                                    <FormattedMessage id="groups.title" />
                                </h1>
                            }
                        />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h1>
                        <FormattedMessage id="groups.title" />
                    </h1>
                </>
            );
        }
    };

    renderTableHeader = (): React.ReactElement | undefined => {
        const { organizations } = this.state;

        let userStatusOptions = userStatuses.map((userStatus) => (
            <Select.Option key={userStatus} value={userStatus}>
                <FormattedMessage id={`userStatuses.${userStatus}`} />
            </Select.Option>
        ));
        userStatuses &&
            (userStatusOptions = [
                <Select.Option key="" value="">
                    {<FormattedMessage id="groups.statuses" />}
                </Select.Option>,
                ...userStatusOptions!,
            ]);

        let roleOptions = roles.map((role) => (
            <Select.Option key={role} value={role}>
                <FormattedMessage id={`roles.${role}`} />
            </Select.Option>
        ));
        roles &&
            (roleOptions = [
                <Select.Option key="" value="">
                    {<FormattedMessage id="groups.roles" />}
                </Select.Option>,
                ...roleOptions!,
            ]);
        let organizationOptions = organizations.map((organization) => (
            <Select.Option key={organization.id} value={organization.id!}>
                {organization.name}
            </Select.Option>
        ));
        organizations &&
            (organizationOptions = [
                <Select.Option key="-" value="">
                    <FormattedMessage id="groups.affiliates" />
                </Select.Option>,
                ...organizationOptions,
            ]);

        return (
            <>
                <header className={styles.tableHeader}>
                    <Search
                        placeholder={this.props.intl.formatMessage({ id: 'button.search' })}
                        className={styles.search}
                        enterButton={''}
                        allowClear={true}
                        onSearch={this.search}
                    />
                    <div className={styles.flex}>
                        <span className={styles.filters}>
                            <FormattedMessage id="groups.filterBy" />
                        </span>
                        <div className={styles.flex}>
                            <Select
                                size="large"
                                className={styles.filter}
                                placeholder={<FormattedMessage id="groups.statuses" />}
                                onChange={this.filterByUserStatus}
                            >
                                {userStatusOptions}
                            </Select>
                            <Select
                                size="large"
                                className={styles.filter}
                                placeholder={<FormattedMessage id="groups.roles" />}
                                onChange={this.filterByRole}
                            >
                                {roleOptions}
                            </Select>
                            <Select
                                size="large"
                                className={styles.filter}
                                placeholder={<FormattedMessage id="groups.affiliates" />}
                                onChange={this.filterByOrganization}
                            >
                                {organizationOptions}
                            </Select>
                        </div>
                    </div>
                </header>
            </>
        );
    };

    renderNameLink = (group: Group, desktop: boolean): React.ReactElement | string | undefined => {
        if (group.profile?.roles?.includes('Player')) {
            return (
                <Link
                    to={`/players/${group.id}/profile?sportId=${group.profile.sports?.find(Boolean)}`}
                    className={styles.username}
                >
                    {stringService.getName(desktop, group.profile?.givenName, group.profile?.familyName)}
                </Link>
            );
        } else if (group.profile?.roles?.includes('Parent') || group.profile?.roles?.includes('OrganizationOwner')) {
            return (
                <Link to={`/parents/${group.id}`} className={styles.username}>
                    {stringService.getName(desktop, group.profile?.givenName, group.profile?.familyName)}
                </Link>
            );
        } else {
            return stringService.getName(desktop, group.profile?.givenName, group.profile?.familyName);
        }
    };

    renderInviteModal = (): React.ReactElement | undefined => {
        const { inviteModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="groups.inviteUsersModal.title" />}
                visible={inviteModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.inviteUser}
                okButtonProps={{ loading: loading === 'inviting' }}
                onCancel={() => this.showInviteModal(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="groups.inviteUsersModal.description" />
                </p>
            </Modal>
        );
    };

    renderReInviteModal = (): React.ReactElement | undefined => {
        const { reInviteModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="groups.reinviteUsersModal.title" />}
                visible={reInviteModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.reInviteUser}
                okButtonProps={{ loading: loading === 'inviting' }}
                onCancel={() => this.showReInviteModal(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="groups.reinviteUsersModal.description" />
                </p>
            </Modal>
        );
    };

    renderEmpty = (): React.ReactElement | undefined => {
        const { organizations, organizationId } = this.state;
        const organization = organizations.find((o) => o.id === organizationId);

        return (
            <>
                {organization && (
                    <>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        <Link to={`/groups/affiliates/${organization.id}`}>
                            <Button className={styles.btn} size="large" shape={'round'}>
                                <FormattedMessage id="groups.empty.navigate" /> {organization.name}
                            </Button>
                        </Link>
                    </>
                )}
                {!organization && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { groupsPage, loading } = this.state;

        const groups = groupsPage && groupsPage.items ? groupsPage.items : [];

        let columns: ColumnsType<Group> = [
            {
                title: <FormattedMessage id="groups.name" />,
                dataIndex: 'givenName',
                key: 'givenName',
                width: 160,
                className: styles.name,
                sorter: true,
                defaultSortOrder: 'ascend',
                render: (value: string, group: Group) => (
                    <>
                        <div className={styles.username}>{this.renderNameLink(group, desktop)}</div>
                        <div className={styles.email}>{group.profile?.email}</div>
                        <Link to={`/groups/families/${group.family?.id}`} className={styles.family}>
                            {group.family?.name}
                        </Link>
                    </>
                ),
            },
            {
                title: <FormattedMessage id="groups.affiliatesTable" />,
                dataIndex: 'organizations',
                key: 'organizations',
                width: 260,
                render: (value: string, group: Group) =>
                    group.organizations?.map((o) => {
                        return (
                            <Link key={o.id} to={`/groups/affiliates/${o.id}`}>
                                <div>{o.name}</div>
                            </Link>
                        );
                    }),
            },
            {
                title: <FormattedMessage id="groups.role" />,
                dataIndex: 'roles',
                key: 'roles',
                sorter: true,
                width: 130,
                render: (value: string, group: Group) =>
                    group.profile?.roles?.map((role) => intl.formatMessage({ id: `roles.${role}` })).join(', '),
            },
            {
                title: <FormattedMessage id="groups.lastLogin" />,
                sorter: true,
                dataIndex: 'lastLogin',
                key: 'lastLogin',
                align: 'center',
                width: 120,
                render: (value: number, group: Group) =>
                    group?.profile?.lastLogin && <FormattedDate value={group?.profile?.lastLogin} dateStyle="medium" />,
            },
            {
                title: <FormattedMessage id="groups.status" />,
                width: 120,
                sorter: true,
                key: 'userStatus',
                dataIndex: 'userStatus',
                align: 'center',
                render: (value: UserStatusType, group: Group) =>
                    group.profile?.userStatus && <FormattedMessage id={`userStatuses.${group.profile?.userStatus}`} />,
            },
            {
                title: <FormattedMessage id="affiliateGroup.actions" />,
                width: 90,
                render: (value: string, group: Group) => (
                    <Dropdown
                        overlay={
                            <Menu>
                                {group.profile?.userStatus === 'None' && (
                                    <Menu.Item key="invite" onClick={() => this.showInviteModal(true, group)}>
                                        <FormattedMessage id="affiliateGroup.invite" />
                                    </Menu.Item>
                                )}
                                {(group.profile?.userStatus === 'Active' ||
                                    group.profile?.userStatus === 'InviteSent' ||
                                    group.profile?.userStatus === 'InviteError') && (
                                    <Menu.Item key="reInvite" onClick={() => this.showReInviteModal(true, group)}>
                                        <FormattedMessage id="affiliateGroup.reInvite" />
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                    >
                        <Button type="text" className={styles.actions}>
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                ),
            },
        ];

        return (
            <>
                {this.renderTableHeader()}
                <Table
                    dataSource={groups}
                    columns={columns}
                    pagination={tableService.createPagination(groupsPage, { pageSize: this.pageSize })}
                    rowKey="id"
                    onChange={this.list}
                    loading={loading === 'initializing' || loading === 'loading'}
                    className={styles.table}
                    locale={{ emptyText: this.renderEmpty() }}
                />
                {this.renderInviteModal()}
                {this.renderReInviteModal()}
            </>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="groups.meta.title" />
                <LayoutComponent page="groups" content={this.renderContent} header={this.renderHeader} />
            </>
        );
    }
}
export default injectIntl(GroupsPage);

interface Props extends WrappedComponentProps {}

interface State {
    groupsPage?: Page<Group>;
    organizations: Organization[];
    loading?: 'initializing' | 'loading' | 'inviting';
    sortField?: string;
    sortOrder?: string;
    searchText?: string;
    userStatus?: UserStatusType;
    role?: RoleType;
    organizationId?: string;
    inviteModalVisible?: boolean;
    reInviteModalVisible?: boolean;
    user?: Group;
}
