import { EventNotification, Notification, OrganizationNotification } from '../model/Entities';

/**
 * This service provides common methods for the user notifications (emails, mobile apps, text messages).
 */
class CommunicationService {
    /**
     * Loads the notification recipients and content.
     * @param notification the notification
     * @param values the values
     */
    loadNotificationRecipientsAndContent = (notification: Notification, values: any): void => {
        // set destination type
        if (values.app && values.email) {
            notification.destinationType = 'All';
        } else if (values.app) {
            notification.destinationType = 'Push';
        } else if (values.email) {
            notification.destinationType = 'Email';
        }

        // set reply to
        notification.replyTo = values.replyTo;

        // set message
        notification.subject = values.subject;
        notification.body = values.body;

        // set schedule type
        if (values.scheduleSending) {
            notification.type = 'Postponed';
            notification.timeZone = values.timeZone;
            notification.sendTime = values.sendTime.valueOf();
        } else {
            notification.type = 'Instant';
        }
    };

    /**
     * Loads the notification roles.
     * @param notification the notification
     * @param values the values
     */
    loadNotificationRoles = (notification: OrganizationNotification | EventNotification, values: any) => {
        // set roles
        if (values.roles && values.roles.length > 0) {
            notification.roles.all = false;
            notification.roles.roleIds = values.roles;
        } else {
            notification.roles.all = true;
        }
    };
}

const communicationService = new CommunicationService();
export default communicationService;
