import { Image } from 'antd';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Event, Sport } from '../../../model/Entities';
import logo from '../../../resources/images/top100-logo.png';
import styles from './EventInfoComponent.module.scss';

class EventInfoComponent extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    /** METHODS **/

    /*** COMPONENTS ***/
    renderContent = (): React.ReactElement | undefined => {
        const { sports, event } = this.props;
        return (
            <>
                <div className={styles.header}>
                    <Image src={event.organizationLogo || logo} preview={false} fallback={logo} />
                </div>

                <div className={styles.body}>
                    <div className={styles.sport}>
                        {<img src={sports.find((s) => s.id === event.sportId)?.iconUrl} alt={logo}></img>}{' '}
                        {sports.find((s) => s.id === event.sportId)?.name}
                    </div>

                    <div className={styles.name}>
                        <h1>{event.name}</h1>
                    </div>

                    <div className={styles.data}>
                        <h2>
                            <FormattedMessage id="signup.dateTime" />
                        </h2>
                        <p>
                            <FormattedDate value={event.startDate} weekday="short" />,{' '}
                            <FormattedDate value={event.startDate} month="short" />{' '}
                            <FormattedDate value={event.startDate} day="2-digit" />,{' '}
                            <FormattedDate value={event.startDate} year="numeric" />,{' '}
                            <FormattedDate value={event.startDate} hour="2-digit" minute="2-digit" />
                        </p>
                    </div>

                    <div className={styles.data}>
                        <h2>
                            <FormattedMessage id="signup.location" />
                        </h2>
                        <p>
                            {event.address}, {event.state}, {event.city} {event.zipCode}
                        </p>
                    </div>

                    <div className={styles.data}>
                        <h2>
                            <FormattedMessage id="signup.fees" />
                        </h2>
                        <p>
                            {event.registrationTypes?.find((r) => r.type === 'Global')?.price || 0}{' '}
                            <FormattedMessage id="$" /> <FormattedMessage id="signup.evaluation" />
                        </p>
                    </div>
                </div>
            </>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(EventInfoComponent);

interface Props extends WrappedComponentProps {
    event: Event;
    sports: Sport[];
}
