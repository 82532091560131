import React, { useContext, useEffect, useState } from 'react';
import organizationProfileApi from '../../apis/OrganizationProfileApi';
import CustomContext from '../../context/CustomContext';
import { Sport } from '../../model/Entities';
import rolesService from '../../services/RolesService';
import styles from './UserBackgroundComponent.module.scss';
import baseballImage from '../../resources/images/ico-parentProfile.svg';
import familyApi from '../../apis/FamilyApi';

/**
 * This component display a different background for the user depending on his role and sport.
 *
 */
const UserBackgroundComponent: React.FC<Props> = (props) => {
    const { sports, sportId } = props;
    const context = useContext(CustomContext);
    const { auth } = context;

    /**^* METHODS */

    const getBackground = (sport: Sport | undefined): string | undefined => {
        let backgroundUrl: string | undefined;
        if (sport && sport.code === 'BASEBALL') {
            backgroundUrl = baseballImage;
        } else if (sport) {
            backgroundUrl = sport.backgroundUrl;
        }

        return backgroundUrl;
    };

    /*** HOOKS */

    const [sport, setSport] = useState<Sport>();
    useEffect(() => {
        const init = async () => {
            try {
                if (auth && sports && sports.length > 0) {
                    if (sportId) {
                        const sport = sports.find((s) => s.id === sportId);
                        setSport(sport);
                    } else if (rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_COACH'])) {
                        const sportId = auth.coaches?.map((s) => s.sportId).find(Boolean);
                        const sport = sports.find((s) => s.id === sportId);
                        setSport(sport);
                    } else if (
                        auth.organizationId &&
                        rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_OWNER', 'ROLE_ORGANIZATION_STAFF'])
                    ) {
                        const organizationProfile = await organizationProfileApi.get(auth?.organizationId);
                        const sportId = organizationProfile.sports?.map((s) => s.sportId).find(Boolean);
                        const sport = sports.find((s) => s.id === sportId);
                        setSport(sport);
                    } else if (rolesService.hasAnyRole(auth, ['ROLE_COLLEGE_COACH'])) {
                        const sportId = auth.colleges?.find(Boolean)?.sportId;
                        const sport = sports.find((s) => s.id === sportId);
                        setSport(sport);
                    } else if (rolesService.hasAnyRole(auth, ['ROLE_PARENT'])) {
                        const family = await familyApi.get(auth.familyId!);
                        const players = family.members?.filter((m) => m.memberType === 'Child');
                        const playerSportIds = players?.map((p) => p.sportId);
                        const sportId = playerSportIds && playerSportIds.length > 0 ? playerSportIds.find(Boolean) : 2;
                        const sport = sports.find((s) => s.id === sportId);
                        setSport(sport);
                    }
                }
            } catch (error) {
                // on error display no image background
            }
        };

        init();
    }, [auth, sports, sportId]);

    /*** COMPONENTS ***/

    return (
        <div className={styles.headerBackground}>
            <div className={styles.headerBackgroundGradient}></div>
            <div
                className={styles.headerBackgroundImage}
                style={{
                    backgroundImage: `url(${getBackground(sport)})`,
                }}
            ></div>
        </div>
    );
};
export default UserBackgroundComponent;

interface Props {
    sports: Sport[];
    sportId?: number;
}
