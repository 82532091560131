import { Button, Col, Form, FormInstance, Input, message, Modal, Row, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import collegeCoachApi from '../../../apis/CollegeCoachApi';
import CustomContext from '../../../context/CustomContext';
import { CollegeCoach, Sport } from '../../../model/Entities';
import { countries, CountryType } from '../../../model/Types';
import notificationService from '../../../services/NotificationService';
import stateService from '../../../services/StateService';
import styles from './CollegeCoachModal.module.scss';

class CollegeCoachModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { coachId, sportId, collegeId } = this.props;
        try {
            if (coachId) {
                this.get(collegeId, coachId);
            } else {
                await this.new(sportId);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    new = async (sportId?: number) => {
        const collegeCoach: CollegeCoach = {
            sportId,
        };
        this.setState({ collegeCoach });
        this.formRef.current!.setFieldsValue(collegeCoach);
    };

    get = async (collegeId: number, coachId: string) => {
        const collegeCoach = await collegeCoachApi.get(collegeId, coachId);
        this.setState({ collegeCoach, country: collegeCoach.country });
        this.formRef.current!.setFieldsValue(collegeCoach);
    };

    create = async () => {
        const { collegeId } = this.props;
        const { collegeCoach } = this.state;

        try {
            this.setState({ loading: 'loading' });
            const values = await this.formRef.current?.validateFields();
            const updateCollegeCoach: CollegeCoach = Object.assign({}, collegeCoach, values);
            await collegeCoachApi.create(collegeId, updateCollegeCoach);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: 'status.duplicatedCollegeCoach' },
                ]);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    update = async () => {
        const { collegeId } = this.props;
        const { collegeCoach } = this.state;

        try {
            this.setState({ loading: 'loading' });
            const values = await this.formRef.current?.validateFields();
            const updateCollegeCoach: CollegeCoach = Object.assign({}, collegeCoach, values);
            await collegeCoachApi.update(collegeId, updateCollegeCoach);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: 'status.duplicatedCollegeCoach' },
                ]);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /**
     * Reset the state when the country is changed in the form
     * @param country
     */
    changeCountry = (country?: CountryType) => {
        this.formRef.current?.resetFields(['state']);
        this.setState({ country });
    };

    /*** COMPONENTS ***/
    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderCoachInfo = (): React.ReactElement | undefined => {
        const { sports, sportId } = this.props;
        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));
        const sportOptions = sports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));

        return (
            <div className={styles.userInfo}>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.firstName" />}
                        name="givenName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.lastName" />}
                        name="familyName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="collegeCoachProfile.addCollegeCoachModal.sport" />}
                        name="sportId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select disabled={!!sportId}>{sportOptions}</Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.email" />}
                        name="email"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />,
                            },
                        ]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.phone" />}
                        name="phone"
                        rules={[
                            {
                                pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                message: <FormattedMessage id="status.phoneNumber" />,
                            },
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={16}>
                            <Form.Item label={<FormattedMessage id="signupChild.streetAddress" />} name="address">
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="signupChild.city" />} name="city">
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="signupChild.country" />}
                                name="country"
                                className={styles.select}
                            >
                                <Select onChange={(value: CountryType) => this.changeCountry(value)} allowClear>
                                    {countryOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="signupChild.state" />}
                                name="state"
                                className={styles.select}
                            >
                                <Select allowClear>{this.renderStates()}</Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="signupChild.zip" />} name="zipCode">
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    render() {
        const { coachId } = this.props;
        const { loading } = this.state;

        return (
            <Modal
                title={
                    coachId ? (
                        <FormattedMessage id="collegeCoachProfile.addCollegeCoachModal.title.edit" />
                    ) : (
                        <FormattedMessage id="collegeCoachProfile.addCollegeCoachModal.title.add" />
                    )
                }
                className={styles.modal}
                width={600}
                visible={true}
                onCancel={this.props.onCancel}
                footer={[
                    <Button className={styles.btn} key="cancel" shape={'round'} onClick={this.props.onCancel}>
                        <FormattedMessage id="button.cancel" />
                    </Button>,
                    <Button
                        key="save"
                        shape={'round'}
                        type="primary"
                        className={styles.btn}
                        onClick={coachId ? this.update : this.create}
                        loading={loading === 'loading'}
                    >
                        <FormattedMessage id="button.save" tagName="span" />
                    </Button>,
                ]}
            >
                {this.renderCoachInfo()}
            </Modal>
        );
    }
}
export default injectIntl(CollegeCoachModal);

interface Props extends WrappedComponentProps {
    collegeId: number;
    sportId?: number;
    coachId?: string;
    sports: Sport[];
    onUpdate: () => void;
    onCancel: () => void;
}

interface State {
    loading?: 'initializing' | 'loading';
    collegeCoach?: CollegeCoach;
    country?: CountryType;
}
