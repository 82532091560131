import Icon, { PlusOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, PageHeader, Select, Tabs } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import collegeDivisionApi from '../../apis/CollegeDivisionApi';
import sportApi from '../../apis/SportApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { CollegeDivision, NeedFeed, Sport } from '../../model/Entities';
import { ReactComponent as searchSvg } from '../../resources/images/ico-searchWhite.svg';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import numericService from '../../services/NumericService';
import rolesService from '../../services/RolesService';
import stateService from '../../services/StateService';
import styles from './CollegeConnectPage.module.scss';
import CollegesComponent from './CollegesComponent/CollegesComponent';
import MapComponent from './MapComponent/MapComponent';
import NeedFeedComponent from './NeedFeedComponent/NeedFeedComponent';
import PostNeedModal from './NeedFeedComponent/PostNeedModal/PostNeedModal';
const { Option, OptGroup } = Select;

class CollegeConnectPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    constructor(props: Props) {
        super(props);
        this.state = { collegeDivisions: [], sports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });

            const createNeeds = true;
            const displayMode: DisplayMode = rolesService.hasAllRoles(this.context.auth, ['ROLE_COLLEGE_COACH'])
                ? 'needFeed'
                : 'colleges';
            const responses = await Promise.all([collegeDivisionApi.list(), sportApi.listAll()]);
            const collegeDivisions = responses[0];
            const sports = responses[1];

            this.setState({ createNeeds, displayMode, collegeDivisions, sports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    changeDisplayMode = (key?: string) => {
        if (key === 'needFeed') {
            this.setState({ displayMode: 'needFeed' });
        } else if (key === 'collegeMap') {
            this.setState({ displayMode: 'collegeMap' });
        } else {
            this.setState({ displayMode: 'colleges' });
        }
    };

    showPostNeedModal = (postNeedModalVisible: boolean, createNeedsAux?: boolean, need?: NeedFeed) => {
        if (createNeedsAux === true) {
            let createNeeds;
            if (this.state.createNeeds === true) {
                createNeeds = false;
            } else {
                createNeeds = true;
            }
            this.setState({ postNeedModalVisible, createNeeds, need });
        } else {
            this.setState({ postNeedModalVisible, need });
        }
    };

    setSearchText = (searchText: string) => {
        this.setState({ searchText });
    };

    setDivision = (divisionId: number) => {
        this.setState({ divisionId });
    };

    setFavorite = () => {
        let favorite;
        if (this.state.favorite === true) {
            favorite = undefined;
        } else {
            favorite = true;
        }
        this.setState({ favorite });
    };

    setCountryState = (state: string) => {
        this.setState({ state, mile: undefined, collegeId: undefined });
    };

    setMile = (mile: number) => {
        this.setState({ mile, state: undefined, collegeId: undefined });
    };

    setMapWithCollegeCoordinates = (collegeId?: number) => {
        this.setState({ displayMode: 'collegeMap', collegeId, mile: undefined, state: undefined });
    };

    setSport = (sportId: number) => {
        this.setState({ sportId });
    };

    isNeedFeedVisible = (): boolean => {
        const { auth } = this.context;
        return (
            rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_COLLEGE_COACH']) ||
            (rolesService.hasAnyRole(auth, [
                'ROLE_ORGANIZATION_OWNER',
                'ROLE_ORGANIZATION_STAFF',
                'ROLE_ORGANIZATION_COACH',
            ]) &&
                auth?.collegeConnectEnabled === 'edit')
        );
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return (
                <div className={styles.header}>
                    <PageHeader
                        title={
                            <Search
                                placeholder={this.props.intl.formatMessage({ id: 'collegeConnect.searchPlaceholder' })}
                                size="large"
                                allowClear={true}
                                onSearch={this.setSearchText}
                                enterButton={<Button type="ghost">Search</Button>}
                            />
                        }
                        className={styles.search}
                    />
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.mobileSearch}>
                        <Search
                            placeholder={this.props.intl.formatMessage({ id: 'collegeConnect.searchPlaceholder' })}
                            size="large"
                            className={styles.searchMob}
                            allowClear={true}
                            onSearch={this.setSearchText}
                            enterButton={<Button type="ghost" icon={<Icon component={searchSvg} />} />}
                        />
                    </div>
                </>
            );
        }
    };

    renderCollegeConnectInfo = (): React.ReactElement | undefined => {
        return (
            <div className={styles.collegeConnectInfo}>
                <div className={styles.flex}>
                    <div className={styles.top100sports}></div>
                    <div className={styles.txt}>
                        <h1>
                            <FormattedMessage id="collegeConnect.college" />
                            <span>
                                <FormattedMessage id="collegeConnect.connect" />
                            </span>
                        </h1>
                    </div>

                    <div className={styles.txt}>
                        <p>
                            <FormattedMessage id="collegeConnect.collegeConnectInfo" />
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    renderTabButtons = (): React.ReactElement | undefined => {
        const { displayMode, favorite, mile, state, collegeDivisions, divisionId, sports, sportId } = this.state;

        const canadianStates = stateService.listCanadianStates();
        const usaStates = stateService.listUsaStates();

        // create state options
        let allStateOptions = (
            <Select.Option key="-" value="">
                <FormattedMessage id="common.allStates" />
            </Select.Option>
        );

        let canadaStatesOptions = (
            <OptGroup label={<FormattedMessage id="CA" />}>
                {canadianStates.map((s) => (
                    <Select.Option key={s.code} value={s.code}>
                        {s.name}
                    </Select.Option>
                ))}
            </OptGroup>
        );

        let usaStatesOptions = (
            <OptGroup label={<FormattedMessage id="US" />}>
                {usaStates.map((s) => (
                    <Select.Option key={s.code} value={s.code}>
                        {s.name}
                    </Select.Option>
                ))}
            </OptGroup>
        );

        // create division options
        let allDivisionOptions = (
            <Select.Option key="-" value="">
                <FormattedMessage id="nationalLeaderboard.allDivisions" />
            </Select.Option>
        );
        let canadaDivisionsOptions = (
            <OptGroup label={<FormattedMessage id="CA" />}>
                {collegeDivisions
                    .sort((a, b) => numericService.sort(b.id, a.id))
                    .map(
                        (d) => (d.name === 'U Sports' || d.name === 'CCAA') && <Option value={d.id!}>{d.name}</Option>,
                    )}
            </OptGroup>
        );
        let usaDivisionsOptions = (
            <OptGroup label={<FormattedMessage id="US" />}>
                {collegeDivisions
                    .sort((a, b) => numericService.sort(b.id, a.id))
                    .map((d) => d.name !== 'U Sports' && d.name !== 'CCAA' && <Option value={d.id!}>{d.name}</Option>)}
            </OptGroup>
        );

        let sportOptions = sports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));

        sportOptions = [
            <Select.Option key="-" value="">
                <FormattedMessage id="collegeConnect.allSports" />
            </Select.Option>,
            ...sportOptions,
        ];

        if (displayMode === 'needFeed') {
            return this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH') ? (
                <Button icon={<PlusOutlined />} onClick={() => this.showPostNeedModal(true)} className={styles.add}>
                    <FormattedMessage id="collegeConnect.postNeed" tagName="span" />
                </Button>
            ) : (
                <div className={styles.tools}>
                    <Select
                        placeholder={<FormattedMessage id="collegeConnect.allDivisions" tagName="span" />}
                        className={styles.filters}
                        onSelect={this.setDivision}
                        value={divisionId}
                    >
                        {allDivisionOptions}
                        {canadaDivisionsOptions}
                        {usaDivisionsOptions}
                    </Select>
                </div>
            );
        } else if (displayMode === 'collegeMap') {
            return (
                <div className={styles.tools}>
                    <Select
                        placeholder={<FormattedMessage id="collegeConnect.allStates" tagName="span" />}
                        className={styles.filters}
                        onSelect={this.setCountryState}
                        value={state}
                    >
                        {allStateOptions}
                        {canadaStatesOptions}
                        {usaStatesOptions}
                    </Select>
                    <Select
                        placeholder={<FormattedMessage id="collegeConnect.nearby" tagName="span" />}
                        className={styles.filters}
                        onSelect={this.setMile}
                        value={mile}
                    >
                        <Select.Option key="50" value={80468}>
                            {<FormattedMessage id="collegeConnect.50miles" />}
                        </Select.Option>
                        <Select.Option key="100" value={160934}>
                            {<FormattedMessage id="collegeConnect.100miles" />}
                        </Select.Option>
                        <Select.Option key="200" value={321869}>
                            {<FormattedMessage id="collegeConnect.200miles" />}
                        </Select.Option>
                    </Select>
                    <Select
                        placeholder={<FormattedMessage id="collegeConnect.allDivisions" tagName="span" />}
                        className={styles.filters}
                        onSelect={this.setDivision}
                        value={divisionId}
                    >
                        {allDivisionOptions}
                        {canadaDivisionsOptions}
                        {usaDivisionsOptions}
                    </Select>
                    <Select
                        placeholder={<FormattedMessage id="collegeConnect.allSports" tagName="span" />}
                        className={styles.filters}
                        onSelect={this.setSport}
                        value={sportId}
                    >
                        {sportOptions}
                    </Select>
                </div>
            );
        } else {
            return (
                <div className={styles.tools}>
                    {favorite ? (
                        <Button
                            type="text"
                            icon={<StarFilled />}
                            onClick={this.setFavorite}
                            className={styles.favorite}
                        ></Button>
                    ) : (
                        <Button
                            type="text"
                            icon={<StarOutlined />}
                            onClick={this.setFavorite}
                            className={styles.favorite}
                        ></Button>
                    )}

                    <Select
                        placeholder={<FormattedMessage id="collegeConnect.allStates" tagName="span" />}
                        className={styles.filters}
                        onSelect={this.setCountryState}
                        value={state}
                    >
                        {allStateOptions}
                        {canadaStatesOptions}
                        {usaStatesOptions}
                    </Select>
                    <Select
                        placeholder={<FormattedMessage id="collegeConnect.allDivisions" tagName="span" />}
                        className={styles.filters}
                        onSelect={this.setDivision}
                        value={divisionId}
                    >
                        {allDivisionOptions}
                        {canadaDivisionsOptions}
                        {usaDivisionsOptions}
                    </Select>
                    <Select
                        placeholder={<FormattedMessage id="collegeConnect.allSports" tagName="span" />}
                        className={styles.filters}
                        onSelect={this.setSport}
                        value={sportId}
                    >
                        {sportOptions}
                    </Select>
                </div>
            );
        }
    };

    renderColleges = (): React.ReactElement | undefined => {
        const { searchText, divisionId, favorite, collegeDivisions, state, sportId } = this.state;
        return (
            <CollegesComponent
                searchText={searchText}
                divisionId={divisionId}
                favorite={favorite}
                goToMap={this.setMapWithCollegeCoordinates}
                collegeDivisions={collegeDivisions}
                state={state}
                sportId={sportId}
            />
        );
    };

    renderNeedFeed = (): React.ReactElement | undefined => {
        const { createNeeds, divisionId } = this.state;
        return (
            <NeedFeedComponent
                createNeeds={createNeeds}
                editNeed={(need: NeedFeed) => this.showPostNeedModal(true, undefined, need)}
                divisionId={divisionId}
            />
        );
    };

    renderMap = (): React.ReactElement | undefined => {
        const { state, mile, collegeId, collegeDivisions, divisionId, sportId } = this.state;
        return (
            <>
                <MapComponent
                    state={state}
                    mile={mile}
                    collegeId={collegeId}
                    collegeDivisions={collegeDivisions}
                    divisionId={divisionId}
                    sportId={sportId}
                />
                <div className={styles.legend}>
                    <ul className={styles.flex}>
                        <li>
                            <FormattedMessage id="collegeConnect.legend.divisions" />
                        </li>
                        {collegeDivisions
                            .filter((division) => division.id && [8, 9].includes(division.id))
                            .map((division) => (
                                <li>
                                    <span className={`${styles['pin' + division.id]}`} />
                                    {division.name}
                                </li>
                            ))}
                        {collegeDivisions
                            .filter((division) => division.id && [1, 2, 3, 4, 5, 6, 7].includes(division.id))
                            .map((division) => (
                                <li>
                                    <span className={`${styles['pin' + division.id]}`} />
                                    {division.name}
                                </li>
                            ))}
                    </ul>
                </div>
            </>
        );
    };

    renderTabs = (): React.ReactElement | undefined => {
        const { displayMode } = this.state;
        let currentDisplayMode = displayMode;
        if (!currentDisplayMode) {
            currentDisplayMode = rolesService.hasAllRoles(this.context.auth, ['ROLE_COLLEGE_COACH'])
                ? 'needFeed'
                : 'colleges';
        }

        return (
            <Tabs
                activeKey={currentDisplayMode}
                className={styles.tabs}
                tabBarExtraContent={this.renderTabButtons()}
                onTabClick={this.changeDisplayMode}
            >
                <Tabs.TabPane
                    tab={<FormattedMessage id="collegeConnect.colleges" />}
                    key="colleges"
                    className={styles.tab}
                    forceRender
                >
                    {this.renderColleges()}
                </Tabs.TabPane>
                {this.isNeedFeedVisible() && (
                    <Tabs.TabPane
                        tab={<FormattedMessage id="collegeConnect.needFeed" />}
                        key="needFeed"
                        className={styles.tab}
                        forceRender
                    >
                        {this.renderNeedFeed()}
                    </Tabs.TabPane>
                )}
                <Tabs.TabPane
                    tab={<FormattedMessage id="collegeConnect.collegeMap" />}
                    key="collegeMap"
                    className={styles.tab}
                    forceRender
                >
                    {this.renderMap()}
                </Tabs.TabPane>
            </Tabs>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { postNeedModalVisible, need } = this.state;
        return (
            <>
                {this.renderCollegeConnectInfo()}
                {this.renderTabs()}
                {postNeedModalVisible && (
                    <PostNeedModal
                        onCancel={() => this.showPostNeedModal(false)}
                        onUpdate={() => this.showPostNeedModal(false, true)}
                        need={need}
                    />
                )}
            </>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="collegeConnect.meta.title" />
                <LayoutComponent page="colleges-public" content={this.renderContent} header={this.renderHeader} />
            </>
        );
    }
}
export default injectIntl(CollegeConnectPage);

interface Props extends WrappedComponentProps {}

interface State {
    loading?: boolean;
    searchText?: string;
    displayMode?: DisplayMode;
    postNeedModalVisible?: boolean;
    divisionId?: number;
    favorite?: boolean;
    state?: string;
    mile?: number;
    createNeeds?: boolean;
    need?: NeedFeed;
    collegeId?: number;
    collegeDivisions: CollegeDivision[];
    sports: Sport[];
    sportId?: number;
}

type DisplayMode = 'colleges' | 'needFeed' | 'collegeMap';
