import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { Button, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import collegeApi from '../../../apis/CollegeApi';
import collegeDivisionApi from '../../../apis/CollegeDivisionApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/Elements';
import { College, CollegeDivision } from '../../../model/Entities';
import HeadMetadata from '../../../services/HeadMetadata';
import notificationService from '../../../services/NotificationService';
import tableService from '../../../services/TableService';
import styles from './AdminCollegesPage.module.scss';

class AdminCollegesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;

    constructor(props: Props) {
        super(props);
        this.state = { collegeDivisions: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const collegesPage = await collegeApi.list(this.pageSize, 1);
            const collegeDivisions = await collegeDivisionApi.list();
            this.setState({ collegesPage, collegeDivisions });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<College> | SorterResult<College>[],
    ) => {
        const { searchText } = this.state;
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            let sortField, sortOrder;
            if (Array.isArray(sorter)) {
            } else {
                sortField = sorter.field as string;
                sortOrder = sorter.order as string;
            }
            const collegesPage = await collegeApi.list(pageSize, page, sortField, sortOrder, searchText);
            this.setState({ collegesPage, sortField, sortOrder });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    search = async (searchText: string) => {
        const { sortField, sortOrder } = this.state;
        try {
            this.setState({ loading: true });
            const collegesPage = await collegeApi.list(this.pageSize, 1, sortField, sortOrder, searchText);

            this.setState({ collegesPage, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                <div className={styles.table}>{this.renderTable()}</div>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        return (
            <div className={styles.toolbar}>
                <h1>
                    <FormattedMessage id="colleges.title" />
                </h1>
                <Link to="/admin/colleges/new">
                    <Tooltip title={<FormattedMessage id="colleges.tooltip" />}>
                        <Button type="primary" size="large" className={styles.add} icon={<PlusOutlined />}>
                            <FormattedMessage id="colleges.add" />
                        </Button>
                    </Tooltip>
                </Link>
            </div>
        );
    };

    renderTableHeader = (): React.ReactElement => {
        return (
            <header className={styles.tableHeader}>
                <Search
                    placeholder={this.props.intl.formatMessage({ id: 'button.search' })}
                    className={styles.search}
                    enterButton={''}
                    allowClear={true}
                    onSearch={this.search}
                />
            </header>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { collegesPage, loading, collegeDivisions } = this.state;
        const items = collegesPage ? collegesPage.items : [];
        const columns: ColumnsType<College> = [
            {
                title: <FormattedMessage id="colleges.school" />,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                render: (value: string, college: College) => <Link to={`/admin/colleges/${college.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="colleges.division" />,
                dataIndex: 'divisions',
                key: 'divisions',
                sorter: true,
                render: (value: string, college: College) => (
                    <Link to={`/admin/colleges/${college.id}`}>
                        {collegeDivisions
                            .filter((d) => college.divisions?.includes(d.id!))
                            .map((d) => d.name)
                            .join(', ')}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="colleges.athleticWebsite" />,
                dataIndex: 'athletesUrl',
                key: 'athletesUrl',
                render: (value: string, college: College) =>
                    value && (
                        <Link to={{ pathname: value }} target="_blank" rel="noreferrer">
                            <FormattedMessage id="colleges.athleticWebsite.url" />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="colleges.staffDirectory" />,
                dataIndex: 'campsUrl',
                key: 'campsUrl',
                render: (value: string, college: College) =>
                    value && (
                        <Link to={{ pathname: value }} target="_blank" rel="noreferrer">
                            <FormattedMessage id="colleges.staffDirectory.url" />
                        </Link>
                    ),
            },
            {
                title: <FormattedMessage id="colleges.admissionLink" />,
                dataIndex: 'admissionsUrl',
                key: 'admissionsUrl',
                render: (value: string, college: College) =>
                    value && (
                        <Link to={{ pathname: value }} target="_blank" rel="noreferrer">
                            <FormattedMessage id="colleges.admissionLink.url" />
                        </Link>
                    ),
            },
        ];

        return (
            <>
                {this.renderTableHeader()}
                <Table
                    dataSource={items}
                    columns={columns}
                    pagination={tableService.createPagination(collegesPage, { pageSize: this.pageSize })}
                    rowKey="id"
                    onChange={this.list}
                    sortDirections={['ascend', 'descend']}
                    showSorterTooltip={false}
                    loading={loading}
                />
            </>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="colleges.meta.title" />
                <LayoutComponent page="admin-colleges" content={this.renderContent} />
            </>
        );
    }
}
export default injectIntl(AdminCollegesPage);

interface Props extends WrappedComponentProps {}

interface State {
    collegesPage?: Page<College>;
    loading?: boolean;
    collegeDivisions: CollegeDivision[];
    sortField?: string;
    sortOrder?: string;
    searchText?: string;
}
