import { Col, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import collegeDivisionApi from '../../../../apis/CollegeDivisionApi';
import divisionApi from '../../../../apis/DivisionApi';
import eventApi from '../../../../apis/EventApi';
import organizationApi from '../../../../apis/OrganizationApi';
import sportApi from '../../../../apis/SportApi';
import teamApi from '../../../../apis/TeamApi';
import { CollegeDivision, Division, Event, Organization, Role, Sport, Team } from '../../../../model/Entities';
import notificationService from '../../../../services/NotificationService';
import { SubscriberAdminType } from '../../AdminNotificationComponent/AdminNotificationComponent';
import { SubscriberOwnerType } from '../../OrganizationOwnerNotificationComponent/OrganizationOwnerNotificationComponent';
import styles from './SummaryModal.module.scss';

/**
 * Returns the summary modal component.
 * @param props the props
 * @returns the summary modal component
 */
const SummaryModal: React.FunctionComponent<SummaryModalProps> = (props): React.ReactElement => {
    const {
        modalType,
        loading,
        save,
        subscriber,
        sportIds,
        organizationIds,
        eventId,
        collegeDivisionIds,
        roles,
        roleIds,
        divisionIds,
        teamIds,
        email,
        scheduleSending,
        subject,
        body,
        sendTime,
        timeZone,
        onUpdate,
    } = props;

    /*** HOOKS ***/
    const intl = useIntl();
    const [sports, setSports] = useState<Sport[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [event, setEvent] = useState<Event>();
    const [collegeDivisions, setCollegeDivisions] = useState<CollegeDivision[]>([]);
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);
    const [loadingModal, setLoadingModal] = useState<boolean>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoadingModal(true);

                // load sports
                if (sportIds && sportIds.length > 0) {
                    const sports = await sportApi.listAll();

                    setSports(sports);
                }

                // load organizations
                if (organizationIds && organizationIds.length > 0) {
                    const organizations = await organizationApi.listAll();

                    setOrganizations(organizations);
                }

                // load events
                if (eventId) {
                    const event = await eventApi.get(eventId);

                    setEvent(event);
                }

                // load college divisions
                if (collegeDivisionIds && collegeDivisionIds.length > 0) {
                    const collegeDivisions = await collegeDivisionApi.list();

                    setCollegeDivisions(collegeDivisions);
                }

                // load divisions
                if (sportIds && sportIds.length === 1 && divisionIds && divisionIds.length > 0) {
                    const divisions = await divisionApi.list(sportIds[0]);
                    setDivisions(divisions);
                }

                // load teams
                if (organizationIds && organizationIds.length === 1 && teamIds && teamIds.length > 0) {
                    const teams = await teamApi.listByOrganization(organizationIds[0]);
                    setTeams(teams);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoadingModal(false);
            }
        };
        init();
    }, [collegeDivisionIds, divisionIds, eventId, intl, organizationIds, sportIds, teamIds]);

    /*** COMPONENTS ***/

    return (
        <Modal
            title={<FormattedMessage id={'notifications.sendNotificationsModal.title'} />}
            className={styles.summaryModal}
            width={600}
            visible={true}
            okText={<FormattedMessage id={'button.send'} />}
            okButtonProps={{ loading: loading === 'loading' }}
            onOk={save}
            onCancel={() => onUpdate(false)}
        >
            {loadingModal && !loading ? (
                <Spin size="large" className={styles.spinner}></Spin>
            ) : (
                <>
                    <Row gutter={[28, 0]} className={styles.label}>
                        <Col span={24}>
                            <div>{<FormattedMessage id={'notifications.sendNotificationsModal.roles'} />}</div>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]} className={styles.labelContent}>
                        <Col span={24}>
                            {
                                <div>
                                    ·{' '}
                                    {!roleIds || roleIds.length === 0 ? (
                                        <FormattedMessage id={'notifications.sendNotificationsModal.allRoles'} />
                                    ) : (
                                        roles
                                            .filter((r) => roleIds.includes(r.id!))
                                            .map((r) =>
                                                intl.formatMessage({
                                                    id: `notifications.${r.name}`,
                                                }),
                                            )
                                            .join(', ')
                                    )}
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]} className={styles.label}>
                        <Col span={24}>
                            {(subscriber || modalType === 'Coach') && (
                                <div>
                                    <FormattedMessage id={'notifications.sendNotificationsModal.subscribers'} />
                                </div>
                            )}
                        </Col>
                    </Row>

                    {modalType === 'Admin' && (
                        <AdminSummaryModal
                            subscriber={subscriber}
                            sports={sports}
                            organizations={organizations}
                            event={event}
                            collegeDivisions={collegeDivisions}
                            divisions={divisions}
                            teams={teams}
                            sportIds={sportIds}
                            organizationIds={organizationIds}
                            eventId={eventId}
                            collegeDivisionIds={collegeDivisionIds}
                            divisionIds={divisionIds}
                            teamIds={teamIds}
                        />
                    )}

                    {modalType === 'Owner' && (
                        <OwnerSummaryModal
                            subscriber={subscriber}
                            sports={sports}
                            event={event}
                            divisions={divisions}
                            teams={teams}
                            sportIds={sportIds}
                            eventId={eventId}
                            divisionIds={divisionIds}
                            teamIds={teamIds}
                        />
                    )}

                    {modalType === 'Coach' && (
                        <CoachSummaryModal
                            divisions={divisions}
                            teams={teams}
                            divisionIds={divisionIds}
                            teamIds={teamIds}
                        />
                    )}

                    {email && (
                        <>
                            <Row gutter={[28, 0]} className={styles.label}>
                                <Col span={24}>
                                    <div>
                                        {<FormattedMessage id={'notifications.sendNotificationsModal.subject'} />}
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={[28, 0]} className={styles.labelContent}>
                                <Col span={24}>
                                    <div>{subject}</div>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row gutter={[28, 0]} className={styles.label}>
                        <Col span={24}>
                            <div>{<FormattedMessage id={'notifications.sendNotificationsModal.body'} />}</div>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]} className={styles.labelContent}>
                        <Col span={24}>
                            <div>{body}</div>
                        </Col>
                    </Row>
                    {scheduleSending && (
                        <>
                            <Row gutter={[28, 0]} className={styles.label}>
                                <Col span={24}>
                                    <div>{<FormattedMessage id={'notifications.dateAndTime'} />}</div>
                                </Col>
                            </Row>
                            <Row gutter={[28, 0]} className={styles.labelContent}>
                                <Col span={24}>
                                    <div>
                                        {
                                            <FormattedDate
                                                value={sendTime}
                                                month="2-digit"
                                                day="2-digit"
                                                year="numeric"
                                                hour="2-digit"
                                                minute="2-digit"
                                            />
                                        }
                                        , {<FormattedMessage id={`scheduledNotifications.${timeZone}`} />}
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

export default SummaryModal;

interface SummaryModalProps {
    modalType?: ModalType;
    loading?: 'loading';
    save: () => void;
    subscriber?: SubscriberAdminType | SubscriberOwnerType;
    sportIds?: number[];
    organizationIds?: string[];
    eventId?: number;
    collegeDivisionIds?: number[];
    roles: Role[];
    roleIds?: number[];
    divisionIds?: number[];
    teamIds?: string[];
    email?: boolean;
    scheduleSending?: boolean;
    subject?: string;
    body?: string;
    sendTime?: number;
    timeZone?: string;
    onUpdate: (visible: boolean) => void;
}

export const modalType = ['Admin', 'Owner', 'Coach'] as const;
export type ModalType = typeof modalType[number];

/**
 * Returns the Admin summary modal component.
 * @param props the props
 * @returns the Admin summary modal component
 */
const AdminSummaryModal: React.FunctionComponent<AdminSummaryModalProps> = (props) => {
    const {
        subscriber,
        sports,
        event,
        organizations,
        divisions,
        teams,
        collegeDivisions,
        sportIds,
        organizationIds,
        collegeDivisionIds,
        divisionIds,
        teamIds,
    } = props;

    return (
        <>
            {(subscriber === 'Organizations' || subscriber === 'Colleges') && (
                <Row gutter={[28, 0]} className={styles.labelContent}>
                    <Col span={24}>
                        <div>
                            <>
                                ·{' '}
                                {(!sportIds || sportIds.length === 0) && (
                                    <FormattedMessage id={'notifications.sendNotificationsModal.allSports'} />
                                )}
                                {sportIds &&
                                    sportIds.length > 0 &&
                                    sports
                                        .filter((s) => sportIds.includes(s.id!))
                                        .map((s) => s.name)
                                        .join(', ')}
                            </>
                        </div>
                    </Col>
                </Row>
            )}

            {subscriber === 'Events' && (
                <Row gutter={[28, 0]} className={styles.labelContent}>
                    <Col span={24}>
                        <div>· {event?.name}</div>
                    </Col>
                </Row>
            )}

            {subscriber === 'Organizations' && (
                <>
                    <Row gutter={[28, 0]} className={styles.labelContent}>
                        <Col span={24}>
                            <div>
                                <>
                                    ·{' '}
                                    {(!organizationIds || organizationIds.length === 0) && (
                                        <FormattedMessage
                                            id={'notifications.sendNotificationsModal.allOrganizations'}
                                        />
                                    )}
                                    {organizationIds &&
                                        organizationIds.length > 0 &&
                                        organizations
                                            .filter((o) => organizationIds.includes(o.id!))
                                            .map((o) => o.name)
                                            .join(', ')}
                                </>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]} className={styles.labelContent}>
                        <Col span={24}>
                            <div>
                                {!divisionIds || divisionIds.length === 0 ? (
                                    <div>
                                        ·{' '}
                                        {<FormattedMessage id={'notifications.sendNotificationsModal.allDivisions'} />}
                                    </div>
                                ) : (
                                    divisionIds.map((divisionId: number) => {
                                        const divisionName = divisions.find((d) => d.id === divisionId)?.name;
                                        return (
                                            <Row gutter={[28, 0]} key={divisionId}>
                                                <Col span={24}>
                                                    <div className={styles.labelAux}>
                                                        · {divisionName}
                                                        {': '}
                                                        {!teamIds || (teamIds && teamIds.length === 0) ? (
                                                            <FormattedMessage
                                                                id={'notifications.sendNotificationsModal.allTeams'}
                                                            />
                                                        ) : (
                                                            teams
                                                                .filter(
                                                                    (team) =>
                                                                        team.id &&
                                                                        team.divisionId === divisionId &&
                                                                        teamIds?.includes(team.id),
                                                                )
                                                                .map((t) => {
                                                                    return t.name;
                                                                })
                                                                .join(', ')
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                )}
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            {subscriber === 'Colleges' && (
                <Row gutter={[28, 0]} className={styles.labelContent}>
                    <Col span={24}>
                        <div>
                            ·{' '}
                            {(!collegeDivisionIds || collegeDivisionIds.length === 0) && (
                                <FormattedMessage id={'notifications.sendNotificationsModal.allDivisions'} />
                            )}
                            {collegeDivisionIds &&
                                collegeDivisionIds.length > 0 &&
                                collegeDivisions
                                    .filter((cd) => collegeDivisionIds.includes(cd.id!))
                                    .map((cd) => cd.name)
                                    .join(', ')}
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

interface AdminSummaryModalProps {
    subscriber?: SubscriberAdminType | SubscriberOwnerType;
    sports: Sport[];
    event?: Event;
    organizations: Organization[];
    divisions: Division[];
    teams: Team[];
    collegeDivisions: CollegeDivision[];
    sportIds?: number[];
    organizationIds?: string[];
    eventId?: number;
    collegeDivisionIds?: number[];
    divisionIds?: number[];
    teamIds?: string[];
}

/**
 * Returns the Owner summary modal component.
 * @param props the props
 * @returns the Owner summary modal component
 */
const OwnerSummaryModal: React.FunctionComponent<OwnerSummaryModalProps> = (props) => {
    const { subscriber, sports, divisions, teams, event, sportIds, divisionIds, teamIds } = props;

    return (
        <>
            {subscriber === 'OrganizationMembers' && (
                <>
                    <Row gutter={[28, 0]} className={styles.labelContent}>
                        <Col span={24}>
                            <div>
                                <>
                                    ·{' '}
                                    {(!sportIds || sportIds.length === 0) && (
                                        <FormattedMessage id={'notifications.sendNotificationsModal.allSports'} />
                                    )}
                                    {sportIds &&
                                        sportIds.length > 0 &&
                                        sports
                                            .filter((s) => sportIds.includes(s.id!))
                                            .map((s) => s.name)
                                            .join(', ')}
                                </>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]} className={styles.labelContent}>
                        <Col span={24}>
                            <div>
                                {!divisionIds || divisionIds.length === 0 ? (
                                    <div>
                                        ·{' '}
                                        {<FormattedMessage id={'notifications.sendNotificationsModal.allDivisions'} />}
                                    </div>
                                ) : (
                                    divisionIds.map((divisionId: number) => {
                                        const divisionName = divisions.find((d) => d.id === divisionId)?.name;
                                        return (
                                            <Row gutter={[28, 0]} key={divisionId} className={styles.labelContent}>
                                                <Col span={24}>
                                                    <div className={styles.labelAux}>
                                                        · {divisionName}
                                                        {': '}
                                                        {!teamIds || (teamIds && teamIds.length === 0) ? (
                                                            <FormattedMessage
                                                                id={'notifications.sendNotificationsModal.allTeams'}
                                                            />
                                                        ) : (
                                                            teams
                                                                .filter(
                                                                    (team) =>
                                                                        team.id &&
                                                                        team.divisionId === divisionId &&
                                                                        teamIds?.includes(team.id),
                                                                )
                                                                .map((t) => {
                                                                    return t.name;
                                                                })
                                                                .join(', ')
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                )}
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            {subscriber === 'Events' && (
                <Row gutter={[28, 0]} className={styles.labelContent}>
                    <Col span={24}>
                        <div>· {event?.name}</div>
                    </Col>
                </Row>
            )}
        </>
    );
};

interface OwnerSummaryModalProps {
    subscriber?: SubscriberAdminType | SubscriberOwnerType;
    sports: Sport[];
    divisions: Division[];
    teams: Team[];
    event?: Event;
    sportIds?: number[];
    organizationIds?: string[];
    eventId?: number;
    divisionIds?: number[];
    teamIds?: string[];
}

/**
 * Returns the Coach summary modal component.
 * @param props the props
 * @returns the Coach summary modal component
 */
const CoachSummaryModal: React.FunctionComponent<CoachSummaryModalProps> = (props) => {
    const { divisions, teams, divisionIds, teamIds } = props;

    if (divisionIds) {
        return (
            <>
                {divisionIds.map((divisionId: number) => {
                    const divisionName = divisions.find((d) => d.id === divisionId)?.name;
                    return (
                        <Row gutter={[28, 0]} key={divisionId} className={styles.labelContent}>
                            <Col span={24}>
                                <div className={styles.labelAux}>
                                    · {divisionName}
                                    {': '}
                                    {!teamIds || (teamIds && teamIds.length === 0) ? (
                                        <FormattedMessage id={'notifications.sendNotificationsModal.allTeams'} />
                                    ) : (
                                        teams
                                            .filter(
                                                (team) =>
                                                    team.id &&
                                                    team.divisionId === divisionId &&
                                                    teamIds?.includes(team.id),
                                            )
                                            .map((t) => {
                                                return t.name;
                                            })
                                            .join(', ')
                                    )}
                                </div>
                            </Col>
                        </Row>
                    );
                })}
            </>
        );
    } else {
        return <></>;
    }
};

interface CoachSummaryModalProps {
    divisions: Division[];
    teams: Team[];
    divisionIds?: number[];
    teamIds?: string[];
}
