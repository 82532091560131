import axios from 'axios';
import qs from 'qs';
import { Page } from '../model/Elements';
import { CognitoUser, FamilyMember, User, UserAcademic, UserAccount } from '../model/Entities';
import apiAxios from './CustomAxios';

class UserApi {
    list = async (
        limit: number,
        page: number,
        search?: string,
        userType?: string,
        sportId?: number,
    ): Promise<Page<FamilyMember>> => {
        let roleIds;
        if (userType) {
            roleIds = userType === 'player' ? [2] : [1];
        }

        const roleIdsValue = 'filtering.RoleIds.Value';
        const roleIdsOperation = 'filtering.RoleIds.Operation';

        const filteringSportIdOperation = 'filtering.SportId.Operation';
        const sportIdOperation = sportId ? 'Equal' : undefined;
        const sportIdValue = 'filtering.SportId.Value';
        const response = await apiAxios.get<Page<FamilyMember>>('/v1/users', {
            params: {
                limit,
                page,
                search,
                [roleIdsValue]: userType ? roleIds : undefined,
                [roleIdsOperation]: userType ? 'NumberIn' : undefined,
                [filteringSportIdOperation]: sportIdOperation,
                [sportIdValue]: sportId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        return response.data;
    };

    listByRole = async (
        limit: number,
        page: number,
        sortField?: string[],
        sortOrder?: string,
        search?: string,
        eventId?: number,
    ): Promise<Page<User>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getSortField(sortField);
        const eventFilter: string = 'filtering.EventId.Value';
        const eventOrder: string = 'filtering.EventId.Operation';
        const eventOrd: string | undefined = eventId ? 'Equal' : undefined;
        const roleFilter: string = 'filtering.RoleIds.Value';
        const roleFilt: number[] = [2, 3];
        const roleOperation: string = 'filtering.RoleIds.Operation';
        const roleOp: string = 'NumberIn';
        const response = await apiAxios.get<Page<User>>('/v1/users', {
            params: {
                limit,
                page,
                [field]: order,
                search,
                [eventFilter]: eventId,
                [eventOrder]: eventOrd,
                [roleFilter]: roleFilt,
                [roleOperation]: roleOp,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        response.data.items.forEach(
            (i) => (i.profile.birthday = i.profile.birthday ? i.profile.birthday * 1000 : undefined),
        );

        return response.data;
    };

    listAll = async (): Promise<FamilyMember[]> => {
        let page = 1;
        let total: number;
        let items: FamilyMember[] = [];
        do {
            const itemsPage = await this.list(500, page);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        return items;
    };

    get = async (userId: string): Promise<User> => {
        const response = await apiAxios.get<User>(`/v1/users/${userId}`);
        response.data.profile.birthday = response.data.profile.birthday
            ? response.data.profile.birthday * 1000
            : undefined;
        return response.data;
    };

    getFromCognito = async (): Promise<CognitoUser> => {
        const response = await apiAxios.get<CognitoUser>(`/v1/users/by-cognito`);
        return response.data;
    };

    getAcademic = async (userId: string): Promise<UserAcademic> => {
        const response = await apiAxios.get<UserAcademic>(`/v1/users/${userId}/academic`);
        return response.data;
    };

    getAccount = async (userId: string): Promise<UserAccount> => {
        const response = await apiAxios.get<UserAccount>(`/v1/users/${userId}/account`);
        response.data.birthday = response.data.birthday ? response.data.birthday * 1000 : undefined;
        return response.data;
    };

    updateAcademic = async (userId: string, userAcademic: UserAcademic): Promise<UserAcademic> => {
        const response = await apiAxios.put<UserAcademic>(`/v1/users/${userId}/academic`, userAcademic);
        return response.data;
    };

    updateAccount = async (userId: string, userAccount: UserAccount): Promise<UserAccount> => {
        userAccount.birthday = userAccount.birthday ? Math.round(userAccount.birthday / 1000) : undefined;
        const response = await apiAxios.put<UserAccount>(`/v1/users/${userId}/account`, userAccount);
        return response.data;
    };

    updateEmail = async (userId: string, email: string): Promise<User> => {
        const response = await apiAxios.put<User>(`/v1/users/${userId}/email`, { email });
        return response.data;
    };

    updateUsername = async (userId: string, userName: string): Promise<void> => {
        const response = await apiAxios.put<void>(`/v1/users/${userId}/user-name`, { userName });
        return response.data;
    };

    sendInvites = async (playerIds: string[]): Promise<string[]> => {
        const players = { users: playerIds };
        const response = await apiAxios.post<string[]>('/v1/users/invite', players);

        return response.data;
    };

    sendReInvites = async (playerIds: string[]): Promise<string[]> => {
        const players = { users: playerIds };
        const response = await apiAxios.put<string[]>('/v1/users/reinvite', players);

        return response.data;
    };

    create = async (user: User): Promise<User> => {
        const profile = user.profile;
        profile.birthday = profile.birthday ? Math.round(profile.birthday / 1000) : undefined;
        const response = await apiAxios.post<User>('/v1/users', profile);
        return response.data;
    };

    update = async (user: User): Promise<User> => {
        const profile = user.profile;
        profile.birthday = profile.birthday ? Math.round(profile.birthday / 1000) : undefined;
        const response = await apiAxios.put<User>(`/v1/users/${user.id}`, profile);
        return response.data;
    };

    delete = async (user: User): Promise<void> => {
        await apiAxios.delete(`/v1/users/${user.id}`);
    };

    updatePhoto = async (userId: string, file: File): Promise<string> => {
        // get s3 upload url
        const initialResponse = await apiAxios.get(`/v1/media/generate-presigned-profile-photo-url/${userId}`);
        const s3UploadUrl = initialResponse.data.url;

        // post photo to s3
        await axios.put(s3UploadUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });

        // confirm new user sport id
        await apiAxios.post<void>(`/v1/media/confirm_uploaded-profile-photo-by-presigned-url-for-all-user`, {
            userId,
            s3Link: s3UploadUrl,
        });

        return s3UploadUrl;
    };

    private getSortField(sortField?: string[]): string {
        let field: string = 'sorting.GivenName';
        if (!sortField) {
            return field;
        }
        if (sortField[1] === 'givenName') {
            field = 'sorting.GivenName';
        } else if (sortField[1] === 'familyName') {
            field = 'sorting.FamilyName';
        }
        return field;
    }
}

const userApi: UserApi = new UserApi();
export default userApi;
