import { message } from 'antd';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import CustomContext from '../../context/CustomContext';
import authService from '../../services/AuthService';
import ga from '../../services/GoogleAnalytics';

class SignOutPage extends Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { updateAuth } = this.context;
        const { intl } = this.props;

        try {
            await authService.signOut();
            updateAuth();
            ga.createDefaultEvent('sign out', 'sign out - sign out success');
            this.props.history.push('/');
        } catch (error: any) {
            if (error.message) {
                message.error(error.message, 6);
                ga.createDefaultEvent('sign out', `sign out - ${error.message}`);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
                ga.createDefaultEvent('sign out', 'sign out - sign out internal error');
            }
        }
    };

    render() {
        return <></>;
    }
}
export default injectIntl(SignOutPage);

interface Props extends WrappedComponentProps, RouteComponentProps {}
