import { Page } from '../model/Elements';
import { Sport } from '../model/Entities';
import apiAxios from './CustomAxios';

class SportApi {
    private list = async (limit: number, page: number, code?: string, name?: string): Promise<Page<Sport>> => {
        const response = await apiAxios.get<Page<Sport>>('/v1/sports', {
            params: { limit, page, code, name },
        });

        return response.data;
    };

    listAll = async (): Promise<Sport[]> => {
        let page = 1;
        let total: number;
        let items: Sport[] = [];
        do {
            const itemsPage = await this.list(500, page);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        items = items.filter((s) => s.code === 'BASEBALL' || s.code === 'SOFTBALL' || s.code === 'LACROSSE');

        return items;
    };

    get = async (sportId?: number): Promise<Sport> => {
        const response = await apiAxios.get<Sport>(`/v1/sports/${sportId}`);
        return response.data;
    };

    create = async (sport: Sport): Promise<Sport> => {
        const response = await apiAxios.post<Sport>('/v1/sports', sport);
        return response.data;
    };

    update = async (sport: Sport): Promise<Sport> => {
        const response = await apiAxios.put<Sport>(`/v1/sports/${sport.id}`, sport);
        return response.data;
    };

    delete = async (sport: Sport): Promise<void> => {
        await apiAxios.delete(`/v1/sports/${sport.id}`);
    };
}

const sportApi: SportApi = new SportApi();
export default sportApi;
