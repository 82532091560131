class UrlService {
    create = (location: Location, searchParams: URLSearchParams): string => {
        return `${location.protocol}//${location.host}${location.pathname}?${searchParams.toString()}`;
    };

    getParameter = (params: URLSearchParams, paramName: string): string | undefined => {
        return params.get(paramName) ? params.get(paramName)!.toString() : undefined;
    };

    getParamAsNumber = (params: URLSearchParams, paramName: string): number | undefined => {
        return params.get(paramName) ? +params.get(paramName)! : undefined;
    };
}

const urlService: UrlService = new UrlService();
export default urlService;
