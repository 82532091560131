export const authorities = [
    'ROLE_ADMIN',
    'ROLE_ORGANIZATION',
    'ROLE_ORGANIZATION_OWNER',
    'ROLE_ORGANIZATION_STAFF',
    'ROLE_ORGANIZATION_COACH',
    'ROLE_PLAYER',
    'ROLE_PARENT',
    'ROLE_PARENT_OWNER',
    'ROLE_COLLEGE_COACH',
] as const;
export type AuthorityType = typeof authorities[number];

export type PageType =
    | 'leaderboard'
    | 'combine'
    | 'events-upcoming'
    | 'events-completed'
    | 'events-live'
    | 'rosters'
    | 'player-profile'
    | 'player-data'
    | 'player-media'
    | 'player-edit'
    | 'player-code'
    | 'colleges'
    | 'colleges-public'
    | 'settings'
    | 'signout'
    | 'signin'
    | 'roster'
    | 'event'
    | 'event-register'
    | 'event-child'
    | 'event-payments'
    | 'event-confirmation'
    | 'event-leaderboard'
    | 'event-players'
    | 'event-settings'
    | 'admin-organizations'
    | 'admin-teams'
    | 'edit-team'
    | 'admin-players'
    | 'edit-player'
    | 'admin-colleges'
    | 'admin-users'
    | 'edit-user'
    | 'admin-coaches'
    | 'edit-coach'
    | 'admin-awards'
    | 'manage-roster'
    | 'add-athlete'
    | 'import-users'
    | 'seasons'
    | 'groups'
    | 'parent-profile'
    | 'organization-user-profile'
    | 'prospects'
    | 'college-coach-profile'
    | 'invoices'
    | 'organization'
    | 'notifications';

export const genders = ['Male', 'Female'] as const;
export type GenderType = typeof genders[number];

export const countries = ['US', 'CA'] as const;
export type CountryType = typeof countries[number];

export const medias = ['Photo', 'Video', 'File'] as const;
export type MediaType = typeof medias[number];

export const displays = ['list', 'cards'] as const;
export type DisplayType = typeof displays[number];

export const payments = ['bankAccount', 'creditCard'] as const;
export type PaymentType = typeof payments[number];

export const usersTypes = ['Coach', 'Athlete', 'FreeAgent'] as const;
export type UserType = typeof usersTypes[number];

export const awards = ['Athletic', 'Academic'] as const;
export type AwardType = typeof awards[number];

export const eventsStatus = ['Completed', 'Live', 'Upcoming'] as const;
export type EventStatusType = typeof eventsStatus[number];

export const countriesFilter = ['US', 'CA'] as const;
export type CountryFilterType = typeof countriesFilter[number];

export const participatedStatus = ['participated', 'noShow'] as const;
export type ParticipatedStatusType = typeof participatedStatus[number];

export const leaderboards = ['global', 'pitching', 'catching'] as const;
export type LeaderboardType = typeof leaderboards[number];

export const userStatuses = ['None', 'InviteError', 'InviteSent', 'Active'] as const;
export type UserStatusType = typeof userStatuses[number];

export const roles = ['Top100Admin', 'Top100Staff', 'TeamCoach', 'Player', 'Parent', 'OrganizationOwner'] as const;
export type RoleType = typeof roles[number];

export const eventRegistrations = ['Global', 'Pitching', 'Catching'] as const;
export type EventRegistrationType = typeof eventRegistrations[number];

export const cards = ['VISA', 'MC', 'AMEX'] as const;
export type CardType = typeof cards[number];

export const paymentProcess = ['selection', 'execution', 'success'] as const;
export type PaymentProcessType = typeof paymentProcess[number];

export const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'] as const;
export type MonthType = typeof months[number];

export const shirtSizes = ['XS', 'Sm', 'Med', 'Lg', 'XL', 'XXL'] as const;
export type ShirtSizeType = typeof shirtSizes[number];

export const timeZones = ['America/New_York', 'America/Chicago', 'America/Denver', 'America/Los_Angeles'] as const;
export type TimeZoneType = typeof timeZones[number];

export const paymentStatus = ['Created', 'Unpaid', 'Paid', 'PartiallyPaid', 'Overdue', 'OverduePartiallyPaid'] as const;
export type PaymentStatusType = typeof paymentStatus[number];

export const sortingPlayers = [
    'jerseyAsc',
    'jerseyDesc',
    'firstNameAsc',
    'firstNameDesc',
    'lastNameAsc',
    'lastNameDesc',
] as const;
export type SortingPlayerType = typeof sortingPlayers[number];

export const paymentServiceStatuses = ['Ok', 'TokenExpired', 'TokenNotSetUp', 'ServiceUnavailable'] as const;
export type PaymentServiceStatus = typeof paymentServiceStatuses[number];

export const usersSources = ['Organization', 'College', 'Event', 'All'] as const;
export type UserSourceType = typeof usersSources[number];

export const notificationsDestinations = ['Push', 'Email', 'All'] as const;
export type NotificationDestinationType = typeof notificationsDestinations[number];

export const notificationsTypes = ['Instant', 'Postponed'] as const;
export type NotificationType = typeof notificationsTypes[number];
