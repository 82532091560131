import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, Form, FormInstance, List, message, Modal, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import collegeApi from '../../../../apis/CollegeApi';
import organizationUserApi from '../../../../apis/OrganizationUserApi';
import sportApi from '../../../../apis/SportApi';
import userApi from '../../../../apis/UserApi';
import CustomContext from '../../../../context/CustomContext';
import { College, FamilyMember, OrganizationUser, Sport } from '../../../../model/Entities';
import avatar from '../../../../resources/images/profile-placeholder.png';
import notificationService from '../../../../services/NotificationService';
import styles from './RecommendPlayerModal.module.scss';

class RecommendPlayerModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();
    readonly pageSize = 4;

    constructor(props: Props) {
        super(props);
        this.state = { users: [], displayMode: 'sportSelection', collegeSports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            const sports = await sportApi.listAll();
            const collegeSports = sports.filter((s) => this.props.college.coachSports?.includes(s.id!));

            this.setState({ collegeSports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    save = async () => {
        const { college } = this.props;
        const { user, sportId } = this.state;

        try {
            this.setState({ loading: `sending` });

            const values = await this.formRef.current?.validateFields();
            await collegeApi.recommendPlayer(college.id!, user?.userId!, sportId!, values.message);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    search = async (searchText?: string) => {
        const { sportId } = this.state;
        try {
            this.setState({ loading: 'loading' });

            let users: FamilyMember[] | OrganizationUser[] = [];
            if (this.context.auth?.authorities.includes('ROLE_ADMIN')) {
                const usersPage = searchText
                    ? await userApi.list(this.pageSize, 1, searchText, 'player', sportId)
                    : undefined;
                users = usersPage?.items ? usersPage.items : [];
            } else if (this.context.auth?.authorities.includes('ROLE_ORGANIZATION')) {
                const usersPage = searchText
                    ? await organizationUserApi.list(
                          this.pageSize,
                          1,
                          this.context.auth.organizationId!,
                          searchText,
                          sportId,
                      )
                    : undefined;
                users = usersPage?.items ? usersPage.items : [];
            }

            this.setState({ users, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeDisplayMode = (user?: FamilyMember) => {
        let displayMode: 'search' | 'recommend' | 'sportSelection' = 'sportSelection';
        if (this.state.displayMode === 'sportSelection') {
            displayMode = 'search';
            const users: FamilyMember[] | OrganizationUser[] = [];
            const searchText = undefined;
            this.setState({ users, user, displayMode, searchText });
        } else if (this.state.displayMode === 'search' && !user) {
            displayMode = 'sportSelection';
            this.setState({ user, displayMode });
        } else if (this.state.displayMode === 'search' && user) {
            displayMode = 'recommend';
            this.setState({ user, displayMode });
        } else if (this.state.displayMode === 'recommend') {
            displayMode = 'search';
            this.setState({ user, displayMode });
        }
    };

    changeSport = (sportId: number) => {
        this.setState({ sportId });
    };

    /*** COMPONENTS ***/

    renderSportList = (): React.ReactElement | undefined => {
        const { collegeSports } = this.state;

        const sportOptions = collegeSports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));

        return (
            <Select
                onSelect={this.changeSport}
                className={styles.sports}
                placeholder={`${this.props.intl.formatMessage({
                    id: 'collegeConnect.selectSport',
                })}`}
            >
                {sportOptions}
            </Select>
        );
    };

    renderExistingUserList = (): React.ReactElement | undefined => {
        const { users, loading, searchText } = this.state;
        return (
            <>
                <Search
                    placeholder={this.props.intl.formatMessage({ id: 'button.search' })}
                    className={styles.search}
                    enterButton={''}
                    allowClear={true}
                    onSearch={this.search}
                />
                {users.length === 0 && searchText ? (
                    <p className={styles.nofound}>
                        <FormattedMessage id="status.userNotFound" />
                    </p>
                ) : (
                    <List
                        grid={{
                            gutter: 0,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 1,
                            xl: 1,
                            xxl: 1,
                        }}
                        dataSource={users}
                        locale={{ emptyText: <></> }}
                        renderItem={(user) => (
                            <List.Item key={user.userId} className={styles.listItem}>
                                <div className={styles.userList}>
                                    <div className={styles.user}>
                                        <Avatar className={styles.avatar} size={64} src={user.photoUrl || avatar} />
                                        <div>
                                            <p>{`${user.givenName} ${user.familyName}`}</p>
                                            <p className={styles.email}>{user.email}</p>
                                        </div>
                                    </div>

                                    <Button
                                        className={styles.addUserButton}
                                        type="text"
                                        icon={<PlusOutlined />}
                                        onClick={() => this.changeDisplayMode(user)}
                                        loading={loading === `saving-${user.userId}`}
                                    >
                                        <FormattedMessage id="recommendPlayer.button.select" tagName="span" />
                                    </Button>
                                </div>
                                <Divider></Divider>
                            </List.Item>
                        )}
                        loading={loading === 'loading'}
                    />
                )}
            </>
        );
    };

    renderRecommendation = (): React.ReactElement | undefined => {
        const { user } = this.state;

        return (
            <>
                <p>
                    {user?.givenName} {user?.familyName}
                </p>
                <p>{user?.email}</p>

                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item label={<FormattedMessage id="recommendPlayer.editMessage" />} name="message">
                        <TextArea />
                    </Form.Item>
                </Form>
            </>
        );
    };

    render() {
        const { displayMode, loading, sportId } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="recommendPlayer.title" />}
                width={600}
                visible={true}
                onCancel={this.props.onCancel}
                footer={
                    displayMode === 'sportSelection' ? (
                        <Button
                            shape={'round'}
                            type="primary"
                            onClick={() => this.changeDisplayMode()}
                            disabled={sportId === undefined}
                        >
                            <FormattedMessage id="button.next" tagName="span" />
                        </Button>
                    ) : displayMode === 'recommend' ? (
                        [
                            <div>
                                <Button shape={'round'} onClick={() => this.changeDisplayMode()}>
                                    <FormattedMessage id="button.back" />
                                </Button>
                                <Button
                                    shape={'round'}
                                    type="primary"
                                    onClick={this.save}
                                    loading={loading === 'sending'}
                                >
                                    <FormattedMessage id="button.send" tagName="span" />
                                </Button>
                            </div>,
                        ]
                    ) : (
                        <Button shape={'round'} onClick={() => this.changeDisplayMode()}>
                            <FormattedMessage id="button.back" />
                        </Button>
                    )
                }
            >
                {<div hidden={displayMode !== 'sportSelection'}>{this.renderSportList()}</div>}
                {<div hidden={displayMode !== 'search'}>{this.renderExistingUserList()}</div>}
                {<div hidden={displayMode !== 'recommend'}>{this.renderRecommendation()}</div>}
            </Modal>
        );
    }
}
export default injectIntl(RecommendPlayerModal);

interface Props extends WrappedComponentProps {
    college: College;
    onUpdate: () => void;
    onCancel: () => void;
}

interface State {
    loading?: 'loading' | 'sending';
    users: FamilyMember[] | OrganizationUser[];
    searchText?: string;
    displayMode: 'sportSelection' | 'search' | 'recommend';
    user?: FamilyMember;
    sportId?: number;
    collegeSports: Sport[];
}
