import Icon from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Image, Input, message, Row, Select, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import collegeCoachApi from '../../../../apis/CollegeCoachApi';
import userApi from '../../../../apis/UserApi';
import CustomContext from '../../../../context/CustomContext';
import { CollegeCoach } from '../../../../model/Entities';
import { countries, CountryType } from '../../../../model/Types';
import { ReactComponent as saveSvg } from '../../../../resources/images/ico-save.svg';
import avatar from '../../../../resources/images/profile-placeholder.png';
import notificationService from '../../../../services/NotificationService';
import stateService from '../../../../services/StateService';
import styles from './CollegeCoachAccountEditComponent.module.scss';

class CollegeCoachAccountEditComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRefAccount = React.createRef<FormInstance>();
    formRefEmail = React.createRef<FormInstance>();
    formRefUsername = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { collegeCoach } = this.props;

        try {
            // get country
            let country = collegeCoach.country;
            if (country && !countries.includes(country)) {
                country = undefined;
            }
            const photo = collegeCoach.photoUrl;
            this.setState({ country, photo });

            // initialize forms
            const values: any = Object.assign({}, collegeCoach);
            values.birthday = moment.utc(values.birthday).local();
            values.country = country;
            this.formRefAccount.current!.setFieldsValue(values);
            this.formRefEmail.current!.setFieldsValue(values);
            this.formRefUsername.current!.setFieldsValue(values);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    save = async (values: any) => {
        const { collegeCoach, onSave, userId } = this.props;
        const { file } = this.state;
        try {
            this.setState({ loading: 'saving' });
            let updatedCollegeCoachAccount: CollegeCoach = Object.assign({}, collegeCoach, values);
            updatedCollegeCoachAccount.sportId = this.context.auth?.colleges?.find(Boolean)?.sportId;
            await collegeCoachApi.update(this.context.auth?.colleges?.find(Boolean)?.id!, updatedCollegeCoachAccount);

            // upload photo
            if (file) {
                await userApi.updatePhoto(userId, file as File);
            }
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            onSave(updatedCollegeCoachAccount);
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'user.status.duplicate' },
            ]);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    saveEmail = async (values: any) => {
        const { userId, collegeCoach, onSave } = this.props;
        try {
            this.setState({ loading: 'savingEmail' });
            await userApi.updateEmail(userId, values.email!);
            const updatedCollegeCoachAccount: CollegeCoach = Object.assign({}, collegeCoach, { email: values.email });
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            onSave(updatedCollegeCoachAccount);
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'status.duplicatedEmail' },
            ]);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    saveUsername = async (values: any) => {
        const { userId, collegeCoach, onSave } = this.props;
        try {
            this.setState({ loading: 'savingUsername' });
            await userApi.updateUsername(userId, values.userName);
            const updatedCollegeCoachAccount: CollegeCoach = Object.assign({}, collegeCoach, {
                userName: values.userName,
            });
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            onSave(updatedCollegeCoachAccount);
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'status.duplicatedUsername' },
            ]);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    uploadPhoto = (file: UploadFile) => {
        this.getBase64(file, (photo: string) =>
            this.setState({
                photo,
                file,
            }),
        );
        return false;
    };

    getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    changeCountry = (country?: CountryType) => {
        this.formRefAccount.current?.resetFields(['state']);
        this.setState({ country });
    };

    /*** COMPONENTS ***/

    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderContent = (): React.ReactElement | undefined => {
        const { backUrl } = this.props;
        const { loading, photo } = this.state;
        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));

        return (
            <>
                <Form
                    ref={this.formRefEmail}
                    colon={false}
                    layout="vertical"
                    className={styles.form}
                    onFinish={this.saveEmail}
                >
                    <Row gutter={[28, 0]}>
                        <Col xs={18} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="parent.edit.email" />}
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: <FormattedMessage id="status.mandatory" />,
                                    },
                                    {
                                        type: 'email',
                                        message: <FormattedMessage id="status.email.invalid" />,
                                    },
                                ]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={6} md={12}>
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                icon={<Icon component={saveSvg} />}
                                className={styles.save}
                                loading={loading === 'savingEmail'}
                                htmlType="submit"
                            >
                                <FormattedMessage id="parent.edit.buttonEmail" tagName="span" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Form
                    ref={this.formRefUsername}
                    colon={false}
                    layout="vertical"
                    className={styles.form}
                    onFinish={this.saveUsername}
                >
                    <Row gutter={[28, 0]}>
                        <Col xs={18} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="parent.edit.username" />}
                                name="userName"
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    {
                                        pattern: /^[a-z0-9_.-]{4,32}$/,
                                        message: <FormattedMessage id="status.characters" />,
                                    },
                                ]}
                            >
                                <Input maxLength={32} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={6} md={12}>
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                icon={<Icon component={saveSvg} />}
                                className={styles.save}
                                loading={loading === 'savingUsername'}
                                htmlType="submit"
                            >
                                <FormattedMessage id="parent.edit.buttonUsername" tagName="span" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={[40, 0]}>
                    <Col xs={24} lg={12}>
                        <div className={styles.photo}>
                            <div className={styles.picture}>
                                <div className={styles.image}>
                                    <Image src={photo || avatar} preview={false} />
                                </div>
                                <Upload.Dragger
                                    beforeUpload={this.uploadPhoto}
                                    showUploadList={false}
                                    className={styles.upload}
                                >
                                    <FormattedMessage id="parent.edit.updatePhoto" />
                                </Upload.Dragger>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Form
                    ref={this.formRefAccount}
                    colon={false}
                    layout="vertical"
                    className={styles.form}
                    onFinish={this.save}
                >
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="parent.edit.firstName" />}
                                name="givenName"
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    {
                                        pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                        message: <FormattedMessage id="status.name" />,
                                    },
                                ]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="parent.edit.lastName" />}
                                name="familyName"
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    {
                                        pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                        message: <FormattedMessage id="status.name" />,
                                    },
                                ]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="parent.edit.phone" />}
                                name="phone"
                                rules={[
                                    {
                                        pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                        message: <FormattedMessage id="status.phoneNumber" />,
                                    },
                                ]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={16}>
                            <Form.Item label={<FormattedMessage id="parent.edit.address" />} name="address">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="parent.edit.city" />} name="city">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="parent.edit.country" />} name="country">
                                <Select onChange={(value: CountryType) => this.changeCountry(value)}>
                                    {countryOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="parent.edit.state" />} name="state">
                                <Select>{this.renderStates()}</Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="parent.edit.zip" />} name="zipCode">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item className={styles.buttons}>
                        <Link to={backUrl}>
                            <Button type="text" size="large" className={styles.back}>
                                <FormattedMessage id="button.back" tagName="span" />
                            </Button>
                        </Link>
                        <Button
                            type="primary"
                            size="large"
                            icon={<Icon component={saveSvg} />}
                            className={styles.save}
                            loading={loading === 'saving'}
                            htmlType="submit"
                        >
                            <FormattedMessage id="button.save" tagName="span" />
                        </Button>
                    </Form.Item>
                </Form>
            </>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(CollegeCoachAccountEditComponent);

interface Props extends WrappedComponentProps {
    userId: string;
    collegeCoach: CollegeCoach;
    onSave: (collegeCoach: CollegeCoach) => void;
    backUrl: string;
}

interface State {
    loading?: 'saving' | 'savingEmail' | 'savingUsername';
    country?: CountryType;
    photo?: string;
    file?: any;
}
