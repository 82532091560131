import { Page } from '../model/Elements';
import { Award } from '../model/Entities';
import apiAxios from './CustomAxios';

class AwardApi {
    list = async (limit: number, page: number, sortField?: string, sortOrder?: string): Promise<Page<Award>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getSortField(sortField);
        const response = await apiAxios.get<Page<Award>>('/v1/awards', {
            params: { limit, page, [field]: order },
        });

        return response.data;
    };

    listAll = async (): Promise<Award[]> => {
        let page = 1;
        let total: number;
        let items: Award[] = [];
        do {
            const itemsPage = await this.list(500, page);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        return items;
    };

    get = async (awardId?: string): Promise<Award> => {
        const response = await apiAxios.get<Award>(`/v1/awards/${awardId}`);
        response.data.date = response.data.date ? response.data.date * 1000 : undefined;
        return response.data;
    };

    create = async (award: Award): Promise<Award> => {
        award.date = award.date ? Math.round(award.date / 1000) : undefined;
        const response = await apiAxios.post<Award>('/v1/awards', award);
        return response.data;
    };

    update = async (award: Award): Promise<Award> => {
        award.date = award.date ? Math.round(award.date / 1000) : undefined;
        const response = await apiAxios.put<Award>(`/v1/awards/${award.id}`, award);
        return response.data;
    };

    delete = async (award: Award): Promise<void> => {
        await apiAxios.delete(`/v1/awards/${award.id}`);
    };

    private getSortField(sortField?: string): string {
        let field: string = 'sorting.Name';
        return field;
    }
}
const awardApi: AwardApi = new AwardApi();
export default awardApi;
