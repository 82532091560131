import axios from 'axios';
import { Page } from '../model/Elements';
import { User, UserSport } from '../model/Entities';
import apiAxios from './CustomAxios';

class UserSportApi {
    list = async (userId: string): Promise<UserSport[]> => {
        const response = await apiAxios.get<Page<UserSport>>(`/v1/users/${userId}/sports`, {});
        return response.data.items;
    };

    update = async (user: User, userSport: UserSport): Promise<UserSport> => {
        const response = await apiAxios.put<UserSport>(`/v1/users/${user.id}/sports/${userSport.sportId}`, userSport);
        return response.data;
    };

    create = async (userId: string, userSport: UserSport): Promise<UserSport> => {
        const response = await apiAxios.post<UserSport>(`/v1/users/${userId}/sports`, userSport);
        return response.data;
    };

    delete = async (userId: string, userSportId: number) => {
        await apiAxios.delete(`/v1/users/${userId}/sports/${userSportId}`);
    };

    updatePhoto = async (userId: string, userSportId: number, file: File): Promise<string> => {
        // get s3 upload url
        const initialResponse = await apiAxios.get(`/v1/media/generate-presigned-profile-photo-url/${userId}`);
        const s3UploadUrl = initialResponse.data.url;

        // post photo to s3
        await axios.put(s3UploadUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });

        // confirm new user sport id
        await apiAxios.post<void>(`/v1/media/confirm_uploaded-profile-photo-by-presigned-url`, {
            userId,
            s3Link: s3UploadUrl,
            userSportId: userSportId,
        });

        return s3UploadUrl;
    };
}

const userSportApi: UserSportApi = new UserSportApi();
export default userSportApi;
