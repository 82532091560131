import { Page } from '../model/Elements';
import {
    ScheduledNotification,
    CollegeNotification,
    EventNotification,
    Notification,
    OrganizationNotification,
} from '../model/Entities';
import dateService from '../services/DateService';
import apiAxios from './CustomAxios';

class NotificationApi {
    list = async (
        limit: number,
        page: number,
        search?: string,
        all?: boolean,
        organizationId?: string,
    ): Promise<Page<ScheduledNotification>> => {
        const filter = this.getFilter(organizationId, all);
        const response = await apiAxios.get<Page<ScheduledNotification>>('/v1/notifications', {
            params: { limit, page, search, ...filter },
        });

        response.data.items.forEach((i) => [
            (i.sendTime = i.sendTime ? dateService.toLocal(i.sendTime * 1000) : undefined),
        ]);

        return response.data;
    };

    listAll = async (): Promise<ScheduledNotification[]> => {
        let page = 1;
        let total: number;
        let items: ScheduledNotification[] = [];
        do {
            const itemsPage = await this.list(500, page);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        return items;
    };

    listHistory = async (
        limit: number,
        page: number,
        search?: string,
        all?: boolean,
        organizationId?: string,
    ): Promise<Page<ScheduledNotification>> => {
        const filter = this.getFilter(organizationId, all);
        const response = await apiAxios.get<Page<ScheduledNotification>>('/v1/notifications/history', {
            params: { limit, page, search, ...filter },
        });

        response.data.items.forEach((i) => [
            (i.sendTime = i.sendTime ? dateService.toLocal(i.sendTime * 1000) : undefined),
        ]);

        return response.data;
    };

    createNotification = async (notification: Notification): Promise<void> => {
        notification.sendTime = notification.sendTime
            ? Math.floor(dateService.toUtc(notification.sendTime) / 1000)
            : undefined;
        const response = await apiAxios.post<void>('/v1/notifications', notification);
        return response.data;
    };

    createOrganizationNotification = async (notification: OrganizationNotification): Promise<void> => {
        notification.sendTime = notification.sendTime
            ? Math.floor(dateService.toUtc(notification.sendTime) / 1000)
            : undefined;
        const response = await apiAxios.post<void>('/v1/notifications/organizations', notification);
        return response.data;
    };

    createEventNotification = async (eventId: number, notification: EventNotification): Promise<void> => {
        notification.sendTime = notification.sendTime
            ? Math.floor(dateService.toUtc(notification.sendTime) / 1000)
            : undefined;
        const response = await apiAxios.post<void>(`/v1/notifications/events/${eventId}`, notification);
        return response.data;
    };

    createCollegeNotification = async (notification: CollegeNotification): Promise<void> => {
        notification.sendTime = notification.sendTime
            ? Math.floor(dateService.toUtc(notification.sendTime) / 1000)
            : undefined;
        const response = await apiAxios.post<void>('/v1/notifications/colleges', notification);
        return response.data;
    };

    delete = async (notification: ScheduledNotification): Promise<void> => {
        await apiAxios.delete(`/v1/notifications/${notification.id}`);
    };

    private getFilter(organizationId?: string, all?: boolean) {
        let fields: any = {};

        if (organizationId) {
            fields['filtering.OrganizationId'] = organizationId;
        }

        if (all) {
            fields['filtering.All'] = all;
        }

        return fields;
    }
}

const notificationApi: NotificationApi = new NotificationApi();
export default notificationApi;
