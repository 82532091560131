import Icon, { CheckCircleFilled, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Divider, Menu, message, Modal, Steps, Table, Upload } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import axios from 'axios';
import FileSaver from 'file-saver';
import React, { Component, Key } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import importCSVApi from '../../../../../apis/ImportCSVApi';
import userApi from '../../../../../apis/UserApi';
import LayoutComponent from '../../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../../context/CustomContext';
import { ImportedPlayer, ImportedPlayers, Organization, Sport } from '../../../../../model/Entities';
import { ReactComponent as dashboardSvg } from '../../../../../resources/images/ico-dashboard.svg';
import { ReactComponent as uploadSvg } from '../../../../../resources/images/ico-uploadCSV.svg';
import HeadMetadata from '../../../../../services/HeadMetadata';
import notificationService from '../../../../../services/NotificationService';
import urlService from '../../../../../services/ParamService';
import styles from './ImportUsersPage.module.scss';

class ImportUsersPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            step: 0,
            selectedRowKeys: [],
            userIds: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    /**
     * Initialiazes the component.
     */
    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            // get parameters and align url
            const params = new URLSearchParams(this.props.location.search);
            const sportId = urlService.getParamAsNumber(params, 'sportId');

            this.setState({ sportId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /**
     * Uploads a file.
     * @param file - the uploaded file
     */
    upload = (file: any) => {
        const fileAux = file as File;
        // If the file has the correct extension, we save it in the state, else, we display an error
        if (fileAux.name.endsWith('.xlsx')) {
            this.setState({ file: fileAux });
        } else {
            message.error(this.props.intl.formatMessage({ id: 'status.invalidDocumentExtension' }), 6);
            this.setState({ file: undefined });
        }

        return false;
    };

    /**
     * Saves the users.
     */
    save = async () => {
        const { sportId, file } = this.state;
        try {
            this.setState({ loading: 'saving' });

            const importedUsers: ImportedPlayers = await importCSVApi.import(
                this.props.match.params.organizationId,
                sportId!,
                file!,
            );

            if (importedUsers.filePath) {
                this.setState({ downloadReportModalVisible: true });
            }

            // show summary in page 2 (users list with team and division)
            const step = 1;
            const updateSelectedRowKeys = importedUsers.players ? importedUsers.players.map((p) => p.userId) : [];

            this.setState({ step, importedUsers, selectedRowKeys: updateSelectedRowKeys });
        } catch (error: any) {
            if (error.response.status === 400 && error.response.data) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 400, message: error.response.data.message as string },
                ]);
            } else {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    sendInvites = async () => {
        const { importedUsers, selectedRowKeys, userIds } = this.state;
        try {
            this.setState({ loading: 'sendingInvites' });

            const userIdsToUse = importedUsers?.players
                ?.filter((u) => selectedRowKeys.includes(u.userId))
                .flatMap((u) => u.userId!);

            const newUserIds = userIdsToUse!.filter((u) => !userIds.includes(u));
            const oldUserIds = userIdsToUse!.filter((u) => userIds.includes(u));

            newUserIds.length > 0 && (await userApi.sendInvites(newUserIds));
            oldUserIds.length > 0 && (await userApi.sendReInvites(oldUserIds));

            const updatedUserIds: string[] = userIds;
            newUserIds.forEach((ne) => !userIds.includes(ne) && updatedUserIds.push(ne));

            this.showSendInvitesModal(false);

            this.setState({ userIds: updatedUserIds });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    goToFirstStep = () => {
        const step = 0;
        const selectedRowKeys: Key[] = [];
        const userIds: string[] = [];
        const file = undefined;
        this.setState({ step, selectedRowKeys, userIds, file });
    };

    select = (selectedRowKeys: Key[]) => {
        this.setState({ selectedRowKeys });
    };

    showSendInvitesModal = (sendInvitesModalVisible: boolean) => {
        this.setState({ sendInvitesModalVisible });
    };

    downloadFile = async (fileUrl: string) => {
        try {
            this.setState({ loading: 'downloading' });
            const response = await axios.get<Blob>(fileUrl, {
                responseType: 'blob',
            });
            const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1) || 'Report.xlsx';

            FileSaver.saveAs(response.data, fileName);

            this.setState({ downloadReportModalVisible: false });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (): React.ReactNode | undefined => {
        const { step, selectedRowKeys } = this.state;
        return (
            <span className={styles.title}>
                <FormattedMessage id="rosters.roster.manageRoster.import.title" />
                <a
                    href={`${process.env.PUBLIC_URL}/templates/import-users-template.xlsx`}
                    download={this.props.intl.formatMessage({
                        id: 'rosters.roster.manageRoster.import.file',
                    })}
                    className={styles.link}
                >
                    <Button className={styles.template} size="large" icon={<DownloadOutlined />}>
                        <span>
                            <FormattedMessage id="rosters.roster.manageRoster.import.csvTemplate" />
                        </span>
                    </Button>
                </a>
                {step === 1 && (
                    <Button
                        className={styles.another}
                        size="large"
                        icon={<UploadOutlined />}
                        onClick={this.goToFirstStep}
                    >
                        <FormattedMessage id="rosters.roster.manageRoster.import.another" tagName="span" />
                    </Button>
                )}
                {step === 1 && (
                    <Button
                        className={styles.inviteSelected}
                        size="large"
                        onClick={() => this.showSendInvitesModal(true)}
                        disabled={selectedRowKeys.length === 0}
                    >
                        <FormattedMessage id="rosters.roster.manageRoster.import.inviteSelected" tagName="span" />
                    </Button>
                )}
            </span>
        );
    };

    renderSendInvitesModal = (): React.ReactElement | undefined => {
        const { sendInvitesModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="rosters.roster.manageRoster.import.modal.title" />}
                visible={sendInvitesModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.sendInvites}
                okButtonProps={{ loading: loading === 'sendingInvites' }}
                onCancel={() => this.showSendInvitesModal(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="rosters.roster.manageRoster.import.modal.description" />
                </p>
            </Modal>
        );
    };

    renderDownloadReportModal = (): React.ReactElement | undefined => {
        const { downloadReportModalVisible, importedUsers, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id={'rosters.roster.manageRoster.import.modalReport.title'} />}
                visible={downloadReportModalVisible}
                closable={false}
                footer={[
                    <Button
                        key="download"
                        shape={'round'}
                        type="primary"
                        loading={loading === 'downloading'}
                        onClick={() => this.downloadFile(importedUsers?.filePath!)}
                    >
                        <FormattedMessage id="button.downloadReport" tagName="span" />
                    </Button>,
                ]}
            >
                <FormattedMessage
                    id={'rosters.roster.manageRoster.import.modalReport.description'}
                    values={{ values: importedUsers?.failed }}
                />
            </Modal>
        );
    };

    renderMenu = (): React.ReactNode | undefined => {
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="events-dashboard"
                        icon={<Icon component={dashboardSvg} />}
                        onClick={() => this.props.history.push('/')}
                    >
                        <Link to="/">
                            <FormattedMessage id="navigation.admin.dashboard" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider></Divider>
                <Menu.ItemGroup title={<FormattedMessage id="rosters.roster.manageRoster.import.import" />}>
                    {this.renderSteps()}
                </Menu.ItemGroup>
            </>
        );
    };

    renderSteps = (): React.ReactNode | undefined => {
        const { step } = this.state;
        return (
            <Steps className={styles.steps} direction="vertical" size="small" current={step}>
                <Steps.Step key={0} title={<FormattedMessage id="rosters.roster.manageRoster.import.load" />} />
                <Steps.Step key={1} title={<FormattedMessage id="rosters.roster.manageRoster.import.result" />} />
            </Steps>
        );
    };

    renderUploader = (): React.ReactNode | undefined => {
        const { loading, file } = this.state;
        return (
            <>
                <div className={styles.dragger}>
                    <Upload.Dragger
                        accept=".xlsx"
                        showUploadList={!!file}
                        beforeUpload={(file) => this.upload(file)}
                        maxCount={1}
                    >
                        <Button icon={<Icon component={uploadSvg} />}>
                            <FormattedMessage id="button.uploadCSV" />
                        </Button>
                    </Upload.Dragger>
                </div>
                <div className={styles.buttons}>
                    <Button
                        className={styles.next}
                        size="large"
                        type="primary"
                        onClick={this.save}
                        loading={loading === 'saving'}
                        disabled={!file}
                    >
                        <FormattedMessage id="button.next" tagName="span" />
                    </Button>
                </div>
            </>
        );
    };

    renderIsPlayerInvited = (id: string): React.ReactNode | undefined => {
        const { userIds } = this.state;
        if (userIds.includes(id)) {
            return (
                <>
                    <FormattedMessage id="importUsers.invited" /> <CheckCircleFilled />
                </>
            );
        } else {
            return <></>;
        }
    };

    renderResult = (): React.ReactNode | undefined => {
        const { importedUsers, selectedRowKeys, loading, sportId, userIds } = this.state;
        const importedUsersResult = importedUsers?.players ? importedUsers?.players : [];

        const columns: ColumnsType<ImportedPlayer> = [
            {
                title: <FormattedMessage id="importUsers.firstName" />,
                dataIndex: 'firstName',
                key: 'firstName',
                width: 100,
                render: (value: string, user: ImportedPlayer) => (
                    <Link target="_blank" rel="noreferrer" to={`/players/${user.userId}/profile?sportId=${sportId}`}>
                        {value}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="importUsers.lastName" />,
                dataIndex: 'lastName',
                key: 'lastName',
                width: 100,
                render: (value: string, user: ImportedPlayer) => (
                    <Link target="_blank" rel="noreferrer" to={`/players/${user.userId}/profile?sportId=${sportId}`}>
                        {value}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="importUsers.email" />,
                dataIndex: 'email',
                key: 'email',
                width: 200,
                render: (value: string, user: ImportedPlayer) => (
                    <Link target="_blank" rel="noreferrer" to={`/players/${user.userId}/profile?sportId=${sportId}`}>
                        {value}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="importUsers.division" />,
                dataIndex: 'division',
                key: 'division',
                width: 100,
                render: (value: string, user: ImportedPlayer) => (
                    <Link target="_blank" rel="noreferrer" to={`/players/${user.userId}/profile?sportId=${sportId}`}>
                        {value}
                    </Link>
                ),
            },
            {
                width: 40,
                render: (user: ImportedPlayer) => this.renderIsPlayerInvited(user.userId!),
            },
            {
                width: 40,
                render: () => (
                    <>
                        <FormattedMessage id="importUsers.imported" /> <CheckCircleFilled />
                    </>
                ),
            },
        ];

        const rowSelection: TableRowSelection<ImportedPlayer> = {
            preserveSelectedRowKeys: true,
            selectedRowKeys,
            onChange: this.select,
        };

        return (
            <>
                <header>
                    {importedUsersResult.length > 0 && (
                        <span className={styles.importedUsers}>
                            {<FormattedMessage id="rosters.roster.manageRoster.import.importedUsersFirst" />}{' '}
                            {importedUsersResult.length}{' '}
                            {<FormattedMessage id="rosters.roster.manageRoster.import.importedUsersSecond" />}
                        </span>
                    )}

                    <span className={styles.importedUsers} hidden={userIds.length === 0}>
                        {<FormattedMessage id="rosters.roster.manageRoster.import.invitedUsersFirst" />}{' '}
                        {userIds.length}{' '}
                        {<FormattedMessage id="rosters.roster.manageRoster.import.invitedUsersSecond" />}
                    </span>

                    {importedUsers?.addedInAffiliate !== undefined && importedUsers?.addedInAffiliate > 0 && (
                        <div className={styles.importedUsers}>
                            {importedUsers?.addedInAffiliate}{' '}
                            <FormattedMessage id="rosters.roster.manageRoster.import.existingPlayersAdded" />
                        </div>
                    )}

                    {importedUsers?.alreadyInAffiliate !== undefined && importedUsers?.alreadyInAffiliate > 0 && (
                        <div className={styles.alreadyExist}>
                            {importedUsers?.alreadyInAffiliate}{' '}
                            <FormattedMessage id="rosters.roster.manageRoster.import.alreadyExist" />
                        </div>
                    )}

                    <span className={styles.playersSelected}>
                        {selectedRowKeys.length}{' '}
                        {<FormattedMessage id="rosters.roster.manageRoster.import.playersSelectedToInvite" />}
                    </span>
                </header>
                <Table
                    dataSource={importedUsersResult}
                    columns={columns}
                    pagination={false}
                    rowKey="userId"
                    loading={loading === 'loading'}
                    rowSelection={rowSelection}
                    className={styles.table}
                />
            </>
        );
    };

    renderContent = (): React.ReactNode | undefined => {
        const { step } = this.state;
        return (
            <>
                <div hidden={step !== 0}>{this.renderUploader()}</div>
                <div hidden={step !== 1}>{this.renderResult()}</div>
                {this.renderSendInvitesModal()}
                {this.renderDownloadReportModal()}
            </>
        );
    };

    render() {
        const { loading } = this.state;
        return (
            <div>
                <HeadMetadata titleKey="rosters.roster.manageRoster.import.meta.title" />
                <LayoutComponent
                    page="import-users"
                    header={this.renderHeader}
                    menu={this.renderMenu}
                    content={this.renderContent}
                    loading={loading === 'initializing'}
                />
            </div>
        );
    }
}
export default injectIntl(ImportUsersPage);

type ParamsType = { organizationId: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    organization?: Organization;
    sportId?: number;
    sport?: Sport;
    loading?: 'initializing' | 'loading' | 'saving' | 'sendingInvites' | 'downloading';
    step: number;
    importedUsers?: ImportedPlayers;
    selectedRowKeys: Key[];
    sendInvitesModalVisible?: boolean;
    userIds: string[];
    file?: File;
    downloadReportModalVisible?: boolean;
}
