import { FilterOutlined } from '@ant-design/icons/lib/icons';
import { Button, Checkbox, Drawer, Form, FormInstance, Image, List, Menu, Row, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import eventApi from '../../apis/EventApi';
import sportApi from '../../apis/SportApi';
import EventsNavigationComponent from '../../components/EventsNavigationComponent/EventsNavigationComponent';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Page } from '../../model/Elements';
import { Event, EventFilters, Sport } from '../../model/Entities';
import { EventStatusType, PageType } from '../../model/Types';
import logo from '../../resources/images/top100-logo.png';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import styles from './EventsPage.module.scss';

class EventsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();
    readonly pageSize = 20;

    constructor(props: Props) {
        super(props);
        this.state = { events: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            let eventStateFilter;
            if (this.props.type === 'Upcoming') {
                eventStateFilter = 'Upcoming';
            } else if (this.props.type === 'Completed') {
                eventStateFilter = 'Completed';
            } else {
                eventStateFilter = 'Live';
            }
            const responses = await Promise.all([
                eventApi.list(
                    this.pageSize,
                    1,
                    'startDate',
                    eventStateFilter === 'Completed' ? 'descend' : 'ascend',
                    eventStateFilter,
                ),
                sportApi.listAll(),
                eventApi.listFilters(),
            ]);
            const eventsPage = responses[0];
            const sports = responses[1];
            const events = eventsPage ? eventsPage.items : [];
            const eventFilters = responses[2];
            this.setState({ eventsPage, sports, events, eventFilters, eventStateFilter });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async () => {
        const {
            eventsPage,
            events,
            eventStateFilter,
            sportFilter,
            mondayFilter,
            tuesdayFilter,
            wednesdayFilter,
            thursdayFilter,
            fridayFilter,
            saturdayFilter,
            sundayFilter,
            locationFilter,
            seasonFilter,
            levelFilter,
        } = this.state;
        try {
            this.setState({ loading: true });
            const page = eventsPage?.currentPage! + 1;
            const eventsPageAux = await eventApi.list(
                this.pageSize,
                page,
                'startDate',
                eventStateFilter === 'Completed' ? 'descend' : 'ascend',
                eventStateFilter,
                sportFilter,
                mondayFilter,
                tuesdayFilter,
                wednesdayFilter,
                thursdayFilter,
                fridayFilter,
                saturdayFilter,
                sundayFilter,
                locationFilter,
                seasonFilter,
                levelFilter,
            );

            const eventsAux = eventsPageAux ? events.concat(eventsPageAux.items) : [];

            this.setState({ eventsPage: eventsPageAux, events: eventsAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    filter = async (values: any) => {
        const { eventStateFilter } = this.state;
        try {
            this.setState({ loading: true });
            const eventsPage = await eventApi.list(
                this.pageSize,
                1,
                'startDate',
                eventStateFilter === 'Completed' ? 'descend' : 'ascend',
                eventStateFilter,
                values.sport,
                values.mo,
                values.tu,
                values.we,
                values.th,
                values.fr,
                values.sa,
                values.su,
                values.location,
                values.season,
                values.level,
            );
            const events = eventsPage ? eventsPage.items : [];
            const sportFilter = values.sport;
            const mondayFilter = values.mo;
            const tuesdayFilter = values.tu;
            const wednesdayFilter = values.we;
            const thursdayFilter = values.th;
            const fridayFilter = values.fr;
            const saturdayFilter = values.sa;
            const sundayFilter = values.su;
            const locationFilter = values.location;
            const seasonFilter = values.season;
            const levelFilter = values.filter;
            this.setState({
                eventsPage,
                events,
                sportFilter,
                mondayFilter,
                tuesdayFilter,
                wednesdayFilter,
                thursdayFilter,
                fridayFilter,
                saturdayFilter,
                sundayFilter,
                locationFilter,
                seasonFilter,
                levelFilter,
                collapsed: false,
            });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    collapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { type } = this.props;
        const { collapsed } = this.state;

        if (desktop) {
            return (
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <header>
                        <h1>
                            {type === 'Upcoming' && <FormattedMessage id="player.events.upcomingEvents" />}
                            {type === 'Completed' && <FormattedMessage id="player.events.completedEvents" />}
                            {type === 'Live' && <FormattedMessage id="player.events.liveEvents" />}
                        </h1>
                        <p>
                            {type === 'Upcoming' && <FormattedMessage id="player.events.eventsDescription" />}
                            {type === 'Completed' && <FormattedMessage id="player.events.completedDescription" />}
                            {type === 'Live' && <FormattedMessage id="player.events.liveDescription" />}
                        </p>
                    </header>
                    {this.context.auth?.authorities.includes('ROLE_ADMIN') && type === 'Upcoming' && (
                        <Link to="/event-creation">
                            <Button size="large" className={styles.register} icon={<PlusOutlined />} shape="round">
                                <FormattedMessage id="player.events.createEvent" />
                            </Button>
                        </Link>
                    )}
                </div>
            );
        } else {
            return (
                <>
                    <Button
                        type="ghost"
                        icon={<FilterOutlined />}
                        onClick={() => this.collapse(true)}
                        hidden={type === 'Live'}
                    />
                    <Drawer
                        className={styles.mobileFilter}
                        placement="right"
                        onClose={() => this.collapse(false)}
                        visible={collapsed}
                    >
                        <Menu className={styles.menu}>{this.renderFilters()}</Menu>
                    </Drawer>
                </>
            );
        }
    };

    renderLoadMore = (): React.ReactElement | undefined => {
        const { loading, eventsPage, events } = this.state;
        return !loading && events.length < eventsPage?.totalItems! ? (
            <div className={styles.loadMore}>
                <Button onClick={this.list}>
                    <FormattedMessage id="button.loadMore" />
                </Button>
            </div>
        ) : (
            <></>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return this.renderDesktop();
        } else {
            return this.renderMobile();
        }
    };

    renderEventsButtons = (type: EventStatusType, event: Event): React.ReactElement | undefined => {
        if (type === 'Completed') {
            return (
                <Link to={`/events/${event.id}/summary`}>
                    <Button size="large" className={styles.register} shape="round">
                        <FormattedMessage id="player.events.details" />
                    </Button>
                </Link>
            );
        } else {
            return !this.context.auth || this.context.auth.authorities.includes('ROLE_PARENT') ? (
                <Link to={`/signup?eventId=${event.id}`}>
                    <Button size="large" type="primary" className={styles.register} shape="round">
                        <FormattedMessage id="player.events.register" />
                    </Button>
                </Link>
            ) : (
                <></>
            );
        }
    };

    renderFilters = (): React.ReactElement | undefined => {
        const { type } = this.props;
        const { sports, eventFilters } = this.state;

        if (type !== 'Live') {
            let sportOptions = sports?.map((sport) => (
                <Select.Option key={sport.id} value={sport.id!}>
                    {sport.name}
                </Select.Option>
            ));
            sports &&
                (sportOptions = [
                    <Select.Option key="" value="">
                        {<FormattedMessage id="player.events.sports" />}
                    </Select.Option>,
                    ...sportOptions!,
                ]);

            let seasonOptions = eventFilters?.seasons.map((season) => (
                <Select.Option key={season} value={season}>
                    {season}
                </Select.Option>
            ));
            eventFilters?.seasons &&
                (seasonOptions = [
                    <Select.Option key="" value="">
                        {<FormattedMessage id="player.events.seasons" />}
                    </Select.Option>,
                    ...seasonOptions!,
                ]);

            let locationOptions = eventFilters?.locations.sort().map((location) => (
                <Select.Option key={location} value={location}>
                    {location}
                </Select.Option>
            ));
            eventFilters?.locations &&
                (locationOptions = [
                    <Select.Option key="" value="">
                        {<FormattedMessage id="player.events.locations" />}
                    </Select.Option>,
                    ...locationOptions!,
                ]);

            let levelOptions = eventFilters?.levels.map((level) => (
                <Select.Option key={level} value={level}>
                    {level}
                </Select.Option>
            ));
            eventFilters?.levels &&
                (levelOptions = [
                    <Select.Option key="" value="">
                        {<FormattedMessage id="player.events.levels" />}
                    </Select.Option>,
                    ...levelOptions!,
                ]);

            return (
                <Form ref={this.formRef} onFinish={this.filter} colon={false} layout="vertical">
                    <div className={styles.subheader}>
                        <div className={styles.shcontent}>
                            <div className={styles.flex}>
                                <Form.Item name="sport">
                                    <Select
                                        className={styles.select}
                                        placeholder={<FormattedMessage id="player.events.sports" />}
                                        style={{ width: 130 }}
                                    >
                                        {sportOptions}
                                    </Select>
                                </Form.Item>
                                <Form.Item name="season">
                                    <Select
                                        className={styles.select}
                                        placeholder={<FormattedMessage id="player.events.seasons" />}
                                        style={{ width: 150 }}
                                    >
                                        {seasonOptions}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className={styles.days}>
                                <Form.Item name="su" valuePropName="checked">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.su" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="mo" valuePropName="checked">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.mo" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="tu" valuePropName="checked">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.tu" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="we" valuePropName="checked">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.we" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="th" valuePropName="checked">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.th" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="fr" valuePropName="checked">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.fr" />
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item name="sa" valuePropName="checked">
                                    <Checkbox>
                                        <FormattedMessage id="player.events.day.sa" />
                                    </Checkbox>
                                </Form.Item>
                            </div>

                            <div className={styles.flex}>
                                <Form.Item name="location">
                                    <Select
                                        className={styles.select}
                                        placeholder={<FormattedMessage id="player.events.locations" />}
                                        style={{ width: 160 }}
                                    >
                                        {locationOptions}
                                    </Select>
                                </Form.Item>

                                <Form.Item name="level">
                                    <Select
                                        className={styles.select}
                                        placeholder={<FormattedMessage id="player.events.levels" />}
                                        style={{ width: 130 }}
                                    >
                                        {levelOptions}
                                    </Select>
                                </Form.Item>
                            </div>

                            <Form.Item name="filter">
                                <Button
                                    shape={'round'}
                                    type="primary"
                                    size={'large'}
                                    htmlType="submit"
                                    className={styles.button}
                                >
                                    <FormattedMessage id="player.events.filter" />
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            );
        }
    };

    renderDesktop = (): React.ReactElement | undefined => {
        const { loading, sports, events } = this.state;
        const { type } = this.props;

        return (
            <>
                {this.renderFilters()}
                <List
                    className={type === 'Completed' ? styles.completedEvents : styles.eventsList}
                    dataSource={events}
                    loadMore={this.renderLoadMore()}
                    renderItem={(event) => (
                        <List.Item key={event.id}>
                            <List.Item.Meta
                                avatar={
                                    <div className={styles.details}>
                                        <div className={styles.address}>
                                            <Image
                                                src={event.organizationLogo || logo}
                                                fallback={logo}
                                                preview={false}
                                                className={styles.orgLogo}
                                            />
                                        </div>
                                        <div className={styles.date}>
                                            <div>
                                                <FormattedDate value={event.startDate} day="2-digit" />
                                            </div>
                                            <div>
                                                <p>
                                                    <FormattedDate value={event.startDate} month="short" />
                                                </p>
                                                <p>
                                                    <FormattedDate value={event.startDate} weekday="short" />,{' '}
                                                    <FormattedDate
                                                        value={event.startDate}
                                                        hour="2-digit"
                                                        minute="2-digit"
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                className={styles.event}
                                title={
                                    <div className={styles.eventTitle}>
                                        <div className={styles.category}>
                                            <img
                                                src={sports?.filter((s) => s.id === event.sportId)[0].iconUrl}
                                                alt={sports?.filter((s) => s.id === event.sportId)[0].iconUrl}
                                            ></img>
                                            {sports?.filter((s) => s.id === event.sportId)[0].name}
                                        </div>
                                        <Link to={`/events/${event.id}/summary`}>{event.name}</Link>
                                    </div>
                                }
                                description={
                                    <>
                                        <Row className={styles.eventDescription}>
                                            <div className={styles.eventFlex}>
                                                <Link
                                                    className={styles.arrow}
                                                    to={`/events/${event.id}/summary`}
                                                ></Link>
                                                <List.Item.Meta
                                                    title={<FormattedMessage id="player.events.generalFees" />}
                                                    description={
                                                        event.registrationTypes?.find((r) => r.type === 'Global')
                                                            ?.price &&
                                                        event.registrationTypes?.find((r) => r.type === 'Global')
                                                            ?.price! > 0 ? (
                                                            event.registrationTypes?.find((r) => r.type === 'Global')
                                                                ?.price
                                                        ) : (
                                                            <FormattedMessage id="player.events.free" />
                                                        )
                                                    }
                                                />
                                                {event.registrationTypes?.find((r) => r.type === 'Catching') && (
                                                    <List.Item.Meta
                                                        title={<FormattedMessage id="player.events.catchingFees" />}
                                                        description={
                                                            event.registrationTypes?.find((r) => r.type === 'Catching')
                                                                ?.price &&
                                                            event.registrationTypes?.find((r) => r.type === 'Catching')
                                                                ?.price! > 0 ? (
                                                                event.registrationTypes?.find(
                                                                    (r) => r.type === 'Catching',
                                                                )?.price
                                                            ) : (
                                                                <FormattedMessage id="player.events.free" />
                                                            )
                                                        }
                                                    />
                                                )}
                                                {event.registrationTypes?.find((r) => r.type === 'Pitching') && (
                                                    <List.Item.Meta
                                                        title={<FormattedMessage id="player.events.pitchingFees" />}
                                                        description={
                                                            event.registrationTypes?.find((r) => r.type === 'Pitching')
                                                                ?.price &&
                                                            event.registrationTypes?.find((r) => r.type === 'Pitching')
                                                                ?.price! > 0 ? (
                                                                event.registrationTypes?.find(
                                                                    (r) => r.type === 'Pitching',
                                                                )?.price
                                                            ) : (
                                                                <FormattedMessage id="player.events.free" />
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>
                                            {this.renderEventsButtons(this.props.type, event)}
                                        </Row>
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                    loading={loading}
                />
            </>
        );
    };

    renderMobileToolbar = (): React.ReactElement | undefined => {
        const { type } = this.props;

        return (
            <Form ref={this.formRef} colon={false} layout="vertical" className={styles.topNavi}>
                <div className={styles.buttonDiv}>
                    <Link to={`/events`}>
                        <Button
                            size="large"
                            shape="round"
                            className={`${styles.events} ${type === 'Upcoming' && styles.selected}`}
                        >
                            <FormattedMessage id="events.events" />
                        </Button>
                    </Link>
                </div>
                <div className={styles.buttonDiv}>
                    <Link to={`/events/completed`}>
                        <Button
                            size="large"
                            shape="round"
                            className={`${styles.tasks} ${type === 'Completed' && styles.selected}`}
                        >
                            <FormattedMessage id="events.completed" />
                        </Button>
                    </Link>
                </div>
                <div className={styles.buttonDiv}>
                    <Link to={`/events/live`}>
                        <Button
                            size="large"
                            shape="round"
                            className={`${styles.lives} ${type === 'Live' && styles.selected}`}
                        >
                            <FormattedMessage id="events.live" />
                        </Button>
                    </Link>
                </div>
            </Form>
        );
    };

    renderMobile = (): React.ReactElement | undefined => {
        const { loading, events, sports } = this.state;

        return (
            <>
                <div className={styles.mobileHeaderBackground}>
                    <div className={styles.headerBackgroundGradient}></div>
                    <div className={styles.headerBackgroundImage}></div>
                </div>
                {this.renderMobileToolbar()}
                <List
                    className={styles.eventsListMobile}
                    dataSource={events}
                    loadMore={this.renderLoadMore()}
                    renderItem={(event) => (
                        <List.Item key={event.id} className={styles.event}>
                            <List.Item.Meta
                                description={
                                    <div className={styles.headerFlex}>
                                        <div className={styles.date}>
                                            <div>
                                                <FormattedDate value={event?.startDate} day="2-digit" />
                                            </div>
                                            <div>
                                                <p>
                                                    <FormattedDate value={event?.startDate} month="short" />
                                                </p>
                                                <p>
                                                    <FormattedDate value={event?.startDate} weekday="short" />,{' '}
                                                    <FormattedDate
                                                        value={event?.startDate}
                                                        hour="2-digit"
                                                        minute="2-digit"
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className={styles.logo}>
                                            <Image
                                                src={event?.organizationLogo || logo}
                                                fallback={logo}
                                                preview={false}
                                                className={styles.orgLogoMobile}
                                            />
                                        </div>
                                    </div>
                                }
                            />
                            <List.Item.Meta
                                className={styles.eventDetails}
                                title={
                                    <div className={styles.eventTitle}>
                                        <div className={styles.category}>
                                            <img
                                                src={sports?.filter((s) => s.id === event.sportId)[0].iconUrl}
                                                alt={sports?.filter((s) => s.id === event.sportId)[0].iconUrl}
                                            ></img>
                                            {sports?.filter((s) => s.id === event.sportId)[0].name}
                                        </div>
                                        <Link to={`/events/${event.id}/summary`}>{event.name}</Link>
                                        <div>{this.renderEventsButtons(this.props.type, event)}</div>
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                    loading={loading}
                />
            </>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        return <EventsNavigationComponent collapsed={collapsed} />;
    };

    render() {
        const eventPageType = `events-${this.props.type}`.toLowerCase() as PageType;
        return (
            <>
                <HeadMetadata titleKey="events.meta.title" />
                <LayoutComponent
                    page={eventPageType}
                    menu={this.renderMenu}
                    content={this.renderContent}
                    header={this.renderHeader}
                />
            </>
        );
    }
}
export default injectIntl(EventsPage);

interface Props extends WrappedComponentProps {
    type: EventStatusType;
}

interface State {
    eventsPage?: Page<Event>;
    events: Event[];
    sports?: Sport[];
    eventFilters?: EventFilters;
    eventStateFilter?: string;
    sportFilter?: any;
    mondayFilter?: any;
    tuesdayFilter?: any;
    wednesdayFilter?: any;
    thursdayFilter?: any;
    fridayFilter?: any;
    saturdayFilter?: any;
    sundayFilter?: any;
    locationFilter?: any;
    seasonFilter?: any;
    levelFilter?: any;
    collapsed?: boolean;
    loading?: boolean;
    searchText?: string;
}
