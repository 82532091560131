import { FilterOutlined } from '@ant-design/icons';
import { Button, Card, Drawer, List, Menu, PageHeader, Select, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import organizationApi from '../../apis/OrganizationApi';
import sportApi from '../../apis/SportApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Page } from '../../model/Elements';
import { Organization, Sport } from '../../model/Entities';
import logo from '../../resources/images/top100-logo.png';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import rolesService from '../../services/RolesService';
import stringService from '../../services/StringService';
import styles from './OrganizationsPage.module.scss';

class OrganizationsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;

    constructor(props: Props) {
        super(props);
        this.state = {
            organizations: [],
            sports: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { auth } = this.context;
        try {
            this.setState({ loading: 'loading' });

            let responses;
            if (rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
                responses = await Promise.all([organizationApi.list(this.pageSize, 1), sportApi.listAll()]);

                const organizationsPage = responses[0];
                const organizations = organizationsPage.items ? organizationsPage.items : [];
                const sports = responses[1].sort((a, b) => stringService.sort(a.name, b.name));

                this.setState({ organizationsPage, organizations, sports });
            } else if (rolesService.hasAnyRole(auth, ['ROLE_COLLEGE_COACH'])) {
                const sportId = auth?.colleges?.find(Boolean)?.sportId;
                responses = await Promise.all([
                    organizationApi.list(this.pageSize, 1, undefined, undefined, sportId ? [sportId] : undefined),
                    sportApi.listAll(),
                ]);

                const organizationsPage = responses[0];
                const organizations = organizationsPage.items ? organizationsPage.items : [];
                const sports = responses[1].sort((a, b) => stringService.sort(a.name, b.name));

                this.setState({ organizationsPage, organizations, sports, sportId });
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    search = async (searchText?: string) => {
        const { sportId } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const organizationsPage = await organizationApi.list(
                this.pageSize,
                1,
                undefined,
                undefined,
                sportId ? [sportId] : undefined,
                searchText,
            );
            const organizations = organizationsPage.items ? organizationsPage.items : [];

            this.setState({ organizationsPage, organizations, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterBySport = async (sportId?: number) => {
        const { searchText } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const organizationsPage = await organizationApi.list(
                this.pageSize,
                1,
                undefined,
                undefined,
                sportId ? [sportId] : undefined,
                searchText,
            );
            const organizations = organizationsPage.items ? organizationsPage.items : [];

            this.setState({ organizationsPage, organizations, sportId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    loadMore = async () => {
        const { searchText, sportId, organizationsPage, organizations } = this.state;
        try {
            this.setState({ loading: 'loadingMore' });

            const page = organizationsPage?.currentPage! + 1;
            const organizationsPageAux = await await organizationApi.list(
                this.pageSize,
                page,
                undefined,
                undefined,
                sportId ? [sportId] : undefined,
                searchText,
            );
            const organizationsAux =
                organizationsPageAux && organizationsPageAux.items
                    ? organizations.concat(organizationsPageAux.items)
                    : [];

            this.setState({ organizationsPage: organizationsPageAux, organizations: organizationsAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    collapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            const { intl } = this.props;
            return (
                <PageHeader
                    title={
                        <Search
                            placeholder={intl.formatMessage({ id: 'button.search' })}
                            className={styles.search}
                            onSearch={this.search}
                            enterButton={''}
                            allowClear={true}
                        />
                    }
                />
            );
        } else {
            return <Button type="ghost" icon={<FilterOutlined />} onClick={() => this.collapse(true)} />;
        }
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { sports } = this.state;
        let sportOptions = sports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));
        sportOptions = [
            <Select.Option key="-" value="">
                <FormattedMessage id="rosters.toolbar.sports" />
            </Select.Option>,
            ...sportOptions,
        ];

        return (
            <PageHeader
                title={<FormattedMessage id="rosters.title" />}
                extra={[
                    rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) && (
                        <Select
                            key="sports"
                            size="large"
                            className={styles.sports}
                            placeholder={<FormattedMessage id="rosters.toolbar.sports" />}
                            onChange={this.filterBySport}
                        >
                            {sportOptions}
                        </Select>
                    ),
                ]}
                className={styles.toolbar}
            ></PageHeader>
        );
    };

    renderLoadMore = (): React.ReactElement | undefined => {
        const { organizationsPage, organizations, loading } = this.state;
        return organizations.length < organizationsPage?.totalItems! ? (
            <div className={styles.loadMore}>
                <Button onClick={this.loadMore} loading={loading === 'loadingMore'}>
                    <FormattedMessage id="button.loadMore" />
                </Button>
            </div>
        ) : (
            <></>
        );
    };

    renderList = (desktop: boolean): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { organizations, loading, sports, collapsed, sportId } = this.state;

        let sportOptions = sports.map((sport) => (
            <Menu.Item key={sport.id} onClick={() => this.filterBySport(sport.id)}>
                {sport.name}
            </Menu.Item>
        ));
        sportOptions = [
            <Menu.Item key="-" onClick={() => this.filterBySport(undefined)}>
                <FormattedMessage id="rosters.toolbar.sports" />
            </Menu.Item>,
            ...sportOptions,
        ];

        return (
            <>
                <Drawer
                    placement="right"
                    onClose={() => this.collapse(false)}
                    visible={collapsed}
                    className={styles.mobileFilter}
                >
                    <Menu>{sportOptions}</Menu>
                </Drawer>
                <List
                    grid={{
                        gutter: 30,
                        xs: 2,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 4,
                        xxl: 5,
                    }}
                    dataSource={organizations}
                    loadMore={this.renderLoadMore()}
                    renderItem={(organization) => (
                        <List.Item key={organization.id}>
                            <Link
                                to={
                                    rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])
                                        ? `/organizations/${organization.id}`
                                        : `/rosters/${organization.id}?sportId=${sportId}`
                                }
                                className={styles.card}
                            >
                                <Card
                                    className={styles.card}
                                    cover={
                                        <div className={styles.imageMedium}>
                                            <img src={organization.logo || logo} alt={logo} className={styles.image} />
                                        </div>
                                    }
                                >
                                    <Card.Meta
                                        title={organization.name}
                                        className={styles.meta}
                                        description={
                                            <div className={styles.description}>
                                                {organization.sports?.map((orgSport) => {
                                                    return (
                                                        <Tooltip
                                                            title={sports.find((s) => s.id === orgSport.sportId)?.name}
                                                        >
                                                            <img
                                                                src={
                                                                    sports.find((s) => s.id === orgSport.sportId)
                                                                        ?.iconUrl
                                                                }
                                                                alt={logo}
                                                            ></img>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </div>
                                        }
                                    />
                                    <div className={styles.footer}>
                                        <div className={styles.ico}></div>#1 - {}
                                    </div>
                                </Card>
                            </Link>
                        </List.Item>
                    )}
                    loading={loading === 'loading'}
                />
            </>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        return (
            <>
                {desktop && this.renderToolbar()}
                {this.renderList(desktop)}
            </>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="rosters.meta.title" />
                <LayoutComponent page="rosters" content={this.renderContent} header={this.renderHeader} />
            </>
        );
    }
}
export default injectIntl(OrganizationsPage);

interface Props extends WrappedComponentProps {}

interface State {
    organizationsPage?: Page<Organization>;
    organizations: Organization[];
    sports: Sport[];
    searchText?: string;
    sportId?: number;
    collapsed?: boolean;
    loading?: 'loading' | 'loadingMore';
}
