import Icon from '@ant-design/icons';
import { Button, Col, DatePicker, Form, FormInstance, InputNumber, message, Modal, Row, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import metricApi from '../../../apis/MetricApi';
import resultApi from '../../../apis/ResultApi';
import CustomContext from '../../../context/CustomContext';
import { Measure, Station, TestCategory, User } from '../../../model/Entities';
import { ReactComponent as removeSvg } from '../../../resources/images/ico-thrash.svg';
import ga from '../../../services/GoogleAnalytics';
import notificationService from '../../../services/NotificationService';
import stringService from '../../../services/StringService';

class AddMetricModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { metrics: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { stations, metricId, measureId } = this.props;
        try {
            this.setState({ loading: 'initializing' });

            const metrics = stations
                ?.flatMap((s) => s.metrics!)
                .filter((thing, index, self) => index === self.findIndex((t) => t.id === thing.id))
                .sort((a, b) => stringService.sort(a.name, b.name));
            this.setState({ metrics });

            if (metricId && measureId) {
                await this.get(metricId, measureId);
            } else {
                this.new();
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    new = () => {
        const measure: Measure = {};
        this.formRef.current!.setFieldsValue(measure);
        this.setState({ measure });
    };

    get = async (metricId: number, measureId: number) => {
        const metric = await metricApi.get(this.props.user?.id!, metricId);
        const measure = metric.measures?.find((m) => m.id === measureId)!;
        const values: any = Object.assign({}, measure);
        values.date = moment.utc(measure.date).local();
        this.formRef.current?.setFieldsValue(values);
        this.setState({ measure });
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    save = async () => {
        const { user, sportId, measureId, stations } = this.props;
        try {
            this.setState({ loading: 'saving' });

            const values = await this.formRef.current?.validateFields();
            let measure: Measure = Object.assign({}, this.state.measure, values);
            measure.date = values.date ? values.date.valueOf() : undefined;

            if (measureId) {
                await metricApi.update(measure!, user?.id!, sportId);
                ga.createDefaultEvent('player create metric', 'player create metric - update metric success');
            } else {
                const station = stations.find((s) => s.metrics?.find((m) => m.id === measure.metricId));
                measure.stationId = station?.id;
                await metricApi.create(measure!, user?.id!);
                ga.createDefaultEvent('player create metric', 'player create metric - create metric success');
            }

            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            this.props.onUpdate();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            ga.createDefaultEvent('player create metric', 'player create metric - error');
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteMetric = async () => {
        const { user, sportId, measureId } = this.props;
        try {
            this.setState({ loading: 'deleting' });
            await resultApi.delete(user?.id!, sportId!, measureId!);
            message.success(this.props.intl.formatMessage({ id: 'status.deleteMetric' }));
            ga.createDefaultEvent('player delete metric', 'player delete metric - success');
            this.props.onUpdate();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            ga.createDefaultEvent('player delete metric', 'player delete metric - error');
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    render() {
        const { loading, metrics, measure } = this.state;
        const metricOptions = metrics?.map((metric) => (
            <Select.Option key={metric?.id} value={metric?.id!}>
                {metric?.name}
            </Select.Option>
        ));

        return (
            <Modal
                width={570}
                visible={true}
                onCancel={this.cancel}
                footer={[
                    <div>
                        {this.props.metricId && (
                            <Button
                                type="text"
                                icon={<Icon component={removeSvg} />}
                                loading={loading === 'deleting'}
                                onClick={this.deleteMetric}
                            >
                                <FormattedMessage id="button.delete" tagName="span" />
                            </Button>
                        )}
                        <Button shape={'round'} onClick={this.cancel}>
                            <FormattedMessage id="button.cancel" />
                        </Button>
                        <Button shape={'round'} type="primary" onClick={this.save} loading={loading === 'saving'}>
                            <FormattedMessage id="button.save" tagName="span" />
                        </Button>
                    </div>,
                ]}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="player.media.metric" />}
                        name="metricId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select disabled={!measure || !!measure.id}>{metricOptions}</Select>
                    </Form.Item>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="player.media.result" />}
                                name="result"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <InputNumber type="number" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="player.media.date" />}
                                name="date"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(AddMetricModal);

interface Props extends WrappedComponentProps {
    onUpdate: () => Promise<void>;
    onCancel: () => void;
    user?: User;
    measureId?: number;
    metricId?: number;
    stations: Station[];
    sportId: number;
}

interface State {
    metrics: TestCategory[];
    measure?: Measure;
    loading?: 'initializing' | 'saving' | 'deleting';
}
