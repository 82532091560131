import { Role } from '../model/Entities';
import apiAxios from './CustomAxios';

class RoleApi {
    list = async (): Promise<Role[]> => {
        const response = await apiAxios.get<Role[]>('/v1/tools/roles');

        return response.data;
    };
}
const roleApi: RoleApi = new RoleApi();
export default roleApi;
