import Icon, { CheckCircleFilled, FilterOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Dropdown, Menu, message, Modal, PageHeader, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { Component, Key } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import divisionApi from '../../../../apis/DivisionApi';
import organizationApi from '../../../../apis/OrganizationApi';
import positionApi from '../../../../apis/PositionApi';
import sportApi from '../../../../apis/SportApi';
import teamApi from '../../../../apis/TeamApi';
import teamMemberApi from '../../../../apis/TeamMemberApi';
import userApi from '../../../../apis/UserApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Division, Organization, Position, Sport, Team, TeamMember } from '../../../../model/Entities';
import { genders, GenderType } from '../../../../model/Types';
import { ReactComponent as arrowBackSvg } from '../../../../resources/images/ico-arrowBack.svg';
import { ReactComponent as dashboardSvg } from '../../../../resources/images/ico-dashboard.svg';
import { ReactComponent as dotsSvg } from '../../../../resources/images/ico-dots.svg';
import { ReactComponent as gendersSvg } from '../../../../resources/images/ico-rosterGenders.svg';
import { ReactComponent as gradesSvg } from '../../../../resources/images/ico-rosterGrades.svg';
import { ReactComponent as positionsSvg } from '../../../../resources/images/ico-rosterPositions.svg';
import { ReactComponent as sportSvg } from '../../../../resources/images/ico-rosterSport.svg';
import { ReactComponent as teamsSvg } from '../../../../resources/images/ico-rosterTeams.svg';
import { ReactComponent as searchSvg } from '../../../../resources/images/ico-searchWhite.svg';
import HeadMetadata from '../../../../services/HeadMetadata';
import notificationService from '../../../../services/NotificationService';
import urlService from '../../../../services/ParamService';
import stringService from '../../../../services/StringService';
import styles from './ManageRosterPage.module.scss';
import TeamMemberModal from './TeamMemberModal/TeamMemberModal';

class ManageRosterPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            divisions: [],
            sports: [],
            positions: [],
            teams: [],
            teamMembers: [],
            grades: [],
            positionIds: [],
            gradesSelected: [],
            gendersSelected: [],
            selectedTeamMembers: [],
            selectedRowKeys: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            // get parameters and align url
            const params = new URLSearchParams(this.props.location.search);
            const sportId = urlService.getParamAsNumber(params, 'sportId');
            const divisionId = urlService.getParamAsNumber(params, 'divisionId');

            // list sports
            const sports = await sportApi.listAll();
            this.setState({
                sports,
            });

            // filter by sport
            this.filterBySport(this.props.match.params.organizationId, sportId!, divisionId);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterBySport = async (organizationId: string, sportId: number, divisionId?: number) => {
        const { sports } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const divisions = await divisionApi.list(sportId);
            const organization = await organizationApi.get(organizationId);
            const sport = sports.find((s) => s.id === sportId);
            this.setState({ organization, divisions, sport });

            this.filterByDivision(organizationId, sportId, divisionId);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByDivision = async (organizationId: string, sportId: number, divisionId?: number) => {
        const { divisions, searchText } = this.state;
        try {
            this.setState({ loading: 'loading' });

            // divisionId is optional because when we select a division in roster page we have it, but when we filterBySport we select the first one
            const division = divisionId ? divisions.find((d) => d.id === divisionId) : divisions[0];
            const teams = await teamApi.listByOrganization(organizationId, false, sportId, division?.id);

            // we handle when teams is empty because now lacrosse doesn't have teams
            const team = teams.length > 0 ? teams[0] : undefined;
            let teamMembers = team ? await teamMemberApi.listAll(organizationId, team.id!) : [];
            teamMembers = this.searchFilter(teamMembers, searchText);
            const positions = await positionApi.list(sportId);
            const positionIds: number[] = [];
            let grades: number[] = [];
            teamMembers?.forEach((p) => p.profile?.grade && grades.push(p.profile?.grade));
            grades = Array.from(new Set(grades)).sort((a, b) => a - b);
            const gradesSelected: number[] = [];
            const gendersSelected: GenderType[] = [];
            this.unselectAll();

            //For changing the url after filtering by sport or division
            this.changeUrl(sportId, division?.id!);

            this.setState({
                team,
                teamMembers,
                division,
                positions,
                teams,
                grades,
                positionIds,
                gradesSelected,
                gendersSelected,
            });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByTeam = async (teamId: string) => {
        const { teams, searchText } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const team = teams.find((t) => t.id === teamId);
            let teamMembers = await teamMemberApi.listAll(this.props.match.params.organizationId, team?.id!);
            teamMembers = this.searchFilter(teamMembers, searchText);
            let grades: number[] = [];
            const gradesSelected: number[] = [];
            teamMembers?.forEach((p) => p.profile?.grade && grades.push(p.profile?.grade));
            grades = Array.from(new Set(grades)).sort((a, b) => a - b);
            const positionIds: number[] = [];
            const gendersSelected: GenderType[] = [];
            this.unselectAll();

            this.setState({ team, teamMembers, grades, positionIds, gradesSelected, gendersSelected });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByPositionId = async (positionId: number) => {
        const { team, searchText } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const positionIds = this.state.positionIds.includes(positionId)
                ? this.state.positionIds.filter((id) => id !== positionId)
                : [...this.state.positionIds, positionId];

            let teamMembers = await teamMemberApi.listAll(this.props.match.params.organizationId, team?.id!);
            if (positionIds.length > 0) {
                teamMembers = teamMembers.filter((m) => m.positions?.some((p) => positionIds.includes(p)));
            }
            teamMembers = this.searchFilter(teamMembers, searchText);

            this.setState({ positionIds, teamMembers });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByGrade = async (grade: number) => {
        const { team, searchText } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const gradesSelected = this.state.gradesSelected.includes(grade)
                ? this.state.gradesSelected.filter((g) => g !== grade)
                : [...this.state.gradesSelected, grade];

            let teamMembers = await teamMemberApi.listAll(this.props.match.params.organizationId, team?.id!);
            if (gradesSelected.length > 0) {
                teamMembers = teamMembers.filter((m) => m.profile?.grade && gradesSelected.includes(m.profile?.grade));
            }
            teamMembers = this.searchFilter(teamMembers, searchText);

            this.setState({ gradesSelected, teamMembers });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByGender = async (gender: GenderType) => {
        const { team, searchText } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const gendersSelected = this.state.gendersSelected.includes(gender)
                ? this.state.gendersSelected.filter((g) => g !== gender)
                : [...this.state.gendersSelected, gender];

            let teamMembers = await teamMemberApi.listAll(this.props.match.params.organizationId, team?.id!);
            if (gendersSelected.length > 0) {
                teamMembers = teamMembers.filter(
                    (m) => m.profile?.gender && gendersSelected.includes(m.profile?.gender),
                );
            }
            teamMembers = this.searchFilter(teamMembers, searchText);

            this.setState({ gendersSelected, teamMembers });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterBySearch = async (searchText?: string) => {
        const { team } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const teamMembersAux = await teamMemberApi.listAll(this.props.match.params.organizationId, team?.id!);
            const teamMembers = this.searchFilter(teamMembersAux, searchText);
            const searchVisible = false;
            this.setState({ teamMembers, searchText, searchVisible });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    searchFilter = (teamMembers: TeamMember[], searchText?: string) => {
        return searchText
            ? teamMembers.filter(
                  (m) =>
                      stringService.search(m.profile?.givenName + ' ' + m.profile?.familyName, searchText) ||
                      stringService.search(m.profile?.email, searchText),
              )
            : teamMembers;
    };

    select = (selectedRowKeys: Key[], selectedTeamMembers: TeamMember[]) => {
        this.setState({ selectedRowKeys, selectedTeamMembers });
    };

    unselectAll = () => {
        this.setState({ selectedRowKeys: [], selectedTeamMembers: [] });
    };

    removeSelectedTeamMembers = async () => {
        const { selectedTeamMembers, organization, team } = this.state;

        try {
            this.setState({ loading: 'removingTeamMember' });
            await Promise.all(
                selectedTeamMembers.map((p) => teamMemberApi.delete(p.id!, organization?.id!, team?.id!)),
            );
            this.showRemoveModal(false);
            await this.filterByTeam(team?.id!);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    updateSelectedTeamMembers = async () => {
        const { team } = this.state;
        try {
            this.showEditModal(false);
            await this.filterByTeam(team?.id!);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    gradeLevelUpTeamMembers = async () => {
        const { selectedTeamMembers, team, organization } = this.state;
        try {
            this.setState({ loading: 'gradeLevelUpTeamMember' });
            const selectedTeamMembersIds = selectedTeamMembers.flatMap((t) => t.id!);
            await teamMemberApi.gradeLevelUp(organization?.id!, team?.id!, selectedTeamMembersIds);
            this.showGradeLevelUpModal(false);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            await this.filterByTeam(team?.id!);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    invite = async () => {
        const { selectedTeamMembers, team } = this.state;
        try {
            this.setState({ loading: 'inviting' });

            const newSelectedTeamMembersIds = selectedTeamMembers
                .filter((t) => t.userStatus === 'None')
                .flatMap((t) => t.userId!);
            const oldSelectedTeamMembersIds = selectedTeamMembers
                .filter((t) => t.userStatus !== 'None')
                .flatMap((t) => t.userId!);
            newSelectedTeamMembersIds.length > 0 && (await userApi.sendInvites(newSelectedTeamMembersIds));
            oldSelectedTeamMembersIds.length > 0 && (await userApi.sendReInvites(oldSelectedTeamMembersIds));

            this.showInviteModal(false);
            message.success(this.props.intl.formatMessage({ id: 'status.invitesSent' }));
            await this.filterByTeam(team?.id!);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeUrl = (sportId: number, divisionId: number) => {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set('sportId', sportId.toString());
        searchParams.set('divisionId', divisionId.toString());
        const url = urlService.create(window.location, searchParams);
        window.history.replaceState({ path: url }, '', url);
    };

    showRemoveModal = (removeTeamMemberModalVisible: boolean) => {
        this.setState({ removeTeamMemberModalVisible });
    };

    showEditModal = (editTeamMemberModalVisible: boolean) => {
        this.setState({ editTeamMemberModalVisible });
    };

    showGradeLevelUpModal = (gradeLevelUpTeamMemberModalVisible: boolean) => {
        this.setState({ gradeLevelUpTeamMemberModalVisible });
    };

    showInviteModal = (inviteModalVisible: boolean) => {
        this.setState({ inviteModalVisible });
    };

    collapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    showSearch = (searchVisible: boolean) => {
        this.setState({ searchVisible });
    };

    /*** COMPONENTS ***/

    renderLogo = (): React.ReactElement | undefined => {
        return (
            <Link to={`/rosters/${this.props.match.params.organizationId}`}>
                <Button
                    type="ghost"
                    size="small"
                    className={styles.back}
                    icon={<Icon component={arrowBackSvg} />}
                ></Button>
            </Link>
        );
    };

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { selectedTeamMembers, team, searchVisible } = this.state;
        if (desktop) {
            return (
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        <div className={styles.headerBackgroundImage}></div>
                    </div>
                    <PageHeader
                        className={styles.searchField}
                        title={
                            <Search
                                size="large"
                                placeholder="Search the Team Roster"
                                className={styles.search}
                                onSearch={(value) => this.filterBySearch(value)}
                                enterButton={''}
                                allowClear={true}
                            />
                        }
                    />
                </div>
            );
        } else {
            return (
                <>
                    <div className={styles.mobileButtons}>
                        {searchVisible && (
                            <span className={styles.mobileBtn}>
                                <Search
                                    onSearch={this.filterBySearch}
                                    size="large"
                                    allowClear={true}
                                    className={styles.searchBar}
                                    enterButton={<Button type="ghost" icon={<Icon component={searchSvg} />} />}
                                    autoFocus
                                />
                            </span>
                        )}
                        {!searchVisible && (
                            <Button
                                className={styles.search}
                                type="ghost"
                                icon={<Icon component={searchSvg} />}
                                onClick={() => this.showSearch(true)}
                            />
                        )}
                        <Button
                            className={styles.filter}
                            type="ghost"
                            icon={<FilterOutlined />}
                            onClick={() => this.collapse(true)}
                        />
                        <Dropdown
                            trigger={['click']}
                            placement="bottomRight"
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        disabled={selectedTeamMembers.length === 0}
                                        onClick={() => this.showInviteModal(true)}
                                    >
                                        <FormattedMessage id="rosters.roster.manageRoster.invite" />
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled={team?.primary || selectedTeamMembers.length === 0}
                                        onClick={() => this.showRemoveModal(true)}
                                    >
                                        <FormattedMessage id="rosters.roster.manageRoster.remove" />
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled={selectedTeamMembers.length === 0}
                                        onClick={() => this.showEditModal(true)}
                                    >
                                        <FormattedMessage id="rosters.roster.manageRoster.manageTeams" />
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled={selectedTeamMembers.length === 0}
                                        onClick={() => this.showGradeLevelUpModal(true)}
                                    >
                                        <FormattedMessage id="rosters.roster.manageRoster.gradeLevelUp" />
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button className={styles.dots} type="ghost" icon={<Icon component={dotsSvg} />} />
                        </Dropdown>
                    </div>
                </>
            );
        }
    };

    renderMenuFilters = (collapsed: boolean): React.ReactNode | undefined => {
        const {
            organization,
            sports,
            sport,
            divisions,
            positions,
            grades,
            division,
            positionIds,
            gradesSelected,
            gendersSelected,
        } = this.state;
        return (
            <Menu.ItemGroup key="roster" title={!collapsed && <FormattedMessage id="roster.navigation" />}>
                <Menu.SubMenu
                    key="roster-sports"
                    icon={<Icon component={sportSvg} />}
                    title={<FormattedMessage id="roster.navigation.sports" />}
                >
                    {sports?.map((s) => (
                        <Menu.Item
                            key={s.id}
                            onClick={() => this.filterBySport(organization?.id!, s.id!)}
                            className={sport?.id === s.id ? styles.selected : styles.unselected}
                        >
                            {s.name}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="roster-divisions"
                    icon={<Icon component={teamsSvg} />}
                    title={<FormattedMessage id="roster.navigation.divisions" />}
                >
                    {divisions.map((d) => (
                        <Menu.Item
                            key={d.id}
                            onClick={() => this.filterByDivision(organization?.id!, sport?.id!, d.id)}
                            className={division?.id === d.id ? styles.selected : styles.unselected}
                        >
                            {d.name}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="roster-positions"
                    icon={<Icon component={positionsSvg} />}
                    title={<FormattedMessage id="roster.navigation.positions" />}
                >
                    {positions.map((position) => (
                        <Menu.Item
                            key={position.id}
                            onClick={() => this.filterByPositionId(position.id)}
                            className={positionIds.includes(position.id) ? styles.selected : styles.unselected}
                        >
                            {position.name}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="roster-grades"
                    icon={<Icon component={gradesSvg} />}
                    title={<FormattedMessage id="roster.navigation.grades" />}
                >
                    {grades.map((grade: number, index: number) => (
                        <Menu.Item
                            key={`${grade}-${index}`}
                            onClick={() => this.filterByGrade(grade)}
                            className={gradesSelected.includes(grade) ? styles.selected : styles.unselected}
                        >
                            {grade}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="roster-genders"
                    icon={<Icon component={gendersSvg} />}
                    title={<FormattedMessage id="roster.navigation.genders" />}
                >
                    {genders.map((gender) => (
                        <Menu.Item
                            key={gender}
                            onClick={() => this.filterByGender(gender)}
                            className={gendersSelected.includes(gender) ? styles.selected : styles.unselected}
                        >
                            <FormattedMessage id={gender} />
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
            </Menu.ItemGroup>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="events-dashboard"
                        icon={<Icon component={dashboardSvg} />}
                        onClick={() => this.props.history.push('/')}
                    >
                        <Link to="/">
                            <FormattedMessage id="navigation.admin.dashboard" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                {this.renderMenuFilters(collapsed)}
            </>
        );
    };

    renderTools = (desktop: boolean): React.ReactElement | undefined => {
        const { organization, division, sport, teams, team, selectedTeamMembers } = this.state;
        const teamOptions = teams.map((t) => (
            <Select.Option key={t.id} value={t.id!}>
                {t.name}
            </Select.Option>
        ));

        return (
            <>
                <h1>
                    {organization?.name} {division?.name} {sport?.name}
                </h1>

                <div className={styles.tools}>
                    <Select
                        className={styles.select}
                        style={{ width: 180 }}
                        value={team?.id}
                        onChange={(value: string) => this.filterByTeam(value)}
                    >
                        {teamOptions}
                    </Select>
                    {desktop && (
                        <>
                            <Button
                                size="large"
                                shape="round"
                                disabled={selectedTeamMembers.length === 0}
                                onClick={() => this.showInviteModal(true)}
                            >
                                <FormattedMessage id="rosters.roster.manageRoster.invite" />
                            </Button>
                            <Button
                                size="large"
                                shape="round"
                                disabled={team?.primary || selectedTeamMembers.length === 0}
                                onClick={() => this.showRemoveModal(true)}
                            >
                                <FormattedMessage id="rosters.roster.manageRoster.remove" />
                            </Button>
                            <Button
                                size="large"
                                shape="round"
                                disabled={selectedTeamMembers.length === 0}
                                onClick={() => this.showEditModal(true)}
                            >
                                <FormattedMessage id="rosters.roster.manageRoster.manageTeams" />
                            </Button>
                            <Button
                                size="large"
                                shape="round"
                                disabled={selectedTeamMembers.length === 0}
                                onClick={() => this.showGradeLevelUpModal(true)}
                            >
                                <FormattedMessage id="rosters.roster.manageRoster.gradeLevelUp" />
                            </Button>
                        </>
                    )}
                </div>
            </>
        );
    };

    renderInvitedStatus = (value: string): React.ReactElement | undefined => {
        if (value === 'InviteSent' || value === 'InviteError') {
            return <FormattedMessage id="events.event.athletes.invited" />;
        } else if (value === 'Active') {
            return <CheckCircleFilled />;
        }
    };

    renderTable = (desktop: boolean): React.ReactElement | undefined => {
        const { loading, teamMembers, selectedRowKeys, collapsed } = this.state;

        const columns: ColumnsType<TeamMember> = [
            {
                title: <FormattedMessage id="rosters.roster.manageRoster.athletes.givenName" />,
                dataIndex: ['profile', 'givenName'],
                key: 'profile.givenName',
                sorter: true,
                render: (value: string, athlete: TeamMember) => value,
            },
            {
                title: <FormattedMessage id="rosters.roster.manageRoster.athletes.familyName" />,
                dataIndex: ['profile', 'familyName'],
                key: 'profile.familyName',
                sorter: true,
                render: (value: string, athlete: TeamMember) => value,
            },
            {
                title: <FormattedMessage id="rosters.roster.manageRoster.athletes.gender" />,
                dataIndex: ['profile', 'gender'],
                key: 'profile.gender',
                render: (value: string, athlete: TeamMember) => value,
            },
            {
                title: <FormattedMessage id="rosters.roster.manageRoster.athletes.grade" />,
                dataIndex: ['profile', 'grade'],
                key: 'profile.grade',
                render: (value: string, athlete: TeamMember) => value,
            },
            {
                title: <FormattedMessage id="rosters.roster.manageRoster.athletes.email" />,
                dataIndex: ['profile', 'email'],
                key: 'profile.email',
                render: (value: string, athlete: TeamMember) => value,
            },
            {
                title: <FormattedMessage id="rosters.roster.manageRoster.athletes.top100Invite" />,
                dataIndex: 'userStatus',
                key: 'userStatus',
                render: (value: string, athlete: TeamMember) => this.renderInvitedStatus(value),
            },
            {
                title: <FormattedMessage id="rosters.roster.manageRoster.athletes.phone" />,
                dataIndex: ['profile', 'phone'],
                key: 'profile.phone',
                render: (value: string, athlete: TeamMember) => value,
            },
        ];

        const rowSelection: TableRowSelection<TeamMember> = {
            preserveSelectedRowKeys: true,
            selectedRowKeys,
            onChange: this.select,
        };

        return (
            <div className={styles.teamMembers}>
                {this.renderTools(desktop)}
                <Drawer
                    className={styles.mobileFilter}
                    placement="right"
                    onClose={() => this.collapse(false)}
                    visible={collapsed}
                >
                    <Menu>{this.renderFilterDrawer(collapsed!)}</Menu>
                </Drawer>
                <Table
                    className={styles.table}
                    dataSource={teamMembers}
                    columns={columns}
                    rowSelection={rowSelection}
                    rowKey="id"
                    loading={loading === 'loading'}
                />
            </div>
        );
    };

    renderRemoveModal = (): React.ReactElement | undefined => {
        const { removeTeamMemberModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="rosters.roster.manageRoster.remove.modal.title" />}
                visible={removeTeamMemberModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.removeSelectedTeamMembers}
                okButtonProps={{ loading: loading === 'removingTeamMember' }}
                onCancel={() => this.showRemoveModal(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="rosters.roster.manageRoster.remove.modal.description" />
                </p>
            </Modal>
        );
    };

    renderGradeLevelUpModal = (): React.ReactElement | undefined => {
        const { gradeLevelUpTeamMemberModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="rosters.roster.manageRoster.gradeLevelUp.modal.title" />}
                visible={gradeLevelUpTeamMemberModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.gradeLevelUpTeamMembers}
                okButtonProps={{ loading: loading === 'gradeLevelUpTeamMember' }}
                onCancel={() => this.showGradeLevelUpModal(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="rosters.roster.manageRoster.gradeLevelUp.modal.description" />
                </p>
            </Modal>
        );
    };

    renderEditModal = (): React.ReactElement | undefined => {
        const {
            divisions,
            division,
            teams,
            team,
            editTeamMemberModalVisible,
            selectedTeamMembers,
            organization,
            sport,
        } = this.state;

        if (editTeamMemberModalVisible && division && team) {
            return (
                <TeamMemberModal
                    divisions={divisions}
                    divisionId={division.id!}
                    teams={teams}
                    teamId={team.id!}
                    onCancel={() => this.showEditModal(false)}
                    onUpdate={this.updateSelectedTeamMembers}
                    organizationId={organization?.id!}
                    sportId={sport?.id!}
                    selectedTeamMembers={selectedTeamMembers}
                />
            );
        }
    };

    renderInviteModal = (): React.ReactElement | undefined => {
        const { inviteModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="rosters.roster.manageRoster.invite.modal.title" />}
                visible={inviteModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.invite}
                okButtonProps={{ loading: loading === 'inviting' }}
                onCancel={() => this.showInviteModal(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="rosters.roster.manageRoster.invite.modal.description" />
                </p>
            </Modal>
        );
    };

    renderFilterDrawer = (collapsed: boolean): React.ReactNode | undefined => {
        return (
            <>
                <FormattedMessage id="roster.navigation" />

                {this.renderMenuFilters(collapsed)}
            </>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        return (
            <>
                {this.renderTable(desktop)}
                {this.renderRemoveModal()}
                {this.renderGradeLevelUpModal()}
                {this.renderEditModal()}
                {this.renderInviteModal()}
            </>
        );
    };

    render() {
        const { organization } = this.state;
        const fullName =
            organization &&
            `${this.props.intl.formatMessage({
                id: 'rosters.roster.manageRoster.meta.title',
            })} - ${organization?.name} - ${this.props.intl.formatMessage({
                id: 'roster.meta.title',
            })}`;
        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="manage-roster"
                    content={this.renderContent}
                    menu={this.renderMenu}
                    header={this.renderHeader}
                    logo={this.renderLogo}
                />
            </>
        );
    }
}
export default injectIntl(ManageRosterPage);

type ParamsType = { organizationId: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    organization?: Organization;
    sports: Sport[];
    sport?: Sport;
    divisions: Division[];
    division?: Division;
    positions: Position[];
    teams: Team[];
    team?: Team;
    teamMembers: TeamMember[];
    grades: number[];
    gradesSelected: number[];
    positionIds: number[];
    gendersSelected: GenderType[];
    selectedRowKeys: Key[];
    selectedTeamMembers: TeamMember[];
    loading?: 'loading' | 'removingTeamMember' | 'gradeLevelUpTeamMember' | 'inviting';
    searchText?: string;
    removeTeamMemberModalVisible?: boolean;
    editTeamMemberModalVisible?: boolean;
    gradeLevelUpTeamMemberModalVisible?: boolean;
    inviteModalVisible?: boolean;
    collapsed?: boolean;
    searchVisible?: boolean;
}
