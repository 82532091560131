import Icon from '@ant-design/icons';
import { Button, Divider, Image, Menu, message, Select, Table, Tabs } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import divisionApi from '../../apis/DivisionApi';
import leaderboardApi from '../../apis/LeaderboardApi';
import positionApi from '../../apis/PositionApi';
import seasonApi from '../../apis/SeasonApi';
import sportApi from '../../apis/SportApi';
import sportMetricApi from '../../apis/SportMetricApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Page } from '../../model/Elements';
import { Division, Leaderboard, Position, Season, Sport, SportCategory } from '../../model/Entities';
import { countriesFilter, CountryFilterType, GenderType, leaderboards, LeaderboardType } from '../../model/Types';
import { ReactComponent as caFlagSvg } from '../../resources/images/flags/ca.svg';
import { ReactComponent as usaFlagSvg } from '../../resources/images/flags/usa.svg';
import { ReactComponent as dashboardSvg } from '../../resources/images/ico-dashboard.svg';
import { ReactComponent as eventsSvg } from '../../resources/images/ico-events.svg';
import { ReactComponent as countriesSvg } from '../../resources/images/ico-location.svg';
import { ReactComponent as gradyearSvg } from '../../resources/images/ico-rosterGrades.svg';
import { ReactComponent as positionsSvg } from '../../resources/images/ico-rosterPositions.svg';
import { ReactComponent as sportSvg } from '../../resources/images/ico-rosterSport.svg';
import { ReactComponent as divisionsSvg } from '../../resources/images/ico-rosterTeams.svg';
import { ReactComponent as seasonsSvg } from '../../resources/images/ico-seasons.svg';
import { ReactComponent as shareSvg } from '../../resources/images/ico-share.svg';
import avatar from '../../resources/images/profile-placeholder-p.png';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import urlService from '../../services/ParamService';
import playerService from '../../services/PlayerService';
import rolesService from '../../services/RolesService';
import stringService from '../../services/StringService';
import tableService from '../../services/TableService';
import PlayerShareModal from '../PlayerProfilePage/PlayerShareModal/PlayerShareModal';
import styles from './LeaderboardPage.module.scss';

class LeaderboardPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;

    constructor(props: Props) {
        super(props);
        this.state = {
            sports: [],
            positions: [],
            positionIds: [],
            divisions: [],
            divisionIds: [],
            sportCategories: [],
            seasons: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            // set default sport and sports
            const sports = await sportApi.listAll();
            const defaultSport: Sport = sports.find((s) => s.code === 'BASEBALL') as Sport;

            // get parameters and align url
            const params = new URLSearchParams(this.props.location.search);
            const sport = sports.find((s) => s.id === urlService.getParamAsNumber(params, 'sportId')) || defaultSport;
            const gender =
                sport.code === 'LACROSSE'
                    ? (urlService.getParameter(params, 'gender') as GenderType) || ('Male' as GenderType)
                    : undefined;
            let seasonId = urlService.getParamAsNumber(params, 'seasonId');
            const seasons = await seasonApi.listAll(sport.id!);
            //TODO
            //seasonId = seasonId ? seasonId : seasons.length > 1 ? seasons[1].id : seasons[0].id;
            seasonId = seasonId ? seasonId : seasons[0].id;
            let leaderboardType: LeaderboardType | undefined = urlService.getParameter(
                params,
                'type',
            ) as LeaderboardType;
            leaderboardType = sport.code !== 'LACROSSE' ? (leaderboardType ? leaderboardType : 'global') : undefined;
            const organizationId = urlService.getParameter(params, 'organizationId');

            this.changeUrl(sport.id!, gender, seasonId, leaderboardType, organizationId);

            const sortByTop100Rank = 'ascend';
            // list leaderboard data
            const responses = await Promise.all([
                leaderboardApi.list(
                    this.pageSize,
                    1,
                    sport.id!,
                    sortByTop100Rank,
                    undefined,
                    undefined,
                    gender,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    seasonId,
                    leaderboardType,
                    organizationId,
                ),
                positionApi.list(sport.id!),
                divisionApi.list(sport.id!),
                sportMetricApi.list(sport.id!, leaderboardType),
            ]);
            const leadersPage = responses[0];
            const positions = responses[1];
            const divisions = responses[2];
            const sportCategories = responses[3];

            this.setState({
                leadersPage,
                sports,
                sport,
                positions,
                divisions,
                sportCategories,
                gender,
                sortByTop100Rank,
                seasons,
                seasonId,
                leaderboardType,
                organizationId,
            });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    list = async (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<Leaderboard> | SorterResult<Leaderboard>[],
    ) => {
        const {
            sport,
            positionIds,
            gender,
            divisionIds,
            gradYear,
            country,
            seasonId,
            leaderboardType,
            organizationId,
        } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const page = pagination.current!;
            let sortField, sortByTop100Rank, sortByGradYear;
            if (Array.isArray(sorter)) {
            } else {
                if (sorter.columnKey === 'score') {
                    sortByTop100Rank = sorter.order as string;
                } else if (sorter.columnKey === 'gradYear') {
                    sortByGradYear = sorter.order as string;
                }
            }
            sortByTop100Rank = !sortByTop100Rank && !sortByGradYear ? (sortByTop100Rank = 'ascend') : sortByTop100Rank;
            const leadersPage = await leaderboardApi.list(
                this.pageSize,
                page,
                sport?.id!,
                sortByTop100Rank,
                sortField,
                positionIds,
                gender,
                divisionIds,
                gradYear,
                sortByGradYear,
                country,
                seasonId,
                leaderboardType,
                organizationId,
            );
            this.setState({ leadersPage, sortByTop100Rank, sortByGradYear });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showModalShare = (modalShareVisible: boolean) => {
        this.setState({ modalShareVisible });
    };

    filterBySport = async (id: number, gender?: GenderType) => {
        const { sports, gradYear, sortByTop100Rank, sortByGradYear, country, leaderboardType, organizationId } =
            this.state;
        try {
            this.setState({ loading: 'loading' });
            const sport: Sport | undefined = sports.find((s) => s.id === id);
            const seasons = await seasonApi.listAll(id);
            const seasonId = seasons.length > 0 ? seasons[0].id : undefined;
            const leaderboardTypeToUse =
                sport?.code !== 'LACROSSE' ? (leaderboardType ? leaderboardType : 'global') : undefined;
            const responses = await Promise.all([
                positionApi.list(id),
                divisionApi.list(id),
                leaderboardApi.list(
                    this.pageSize,
                    1,
                    id,
                    sortByTop100Rank,
                    undefined,
                    undefined,
                    gender,
                    undefined,
                    gradYear,
                    sortByGradYear,
                    country,
                    seasonId,
                    leaderboardTypeToUse,
                    organizationId,
                ),
                sportMetricApi.list(id, leaderboardTypeToUse),
            ]);
            const positions = responses[0];
            const positionIds: number[] = [];
            const divisions = responses[1];
            const divisionIds: number[] = [];
            const leadersPage = responses[2];
            const sportCategories = responses[3];
            this.changeUrl(id, gender, seasonId, leaderboardTypeToUse, organizationId);

            this.setState({
                sport,
                positions,
                positionIds,
                leadersPage,
                gender,
                divisions,
                divisionIds,
                sportCategories,
                seasons,
                seasonId,
                leaderboardType: leaderboardTypeToUse,
            });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByPositionId = async (positionId: number) => {
        const {
            sport,
            gender,
            divisionIds,
            gradYear,
            sortByTop100Rank,
            sortByGradYear,
            country,
            seasonId,
            leaderboardType,
            organizationId,
        } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const positionIds = this.state.positionIds.includes(positionId)
                ? this.state.positionIds.filter((id) => id !== positionId)
                : [...this.state.positionIds, positionId];

            const leadersPage = await leaderboardApi.list(
                this.pageSize,
                1,
                sport?.id!,
                sortByTop100Rank,
                undefined,
                positionIds,
                gender,
                divisionIds,
                gradYear,
                sortByGradYear,
                country,
                seasonId,
                leaderboardType,
                organizationId,
            );

            this.setState({ positionIds, leadersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByDivisionId = async (divisionId: number) => {
        const {
            sport,
            gender,
            positionIds,
            gradYear,
            sortByTop100Rank,
            sortByGradYear,
            country,
            seasonId,
            leaderboardType,
            organizationId,
        } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const divisionIds = this.state.divisionIds.includes(divisionId)
                ? this.state.divisionIds.filter((id) => id !== divisionId)
                : [...this.state.divisionIds, divisionId];

            const leadersPage = await leaderboardApi.list(
                this.pageSize,
                1,
                sport?.id!,
                sortByTop100Rank,
                undefined,
                positionIds,
                gender,
                divisionIds,
                gradYear,
                sortByGradYear,
                country,
                seasonId,
                leaderboardType,
                organizationId,
            );

            this.setState({ divisionIds, leadersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByGradYear = async (gradYearSelected: number) => {
        const {
            sport,
            gender,
            positionIds,
            divisionIds,
            gradYear,
            sortByTop100Rank,
            sortByGradYear,
            country,
            seasonId,
            leaderboardType,
            organizationId,
        } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const gradYearToUse = gradYear !== gradYearSelected ? gradYearSelected : undefined;

            const leadersPage = await leaderboardApi.list(
                this.pageSize,
                1,
                sport?.id!,
                sortByTop100Rank,
                undefined,
                positionIds,
                gender,
                divisionIds,
                gradYearToUse,
                sortByGradYear,
                country,
                seasonId,
                leaderboardType,
                organizationId,
            );

            this.setState({ gradYear: gradYearToUse, leadersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByCountry = async (countrySelected: CountryFilterType) => {
        const {
            sport,
            gender,
            positionIds,
            divisionIds,
            gradYear,
            sortByTop100Rank,
            sortByGradYear,
            country,
            seasonId,
            leaderboardType,
            organizationId,
        } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const countryToUse = country !== countrySelected ? countrySelected : undefined;

            const leadersPage = await leaderboardApi.list(
                this.pageSize,
                1,
                sport?.id!,
                sortByTop100Rank,
                undefined,
                positionIds,
                gender,
                divisionIds,
                gradYear,
                sortByGradYear,
                countryToUse,
                seasonId,
                leaderboardType,
                organizationId,
            );

            this.setState({ country: countryToUse, leadersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterBySeason = async (newSeasonId: number) => {
        const {
            sport,
            gender,
            positionIds,
            divisionIds,
            gradYear,
            sortByTop100Rank,
            sortByGradYear,
            country,
            seasonId,
            leaderboardType,
            organizationId,
        } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const seasonToUse = seasonId !== newSeasonId ? newSeasonId : seasonId;

            const leadersPage = await leaderboardApi.list(
                this.pageSize,
                1,
                sport?.id!,
                sortByTop100Rank,
                undefined,
                positionIds,
                gender,
                divisionIds,
                gradYear,
                sortByGradYear,
                country,
                seasonToUse,
                leaderboardType,
                organizationId,
            );

            this.changeUrl(sport?.id!, gender, seasonToUse, leaderboardType, organizationId);

            this.setState({ seasonId: seasonToUse, leadersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByLeaderboardType = async (leaderboardType: LeaderboardType) => {
        const {
            sport,
            gender,
            seasonId,
            sortByTop100Rank,
            positionIds,
            divisionIds,
            gradYear,
            sortByGradYear,
            country,
            organizationId,
        } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const sportCategories = await sportMetricApi.list(sport?.id!, leaderboardType);
            this.changeUrl(sport?.id!, gender, seasonId, leaderboardType, organizationId);

            const leadersPage = await leaderboardApi.list(
                this.pageSize,
                1,
                sport?.id!,
                sortByTop100Rank,
                undefined,
                positionIds,
                gender,
                divisionIds,
                gradYear,
                sortByGradYear,
                country,
                seasonId,
                leaderboardType,
                organizationId,
            );

            this.setState({ leaderboardType, sportCategories, leadersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByOrganization = async (organizationIdSelected: string) => {
        const {
            sport,
            gender,
            positionIds,
            divisionIds,
            gradYear,
            sortByTop100Rank,
            sortByGradYear,
            country,
            seasonId,
            leaderboardType,
            organizationId,
        } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const organizationIdToUse = organizationId ? undefined : organizationIdSelected;

            const leadersPage = await leaderboardApi.list(
                this.pageSize,
                1,
                sport?.id!,
                sortByTop100Rank,
                undefined,
                positionIds,
                gender,
                divisionIds,
                gradYear,
                sortByGradYear,
                country,
                seasonId,
                leaderboardType,
                organizationIdToUse,
            );

            this.changeUrl(sport?.id!, gender, seasonId, leaderboardType, organizationIdToUse);

            this.setState({ organizationId: organizationIdToUse, leadersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeUrl = (
        sportId: number,
        gender?: GenderType,
        seasonId?: number,
        leaderboardType?: LeaderboardType,
        organizationId?: string,
    ) => {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set('sportId', sportId.toString());
        gender ? searchParams.set('gender', gender) : searchParams.delete('gender');
        seasonId ? searchParams.set('seasonId', seasonId.toString()) : searchParams.delete('seasonId');
        leaderboardType ? searchParams.set('type', leaderboardType) : searchParams.delete('type');
        organizationId ? searchParams.set('organizationId', organizationId) : searchParams.delete('organizationId');
        const url = urlService.create(window.location, searchParams);
        window.history.replaceState({ path: url }, '', url);
    };

    downloadExcel = async () => {
        const {
            sport,
            gender,
            positionIds,
            divisionIds,
            gradYear,
            sortByTop100Rank,
            sortByGradYear,
            country,
            seasonId,
            leaderboardType,
            organizationId,
        } = this.state;
        try {
            this.setState({ loading: 'downloadingExcel' });

            await leaderboardApi.exportLeaderboardToExcel(
                sport?.id!,
                sortByTop100Rank,
                undefined,
                positionIds,
                gender,
                divisionIds,
                gradYear,
                sortByGradYear,
                country,
                seasonId,
                leaderboardType,
                organizationId,
            );

            message.success(this.props.intl.formatMessage({ id: 'status.downloaded' }));
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderTitle = (): React.ReactElement | undefined => {
        const { leaderboardType } = this.state;
        if (!leaderboardType || leaderboardType === 'global') {
            return <FormattedMessage id="nationalLeaderboard.title" />;
        } else if (leaderboardType === 'pitching') {
            return <FormattedMessage id="nationalLeaderboard.pitchersTitle" />;
        } else {
            return <FormattedMessage id="nationalLeaderboard.catchersTitle" />;
        }
    };

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { sport, seasons, seasonId, loading } = this.state;

        const seasonOptions = seasons.map((s) => (
            <Select.Option key={s.id} value={s.id!} className={s.id === seasonId ? styles.selected : styles.unselected}>
                {s.name}
            </Select.Option>
        ));

        if (desktop) {
            return (
                <>
                    <div className={styles.header}>
                        <p>{this.renderTitle()}</p>
                        <span className={styles.headerButtons}>
                            <Button
                                className={styles.downloadExcel}
                                onClick={this.downloadExcel}
                                loading={loading === 'downloadingExcel'}
                                hidden={
                                    !rolesService.hasAnyRole(auth, [
                                        'ROLE_ADMIN',
                                        'ROLE_ORGANIZATION',
                                        'ROLE_COLLEGE_COACH',
                                    ])
                                }
                            >
                                <FormattedMessage id="events.event.leaderboard.button.downloadExcel" />
                            </Button>
                            <Button
                                className={styles.share}
                                icon={<Icon component={shareSvg} />}
                                onClick={() => this.showModalShare(true)}
                            >
                                <FormattedMessage id="button.share" />
                            </Button>
                        </span>
                    </div>
                    <div className={styles.subheader}>
                        {sport && seasonId && (
                            <h2>
                                {sport?.name}{' '}
                                <Select
                                    key="seasons"
                                    value={seasonId}
                                    onSelect={(value: number) => this.filterBySeason(value)}
                                    className={styles.seasonsDropdown}
                                >
                                    {seasonOptions}
                                </Select>
                            </h2>
                        )}
                    </div>
                </>
            );
        } else {
            return (
                <Button
                    className={styles.share}
                    icon={<Icon component={shareSvg} />}
                    onClick={() => this.showModalShare(true)}
                ></Button>
            );
        }
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="leaderboard-dashboard"
                        icon={<Icon component={dashboardSvg} />}
                        onClick={() => this.props.history.push('/')}
                    >
                        <Link to="/">
                            <FormattedMessage id="navigation.admin.dashboard" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                {this.renderMenuFilters(collapsed)}
            </>
        );
    };

    renderMenuFilters = (collapsed: boolean): React.ReactNode | undefined => {
        const {
            sports,
            positions,
            positionIds,
            sport,
            gender,
            divisions,
            divisionIds,
            gradYear,
            country,
            seasons,
            seasonId,
        } = this.state;
        const currentYear = new Date().getFullYear();
        let gradYears = Array.from(Array(11).keys()).map((x) => currentYear + x);
        return (
            <>
                {!this.context.auth && (
                    <>
                        <Menu.ItemGroup>
                            <Menu.Item key="events" icon={<Icon component={eventsSvg} />}>
                                <Link to="/events">
                                    <FormattedMessage id="navigation.admin.dashboard.events" />
                                </Link>
                            </Menu.Item>
                        </Menu.ItemGroup>
                        <Divider className="divider" />
                    </>
                )}
                <Menu.ItemGroup key="leaderboard" title={!collapsed && <FormattedMessage id="roster.navigation" />}>
                    <Menu.SubMenu
                        key="sports"
                        icon={<Icon component={sportSvg} />}
                        title={<FormattedMessage id="roster.navigation.sports" />}
                    >
                        {sports?.map((s) =>
                            s.id === 15 ? (
                                <>
                                    <Menu.Item
                                        key={s.id}
                                        onClick={() => this.filterBySport(s.id!, 'Male')}
                                        className={
                                            sport?.id === s.id && gender === 'Male'
                                                ? styles.selected
                                                : styles.unselected
                                        }
                                    >
                                        {s.name} <FormattedMessage id="Male" />
                                    </Menu.Item>
                                    <Menu.Item
                                        key={s.id + 1}
                                        onClick={() => this.filterBySport(s.id!, 'Female')}
                                        className={
                                            sport?.id === s.id && gender === 'Female'
                                                ? styles.selected
                                                : styles.unselected
                                        }
                                    >
                                        {s.name} <FormattedMessage id="Female" />
                                    </Menu.Item>
                                </>
                            ) : (
                                <Menu.Item
                                    key={s.id}
                                    onClick={() => this.filterBySport(s.id!)}
                                    className={sport?.id === s.id ? styles.selected : styles.unselected}
                                >
                                    {s.name}
                                </Menu.Item>
                            ),
                        )}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="positions"
                        icon={<Icon component={positionsSvg} />}
                        title={<FormattedMessage id="roster.navigation.positions" />}
                    >
                        {positions.map((position) => (
                            <Menu.Item
                                key={position.code}
                                onClick={() => this.filterByPositionId(position.id)}
                                className={positionIds.includes(position.id) ? styles.selected : styles.unselected}
                            >
                                {position.name}
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="divisions"
                        icon={<Icon component={divisionsSvg} />}
                        title={<FormattedMessage id="roster.navigation.divisions" />}
                    >
                        {divisions.map((division) => (
                            <Menu.Item
                                key={division.id}
                                onClick={() => this.filterByDivisionId(division.id!)}
                                className={divisionIds.includes(division.id!) ? styles.selected : styles.unselected}
                            >
                                {division.name}
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="gradYears"
                        icon={<Icon component={gradyearSvg} />}
                        title={<FormattedMessage id="roster.navigation.gradYears" />}
                    >
                        {gradYears.map((g) => (
                            <Menu.Item
                                key={g}
                                onClick={() => this.filterByGradYear(g)}
                                className={gradYear === g ? styles.selected : styles.unselected}
                            >
                                {g}
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="countries"
                        icon={<Icon component={countriesSvg} />}
                        title={<FormattedMessage id="roster.navigation.countries" />}
                    >
                        {countriesFilter.map((c) => (
                            <Menu.Item
                                key={c}
                                onClick={() => this.filterByCountry(c)}
                                className={country === c ? styles.selected : styles.unselected}
                            >
                                <FormattedMessage id={c} />
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                    <Menu.SubMenu
                        key="seasons"
                        icon={<Icon component={seasonsSvg} />}
                        title={<FormattedMessage id="roster.navigation.seasons" />}
                    >
                        {seasons.map((s) => (
                            <Menu.Item
                                key={s.id}
                                onClick={() => this.filterBySeason(s.id!)}
                                className={s.id === seasonId ? styles.selected : styles.unselected}
                            >
                                {s.name}
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                </Menu.ItemGroup>
            </>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        return this.renderTabs(desktop);
    };

    renderAffiliatePlayersButton = (organizationId?: string): React.ReactElement | undefined => {
        if (this.context.auth?.authorities.includes('ROLE_ORGANIZATION')) {
            return (
                <Button
                    type={organizationId ? 'primary' : 'default'}
                    onClick={() => this.filterByOrganization(this.context.auth?.organizationId!)}
                    className={styles.affiliateButton}
                >
                    <FormattedMessage id="nationalLeaderboard.affiliatePlayers" />
                </Button>
            );
        }
    };

    renderTabs = (desktop: boolean): React.ReactElement | undefined => {
        const { sport, leaderboardType, organizationId } = this.state;
        return (
            <>
                {sport?.code === 'BASEBALL' || sport?.code === 'SOFTBALL' ? (
                    <div className={styles.tabs}>
                        <Tabs
                            type="card"
                            className="leaderboardTabs"
                            onChange={(value: string) => this.filterByLeaderboardType(value as LeaderboardType)}
                            activeKey={leaderboardType}
                            tabBarExtraContent={this.renderAffiliatePlayersButton(organizationId)}
                        >
                            {leaderboards.map((l) => (
                                <Tabs.TabPane key={l} tab={<FormattedMessage id={l} />} className={styles.tab}>
                                    {this.renderTable(desktop)}
                                </Tabs.TabPane>
                            ))}
                        </Tabs>
                    </div>
                ) : (
                    <>
                        {this.renderAffiliatePlayersButton(organizationId)}
                        {this.renderTable(desktop)}
                    </>
                )}
            </>
        );
    };

    renderIndex = (leaderboard: Leaderboard, categoryId: number): React.ReactElement | undefined => {
        const index = leaderboard.categoriesIndexes.find((i) => i.ratingCategoryId === categoryId)?.score;
        if (index) {
            return <FormattedNumber value={index * 100} minimumFractionDigits={0} maximumFractionDigits={0} />;
        }
    };

    renderIndexColumns = (width?: number): ColumnsType<any> => {
        const { sportCategories } = this.state;
        return sportCategories.map((category) => ({
            title: category.name,
            key: category.id,
            align: 'center',
            sortDirections: ['descend'],
            width,
            render: (value: any, leaderboard: Leaderboard) => this.renderIndex(leaderboard, category.id!),
        }));
    };

    renderFlag = (country?: string): React.ReactElement | undefined => {
        if (country === 'CA') {
            return <Icon component={caFlagSvg} height={50} className={styles.flag} />;
        } else {
            return <Icon component={usaFlagSvg} height={50} className={styles.flag} />;
        }
    };

    renderPlayerLink = (
        content: React.ReactElement | string,
        userId?: string,
        sportId?: number,
        familyId?: number,
        organizationIds?: string[],
    ): React.ReactElement | string => {
        if (playerService.isPlayerVisible(this.context.auth, familyId, organizationIds)) {
            return <Link to={`/players/${userId}?sportId=${sportId}`}>{content}</Link>;
        } else {
            return content;
        }
    };

    renderTable = (desktop: boolean): React.ReactElement | undefined => {
        const { leadersPage, modalShareVisible, loading, sport } = this.state;
        const items = leadersPage ? leadersPage.items : [];
        const columnsFixed = desktop ? undefined : 'left';
        const columnRankWidth = desktop ? undefined : 60;
        const columnIndexWidth = desktop ? undefined : 60;

        let columns: ColumnsType<Leaderboard> = [
            {
                title: (
                    <span>
                        <span className={styles.rankDes}>
                            <FormattedMessage id="player.rank" />
                        </span>
                        <span className={styles.rankMob}>
                            <FormattedMessage id="player.hash" />
                        </span>
                    </span>
                ),
                dataIndex: ['athlete', 'score'],
                key: 'score',
                sorter: true,
                sortDirections: ['ascend', 'descend'],
                fixed: columnsFixed,
                width: columnRankWidth,
                render: (value: any, player: Leaderboard) => (
                    <>
                        <span className={styles.rank}>{value === 0 ? '--' : value}</span>
                        {this.renderFlag(player.athlete.country)}
                    </>
                ),
            },
            {
                dataIndex: ['athlete', 'photoUrl'],
                key: 'photoUrl',
                fixed: columnsFixed,
                render: (value: any, player: Leaderboard) =>
                    this.renderPlayerLink(
                        <Image src={value || avatar} width={72} fallback={avatar} preview={false} />,
                        player.athlete.userId,
                        sport?.id,
                        player.athlete.familyId,
                        player.athlete.organizationIds,
                    ),
            },
            {
                title: <FormattedMessage id="player.name" />,
                dataIndex: ['athlete', 'givenName'],
                key: 'givenName',
                fixed: columnsFixed,
                ellipsis: !desktop,
                render: (value: string, player: Leaderboard) =>
                    this.renderPlayerLink(
                        stringService.getName(desktop, player.athlete.givenName, player.athlete.familyName),
                        player.athlete.userId,
                        sport?.id,
                        player.athlete.familyId,
                        player.athlete.organizationIds,
                    ),
            },
            {
                title: <FormattedMessage id="player.gradYear" />,
                dataIndex: ['athlete', 'gradYear'],
                key: 'gradYear',
                align: 'center',
                width: 130,
                sorter: true,
                sortDirections: ['ascend', 'descend'],
                render: (value: string, player: Leaderboard) =>
                    this.renderPlayerLink(
                        value,
                        player.athlete.userId,
                        sport?.id,
                        player.athlete.familyId,
                        player.athlete.organizationIds,
                    ),
            },
            {
                title: <FormattedMessage id="player.top100Index" />,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                width: columnIndexWidth,
                render: (value: number, player: Leaderboard) =>
                    this.renderPlayerLink(
                        <FormattedNumber value={value * 100} minimumFractionDigits={0} maximumFractionDigits={0} />,
                        player.athlete.userId,
                        sport?.id,
                        player.athlete.familyId,
                        player.athlete.organizationIds,
                    ),
            },
            ...this.renderIndexColumns(columnIndexWidth),
        ];
        columns = columns.filter((col) => desktop || col.key !== 'photoUrl');
        const scroll =
            columnIndexWidth && columnRankWidth
                ? (columns.length - 3) * columnIndexWidth + columnRankWidth + 430
                : undefined;

        return (
            <>
                {modalShareVisible && <PlayerShareModal visible={true} onCancel={() => this.showModalShare(false)} />}
                <Table
                    dataSource={items}
                    columns={columns}
                    pagination={tableService.createPagination(leadersPage, { pageSize: this.pageSize })}
                    rowKey="id"
                    onChange={this.list}
                    showSorterTooltip={false}
                    loading={loading === 'loading'}
                    className={styles.table}
                    scroll={{ x: scroll }}
                />
            </>
        );
    };

    render() {
        const { sport } = this.state;
        const fullName =
            sport &&
            `${this.props.intl.formatMessage({
                id: 'nationalLeaderboard.meta.title',
            })} - ${sport?.name}`;
        return (
            <div className="leaderboard gradient">
                <div className="bg"></div>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="leaderboard"
                    header={this.renderHeader}
                    menu={this.renderMenu}
                    content={this.renderContent}
                />
            </div>
        );
    }
}
export default injectIntl(LeaderboardPage);

interface Props extends RouteComponentProps, WrappedComponentProps {}

interface State {
    leadersPage?: Page<Leaderboard>;
    sports: Sport[];
    sport?: Sport;
    positions: Position[];
    positionIds: number[];
    divisions: Division[];
    divisionIds: number[];
    gradYear?: number;
    gender?: GenderType;
    modalShareVisible?: boolean;
    loading?: 'loading' | 'downloadingExcel';
    searchText?: string;
    sportCategories: SportCategory[];
    sortByTop100Rank?: string;
    sortByGradYear?: string;
    country?: CountryFilterType;
    seasons: Season[];
    seasonId?: number;
    leaderboardType?: LeaderboardType;
    organizationId?: string;
}
