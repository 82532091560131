import { FormInstance, Modal, Image, Button } from 'antd';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import ReactPlayer from 'react-player';
import CustomContext from '../../../context/CustomContext';
import { Media } from '../../../model/Entities';
import notificationService from '../../../services/NotificationService';
import styles from './MediaPlayerComponent.module.scss';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

class MediaPlayerComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { media: this.props.medias[0] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    previousVideo = async () => {
        const { medias } = this.props;
        const index = medias.indexOf(this.state.media);
        const media = medias[index - 1];
        this.setState({ media });
    };

    nextVideo = async () => {
        const { medias } = this.props;
        const index = medias.indexOf(this.state.media);
        const media = medias[index + 1];
        this.setState({ media });
    };

    /*** COMPONENTS ***/

    render() {
        const { medias } = this.props;
        const { media } = this.state;

        if (medias.length === 1 && media.mediaType === 'Photo') {
            return (
                <Modal footer={[]} visible={true} className={styles.modal} onCancel={this.cancel}>
                    <Image src={media.url} preview={false} />
                </Modal>
            );
        } else {
            return (
                <Modal
                    footer={[
                        <span className={styles.footer}>
                            <Button
                                onClick={this.previousVideo}
                                hidden={medias.length < 2}
                                disabled={media === medias[0]}
                                className={styles.buttonLeft}
                            >
                                <LeftOutlined />
                            </Button>
                            <Button
                                onClick={this.nextVideo}
                                hidden={medias.length < 2}
                                disabled={media === medias[medias.length - 1]}
                                className={styles.buttonRight}
                            >
                                <RightOutlined />
                            </Button>
                        </span>,
                    ]}
                    visible={true}
                    className={styles.modal}
                    onCancel={this.cancel}
                >
                    {(media.mediaType === 'Video' || media.mediaType === undefined) && (
                        <ReactPlayer controls={true} width="10" url={media.url} />
                    )}
                </Modal>
            );
        }
    }
}
export default injectIntl(MediaPlayerComponent);

interface Props extends WrappedComponentProps {
    onCancel: () => void;
    medias: Media[];
}

interface State {
    loading?: boolean;
    media: Media;
}
