import Icon, { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Modal, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import familyApi from '../../../apis/FamilyApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Family, FamilyMember } from '../../../model/Entities';
import { ReactComponent as arrowBackSvg } from '../../../resources/images/ico-back.svg';
import HeadMetadata from '../../../services/HeadMetadata';
import notificationService from '../../../services/NotificationService';
import stringService from '../../../services/StringService';
import AddUserModal from '../../../components/AddUserModal/AddUserModal';
import styles from './FamilyGroupPage.module.scss';

class FamilyGroupPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const family = await familyApi.get(+this.props.match.params.id);

            this.setState({ family });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    refresh = async () => {
        const { family } = this.state;
        try {
            this.setState({ loading: 'loading' });

            // get family
            const familyUpdated = await familyApi.get(family?.id!);
            this.changeAddUserModalVisible(false);

            this.setState({ family: familyUpdated });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteParent = async (parent: FamilyMember) => {
        const { family } = this.state;

        try {
            this.setState({ loading: 'loading' });

            await familyApi.deleteParent(family?.id!, parent);

            message.success(this.props.intl.formatMessage({ id: 'status.action' }));

            const familyUpdated = await familyApi.get(family?.id!);

            this.changeModalDeleteParentVisible(false);
            this.setState({ family: familyUpdated });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 400, message: 'families.status.deleteParent' },
            ]);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    convertToOwner = async (parent: FamilyMember) => {
        const { family } = this.state;

        try {
            this.setState({ loading: 'loading' });

            await familyApi.addOwner(family?.id!, parent);

            message.success(this.props.intl.formatMessage({ id: 'status.action' }));

            const familyUpdated = await familyApi.get(family?.id!);

            this.changeModalConvertToOwnerVisible(false);
            this.setState({ family: familyUpdated });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deletePlayer = async (player: FamilyMember) => {
        const { family } = this.state;

        try {
            this.setState({ loading: 'loading' });

            await familyApi.deletePlayer(family?.id!, player);
            message.success(this.props.intl.formatMessage({ id: 'status.deleted' }));

            const familyUpdated = await familyApi.get(family?.id!);
            this.changeModalPlayerConfirmationVisible(false);

            this.setState({ family: familyUpdated });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 400, message: 'families.status.deletePlayer' },
            ]);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeAddUserModalVisible = (addUserModalVisible: boolean, userType?: string) => {
        this.setState({ addUserModalVisible, userType });
    };

    changeModalDeleteParentVisible = (modalDeleteParentVisible: boolean, parent?: FamilyMember) => {
        this.setState({ modalDeleteParentVisible, parent });
    };

    changeModalConvertToOwnerVisible = (modalConvertToOwnerVisible: boolean, parent?: FamilyMember) => {
        this.setState({ modalConvertToOwnerVisible, parent });
    };

    changeModalPlayerConfirmationVisible = (modalPlayerConfirmationVisible: boolean) => {
        this.setState({ modalPlayerConfirmationVisible });
    };

    changePlayer = async (player: FamilyMember) => {
        this.setState({ player, modalPlayerConfirmationVisible: true });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return (
                <>
                    <div className={styles.header}>
                        <span className={styles.headerSelect}>
                            <Select
                                size="large"
                                className={styles.addUser}
                                placeholder={<FormattedMessage id="parent.profile.addUser" />}
                                onSelect={(value: string) => this.changeAddUserModalVisible(true, value)}
                            >
                                <Select.Option value="parent">
                                    <FormattedMessage id="parent.profile.addParent" />
                                </Select.Option>
                                <Select.Option value="player">
                                    <FormattedMessage id="parent.profile.addPlayer" />
                                </Select.Option>
                            </Select>
                        </span>
                    </div>
                </>
            );
        } else {
            return <div className={styles.header}></div>;
        }
    };

    renderParentsTable = (): React.ReactElement | undefined => {
        const { family, loading } = this.state;
        const parents = family?.members ? family?.members.filter((m) => m.memberType !== 'Child') : [];

        const columns: ColumnsType<FamilyMember> = [
            {
                title: <FormattedMessage id="parent.name" />,
                dataIndex: 'givenName',
                key: 'givenName',
                width: 160,
                render: (value: string, parent: FamilyMember) => (
                    <Link to={`/parents/${parent.userId}`} className={styles.name}>
                        {stringService.getName(true, value, parent.familyName)}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="parent.username" />,
                dataIndex: 'userName',
                key: 'userName',
                width: 160,
                render: (value: string) => value,
            },
            {
                title: <FormattedMessage id="parent.email" />,
                dataIndex: 'email',
                key: 'email',
                render: (value: string, parent: FamilyMember) => value,
            },
            {
                title: <FormattedMessage id="parent.role" />,
                dataIndex: 'memberType',
                key: 'memberType',
                width: 130,
                render: (value: string, parent: FamilyMember) => value,
            },

            {
                title: <FormattedMessage id="parent.actions" />,
                width: 90,
                render: (value: string, parent: FamilyMember) => (
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key="delete"
                                    onClick={() => this.changeModalDeleteParentVisible(true, parent)}
                                    disabled={family?.members?.length === 1}
                                >
                                    <FormattedMessage id="parent.profile.delete" />
                                </Menu.Item>
                                {parent.memberType !== 'Owner' && (
                                    <Menu.Item
                                        key="owner"
                                        onClick={() => this.changeModalConvertToOwnerVisible(true, parent)}
                                    >
                                        <FormattedMessage id="parent.profile.owner" />
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                    >
                        <Button type="text" className={styles.actions}>
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                ),
            },
        ];

        return (
            <>
                <div className={styles.players}>
                    <FormattedMessage id="parent.profile.parents" />
                </div>
                <Table
                    dataSource={parents}
                    columns={columns}
                    pagination={false}
                    rowKey="userId"
                    loading={loading === 'initializing'}
                    className={styles.table}
                />
            </>
        );
    };

    renderPlayersTable = (): React.ReactElement | undefined => {
        const { family, loading } = this.state;
        const players = family?.members ? family?.members.filter((m) => m.memberType === 'Child') : [];

        const columns: ColumnsType<FamilyMember> = [
            {
                title: <FormattedMessage id="parent.name" />,
                dataIndex: 'givenName',
                key: 'givenName',
                width: 160,
                render: (value: string, player: FamilyMember) => (
                    <Link to={`/players/${player.userId}?sportId=${player.sportId}`} className={styles.name}>
                        {stringService.getName(true, value, player.familyName)}
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="parent.username" />,
                dataIndex: 'userName',
                key: 'userName',
                width: 160,
                render: (value: string) => value,
            },
            {
                title: <FormattedMessage id="parent.affiliates" />,
                dataIndex: 'organizations',
                key: 'organizations',
                width: 280,
                render: (value: string, player: FamilyMember) =>
                    player.organizations?.map((o) => {
                        return <div key={o.name}>{o.name}</div>;
                    }),
            },
            {
                title: <FormattedMessage id="parent.email" />,
                dataIndex: 'email',
                key: 'email',
                render: (value: string, player: FamilyMember) => value,
            },
            {
                title: <FormattedMessage id="parent.actions" />,
                width: 90,
                render: (value: string, player: FamilyMember) => (
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key="delete"
                                    onClick={() => this.changePlayer(player)}
                                    disabled={family?.members?.length === 1}
                                >
                                    <FormattedMessage id="parent.profile.delete" />
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type="text" className={styles.actions}>
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                ),
            },
        ];

        return (
            <>
                <div className={styles.players}>
                    <FormattedMessage id="parent.profile.players" />
                </div>
                <Table
                    dataSource={players}
                    columns={columns}
                    pagination={false}
                    rowKey="userId"
                    loading={loading === 'initializing'}
                    className={styles.table}
                />
            </>
        );
    };

    renderDeleteParentModal = (): React.ReactElement | undefined => {
        const { modalDeleteParentVisible, loading, parent } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="parent.profile.modalDelete.title" />}
                visible={modalDeleteParentVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={() => this.deleteParent(parent!)}
                okButtonProps={{ loading: loading === 'loading' }}
                onCancel={() => this.changeModalDeleteParentVisible(false)}
                className={styles.modal}
            >
                <FormattedMessage id="parent.profile.modalDelete.description" />
            </Modal>
        );
    };

    renderConvertToOwnerModal = (): React.ReactElement | undefined => {
        const { modalConvertToOwnerVisible, loading, parent } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="parent.profile.modalOwner.title" />}
                visible={modalConvertToOwnerVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={() => this.convertToOwner(parent!)}
                okButtonProps={{ loading: loading === 'loading' }}
                onCancel={() => this.changeModalConvertToOwnerVisible(false)}
                className={styles.modal}
            >
                <FormattedMessage id="parent.profile.modalOwner.description" />
            </Modal>
        );
    };

    renderPlayerConfirmationModal = (): React.ReactElement | undefined => {
        const { modalPlayerConfirmationVisible, loading, player } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="families.modal.title" />}
                visible={modalPlayerConfirmationVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={() => this.deletePlayer(player!)}
                okButtonProps={{ loading: loading === 'loading' }}
                onCancel={() => this.changeModalPlayerConfirmationVisible(false)}
                className={styles.modal}
            >
                {<FormattedMessage id="families.modal.description" />}
            </Modal>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { addUserModalVisible, userType, family } = this.state;
        return (
            <>
                {addUserModalVisible && (
                    <AddUserModal
                        onUpdate={this.refresh}
                        onCancel={() => this.changeAddUserModalVisible(false)}
                        userType={userType}
                        familyId={family?.id!}
                    />
                )}

                <div className={styles.familyNav}>
                    <div className={styles.flex}>
                        <Link to={`/groups`}>
                            <Button
                                type="text"
                                size="small"
                                className={styles.back}
                                icon={<Icon component={arrowBackSvg} />}
                            ></Button>
                        </Link>
                        <h1>{family?.name}</h1>
                    </div>
                </div>

                {this.renderParentsTable()}
                {this.renderPlayersTable()}
                {this.renderDeleteParentModal()}
                {this.renderConvertToOwnerModal()}
                {this.renderPlayerConfirmationModal()}
            </>
        );
    };

    render() {
        const { family } = this.state;
        const fullName =
            family &&
            `${family?.name} - ${this.props.intl.formatMessage({
                id: 'families',
            })}`;

        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent page="groups" content={this.renderContent} header={this.renderHeader} />
            </>
        );
    }
}
export default injectIntl(FamilyGroupPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    loading?: 'initializing' | 'loading';
    family?: Family;
    addUserModalVisible?: boolean;
    userType?: string;
    action?: string;
    parent?: FamilyMember;
    modalDeleteParentVisible?: boolean;
    modalConvertToOwnerVisible?: boolean;
    modalPlayerConfirmationVisible?: boolean;
    player?: FamilyMember;
}
