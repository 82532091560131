import { Col, Form, FormInstance, Input, message, Modal, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import positionApi from '../../../apis/PositionApi';
import userSportApi from '../../../apis/UserSportApi';
import CustomContext from '../../../context/CustomContext';
import { Position, Sport, User, UserSport } from '../../../model/Entities';
import notificationService from '../../../services/NotificationService';

class AddUserSportComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { positions: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initilizing' });
            const userSports = this.props.user.userSports;
            const sports = this.props.sports?.filter((s) => !userSports.map((u) => u.sportId).includes(s.id));
            this.setState({ sports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    save = async () => {
        const { userId } = this.props;
        try {
            this.setState({ loading: 'saving' });
            const values = await this.formRef.current?.validateFields();
            let userSport: UserSport = Object.assign({}, values);
            userSport = await userSportApi.create(userId, userSport);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            this.formRef.current?.resetFields();
            this.props.onUpdate(userSport);
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changePositions = async (value: number) => {
        try {
            this.setState({ loading: 'loading' });
            const positions = await positionApi.list(value);
            this.setState({ positions });
            this.formRef.current?.setFieldsValue({ position: undefined });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    render() {
        const { loading, positions, sports } = this.state;

        let sportOptions = sports?.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));

        const positionOptions = positions?.map((position) => (
            <Select.Option key={position.id} value={position.id!}>
                {position.code} - {position.name}
            </Select.Option>
        ));

        const jerseyNumbers = Array.from({ length: 100 }, (x, i) => i);

        const jerseyNumbersOptions = jerseyNumbers.map((jn) => (
            <Select.Option key={jn} value={jn}>
                {jn}
            </Select.Option>
        ));

        return (
            <Modal
                width={790}
                visible={true}
                onCancel={this.cancel}
                onOk={this.save}
                okText={<FormattedMessage id="button.save" tagName="span" />}
                okButtonProps={{ loading: loading === 'saving' }}
            >
                {sports && (
                    <Form ref={this.formRef} colon={false} layout="vertical">
                        <Row align="stretch" gutter={[28, 0]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={<FormattedMessage id="addUserSport.sportId" />}
                                    name="sportId"
                                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                >
                                    <Select size="large" onChange={(value: number) => this.changePositions(value)}>
                                        {sportOptions}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="stretch" gutter={[28, 0]}>
                            <Col xs={24} md={24}>
                                <Form.Item label={<FormattedMessage id="addUserSport.bio" />} name="bio">
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="stretch" gutter={[28, 0]}>
                            <Col xs={24} md={24}>
                                <Form.Item label={<FormattedMessage id="addUserSport.position" />} name="positions">
                                    <Select mode="multiple">{positionOptions}</Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="stretch" gutter={[28, 0]}>
                            <Col xs={24} md={12}>
                                <Form.Item label={<FormattedMessage id="addUserSport.offers" />} name="offers">
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label={<FormattedMessage id="addUserSport.bat" />} name="bat">
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="stretch" gutter={[28, 0]}>
                            <Col xs={24} md={12}>
                                <Form.Item label={<FormattedMessage id="addUserSport.throws" />} name="throws">
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label={<FormattedMessage id="addUserSport.commits" />} name="commits">
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="stretch" gutter={[28, 0]}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label={<FormattedMessage id="addUserSport.jerseyNumber" />}
                                    name="jerseyNumber"
                                >
                                    <Select mode="multiple">{jerseyNumbersOptions}</Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label={<FormattedMessage id="addUserSport.aboutMe" />} name="aboutMe">
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal>
        );
    }
}
export default injectIntl(AddUserSportComponent);

interface Props extends WrappedComponentProps {
    userId: string;
    onUpdate: (userSport: UserSport) => Promise<void>;
    onCancel: () => void;
    sports?: Sport[];
    user: User;
}

interface State {
    sports?: Sport[];
    positions: Position[];
    loading?: 'initilizing' | 'saving' | 'loading';
}
