import qs from 'qs';
import { Page } from '../model/Elements';
import { Organization } from '../model/Entities';
import apiAxios from './CustomAxios';

class OrganizationApi {
    list = async (
        limit: number,
        page: number,
        sortField?: string,
        sortOrder?: string,
        sportIds?: number[],
        searchText?: string,
    ): Promise<Page<Organization>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getSortField(sortField);
        const filteringSportIds = 'filtering.SportIds';
        const search = searchText ? searchText : undefined;
        const response = await apiAxios.get<Page<Organization>>('/v1/organizations', {
            params: { limit, page, [field]: order, [filteringSportIds]: sportIds, search },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        return response.data;
    };

    listAll = async (sportIds?: number[]): Promise<Organization[]> => {
        let page = 1;
        let total: number;
        let items: Organization[] = [];
        do {
            const itemsPage = await this.list(500, page, undefined, undefined, sportIds);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        return items;
    };

    get = async (organizationId?: string): Promise<Organization> => {
        const response = await apiAxios.get<Organization>(`/v1/organizations/${organizationId}`);
        return response.data;
    };

    create = async (organization: Organization): Promise<Organization> => {
        const response = await apiAxios.post<Organization>('/v1/organizations', organization);
        return response.data;
    };

    update = async (organization: Organization): Promise<Organization> => {
        const response = await apiAxios.put<Organization>(`/v1/organizations/${organization.id}`, organization);
        return response.data;
    };

    delete = async (organization: Organization): Promise<void> => {
        await apiAxios.delete(`/v1/organizations/${organization.id}`);
    };

    uploadLogo = async (organizationId: string, file: any): Promise<void> => {
        const formData: FormData = new FormData();
        file && formData.append('file', file);

        await apiAxios.post<void>(`/v1/organizations/${organizationId}/upload-logo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    private getSortField(sortField?: string): string {
        let field: string = 'sorting.Name';
        return field;
    }
}
const organizationApi: OrganizationApi = new OrganizationApi();
export default organizationApi;
