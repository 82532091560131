import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import collegeApi from '../../../apis/CollegeApi';
import userApi from '../../../apis/UserApi';
import CustomContext from '../../../context/CustomContext';
import { CollegeDivision, SimpleCollege } from '../../../model/Entities';
import notificationService from '../../../services/NotificationService';
import stateService from '../../../services/StateService';
import styles from './MapComponent.module.scss';
import MarkerComponent from './MarkerComponent/MarkerComponent';

class MapComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;

    constructor(props: Props) {
        super(props);
        this.state = { simpleColleges: [], focus: 8 };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.state !== this.props.state || prevProps.mile !== this.props.mile) {
            if (this.props.state === undefined && this.props.mile !== undefined) {
                this.filterByMiles(this.props.mile);
            } else if (this.props.mile === undefined && this.props.state !== undefined) {
                this.filterByState(this.props.state);
            }
        }
        if (prevProps.collegeId !== this.props.collegeId && this.props.collegeId !== undefined) {
            this.init();
        }
        if (prevProps.divisionId !== this.props.divisionId) {
            this.filterByDivision(this.props.divisionId);
        }
        if (prevProps.sportId !== this.props.sportId) {
            this.filterBySport(this.props.sportId);
        }
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });

            if (this.props.collegeId) {
                const college = await collegeApi.get(this.props.collegeId);
                const simpleColleges = [];
                simpleColleges.push(college);
                const focus = 12;
                this.setState({ simpleColleges, lat: college.lat, lng: college.lon, focus });
            } else {
                let lat, lng;

                navigator.geolocation.getCurrentPosition(
                    async (position: any) => {
                        lat = position.coords.latitude;
                        lng = position.coords.longitude;
                        if (lat === undefined && lng === undefined) {
                            lat = 40.73061;
                            lng = -73.935242;
                        } /* else if(this.context.auth?.organizationId) {
                        // TODO
                        const organization = await organizationApi.get(this.context.auth?.organizationId)
                        organization.state
                    } */

                        const simpleColleges = await collegeApi.listSimpleColleges(undefined, 80468, lat, lng);

                        this.setState({ lat, lng, simpleColleges });
                    },
                    async () => {
                        const userAccount = await userApi.getAccount(this.context.auth?.id!);
                        if (userAccount.state) {
                            const simpleColleges = await collegeApi.listSimpleColleges(userAccount.state);
                            const usaState = stateService.getCoordinates(userAccount.state);
                            this.setState({ simpleColleges, lat: usaState?.lat, lng: usaState?.lng });
                        } else {
                            lat = 40.73061;
                            lng = -73.935242;
                            const simpleColleges = await collegeApi.listSimpleColleges(undefined, 80468, lat, lng);

                            this.setState({ lat, lng, simpleColleges });
                        }
                    },
                );
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    filterByState = async (state: string) => {
        const { divisionId, sportId } = this.props;
        try {
            this.setState({ loading: true, focus: 5 });
            if (state === undefined || state === '') {
                let lat, lng;
                const focus = 7;
                navigator.geolocation.getCurrentPosition(
                    async (position: any) => {
                        lat = position.coords.latitude;
                        lng = position.coords.longitude;
                        if (lat === undefined && lng === undefined) {
                            lat = 40.73061;
                            lng = -73.935242;
                        } /* else if(this.context.auth?.organizationId) {
                        // TODO
                        const organization = await organizationApi.get(this.context.auth?.organizationId)
                        organization.state
                    } */

                        const simpleColleges = await collegeApi.listSimpleColleges(
                            undefined,
                            80468,
                            lat,
                            lng,
                            divisionId,
                            sportId,
                        );

                        this.setState({ lat, lng, simpleColleges, focus });
                    },
                    async () => {
                        const userAccount = await userApi.getAccount(this.context.auth?.id!);
                        if (userAccount.state) {
                            const simpleColleges = await collegeApi.listSimpleColleges(
                                userAccount.state,
                                undefined,
                                undefined,
                                undefined,
                                divisionId,
                                sportId,
                            );
                            const usaState = stateService.getCoordinates(userAccount.state);
                            this.setState({ simpleColleges, lat: usaState?.lat, lng: usaState?.lng });
                        } else {
                            lat = 40.73061;
                            lng = -73.935242;
                            const simpleColleges = await collegeApi.listSimpleColleges(
                                undefined,
                                80468,
                                lat,
                                lng,
                                divisionId,
                                sportId,
                            );

                            this.setState({ lat, lng, simpleColleges, focus });
                        }
                    },
                );
            } else {
                const simpleColleges = await collegeApi.listSimpleColleges(
                    state,
                    undefined,
                    undefined,
                    undefined,
                    divisionId,
                    sportId,
                );
                const usaState = stateService.getCoordinates(state);
                const focus = 7;

                if (usaState) {
                    this.setState({ simpleColleges, lat: usaState.lat, lng: usaState.lng, focus });
                } else {
                    this.setState({ simpleColleges, focus });
                }
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    filterByMiles = async (mile: number) => {
        const { divisionId, sportId } = this.props;
        try {
            this.setState({ loading: true, focus: 6 });

            let lat, lng;
            navigator.geolocation.getCurrentPosition((position: any) => {
                lat = position.coords.latitude;
                lng = position.coords.longitude;
            });
            if (lat === undefined || lng === undefined) {
                lat = 40.73061;
                lng = -73.935242;
            }

            const simpleColleges = await collegeApi.listSimpleColleges(undefined, mile, lat, lng, divisionId, sportId);
            const focus = 7;

            this.setState({ simpleColleges, lat, lng, focus });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    filterByDivision = async (divisionId?: number) => {
        const { sportId } = this.props;
        try {
            this.setState({ loading: true });

            let lat, lng;
            navigator.geolocation.getCurrentPosition((position: any) => {
                lat = position.coords.latitude;
                lng = position.coords.longitude;
            });
            if (lat === undefined || lng === undefined) {
                lat = 40.73061;
                lng = -73.935242;
            }

            let simpleColleges;
            if (this.props.state) {
                simpleColleges = await collegeApi.listSimpleColleges(
                    this.props.state,
                    undefined,
                    undefined,
                    undefined,
                    divisionId,
                    sportId,
                );
            } else if (this.props.mile) {
                simpleColleges = await collegeApi.listSimpleColleges(
                    undefined,
                    this.props.mile,
                    lat,
                    lng,
                    divisionId,
                    sportId,
                );
            } else {
                simpleColleges = await collegeApi.listSimpleColleges(undefined, 80468, lat, lng, divisionId, sportId);
            }

            this.setState({ simpleColleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    filterBySport = async (sportId?: number) => {
        const { divisionId } = this.props;
        try {
            this.setState({ loading: true });

            let lat, lng;
            navigator.geolocation.getCurrentPosition((position: any) => {
                lat = position.coords.latitude;
                lng = position.coords.longitude;
            });
            if (lat === undefined || lng === undefined) {
                lat = 40.73061;
                lng = -73.935242;
            }

            let simpleColleges;
            if (this.props.state) {
                simpleColleges = await collegeApi.listSimpleColleges(
                    this.props.state,
                    undefined,
                    undefined,
                    undefined,
                    divisionId,
                    sportId,
                );
            } else if (this.props.mile) {
                simpleColleges = await collegeApi.listSimpleColleges(
                    undefined,
                    this.props.mile,
                    lat,
                    lng,
                    divisionId,
                    sportId,
                );
            } else {
                simpleColleges = await collegeApi.listSimpleColleges(undefined, 80468, lat, lng, divisionId, sportId);
            }

            this.setState({ simpleColleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/
    renderMarkers = (): React.ReactElement[] | undefined => {
        const { collegeDivisions } = this.props;
        const { simpleColleges } = this.state;

        return simpleColleges.map((sc) =>
            sc.lat !== undefined && sc.lon !== undefined ? (
                <MarkerComponent
                    key={sc.id}
                    lat={sc.lat}
                    lng={sc.lon}
                    collegeId={sc.id}
                    collegeDivisions={collegeDivisions}
                />
            ) : (
                <></>
            ),
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { lat, lng, focus } = this.state;

        return (
            <div className={styles.googleMaps}>
                {lat && lng && (
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyA8ghrZRcPK_8n1S3I0yyph3h8c0IYUT_o' }}
                        center={{
                            lat: lat,
                            lng: lng,
                        }}
                        zoom={focus}
                    >
                        {this.renderMarkers()}
                    </GoogleMapReact>
                )}
            </div>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(MapComponent);

interface Props extends WrappedComponentProps {
    state?: string;
    mile?: number;
    collegeId?: number;
    collegeDivisions: CollegeDivision[];
    divisionId?: number;
    sportId?: number;
}

interface State {
    loading?: boolean;
    simpleColleges: SimpleCollege[];
    lat?: number;
    lng?: number;
    focus: number;
}
