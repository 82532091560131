import Icon from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, message, Modal, Popconfirm, Row, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import organizationSportsApi from '../../../../../../apis/OrganizationSportsApi';
import CustomContext from '../../../../../../context/CustomContext';
import { OrganizationSport, Sport } from '../../../../../../model/Entities';
import { ReactComponent as removeSvg } from '../../../../../../resources/images/ico-thrash.svg';
import notificationService from '../../../../../../services/NotificationService';
import styles from './OrganizationSportModal.module.scss';

class OrganizationSportModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { organizationSports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { organizationSport } = this.props;
        try {
            this.setState({ loading: 'initializing' });

            if (organizationSport) {
                this.formRef.current?.setFieldsValue(organizationSport);
            } else {
                this.new();
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    new = async () => {
        const { organizationId } = this.props;
        const organizationSport: OrganizationSport = {};
        const organizationSports = await organizationSportsApi.get(organizationId);
        this.formRef.current!.setFieldsValue(organizationSport);
        this.setState({ organizationSports });
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    save = async () => {
        const { organizationSport, organizationId } = this.props;
        try {
            this.setState({ loading: 'saving' });

            const values = await this.formRef.current?.validateFields();

            if (organizationSport) {
                await organizationSportsApi.update(organizationId, values?.name, values?.sportId);
            } else {
                await organizationSportsApi.create(organizationId, values?.name, values?.sportId);
            }

            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    delete = async () => {
        const { organizationId, organizationSport } = this.props;

        try {
            this.setState({ loading: 'deleting' });

            await organizationSportsApi.delete(organizationId, organizationSport?.sportId!);
            message.success(this.props.intl.formatMessage({ id: 'organization.status.sportDeleted' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (error.response.status === 409) {
                message.error(this.props.intl.formatMessage({ id: 'organization.status.organizationNotEmpty' }), 6);
            } else {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    render() {
        const { loading, organizationSports } = this.state;
        const { sports } = this.props;

        const sportsAvailable = sports.filter((s) => !organizationSports.find((o) => o.sportId === s.id));

        const sportOptions = sportsAvailable?.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));

        return (
            <Modal
                width={570}
                visible={true}
                onCancel={this.cancel}
                footer={[
                    <div className={styles.buttons}>
                        <div>
                            {this.props.organizationSport && (
                                <Popconfirm
                                    title={<FormattedMessage id={'organization.deleteSport'} />}
                                    onConfirm={this.delete}
                                    okText={<FormattedMessage id="button.yes" />}
                                    cancelText={<FormattedMessage id="button.no" />}
                                >
                                    <Button type="text" icon={<Icon component={removeSvg} />}>
                                        <FormattedMessage id="button.delete" tagName="span" />
                                    </Button>
                                </Popconfirm>
                            )}
                        </div>
                        <div className={styles.flex}>
                            <Button shape={'round'} onClick={this.cancel}>
                                <FormattedMessage id="button.cancel" />
                            </Button>
                            <Button shape={'round'} type="primary" onClick={this.save} loading={loading === 'saving'}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                        </div>
                    </div>,
                ]}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="organization.modal.name" />}
                                name="name"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="organization.modal.sport" />}
                                name="sportId"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select disabled={this.props.organizationSport ? true : false}>{sportOptions}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(OrganizationSportModal);

interface Props extends WrappedComponentProps {
    onUpdate: () => void;
    onCancel: () => void;
    organizationId: string;
    sports: Sport[];
    organizationSport?: OrganizationSport;
}

interface State {
    loading?: 'initializing' | 'saving' | 'deleting';
    organizationSports: OrganizationSport[];
}
