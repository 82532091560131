import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, FormInstance, Input, message, Row, Select } from 'antd';
import React, { Component } from 'react';
import { IWithGoogleReCaptchaProps, withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import eventApi from '../../apis/EventApi';
import familyApi from '../../apis/FamilyApi';
import signUpApi from '../../apis/SignUpApi';
import sportApi from '../../apis/SportApi';
import LayoutMarketingComponent from '../../components/LayoutMarketingComponent/LayoutMarketingComponent';
import CustomContext from '../../context/CustomContext';
import { Event, Family, ParentRegistered, Sport } from '../../model/Entities';
import { countries, CountryType } from '../../model/Types';
import authService from '../../services/AuthService';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import stateService from '../../services/StateService';
import EventInfoComponent from './EventInfoComponent/EventInfoComponent';
import styles from './SignUpPage.module.scss';

class SignUpPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { sports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            // get the eventId to register in
            const params = new URLSearchParams(window.location.search);
            const eventId = params.get('eventId') ? +params.get('eventId')! : undefined;

            // if we have an eventId we call the event and sports apis
            let event;
            let sports: Sport[] = [];
            if (eventId) {
                const responses = await Promise.all([eventApi.get(eventId), sportApi.listAll()]);
                event = responses[0];
                sports = responses[1];
            }

            let family;
            if (this.context.auth && this.context.auth.authorities.includes('ROLE_PARENT')) {
                family = await familyApi.get(this.context.auth.familyId!);
            }

            this.setState({ event, sports, family });

            this.formRef.current?.setFieldsValue({ marketingEmails: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    save = async (values: any) => {
        const { updateAuth } = this.context;
        const { event } = this.state;
        try {
            this.setState({ loading: 'saving' });

            if (this.props.googleReCaptchaProps.executeRecaptcha) {
                const parent: ParentRegistered = Object.assign({}, values);
                parent.recaptcha = await this.props.googleReCaptchaProps.executeRecaptcha('signup');

                await signUpApi.createParent(parent);
                message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

                const auth = await authService.signIn(parent.userName!, parent.password!);
                updateAuth(auth);

                this.props.history.push(`/signup/child?eventId=${event?.id}`);
            } else {
                // TODO: DIPLAY NOTIFICATION ERROR ABOUT RECAPTCHA
            }
        } catch (error: any) {
            if (error.response.status === 409 && error.response.data) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: error.response.data.message as string },
                ]);
            } else {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /**
     * Reset the state when the country is changed in the form
     * @param country
     */
    changeCountry = (country?: CountryType) => {
        this.formRef.current?.resetFields(['state']);
        this.setState({ country });
    };

    /*** COMPONENTS ***/
    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderForm = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical" className={styles.form}>
                <h2>
                    <FormattedMessage id="signup.parentProfile" />
                </h2>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signup.firstName" />}
                            name="givenName"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                    message: <FormattedMessage id="status.name" />,
                                },
                            ]}
                        >
                            <Input maxLength={64} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signup.lastName" />}
                            name="familyName"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                    message: <FormattedMessage id="status.name" />,
                                },
                            ]}
                        >
                            <Input maxLength={64} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signup.email" />}
                            name="email"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    type: 'email',
                                    message: <FormattedMessage id="status.email.invalid" />,
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signup.phone" />}
                            name="phoneNumber"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                    message: <FormattedMessage id="status.phoneNumber" />,
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signup.username" />}
                            name="userName"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[a-z0-9_.-]{4,32}$/,
                                    message: <FormattedMessage id="status.characters" />,
                                },
                            ]}
                        >
                            <Input maxLength={32} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signup.password" />}
                            className={styles.pass}
                            name="password"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                                    message: <FormattedMessage id="status.password" />,
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password
                                type="password"
                                minLength={8}
                                size="large"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signup.repeatPassword" />}
                            name="repeatPassword"
                            className={styles.pass}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                                    message: <FormattedMessage id="status.password" />,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(<FormattedMessage id="status.passwordEqual" />);
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                type="password"
                                minLength={8}
                                size="large"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <h2>
                    <FormattedMessage id="signup.address" />
                </h2>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signup.streetAddress" />}
                            name="address"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signup.city" />}
                            name="city"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signup.country" />}
                            name="country"
                            className={styles.select}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select onChange={(value: CountryType) => this.changeCountry(value)}>
                                {countryOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signup.state" />}
                            name="state"
                            className={styles.select}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select>{this.renderStates()}</Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signup.zip" />}
                            name="zipCode"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={24} className={styles.formRow24}>
                        <Form.Item
                            label={<FormattedMessage id="signup.emailUpdates" />}
                            name="marketingEmails"
                            valuePropName="checked"
                        >
                            <Checkbox className={styles.checkbox}>
                                <FormattedMessage id="signup.emailMe" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>

                <p>
                    <FormattedMessage id="signup.termsOfService1" />
                    <Link to="/terms-conditions" target="_blank">
                        <FormattedMessage id="signup.termsOfService2" />
                    </Link>
                    <FormattedMessage id="signup.termsOfService3" />
                    <Link to="/privacy-policy" target="_blank">
                        <FormattedMessage id="signup.termsOfService4" />
                    </Link>
                </p>

                <div className={styles.buttons}>
                    <Form.Item>
                        <Link to="/events">
                            <Button type="primary" size="large" className={styles.btnSecondary}>
                                <FormattedMessage id="button.cancel" tagName="span" />
                            </Button>
                        </Link>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={loading === 'saving'}
                            className={styles.btnMain}
                        >
                            <FormattedMessage id="signup.completeAndGo" tagName="span" />
                        </Button>
                    </Form.Item>
                </div>

                <p className={styles.googleTerms}>
                    <FormattedMessage id="signup.googleTermsOfService1" />
                    <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                        <FormattedMessage id="signup.googleTermsOfService2" />
                    </a>
                    <FormattedMessage id="signup.googleTermsOfService3" />
                    <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
                        <FormattedMessage id="signup.googleTermsOfService4" />
                    </a>
                    <FormattedMessage id="signup.googleTermsOfService5" />
                </p>
            </Form>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { event, sports } = this.state;
        return (
            <div className={styles.registrationForm}>
                <div className={styles.row}>
                    <div className={styles.flex}>
                        <aside>{event && <EventInfoComponent event={event} sports={sports} />}</aside>

                        <section className={styles.content}>
                            <div className={styles.header}>
                                <h1>
                                    <FormattedMessage id="signup.registration" />
                                </h1>
                                <p>
                                    <FormattedMessage id="signup.description" />
                                </p>
                                <h2>
                                    <FormattedMessage id="signup.haveAccount" />
                                </h2>
                                <Link to="/signin">
                                    <Button type="primary">
                                        <FormattedMessage id="signup.login" />
                                    </Button>
                                </Link>
                            </div>

                            {this.renderForm()}
                        </section>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { loading, family, event } = this.state;

        // get the eventId to register in
        const params = new URLSearchParams(window.location.search);
        const eventId = params.get('eventId') ? +params.get('eventId')! : undefined;

        if (event) {
            if (
                this.context.auth &&
                this.context.auth.authorities.includes('ROLE_PARENT') &&
                family &&
                family.members?.filter((m) => m.memberType === 'Child').length === 0
            ) {
                return <Redirect to={`/signup/child?eventId=${eventId}`} />;
            } else if (this.context.auth && this.context.auth.authorities.includes('ROLE_PARENT')) {
                return <Redirect to={`/signup/payment?eventId=${eventId}`} />;
            } else if (this.context.auth) {
                return <Redirect to="/" />;
            } else {
                return (
                    <>
                        <HeadMetadata titleKey="signup.meta.title" />
                        <LayoutMarketingComponent content={this.renderContent} loading={loading === 'initializing'} />
                    </>
                );
            }
        } else {
            return <></>;
        }
    }
}
export default injectIntl(withGoogleReCaptcha(SignUpPage));

interface Props extends RouteComponentProps, WrappedComponentProps, IWithGoogleReCaptchaProps {}

interface State {
    event?: Event;
    loading?: 'saving' | 'initializing';
    sports: Sport[];
    country?: CountryType;
    family?: Family;
}
