import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import LayoutMarketingComponent from '../../../components/LayoutMarketingComponent/LayoutMarketingComponent';
import HeadMetadata from '../../../services/HeadMetadata';
import styles from './PrivacyPolicyPage.module.scss';

class PrivacyPolicyPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    /** METHODS **/

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <div className={styles.content}>
                <h1>Privacy Policy</h1>
                <p>This Top 100 Sports Privacy Policy (“Privacy Policy”) discloses the practices of Top 100 Sports, Inc. (“Top 100 Sports,” “we,” “our” or “us”) concerning information we obtain by and through your access to and/or use of: (a) the Top 100 Sports platform (“Platform”); (b) the website located at https://top100sports.com (the “Site”); (c) the Top 100 Sports mobile application (the “App”); (d) the content, functionality and services offered by or through the Platform or the Site (collectively, the “Content”); and (e) any of our other services or products.  The Platform, Site, App, Content and any of our other services and products are collectively referred to herein as the “Services.”</p>
                <p>Top 100 Sports is committed to respecting your privacy and recognizing your need for appropriate protection and management of personally identifiable information that you share with us. The purpose of this Privacy Policy is to explain: (i) the types of information Top 100 Sports obtains about visitors to our Site, users of our App and users of our other Services; (ii) how the information is obtained; (iii) how it is used; (iv) the choices you have regarding our use of; and (iv) your ability to review and correct, the information. Please read this Privacy Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, you do not have permission to access and/or use the Services. By using the Services, you are accepting and consenting to the use of your information as described in this Privacy Policy.</p>
                <p>Users with disabilities who wish to access this Privacy Policy in an alternative format can contact us by emailing us at: privacy@leagueapps.com; calling us at: (844) 486-7100; or sending us U.S. mail to: Top 100 Sports, 373 Spencer St, Suite 101, Syracuse, NY 13204.</p>
                <p>The Top 100 Sports Platform may be used and accessed by sports organizers who use the Top 100 Sports sports management software to interact with sports registrants and/or by the sports registrants themselves. The term “sports registrants” refers to member account holders who are either: (A) sports registrants over sixteen (16) years of age; or (B) the parents or legal guardians of youth sports registrants under sixteen (16) years of age. As such, certain features of the Services may be offered to users directly by Top 100 Sports, or through one or more third party league organizers who offer a white label or private-label version of the Services to their respective sports registrants.</p>
                <p>This Privacy Policy describes how Top 100 Sports collects, manages, and uses information collected through the Services but your use of and access to the Services may also be governed by additional policies or agreements entered into directly between sports registrants and organizers. Top 100 Sports is not responsible for the practices of third parties that Top 100 Sports does not own or control or individuals that Top 100 Sports does not employ or manage. However, sports organizers agree to provide their respective sports registrants with at least the same level of protection as that provided to users by Top 100 Sports by and through this Privacy Policy. If you have any questions about whether any third party sports organizer policies or agreements apply to your use of the Site, App and/or Services, please contact the applicable sports organizer.</p>
                <p><strong>Your California Privacy Rights</strong></p>
                <p><em>Shine the Light.</em> If you are a resident of the State of California and would like to learn how your “personal information” (as defined in the Shine the Light Law, Cal. Civ. Code § 1798.83) is shared with third parties, what categories of personal information that we have shared with third parties in the preceding year, as well as the names and addresses of those third parties, email us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a>; call us at: (844) 486-710; or send us U.S. mail to: Top 100 Sports, 373 Spencer St. Suite 101, Syracuse NY 13204.  Further, if you are a resident of the State of California and would like to opt-out from the disclosure of your personal information to any third party for marketing purposes, please email us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a>; call us at: (844) 486-7100; or send us U.S. mail to: Top 100 Sports, 373 Spencer St. Suite 101, Syracuse NY 13204.</p>
                <p><em>California Consumer Privacy Act of 2018 (“CCPA”).</em> In addition to the foregoing, if you are a resident of the State of California certain other privacy-related rights may apply to you in accordance with the CCPA, including the right to opt-out of our sale of your personal information, as well as the right to know what personal information about you we have collected, whether your personal information was shared with third-parties in the preceding year and, if so, what categories of personal information were shared, as well as the categories of third parties with whom we shared that personal information.  Please see our “Privacy Provisions for California Residents” below for a more complete description of your rights under the CCPA as a California resident.</p>
                <p><strong>Your Nevada Privacy Rights</strong></p>
                <p>If you are a resident of the State of Nevada and would like to opt-out from the sale of your personal information to any third party data broker, please email us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a>; call us at: (844) 486-7100; or send us U.S. mail to: Top 100 Sports, 373 Spencer St. Suite 101, Syracuse NY 13204.</p>
                <h2>1. WHAT INFORMATION WE COLLECT</h2>
                <p>Please see our Privacy Provisions for California Residents below for additional details regarding the categories of personal information collected.</p>
                <p>We collect personally identifiable information only when you provide it to us. If you do not wish for your personally identifiable information to be used or collected, please do not submit it to us. <strong>Do not submit personally identifiable information about others (for example, sports registrants) without their permission.</strong> Whether or not you choose to provide personal information is completely your own choice. But if you choose not to provide the information we request, you may be unable to use certain features of the Services.  We collect several types of information from and about users of the Services, some of which is personally identifiable and some of which is non-identifying or aggregated, including information:</p>
                <ul>
                    <li>by which you may be personally identified, such as name, postal address, e-mail address, telephone number, credit card and other billing/payment information, date of birth and any other identifier by which you may be contacted online or offline (collectively, “personal information”);</li>
                    <li>that is about you, but individually does not identify you; and/or</li>
                    <li>about your Internet connection and browser, as well the computer, mobile device and/or other equipment that you use to access the Services and your usage details.</li>
                </ul>
                <p>We collect this information (i) directly from you when you provide it to us, (ii) automatically as you use the Services (which information may include usage details, Internet Protocol (IP) addresses and information collected through cookies and other tracking technologies); and (iii) from third parties (such as our business partners).</p>
                <p>In connection with certain aspects of the Services, we may request, collect and/or display some of the following types of information from/about our registered users:</p>
                <ul>
                    <li>Member Account Information: When you create a “Member Account” you may be asked to provide the following information: name, email address, phone number, organization name, role in organization, number of players in your organization, organization website and zip code. If you subscribe to paid features or functions of the Services, we may also collect billing information from you.</li>
                    <li>Sports Organization Information: Through your use of our Services, we may also ask you to provide statistics, demographic information, and/or other information relating to your sports organization or your organization’s sports registrants. We collect this information to allow you to use more of the features and functions related to our Services, including scheduling, sports management and tracking tools. <strong>You should not submit any information regarding your organization or its sports registrants that you are not fully authorized to submit with the informed consent of the applicable sports registrants in each instance.</strong></li>
                </ul>
                <p>We may also collect the following information from any visitor to our Site/user of our App, including, but not limited to, registered users:</p>
                <ul>
                    <li>•	IP Address Information and Other Information Collected Automatically: Top 100 Sports automatically receives and records information from your web browser when you interact with the Services and/or the Site, including your IP address and cookie information. This information may be used for service functionality, to help us understand how users access a website, to fight spam/malware, to facilitate the collection of data concerning your interaction with the Services or the Site and for other similar purposes.</li>
                    <li>•	We may collect certain non-personally identifiable information about you and your mobile device when you access the App and/or visit certain App pages.  This non-personally identifiable information includes, without limitation, the following information pertaining to your mobile device and your use of the App: (a) mobile device platform type; (b) mobile device identifier; (c) SDK version; (d) API key (identifier for the App); (e) App version; (f) the model type, manufacture and Android version of the mobile device; (g) your App session start/stop time and date; and (h) the actions taken while utilizing the App.  We use the non-personally identifiable information identified in this paragraph to improve the design and content of the Services and to enable us to personalize your App experience.  We also may use this information in the aggregate to analyze Services usage, alter existing Services and/or develop new or different Services. <strong>You understand and agree that we may send push notifications about the Services to you by and through your mobile device.</strong> Top 100 Sports does not use geolocation technology to track App users’ locations.</li>
                    <li>•	Metrics and Performance Statistics: Top 100 Sports may collect and track metrics and performance statistics relating to use of the Services, App and Site, and Top 100 Sports may use these metrics and statistics to generate analytics. This information may include, but is not limited to: (i) the IP address and physical location of the devices accessing the App and/or the URLs provided through the Services; (ii) the referring websites or services; (iii) the time and date of each access and the amount of time spent on certain sections of the App and/or Site. These metrics and analytics may be used by Top 100 Sports and its partners to improve their respective websites, mobile applications and services and to conduct market research.</li>
                    <li>Cookies: Our Site uses or may use “cookies” to gather non-personally identifiable information. A cookie is a small amount of data that is sent to your browser from a web server and stored on your computer’s hard drive. To find out more about cookies, please visit www.cookiecentral.com.  Generally, we use cookies to store information about your preferences and activity during and after your visit to a website. Most browsers automatically accept cookies, but you can usually refuse cookies, or selectively accept certain cookies, by adjusting the preferences in your browser. If you turn off cookies, though, there may be some features of the Services that will not be available to you and some Site pages may not display properly. By using the Site and/or Services, you consent to Top 100 Sports use of cookies.</li>
                </ul>
                <p>You also may provide information to be published or displayed on public areas of the Services, to be transmitted to other users of the Services or to be transmitted to third parties (collectively, “User Data”). User Data that is posted on and/or transmitted by and/or through the services is done at your own risk. Although we limit access to certain information, please be aware that no security measures are perfect or impenetrable.</p>
                <p>Additionally, we cannot control the actions of other users of the Services. Therefore, we cannot and do not guarantee that User Data will not be viewed by unauthorized persons.</p>
                <p>Behavioral Tracking:  Neither Top 100 Sports, nor any third parties, place cookies or other tracking technology on the Site or in the App that tracks user activities after they leave the Site and/or exit the App, as applicable. Therefore, the Site and App do not respond to Do Not Track (DNT) signals.</p>
                <p>Cross Device Tracking: Top 100 Sports tracks users’ use of the Services across various devices, including your personal computer and mobile device, in order to optimize and personalize your Services experience.</p>
                <h2>2. HOW WE USE INFORMATION ABOUT YOU</h2>
                <p>Please see our Privacy Provisions for California Residents below for details regarding our use of personal information.</p>
                <p>Generally speaking, we use the information from and about you to: (a) provide you with the features and functions of the Site, App and/or the Services; (b) communicate with you regarding our Services and/or events, and Top 100 Sports and/or third party products, services, opportunities or promotions that may be of interest to you; (c) provide users with superior service and with information, opportunities and features targeted to your organization; (d) provide all visitors with a smooth, efficient, and personalized experience while using our Site, App and/or Services.</p>
                <p>By submitting your personal information by and through the Services, you agree that such act constitutes an inquiry and/or application for purposes of the Amended Telemarketing Sales Rule (16 CFR §310 et seq.), as amended from time to time (the “Rule”) and applicable state do-not-call regulations.  As such, notwithstanding that your telephone number may be listed on the Federal Trade Commission’s Do-Not-Call List, and/or on applicable state do-not-call lists, we retain the right to contact you via telemarketing in accordance with the Rule and applicable state do-not-call regulations.</p>
                <p>Where you provide “prior express consent” within the meaning of the Telephone Consumer Protection Act (47 USC § 227), and its implementing regulations adopted by the Federal Communications Commission (47 CFR § 64.1200), as amended from time-to-time (“TCPA”), you consent to receive, from Top 100 Sports, telephone calls, including artificial voice calls, pre-recorded messages and/or calls (including text alerts via SMS text messages delivered via automated technology, to the telephone number(s) that you provided.  Please note that you are not required to provide this consent in order to obtain access to the Services, and your consent simply allows Top 100 Sports to contact you via these means.  <strong>Please be advised that by agreeing to this Privacy Policy, you are obligated to immediately inform us if and when the telephone number that you have previously provided to us changes.  Without limiting the foregoing, if you: (a) have your telephone number reassigned to another person or entity; (b) give up your telephone number so that it is no longer used by you; (c) port your telephone number to a landline or vice versa; or (d) otherwise stop using that telephone number for any reason (collectively “Phone Number Change”), you agree that you shall promptly notify Top 100 Sports of the Phone Number Change via e-mail at: <a href="mailto:info@top100sports.com">info@top100sports.com</a>, or by using one of the methods set forth in the “Contact Us” section below.</strong></p>
                <p>We do not use your personal identifiable information to make automated decisions. As discussed above, our web servers may automatically collect non-personally identifiable information, including the web page from which a visitor enters our Site and/or accesses on or through our App, as applicable, which pages/sections a visitor visits/accesses on our Site/App, and how much time a visitor spends on each page/section. We aggregate this information and use it to evaluate and improve our Site, App and Services and use the information for market research purposes, including for our third party sponsors/partners. We may also use information for research purposes, to develop new products and services, to analyze customer behavior and to help us understand how visitors use our Site, App and Services and to measure interest in the various pages, to gather statistical information, improve the content of our Site and App, to make our Site and App easier to use, or to provide information and statistics to our sponsors/partners. Additionally, we may use information that we collect about you or that you provide to us in any other way we may describe when you provide the information, as well as for any other purposes with your consent.</p>
                <p>Following termination or deactivation of an account, we may retain information and content for backup, archival, audit or other business purposes, or otherwise in accordance with applicable laws. We may maintain anonymized, de-identified or aggregated data, including usage data, for analytics purposes. If you have any questions about data retention or deletion, please e-mail us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a></p>
                <h2>3.     HOW WE PROTECT YOUR INFORMATION</h2>
                <p>We endeavor to safeguard and protect our users’ personal information.  When users make personal information available to us, their personal information is protected both online and offline (to the extent that we maintain any personal information offline).  Where our registration/application process prompts users to enter sensitive data (such as medical information, driver’s license/ID card number, health insurance information, data collected from an automated license plate recognition system, Social Security Number and credit card information) and when we store and transmit such sensitive data, that personal information is encrypted with advanced TLS (Transport Layer Security).</p>
                <p>Access to your personal information is strictly limited, and we take reasonable measures to ensure that your personal information is not accessible to the public.  All of our users’ personal information is restricted in our offices, as well as the offices of our third-party service providers.  Only employees or third-party agents who need user personal information to perform a specific job are granted access to user personal information.  Our employees are dedicated to ensuring the security and privacy of all user personal information.  Employees not adhering to our firm policies are subject to disciplinary action.  The servers that we store user personal information on are kept in a secure physical environment.  We also have security measures in place to protect the loss, misuse and alteration of personal information under our control.</p>
                <p>Please be advised, however, that while we take every reasonable precaution available to protect your data, no storage facility, technology, software, security protocols or data transmission over the Internet or via wireless networks can be guaranteed to be 100% secure.  Computer hackers that circumvent our security measures may gain access to certain portions of your personal information, and technological bugs, errors and glitches may cause inadvertent disclosures of your personal information; provided, however, that any attempt to breach the security of the network, our servers, databases or other hardware or software may constitute a crime punishable by law.  For the reasons mentioned above, we cannot warrant that your personal information will be absolutely secure.  Any transmission of data at or through the Site, App, other Services or otherwise via the Internet or wireless networks, is done at your own risk.</p>
                <p>In compliance with applicable federal and state laws, we shall notify you and any applicable regulatory agencies in the event that we learn of an information security breach with respect to your personal information.  You will be notified via e-mail in the event of such a breach.  Please be advised that notice may be delayed in order to address the needs of law enforcement, determine the scope of network damage, and to engage in remedial measures.</p>
                <h2>4.     HOW WE SHARE YOUR PERSONAL INFORMATION WITH THIRD PARTIES</h2>
                <p>Please see our Privacy Provisions for California Residents below for details regarding our sharing of personal information.  We may share your information with third parties as follows:</p>
                <ul>
                    <li>With Service Providers: We may use other companies to perform services including, without limitation, facilitating some aspects of our Site or Services such as sending emails, processing payments, and fulfilling customer service requests. These other companies may be supplied with or have access to your information solely for the purpose of providing these services to you on our behalf.</li>
                    <li>With Business Partners/Affiliates/Sponsors: We may share and/or sell your information for marketing purposes with/to the businesses with which we partner and/or affiliates or sponsors for the sports programs or platforms that you participate in. The information we share, and how it is used by our partners, may vary depending on whether you are a sports organizer or a sports registrant and whether you use a platform that is offered through a sponsored or affiliate program. For sports organizers and for sports registrants of sponsored or affiliated programs, our partners may contact you regarding products, services, events, opportunities or promotions that may be of interest to you or your sports organization. For sports registrants, our partners will only contact you with the approval of your sports organizer or if you otherwise opt in or consent to be contacted. You may also be given the opportunity to opt-in to receive additional communications from third parties relating to information, events or promotions that may be of interest to you or your sports organization.</li>
                    <li>Special Circumstances: We also may disclose your information, including personally identifiable information, in the following circumstances:
                        <ul>
                            <li>In response to a subpoena or similar investigative demand, a court order, or other request from a law enforcement or government agency; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law;</li>
                            <li>When we believe disclosure is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing; to protect and defend the rights, property or safety of our company, our users, our employees, or others; to comply with applicable law or cooperate with law enforcement; or to enforce our Site/App terms and conditions or other agreements or policies.</li>
                            <li>In connection with a corporate transaction, such as the sale of all or a portion of our business, a divestiture, merger, consolidation, or asset sale, or in the event of bankruptcy. We will endeavor to contact you in the event of any of these transactions, but we do not promise to do so.</li>
                        </ul>
                    </li>
                </ul>
                <p>We may also share aggregated, non-personally identifiable information publicly, including with users, partners or the press in order to, for example, demonstrate how our Services are used, evaluate industry trends, or to create our marketing materials. Any aggregated information shared this way will not contain any personal information.</p>
                <h2>5.     HOW TO UPDATE, MODIFY OR REMOVE PERSONAL INFORMATION</h2>
                <p>Please see our Privacy Provisions for California Residents below for instructions on how California Residents can access and/or delete personal information that we have collected.</p>
                <p>At your request, we will: (a) inform you of what personal information we have on file for you; (b) amend the personal information that we have on file for you; and/or (c) remove personal information that you have provided to us, or that we have otherwise collected.  You may do so by e-mailing us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a>.  We ask individual users to identify themselves and the information requested to be accessed, corrected or removed before processing such requests, and, to the extent permitted by applicable law, we may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort, jeopardize the privacy of others or would be extremely impractical (for instance, requests concerning information residing on backup tapes).</p>
                <p>Please be advised that deleting your personal information may terminate your access to certain of the Services.  If you wish to continue using the full complement of Services, you may not be able to delete all of the personal information that we have on file for you.</p>
                <p>Please be further advised that, after you delete your personal information, residual copies may take a period of time before they are deleted from our active servers and may remain in our backup systems.</p>
                <h2>6.     OPT OUT/UNSUBSCRIBE</h2>
                <p>Please see our Privacy Provisions for California Residents below for instructions on how California Residents can opt-out of the sale of their personal information to third parties.</p>
                <p>To opt-out of receiving e-mail and other forms of communication from us, you can: (a) follow the instructions included in the applicable e-mail message or other communication; or (b) e-mail us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a></p>
                <p>Notwithstanding the foregoing, we may continue to contact you for the purpose of communicating information relating to your request for Services, as well as to respond to any inquiry or request made by you.  To opt-out of receiving Services-related and/or inquiry response-related messages fromTop 100 Sports, you must cease requesting and/or utilizing the Services and/or cease submitting inquiries to Top 100 Sports, as applicable.</p>
                <h2>7.     PRIVACY FOR INDIVIDUALS UNDER SIXTEEN (16) YEARS OF AGE</h2>
                <p>Please see our Privacy Provisions for California Residents below for details regarding our use and sharing of the personal information of minors.</p>
                <p>The Site, App and Services are not directed at, and should not be used by, individuals under sixteen (16) years of age, and we do not knowingly collect personal information from individuals under sixteen (16) years of age.  If you are sixteen (16) years of age or older, but younger than eighteen (18) years of age, you can only submit information by and through the Site, App and/or Services with the permission of a parent or legal guardian, after that parent or legal guardian has reviewed this Privacy Policy in its entirety.  If you are under  sixteen (16) years of age, you are not permitted to provide any information by and through the Site, App and/or Services (including any information about yourself, such as your name, address, telephone number, e-mail address or any screen name or user name that you may use). Only parents or legal guardians may create an account for leagues for, and/or submit information about, sports registrants under sixteen (16) years of age.</p>
                <p>Top 100 Sports does not solicit or collect personal information from individuals under sixteen (16) years of age. If we learn we have collected or received personal information from an individual under sixteen (16) years of age, we will delete that information and any related account. By registering for the Site and/or Services, users represent and warrant that they are over sixteen (16) years of age.</p>
                <h2>8.     LINKS TO THIRD PARTY SITES AND/OR INTEGRATION WITH THIRD PARTY PLATFORMS</h2>
                <p>Our Site, App and/or Services may interface with third party websites and services (for example, social media platforms like Facebook®, Twitter®, Google+® or other third party applications, sites or services), over which we have no control.</p>
                <p>Privacy policies for these third party sites and services may be different from our Privacy Policy. You access these third party sites and services at your own risk. You should always read the privacy policy of a linked site or integrated service before disclosing any personal information on such site and/or through such service. Top 100 Sports is not responsible for information you submit to third parties or through third party platforms.</p>
                <h2>9.     VISITING OUR SITE/ACCESSING OUR APP FROM OUTSIDE THE UNITED STATES</h2>
                <p><strong>We store and process all personal information for Top 100 Sports within the US.</strong></p>
                <p>If you are visiting our Site and/or App from outside the United States, please be aware that your information will typically be transferred to, stored and processed in the United States where our servers are located and our central database is operated. The data protection and other laws of the United States and other countries might not be as comprehensive as those in your country. Please be assured that we seek to take reasonable steps to ensure that your personal information is protected. By using our services, you understand that your information may be transferred to our facilities and those third parties with whom we share it as described in this Privacy Policy.</p>
                <p>Furthermore, with respect to any personal information originating outside the U.S., we will retain such information until we determine that you are no longer active within our system.</p>
                <h2>10.   VISITING OUR SITE/ACCESSING THE APP FROM THE EUROPEAN ECONOMIC AREA (EEA)</h2>
                <p>Please note, Top 100 Sports is considered the “Data Controller” in connection with the personal information collected pursuant to this Privacy Policy for purposes of applicable data protection laws, including the European Union’s (“EU”) General Data Protection Regulation (“GDPR”).</p>
                <h3>The Legal Basis for Using European Personal Information</h3>
                <ul>
                    <li>We only use and store your personal information if we have a legal basis for doing so, including where you have given us your express consent, where we have a legitimate business interest, or pursuant to the contractual relationship that exists between you and Top 100 Sports.</li>
                    <li>When we collect information about you through our Site and/or App, the legal basis on which we process your personal information is fulfillment of an agreement between us, namely, to provide you with the products and services requested, or Top 100 Sports marketing material promoting our offerings and/or those of third parties in response to your request.</li>
                    <li>When we collect information about you through our Site and/or App, the legal basis on which we process your personal information is the performance of our agreement with you to purchase or review the Services. When we use your personal information outside of what is strictly necessary for that transaction, we are doing so in order to pursue our legitimate interest of informing you of related products and promotions.</li>
                    <li>To the extent that we transfer personal information from the EEA to a jurisdiction outside the EEA that has not been adduced by the European Commission as providing adequate data protections (such as the US), the basis for such transfer is the necessity of providing you with information, products, or Services you have requested.</li>
                </ul>
                <h3>Rights for EEA Residents</h3>
                <p>In addition, if you are a resident of the EEA, you  :</p>
                <ul>
                    <li>Find out if we use your personal information, to access your personal information, and receive copies of your personal information in a machine readable format.</li>
                    <li>Withdraw any express consent that you have provided to the processing of your personal information at any time without penalty.</li>
                    <li>Access your personal information and have it corrected or amended if it is inaccurate or incomplete.</li>
                    <li>Obtain a transferable copy of some of your personal information which can be transferred to another provider when the personal information was processed based on your consent.</li>
                    <li>If you believe your personal information is inaccurate, no longer necessary for our business purposes, or if you object to our processing of your personal information, you also have the right to request that we restrict the processing of your data pending our investigation and/or verification of your claim.</li>
                    <li>Request your personal information be deleted or restricted under certain circumstances. For example, if Top 100 Sports is using your personal information on the basis of your consent and has no other legal basis to use such, you may request your personal information be deleted from our databases, as well as the databases of any third party with which we shared your personal information, when you withdraw your consent.</li>
                    <li>If you wish to exercise any of these rights, or raise a complaint on how we have handled your personal information, please contact us at <a href="mailto:info@top100sports.com">info@top100sports.com</a> or via the details below.</li>
                </ul>
                <h2>11.   PRIVACY POLICY CHANGES</h2>
                <p>This Privacy Policy may change from time to time. To improve the services and opportunities it can offer you, Top 100 Sports may opt to expand its capabilities for obtaining information about users in the future. Top 100 Sports will update this Privacy Policy continually to ensure that you are aware of developments in this area. We will post those changes on the Site and in the App so that you will always know what information we collect online on or through the Site, App and/or Services, how we use that information, and what choices you have with respect to same. Your continued use of the Site and the Services after we make changes is deemed to be acceptance of those changes; provided, however, that if the manner in which we use personal information changes, Top 100 Sports will notify users by: (a) sending the modified policy to our users via e-mail; and/or (b) by any other reasonable means acceptable under applicable state and federal law.  You will have a choice as to whether or not we use your information in this different manner and we will only use your information in this different manner where you opt-in to such use.</p>
                <h2>12.   QUESTIONS OR FEEDBACK</h2>
                <p>If you have any questions or comments regarding our Privacy Policy and associated practices, please email us at <a href="mailto:info@top100sports.com">info@top100sports.com</a> or send us U.S. mail to: Top 100 Sports, 373 Spencer St. Suite 101, Syracuse NY 13204 .</p>
                <h3>PRIVACY PROVISIONS FOR CALIFORNIA RESIDENTS</h3>
                <p>These Privacy Provisions for California Residents (“Provisions”) supplement, and do not limit in any way, the Privacy Policy set forth above.  These Provisions apply solely to residents of the State of California (“CA Users”).  We adopt these Provisions in compliance with the California Consumer Privacy Act of 2018 (“CCPA”).  Any terms defined in the CCPA have the same meaning when used in these Provisions.  CA Users with disabilities who wish to access these Provisions in an alternative format can contact us by emailing us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a>; calling us at: (844) 486-7100; or sending us U.S. mail to: Top 100 Sports, 373 Spencer St. Suite 101, Syracuse NY 13204.</p>
                <h3>Categories of Information We Collect</h3>
                <p>We collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular CA User or device (“personal information”). In particular, we have collected the following categories of personal information from CA Users within the last twelve (12) months:</p>
                <table>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Examples</th>
                            <th>Collected</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>A. Identifiers.</td>
                            <td>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, telephone number, or other similar identifiers.</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
                            <td>A name, signature, Social Security number, physical characteristics or description, postal address, telephone number, passport number, driver’s license or State identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information. Some personal information included in this category may overlap with other categories.</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>C. Protected classification characteristics under California or federal law.</td>
                            <td>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>D. Commercial information.</td>
                            <td>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>E. Biometric information.</td>
                            <td>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>F. Internet or other similar network activity.</td>
                            <td>Browsing history, search history, information on a User’s interaction with a website, application or advertisement.</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>G. Geolocation data.</td>
                            <td>Physical location or movements.</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>H. Sensory data.</td>
                            <td>Audio, electronic, visual, thermal, olfactory, or similar information.</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>I. Professional or employment-related information.</td>
                            <td>Current or past job history or performance evaluations.</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
                            <td>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>K. Inferences drawn from other personal information.</td>
                            <td>Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</td>
                            <td>NO</td>
                        </tr>
                    </tbody>
                </table>
                <p>Personal information does not include:</p>
                <ul>
                    <li>Publicly available information from government records.</li>
                    <li>De-identified or aggregated CA User information.</li>
                    <li>Information excluded from the CCPA’s scope, such as:
                        <ul>
                            <li>health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data; and</li>
                            <li>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver’s Privacy Protection Act of 1994.</li>
                        </ul>
                    </li>
                </ul>
                <p>We obtain the categories of personal information listed above from the following categories of sources (with the specific categories of personal information indicated in parenthesis:</p>
                <ul>
                    <li>Directly from our CA Users. For example, from online registration forms that our CA Users submit to us in connection with the products and/or Services that we offer by and through the Site and/or App. (Categories A, B, C and D)</li>
                    <li>Indirectly from our CA Users. For example, through information we collect from our CA Users in the course of providing our products and/or Services to them. (Categories A, B, C, D and F)</li>
                    <li>Directly and indirectly from activity on the Site and/or in the App. This includes the type of browser that you use (e.g., Safari, Chrome, Internet Explorer), your IP address, the type of operating system that you use (e.g., Windows or iOS), the type of mobile device that you use and the domain name of your Internet Service Provider.  In addition, we obtain certain Site usage details and analytics as same are collected automatically by us and our third party partners. (Category F)</li>
                    <li>When our CA Users interact with us on our social media accounts, including commenting on and/or liking our posts. (Category F)</li>
                    <li>From third-parties that interact with us in connection with the products and/or Services that we offer to our CA Users. For example, third party entities that assist us in sending direct and electronic mail, removing duplicate information from CA User lists, analyzing data and providing marketing analysis. (Categories A, B, C, D and F)</li>
                </ul>
                <h3>Use of Personal Information</h3>
                <p>We may use or disclose the personal information that we collect (including: (a) name; (b) mailing/billing address; (c) e-mail address; (d) telephone number; (e) date of birth; (f) organization name; (g) your role in the organization; (h) the number of players in your organization; (i) your organization website and zip) for one or more of the following business purposes (with the specific categories of personal information indicated in parenthesis:</p>
                <ul>
                    <li>To fulfill or meet the reason for which the information is provided. For example, if you provide us with personal information in connection with your purchase of Services, we will use that information to process your order. (Categories A, B, C and D)</li>
                    <li>To provide you with information, products or Services that you request from us. (Categories A, B, C, D and F)</li>
                    <li>To create, maintain, customize and secure your account with us. (Categories A, B, C, D and F)</li>
                    <li>To provide you with e-mail, direct mail and telemarketing messages concerning Services, as well as third-party products and/or services, that we believe may be of interest to you. (Categories A, B, C, D and F)</li>
                    <li>To deliver relevant Site/App content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you. (Categories A, B, C, D and F)</li>
                    <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including the Site/App’s Terms and Conditions. (Categories A, B, C, D and F)</li>
                    <li>To improve the Site and App, and better present their contents to you. (Categories A, B, C, D and F)</li>
                    <li>For customer service purposes and to respond to inquiries from you. (Categories A, B, C and D)</li>
                    <li>For testing, research, analysis and product development. (Categories A, B, C, D and F)</li>
                    <li>As necessary or appropriate to protect our rights, property or safety, and that of our clients or others. (Categories A, B, C, D and F)</li>
                    <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations. (Categories A, B, C, D and F)</li>
                    <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA. (Categories A, B, C, D and F)</li>
                    <li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by us is among the assets transferred. (Categories A, B, C, D and F)</li>
                </ul>
                <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated or incompatible purposes without providing you with notice.</p>
                <h3>Sharing Personal Information</h3>
                <p>Subject to your right to opt-out of such sharing/sale, we may share, rent and/or sell your personal information from Categories A, B, C, D and F (including: (a) name; (b) mailing/billing address; (c) e-mail address; (d) telephone number; (e) date of birth; (f) organization name; (g) your role in the organization; (h) the number of players in your organization; (i) your organization website and zip): (i) with/to third parties for marketing purposes; and (ii) for the other business purposes set forth above.</p>
                <p>When we disclose personal information to a third party service provider or other entity, we enter into a contractual relationship that describes the purpose for which such third party may use the personal information and requires that third party to both keep the personal information confidential and not use it for any purpose other than the performance of its services under the applicable contract.  Please note, we do not collect information from CA Users that we actually know are less than sixteen (16) years of age and we do not share or sell the personal information of CA Users that we actually know are less than sixteen (16) years of age.  Without limiting the foregoing, we have not shared or sold the personal information of CA Users that we actually know are less than sixteen (16) years of age in the preceding twelve (12) months.</p>
                <p>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
                <p>Category A - Identifiers.</p>
                <p>Category B - California Customer Records personal information categories.</p>
                <p>Category C - Protected classification characteristics under California or federal law.</p>
                <p>Category D - Commercial information.</p>
                <p>Category F - Internet or other similar network activity.</p>
                <p>We disclose your personal information (including: (a) name; (b) mailing/billing address; (c) e-mail address; (d) telephone number; (e) date of birth; (f) organization name; (g) your role in the organization; (h) the number of players in your organization; (i) your organization website and zip) for a business purpose to the following categories of third parties (with the specific categories of personal information indicated in parenthesis:</p>
                <ul>
                    <li>Our affiliates. (Categories A, B, C, D and F)</li>
                    <li>Service providers. (Categories A, B, C, D and F)</li>
                    <li>Third parties who provide certain of the products and/or services featured on the Site. (Categories A, B, C, D and F)</li>
                    <li>Third parties who purchase and/or license your personal information for marketing purposes, including: (a) providers of direct marketing services and applications, including lookup and reference, data enhancement, suppression and validation; (b) e-mail marketers; (c) telemarketers (where permitted by applicable law); and (d) direct marketers.  If a third party purchaser of your personal information wants to resell it, that purchaser is required by law to provide you with explicit notice and an opportunity to opt-out of further sales, unless the right to resell it was secured by us at the point of collection. (Categories A, B, C, D and F)</li>
                    <li>Third parties to whom you authorize us to disclose your personal information in connection with the products and/or Services that we provide to you. (Categories A, B, C, D and F)</li>
                </ul>
                <p>In the preceding twelve (12) months, we have sold the following categories of personal information to third parties:</p>
                <p>Category A - Identifiers.</p>
                <p>Category B - California Customer Records personal information categories.</p>
                <p>Category C - Protected classification characteristics under California or federal law.</p>
                <p>Category D - Commercial information.</p>
                <p>Category F - Internet or other similar network activity.</p>
                <p>In the preceding twelve (12) months, we have sold the above referenced categories of personal information (including: (a) name; (b) mailing/billing address; (c) e-mail address; and/or (d) telephone number) to the third parties who purchase and/or license your personal information for marketing purposes, as well as the third parties who provide certain of the products and/or services featured on the Site.</p>
                <h3>Your Rights and Choices</h3>
                <p>The CCPA provides CA Users (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
                <p><em>Opt-Out from the Sale of Your Personal Information</em></p>
                <p>You have the right to opt-out of our sale of your personal information to third parties.  To exercise your right to opt-out of our sale of your personal information to third parties, please submit a verifiable CA User request to us by either:</p>
                <ul>
                    <li>Calling us at: (844) 486-7100</li>
                    <li>Emailing us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a>; or</li>
                    <li>Sending us U.S. mail to:Top 100 Sports, 373 Spencer St. Suite 101, Syracuse NY 13204.</li>
                </ul>
                <p>We endeavor to act on all opt-out requests as soon as practicable, but in all cases within fifteen (15) days of the receipt of your request.</p>
                <p><em>Access to Specific Information and Data Portability Rights</em></p>
                <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past twelve (12) months. Once we receive and confirm your verifiable CA User request, we will disclose to you:</p>
                <ul>
                    <li>The categories of personal information we collected about you.</li>
                    <li>The categories of sources for the personal information we collected about you.</li>
                    <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                    <li>The categories of third parties with whom we have shared that personal information.</li>
                    <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
                    <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:
                        <ul>
                            <li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
                            <li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
                        </ul>
                    </li>
                </ul>
                <p><em>Deletion Request Rights</em></p>
                <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable CA User request, we will delete (and direct our service providers to delete) your personal information from our (their) records, unless an exception applies; provided, however, that in some cases, strictly for regulatory compliance purposes and to better evidence/honor opt-out/unsubscribe requests (and for no other purposes), we may retain certain items of your personal information on a de-identified and aggregated basis in such a manner that the data no longer identifies you.</p>
                <p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
                <ol>
                    <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our obligations in connection with our contract with you.</li>
                    <li>Detect security incidents, protect against malicious, deceptive, fraudulent or illegal activity, or prosecute those responsible for such activities.</li>
                    <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                    <li>Exercise free speech rights, ensure the right of another CA User to exercise her/his free speech rights or exercise another right provided for by law.</li>
                    <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).</li>
                    <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, but only if you previously provided informed consent.</li>
                    <li>Enable solely internal uses that are reasonably aligned with CA User expectations based on your relationship with us.</li>
                    <li>Comply with a legal obligation.</li>
                    <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it</li>
                </ol>
                <p><em>Exercising Access, Data Portability and Deletion Rights</em></p>
                <p>To exercise your access, data portability and/or deletion rights described above, please submit a verifiable CA User request to us by either:</p>
                <ul>
                    <li>Calling us at: (844) 486-7100</li>
                    <li>Emailing us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a>; or</li>
                    <li>Sending us U.S. mail to: Top 100 Sports, 373 Spencer St. Suite 101, Syracuse NY 13204.</li>
                </ul>
                <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable CA User request related to your personal information.</p>
                <p>You may only make a verifiable CA User request for access or data portability twice within a 12-month period. The verifiable CA User request must:</p>
                <ul>
                    <li>Provide sufficient information that allows us to reasonably verify that you are: (1) the person about whom we collected personal information; or (2) an authorized representative.</li>
                    <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
                </ul>
                <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm that the personal information relates to you.  Making a verifiable CA User request does not require that you create an account with us.  We will only use personal information provided in a verifiable CA User request to verify the requestor’s identity or authority to make the request.</p>
                <p><em>Response Timing and Format</em></p>
                <p>We endeavor to respond to all verifiable CA User requests within forty-five (45) days of the receipt thereof.  If we require more time (up to ninety (90) days), we will inform you of the reason and extension period in writing.  We will deliver our written response by mail or electronically, at your option.  Any disclosures that we provide will only cover the twelve (12) month period preceding the receipt of your verifiable request.  The response that we provide will also explain the reasons that we cannot comply with a request, if applicable.  For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
                <p>We do not charge a fee to process or respond to your verifiable CA User request unless it is excessive, repetitive, or manifestly unfounded.  If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
                <h3>Non-Discrimination</h3>
                <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
                <ul>
                    <li>Deny you goods or services;</li>
                    <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties;</li>
                    <li>Provide you a different level or quality of goods or services; and/or</li>
                    <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
                </ul>
                <h3>Changes to these Provisions</h3>
                <p>We reserve the right to amend these Provisions in our discretion and at any time. When we make changes to these Provisions, we will notify you by email or through a notice on the Site’s homepage.</p>
                <h3>Contact Information</h3>
                <p>If you have any questions or comments about these Provisions, our Privacy Policy, the ways in which we collect and use your personal information, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us by either:</p>
                <ul>
                    <li>Calling us at: (844) 486-7100</li>
                    <li>Emailing us at: <a href="mailto:info@top100sports.com">info@top100sports.com</a>; or</li>
                    <li>Sending us U.S. mail to:Top 100 Sports, 373 Spencer St. Suite 101, Syracuse NY 13204.</li>
                </ul>
                <p><strong>Updated: June 28, 2022</strong></p>
            </div>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="privacyPolicy.meta.title" />
                <LayoutMarketingComponent content={this.renderContent} />
            </>
        );
    }
}
export default injectIntl(PrivacyPolicyPage);

interface Props extends WrappedComponentProps {}

interface State {}
