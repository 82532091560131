import { Page } from '../model/Elements';
import { Prospect } from '../model/Entities';
import apiAxios from './CustomAxios';

class ProspectsApi {
    list = async (limit: number, page: number, search?: string): Promise<Page<Prospect>> => {
        const response = await apiAxios.get<Page<Prospect>>(`/v1/prospects`, {
            params: { limit, page, search },
        });

        return response.data;
    };

    get = async (prospectId: number, seasonId: number): Promise<Prospect> => {
        const response = await apiAxios.get<Prospect>(`/v1/prospects/${prospectId}`, {
            params: { SeasonId: seasonId },
        });
        response.data.playerSport?.games?.forEach((g) => (g.date ? (g.date = g.date * 1000) : undefined));
        return response.data;
    };

    delete = async (userId: string, sportId: number): Promise<void> => {
        await apiAxios.delete(`/v1/users/${userId}/sports/${sportId}/prospects`);
    };

    update = async (userId: string, sportId: number, rate?: number, note?: string): Promise<void> => {
        const response = await apiAxios.put<void>(`/v1/users/${userId}/sports/${sportId}/prospects`, {
            rate: rate,
            note: note,
        });
        return response.data;
    };

    create = async (userId: string, sportId: number, rate?: number): Promise<void> => {
        const response = await apiAxios.post<void>(`/v1/users/${userId}/sports/${sportId}/prospects`, {
            rate: rate,
        });
        return response.data;
    };
}
const prospectsApi: ProspectsApi = new ProspectsApi();
export default prospectsApi;
