import { Page } from '../model/Elements';
import { Location } from '../model/Entities';
import apiAxios from './CustomAxios';

class LocationApi {
    list = async (): Promise<Location[]> => {
        const response = await apiAxios.get<Page<Location>>('/v1/locations');

        return response.data.items;
    };
}
const locationApi: LocationApi = new LocationApi();
export default locationApi;
