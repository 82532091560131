import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import CustomContext from '../../context/CustomContext';
import { AuthorityType } from '../../model/Types';

/**
 * This component represents a protected route. It redirect to the home page if the user is not authorized to view a route.
 * @param props - the route props
 */
const ProtectedRoute: React.FC<Props> = (props: Props) => {
    const context = useContext(CustomContext);
    const { authorities } = props;
    const { auth } = context;

    if (auth && (!authorities || auth.authorities.some((a) => authorities.includes(a)))) {
        return <Route {...props} />;
    } else {
        return <Redirect to="/" />;
    }
};
export default ProtectedRoute;

interface Props extends RouteProps {
    authorities?: AuthorityType[];
}
