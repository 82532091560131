import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import gameStatisticsApi from '../../../apis/GameStatisticsApi';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/Elements';
import { ProspectPlayerGame, User } from '../../../model/Entities';
import notificationService from '../../../services/NotificationService';
import numericService from '../../../services/NumericService';
import rolesService from '../../../services/RolesService';
import AddGameModal from './AddGameModal/AddGameModal';
import styles from './PlayerGameStatisticsComponent.module.scss';

class PlayerGameStatisticsComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.seasonId !== this.props.seasonId || prevProps.organization !== this.props.organization) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const playerGameStatisticsPage = await gameStatisticsApi.list(
                this.props.user.id!,
                this.props.seasonId,
                this.pageSize,
            );

            this.setState({ playerGameStatisticsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    update = async () => {
        try {
            this.setState({ loading: 'loading' });

            const playerGameStatisticsPage = await gameStatisticsApi.list(
                this.props.user.id!,
                this.props.seasonId,
                this.pageSize,
            );
            this.showAddGameModal(false);

            this.setState({ playerGameStatisticsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showAddGameModal = (addGameModalVisible: boolean, playerGameStatistics?: ProspectPlayerGame) => {
        this.setState({ addGameModalVisible, playerGameStatistics });
    };

    isEditable = () => {
        const { organization, user } = this.props;
        const { auth } = this.context;

        let isEditable = false;
        if (auth && auth.authorities.includes('ROLE_ADMIN')) {
            isEditable = true;
        } else if (
            auth &&
            rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_OWNER', 'ROLE_ORGANIZATION_STAFF']) &&
            organization === auth.organizationId
        ) {
            isEditable = true;
        } else if (
            auth &&
            rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_COACH']) &&
            user?.organizationTeamsMemberships.some((otm) => otm.organizationId === auth.organizationId) &&
            auth.coaches
                ?.find(Boolean)
                ?.teams?.some((t) =>
                    user.organizationTeamsMemberships.find((om) => om.teams?.find((team) => team.id === t.teamId)),
                )
        ) {
            isEditable = true;
        }

        return isEditable;
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { loading, playerGameStatisticsPage } = this.state;
        let items = playerGameStatisticsPage && playerGameStatisticsPage.items ? playerGameStatisticsPage.items : [];
        items = items.sort((a, b) => (b.date || 0) - (a.date || 0));
        const isEditable = this.isEditable();

        const columns: ColumnsType<ProspectPlayerGame> = [
            {
                title: <FormattedMessage id="gameStatistics.date" />,
                dataIndex: 'date',
                key: 'date',
                width: 140,
                render: (value: number) => <FormattedDate value={value} day="2-digit" month="2-digit" year="2-digit" />,
            },
            {
                title: <FormattedMessage id="gameStatistics.opponent" />,
                dataIndex: 'opponent',
                key: 'opponent',
                render: (value: string) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.ab" />,
                dataIndex: 'atBats',
                key: 'atBats',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.r" />,
                dataIndex: 'run',
                key: 'run',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.h" />,
                dataIndex: 'hits',
                key: 'hits',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.rbi" />,
                dataIndex: 'runsBattedIn',
                key: 'runsBattedIn',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.bb" />,
                dataIndex: 'baseOnBalls',
                key: 'baseOnBalls',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.k" />,
                dataIndex: 'strikeouts',
                key: 'strikeouts',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.avg" />,
                dataIndex: 'battingAverage',
                key: 'battingAverage',
                render: (value: number) => {
                    const formattedNumber = value
                        ? intl.formatNumber(value, { maximumFractionDigits: 3, minimumFractionDigits: 3 })
                        : undefined;
                    return numericService.zeroRemove(formattedNumber);
                },
            },
            {
                title: <FormattedMessage id="gameStatistics.obp" />,
                dataIndex: 'onBasePercentage',
                key: 'onBasePercentage',
                render: (value: number) => {
                    const formattedNumber = value
                        ? intl.formatNumber(value, { maximumFractionDigits: 3, minimumFractionDigits: 3 })
                        : undefined;
                    return numericService.zeroRemove(formattedNumber);
                },
            },
            {
                title: <FormattedMessage id="gameStatistics.slg" />,
                dataIndex: 'slugging',
                key: 'slugging',
                render: (value: number) => {
                    const formattedNumber = value
                        ? intl.formatNumber(value, { maximumFractionDigits: 3, minimumFractionDigits: 3 })
                        : undefined;
                    return numericService.zeroRemove(formattedNumber);
                },
            },
        ];

        return (
            <div className={styles.statistics}>
                <h2>
                    <FormattedMessage id="gameStatistics.gameStatistics" />
                    {isEditable && (
                        <Button type="text" icon={<PlusOutlined />} onClick={() => this.showAddGameModal(true)}>
                            <FormattedMessage id="gameStatistics.addGame" />
                        </Button>
                    )}
                </h2>
                <div className={styles.table}>
                    <Table
                        dataSource={items}
                        columns={columns}
                        pagination={false}
                        rowKey="id"
                        loading={loading === 'initializing' || loading === 'loading'}
                        className={isEditable ? styles.editableTable : undefined}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => isEditable && this.showAddGameModal(true, record),
                            };
                        }}
                    />
                </div>
            </div>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { addGameModalVisible, playerGameStatistics } = this.state;
        return (
            <>
                {this.renderTable()}
                {addGameModalVisible && (
                    <AddGameModal
                        onUpdate={this.update}
                        onCancel={() => this.showAddGameModal(false)}
                        playerGameStatistics={playerGameStatistics}
                        userId={this.props.user.id!}
                        seasonId={this.props.seasonId}
                    />
                )}
            </>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(PlayerGameStatisticsComponent);

interface Props extends WrappedComponentProps {
    user: User;
    seasonId: number;
    organization?: string;
}

interface State {
    loading?: 'initializing' | 'loading';
    playerGameStatisticsPage?: Page<ProspectPlayerGame>;
    addGameModalVisible?: boolean;
    playerGameStatistics?: ProspectPlayerGame;
}
