import Icon, { DownOutlined } from '@ant-design/icons';
import * as signalR from '@microsoft/signalr';
import { Button, Divider, Image, Menu, message, Select, Table, Tabs } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import divisionApi from '../../../../apis/DivisionApi';
import eventApi from '../../../../apis/EventApi';
import positionApi from '../../../../apis/PositionApi';
import sportApi from '../../../../apis/SportApi';
import sportMetricApi from '../../../../apis/SportMetricApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Page } from '../../../../model/Elements';
import { Division, Event, Leaderboard, Position, Sport, SportCategory } from '../../../../model/Entities';
import { LeaderboardType } from '../../../../model/Types';
import { ReactComponent as backSvg } from '../../../../resources/images/ico-back.svg';
import { ReactComponent as gradyearSvg } from '../../../../resources/images/ico-rosterGrades.svg';
import { ReactComponent as positionsSvg } from '../../../../resources/images/ico-rosterPositions.svg';
import { ReactComponent as shareSvg } from '../../../../resources/images/ico-share.svg';
import avatar from '../../../../resources/images/profile-placeholder.png';
import HeadMetadata from '../../../../services/HeadMetadata';
import metricService from '../../../../services/MetricService';
import notificationService from '../../../../services/NotificationService';
import numericService from '../../../../services/NumericService';
import playerService from '../../../../services/PlayerService';
import rolesService from '../../../../services/RolesService';
import stringService from '../../../../services/StringService';
import tableService from '../../../../services/TableService';
import PlayerShareModal from '../../../PlayerProfilePage/PlayerShareModal/PlayerShareModal';
import styles from './EventLeaderboardPage.module.scss';

const url = process.env.REACT_APP_API_URL + '/msgHub';

class EventLeaderboardPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;
    readonly hubConnection = new signalR.HubConnectionBuilder().withUrl(url!).withAutomaticReconnect().build();

    constructor(props: Props) {
        super(props);
        this.state = { positions: [], positionIds: [], divisions: [], sportCategories: [] };
    }

    componentDidMount() {
        this.init();
        this.hubConnection.on('Notification', () => this.refresh());
        this.hubConnection.start();
    }

    componentWillUnmount() {
        this.hubConnection.stop();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });
            const event = await eventApi.get(+this.props.match.params.id);
            const sportCategories = await sportMetricApi.list(event.sportId!, 'global');
            const subbankId = sportCategories && sportCategories.length > 0 ? sportCategories[0].id : undefined;
            const testCategoryId = this.getDefaultTestCategory(sportCategories);
            const responses = await Promise.all([
                eventApi.listLeaderboard(
                    this.pageSize,
                    1,
                    event?.id!,
                    testCategoryId,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    subbankId,
                ),
                positionApi.list(event.sportId!),
                sportApi.get(event.sportId),
                divisionApi.list(event.sportId!),
            ]);
            const leadersPage = responses[0];
            const positions = responses[1];
            const sport = responses[2];
            const divisions = responses[3];
            const leaderboardType = sport.code !== 'LACROSSE' ? 'global' : undefined;
            this.setState({
                event,
                leadersPage,
                positions,
                sortField: testCategoryId,
                sport,
                divisions,
                sportCategories,
                leaderboardType,
                subbankId,
            });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    refresh = async () => {
        try {
            const { event, page, sortField, sortOrder, positionIds, divisionId, gradYear, sportCategories, subbankId } =
                this.state;
            let sortFieldAux;
            if (sortField) {
                sortFieldAux = sortField;
            } else {
                sortFieldAux = this.getDefaultTestCategory(sportCategories);
            }
            const leadersPage = await eventApi.listLeaderboard(
                this.pageSize,
                page || 1,
                event?.id!,
                sortFieldAux,
                sortOrder,
                positionIds,
                divisionId,
                gradYear,
                undefined,
                subbankId,
            );
            this.setState({ leadersPage, sortField: sortFieldAux });
        } catch (error) {
            // notificationService.displayError(error, this.props.intl);
        }
    };

    list = async (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<Leaderboard> | SorterResult<Leaderboard>[],
    ) => {
        const { event, sortField, positionIds, divisionId, gradYear, subbankId } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const page = pagination.current!;
            let sortFieldAux, sortOrder, sortByGradYear;
            if (Object.keys(sorter).length > 0) {
                if (!Array.isArray(sorter) && sorter.columnKey !== 'gradYear') {
                    sortFieldAux = sorter.columnKey as number;
                    sortOrder = sorter.order as string;
                } else if (!Array.isArray(sorter) && sorter.columnKey === 'gradYear') {
                    sortByGradYear = sorter.order as string;
                }
            } else {
                sortFieldAux = sortField;
            }
            const leadersPage = await eventApi.listLeaderboard(
                this.pageSize,
                page,
                event?.id!,
                sortFieldAux,
                sortOrder,
                positionIds,
                divisionId,
                gradYear,
                sortByGradYear,
                subbankId,
            );
            this.setState({ leadersPage, page, sortField: sortFieldAux, sortOrder });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showModalShare = (modalShareVisible: boolean) => {
        this.setState({ modalShareVisible });
    };

    filterByPositionId = async (positionId: number) => {
        const { event, sortField, sortOrder, divisionId, gradYear, subbankId } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const positionIds = this.state.positionIds.includes(positionId)
                ? this.state.positionIds.filter((id) => id !== positionId)
                : [...this.state.positionIds, positionId];

            const leadersPage = await eventApi.listLeaderboard(
                this.pageSize,
                1,
                event?.id!,
                sortField,
                sortOrder,
                positionIds,
                divisionId,
                gradYear,
                undefined,
                subbankId,
            );

            this.setState({ positionIds, leadersPage, page: 1 });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    sortByDefaultCategory = async (value: string) => {
        const { event, positionIds, sortOrder, divisionId, gradYear, sportCategories } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const subbankId = +value;
            const testCategoryId = this.getDefaultTestCategory(sportCategories, subbankId);
            const leadersPage = await eventApi.listLeaderboard(
                this.pageSize,
                1,
                event?.id!,
                testCategoryId,
                sortOrder,
                positionIds,
                divisionId,
                gradYear,
                undefined,
                subbankId,
            );
            this.setState({ leadersPage, sortField: testCategoryId, page: 1, subbankId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    getDefaultTestCategory = (sportCategories: SportCategory[], sportCategoryId?: number): number | undefined => {
        let metricId;
        if (sportCategoryId) {
            metricId = sportCategories.find((ss) => ss.id === sportCategoryId)?.metrics![0].id;
        } else {
            metricId = sportCategories[0].metrics && sportCategories[0].metrics[0].id;
        }
        return metricId;
    };

    changeDivision = async (divisionId: number | string) => {
        const divisionIdAux = divisionId === '' ? undefined : (divisionId as number);
        const { event, sortField, sortOrder, positionIds, gradYear, subbankId } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const page = 1;
            const leadersPage = await eventApi.listLeaderboard(
                this.pageSize,
                page,
                event?.id!,
                sortField,
                sortOrder,
                positionIds,
                divisionIdAux,
                gradYear,
                undefined,
                subbankId,
            );
            this.setState({ divisionId: divisionIdAux, leadersPage, page });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByGradYear = async (gradYearSelected: number) => {
        const { event, sortField, sortOrder, positionIds, gradYear, divisionId, subbankId } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const gradYearToUse = gradYear !== gradYearSelected ? gradYearSelected : undefined;
            const page = 1;

            const leadersPage = await eventApi.listLeaderboard(
                this.pageSize,
                page,
                event?.id!,
                sortField,
                sortOrder,
                positionIds,
                divisionId,
                gradYearToUse,
                undefined,
                subbankId,
            );

            this.setState({ gradYear: gradYearToUse, leadersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByLeaderboardType = async (leaderboardType: LeaderboardType) => {
        const { sport, event, sortField, sortOrder, positionIds, divisionId, gradYear } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const sportCategories = await sportMetricApi.list(sport?.id!, leaderboardType);

            const subbankId = sportCategories && sportCategories.length > 0 ? sportCategories[0].id : undefined;

            const leadersPage = await eventApi.listLeaderboard(
                this.pageSize,
                1,
                event?.id!,
                sortField,
                sortOrder,
                positionIds,
                divisionId,
                gradYear,
                undefined,
                subbankId,
            );

            this.setState({ sportCategories, leaderboardType, leadersPage, subbankId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    downloadExcel = async () => {
        const { event, sortField, sortOrder, positionIds, divisionId, gradYear, subbankId, leaderboardType } =
            this.state;
        try {
            this.setState({ loading: 'downloadingExcel' });

            await eventApi.exportLeaderboardSpreadsheet(
                event!,
                leaderboardType!,
                sortField,
                sortOrder,
                positionIds,
                divisionId,
                gradYear,
                undefined,
                subbankId,
            );

            message.success(this.props.intl.formatMessage({ id: 'status.downloaded' }));
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    downloadExcelButtonHidden = (): boolean => {
        const { auth } = this.context;
        const { event } = this.state;

        if (rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION']) && auth?.organizationId === event?.organizationId) {
            return false;
        } else {
            return !rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_COLLEGE_COACH']);
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { event, divisionId, leaderboardType, loading } = this.state;

        if (desktop) {
            let url = `/events/${event?.id}/leaderboard/live`;
            if (divisionId) {
                url = url + `?divisionId=${divisionId}`;
            }
            if (leaderboardType && !divisionId) {
                url = url + `?type=${leaderboardType}`;
            } else if (leaderboardType) {
                url = url + `&type=${leaderboardType}`;
            }

            return (
                <>
                    <div className={styles.header}>
                        <p>
                            <FormattedMessage id="events.event.leaderboard.title" />
                        </p>
                        <div>
                            <Button
                                className={styles.live}
                                onClick={this.downloadExcel}
                                loading={loading === 'downloadingExcel'}
                                hidden={this.downloadExcelButtonHidden()}
                            >
                                <FormattedMessage id="events.event.leaderboard.button.downloadExcel" />
                            </Button>
                            <Link to={url} hidden={!event}>
                                <Button className={styles.live}>
                                    <FormattedMessage id="events.event.leaderboard.live" />
                                </Button>
                            </Link>
                            <Button
                                className={styles.share}
                                icon={<Icon component={shareSvg} />}
                                onClick={() => this.showModalShare(true)}
                            >
                                <FormattedMessage id="button.share" />
                            </Button>
                        </div>
                    </div>
                    <div className={styles.subheader}>
                        {event && (
                            <h2>
                                {event?.name} - <FormattedDate value={event?.startDate} month="short" />{' '}
                                <FormattedDate value={event?.startDate} day="2-digit" />,{' '}
                                <FormattedDate value={event?.startDate} year="numeric" />,{' '}
                                <FormattedDate value={event?.startDate} hour="2-digit" minute="2-digit" />
                            </h2>
                        )}
                    </div>
                </>
            );
        } else {
            return (
                <Button
                    className={styles.share}
                    icon={<Icon component={shareSvg} />}
                    onClick={() => this.showModalShare(true)}
                ></Button>
            );
        }
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="event-dashboard"
                        icon={<Icon component={backSvg} />}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/summary`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/summary`}>
                            <FormattedMessage id="events.event.leaderboard.backToEvent" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                {this.renderMenuFilters(collapsed)}
            </>
        );
    };

    renderMenuFilters = (collapsed: boolean): React.ReactNode | undefined => {
        const { positions, positionIds, gradYear } = this.state;
        const currentYear = new Date().getFullYear();
        let gradYears = Array.from(Array(11).keys()).map((x) => currentYear + x);
        return (
            <Menu.ItemGroup key="event-leaderboard" title={!collapsed && <FormattedMessage id="event.name" />}>
                <Menu.SubMenu
                    key="positions"
                    icon={<Icon component={positionsSvg} />}
                    title={<FormattedMessage id="roster.navigation.positions" />}
                >
                    {positions.map((position) => (
                        <Menu.Item
                            key={position.code}
                            onClick={() => this.filterByPositionId(position.id)}
                            className={positionIds.includes(position.id) ? styles.selected : styles.unselected}
                        >
                            {position.name}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="gradYears"
                    icon={<Icon component={gradyearSvg} />}
                    title={<FormattedMessage id="roster.navigation.gradYears" />}
                >
                    {gradYears.map((g) => (
                        <Menu.Item
                            key={g}
                            onClick={() => this.filterByGradYear(g)}
                            className={gradYear === g ? styles.selected : styles.unselected}
                        >
                            {g}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
            </Menu.ItemGroup>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        return this.renderTabs(desktop);
    };

    renderTabs = (desktop: boolean): React.ReactElement | undefined => {
        const { divisions, sportCategories, sport, leaderboardType, subbankId } = this.state;
        let divisionsOptions = divisions.map((division) => (
            <Select.Option key={division.id} value={division.id!}>
                {division.name}
            </Select.Option>
        ));
        divisionsOptions = [
            <Select.Option key="-" value="">
                <FormattedMessage id="nationalLeaderboard.allDivisions" />
            </Select.Option>,
            ...divisionsOptions,
        ];
        return (
            <div className={styles.tabs}>
                <Tabs
                    activeKey={subbankId?.toString()}
                    type="card"
                    className="leaderboardTabs"
                    onChange={(value: string) => this.sortByDefaultCategory(value)}
                    moreIcon={<DownOutlined />}
                    tabBarExtraContent={
                        <div className={styles.filter}>
                            {(sport?.code === 'BASEBALL' || sport?.code === 'SOFTBALL') && (
                                <Select
                                    onChange={(value: LeaderboardType) => this.filterByLeaderboardType(value)}
                                    value={leaderboardType}
                                    style={{ width: 150 }}
                                    className={styles.select}
                                >
                                    <Select.Option value="global">
                                        <FormattedMessage id="nationalLeaderboard.global" />
                                    </Select.Option>
                                    <Select.Option value="pitching">
                                        <FormattedMessage id="nationalLeaderboard.pitchers" />
                                    </Select.Option>
                                    <Select.Option value="catching">
                                        <FormattedMessage id="nationalLeaderboard.catchers" />
                                    </Select.Option>
                                </Select>
                            )}
                            <Select
                                placeholder={<FormattedMessage id="nationalLeaderboard.divisions" />}
                                style={{ width: 150 }}
                                className={styles.select}
                                onSelect={(value: number) => this.changeDivision(value)}
                            >
                                {divisionsOptions}
                            </Select>
                        </div>
                    }
                >
                    {sportCategories.map((ss) => (
                        <Tabs.TabPane key={ss.id} tab={ss.name} className={styles.tab}>
                            {this.renderTable(ss, desktop)}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
        );
    };

    renderUnitOfMeasure = (categoryId: number, result?: number): string | undefined => {
        const { sport } = this.state;
        const code = sport?.stations?.flatMap((s) => s.metrics?.filter((m) => m.id === categoryId))[0]?.unitOfMeasure
            ?.code;
        return metricService.abbreviate(code);
    };

    renderResult = (
        leaderboard: Leaderboard,
        categoryId: number,
        comparable?: boolean,
    ): React.ReactElement | undefined => {
        const result = leaderboard.measures.find((r) => r.metricId === categoryId)?.result;
        if (result) {
            if (!comparable) {
                const hours = Math.round(result / 60);
                const minutes = result % 60;
                return (
                    <>
                        {numericService.zeroPad(hours, 2)}
                        <FormattedMessage id=":" />
                        {numericService.zeroPad(minutes, 2)}
                        {this.renderUnitOfMeasure(categoryId, result)}
                    </>
                );
            } else {
                return (
                    <>
                        <FormattedNumber value={result} minimumFractionDigits={2} maximumFractionDigits={2} />
                        {this.renderUnitOfMeasure(categoryId, result)}
                    </>
                );
            }
        } else {
            return <></>;
        }
    };

    renderPlayerLink = (
        content: React.ReactElement | string,
        userId?: string,
        sportId?: number,
        familyId?: number,
        organizationIds?: string[],
    ): React.ReactElement | string => {
        if (playerService.isPlayerVisible(this.context.auth, familyId, organizationIds)) {
            return <Link to={`/players/${userId}?sportId=${sportId}`}>{content}</Link>;
        } else {
            return content;
        }
    };

    renderResultColumns = (sportCategory: SportCategory, width?: number): ColumnsType<any> => {
        return sportCategory.metrics!.map((m) => ({
            title: m.name,
            key: m.id,
            align: 'center',
            sorter: m.comparable,
            sortDirections: ['ascend', 'descend'],
            width,
            render: (value: any, leaderboard: Leaderboard) => this.renderResult(leaderboard, m.id!, m.comparable),
        }));
    };

    renderTable = (sportCategory: SportCategory, desktop: boolean): React.ReactElement | undefined => {
        const { leadersPage, modalShareVisible, loading, event } = this.state;
        const items = leadersPage ? leadersPage.items : [];
        const columnsFixed = desktop ? undefined : 'left';
        const columnIndexWidth = desktop ? undefined : 100;

        let columns: ColumnsType<Leaderboard> = [
            {
                dataIndex: ['athlete', 'photoUrl'],
                key: 'photoUrl',
                fixed: columnsFixed,
                render: (value: any, player: Leaderboard) =>
                    this.renderPlayerLink(
                        <Image src={value || avatar} width={72} fallback={avatar} preview={false} />,
                        player.athlete.userId,
                        event?.sportId,
                        player.athlete.familyId,
                        player.athlete.organizationIds,
                    ),
            },
            {
                title: <FormattedMessage id="player.name" />,
                dataIndex: ['athlete', 'givenName'],
                fixed: columnsFixed,
                key: 'givenName',
                ellipsis: !desktop,
                render: (value: string, player: Leaderboard) =>
                    this.renderPlayerLink(
                        stringService.getName(desktop, player.athlete.givenName, player.athlete.familyName),
                        player.athlete.userId,
                        event?.sportId,
                        player.athlete.familyId,
                        player.athlete.organizationIds,
                    ),
            },
            {
                title: <FormattedMessage id="player.gradYear" />,
                dataIndex: ['athlete', 'gradYear'],
                key: 'gradYear',
                width: 130,
                align: 'center',
                sorter: true,
                sortDirections: ['ascend', 'descend'],
                render: (value: string, player: Leaderboard) =>
                    this.renderPlayerLink(
                        value,
                        player.athlete.userId,
                        event?.sportId,
                        player.athlete.familyId,
                        player.athlete.organizationIds,
                    ),
            },
            ...this.renderResultColumns(sportCategory, columnIndexWidth),
        ];
        columns = columns.filter((col) => desktop || col.key !== 'photoUrl');
        const scroll = columnIndexWidth ? (columns.length - 2) * columnIndexWidth + 430 : undefined;

        return (
            <>
                <PlayerShareModal visible={modalShareVisible} onCancel={() => this.showModalShare(false)} />
                <Table
                    dataSource={items}
                    columns={columns}
                    pagination={tableService.createPagination(leadersPage, { pageSize: this.pageSize })}
                    rowKey="id"
                    onChange={this.list}
                    showSorterTooltip={false}
                    loading={loading === 'loading'}
                    className={styles.table}
                    scroll={{ x: scroll }}
                />
            </>
        );
    };

    render() {
        const { loading, event } = this.state;
        const fullName =
            event &&
            `${this.props.intl.formatMessage({
                id: 'event.leaderboard.meta.title',
            })} - ${event?.name} - ${this.props.intl.formatMessage({
                id: 'events.event.event.meta.title',
            })}`;
        return (
            <div className="leaderboard">
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="event-leaderboard"
                    header={this.renderHeader}
                    menu={this.renderMenu}
                    content={this.renderContent}
                    loading={loading === 'initializing'}
                />
            </div>
        );
    }
}
export default injectIntl(EventLeaderboardPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    leadersPage?: Page<Leaderboard>;
    event?: Event;
    divisions: Division[];
    sortField?: number;
    sortOrder?: string;
    positions: Position[];
    positionIds: number[];
    gradYear?: number;
    sport?: Sport;
    modalShareVisible?: boolean;
    page?: number;
    loading?: 'loading' | 'initializing' | 'downloadingExcel';
    searchText?: string;
    divisionId?: number;
    sportCategories: SportCategory[];
    leaderboardType?: LeaderboardType;
    subbankId?: number;
}
