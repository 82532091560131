import axios from 'axios';
import { Page } from '../model/Elements';
import { Media } from '../model/Entities';
import apiAxios from './CustomAxios';

class MediaApi {
    list = async (
        userId: string,
        limit: number,
        page: number,
        sportId?: number,
        sortField?: string,
        sortOrder?: string,
    ): Promise<Page<Media>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const sortFieldName = this.getSortField(sortField);
        const filteringFieldName = 'filtering.SportId.Value';
        const filteringFieldValue = sportId ? sportId : undefined;
        const filteringFieldOperationTitle = 'filtering.SportId.Operation';
        const filteringFieldOperationValue = sportId ? 'Equal' : undefined;
        const response = await apiAxios.get<Page<Media>>(`/v1/users/${userId}/media`, {
            params: {
                limit,
                page,
                [sortFieldName]: order,
                [filteringFieldName]: filteringFieldValue,
                [filteringFieldOperationTitle]: filteringFieldOperationValue,
            },
        });

        return response.data;
    };

    uploadVideo = async (userId: string, metricId: number | undefined, file: File): Promise<string> => {
        // get s3 upload url
        const initialResponse = await apiAxios.get(`/v1/media/generate-presigned-video-url/${userId}`);
        const s3UploadUrl = initialResponse.data.url;

        // post video to s3
        await axios.put(s3UploadUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });

        await apiAxios.post<void>(`/v1/media/confirm_uploaded-video-by-presigned-url`, {
            userId,
            s3Link: s3UploadUrl,
            stationMeasureId: metricId,
        });

        return s3UploadUrl;
    };

    uploadVideoWithoutMetric = async (userId: string, file: File, sportId: number): Promise<string> => {
        // get s3 upload url
        const initialResponse = await apiAxios.get(`/v1/media/generate-presigned-video-url/${userId}`);
        const s3UploadUrl = initialResponse.data.url;

        // post video to s3
        await axios.put(s3UploadUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });

        await apiAxios.post<void>(`/v1/users/${userId}/media`, {
            mediaType: 'Video',
            url: s3UploadUrl,
            sportId,
            caption: file.name,
        });

        return s3UploadUrl;
    };

    uploadFile = async (userId: string, file: File, sportId: number): Promise<string> => {
        // get s3 upload url
        const initialResponse = await apiAxios.get(`/v1/media/generate-presigned-file-url/${userId}`, {
            params: { fileName: file.name },
        });
        const s3UploadUrl = initialResponse.data.url;

        // post video to s3
        await axios.put(s3UploadUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });

        await apiAxios.post<void>(`/v1/users/${userId}/media`, {
            mediaType: 'File',
            url: s3UploadUrl,
            sportId,
            caption: file.name,
        });

        return s3UploadUrl;
    };

    uploadPhoto = async (userId: string, file: File, sportId: number): Promise<string> => {
        // get s3 upload url
        const initialResponse = await apiAxios.get(`/v1/media/generate-presigned-profile-photo-url/${userId}`);
        const s3UploadUrl = initialResponse.data.url;

        // post photo to s3
        await axios.put(s3UploadUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });

        await apiAxios.post<void>(`/v1/users/${userId}/media`, {
            mediaType: 'Photo',
            url: s3UploadUrl,
            sportId,
            caption: file.name,
        });

        return s3UploadUrl;
    };

    delete = async (userId: string, medias: Media[]): Promise<void> => {
        const body = medias.map((m) => m.id);
        const media: any = Object.assign({}, { media: body });
        await apiAxios.delete(`/v1/users/${userId}/media`, { data: media });
    };

    private getSortField(sortField?: string): string {
        let field: string = 'sorting.MediaType';
        if (!sortField) {
            return field;
        }
        if (sortField === 'mediaType') {
            field = 'sorting.MediaType';
        } else if (sortField === 'createdAt') {
            field = 'sorting.CreatedAt';
        }
        return field;
    }
}

const mediaApi: MediaApi = new MediaApi();
export default mediaApi;
