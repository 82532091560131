import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import CustomContext from '../../../context/CustomContext';
import { ReactComponent as collegeSvg } from '../../../resources/images/ico-collegeDirectory.svg';
import { ReactComponent as eventsSvg } from '../../../resources/images/ico-events.svg';
import { ReactComponent as leaderboardSvg } from '../../../resources/images/ico-leaderboard.svg';
import PlayerMenuComponent from '../Common/PlayerMenuComponent/PlayerMenuComponent';

class PlayerNavigationComponent extends React.Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /*** METHODS ***/

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    /*** COMPONENTS ***/

    render() {
        const { menu, collapsed, playerEditable } = this.props;

        if (menu) {
            return menu;
        } else {
            const userId = this.props.userId || this.context.auth?.id;
            const sportId = this.props.sportId || this.context.auth?.sportId;
            return (
                <>
                    {userId && sportId && (
                        <PlayerMenuComponent
                            userId={userId}
                            sportId={sportId}
                            divider={false}
                            editable={playerEditable}
                            collapsed={collapsed}
                            additionalMenu={
                                <>
                                    <Menu.Item
                                        key="leaderboard"
                                        icon={<Icon component={leaderboardSvg} />}
                                        onClick={() => this.navigate('/leaderboard')}
                                    >
                                        <Link to="/leaderboard">
                                            <FormattedMessage id="navigation.player.dashboard.leaderboard" />
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item
                                        key="events"
                                        icon={<Icon component={eventsSvg} />}
                                        onClick={() => this.navigate('/events')}
                                    >
                                        <Link to="/events">
                                            <FormattedMessage id="navigation.player.dashboard.events" />
                                        </Link>
                                    </Menu.Item>
                                    {this.context.auth?.collegeConnectEnabled && (
                                        <Menu.Item
                                            key="colleges-public"
                                            icon={<Icon component={collegeSvg} />}
                                            onClick={() => this.navigate('/colleges')}
                                        >
                                            <Link to="/colleges">
                                                <FormattedMessage id="navigation.organization.dashboard.colleges" />
                                            </Link>
                                        </Menu.Item>
                                    )}
                                </>
                            }
                        />
                    )}
                </>
            );
        }
    }
}
export default injectIntl(withRouter(PlayerNavigationComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    menu?: React.ReactNode;
    collapsed: boolean;
    playerEditable?: boolean;
    userId?: string;
    sportId?: number;
    familyId?: number;
}
