import Icon from '@ant-design/icons';
import { Button, Divider, Menu, Image, message } from 'antd';
import GoogleMapReact from 'google-map-react';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, RouteComponentProps } from 'react-router-dom';
import eventApi from '../../../apis/EventApi';
import sportApi from '../../../apis/SportApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Event, Sport } from '../../../model/Entities';
import { ReactComponent as arrowBackSvg } from '../../../resources/images/ico-arrowBack.svg';
import { ReactComponent as backSvg } from '../../../resources/images/ico-back.svg';
import { ReactComponent as eventLeaderboardSvg } from '../../../resources/images/ico-leaderboard.svg';
import { ReactComponent as locationSvg } from '../../../resources/images/ico-location.svg';
import { ReactComponent as positionsSvg } from '../../../resources/images/ico-positions.svg';
import { ReactComponent as shareSvg } from '../../../resources/images/ico-share.svg';
import { ReactComponent as eventSummarySvg } from '../../../resources/images/ico-summary.svg';
import { ReactComponent as eventSettingsSvg } from '../../../resources/images/ico-eventSettings.svg';
import HeadMetadata from '../../../services/HeadMetadata';
import notificationService from '../../../services/NotificationService';
import PlayerShareModal from '../../PlayerProfilePage/PlayerShareModal/PlayerShareModal';
import styles from './EventPage.module.scss';
import logo from '../../../resources/images/top100-logo.png';

class EventPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });
            const responses = await Promise.all([eventApi.get(+this.props.match.params.id), sportApi.listAll()]);
            const event = responses[0];
            const sports = responses[1];
            this.setState({ event, sports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showModalShare = (modalShareVisible: boolean) => {
        this.setState({ modalShareVisible });
    };

    updateOfflineMode = async () => {
        const { event } = this.state;

        try {
            if (event) {
                this.setState({ loading: 'saving' });
                let updatedEvent: Event = Object.assign({}, event);
                updatedEvent.isOffline = !updatedEvent.isOffline;
                await eventApi.updateOffline(updatedEvent);
                updatedEvent = await eventApi.get(event.id!);

                this.setState({ event: updatedEvent });
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    completeEvent = async () => {
        const { event } = this.state;

        try {
            this.setState({ loading: 'completing' });

            await eventApi.completeEvent(event?.id!);
            message.success(this.props.intl.formatMessage({ id: 'events.event.summary.status.eventCompleted' }));
            const updatedEvent = await eventApi.get(+this.props.match.params.id);

            this.setState({ event: updatedEvent });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { event, loading } = this.state;
        const { auth } = this.context;

        if (desktop) {
            return (
                <div className={styles.header}>
                    <p>
                        {(event?.status === 'Upcoming' || event?.status === 'Live') && (
                            <FormattedMessage id="events.event.summary.registration" />
                        )}
                    </p>
                    <div>
                        {event?.status === 'Live' && (
                            <Button
                                onClick={this.completeEvent}
                                hidden={!auth?.authorities.includes('ROLE_ADMIN')}
                                loading={loading === 'completing'}
                                disabled={!event}
                            >
                                <FormattedMessage id="events.event.summary.completeEvent" />
                            </Button>
                        )}
                        <Button
                            onClick={this.updateOfflineMode}
                            className={event?.isOffline ? styles.offlineOn : styles.offlineOff}
                            hidden={!auth?.authorities.includes('ROLE_ADMIN')}
                            loading={loading === 'saving'}
                            disabled={!event}
                        >
                            <FormattedMessage id="events.event.summary.offline" />
                        </Button>
                        <Button icon={<Icon component={shareSvg} />} onClick={() => this.showModalShare(true)}>
                            <FormattedMessage id="events.event.summary.share" />
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={styles.header}>
                    <Link to={`/events`}>
                        <Button
                            type="text"
                            size="small"
                            className={styles.back}
                            icon={<Icon component={arrowBackSvg} />}
                        ></Button>
                    </Link>
                    <p>
                        {(event?.status === 'Upcoming' || event?.status === 'Live') && (
                            <FormattedMessage id="events.event.summary.registration" />
                        )}
                    </p>
                    <div>
                        <Link to={`/events/${this.props.match.params.id}/leaderboard`}>
                            <Button
                                type="ghost"
                                className={styles.share}
                                icon={<Icon component={eventLeaderboardSvg} />}
                            ></Button>
                        </Link>
                        <Link to={`/events/${this.props.match.params.id}/players`}>
                            <Button
                                type="ghost"
                                className={styles.share}
                                icon={<Icon component={positionsSvg} />}
                                hidden={!auth?.authorities.includes('ROLE_ADMIN')}
                            ></Button>
                        </Link>
                        <Button
                            type="ghost"
                            className={styles.share}
                            icon={<Icon component={shareSvg} />}
                            onClick={() => this.showModalShare(true)}
                        ></Button>
                    </div>
                </div>
            );
        }
    };

    renderEventDetails = (desktop: boolean): React.ReactElement | undefined => {
        const { event, sports, modalShareVisible } = this.state;

        return (
            <>
                <PlayerShareModal visible={modalShareVisible} onCancel={() => this.showModalShare(false)} />

                <div className={styles.eventDetails}>
                    <div className={styles.top}>
                        <div className={styles.details}>
                            <div className={styles.category}>
                                <img
                                    src={sports?.filter((s) => s.id === event?.sportId)[0].iconUrl}
                                    alt={sports?.filter((s) => s.id === event?.sportId)[0].iconUrl}
                                ></img>
                                {sports?.filter((s) => s.id === event?.sportId)[0].name}
                            </div>
                            <h1>{event?.name}</h1>
                            <ul>
                                <li>
                                    <h2>
                                        <FormattedMessage id="player.events.generalFees" />
                                    </h2>
                                    <p>
                                        {event?.registrationTypes?.find((r) => r.type === 'Global')?.price &&
                                        event.registrationTypes?.find((r) => r.type === 'Global')?.price! > 0 ? (
                                            event.registrationTypes?.find((r) => r.type === 'Global')?.price
                                        ) : (
                                            <FormattedMessage id="player.events.free" />
                                        )}
                                    </p>
                                </li>
                                {event?.registrationTypes?.find((r) => r.type === 'Catching') && (
                                    <li>
                                        <h2>
                                            <FormattedMessage id="player.events.catchingFees" />
                                        </h2>
                                        <p>
                                            {event?.registrationTypes?.find((r) => r.type === 'Catching')?.price &&
                                            event.registrationTypes?.find((r) => r.type === 'Catching')?.price! > 0 ? (
                                                event.registrationTypes?.find((r) => r.type === 'Catching')?.price
                                            ) : (
                                                <FormattedMessage id="player.events.free" />
                                            )}
                                        </p>
                                    </li>
                                )}
                                {event?.registrationTypes?.find((r) => r.type === 'Pitching') && (
                                    <li>
                                        <h2>
                                            <FormattedMessage id="player.events.pitchingFees" />
                                        </h2>
                                        <p>
                                            {event?.registrationTypes?.find((r) => r.type === 'Pitching')?.price &&
                                            event.registrationTypes?.find((r) => r.type === 'Pitching')?.price! > 0 ? (
                                                event.registrationTypes?.find((r) => r.type === 'Pitching')?.price
                                            ) : (
                                                <FormattedMessage id="player.events.free" />
                                            )}
                                        </p>
                                    </li>
                                )}
                            </ul>

                            <div className={styles.register}>
                                {event?.status !== 'Completed' &&
                                    (!this.context.auth || this.context.auth.authorities.includes('ROLE_PARENT')) && (
                                        <Link to={`/signup?eventId=${event?.id}`}>
                                            <Button size="large" type="primary" shape="round">
                                                <FormattedMessage id="player.events.register" />
                                            </Button>
                                        </Link>
                                    )}
                            </div>
                        </div>

                        <div className={styles.register}>
                            <div className={styles.sponsor}>
                                <div className={styles.logo}>
                                    <Image
                                        src={event?.organizationLogo || logo}
                                        fallback={logo}
                                        preview={false}
                                        className={desktop ? styles.orgLogoDesktop : styles.orgLogoMobile}
                                    />
                                </div>
                                <div className={styles.date}>
                                    <div>
                                        <FormattedDate value={event?.startDate} day="2-digit" />
                                    </div>
                                    <div>
                                        <p>
                                            <FormattedDate value={event?.startDate} month="short" />
                                        </p>
                                        <p>
                                            <FormattedDate value={event?.startDate} weekday="short" />,{' '}
                                            <FormattedDate value={event?.startDate} hour="2-digit" minute="2-digit" />
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {event?.status !== 'Completed' &&
                                (!this.context.auth || this.context.auth.authorities.includes('ROLE_PARENT')) && (
                                    <Link to={`/signup?eventId=${event?.id}`}>
                                        <Button size="large" type="primary" shape="round">
                                            <FormattedMessage id="player.events.register" />
                                        </Button>
                                    </Link>
                                )}
                        </div>
                    </div>

                    <div className={styles.more}>
                        <div className={styles.flex}>
                            <div className={styles.info}>
                                <h3>
                                    <FormattedMessage id="events.event.summary.summary" />
                                </h3>
                                <div className={styles.desc}>
                                    {event?.description && (
                                        <ReactQuill value={event?.description} readOnly={true} theme={'bubble'} />
                                    )}
                                </div>
                            </div>

                            <div className={styles.map}>
                                <h3>
                                    <FormattedMessage id="events.event.summary.location" />
                                </h3>
                                <div className={styles.googleMaps}>
                                    {event && event?.lat && event?.lon && (
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: 'AIzaSyA8ghrZRcPK_8n1S3I0yyph3h8c0IYUT_o' }}
                                            defaultCenter={{
                                                lat: event.lat,
                                                lng: event.lon,
                                            }}
                                            defaultZoom={17}
                                        ></GoogleMapReact>
                                    )}
                                </div>
                                <p>{event?.address}</p>
                                <Button icon={<Icon component={locationSvg} />}>
                                    {<FormattedMessage id="events.event.summary.getDirections" />}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { auth } = this.context;
        const { event } = this.state;

        const isMenuVisible = auth?.authorities.includes('ROLE_ADMIN');
        const isAthletesMenuVisible =
            auth?.authorities.includes('ROLE_ADMIN') ||
            (auth?.authorities.includes('ROLE_ORGANIZATION') && event?.organizationId === auth.organizationId);
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="event-dashboard"
                        icon={<Icon component={backSvg} />}
                        onClick={() => this.props.history.push('/events')}
                    >
                        <Link to="/events">
                            <FormattedMessage id="events.event.backToEvents" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                <Menu.ItemGroup key="event-menu" title={!collapsed && <FormattedMessage id="events.event.info" />}>
                    <Menu.Item
                        key="event"
                        icon={<Icon component={eventSummarySvg} />}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/summary`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/summary`}>
                            <FormattedMessage id="events.event.summary" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-settings"
                        icon={<Icon component={eventSettingsSvg} />}
                        hidden={!isMenuVisible}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/settings`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/settings`}>
                            <FormattedMessage id="events.event.settings" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="leaderboard"
                        icon={<Icon component={eventLeaderboardSvg} />}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/leaderboard`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/leaderboard`}>
                            <FormattedMessage id="events.event.leaderboard" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-players"
                        icon={<Icon component={positionsSvg} />}
                        hidden={!isAthletesMenuVisible}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/players`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/players`}>
                            <FormattedMessage id="events.event.athletes" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        return <>{this.renderEventDetails(desktop)}</>;
    };

    render() {
        const { loading, event } = this.state;
        const fullName =
            event &&
            `${event?.name} - ${this.props.intl.formatMessage({
                id: 'events.event.event.meta.title',
            })}`;
        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="event"
                    menu={this.renderMenu}
                    content={this.renderContent}
                    header={this.renderHeader}
                    loading={loading === 'initializing'}
                />
            </>
        );
    }
}
export default injectIntl(EventPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    event?: Event;
    sports?: Sport[];
    modalShareVisible?: boolean;
    loading?: 'loading' | 'saving' | 'initializing' | 'completing';
}
