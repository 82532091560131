import Icon from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import CustomContext from '../../../context/CustomContext';
import { ReactComponent as adminCodesSvg } from '../../../resources/images/ico-adminCodes.svg';
import { ReactComponent as adminTeamSvg } from '../../../resources/images/ico-adminTeam.svg';
import { ReactComponent as adminTutorialSvg } from '../../../resources/images/ico-adminTutorial.svg';
import { ReactComponent as awardsSvg } from '../../../resources/images/ico-award.svg';
import { ReactComponent as collegeSvg } from '../../../resources/images/ico-collegeDirectory.svg';
import { ReactComponent as eventsSvg } from '../../../resources/images/ico-events.svg';
import { ReactComponent as usersGroupsSvg } from '../../../resources/images/ico-grid.svg';
import { ReactComponent as leaderboardSvg } from '../../../resources/images/ico-leaderboard.svg';
import { ReactComponent as organizationsSvg } from '../../../resources/images/ico-list.svg';
import { ReactComponent as collegesSvg } from '../../../resources/images/ico-rosterGrades.svg';
import { ReactComponent as rostersSvg } from '../../../resources/images/ico-rosters.svg';
import { ReactComponent as teamsSvg } from '../../../resources/images/ico-rosterTeams.svg';
import { ReactComponent as seasonsSvg } from '../../../resources/images/ico-seasons.svg';
import ParentMenuComponent from '../Common/ParentMenuComponent/ParentMenuComponent';
import PlayerMenuComponent from '../Common/PlayerMenuComponent/PlayerMenuComponent';
import styles from './AdminNavigationComponent.module.scss';

class AdminNavigationComponent extends React.Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /*** METHODS ***/

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    /*** COMPONENTS ***/

    renderPlayerMenu = (): React.ReactNode | undefined => {
        const { collapsed, userId, sportId, playerEditable } = this.props;
        if (userId && sportId) {
            return (
                <PlayerMenuComponent
                    collapsed={collapsed}
                    userId={userId}
                    sportId={sportId}
                    divider={true}
                    editable={playerEditable}
                />
            );
        }
    };

    renderParentMenu = (): React.ReactNode | undefined => {
        const { collapsed, userId, sportId } = this.props;
        if (userId && !sportId) {
            return <ParentMenuComponent collapsed={collapsed} userId={userId} />;
        }
    };

    render() {
        const { menu, collapsed } = this.props;

        if (menu) {
            return menu;
        } else {
            return (
                <>
                    {this.renderPlayerMenu()}
                    {this.renderParentMenu()}

                    <Menu.ItemGroup
                        key="admin-dashboard"
                        title={!collapsed && <FormattedMessage id="navigation.admin.dashboard" />}
                    >
                        <Menu.Item
                            key="rosters"
                            icon={<Icon component={rostersSvg} />}
                            onClick={() => this.navigate('/organizations')}
                        >
                            <Link to="/organizations">
                                <FormattedMessage id="navigation.admin.dashboard.affiliates" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="events"
                            icon={<Icon component={eventsSvg} />}
                            onClick={() => this.navigate('/events')}
                        >
                            <Link to="/events">
                                <FormattedMessage id="navigation.admin.dashboard.events" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="leaderboard"
                            icon={<Icon component={leaderboardSvg} />}
                            onClick={() => this.navigate('/leaderboard')}
                        >
                            <Link to="/leaderboard">
                                <FormattedMessage id="navigation.admin.dashboard.leaderboard" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="seasons"
                            icon={<Icon component={seasonsSvg} />}
                            onClick={() => this.navigate('/seasons')}
                        >
                            <Link to="/seasons">
                                <FormattedMessage id="navigation.admin.dashboard.seasons" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="colleges-public"
                            icon={<Icon component={collegeSvg} />}
                            onClick={() => this.navigate('/colleges')}
                        >
                            <Link to="/colleges">
                                <FormattedMessage id="navigation.admin.dashboard.colleges" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="groups"
                            icon={<Icon component={usersGroupsSvg} />}
                            onClick={() => this.navigate('/groups')}
                        >
                            <Link to="/groups">
                                <FormattedMessage id="navigation.admin.dashboard.groups" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Divider className={styles.divider} />
                    {false && (
                        <>
                            <Menu.ItemGroup
                                key="events-admin"
                                title={!collapsed && <FormattedMessage id="navigation.admin.eventsAdmin" />}
                            >
                                <Menu.Item
                                    key="event-creation"
                                    icon={<Icon component={adminTutorialSvg} />}
                                    onClick={() => this.navigate('/event-creation')}
                                >
                                    <Link to="/event-creation">
                                        <FormattedMessage id="navigation.admin.eventsAdmin.eventCreation" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="events-management"
                                    icon={<Icon component={adminCodesSvg} />}
                                    onClick={() => this.navigate('/events-management')}
                                >
                                    <Link to="/events-management">
                                        <FormattedMessage id="navigation.admin.eventsAdmin.eventsManagement" />
                                    </Link>
                                </Menu.Item>
                                <Menu.Item
                                    key="events-media"
                                    icon={<Icon component={adminTeamSvg} />}
                                    onClick={() => this.navigate('/events-media')}
                                >
                                    <Link to="/events-media">
                                        <FormattedMessage id="navigation.admin.eventsAdmin.eventsMedia" />
                                    </Link>
                                </Menu.Item>
                            </Menu.ItemGroup>
                            <Divider className={styles.divider} />
                        </>
                    )}
                    <Menu.ItemGroup key="admin" title={!collapsed && <FormattedMessage id="navigation.admin.admin" />}>
                        <Menu.Item
                            key="admin-organizations"
                            icon={<Icon component={organizationsSvg} />}
                            onClick={() => this.navigate('/admin/organizations')}
                        >
                            <Link to="/admin/organizations">
                                <FormattedMessage id="navigation.admin.admin.organizations" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            hidden={true}
                            key="admin-teams"
                            icon={<Icon component={teamsSvg} />}
                            onClick={() => this.navigate('/admin/teams')}
                        >
                            <Link to="/admin/teams">
                                <FormattedMessage id="navigation.admin.admin.teams" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="admin-colleges"
                            icon={<Icon component={collegesSvg} />}
                            onClick={() => this.navigate('/admin/colleges')}
                        >
                            <Link to="/admin/colleges">
                                <FormattedMessage id="navigation.admin.admin.colleges" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="admin-awards"
                            icon={<Icon component={awardsSvg} />}
                            onClick={() => this.navigate('/admin/awards')}
                        >
                            <Link to="/admin/awards">
                                <FormattedMessage id="navigation.admin.admin.awards" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="notifications"
                            icon={<Icon component={seasonsSvg} />}
                            onClick={() => this.navigate('/admin/notifications')}
                        >
                            <Link to="/admin/notifications">
                                <FormattedMessage id="navigation.admin.admin.notifications" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </>
            );
        }
    }
}
export default injectIntl(withRouter(AdminNavigationComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    menu?: React.ReactNode;
    collapsed: boolean;
    playerEditable?: boolean;
    userId?: string;
    sportId?: number;
}
