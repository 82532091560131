import { Page } from '../model/Elements';
import { OrganizationSport } from '../model/Entities';
import apiAxios from './CustomAxios';

class OrganizationSportsApi {
    list = async (
        limit: number,
        page: number,
        search?: string,
        organizationId?: string,
        sportId?: number,
    ): Promise<Page<OrganizationSport>> => {
        const filteringOrganizationOperation = 'filtering.OrganizationId.Operation';
        const filteringSportOperation = 'filtering.SportId.Operation';
        const filteringOrganizationValue = 'filtering.OrganizationId.Value';
        const filteringSportValue = 'filtering.SportId.Value';
        const sortingName = 'sorting.Name';
        const sorting = 'Asc';

        const response = await apiAxios.get<Page<OrganizationSport>>('/v1/organizations/sports', {
            params: {
                limit,
                page,
                search: search || undefined,
                [filteringOrganizationOperation]: organizationId ? 'Equal' : undefined,
                [filteringOrganizationValue]: organizationId || undefined,
                [filteringSportOperation]: sportId ? 'Equal' : undefined,
                [filteringSportValue]: sportId || undefined,
                [sortingName]: sorting,
            },
        });

        return response.data;
    };

    listAll = async (organizationId?: string, sportId?: number): Promise<OrganizationSport[]> => {
        let page = 1;
        let total: number;
        let items: OrganizationSport[] = [];
        do {
            const itemsPage = await this.list(500, page, undefined, organizationId, sportId);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        return items;
    };

    get = async (organizationId: string, sportId?: number): Promise<OrganizationSport[]> => {
        const response = await apiAxios.get<OrganizationSport[]>(`/v1/organizations/${organizationId}/sports`, {
            params: { sportId },
        });

        return response.data;
    };

    create = async (organizationId: string, name: string, sportId: number): Promise<void> => {
        await apiAxios.post<void>(`/v1/organizations/${organizationId}/sports`, { name, sportId });
    };

    update = async (organizationId: string, name: string, sportId: number): Promise<OrganizationSport> => {
        const response = await apiAxios.put<OrganizationSport>(
            `/v1/organizations/${organizationId}/sports/${sportId}`,
            { name },
        );
        return response.data;
    };

    delete = async (organizationId: string, sportId: number): Promise<void> => {
        await apiAxios.delete<void>(`/v1/organizations/${organizationId}/sports/${sportId}`);
    };
}
const organizationSportsApi: OrganizationSportsApi = new OrganizationSportsApi();
export default organizationSportsApi;
