import React, { useContext } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import CustomContext from '../../context/CustomContext';

/**
 * This component represents a protected route. It redirect to the home page if the user is not authorized to view a route.
 * @param props - the route props
 */
const DefaultRoute: React.FC<RouteProps> = (props: RouteProps) => {
    const context = useContext(CustomContext);
    const { auth } = context;

    if (auth && auth.authorities.includes('ROLE_ADMIN')) {
        return <Redirect to="/organizations" />;
    } else if (auth && auth.authorities.includes('ROLE_ORGANIZATION_OWNER')) {
        return <Redirect to={`/affiliate-owners/${context.auth?.id}`} />;
    } else if (auth && auth.authorities.includes('ROLE_ORGANIZATION_STAFF')) {
        return <Redirect to={`/affiliate-staffs/${context.auth?.id}`} />;
    } else if (auth && auth.authorities.includes('ROLE_ORGANIZATION_COACH')) {
        return <Redirect to={`/affiliate-coaches/${context.auth?.id}`} />;
    } else if (auth && auth.authorities.includes('ROLE_COLLEGE_COACH')) {
        return <Redirect to={`/college-coaches/${context.auth?.id}`} />;
    } else if (auth && auth.authorities.includes('ROLE_PLAYER')) {
        return <Redirect to={`/players/${context.auth?.id}/profile?sportId=${context.auth?.sportId}`} />;
    } else if (auth && auth.authorities.includes('ROLE_PARENT')) {
        return <Redirect to={`/parents/${context.auth?.id}`} />;
    } else {
        return <Redirect to="/leaderboard" />;
    }
};
export default DefaultRoute;
