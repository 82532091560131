import { Page } from '../model/Elements';
import { College, SimpleCollege } from '../model/Entities';
import apiAxios from './CustomAxios';

class CollegeApi {
    list = async (
        limit: number,
        page: number,
        sortField?: string,
        sortOrder?: string,
        search?: string,
        divisionId?: number,
        favorite?: boolean,
        state?: string,
        sportId?: number,
    ): Promise<Page<College>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getSortField(sortField);
        const filteringDivisionOperationName = 'filtering.Division.Operation';
        const filteringDivisionOperationValue = divisionId ? 'Equal' : undefined;
        const filteringDivisionValue = 'filtering.Division.Value';
        const filteringFavorite = 'filtering.Favorite';
        const filteringStateOperationName = 'filtering.State.Operation';
        const filteringStateOperationValue = state ? 'Equal' : undefined;
        const filteringStateValue = 'filtering.State.Value';
        const filteringSportOperationName = 'filtering.Sport.Operation';
        const filteringSportOperationValue = sportId ? 'Equal' : undefined;
        const filteringSportValue = 'filtering.Sport.Value';
        const response = await apiAxios.get<Page<College>>('/v1/colleges', {
            params: {
                limit,
                page,
                [field]: order,
                search,
                [filteringDivisionOperationName]: filteringDivisionOperationValue,
                [filteringDivisionValue]: divisionId ? divisionId : undefined,
                [filteringFavorite]: favorite,
                [filteringStateOperationName]: filteringStateOperationValue,
                [filteringStateValue]: state ? state : undefined,
                [filteringSportOperationName]: filteringSportOperationValue,
                [filteringSportValue]: sportId ? sportId : undefined,
            },
        });

        return response.data;
    };

    listAll = async (): Promise<College[]> => {
        return Promise.resolve(colleges);
    };

    listSimpleColleges = async (
        state?: string,
        mile?: number,
        lat?: number,
        lon?: number,
        divisionId?: number,
        sportId?: number,
        limit?: number,
        page?: number,
    ): Promise<SimpleCollege[]> => {
        const filteringDistanceFilteringLat = 'DistanceFiltering.Lat';
        const filteringDistanceFilteringLon = 'DistanceFiltering.Lon';
        const filteringDistanceFilteringDistance = 'DistanceFiltering.Distance';
        const response = await apiAxios.get<SimpleCollege[]>('/v1/colleges/light', {
            params: {
                limit,
                page,
                state,
                divisionId,
                sportId,
                [filteringDistanceFilteringLat]: lat,
                [filteringDistanceFilteringLon]: lon,
                [filteringDistanceFilteringDistance]: mile,
            },
        });

        return response.data;
    };

    get = async (collegeId?: number): Promise<College> => {
        const response = await apiAxios.get<College>(`/v1/colleges/${collegeId}`);
        return response.data;
    };

    create = async (college: College): Promise<College> => {
        const response = await apiAxios.post<College>('/v1/colleges', college);
        return response.data;
    };

    update = async (college: College): Promise<College> => {
        const response = await apiAxios.put<College>(`/v1/colleges/${college.id}`, college);
        return response.data;
    };

    delete = async (college: College): Promise<void> => {
        await apiAxios.delete(`/v1/colleges/${college.id}`);
    };

    uploadLogo = async (collegeId: number, file: any): Promise<void> => {
        const formData: FormData = new FormData();
        file && formData.append('file', file);
        await apiAxios.post<void>(`/v1/colleges/${collegeId}/upload-logo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    createFavorite = async (collegeId: number): Promise<void> => {
        const response = await apiAxios.post<void>(`/v1/colleges/${collegeId}/favorites`);
        return response.data;
    };

    deleteFavorite = async (collegeId: number): Promise<void> => {
        const response = await apiAxios.delete<void>(`/v1/colleges/${collegeId}/favorites`);
        return response.data;
    };

    recommendPlayer = async (collegeId: number, playerId: string, sportId: number, message?: string): Promise<void> => {
        const response = await apiAxios.post<void>(`/v1/colleges/${collegeId}/recommendations`, {
            playerId: playerId,
            message: message,
            sportId: sportId,
        });
        return response.data;
    };

    private getSortField(sortField?: string): string {
        let field: string = 'sorting.School';
        if (sortField === 'school') {
            field = 'sorting.School';
        } else if (sortField === 'division') {
            field = 'sorting.Division';
        }
        return field;
    }
}

const collegeApi: CollegeApi = new CollegeApi();
export default collegeApi;

// mock data
const colleges: College[] = [...Array(4)].map((v, i) => ({
    id: i,
    name: `College ${i}`,
    division: 'NCAA Division I',
    state: 'Alabama',
    athleticWebsite: `College${i}.com`,
}));
