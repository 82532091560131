import { Button, FormInstance, Result } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import eventApi from '../../../apis/EventApi';
import eventRegistrationApi from '../../../apis/EventRegistrationApi';
import familyApi from '../../../apis/FamilyApi';
import sportApi from '../../../apis/SportApi';
import LayoutMarketingComponent from '../../../components/LayoutMarketingComponent/LayoutMarketingComponent';
import CustomContext from '../../../context/CustomContext';
import { Event, EventChild, EventRegistrationInfo, EventRegistrationInfoList, Sport } from '../../../model/Entities';
import { PaymentProcessType } from '../../../model/Types';
import HeadMetadata from '../../../services/HeadMetadata';
import notificationService from '../../../services/NotificationService';
import SignUpPaymentExecutionComponent from './SignUpPaymentExecutionComponent/SignUpPaymentExecutionComponent';
import SignUpPaymentSelectionComponent from './SingUpPaymentSelectionComponent/SignUpPaymentSelectionComponent';
import styles from './SignUpPaymentPage.module.scss';

class SignUpPaymentPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { sports: [], eventChildren: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            // get the eventId to register in
            const params = new URLSearchParams(window.location.search);
            const eventId = params.get('eventId') ? +params.get('eventId')! : undefined;

            // if we have an eventId we call the event and sports apis
            let event;
            let sports: Sport[] = [];
            let eventRegistrationList: EventRegistrationInfoList;
            if (eventId) {
                const responses = await Promise.all([
                    eventApi.get(eventId),
                    sportApi.listAll(),
                    eventRegistrationApi.get(eventId),
                ]);
                event = responses[0];
                sports = responses[1];
                eventRegistrationList = responses[2];
            }

            let family;
            if (this.context.auth) {
                family = await familyApi.get(this.context.auth.familyId!);
            }

            let eventChildren: EventChild[] = [];
            family?.members
                ?.filter((m) => m.memberType === 'Child')
                .map((m) => {
                    const eventChild: EventChild = {};
                    eventChild.userId = m.userId;
                    eventChild.general = true;
                    eventChild.givenName = m.givenName;
                    eventChild.familyName = m.familyName;
                    eventChildren.push(eventChild);
                    return {};
                });

            const generalPrice = event?.registrationTypes?.find((r) => r.type === 'Global')?.price || 0;
            const catchingPrice = event?.registrationTypes?.find((r) => r.type === 'Catching')?.price;
            const pitchingPrice = event?.registrationTypes?.find((r) => r.type === 'Pitching')?.price;

            const paymentDisplay = 'selection';

            eventChildren = eventChildren.filter(
                (child) => !eventRegistrationList.items?.find((i) => i.players?.find((p) => p.userId === child.userId)),
            );

            this.setState({ event, sports, eventChildren, generalPrice, catchingPrice, pitchingPrice, paymentDisplay });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    updateChildren = (eventChildrenAux: EventChild[]) => {
        this.setState({ eventChildren: eventChildrenAux });
    };

    changeDisplay = (paymentDisplay: PaymentProcessType, registration?: EventRegistrationInfo) => {
        this.setState({ paymentDisplay, registration });
    };

    goToProfile = () => {
        this.props.history.push('/');
    };

    /*** COMPONENTS ***/
    renderContent = (): React.ReactElement | undefined => {
        const {
            event,
            sports,
            paymentDisplay,
            eventChildren,
            generalPrice,
            catchingPrice,
            pitchingPrice,
            registration,
        } = this.state;
        if (event) {
            return (
                <div className={styles.content}>
                            
                    <div hidden={paymentDisplay !== 'selection'}>
                        <SignUpPaymentSelectionComponent
                            event={event}
                            sports={sports}
                            eventChildren={eventChildren}
                            generalPrice={generalPrice}
                            catchingPrice={catchingPrice}
                            pitchingPrice={pitchingPrice}
                            onUpdate={this.updateChildren}
                            changeDisplay={this.changeDisplay}
                        />
                    </div>
                    {registration && (
                        <div hidden={paymentDisplay !== 'execution'}>
                            <SignUpPaymentExecutionComponent
                                onUpdate={this.changeDisplay}
                                event={event}
                                registration={registration}
                            />
                        </div>
                    )}
                    <div hidden={paymentDisplay !== 'success'}>
                        <Result
                            status="success"
                            title={<FormattedMessage id="signupPayment.success" />}
                            extra={[
                                <Button type="primary" onClick={this.goToProfile}>
                                    <FormattedMessage id="signupPayment.goToProfile" />
                                </Button>,
                            ]}
                        />
                    </div>
                </div>
            );
        }
    };

    render() {
        const { loading } = this.state;

        return (
            <>
                <HeadMetadata titleKey="signupPayment.meta.title" />
                <LayoutMarketingComponent content={this.renderContent} loading={loading === 'initializing'} />
            </>
        );
    }
}
export default injectIntl(SignUpPaymentPage);

interface Props extends RouteComponentProps, WrappedComponentProps {}

interface State {
    event?: Event;
    loading?: 'saving' | 'initializing';
    sports: Sport[];
    eventChildren: EventChild[];
    generalPrice?: number;
    catchingPrice?: number;
    pitchingPrice?: number;
    paymentDisplay?: PaymentProcessType;
    registration?: EventRegistrationInfo;
}
