import * as React from 'react';
import styles from './ParentMenuComponent.module.scss';
import Icon from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as playerProfileSvg } from '../../../../resources/images/ico-playerProfile.svg';
import CustomContext from '../../../../context/CustomContext';

class ParentMenuComponent extends React.Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /*** METHODS ***/

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    /*** COMPONENTS ***/

    render() {
        const { collapsed, userId } = this.props;

        return (
            <Menu.ItemGroup key="admin-parent" title={!collapsed && <FormattedMessage id="navigation.menu.parent" />}>
                <Menu.Item
                    key="parent-profile"
                    icon={<Icon component={playerProfileSvg} />}
                    onClick={() => this.navigate(`/parents/${userId}`)}
                >
                    <Link to={`/parents/${userId}`}>
                        <FormattedMessage id="navigation.parent.dashboard.profile" />
                    </Link>
                </Menu.Item>
                <Divider className={styles.divider} />
            </Menu.ItemGroup>
        );
    }
}
export default injectIntl(withRouter(ParentMenuComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    collapsed: boolean;
    userId: string;
}
