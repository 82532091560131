import { ChildRegistered, ParentRegistered } from '../model/Entities';
import apiAxios from './CustomAxios';

class SignUpApi {
    createParent = async (parent: ParentRegistered): Promise<void> => {
        await apiAxios.post<void>('/v1/signup', parent);
    };

    createChild = async (child: ChildRegistered): Promise<void> => {
        child.dateOfBirth = child.dateOfBirth ? Math.round(child.dateOfBirth / 1000) : undefined;
        await apiAxios.post<void>('/v1/signup/child', child);
    };
}
const signUpApi: SignUpApi = new SignUpApi();
export default signUpApi;
