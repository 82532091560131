import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, FormInstance, Input, InputNumber, message, Row, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import divisionApi from '../../../apis/DivisionApi';
import eventApi from '../../../apis/EventApi';
import positionApi from '../../../apis/PositionApi';
import signUpApi from '../../../apis/SignUpApi';
import sportApi from '../../../apis/SportApi';
import userApi from '../../../apis/UserApi';
import LayoutMarketingComponent from '../../../components/LayoutMarketingComponent/LayoutMarketingComponent';
import CustomContext from '../../../context/CustomContext';
import { ChildRegistered, Division, Event, Position, Sport, UserAccount } from '../../../model/Entities';
import { countries, CountryType, genders, shirtSizes } from '../../../model/Types';
import HeadMetadata from '../../../services/HeadMetadata';
import metricService from '../../../services/MetricService';
import notificationService from '../../../services/NotificationService';
import stateService from '../../../services/StateService';
import EventInfoComponent from '../EventInfoComponent/EventInfoComponent';
import styles from './SignUpChildPage.module.scss';

class SignUpChildPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { sports: [], divisions: [], positions: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            // get the eventId to register in
            const params = new URLSearchParams(window.location.search);
            const eventId = params.get('eventId') ? +params.get('eventId')! : undefined;

            // if we have an eventId we call the event and sports apis
            let event;
            let sports: Sport[] = [];
            let divisions: Division[] = [];
            let positions: Position[] = [];
            if (eventId) {
                const responses = await Promise.all([eventApi.get(eventId), sportApi.listAll()]);
                event = responses[0];
                sports = responses[1];
            }
            if (eventId) {
                const responses = await Promise.all([
                    divisionApi.list(event?.sportId!),
                    positionApi.list(event?.sportId!),
                ]);
                divisions = responses[0];
                positions = responses[1];
            }

            let user;
            // We can remove this condition in the future
            if (this.context.auth) {
                user = await userApi.getAccount(this.context.auth.id!);
            }

            this.formRef.current?.setFieldsValue({
                country: user?.country,
                state: user?.state,
                address: user?.address,
                city: user?.city,
                zipCode: user?.zip,
            });

            const country = user?.country ? user.country : undefined;

            this.setState({ event, sports, divisions, positions, user, country });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    save = async () => {
        const { event } = this.state;
        try {
            this.setState({ loading: 'saving' });

            const values = await this.formRef.current?.validateFields();

            const child: ChildRegistered = Object.assign({}, values);
            child.sportId = event?.sportId;
            child.height =
                values.height || values.heightInches
                    ? metricService.toTotalInches(values.height, values.heightInches)
                    : undefined;
            child.dateOfBirth = values.dateOfBirth ? values.dateOfBirth.valueOf() : undefined;

            await signUpApi.createChild(child);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.history.push(`/signup/payment?eventId=${event?.id}`);
        } catch (error: any) {
            if (!error.errorFields) {
                if (error.response.status === 409 && error.response.data) {
                    notificationService.displayError(error, this.props.intl, [
                        { status: 409, message: error.response.data.message as string },
                    ]);
                } else {
                    notificationService.displayError(error, this.props.intl);
                }
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    saveAndRefresh = async () => {
        const { event } = this.state;
        try {
            this.setState({ loading: 'savingAndRefreshing' });

            const values = await this.formRef.current?.validateFields();

            const child: ChildRegistered = Object.assign({}, values);
            child.sportId = event?.sportId;
            child.height =
                values.height || values.heightInches
                    ? metricService.toTotalInches(values.height, values.heightInches)
                    : undefined;
            child.dateOfBirth = values.dateOfBirth ? values.dateOfBirth.valueOf() : undefined;

            await signUpApi.createChild(child);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.resetPage();
        } catch (error: any) {
            if (!error.errorFields) {
                if (error.response.status === 409 && error.response.data) {
                    notificationService.displayError(error, this.props.intl, [
                        { status: 409, message: error.response.data.message as string },
                    ]);
                } else {
                    notificationService.displayError(error, this.props.intl);
                }
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    resetPage = () => {
        const { user } = this.state;
        this.formRef.current?.resetFields();
        this.formRef.current?.setFieldsValue({
            country: user?.country,
            state: user?.state,
            address: user?.address,
            city: user?.city,
            zipCode: user?.zip,
        });
        const country = user?.country ? user.country : undefined;
        this.setState({ country });
    };

    /**
     * Reset the state when the country is changed in the form
     * @param country
     */
    changeCountry = (country?: CountryType) => {
        this.formRef.current?.resetFields(['state']);
        this.setState({ country });
    };

    /*** COMPONENTS ***/
    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderForm = (): React.ReactElement | undefined => {
        const { loading, divisions, positions } = this.state;

        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));

        const genderOptions = genders.map((gender) => (
            <Select.Option key={gender} value={gender}>
                <FormattedMessage id={gender} />
            </Select.Option>
        ));

        const divisionOptions = divisions.map((division) => (
            <Select.Option key={division.id} value={division.id!}>
                {division.name}
            </Select.Option>
        ));

        const positionOptions = positions.map((p) => (
            <Checkbox key={p.id} value={p.id}>
                <span>{p.code}</span> {p.name}
            </Checkbox>
        ));

        const shirtSizeOptions = shirtSizes.map((s) => (
            <Select.Option key={s} value={s}>
                {s}
            </Select.Option>
        ));

        const minGraduationYear = moment().year() - 19;
        const maxGraduationYear = moment().year() + 19;

        return (
            <Form ref={this.formRef} colon={false} layout="vertical" className={styles.form}>
                <h2>
                    <FormattedMessage id="signupChild.childProfile" />
                </h2>

                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.firstName" />}
                            name="givenName"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                    message: <FormattedMessage id="status.name" />,
                                },
                            ]}
                        >
                            <Input maxLength={64} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.lastName" />}
                            name="familyName"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                    message: <FormattedMessage id="status.name" />,
                                },
                            ]}
                        >
                            <Input maxLength={64} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.dateOfBirth" />}
                            name="dateOfBirth"
                            className={styles.input}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker
                                size="small"
                                format="MM-DD-YYYY"
                                disabledDate={(current) => current.isAfter(moment())}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.gender" />}
                            name="gender"
                            className={styles.select}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select>{genderOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.email" />}
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: <FormattedMessage id="status.email.invalid" />,
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.phone" />}
                            name="phone"
                            rules={[
                                {
                                    pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                    message: <FormattedMessage id="status.phoneNumber" />,
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item label={<FormattedMessage id="signupChild.streetAddress" />} name="address">
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item label={<FormattedMessage id="signupChild.city" />} name="city">
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.country" />}
                            name="country"
                            className={styles.select}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select onChange={(value: CountryType) => this.changeCountry(value)}>
                                {countryOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.state" />}
                            name="state"
                            className={styles.select}
                        >
                            <Select allowClear>{this.renderStates()}</Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item label={<FormattedMessage id="signupChild.zip" />} name="zipCode">
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.username" />}
                            name="userName"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[a-z0-9_.-]{4,32}$/,
                                    message: <FormattedMessage id="status.characters" />,
                                },
                            ]}
                        >
                            <Input maxLength={32} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.password" />}
                            name="password"
                            className={styles.pass}
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                                    message: <FormattedMessage id="status.password" />,
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password
                                type="password"
                                minLength={8}
                                size="large"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.repeatPassword" />}
                            name="repeatPassword"
                            className={styles.pass}
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                                    message: <FormattedMessage id="status.password" />,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(<FormattedMessage id="status.passwordEqual" />);
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                type="password"
                                minLength={8}
                                size="large"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <h3>
                    <FormattedMessage id="signupChild.playerInfo" />
                </h3>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.division" />}
                            name="divisionId"
                            className={styles.select}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select>{divisionOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={24} className={styles.formRow24}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.positions" />}
                            name="positions"
                            className="positionsCheckbox"
                        >
                            <Checkbox.Group className={styles.positions}>{positionOptions}</Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.height" />}
                            name="height"
                            rules={[
                                {
                                    type: 'number',
                                    message: this.props.intl.formatMessage({
                                        id: 'status.height',
                                    }),
                                },
                            ]}
                        >
                            <InputNumber type="number" min={0} max={7} precision={0} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item label={<FormattedMessage id="signupChild.heightInches" />} name="heightInches">
                            <InputNumber type="number" min={0} max={11} precision={0} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.weight" />}
                            name="weight"
                            rules={[
                                {
                                    type: 'number',
                                    message: this.props.intl.formatMessage({
                                        id: 'status.weight',
                                    }),
                                },
                            ]}
                        >
                            <InputNumber type="number" min={0} maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12} className={styles.formRow12}>
                        <Form.Item label={<FormattedMessage id="signupChild.school" />} name="school">
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4} className={styles.formRow4}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.grade" />}
                            name="grade"
                            rules={[{ pattern: /\b([1-9]|1[0-2])\b/, message: <FormattedMessage id="status.grade" /> }]}
                        >
                            <InputNumber type="number" min={1} max={12} size="small" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.gradYear" />}
                            name="gradYear"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    min: minGraduationYear,
                                    type: 'number',
                                    max: maxGraduationYear,
                                    message: `${this.props.intl.formatMessage({
                                        id: 'status.gradYear',
                                    })} ${minGraduationYear} ${this.props.intl.formatMessage({
                                        id: 'and',
                                    })} ${maxGraduationYear}`,
                                },
                            ]}
                        >
                            <InputNumber type="number" min={0} max={maxGraduationYear} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.shirtSize" />}
                            name="shirtSize"
                            className={styles.select}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select>{shirtSizeOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>

                <h2>
                    <FormattedMessage id="signupChild.teamInfo" />
                </h2>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item label={<FormattedMessage id="signupChild.team" />} name="clubTeam">
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item label={<FormattedMessage id="signupChild.teamCoach" />} name="clubTeamCoach">
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.teamCoachEmail" />}
                            name="clubTeamCoachEmail"
                            rules={[
                                {
                                    type: 'email',
                                    message: <FormattedMessage id="status.email.invalid" />,
                                },
                            ]}
                        >
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8} className={styles.formRow8}>
                        <Form.Item
                            label={<FormattedMessage id="signupChild.teamCoachPhone" />}
                            name="clubTeamCoachPhone"
                            rules={[
                                {
                                    pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                    message: <FormattedMessage id="status.phoneNumber" />,
                                },
                            ]}
                        >
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                </Row>

                <div className={styles.buttons}>
                    <Form.Item>
                        <Link to="/">
                            <Button type="primary" size="large" className={styles.btnSecondary}>
                                <FormattedMessage id="button.cancel" tagName="span" />
                            </Button>
                        </Link>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            loading={loading === 'saving'}
                            onClick={this.save}
                            className={styles.btnMain}
                        >
                            <FormattedMessage id="signupChild.checkout" tagName="span" />
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            loading={loading === 'savingAndRefreshing'}
                            onClick={this.saveAndRefresh}
                            className={styles.btnMain}
                        >
                            <FormattedMessage id="signupChild.addAnother" tagName="span" />
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { event, sports } = this.state;
        return (
            <div className={styles.registrationForm}>
                <div className={styles.row}>
                    <div className={styles.flex}>
                        <aside>{event && <EventInfoComponent event={event} sports={sports} />}</aside>
                        <section className={styles.content}>
                            <div className={styles.header}>
                                <h1>
                                    <FormattedMessage id="signupChild.registration" />
                                </h1>
                                <p>
                                    <FormattedMessage id="signupChild.description" />
                                </p>
                            </div>
                            {this.renderForm()}
                        </section>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { loading } = this.state;

        return (
            <>
                <HeadMetadata titleKey="signupChild.meta.title" />
                <LayoutMarketingComponent content={this.renderContent} loading={loading === 'initializing'} />
            </>
        );
    }
}
export default injectIntl(SignUpChildPage);

interface Props extends RouteComponentProps, WrappedComponentProps {}

interface State {
    event?: Event;
    loading?: 'saving' | 'initializing' | 'savingAndRefreshing';
    sports: Sport[];
    country?: CountryType;
    divisions: Division[];
    positions: Position[];
    user?: UserAccount;
}
