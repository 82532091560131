import FileSaver from 'file-saver';
import qs from 'qs';
import { Page } from '../model/Elements';
import { Event, EventFilters, EventStaff, Leaderboard, LiveLeaderboardList, NewEvent } from '../model/Entities';
import { LeaderboardType } from '../model/Types';
import dateService from '../services/DateService';
import apiAxios from './CustomAxios';

class EventApi {
    list = async (
        limit?: number,
        page?: number,
        sortField?: string,
        sortOrder?: string,
        eventState?: string,
        sportId?: number,
        mo?: boolean,
        tu?: boolean,
        we?: boolean,
        th?: boolean,
        fr?: boolean,
        sa?: boolean,
        su?: boolean,
        location?: string,
        season?: string,
        level?: string,
        organizationId?: string,
    ): Promise<Page<Event>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const sortFieldName = this.getSortField(sortField);
        const filters = this.getFilters(
            eventState,
            sportId,
            mo,
            tu,
            we,
            th,
            fr,
            sa,
            su,
            location,
            season,
            level,
            organizationId,
        );
        const response = await apiAxios.get<Page<Event>>('/v1/events', {
            params: { limit, page, [sortFieldName]: order, ...filters },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        response.data.items.forEach((i) => [
            (i.startDate = i.startDate ? dateService.toLocal(i.startDate * 1000) : undefined),
            (i.endDate = i.endDate ? dateService.toLocal(i.endDate * 1000) : undefined),
        ]);

        return response.data;
    };

    get = async (eventId: number): Promise<Event> => {
        const response = await apiAxios.get<Event>(`/v1/events/${eventId}`);
        response.data.startDate = response.data.startDate
            ? dateService.toLocal(response.data.startDate * 1000)
            : undefined;
        response.data.endDate = response.data.endDate ? dateService.toLocal(response.data.endDate * 1000) : undefined;
        return response.data;
    };

    create = async (event: NewEvent): Promise<Event> => {
        event.startDate = event.startDate ? Math.floor(dateService.toUtc(event.startDate) / 1000) : undefined;
        event.endDate = event.endDate ? Math.floor(dateService.toUtc(event.endDate) / 1000) : undefined;
        const response = await apiAxios.post<Event>('/v1/events', event);
        return response.data;
    };

    updateOffline = async (event: Event): Promise<Event> => {
        const response = await apiAxios.put<Event>(`/v1/events/${event.id}/offline`, {
            isOffline: event.isOffline,
        });
        return response.data;
    };

    update = async (eventId: number, event: NewEvent): Promise<Event> => {
        event.startDate = event.startDate ? Math.floor(dateService.toUtc(event.startDate) / 1000) : undefined;
        event.endDate = event.endDate ? Math.floor(dateService.toUtc(event.endDate) / 1000) : undefined;
        const response = await apiAxios.put<Event>(`/v1/events/${eventId}`, event);
        return response.data;
    };

    delete = async (event: Event): Promise<void> => {
        await apiAxios.delete(`/v1/events/${event.id}`);
    };

    completeEvent = async (eventId: number): Promise<void> => {
        const response = await apiAxios.put<void>(`/v1/events/${eventId}/complete`);
        return response.data;
    };

    listLeaderboard = async (
        limit: number,
        page: number,
        eventId: number,
        orderingMetricId?: number,
        sortOrder?: string,
        positions?: number[],
        divisionIds?: number,
        gradYear?: number,
        sortByGradYear?: string,
        tabId?: number,
    ): Promise<Page<Leaderboard>> => {
        const orderBy = sortOrder === 'ascend' ? 'Asc' : 'Desc';
        let byGradYear;
        if (sortByGradYear) {
            byGradYear = sortByGradYear === 'ascend' ? 'Asc' : 'Desc';
        }
        const response = await apiAxios.get<Page<Leaderboard>>(`/v1/events/${eventId}/leaderboards`, {
            params: { limit, page, orderingMetricId, orderBy, positions, divisionIds, gradYear, byGradYear, tabId },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        return response.data;
    };

    exportLeaderboardSpreadsheet = async (
        event: Event,
        leaderboardType?: LeaderboardType,
        orderingMetricId?: number,
        sortOrder?: string,
        positions?: number[],
        divisionIds?: number,
        gradYear?: number,
        sortByGradYear?: string,
        tabId?: number,
    ): Promise<void> => {
        const orderBy = sortOrder === 'ascend' ? 'Asc' : 'Desc';
        let byGradYear;
        if (sortByGradYear) {
            byGradYear = sortByGradYear === 'ascend' ? 'Asc' : 'Desc';
        }
        const type = leaderboardType ? leaderboardType : 'global';

        const response = await apiAxios.get<Blob>(`/v1/events/${event.id}/excel-export`, {
            responseType: 'blob',
            params: { orderingMetricId, orderBy, positions, divisionIds, gradYear, byGradYear, tabId, type },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        FileSaver.saveAs(response.data, event.name + '.xlsx');
    };

    listLiveLeaderboard = async (
        eventId: number,
        type: LeaderboardType,
        divisionIds?: number,
        sortingCategory?: number,
    ): Promise<LiveLeaderboardList> => {
        const response = await apiAxios.get<LiveLeaderboardList>(`/v1/events/${eventId}/leaderboards/live`, {
            params: { divisionIds, type, sortingCategory },
        });

        return response.data;
    };

    listFilters = async (): Promise<EventFilters> => {
        const response = await apiAxios.get<EventFilters>(`/v1/events/filters`);
        return response.data;
    };

    sendEmails = async (eventId: number, users: string[]) => {
        const players: any = Object.assign({}, { players: users });
        await apiAxios.post<void>(`/v1/events/${eventId}/players/notify`, players);
    };

    listStaff = async (
        limit: number,
        page: number,
        eventId: number,
        sortField?: string,
        sortOrder?: string,
    ): Promise<Page<EventStaff>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getStaffSortField(sortField);
        const response = await apiAxios.get<Page<EventStaff>>(`/v1/events/${eventId}/staff`, {
            params: { limit, page, [field]: order },
        });
        return response.data;
    };

    listAllStaff = async (eventId: number, sortField?: string, sortOrder?: string): Promise<EventStaff[]> => {
        let page = 1;
        let total: number;
        let items: EventStaff[] = [];
        do {
            const itemsPage = await this.listStaff(500, page, eventId, sortField, sortOrder);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        return items;
    };

    addGroups = async (eventId: number, divisions: number[]): Promise<void> => {
        const response = await apiAxios.put<void>(`/v1/events/${eventId}/divisions`, { divisions: divisions });
        return response.data;
    };

    addExistingPlayer = async (eventId: number, userId: string): Promise<void> => {
        const response = await apiAxios.put<void>(`/v1/events/${eventId}/players/${userId}`);
        return response.data;
    };

    private getSortField(sortField?: string): string {
        let field: string;
        if (sortField === 'name') {
            field = 'sorting.Name';
        } else {
            field = 'sorting.StartDate';
        }

        return field;
    }

    private getStaffSortField(sortField?: string): string {
        let field: string;
        if (sortField === 'givenName') {
            field = 'sorting.GivenName';
        } else if (sortField === 'roles') {
            field = 'sorting.EventRole';
        } else {
            field = 'sorting.GivenName';
        }

        return field;
    }

    private getFilters(
        eventState?: string,
        sportId?: number,
        mo?: boolean,
        tu?: boolean,
        we?: boolean,
        th?: boolean,
        fr?: boolean,
        sa?: boolean,
        su?: boolean,
        location?: string,
        season?: string,
        level?: string,
        organizationId?: string,
    ) {
        let fields: any = {};

        if (eventState) {
            fields['filtering.EventState.Value'] = eventState;
            fields['filtering.EventState.Operation'] = 'Equal';
        }

        if (sportId) {
            fields['filtering.SportId.Value'] = sportId;
            fields['filtering.SportId.Operation'] = 'NumberIn';
        }

        if (mo || tu || we || th || fr || sa || su) {
            fields['filtering.StartDay.Value'] = [];
            if (mo) {
                fields['filtering.StartDay.Value'].push('Monday');
            }
            if (tu) {
                fields['filtering.StartDay.Value'].push('Tuesday');
            }
            if (we) {
                fields['filtering.StartDay.Value'].push('Wednesday');
            }
            if (th) {
                fields['filtering.StartDay.Value'].push('Thursday');
            }
            if (fr) {
                fields['filtering.StartDay.Value'].push('Friday');
            }
            if (sa) {
                fields['filtering.StartDay.Value'].push('Saturday');
            }
            if (su) {
                fields['filtering.StartDay.Value'].push('Sunday');
            }
            fields['filtering.StartDay.Operation'] = 'NumberIn';
        }

        if (location) {
            fields['filtering.Location.Value'] = location;
            fields['filtering.Location.Operation'] = 'Equal';
        }

        if (season) {
            fields['filtering.Season.Value'] = season;
            fields['filtering.Season.Operation'] = 'Equal';
        }

        if (level) {
            fields['filtering.Level.Value'] = level;
            fields['filtering.Level.Operation'] = 'Equal';
        }

        if (organizationId) {
            fields['filtering.OrganizationId.Value'] = organizationId;
            fields['filtering.OrganizationId.Operation'] = 'Equal';
        }

        return fields;
    }
}

const eventApi: EventApi = new EventApi();
export default eventApi;
