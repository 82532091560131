import { Button, Checkbox, Form, FormInstance, message, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import teamApi from '../../../../../apis/TeamApi';
import teamMemberApi from '../../../../../apis/TeamMemberApi';
import CustomContext from '../../../../../context/CustomContext';
import { Division, Team, TeamMember } from '../../../../../model/Entities';
import notificationService from '../../../../../services/NotificationService';

class TeamMemberModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { teams: props.teams, divisionId: props.divisionId, teamId: props.teamId };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { divisionId } = this.state;
        try {
            this.setState({ loading: 'loading' });

            this.formRef.current!.setFieldsValue({
                divisionId,
            });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    cancel = async () => {
        this.props.onCancel();
    };

    update = async () => {
        const { selectedTeamMembers, organizationId, teamId } = this.props;
        try {
            this.setState({ loading: 'saving' });
            const selectedTeamMembersIds = selectedTeamMembers.flatMap((t) => t.id!);
            const values = await this.formRef.current?.validateFields();
            const removeFromCurrentTeam = values.check;
            const newTeamId = values.teamId;
            await teamMemberApi.updateTeamFromTeamMembers(
                organizationId,
                teamId,
                newTeamId,
                selectedTeamMembersIds,
                removeFromCurrentTeam,
            );
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            this.formRef.current?.resetFields();
            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByDivision = async (divisionId: number) => {
        const { organizationId, sportId } = this.props;
        try {
            this.setState({ loading: 'loading' });
            const teams = await teamApi.listByOrganization(organizationId, false, sportId, divisionId);
            this.setState({ teams, divisionId });
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    render() {
        const { loading, teams, teamId } = this.state;
        const { divisions } = this.props;
        const divisionOptions = divisions?.map((division) => (
            <Select.Option key={division.id} value={division.id!}>
                {division.name}
            </Select.Option>
        ));
        const teamOptions = teams
            .filter((t) => t.id !== teamId)
            .map((team) => (
                <Select.Option key={team.id} value={team.id!}>
                    {team.name}
                </Select.Option>
            ));

        return (
            <Modal
                width={790}
                visible={true}
                title={<FormattedMessage id="rosters.roster.manageRoster.manageTeams.modal.title" />}
                onCancel={this.cancel}
                footer={[
                    <div>
                        <Button shape={'round'} onClick={this.cancel}>
                            <FormattedMessage id="button.cancel" />
                        </Button>
                        <Button shape={'round'} type="primary" onClick={this.update} loading={loading === 'saving'}>
                            <FormattedMessage id="button.add" tagName="span" />
                        </Button>
                    </div>,
                ]}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="rosters.roster.manageRoster.manageTeams.modal.division" />}
                        name="divisionId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select onChange={(value: number) => this.filterByDivision(value)}>{divisionOptions}</Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="rosters.roster.manageRoster.manageTeams.modal.team" />}
                        name="teamId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select>{teamOptions}</Select>
                    </Form.Item>
                    <Form.Item name="check" valuePropName="checked">
                        <Checkbox>
                            <FormattedMessage id="rosters.roster.manageRoster.manageTeams.modal.remove" />
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(TeamMemberModal);

interface Props extends WrappedComponentProps {
    onUpdate: () => Promise<void>;
    onCancel: () => void;
    divisions: Division[];
    divisionId: number;
    teams: Team[];
    teamId: string;
    organizationId: string;
    sportId: number;
    selectedTeamMembers: TeamMember[];
}

interface State {
    divisionId: number;
    teams: Team[];
    teamId: string;
    loading?: 'loading' | 'saving' | 'deleting';
}
