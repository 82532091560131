import { RouteComponentProps } from 'react-router';
import sportApi from '../apis/SportApi';
import userApi from '../apis/UserApi';
import userSportApi from '../apis/UserSportApi';
import { CustomAuth, Season, Sport, User, UserOrganizationMember, UserSport } from '../model/Entities';
import rolesService from './RolesService';

class PlayerService {
    getPlayer = async (props: RouteComponentProps<ParamsType>): Promise<PlayerResponse> => {
        const playerProps = this.getProps(props);
        const responses = await Promise.all([
            userApi.get(playerProps.userId),
            sportApi.get(playerProps.sportId),
            userSportApi.list(playerProps.userId),
        ]);
        const user = responses[0];
        const sport = responses[1];
        const userSports = responses[2];
        const userSport = this.getPlayerSport(userSports, sport);

        return { user, sport, userSport, userSports };
    };

    getProps = (props: RouteComponentProps<ParamsType>, user?: User): PlayerProps => {
        const userId = props.match.params.id;
        const params = new URLSearchParams(props.location.search);
        const sportId = params.get('sportId') || undefined;
        const familyId = params.get('familyId') || user?.familyId;

        let organizationIds: string[] = [];

        if (user) {
            organizationIds = user.organizationTeamsMemberships.map((otm) => otm.organizationId!) || [];
        } else if (params.get('organizationIds')) {
            organizationIds = params.get('organizationIds')?.split(',') || [];
        }

        return {
            userId,
            sportId: sportId ? +sportId : undefined,
            familyId: familyId ? +familyId : undefined,
            organizationIds,
        };
    };

    getPlayerSport = (userSports: UserSport[], sport?: Sport): UserSport => {
        let userSport: UserSport;
        if (sport && userSports.some((us) => us.sportId === sport.id)) {
            userSport = userSports.filter((us) => us.sportId === sport.id)[0];
        } else if (userSports.some((us) => us.primarySport)) {
            userSport = userSports.filter((us) => us.primarySport)[0];
        } else {
            userSport = userSports[0];
        }

        return userSport;
    };

    getPlayerData = (user: User, sport: Sport): PlayerData => {
        const userOrganizations =
            user && user.organizationTeamsMemberships && user.organizationTeamsMemberships.length > 0
                ? user.organizationTeamsMemberships
                : [];

        const userOrganizationsBySport =
            userOrganizations.length > 0 ? userOrganizations.filter((o) => o.sportId === sport?.id) : [];

        const organization =
            userOrganizationsBySport.length > 0 ? userOrganizationsBySport[0].organizationId : undefined;

        const team =
            userOrganizationsBySport.length > 0 &&
            userOrganizationsBySport[0].teams &&
            userOrganizationsBySport[0].teams.length > 0
                ? userOrganizationsBySport[0].teams[0].name
                : undefined;

        return { userOrganizationsBySport, organization, team };
    };

    /**
     * Returns if a player is visible for the current user based in her family and organizations.
     * @param auth - the current user
     * @param familyId - the family id of the player
     * @param organizationIds - the organization ids of the player
     * @returns if a player is visible for the current user
     */
    isPlayerVisible = (auth?: CustomAuth, familyId?: number, organizationIds?: string[]): boolean => {
        let visible: boolean = false;

        // if current user is admin, player is visible
        if (auth && auth.authorities.includes('ROLE_ADMIN')) {
            visible = true;
        }
        // if current user is college coach, player is visible
        else if (auth && auth.authorities.includes('ROLE_COLLEGE_COACH')) {
            visible = true;
        }
        // if current user is parent and player belongs to her family, player is visible
        else if (auth && auth.authorities.includes('ROLE_PARENT') && auth.familyId && auth.familyId === familyId) {
            visible = true;
        }
        // if current user is affiliate and player belongs to her organization, player is visible
        else if (
            auth &&
            auth.authorities.includes('ROLE_ORGANIZATION') &&
            auth.organizationId &&
            organizationIds?.includes(auth.organizationId)
        ) {
            visible = true;
        }
        // if current user is player and player belongs to any of her organizations, player is visible
        else if (
            auth &&
            auth.authorities.includes('ROLE_PLAYER') &&
            auth.organizationIds &&
            organizationIds?.some((id) => auth.organizationIds?.includes(id))
        ) {
            visible = true;
        }
        // if current user is player and player belongs to her family, player is visible
        else if (auth && auth.authorities.includes('ROLE_PLAYER') && auth.familyId && auth.familyId === familyId) {
            visible = true;
        }

        return visible;
    };

    /**
     * Returns if the player is editable for the current user.
     * @param user  - the user
     * @param auth - the current user
     * @param seasons - the seasons
     * @param seasonId - the season id
     */
    isEditable = (user: User, auth?: CustomAuth, seasons?: Season[], seasonId?: number) => {
        let editable = false;

        // admin
        if (auth && auth.authorities.includes('ROLE_ADMIN')) {
            editable = true;
        }
        // organization owner and staff
        else if (
            auth &&
            rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_OWNER', 'ROLE_ORGANIZATION_STAFF']) &&
            user?.organizationTeamsMemberships.some((otm) => otm.organizationId === auth.organizationId)
        ) {
            editable = true;
        }

        // organization coach
        else if (
            auth &&
            rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_COACH']) &&
            user?.organizationTeamsMemberships.some((otm) => otm.organizationId === auth.organizationId) &&
            auth.coaches
                ?.find(Boolean)
                ?.teams?.some((t) =>
                    user.organizationTeamsMemberships.find((om) => om.teams?.find((team) => team.id === t.teamId)),
                )
        ) {
            editable = true;
        }
        // player
        else if (auth && auth.authorities.includes('ROLE_PLAYER') && auth.id === user.id) {
            editable = true;
        }
        // parent
        else if (auth && auth.authorities.includes('ROLE_PARENT') && auth.familyId === user.familyId) {
            editable = true;
        }

        // verify season
        if (seasons && !seasons.some((s) => s.id === seasonId && s.isActive)) {
            editable = false;
        }

        return editable;
    };
}

const playerService: PlayerService = new PlayerService();
export default playerService;

type ParamsType = { id: string };
interface PlayerProps {
    userId: string;
    sportId?: number;
    familyId?: number;
    organizationIds: string[];
}
interface PlayerResponse {
    user: User;
    sport: Sport;
    userSport: UserSport;
    userSports: UserSport[];
}

interface PlayerData {
    userOrganizationsBySport: UserOrganizationMember[];
    organization?: string;
    team?: string;
}
