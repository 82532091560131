import { message } from 'antd';
import { IntlShape } from 'react-intl';

class NotificationService {
    displayError = (error: any, intl: IntlShape, errorMessages: ErrorMessage[] = []) => {
        const status = error.response && error.response.status;
        const errorMessage = status && errorMessages.find((m) => m.status === status);
        if (errorMessage) {
            message.error(intl.formatMessage({ id: errorMessage.message }), errorMessage.duration || 6);
        } else if (status === 403 || status === 401) {
            message.error(intl.formatMessage({ id: 'status.forbiddenError' }), 6);
        } else if (status === 404) {
            message.error(intl.formatMessage({ id: 'status.notFoundError' }), 6);
        } else {
            message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
        }
    };
}

export interface ErrorMessage {
    status: number;
    message: string;
    duration?: number;
}

const notificationService = new NotificationService();
export default notificationService;
