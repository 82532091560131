import { Button, Col, Form, message, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import eventApi from '../../../apis/EventApi';
import notificationApi from '../../../apis/NotificationApi';
import organizationSportsApi from '../../../apis/OrganizationSportsApi';
import sportApi from '../../../apis/SportApi';
import CustomContext from '../../../context/CustomContext';
import { Event, EventNotification, OrganizationNotification, Role, Sport } from '../../../model/Entities';
import communicationService from '../../../services/CommunicationService';
import notificationService from '../../../services/NotificationService';
import stringService from '../../../services/StringService';
import SummaryModal from '../Components/SummaryModal/SummaryModal';
import DivisionTeamsFormList from '../Components/DivisionTeamsFormList/DivisionTeamsFormList';
import RecipientsAndContentFormItems from '../Components/RecipientsAndContentFormItems/RecipientsAndContentFormItems';
import styles from './OrganizationOwnerNotificationComponent.module.scss';

/**
 * Returns the Organization Owner notification component.
 * @returns the Organization Owner notification component.
 */
const OrganizationOwnerNotificationComponent: React.FC = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const context = useContext(CustomContext);
    const { auth } = context;
    const organizationId = auth ? auth.organizationId : undefined;
    const [form] = Form.useForm();
    const [subscriber, setSubscriber] = useState<SubscriberOwnerType>();
    const [sports, setSports] = useState<Sport[]>([]);
    const [sportIds, setSportIds] = useState<number[]>([]);
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState<'loading'>();
    const [roles, setRoles] = useState<Role[]>([]);
    const [summaryModalVisible, setSummaryModalVisible] = useState<boolean>();

    /*** EFFECTS ***/

    // init
    useEffect(() => {
        const init = async () => {
            try {
                setSubscriber('OrganizationMembers');

                // initialize form
                form.setFieldsValue({ subscriber: 'OrganizationMembers', app: true });
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [intl, form]);

    // load sports
    useEffect(() => {
        const loadSports = async () => {
            try {
                if (organizationId && subscriber === 'OrganizationMembers') {
                    // load sports
                    const responses = await Promise.all([
                        sportApi.listAll(),
                        organizationSportsApi.listAll(organizationId),
                    ]);
                    const allSports = responses[0];
                    const organizationSports = responses[1];
                    const sports = allSports.filter((s) => organizationSports.map((a) => a.sportId).includes(s.id));
                    setSports(sports);

                    // initialize form
                    form.resetFields(['sportIds', 'divisions', 'roles', 'event']);
                    if (sports.length === 1) {
                        const sportIds = [sports[0].id as number];
                        form.setFieldsValue({ sportIds });
                        setSportIds(sportIds);
                    } else {
                        setSportIds([]);
                    }
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        loadSports();
    }, [form, intl, organizationId, subscriber]);

    // load events
    useEffect(() => {
        const loadEvents = async () => {
            try {
                if (organizationId && subscriber === 'Events') {
                    const eventsPage = await eventApi.list(
                        1000,
                        1,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        organizationId,
                    );
                    setEvents(eventsPage.items);
                    form.resetFields(['sportIds', 'divisions', 'roles', 'event']);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        loadEvents();
    }, [form, intl, organizationId, subscriber]);

    /*** METHODS ***/

    const save = async () => {
        try {
            setLoading('loading');
            const values = await form.validateFields();

            if (organizationId && values.subscriber === 'OrganizationMembers') {
                const notification: OrganizationNotification = {
                    roles: { roleIds: [] },
                    divisions: { division: [] },
                    sports: { sportIds: [] },
                    organizations: { organizationIds: [organizationId], all: false },
                };

                // set sports
                if (values.sportIds && values.sportIds.length > 0) {
                    notification.sports.all = false;
                    notification.sports.sportIds = values.sportIds;
                } else {
                    notification.sports.all = true;
                }

                // set division teams
                if (values.divisions && values.divisions.length > 0) {
                    notification.divisions.all = false;
                    notification.divisions.division = values.divisions.map((d: any) => ({
                        divisionId: d.divisionId,
                        teams: {
                            all: !d.teamIds || d.teamIds.length === 0,
                            teamIds: d.teamIds,
                        },
                    }));
                } else {
                    notification.divisions.all = true;
                }

                // set recipients and content
                communicationService.loadNotificationRoles(notification, values);
                communicationService.loadNotificationRecipientsAndContent(notification, values);

                await notificationApi.createOrganizationNotification(notification);
            } else {
                const notification: EventNotification = {
                    roles: { roleIds: [] },
                    eventDivisions: { eventDivisionIds: [] },
                };

                // event
                const eventId = values.event;

                // set division (always all)
                notification.eventDivisions.all = true;

                // set recipients and content
                communicationService.loadNotificationRoles(notification, values);
                communicationService.loadNotificationRecipientsAndContent(notification, values);

                await notificationApi.createEventNotification(eventId, notification);
            }

            // Success messages
            if (form.getFieldValue('scheduleSending')) {
                message.success(intl.formatMessage({ id: 'notifications.scheduled' }));
            } else {
                message.success(intl.formatMessage({ id: 'notifications.sent' }));
            }

            // reset form
            form.resetFields();
            form.setFieldsValue({ subscriber: 'OrganizationMembers', app: true });
            if (sports.length === 1) {
                const sportIds = sports[0].id as number;
                form.setFieldsValue({ sportIds: [sportIds] });
            }

            setSummaryModalVisible(false);
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, intl);
            }
        } finally {
            setLoading(undefined);
        }
    };

    const changeType = async (subscriber: SubscriberOwnerType) => {
        setSubscriber(subscriber);
        setSportIds([]);
    };

    /*** COMPONENTS ***/

    const subscriberOptions = subscribers.map((subscriber) => (
        <Select.Option key={subscriber} value={subscriber}>
            <FormattedMessage id={`ownerNotifications.subscribers.${subscriber}`} />
        </Select.Option>
    ));
    const sportOptions = sports.map((s) => (
        <Select.Option key={s.id} value={s.id!}>
            {s.name}
        </Select.Option>
    ));
    const eventsOptions = events.map((event) => (
        <Select.Option key={event.id} value={event.id!}>
            {event.name}
        </Select.Option>
    ));

    return (
        <Form
            form={form}
            onFinish={() => setSummaryModalVisible(true)}
            colon={false}
            layout="vertical"
            className={styles.form}
        >
            <h2>
                <FormattedMessage id="notifications.subscribers" />
            </h2>
            <Row gutter={[28, 0]}>
                <Col xs={24} sm={18} md={12}>
                    <Form.Item label={<FormattedMessage id="notifications.type" />} name="subscriber">
                        <Select className={styles.select} onChange={(value: SubscriberOwnerType) => changeType(value)}>
                            {subscriberOptions}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {subscriber === 'OrganizationMembers' && (
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={18} md={12}>
                        <Form.Item label={<FormattedMessage id="notifications.sport" />} name="sportIds">
                            <Select
                                className={`placeholder-select ${styles.multioption}`}
                                mode="multiple"
                                placeholder={<FormattedMessage id="notifications.all" />}
                                onChange={(value: number[]) => setSportIds(value)}
                                disabled={!sports || sports.length === 0}
                            >
                                {sportOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {subscriber === 'OrganizationMembers' && sportIds.length === 1 && organizationId && (
                <Row gutter={[28, 0]}>
                    <Col xs={24} span={24}>
                        <DivisionTeamsFormList
                            form={form}
                            sportId={sportIds[0]}
                            organizationId={organizationId}
                            minimum={0}
                        />
                    </Col>
                </Row>
            )}
            {subscriber === 'Events' && (
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={18} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="notifications.event" />}
                            name="event"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                className={styles.select}
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="notifications.selectEvent" />}
                                disabled={!events || events.length === 0}
                            >
                                {eventsOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            )}
            <RecipientsAndContentFormItems form={form} allowedRoleIds={allowedRoles} updateRoles={setRoles} />

            <Row gutter={[28, 0]} align="middle">
                <Col xs={24} md={24} className={styles.buttons}>
                    <Button type="primary" htmlType="submit" size="large">
                        <FormattedMessage id="button.send" tagName="span" />
                    </Button>
                </Col>
            </Row>

            {summaryModalVisible && (
                <SummaryModal
                    modalType="Owner"
                    loading={loading}
                    save={save}
                    subscriber={subscriber}
                    sportIds={sportIds}
                    organizationIds={[organizationId!]}
                    eventId={form.getFieldValue('event')}
                    roles={roles}
                    roleIds={form.getFieldValue('roles')}
                    divisionIds={
                        form.getFieldValue('divisions')
                            ? form.getFieldValue('divisions').flatMap((d: any) => d.divisionId)
                            : []
                    }
                    teamIds={
                        form.getFieldValue('divisions')
                            ? form.getFieldValue('divisions').flatMap((d: any) => d.teamIds)
                            : []
                    }
                    email={form.getFieldValue('email')}
                    scheduleSending={form.getFieldValue('scheduleSending')}
                    subject={form.getFieldValue('subject')}
                    body={form.getFieldValue('body')}
                    sendTime={form.getFieldValue('sendTime')}
                    timeZone={form.getFieldValue('timeZone')}
                    onUpdate={setSummaryModalVisible}
                />
            )}
        </Form>
    );
};
export default OrganizationOwnerNotificationComponent;

export const subscribers = ['OrganizationMembers', 'Events'] as const;
export type SubscriberOwnerType = typeof subscribers[number];

// List of allowed roles for Organization Owner
const allowedRoles = [1, 2, 3, 4, 7];
