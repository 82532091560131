import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Menu, message, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import collegeCoachApi from '../../../../../apis/CollegeCoachApi';
import { College, CollegeCoach, Sport } from '../../../../../model/Entities';
import notificationService from '../../../../../services/NotificationService';
import stringService from '../../../../../services/StringService';
import CollegeCoachModal from '../../../../CollegeCoachProfilePage/CollegeCoachModal/CollegeCoachModal';
import styles from './CollegeCoachesComponent.module.scss';

class CollegeCoachesComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { collegeCoaches: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { college } = this.props;

        try {
            this.setState({ loading: this.props.loading });

            const collegeCoaches = await collegeCoachApi.list(college.id!);

            const allCoachesSports = collegeCoaches.length > 0 ? collegeCoaches.map((c) => c.sportId!) : [];
            const coachesSports = allCoachesSports.filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
            });

            this.props.update(coachesSports);

            this.setState({ collegeCoaches });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    getSportName = (sportId: number): string | undefined => {
        const { sports } = this.props;
        return sports.find((s) => s.id === sportId)?.name;
    };

    showModal = (modalVisible: boolean, collegeCoach?: CollegeCoach) => {
        this.setState({ modalVisible, collegeCoach });
    };

    list = async () => {
        const { college } = this.props;
        try {
            this.setState({ loading: 'loading' });
            const collegeCoaches = await collegeCoachApi.list(college.id!);
            this.showModal(false);

            // We update coaches sports
            const allCoachesSports = collegeCoaches.length > 0 ? collegeCoaches.map((c) => c.sportId!) : [];
            const coachesSports = allCoachesSports.filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
            });

            this.props.update(coachesSports);
            this.props.updateCollege();

            this.setState({ collegeCoaches });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteCoach = async () => {
        const { college } = this.props;
        const { collegeCoach } = this.state;
        try {
            this.setState({ loading: 'deleting' });

            await collegeCoachApi.delete(college.id!, collegeCoach?.id!);
            message.success(this.props.intl.formatMessage({ id: 'status.deleted' }));
            this.showDeleteCoachModal(false);

            const collegeCoaches = await collegeCoachApi.list(college.id!);

            // We update coaches sports
            const allCoachesSports = collegeCoaches.length > 0 ? collegeCoaches.map((c) => c.sportId!) : [];
            const coachesSports = allCoachesSports.filter(function (item, pos, self) {
                return self.indexOf(item) === pos;
            });

            this.props.update(coachesSports);

            this.setState({ collegeCoaches });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showDeleteCoachModal = (deleteCoachModalVisible: boolean, collegeCoach?: CollegeCoach) => {
        this.setState({ deleteCoachModalVisible, collegeCoach });
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { collegeCoaches, loading } = this.state;
        const columns: ColumnsType<CollegeCoach> = [
            {
                title: <FormattedMessage id="college.coaches.name" />,
                dataIndex: 'givenName',
                key: 'givenName',
                render: (value: string, collegeCoach: CollegeCoach) => (
                    <span className={styles.link} onClick={() => this.showModal(true, collegeCoach)}>
                        {stringService.getName(true, value, collegeCoach.familyName)}
                    </span>
                ),
            },
            {
                title: <FormattedMessage id="college.coaches.sport" />,
                dataIndex: 'sportId',
                key: 'sportId',
                render: (value: number, collegeCoach: CollegeCoach) => (
                    <span className={styles.link} onClick={() => this.showModal(true, collegeCoach)}>
                        {this.getSportName(value)}
                    </span>
                ),
            },
            {
                title: <FormattedMessage id="college.coaches.email" />,
                dataIndex: 'email',
                key: 'email',
                render: (value: string, collegeCoach: CollegeCoach) => (
                    <span className={styles.link} onClick={() => this.showModal(true, collegeCoach)}>
                        {value}
                    </span>
                ),
            },
            {
                title: <FormattedMessage id="college.coaches.username" />,
                dataIndex: 'userName',
                key: 'userName',
                render: (value: string, collegeCoach: CollegeCoach) => (
                    <span className={styles.link} onClick={() => this.showModal(true, collegeCoach)}>
                        {value}
                    </span>
                ),
            },
            {
                title: <FormattedMessage id="parent.actions" />,
                width: 90,
                render: (value: string, collegeCoach: CollegeCoach) => (
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="delete" onClick={() => this.showDeleteCoachModal(true, collegeCoach)}>
                                    <FormattedMessage id="parent.profile.delete" />
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type="text" className={styles.actions}>
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                ),
            },
        ];

        return (
            <Table
                dataSource={collegeCoaches}
                columns={columns}
                pagination={false}
                rowKey="id"
                onChange={this.list}
                loading={loading === 'initializing'}
            />
        );
    };

    renderModal = (): React.ReactElement | undefined => {
        const { college, sports } = this.props;
        const { modalVisible, collegeCoach } = this.state;

        if (modalVisible) {
            return (
                <CollegeCoachModal
                    onCancel={() => this.showModal(false)}
                    onUpdate={this.list}
                    collegeId={college.id!}
                    coachId={collegeCoach?.id}
                    sports={sports}
                />
            );
        }
    };

    renderDeleteCoachModal = (): React.ReactElement | undefined => {
        const { deleteCoachModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="collegeCoachProfile.deleteModal.title" />}
                visible={deleteCoachModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.deleteCoach}
                okButtonProps={{ loading: loading === 'deleting' }}
                onCancel={() => this.showDeleteCoachModal(false)}
                className={styles.modal}
            >
                <FormattedMessage id="collegeCoachProfile.deleteModal.description" />
            </Modal>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { college } = this.props;

        if (college?.id) {
            return (
                <>
                    <Divider></Divider>
                    <div className={styles.flex}>
                        <h2>
                            <FormattedMessage id="college.coaches" />
                        </h2>
                        <Button icon={<PlusOutlined />} onClick={() => this.showModal(true)}>
                            <FormattedMessage id="college.coaches.add" />
                        </Button>
                    </div>
                    <div className={styles.table}>{this.renderTable()}</div>
                    {this.renderDeleteCoachModal()}
                </>
            );
        }
    };

    render() {
        return (
            <>
                {this.renderContent()}
                {this.renderModal()}
            </>
        );
    }
}
export default injectIntl(CollegeCoachesComponent);

interface Props extends WrappedComponentProps {
    college: College;
    sports: Sport[];
    update: (sports: number[]) => void;
    updateCollege: () => void;
    loading?: 'initializing' | 'saving' | 'deleting' | 'loading';
}

interface State {
    loading?: 'initializing' | 'saving' | 'deleting' | 'loading';
    modalVisible?: boolean;
    collegeCoach?: CollegeCoach;
    collegeCoaches: CollegeCoach[];
    deleteCoachModalVisible?: boolean;
}
