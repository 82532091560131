import { Button, Col, Form, message, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import notificationApi from '../../../apis/NotificationApi';
import CustomContext from '../../../context/CustomContext';
import { OrganizationNotification, Role } from '../../../model/Entities';
import communicationService from '../../../services/CommunicationService';
import notificationService from '../../../services/NotificationService';
import SummaryModal from '../Components/SummaryModal/SummaryModal';
import DivisionTeamsFormList from '../Components/DivisionTeamsFormList/DivisionTeamsFormList';
import RecipientsAndContentFormItems from '../Components/RecipientsAndContentFormItems/RecipientsAndContentFormItems';
import styles from './OrganizationCoachNotificationComponent.module.scss';

/**
 * Returns the Organization Coach notification component.
 * @returns the Organization Coach notification component.
 */
const OrganizationCoachNotificationComponent: React.FunctionComponent = (): React.ReactElement => {
    /*** HOOKS ***/
    const intl = useIntl();
    const [form] = Form.useForm();
    const context = useContext(CustomContext);
    const { auth } = context;
    const organizationId = auth ? auth.organizationId : undefined;
    const sportId = auth && auth.coaches && auth.coaches.length > 0 ? auth.coaches[0].sportId : undefined;
    const minimumDivisions = 1;
    const [loading, setLoading] = useState<'loading'>();
    const [roles, setRoles] = useState<Role[]>([]);
    const [summaryModalVisible, setSummaryModalVisible] = useState<boolean>();

    /*** EFFECTS ***/

    // init
    useEffect(() => {
        const init = async () => {
            try {
                // initialize form
                form.setFieldsValue({ app: true });
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [intl, form]);

    /*** METHODS ***/

    const save = async () => {
        try {
            setLoading('loading');
            const values = await form.validateFields();

            if (organizationId && sportId) {
                const notification: OrganizationNotification = {
                    roles: { roleIds: [] },
                    divisions: { division: [] },
                    sports: { sportIds: [sportId], all: false },
                    organizations: { organizationIds: [organizationId], all: false },
                };

                // set division teams
                if (values.divisions && values.divisions.length > 0) {
                    notification.divisions.all = false;
                    notification.divisions.division = values.divisions.map((d: any) => ({
                        divisionId: d.divisionId,
                        teams: {
                            all: !d.teamIds || d.teamIds.length === 0,
                            teamIds: d.teamIds,
                        },
                    }));
                } else {
                    notification.divisions.all = true;
                }

                // set recipients and content
                communicationService.loadNotificationRoles(notification, values);
                communicationService.loadNotificationRecipientsAndContent(notification, values);

                // save notification
                await notificationApi.createOrganizationNotification(notification);

                // Success messages
                if (form.getFieldValue('scheduleSending')) {
                    message.success(intl.formatMessage({ id: 'notifications.scheduled' }));
                } else {
                    message.success(intl.formatMessage({ id: 'notifications.sent' }));
                }

                // reset form
                form.resetFields();
                form.setFieldsValue({ app: true, divisions: Array(minimumDivisions).fill({}) });
            }

            setSummaryModalVisible(false);
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, intl);
            }
        } finally {
            setLoading(undefined);
        }
    };

    /*** COMPONENTS ***/

    return (
        <Form
            form={form}
            onFinish={() => setSummaryModalVisible(true)}
            colon={false}
            layout="vertical"
            className={styles.form}
        >
            <h2>
                <FormattedMessage id="notifications.subscribers" />
            </h2>
            {sportId && organizationId && (
                <Row gutter={[28, 0]}>
                    <Col xs={24} span={24}>
                        <DivisionTeamsFormList
                            form={form}
                            sportId={sportId}
                            organizationId={organizationId}
                            minimum={minimumDivisions}
                        />
                    </Col>
                </Row>
            )}
            <RecipientsAndContentFormItems form={form} allowedRoleIds={allowedRoles} updateRoles={setRoles} />

            <Row gutter={[28, 0]} align="middle">
                <Col xs={24} md={24} className={styles.buttons}>
                    <Button type="primary" htmlType="submit" size="large">
                        <FormattedMessage id="button.send" tagName="span" />
                    </Button>
                </Col>
            </Row>

            {summaryModalVisible && (
                <SummaryModal
                    modalType="Coach"
                    loading={loading}
                    save={save}
                    roles={roles}
                    roleIds={form.getFieldValue('roles')}
                    sportIds={[sportId!]}
                    organizationIds={[organizationId!]}
                    divisionIds={
                        form.getFieldValue('divisions')
                            ? form.getFieldValue('divisions').flatMap((d: any) => d.divisionId)
                            : []
                    }
                    teamIds={
                        form.getFieldValue('divisions')
                            ? form.getFieldValue('divisions').flatMap((d: any) => d.teamIds)
                            : []
                    }
                    email={form.getFieldValue('email')}
                    scheduleSending={form.getFieldValue('scheduleSending')}
                    subject={form.getFieldValue('subject')}
                    body={form.getFieldValue('body')}
                    sendTime={form.getFieldValue('sendTime')}
                    timeZone={form.getFieldValue('timeZone')}
                    onUpdate={setSummaryModalVisible}
                />
            )}
        </Form>
    );
};
export default OrganizationCoachNotificationComponent;

// List of allowed roles for Organization Coach
const allowedRoles = [1, 2, 4, 7];
