import { CollegeDivision } from '../model/Entities';
import apiAxios from './CustomAxios';

class CollegeDivisionApi {
    list = async (): Promise<CollegeDivision[]> => {
        const response = await apiAxios.get<CollegeDivision[]>('/v1/colleges/divisions');

        return response.data;
    };
}
const collegeDivisionApi: CollegeDivisionApi = new CollegeDivisionApi();
export default collegeDivisionApi;
