import React, { Component } from 'react';
import styles from './ForgotPasswordPage.module.scss';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../context/CustomContext';
import authService from '../../services/AuthService';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import HeadMetadata from '../../services/HeadMetadata';
import ga from '../../services/GoogleAnalytics';

class ForgotPasswordPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    forgotPassword = async (values: any): Promise<void> => {
        const { intl } = this.props;

        try {
            this.setState({ loading: true });
            ga.createDefaultEvent('forgot password', 'forgot password - password forgot');
            await authService.forgotPassword(values.username);
            this.setState({ codeSent: true });
        } catch (error: any) {
            if (error.message) {
                message.error(error.message, 6);
                ga.createDefaultEvent('forgot password', `forgot password - ${error.message}`);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
                ga.createDefaultEvent('forgot password', 'forgot password - internal error');
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    resetPassword = async (values: any): Promise<void> => {
        const { updateAuth } = this.context;
        const { intl } = this.props;

        try {
            this.setState({ loading: true });
            ga.createDefaultEvent('forgot password', 'forgot password - reseting password');
            const auth = await authService.resetPassword(values.username, values.code, values.password);
            updateAuth(auth);
            ga.createDefaultEvent('forgot password', 'forgot password - reset password success');
            this.props.history.push('/');
        } catch (error: any) {
            if (error.message) {
                message.error(error.message, 6);
                ga.createDefaultEvent('forgot password', `forgot password - ${error.message}`);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
                ga.createDefaultEvent('forgot password', 'forgot password - reset password internal error');
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <div className={styles.container}>
                <img src={process.env.PUBLIC_URL + '/img/top100-logo.png'} alt="" />
                <div className={styles.panel}>
                    <div className={styles.shadow}></div>
                    <div className={styles.body}>{this.renderForm()}</div>
                </div>
            </div>
        );
    };

    renderForm = (): React.ReactElement | undefined => {
        const { codeSent } = this.state;

        return (
            <>
                <Row>
                    <Col>
                        <h1>
                            <FormattedMessage id="forgotPassword.title" />
                        </h1>
                        <p>
                            <FormattedMessage id={codeSent ? 'forgotPassword.desc2' : 'forgotPassword.desc1'} />
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>{codeSent ? this.renderResetPasswordForm() : this.renderForgotPasswordForm()}</Col>
                </Row>
                <Row>
                    <Col>
                        <Link to="/signin">
                            <Button type="link" className={styles.password}>
                                <FormattedMessage id="forgotPassword.signIn" />
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </>
        );
    };

    renderForgotPasswordForm = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        return (
            <Form ref={this.formRef} onFinish={this.forgotPassword} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="forgotPassword.username" />}
                    name="username"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    required={false}
                >
                    <Input maxLength={100} size="large" />
                </Form.Item>
                <Form.Item className={styles.buttons}>
                    <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                        <FormattedMessage id="forgotPassword.continue" tagName="span" />
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    renderResetPasswordForm = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        return (
            <Form ref={this.formRef} onFinish={this.resetPassword} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="forgotPassword.username" />}
                    name="username"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    required={false}
                >
                    <Input maxLength={100} size="large" />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="forgotPassword.code" />}
                    name="code"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    required={false}
                >
                    <Input maxLength={50} size="large" />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id="forgotPassword.password" />}
                    name="password"
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    required={false}
                >
                    <Input type="password" maxLength={25} size="large" />
                </Form.Item>
                <Form.Item className={styles.buttons}>
                    <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                        <FormattedMessage id="forgotPassword.continue" tagName="span" />
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="forgotPassword.meta.title" />
                {this.renderContent()}
            </>
        );
    }
}
export default injectIntl(ForgotPasswordPage);

interface Props extends WrappedComponentProps, RouteComponentProps {}

interface State {
    codeSent?: boolean;
    loading?: boolean;
}
