import { ImportedPlayers } from '../model/Entities';
import apiAxios from './CustomAxios';

class ImportCSVApi {
    import = async (organizationId: string, sportId: number, file: any): Promise<ImportedPlayers> => {
        const formData: FormData = new FormData();
        file && formData.append('file', file);
        const response = await apiAxios.post<ImportedPlayers>(
            `/v1/organizations/${organizationId}/sports/${sportId}/import`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );

        return response.data;
    };
}

const importCSVApi: ImportCSVApi = new ImportCSVApi();
export default importCSVApi;
