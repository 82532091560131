import { SystemHealth } from '../model/Entities';
import apiAxios from './CustomAxios';

class ToolApi {
    getQrCode = async (data: string): Promise<string> => {
        const response = await apiAxios.get(`/v1/tools/qrcode`, {
            params: { data },

            responseType: 'arraybuffer',
        });

        return 'data:image/png;base64,' + Buffer.from(response.data, 'binary').toString('base64');
    };

    getHealth = async (): Promise<SystemHealth> => {
        const response = await apiAxios.get<SystemHealth>(`/v1/tools/health`);
        return response.data;
    };
}

const toolApi: ToolApi = new ToolApi();
export default toolApi;
