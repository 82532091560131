import { notification } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import toolApi from '../../apis/ToolApi';
import CustomContext from '../../context/CustomContext';
import { CustomAuth } from '../../model/Entities';

/**
 * This component represents the systems health. It verifies and notify admin users of the system status (e.g. quickbooks errors).
 *
 */
const SystemHealthComponent: React.FC = () => {
    const context = useContext(CustomContext);
    const { auth } = context;
    const quickbooksUrl = process.env.REACT_APP_QUICKBOOKS_URL || '#';

    const intl = useIntl();

    const check = React.useCallback(
        async (auth?: CustomAuth) => {
            try {
                if (auth?.authorities.includes('ROLE_ADMIN')) {
                    const systemHealth = await toolApi.getHealth();

                    notification.close('systemHealth');

                    switch (systemHealth.paymentService.state) {
                        case 'TokenNotSetUp':
                            notification.error({
                                key: 'systemHealth',
                                message: intl.formatMessage({ id: 'systemHealth.paymentService.alert' }),
                                description: intl.formatMessage(
                                    { id: 'systemHealth.paymentService.tokenNotSetUp' },
                                    {
                                        link: (
                                            <a href={quickbooksUrl} target="_blank" rel="noreferrer">
                                                {intl.formatMessage({ id: 'button.link' })}
                                            </a>
                                        ),
                                    },
                                ),
                                duration: 0,
                            });
                            break;
                        case 'TokenExpired':
                            notification.error({
                                key: 'systemHealth',
                                message: intl.formatMessage({ id: 'systemHealth.paymentService.alert' }),
                                description: intl.formatMessage(
                                    { id: 'systemHealth.paymentService.tokenExpired' },
                                    {
                                        link: (
                                            <a href={quickbooksUrl} target="_blank" rel="noreferrer">
                                                {intl.formatMessage({ id: 'button.link' })}
                                            </a>
                                        ),
                                    },
                                ),
                                duration: 0,
                            });
                            break;
                        case 'ServiceUnavailable':
                            notification.error({
                                key: 'systemHealth',
                                message: intl.formatMessage({ id: 'systemHealth.paymentService.alert' }),
                                description: intl.formatMessage(
                                    { id: 'systemHealth.paymentService.serviceUnavailable' },
                                    {
                                        link: (
                                            <a href={quickbooksUrl} target="_blank" rel="noreferrer">
                                                {intl.formatMessage({ id: 'button.link' })}
                                            </a>
                                        ),
                                    },
                                ),
                                duration: 0,
                            });

                            break;
                        default:
                            break;
                    }
                }
            } catch {
                // do nothing
            }
        },
        [intl, quickbooksUrl],
    );

    useEffect(() => {
        check(auth);

        const interval = setInterval(() => check(auth), 900000);
        return () => {
            interval && clearInterval(interval);
        };
    }, [auth, check]);

    return <></>;
};
export default SystemHealthComponent;
