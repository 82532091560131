import Icon from '@ant-design/icons';
import { Button, Form, FormInstance, Input, List, message, Modal, PageHeader, Tabs } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import seasonApi from '../../apis/SeasonApi';
import sportApi from '../../apis/SportApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Page } from '../../model/Elements';
import { Season, Sport } from '../../model/Entities';
import { ReactComponent as leaderboardSvg } from '../../resources/images/ico-leaderboard.svg';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import styles from './SeasonsPage.module.scss';

class SeasonsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();
    readonly pageSize = 20;

    constructor(props: Props) {
        super(props);
        this.state = { seasons: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const sports = await sportApi.listAll();
            const seasonsPage = await seasonApi.list(this.pageSize, 1, 2);
            const seasons = seasonsPage && seasonsPage.items ? seasonsPage?.items : [];
            const sportId = 2;
            const modalStep = 1;

            this.setState({
                sports,
                seasonsPage,
                seasons,
                sportId,
                modalStep,
            });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    list = async () => {
        const { seasonsPage, seasons, sportId, searchText } = this.state;
        try {
            this.setState({ loading: 'loading' });
            const page = seasonsPage?.currentPage! + 1;
            const seasonsPageAux = await seasonApi.list(
                this.pageSize,
                page,
                sportId!,
                undefined,
                undefined,
                searchText,
            );

            const seasonsAux = seasonsPageAux ? seasons.concat(seasonsPageAux.items) : [];

            this.setState({ seasonsPage: seasonsPageAux, seasons: seasonsAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showCompleteSeasonModal = (completeSeasonModalVisible: boolean) => {
        this.setState({ completeSeasonModalVisible });
    };

    filterBySport = async (sportId: number) => {
        try {
            this.setState({ loading: 'loading' });
            const seasonsPage = await seasonApi.list(this.pageSize, 1, sportId);
            const seasons = seasonsPage && seasonsPage.items ? seasonsPage?.items : [];
            this.setState({ seasonsPage, seasons, sportId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    goToNextStep = async () => {
        try {
            const values = await this.formRef.current?.validateFields();
            const modalStep = 2;
            const name = values.name;
            this.setState({ modalStep, name });
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    createNewSeason = async () => {
        const { sportId, name } = this.state;
        try {
            this.setState({ loading: 'saving' });
            const season: Season = Object.assign({}, { name: name });
            await seasonApi.create(sportId!, season);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            this.formRef.current?.resetFields();
            this.showCompleteSeasonModal(false);
            const modalStep = 1;
            const seasonsPage = await seasonApi.list(this.pageSize, 1, sportId!);
            const seasons = seasonsPage && seasonsPage.items ? seasonsPage?.items : [];
            const newName = undefined;
            this.setState({ seasonsPage, seasons, modalStep, name: newName });
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: 'seasons.status.duplicate' },
                ]);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    cancel = () => {
        const modalStep = 1;
        const name = undefined;
        this.formRef.current?.resetFields();
        this.showCompleteSeasonModal(false);
        this.setState({ modalStep, name });
    };

    back = () => {
        const modalStep = 1;
        this.setState({ modalStep });
    };

    search = async (searchText: string) => {
        const { sportId } = this.state;
        try {
            this.setState({ loading: 'searching' });
            const seasonsPage = await seasonApi.list(this.pageSize, 1, sportId!, undefined, undefined, searchText);
            const seasons = seasonsPage && seasonsPage.items ? seasonsPage?.items : [];
            this.setState({ seasonsPage, seasons, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        if (desktop) {
            return (
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                    </div>
                    <PageHeader
                        title={
                            <Search
                                placeholder={this.props.intl.formatMessage({ id: 'button.search' })}
                                className={styles.search}
                                enterButton={''}
                                allowClear={true}
                                onSearch={(value) => this.search(value)}
                            />
                        }
                    />
                </div>
            );
        } else {
            return <></>;
        }
    };

    renderLoadMore = (): React.ReactElement | undefined => {
        const { loading, seasonsPage, seasons } = this.state;
        return seasons.length < seasonsPage?.totalItems! ? (
            <div className={styles.loadMore}>
                <Button onClick={this.list} loading={loading === 'loading'}>
                    <FormattedMessage id="button.loadMore" tagName="span" />
                </Button>
            </div>
        ) : (
            <></>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        return (
            <>
                <h1 className={styles.seasonsHeader}>
                    <FormattedMessage id="seasons.title" />
                </h1>
                {this.renderList(desktop)} {this.renderCompleteSeasonModal()}
            </>
        );
    };

    renderSeasonButtons = (season: Season): React.ReactElement | undefined => {
        const { sportId } = this.state;
        return (
            <>
                <Link to={`/leaderboard?sportId=${sportId}&seasonId=${season.id}`}>
                    <Button
                        size="large"
                        shape="round"
                        className={styles.chartButton}
                        icon={<Icon component={leaderboardSvg} />}
                    ></Button>
                </Link>
                {season.isActive && (
                    <Button
                        size="large"
                        type="primary"
                        className={styles.complete}
                        shape="round"
                        onClick={() => this.showCompleteSeasonModal(true)}
                    >
                        <FormattedMessage id="seasons.button.complete" />
                    </Button>
                )}
            </>
        );
    };

    renderSportTabs = (desktop: boolean): React.ReactElement | undefined => {
        const { sports } = this.state;

        return (
            <Tabs
                defaultActiveKey="1"
                type="card"
                className={desktop ? styles.tabs : styles.tabsMobile}
                onChange={(sportId) => this.filterBySport(+sportId)}
            >
                {sports?.map((s) => (
                    <Tabs.TabPane
                        tab={<FormattedMessage id={s.name} />}
                        key={s.id}
                        className={styles.tab}
                        forceRender
                    ></Tabs.TabPane>
                ))}
            </Tabs>
        );
    };

    renderList = (desktop: boolean): React.ReactElement | undefined => {
        const { loading, sports, seasons, sportId } = this.state;

        return (
            <>
                {this.renderSportTabs(desktop)}
                <List
                    className={styles.seasonsList}
                    dataSource={seasons}
                    loadMore={this.renderLoadMore()}
                    renderItem={(season) => (
                        <List.Item key={season.id}>
                            <List.Item.Meta
                                className={styles.event}
                                title={
                                    <div className={styles.seasonTitle}>
                                        <div className={styles.sportName}>
                                            <p>{sports?.find((s) => s.id === sportId)?.name?.toUpperCase()}</p>
                                            <p>{season.name}</p>
                                        </div>
                                        <span className={styles.dates}>
                                            <span className={styles.date}>
                                                <span className={styles.dateTitle}>
                                                    <FormattedMessage id="seasons.startDate" />
                                                </span>{' '}
                                                <span>
                                                    <FormattedDate value={season.startDate}></FormattedDate>
                                                </span>
                                            </span>
                                            <span className={styles.date}>
                                                <span className={styles.dateTitle}>
                                                    <FormattedMessage id="seasons.endDate" />
                                                </span>{' '}
                                                <span>
                                                    {season.endDate ? (
                                                        <FormattedDate value={season.endDate}></FormattedDate>
                                                    ) : (
                                                        <FormattedMessage id="--" />
                                                    )}
                                                </span>
                                            </span>
                                        </span>
                                        <div className={styles.btns}>{this.renderSeasonButtons(season)}</div>
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                    loading={loading === 'initializing'}
                />
            </>
        );
    };

    renderCompleteSeasonModal = (): React.ReactElement | undefined => {
        const { completeSeasonModalVisible, loading, modalStep } = this.state;
        return (
            <Form ref={this.formRef} colon={false} layout="vertical">
                <Modal
                    title={
                        modalStep === 1 ? (
                            <FormattedMessage id="seasons.modal.title1" />
                        ) : (
                            <FormattedMessage id="seasons.modal.title2" />
                        )
                    }
                    footer={[
                        <Button size="large" onClick={modalStep === 1 ? this.cancel : this.back}>
                            {modalStep === 1 ? (
                                <FormattedMessage id="button.cancel" tagName="span" />
                            ) : (
                                <FormattedMessage id="button.back" tagName="span" />
                            )}
                        </Button>,
                        <Button
                            size="large"
                            type="primary"
                            loading={loading === 'saving'}
                            onClick={modalStep === 1 ? this.goToNextStep : this.createNewSeason}
                        >
                            {modalStep === 1 ? (
                                <FormattedMessage id="button.complete" tagName="span" />
                            ) : (
                                <FormattedMessage id="button.confirm" tagName="span" />
                            )}
                        </Button>,
                    ]}
                    visible={completeSeasonModalVisible}
                    onCancel={this.cancel}
                    width={400}
                    className={styles.modal}
                >
                    <p>
                        {modalStep === 1 ? (
                            <FormattedMessage id="seasons.modal.descriptionFirstStep" />
                        ) : (
                            <>
                                <FormattedMessage id="seasons.modal.descriptionSecondStep1" />{' '}
                                <b>{`'${this.formRef.current?.getFieldValue('name')}'`}</b>
                                <FormattedMessage id="seasons.modal.descriptionSecondStep2" />
                            </>
                        )}
                    </p>
                    {modalStep === 1 && (
                        <Form.Item
                            label={<FormattedMessage id="seasons.modal.label" />}
                            name="name"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    )}
                </Modal>
            </Form>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="seasons.meta.title" />
                <LayoutComponent page="seasons" content={this.renderContent} header={this.renderHeader} />
            </>
        );
    }
}
export default injectIntl(SeasonsPage);

interface Props extends WrappedComponentProps {}

interface State {
    sports?: Sport[];
    loading?: 'initializing' | 'loading' | 'saving' | 'searching';
    searchText?: string;
    seasonsPage?: Page<Season>;
    seasons: Season[];
    completeSeasonModalVisible?: boolean;
    sportId?: number;
    modalStep?: 1 | 2;
    name?: string;
}
