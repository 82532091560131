import { Button, Checkbox, Col, Collapse, Popconfirm, Row, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import configApi from '../../../../apis/ConfigApi';
import eventRegistrationApi from '../../../../apis/EventRegistrationApi';
import { Config, Event, EventChild, EventRegistrationInfo, Sport } from '../../../../model/Entities';
import { EventRegistrationType, PaymentProcessType } from '../../../../model/Types';
import notificationService from '../../../../services/NotificationService';
import stringService from '../../../../services/StringService';
import EventInfoComponent from '../../EventInfoComponent/EventInfoComponent';
import styles from './SignUpPaymentSelectionComponent.module.scss';

const { Panel } = Collapse;

class SignUpPaymentSelectionComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/
    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const config = await configApi.get();

            this.setState({ config });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    submitRegistration = async () => {
        const { event, eventChildren } = this.props;
        try {
            this.setState({ loading: 'submitting' });

            const registeredEventChildren = eventChildren.filter((ec) => ec.catching || ec.general || ec.pitching);
            const registration = await eventRegistrationApi.create(event?.id!, registeredEventChildren);
            this.props.changeDisplay('execution', registration);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    submitFreeRegistration = async () => {
        const { event, eventChildren } = this.props;
        try {
            this.setState({ loading: 'submitting' });

            const registeredEventChildren = eventChildren.filter((ec) => ec.catching || ec.general || ec.pitching);
            const registration = await eventRegistrationApi.create(event?.id!, registeredEventChildren);
            this.props.changeDisplay('success', registration);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    checkPrice = (child: EventChild, type: EventRegistrationType) => {
        const { eventChildren } = this.props;
        const eventChildrenAux: EventChild[] = [];
        if (type === 'Global') {
            eventChildren.find((c) => c.userId === child.userId && (c.general = c.general === true ? false : true));
        } else if (type === 'Catching') {
            eventChildren.find((c) => c.userId === child.userId && (c.catching = c.catching === true ? false : true));
        } else if (type === 'Pitching') {
            eventChildren.find((c) => c.userId === child.userId && (c.pitching = c.pitching === true ? false : true));
        }
        eventChildren.forEach((c) => eventChildrenAux.push(c));
        this.props.onUpdate(eventChildrenAux);
    };

    navigateToProfile = () => {
        this.props.history.push('/');
    };

    changeWaiverOne = () => {
        const waiverOne = !this.state.waiverOne;
        this.setState({ waiverOne });
    };

    changeWaiverTwo = () => {
        const waiverTwo = !this.state.waiverTwo;
        this.setState({ waiverTwo });
    };

    changeWaiverThree = () => {
        const waiverThree = !this.state.waiverThree;
        this.setState({ waiverThree });
    };

    isAnyPlayerSelected = () => {
        const { eventChildren } = this.props;
        const registeredEventChildren = eventChildren.filter((ec) => ec.catching || ec.general || ec.pitching);
        if (registeredEventChildren.length === 0) {
            return true;
        } else {
            return false;
        }
    };

    isTotalPriceZero = () => {
        const { eventChildren, generalPrice, catchingPrice, pitchingPrice } = this.props;
        const general = eventChildren.filter((c) => c.general).length * generalPrice!;
        const catching = catchingPrice ? eventChildren.filter((c) => c.catching).length * catchingPrice! : 0;
        const pitching = pitchingPrice ? eventChildren.filter((c) => c.pitching).length * pitchingPrice! : 0;
        const totalPrice = general + catching + pitching;
        if (totalPrice === 0) {
            return true;
        } else {
            return false;
        }
    };

    /*** COMPONENTS ***/
    renderForm = (): React.ReactElement | undefined => {
        const { waiverOne, waiverTwo, waiverThree } = this.state;
        return (
            <>
                <div className={styles.header}>
                    <h1>
                        <FormattedMessage id="signupPayment.registrationSummary" />
                    </h1>
                </div>

                <div className={styles.checkboxes}>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Collapse defaultActiveKey={['0']}>
                                <Panel
                                    className={styles.checkbox}
                                    header={
                                        <Checkbox onChange={this.changeWaiverOne} value={waiverOne}>
                                            {<FormattedMessage id="signupPayment.waiverRequirements" />}
                                        </Checkbox>
                                    }
                                    key="1"
                                >
                                    <p>
                                        <FormattedMessage id="signupPayment.waiverOneDescription" />
                                    </p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Collapse defaultActiveKey={['0']}>
                                <Panel
                                    className={styles.checkbox}
                                    header={
                                        <Checkbox onChange={this.changeWaiverTwo} value={waiverTwo}>
                                            {<FormattedMessage id="signupPayment.agreementStatementOne" />}
                                        </Checkbox>
                                    }
                                    key="1"
                                >
                                    <p>
                                        <FormattedMessage id="signupPayment.waiverTwoDescription" />
                                    </p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Collapse defaultActiveKey={['0']}>
                                <Panel
                                    className={styles.checkbox}
                                    header={
                                        <Checkbox onChange={this.changeWaiverThree} value={waiverThree}>
                                            {<FormattedMessage id="signupPayment.agreementStatementTwo" />}
                                        </Checkbox>
                                    }
                                    key="1"
                                >
                                    <p>
                                        <FormattedMessage id="signupPayment.waiverThreeDescription" />
                                    </p>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </div>
            </>
        );
    };

    renderEventColumn = (event?: Event): React.ReactElement | undefined => {
        const { sports } = this.props;
        return (
            <>
                <strong>
                    {event?.name} - {sports.find((s) => s.id === event?.sportId)!.name}
                </strong>
                <FormattedDate value={event?.startDate} weekday="short" />,{' '}
                <FormattedDate value={event?.startDate} month="short" />{' '}
                <FormattedDate value={event?.startDate} day="2-digit" />,{' '}
                <FormattedDate value={event?.startDate} year="numeric" />,{' '}
                <FormattedDate value={event?.startDate} hour="2-digit" minute="2-digit" />
            </>
        );
    };

    renderGeneralColumn = (child: EventChild): React.ReactElement | undefined => {
        const { generalPrice } = this.props;
        return (
            <>
                <strong>
                    <FormattedMessage id="$" />
                    {generalPrice}
                </strong>
                <Switch defaultChecked={child.general} onChange={() => this.checkPrice(child, 'Global')} />
            </>
        );
    };

    renderCatchingColumn = (child: EventChild): React.ReactElement | undefined => {
        const { catchingPrice } = this.props;
        return (
            <>
                <strong>
                    <FormattedMessage id="$" />
                    {catchingPrice}
                </strong>
                <Switch defaultChecked={child.catching} onChange={() => this.checkPrice(child, 'Catching')} />
            </>
        );
    };

    renderPitchingColumn = (child: EventChild): React.ReactElement | undefined => {
        const { pitchingPrice } = this.props;
        return (
            <>
                <strong>
                    <FormattedMessage id="$" />
                    {pitchingPrice}
                </strong>
                <Switch defaultChecked={child.pitching} onChange={() => this.checkPrice(child, 'Pitching')} />
            </>
        );
    };

    renderSubtotalPrice = (child: EventChild): React.ReactElement | undefined => {
        const { generalPrice, catchingPrice, pitchingPrice } = this.props;
        const generalPriceChecked = child.general === true ? generalPrice! : 0;
        const catchingPriceChecked = child.catching === true ? catchingPrice! : 0;
        const pitchingPriceChecked = child.pitching === true ? pitchingPrice! : 0;
        const subtotal = generalPriceChecked + catchingPriceChecked + pitchingPriceChecked;
        return (
            <>
                <strong>
                    <FormattedMessage id="$" />
                    {subtotal}
                </strong>
            </>
        );
    };

    renderChildrenTable = (): React.ReactElement | undefined => {
        const { eventChildren, event } = this.props;

        let columns: ColumnsType<EventChild> = [
            {
                title: <FormattedMessage id="signupPayment.event" />,
                dataIndex: 'event',
                key: 'event',
                width: 300,
                render: (value: string, child: EventChild) => this.renderEventColumn(event),
            },
            {
                title: <FormattedMessage id="signupPayment.type" />,
                dataIndex: 'givenName',
                key: 'givenName',
                width: 300,
                render: (value: string, child: EventChild) => [
                    <div>
                        <strong>
                            <FormattedMessage id="signupPayment.player" />
                        </strong>
                    </div>,
                    stringService.getName(true, child.givenName, child.familyName),
                ],
            },
            {
                title: <FormattedMessage id="signupPayment.general" />,
                dataIndex: 'general',
                key: 'general',
                width: 300,
                render: (value: string, child: EventChild) => this.renderGeneralColumn(child),
            },
            {
                title: <FormattedMessage id="signupPayment.catching" />,
                dataIndex: 'catching',
                key: 'catching',
                render: (value: string, child: EventChild) => this.renderCatchingColumn(child),
            },
            {
                title: <FormattedMessage id="signupPayment.pitching" />,
                dataIndex: 'pitching',
                key: 'pitching',
                render: (value: string, child: EventChild) => this.renderPitchingColumn(child),
            },
            {
                title: <FormattedMessage id="signupPayment.subtotal" />,
                dataIndex: 'email',
                key: 'email',
                render: (value: string, child: EventChild) => this.renderSubtotalPrice(child),
            },
        ];

        columns = columns.filter(
            (c) => c.key !== 'pitching' || event?.registrationTypes?.find((r) => r.type === 'Pitching'),
        );
        columns = columns.filter(
            (c) => c.key !== 'catching' || event?.registrationTypes?.find((r) => r.type === 'Catching'),
        );

        return (
            <Table
                className={styles.eventtable}
                dataSource={eventChildren}
                columns={columns}
                pagination={false}
                rowKey="userId"
            />
        );
    };

    renderTableFooter = (): React.ReactElement | undefined => {
        const { event, eventChildren, generalPrice, catchingPrice, pitchingPrice } = this.props;
        const { config } = this.state;
        const general = generalPrice ? eventChildren.filter((c) => c.general).length * generalPrice : 0;
        const catching = catchingPrice ? eventChildren.filter((c) => c.catching).length * catchingPrice : 0;
        const pitching = pitchingPrice ? eventChildren.filter((c) => c.pitching).length * pitchingPrice : 0;
        const totalPrice = general + catching + pitching;
        const fees =
            totalPrice > 0
                ? Math.ceil(((totalPrice / 100) * +config?.feePercent! + +config?.feeRate! + Number.EPSILON) * 100) /
                  100
                : 0;
        const totalPricePlusFees = totalPrice + fees;
        return (
            <>
                <div className={styles.tableRow}>
                    <Link to={`/signup/child?eventId=${event?.id}`}>
                        <Button type="primary" size="large" className={styles.btnMain}>
                            <FormattedMessage id="signupPayment.addChild" tagName="span" />
                        </Button>
                    </Link>
                </div>
                <div className={styles.summaryRow}>
                    <p>
                        <FormattedMessage id="signupPayment.serviceFees" />
                    </p>
                    <p>
                        <FormattedMessage id="$" />
                        <FormattedNumber value={fees} minimumFractionDigits={2} maximumFractionDigits={2} />
                    </p>
                </div>
                <div className={styles.balanceRow}>
                    <p>
                        <FormattedMessage id="signupPayment.balanceDue" />
                    </p>
                    <p>
                        <FormattedMessage id="$" />
                        <FormattedNumber
                            value={totalPricePlusFees}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    </p>
                </div>
            </>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { event, sports } = this.props;
        const { loading, waiverOne, waiverTwo, waiverThree } = this.state;
        return (
            <div className={styles.registrationForm}>
                <div className={styles.row}>
                    <div className={styles.flex}>
                        <aside>{event && <EventInfoComponent event={event} sports={sports} />}</aside>

                        <div className={styles.content}>
                            {this.renderForm()}
                            {this.renderChildrenTable()}
                            {this.renderTableFooter()}
                            <div className={styles.terms}>
                                <p>
                                    <FormattedMessage id="signupPayment.transactionTerms1" />
                                    <a
                                        href="https://quickbooks.intuit.com/global/terms-of-service/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FormattedMessage id="signupPayment.transactionTerms2" />
                                    </a>
                                    <FormattedMessage id="signupPayment.transactionTerms3" />
                                </p>
                            </div>

                            <div className={styles.buttons}>
                                <Popconfirm
                                    title={<FormattedMessage id="signupPayment.popUp" />}
                                    onConfirm={this.navigateToProfile}
                                    okText={<FormattedMessage id="button.yes" />}
                                    cancelText={<FormattedMessage id="button.no" />}
                                >
                                    <Button type="primary" size="large" className={styles.btnSecondary}>
                                        <FormattedMessage id="button.cancel" tagName="span" />
                                    </Button>
                                </Popconfirm>

                                <Button
                                    type="primary"
                                    className={styles.btnMain}
                                    size="large"
                                    loading={loading === 'submitting'}
                                    onClick={
                                        this.isTotalPriceZero() ? this.submitFreeRegistration : this.submitRegistration
                                    }
                                    disabled={!waiverOne || !waiverTwo || !waiverThree || this.isAnyPlayerSelected()}
                                >
                                    <FormattedMessage id="signupPayment.submitRegistration" tagName="span" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(withRouter(SignUpPaymentSelectionComponent));

interface Props extends RouteComponentProps, WrappedComponentProps {
    event: Event;
    sports: Sport[];
    eventChildren: EventChild[];
    generalPrice?: number;
    catchingPrice?: number;
    pitchingPrice?: number;
    onUpdate: (eventChildren: EventChild[]) => void;
    changeDisplay: (paymentDisplay: PaymentProcessType, registration: EventRegistrationInfo) => void;
}

interface State {
    loading?: 'initializing' | 'submitting';
    waiverOne?: boolean;
    waiverTwo?: boolean;
    waiverThree?: boolean;
    config?: Config;
}
