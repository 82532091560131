import { Button, Card, Image, List } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import needFeedApi from '../../../apis/NeedFeedApi';
import sportApi from '../../../apis/SportApi';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/Elements';
import { NeedFeed, Sport } from '../../../model/Entities';
import defaultCollegeLogo from '../../../resources/images/College-Connect-Default-School-Icon.png';
import notificationService from '../../../services/NotificationService';
import styles from './NeedFeedComponent.module.scss';
import RecommendPlayerModal from './RecommendPlayerModal/RecommendPlayerModal';

class NeedFeedComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;
    constructor(props: Props) {
        super(props);
        this.state = { sports: [], needs: [] };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.createNeeds !== this.props.createNeeds) {
            this.init();
        }
        if (
            prevProps.divisionId !== this.props.divisionId &&
            !this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')
        ) {
            this.filterByDivision(this.props.divisionId);
        }
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            let needsPage;
            if (this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')) {
                needsPage = await needFeedApi.listForCollegeCoach(
                    this.pageSize,
                    1,
                    this.context.auth?.colleges?.find(Boolean)?.id!,
                    this.context.auth?.colleges?.find(Boolean)?.sportId,
                );
                const needs = needsPage?.items ? needsPage.items : [];
                this.setState({ needsPage, needs });
            } else {
                needsPage = await needFeedApi.list(this.pageSize, 1);
                const needs = needsPage?.items ? needsPage.items : [];

                const sports = await sportApi.listAll();
                this.setState({ needsPage, needs, sports });
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    list = async () => {
        const { divisionId } = this.props;
        const { needsPage, needs } = this.state;
        try {
            this.setState({ loading: 'loadingMore' });

            const page = needsPage?.currentPage! + 1;

            let needsPageAux;
            if (this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')) {
                needsPageAux = await needFeedApi.listForCollegeCoach(
                    this.pageSize,
                    page,
                    this.context.auth?.colleges?.find(Boolean)?.id!,
                    this.context.auth?.colleges?.find(Boolean)?.sportId,
                );
            } else {
                needsPageAux = await needFeedApi.list(this.pageSize, page, undefined, divisionId);
            }
            const needsAux = needsPageAux ? needs.concat(needsPageAux.items) : [];

            this.setState({ needsPage: needsPageAux, needs: needsAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByDivision = async (divisionId?: number) => {
        try {
            this.setState({ loading: 'loading' });

            const needsPage = await needFeedApi.list(
                this.pageSize,
                1,
                this.context.auth?.colleges?.find(Boolean)?.sportId,
                divisionId,
            );
            const needs = needsPage.items ? needsPage.items : [];

            this.setState({ needsPage, needs });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showRecommendPlayerModal = (recommendPlayerModalVisible: boolean, need?: NeedFeed) => {
        this.setState({ recommendPlayerModalVisible, need });
    };

    /*** COMPONENTS ***/
    renderLoadMore = (): React.ReactElement | undefined => {
        const { loading, needsPage, needs } = this.state;
        return needs.length < needsPage?.totalItems! ? (
            <div className={styles.loadMore}>
                <Button onClick={this.list} loading={loading === 'loadingMore'}>
                    <FormattedMessage id="button.loadMore" tagName="span" />
                </Button>
            </div>
        ) : (
            <></>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { loading, recommendPlayerModalVisible, need, sports, needs } = this.state;
        return (
            <>
                <List
                    grid={{
                        gutter: 15,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 3,
                        xxl: 4,
                    }}
                    dataSource={needs}
                    //loadMore={this.renderLoadMore()}
                    className={styles.list}
                    renderItem={(need) => (
                        <List.Item key={need.id}>
                            <Card className={styles.tabContent}>
                                <Card.Meta
                                    title={
                                        <div className={styles.collegeHeader}>
                                            <Image
                                                className={styles.image}
                                                src={
                                                    (this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')
                                                        ? this.context.auth?.colleges?.find(Boolean)?.logo
                                                        : need.collegeLogo) || defaultCollegeLogo
                                                }
                                                fallback={defaultCollegeLogo}
                                                preview={false}
                                            />
                                            <h3>
                                                {this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')
                                                    ? this.context.auth?.colleges?.find(Boolean)?.name
                                                    : need.collegeName}
                                            </h3>
                                        </div>
                                    }
                                    className={styles.meta}
                                    description={
                                        <div className={styles.description}>
                                            <div className={styles.header}>
                                                {sports.find((s) => s.id === need.sportId)?.name}
                                                <label>
                                                    <FormattedMessage id="needFeed.position" />
                                                </label>
                                                <p>{need.positionNeeded}</p>

                                                <label>
                                                    <FormattedMessage id="needFeed.gradYear" />
                                                </label>
                                                <p>{need.gradYear}</p>

                                                {(need.gpa !== undefined ||
                                                    need.sat !== undefined ||
                                                    need.act !== undefined) && (
                                                    <label>
                                                        <FormattedMessage id="needFeed.academic" />
                                                    </label>
                                                )}
                                                <div className={styles.academic}>
                                                    <div className={styles.flex}>
                                                        {need.gpa !== undefined && (
                                                            <div className={styles.box}>
                                                                <div className={styles.content}>
                                                                    <label>
                                                                        <FormattedMessage id="needFeed.gpa" />
                                                                    </label>
                                                                    <p>{need.gpa}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {need.sat !== undefined && (
                                                            <div className={styles.box}>
                                                                <div className={styles.content}>
                                                                    <label>
                                                                        <FormattedMessage id="needFeed.sat" />
                                                                    </label>
                                                                    <p>{need.sat}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {need.act !== undefined && (
                                                            <div className={styles.box}>
                                                                <div className={styles.content}>
                                                                    <label>
                                                                        <FormattedMessage id="needFeed.act" />
                                                                    </label>
                                                                    <p>{need.act}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                {need.details && (
                                                    <div className={styles.details}>
                                                        <label>
                                                            <FormattedMessage id="needFeed.details" />
                                                        </label>
                                                        <p>{need.details}</p>
                                                    </div>
                                                )}
                                            </div>

                                            <div className={styles.buttons}>
                                                {this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH') ? (
                                                    <div>
                                                        <Button
                                                            type="primary"
                                                            onClick={() => this.props.editNeed(need)}
                                                        >
                                                            <FormattedMessage id="needFeed.editNeed" />
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Button
                                                            type="primary"
                                                            onClick={() => this.showRecommendPlayerModal(true, need)}
                                                        >
                                                            <FormattedMessage id="needFeed.recommendPlayer" />
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                />
                            </Card>
                        </List.Item>
                    )}
                    loading={loading === 'loading'}
                />
                {recommendPlayerModalVisible && need && (
                    <RecommendPlayerModal
                        need={need}
                        onCancel={() => this.showRecommendPlayerModal(false)}
                        onUpdate={() => this.showRecommendPlayerModal(false)}
                    />
                )}
                {this.renderLoadMore()}
            </>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(NeedFeedComponent);

interface Props extends WrappedComponentProps {
    createNeeds?: boolean;
    editNeed: (need: NeedFeed) => void;
    divisionId?: number;
}

interface State {
    needsPage?: Page<NeedFeed>;
    loading?: 'loading' | 'loadingMore';
    recommendPlayerModalVisible?: boolean;
    need?: NeedFeed;
    sports: Sport[];
    needs: NeedFeed[];
}
