import { Col, Form, message, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import organizationApi from '../../../../apis/OrganizationApi';
import organizationGroupApi from '../../../../apis/OrganizationGroupApi';
import { Organization, OrganizationGroupPlayer, Sport } from '../../../../model/Entities';
import notificationService from '../../../../services/NotificationService';
import styles from './MovePlayerModal.module.scss';

/**
 * Returns the move player to another affiliate modal panel.
 * @returns the modal panel.
 */
const MovePlayerModal = (props: ModalProps): React.ReactElement => {
    const { sports, player, onCancel, sourceOrganizationId, onUpdate } = props;
    const playerSports = sports.filter((s) => player.isInAffiliateSports?.find((sportId) => sportId === s.id));

    /** HOOKS */

    const [loading, setLoading] = useState<'loading'>();
    const intl = useIntl();
    const [sportIds, setSportIds] = useState<number[]>();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [form] = Form.useForm();

    /** METHODS **/

    /** Close modal panel */
    const cancel = async () => {
        onCancel();
    };

    /** Move the player to the selected affiliate */
    const save = async () => {
        try {
            setLoading('loading');

            const values = await form.validateFields();

            await organizationGroupApi.movePlayer(
                values.organizationId,
                player.userId!,
                sourceOrganizationId,
                values.sportIds,
            );
            message.success(intl.formatMessage({ id: 'status.action' }));

            onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, intl);
            }
        } finally {
            setLoading(undefined);
        }
    };

    /* useEffect(() => {  
        form.resetFields(['organizationId']);

        const organizations = await organizationSportsApi.listAll(undefined, sportIds);

        setSports(sportIds);
        setOrganizations(organizations);

    }, [form, sportIds]); */

    /** Select the sports and get the organizations of those sports */
    const changeSport = async (sportIds: number[]) => {
        form.resetFields(['organizationId']);

        const organizations = await organizationApi.listAll(sportIds);

        setSportIds(sportIds);
        setOrganizations(organizations);
    };

    /*** COMPONENTS ***/

    const playerSportsOptions = playerSports.map((sport) => (
        <Select.Option key={sport.id} value={sport.id!}>
            {sport.name}
        </Select.Option>
    ));

    const organizationOptions = organizations.map((organization) => (
        <Select.Option key={organization.id} value={organization.id!}>
            {organization.name}
        </Select.Option>
    ));

    return (
        <Modal
            width={600}
            visible={true}
            onCancel={cancel}
            onOk={save}
            cancelText={<FormattedMessage id="button.cancel" />}
            okText={<FormattedMessage id="affiliateGroup.movePlayerModal.button" tagName="span" />}
            okButtonProps={{ loading: loading === 'loading' }}
            title={<FormattedMessage id="affiliateGroup.movePlayerModal.title" />}
        >
            {
                <Form form={form} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col span={22}>
                            <Form.Item
                                label={<FormattedMessage id="affiliateGroup.movePlayerModal.sports" />}
                                name="sportIds"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                className={styles.input}
                            >
                                <Select
                                    placeholder={<FormattedMessage id="affiliateGroup.movePlayerModal.sport" />}
                                    onChange={changeSport}
                                    mode="multiple"
                                    className={styles.select}
                                >
                                    {playerSportsOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={22}>
                            <Form.Item
                                label={<FormattedMessage id="affiliateGroup.movePlayerModal.affiliate" />}
                                name="organizationId"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                className={styles.input}
                            >
                                <Select
                                    placeholder={
                                        <FormattedMessage id="affiliateGroup.movePlayerModal.selectAffiliate" />
                                    }
                                    disabled={sportIds?.length === 0 || !sportIds}
                                    className={styles.select}
                                >
                                    {organizationOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            }
        </Modal>
    );
};
export default MovePlayerModal;

interface ModalProps {
    sourceOrganizationId: string;
    player: OrganizationGroupPlayer;
    sports: Sport[];
    onCancel: () => void;
    onUpdate: () => void;
}
