import Icon from '@ant-design/icons';
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined';
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    FormInstance,
    Image,
    Input,
    message,
    Modal,
    Row,
    Select,
    Upload,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import organizationApi from '../../../../apis/OrganizationApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Organization } from '../../../../model/Entities';
import { countries, CountryType, timeZones } from '../../../../model/Types';
import { ReactComponent as SaveSvg } from '../../../../resources/images/ico-save.svg';
import logo from '../../../../resources/images/top100-logo.png';
import HeadMetadata from '../../../../services/HeadMetadata';
import notificationService from '../../../../services/NotificationService';
import stateService from '../../../../services/StateService';
import styles from './AdminOrganizationPage.module.scss';
import OrganizationSportsComponent from './OrganizationSportsComponent/OrganizationSportsComponent';

class AdminOrganizationPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });
            if (this.props.match.params.id === 'new') {
                await this.new();
            } else {
                await this.get(this.props.match.params.id);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    new = async () => {
        const organization: Organization = {
            name: '',
            country: 'US',
        };

        this.changeCountry(organization.country);
        this.setState({ organization });
        this.formRef.current!.setFieldsValue(organization);
    };

    get = async (id: string) => {
        const organization = await organizationApi.get(id);
        const photo = organization.logo;
        organization.country =
            organization.country && countries.includes(organization.country) ? organization.country : undefined;

        this.changeCountry(organization.country);
        this.setState({ organization, photo });
        this.formRef.current!.setFieldsValue(organization);
    };

    save = async (values: any) => {
        const { file } = this.state;
        try {
            this.setState({ loading: 'saving' });
            let organization: Organization = Object.assign({}, this.state.organization, values);
            organization = organization.id
                ? await organizationApi.update(organization)
                : await organizationApi.create(organization);

            // upload photo
            if (file) {
                await organizationApi.uploadLogo(organization.id!, file as File);
            }
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ organization });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'organization.status.duplicate' },
            ]);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    delete = async () => {
        const { organization } = this.state;
        try {
            this.setState({ loading: 'deleting' });
            await organizationApi.delete(organization!);
            this.props.history.push(`/admin/organizations`);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    uploadPhoto = (file: UploadFile) => {
        this.getBase64(file, (photo: string) =>
            this.setState({
                photo,
                file,
            }),
        );
        return false;
    };

    getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    changeCountry = (country?: CountryType) => {
        this.formRef.current?.resetFields(['state']);
        this.setState({ country });
    };

    showDeleteOrganizationModal = () => {
        const { loading } = this.state;

        Modal.confirm({
            title: this.props.intl.formatMessage({ id: 'organization.modalDelete.title' }),
            content: this.props.intl.formatMessage({ id: 'organization.modalDelete.description' }),
            onOk: this.delete,
            okButtonProps: { loading: loading === 'deleting' },
        });
    };

    /*** COMPONENTS ***/

    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderContent = (): React.ReactElement | undefined => {
        const { loading, photo, organization } = this.state;

        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));

        const timeZoneOptions = timeZones.map((t) => (
            <Select.Option key={t} value={t}>
                <FormattedMessage id={t} />
            </Select.Option>
        ));

        return (
            <>
                {this.renderToolbar()}
                <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <div className={styles.photo}>
                                <Form.Item name="logo" className={styles.image}>
                                    <Image src={photo || logo} fallback={logo} preview={false} />
                                </Form.Item>
                                <Upload.Dragger
                                    beforeUpload={this.uploadPhoto}
                                    showUploadList={false}
                                    className={styles.upload}
                                >
                                    <FormattedMessage id="organization.upload" />
                                </Upload.Dragger>
                            </div>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="organization.name" />}
                                name="name"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                className={styles.name}
                            >
                                <Input maxLength={128} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="organization.address" />}
                                name="address"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="organization.city" />}
                                name="city"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="organization.country" />}
                                name="country"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select onChange={(value: CountryType) => this.changeCountry(value)}>
                                    {countryOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="organization.state" />}
                                name="state"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select>{this.renderStates()}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="organization.zip" />}
                                name="zip"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={10} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="eventCreation.timeZone" />}
                                name="timeZone"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select>{timeZoneOptions}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item name="collegeConnectEnabled" valuePropName="checked">
                                <Checkbox>
                                    <FormattedMessage id="organization.collegeConnect" />
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider></Divider>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="organization.quickbooksId" />} name="quickBooksId">
                                <Input disabled size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="organization.quickbooksCustomer" />}
                                name="quickBooksCustomer"
                            >
                                <Input disabled size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {organization?.id && <OrganizationSportsComponent organization={organization} loading={loading} />}

                    <div className={styles.buttons}>
                        <Link to="/admin/organizations">
                            <Button size="large" className={styles.back}>
                                <FormattedMessage id="organization.back" />
                            </Button>
                        </Link>
                        {organization?.id && !organization.primary && (
                            <Button
                                size="large"
                                icon={<DeleteOutlined />}
                                className={styles.delete}
                                onClick={this.showDeleteOrganizationModal}
                            >
                                <FormattedMessage id="organization.delete" />
                            </Button>
                        )}
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className={styles.save}
                            icon={<Icon component={SaveSvg} />}
                            loading={loading === 'saving'}
                        >
                            <FormattedMessage id="organization.save" />
                        </Button>
                    </div>
                </Form>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        const { organization } = this.state;
        if (organization?.id) {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="organizations.editTitle" />
                    </h1>
                </div>
            );
        } else {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="organizations.addTitle" />
                    </h1>
                </div>
            );
        }
    };

    render() {
        const { loading } = this.state;
        return (
            <>
                <HeadMetadata titleKey="organization.meta.title" />
                <LayoutComponent
                    page="admin-organizations"
                    content={this.renderContent}
                    loading={loading === 'initializing'}
                />
            </>
        );
    }
}
export default injectIntl(AdminOrganizationPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    organization?: Organization;
    photo?: string;
    file?: any;
    loading?: 'initializing' | 'saving' | 'deleting';
    country?: CountryType;
}
