import Icon, { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Divider, Image, Menu, Row, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import organizationProfileApi from '../../../apis/OrganizationProfileApi';
import sportApi from '../../../apis/SportApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import OrganizationComponent from '../../../components/OrganizationComponent/OrganizationComponent';
import UserBackgroundComponent from '../../../components/UserBackgroundComponent/UserBackgroundComponent';
import CustomContext from '../../../context/CustomContext';
import {
    OrganizationProfile,
    OrganizationProfileEvent,
    OrganizationProfileSportBest,
    OrganizationSport,
    Sport,
} from '../../../model/Entities';
import { ReactComponent as dashboardSvg } from '../../../resources/images/ico-dashboard.svg';
import { ReactComponent as usersGroupsSvg } from '../../../resources/images/ico-grid.svg';
import { ReactComponent as invoicesSvg } from '../../../resources/images/ico-list.svg';
import { ReactComponent as playerProfileSvg } from '../../../resources/images/ico-playerProfile.svg';
import avatar from '../../../resources/images/profile-placeholder.png';
import logo from '../../../resources/images/top100-logo.png';
import HeadMetadata from '../../../services/HeadMetadata';
import notificationService from '../../../services/NotificationService';
import OrganizationSportModal from '../../Backend/AdminOrganizationsPage/AdminOrganizationPage/OrganizationSportsComponent/OrganizationSportModal/OrganizationSportModal';
import styles from './OrganizationPage.module.scss';

class OrganizationPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { sports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const responses = await Promise.all([
                organizationProfileApi.get(this.props.match.params.id),
                sportApi.listAll(),
            ]);
            const organizationProfile = responses[0];
            const sports = responses[1];
            const pageDisplayed = 'affiliateProfile';

            this.setState({ sports, organizationProfile, pageDisplayed });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    updateOrganizationProfile = async () => {
        try {
            this.setState({ loading: 'loading' });

            // update organization profile
            const organizationProfile = await organizationProfileApi.get(this.props.match.params.id);
            this.showAddSportModal(false);

            this.setState({ organizationProfile });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changePageDisplayed = (pageDisplayed?: 'affiliateProfile' | 'affiliateEdit') => {
        this.updateOrganizationProfile();
        const pageDisplayedAux = pageDisplayed ? pageDisplayed : 'affiliateProfile';
        this.setState({ pageDisplayed: pageDisplayedAux });
    };

    showAddSportModal = (addSportModalVisible: boolean, organizationSport?: OrganizationSport) => {
        this.setState({ addSportModalVisible, organizationSport });
    };

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { sports, organizationProfile } = this.state;
        const sportId =
            organizationProfile && organizationProfile.sports && organizationProfile.sports.length > 0
                ? organizationProfile.sports[0].sportId
                : undefined;

        if (desktop) {
            return (
                <div className={styles.header}>
                    <UserBackgroundComponent sports={sports} sportId={sportId} />
                </div>
            );
        }
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const organizationId = this.props.match.params.id;
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="organizations-dashboard"
                        icon={<Icon component={dashboardSvg} />}
                        onClick={() => this.navigate('/organizations')}
                    >
                        <Link to="/organizations">
                            <FormattedMessage id="navigation.admin.dashboard" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                <Menu.ItemGroup
                    key="organization-menu"
                    title={!collapsed && <FormattedMessage id="navigation.organization.dashboard.organization" />}
                >
                    <Menu.Item
                        key="organization"
                        icon={<Icon component={playerProfileSvg} />}
                        onClick={() => this.navigate(`/organizations/${organizationId}`)}
                    >
                        <Link to={`/organizations/${organizationId}`}>
                            <FormattedMessage id="navigation.organization.dashboard.profile" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="groups"
                        icon={<Icon component={usersGroupsSvg} />}
                        onClick={() => this.navigate(`/groups/affiliates/${organizationId}`)}
                    >
                        <Link to={`/groups/affiliates/${organizationId}`}>
                            <FormattedMessage id="navigation.admin.dashboard.groups" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="invoices"
                        icon={<Icon component={invoicesSvg} />}
                        onClick={() => this.navigate(`/invoices/${organizationId}`)}
                    >
                        <Link to={`/invoices/${organizationId}`}>
                            <FormattedMessage id="navigation.admin.dashboard.invoices" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    };

    renderAffiliateInfo = (): React.ReactElement | undefined => {
        const { organizationProfile } = this.state;

        const numberOfSports =
            organizationProfile && organizationProfile.sports ? organizationProfile.sports.length : 0;

        const numberOfEvents =
            organizationProfile && organizationProfile.events
                ? organizationProfile.events.filter((e) => e.status !== 'Completed').length
                : 0;

        return (
            <>
                <Row gutter={[18, 0]} className={styles.cards}>
                    <Col>
                        <Card className={styles.card}>
                            <div className={styles.logo}>
                                <div className={styles.gradient}></div>
                                <Image src={organizationProfile?.logo || logo} fallback={logo} />
                            </div>
                            <Card.Meta
                                title={
                                    <>
                                        <div>
                                            <span>{organizationProfile?.uniquePlayersCount || 0}</span>{' '}
                                            <FormattedMessage id="affiliateOwner.profile.players" />
                                        </div>
                                        <div>
                                            {numberOfSports} <FormattedMessage id="affiliateOwner.profile.sports" />
                                        </div>
                                    </>
                                }
                                description={
                                    <Link to={`/groups/affiliates/${organizationProfile?.id}`}>
                                        <Button className={styles.button}>
                                            <span className={styles.des}>
                                                <FormattedMessage id="affiliateOwner.profile.managePeople" />
                                            </span>
                                            <span className={styles.mob}>
                                                <FormattedMessage id="affiliateOwner.profile.manage" />
                                            </span>
                                        </Button>
                                    </Link>
                                }
                            />
                        </Card>
                    </Col>
                    <Col>
                        <Card className={styles.card}>
                            <div className={styles.logo}>
                                <div className={styles.gradient}></div>
                                <Image src={organizationProfile?.logo || logo} fallback={logo} />
                            </div>
                            <Card.Meta
                                title={
                                    <>
                                        <div>
                                            <span>{numberOfEvents}</span>{' '}
                                            <FormattedMessage id="affiliateOwner.profile.upcomingEvents" />
                                        </div>
                                    </>
                                }
                                description={
                                    <Button className={styles.button} hidden>
                                        <span className={styles.des}>
                                            <FormattedMessage id="affiliateOwner.profile.requestEvent" />
                                        </span>
                                        <span className={styles.mob}>
                                            <FormattedMessage id="affiliateOwner.profile.request" />
                                        </span>
                                    </Button>
                                }
                            />
                        </Card>
                    </Col>
                    <Col>
                        <Card className={styles.card}>
                            <div className={styles.logo}>
                                <div className={styles.gradient}></div>
                                <Image src={organizationProfile?.logo || logo} fallback={logo} />
                            </div>
                            <Card.Meta
                                title={
                                    <>
                                        <div>
                                            <span>{organizationProfile?.playersWithNationalRank}</span>{' '}
                                            <FormattedMessage id="affiliateOwner.profile.playersRank" />
                                        </div>
                                    </>
                                }
                                description={
                                    <Link to={`/leaderboard?organizationId=${organizationProfile?.id}`}>
                                        <Button className={styles.button}>
                                            <FormattedMessage id="affiliateOwner.profile.leaderboard" />
                                        </Button>
                                    </Link>
                                }
                            />
                        </Card>
                    </Col>
                    <Col>
                        <h2>
                            <FormattedMessage id="affiliateOwner.profile.affiliateInfo" />
                            <Button
                                type="text"
                                icon={<EditOutlined />}
                                onClick={() => this.changePageDisplayed('affiliateEdit')}
                            ></Button>
                        </h2>
                        <h3>
                            <FormattedMessage id="affiliateOwner.profile.name" />
                        </h3>
                        <p>{organizationProfile?.name}</p>
                        <Image src={organizationProfile?.logo || logo} fallback={logo} />
                    </Col>
                </Row>
            </>
        );
    };

    renderEventsTable = (): React.ReactElement | undefined => {
        const { organizationProfile, loading } = this.state;
        const events = organizationProfile && organizationProfile.events ? organizationProfile.events : [];

        const columns: ColumnsType<OrganizationProfileEvent> = [
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventName" />,
                dataIndex: 'name',
                key: 'name',
                width: 450,
                render: (value: string, event: OrganizationProfileEvent) => (
                    <Link to={`/events/${event.id}/summary`}>{value}</Link>
                ),
            },
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventDate" />,
                dataIndex: 'date',
                key: 'date',
                width: 200,
                render: (value: number, event: OrganizationProfileEvent) => (
                    <FormattedDate value={value} dateStyle="medium" />
                ),
            },
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventPlayersRegistered" />,
                dataIndex: 'registrations',
                key: 'registrations',
                width: 100,
                render: (value: number, event: OrganizationProfileEvent) => value,
            },
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventStatus" />,
                dataIndex: 'status',
                key: 'status',
                width: 130,
                render: (value: string, event: OrganizationProfileEvent) => value,
            },
        ];

        return (
            <>
                <div className={styles.players}>
                    <FormattedMessage id="affiliateOwner.profile.recentEvents" />
                </div>
                <Table
                    dataSource={events}
                    columns={columns}
                    pagination={false}
                    rowKey="id"
                    loading={loading === 'initializing'}
                    className={styles.table}
                />
            </>
        );
    };

    renderBestPlayer = (player?: OrganizationProfileSportBest): React.ReactElement | undefined => {
        return (
            <>
                <div className={styles.avatar}>{player && <Avatar src={player.photoUrl || avatar} />}</div>
                <div className={styles.playerRank}>{player?.rank}</div>
                <p>
                    {player?.givenName?.substring(0, 1)}. {player?.familyName}
                </p>
            </>
        );
    };

    renderSportsList = (): React.ReactElement | undefined => {
        const { organizationProfile, sports } = this.state;

        const numberOfSports =
            organizationProfile && organizationProfile.sports ? organizationProfile.sports.length : 0;

        return (
            <div className={styles.misc}>
                <div className={styles.players}>
                    <FormattedMessage id="affiliateOwner.profile.sports" />
                    <Button onClick={() => this.showAddSportModal(true)} hidden={sports.length === numberOfSports}>
                        <FormattedMessage id="affiliateOwner.profile.addSport" />
                    </Button>
                </div>

                {organizationProfile?.sports?.map((s) => {
                    const playerRankOne = s.best ? s.best.find((b) => b.rank === 1) : undefined;
                    const playerRankTwo = s.best ? s.best.find((b) => b.rank === 2) : undefined;
                    const playerRankThree = s.best ? s.best.find((b) => b.rank === 3) : undefined;
                    return (
                        <Card key={s.id} className={styles.card}>
                            <Card.Meta
                                className={styles.sportSection}
                                title={
                                    <div className={styles.sport}>
                                        <div className={styles.affiliate}>
                                            <div className={styles.logo}>
                                                <img src={organizationProfile.logo || logo} alt={logo}></img>
                                            </div>

                                            <div className={styles.sportDetails}>
                                                <Link
                                                    to={`/rosters/${organizationProfile.id}?sportId=${s.sportId}`}
                                                    className={styles.title}
                                                >
                                                    {s.name}
                                                </Link>
                                                <div className={styles.sportLogo}>
                                                    <img
                                                        src={sports.find((sport) => sport.id === s.sportId)?.iconUrl}
                                                        alt={logo}
                                                    ></img>
                                                    {sports.find((sport) => sport.id === s.sportId)?.name}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.rank}>
                                            <span>{this.renderBestPlayer(playerRankOne)}</span>
                                            <span>{this.renderBestPlayer(playerRankTwo)}</span>
                                            <span>{this.renderBestPlayer(playerRankThree)}</span>
                                        </div>
                                        <Button
                                            onClick={() => this.showAddSportModal(true, s)}
                                            className={styles.manage}
                                        >
                                            <FormattedMessage id="affiliateOwner.profile.manage" />
                                        </Button>
                                    </div>
                                }
                            />
                        </Card>
                    );
                })}
            </div>
        );
    };

    renderOrganizationSportModal = (): React.ReactElement | undefined => {
        const { organizationProfile, addSportModalVisible, sports, organizationSport } = this.state;

        if (addSportModalVisible) {
            return (
                <OrganizationSportModal
                    onUpdate={this.updateOrganizationProfile}
                    onCancel={() => this.showAddSportModal(false)}
                    organizationId={organizationProfile?.id!}
                    sports={sports}
                    organizationSport={organizationSport}
                />
            );
        }
    };

    renderAffiliateProfile = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderAffiliateInfo()}
                {this.renderEventsTable()}
                {this.renderSportsList()}
                {this.renderOrganizationSportModal()}
            </>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { pageDisplayed, organizationProfile } = this.state;

        return (
            <>
                {pageDisplayed === 'affiliateProfile' && this.renderAffiliateProfile()}
                {pageDisplayed === 'affiliateEdit' && (
                    <OrganizationComponent
                        organizationId={organizationProfile?.id!}
                        onBack={this.changePageDisplayed}
                        onSave={this.changePageDisplayed}
                    />
                )}
            </>
        );
    };

    render() {
        const { loading, organizationProfile } = this.state;
        const fullName =
            organizationProfile &&
            `${this.props.intl.formatMessage({
                id: 'affiliateProfile',
            })} - ${organizationProfile?.name}`;

        return (
            <div className="affiliateProfile">
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="organization"
                    header={this.renderHeader}
                    menu={this.renderMenu}
                    content={this.renderContent}
                    loading={loading === 'initializing'}
                />
            </div>
        );
    }
}
export default injectIntl(OrganizationPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    loading?: 'initializing' | 'loading';
    sports: Sport[];
    organizationProfile?: OrganizationProfile;
    pageDisplayed?: 'affiliateProfile' | 'affiliateEdit';
    addSportModalVisible?: boolean;
    organizationSport?: OrganizationSport;
}
