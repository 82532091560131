import {
    ChildToRegister,
    EventChild,
    EventRegistrationInfo,
    EventRegistrationInfoList,
    QuickbooksToken,
} from '../model/Entities';
import apiAxios from './CustomAxios';

class EventRegistrationApi {
    create = async (eventId: number, children: EventChild[]): Promise<EventRegistrationInfo> => {
        const players: ChildToRegister[] = [];
        children.forEach((c) => {
            const child: ChildToRegister = { userId: c.userId, registrationTypes: [] };
            c.general && child.registrationTypes?.push('Global');
            c.catching && child.registrationTypes?.push('Catching');
            c.pitching && child.registrationTypes?.push('Pitching');
            players.push(child);
        });
        const response = await apiAxios.post<EventRegistrationInfo>(`/v1/events/${eventId}/registrations`, {
            players: players,
        });
        return response.data;
    };

    get = async (eventId: number): Promise<EventRegistrationInfoList> => {
        const response = await apiAxios.get<EventRegistrationInfoList>(`/v1/events/${eventId}/registrations`);
        return response.data;
    };

    pay = async (eventId: number, registrationId: string, token: QuickbooksToken): Promise<void> => {
        await apiAxios.post<void>(`/v1/events/${eventId}/registrations/${registrationId}/payments`, {
            cardToken: token.value,
        });
    };
}
const eventRegistrationApi: EventRegistrationApi = new EventRegistrationApi();
export default eventRegistrationApi;
