import React, { Component } from 'react';
import styles from './SignInPage.module.scss';
import { Button, Col, Form, Input, message, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import CustomContext from '../../context/CustomContext';
import authService from '../../services/AuthService';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import HeadMetadata from '../../services/HeadMetadata';
import ga from '../../services/GoogleAnalytics';

class SignInPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    /** METHODS **/

    signIn = async (values: any): Promise<void> => {
        const { updateAuth } = this.context;
        const { intl } = this.props;

        try {
            this.setState({ loading: true });
            ga.createDefaultEvent('sign in', 'sign in - signing in');
            const auth = await authService.signIn(values.username, values.password);

            if ('challengeName' in auth) {
                this.setState({ awsCognitoAuth: auth });
                ga.createDefaultEvent('sign in', 'sign in - change password required');
            } else {
                updateAuth(auth);
                ga.createDefaultEvent('sign in', 'sign in - success');
                this.props.history.push('/');
            }
        } catch (error: any) {
            if (error.message) {
                message.error(error.message, 6);
                ga.createDefaultEvent('sign in', `sign in - ${error.message}`);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
                ga.createDefaultEvent('sign in', 'sign in - internal error');
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    completeNewPassword = async (values: any): Promise<void> => {
        const { updateAuth } = this.context;
        const { intl } = this.props;
        const { awsCognitoAuth } = this.state;

        try {
            this.setState({ loading: true });
            ga.createDefaultEvent('sign in', 'sign in - changing password');
            const auth = await authService.completeNewPassword(awsCognitoAuth, values.password);
            updateAuth(auth);
            ga.createDefaultEvent('sign in', 'sign in - change password success');
            this.props.history.push('/');
        } catch (error: any) {
            if (error.message) {
                message.error(error.message, 6);
                ga.createDefaultEvent('sign in', `sign in - ${error.message}`);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
                ga.createDefaultEvent('sign in', 'sign in - change password internal error');
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { awsCognitoAuth } = this.state;

        return (
            <div className={styles.container}>
                <img src={process.env.PUBLIC_URL + '/img/top100-logo.png'} alt="" />
                <div className={styles.panel}>
                    <div className={styles.shadow}></div>
                    <div className={styles.body}>
                        {awsCognitoAuth ? this.renderCompleteNewPasswordForm() : this.renderSignInForm()}
                    </div>
                </div>
            </div>
        );
    };

    renderCompleteNewPasswordForm = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        return (
            <>
                <Row>
                    <Col>
                        <h1>
                            <FormattedMessage id="completeNewPassword.title" />
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form ref={this.formRef} onFinish={this.completeNewPassword} colon={false} layout="vertical">
                            <Form.Item
                                label={<FormattedMessage id="completeNewPassword.password" />}
                                name="password"
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    {
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                                        message: <FormattedMessage id="status.password" />,
                                    },
                                ]}
                                required={false}
                            >
                                <Input type="password" maxLength={35} size="large" />
                            </Form.Item>
                            <Form.Item className={styles.buttons}>
                                <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                                    <FormattedMessage id="completeNewPassword.submit" tagName="span" />
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    };

    renderSignInForm = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        return (
            <>
                <Row>
                    <Col>
                        <h1>
                            <FormattedMessage id="signIn.title" />
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form ref={this.formRef} onFinish={this.signIn} colon={false} layout="vertical">
                            <Form.Item
                                label={<FormattedMessage id="signIn.username" />}
                                name="username"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                required={false}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                            <Form.Item
                                label={<FormattedMessage id="signIn.password" />}
                                name="password"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                required={false}
                            >
                                <Input type="password" maxLength={25} size="large" />
                            </Form.Item>
                            <Form.Item className={styles.buttons}>
                                <Button type="primary" htmlType="submit" size="large" loading={loading} block>
                                    <FormattedMessage id="signIn.signIn" tagName="span" />
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Link to="/forgot-password">
                            <Button type="link" className={styles.password}>
                                <FormattedMessage id="signIn.forgotPassword" />
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="signIn.meta.title" />
                {this.renderContent()}
            </>
        );
    }
}
export default injectIntl(SignInPage);

interface Props extends WrappedComponentProps, RouteComponentProps {}

interface State {
    loading?: boolean;
    awsCognitoAuth?: any;
}
