import { Page } from '../model/Elements';
import { Result } from '../model/Entities';
import apiAxios from './CustomAxios';

class ResultApi {
    list = async (userId: string, sportId: number, seasonId?: number): Promise<Result[]> => {
        const response = await apiAxios.get<Page<Result>>(`/v1/users/${userId}/sports/${sportId}/results`, {
            params: { seasonId },
        });
        response.data.items.forEach((i) => (i.date = i.date ? i.date * 1000 : undefined));
        return response.data.items;
    };

    delete = async (userId: string, sportId: number, metricMeasureId: number): Promise<void> => {
        await apiAxios.delete(`/v1/users/${userId}/sports/${sportId}/results/${metricMeasureId}`);
    };
}

const resultApi: ResultApi = new ResultApi();
export default resultApi;
