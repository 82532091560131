import axios from 'axios';
import { Card, QuickbooksToken } from '../model/Entities';

class QuickbooksApi {
    readonly url = process.env.REACT_APP_QUICKBOOKS_API || '';

    create = async (card: Card): Promise<QuickbooksToken> => {
        const response = await axios.post<QuickbooksToken>(this.url + '/quickbooks/v4/payments/tokens', card);
        return response.data;
    };
}
const quickbooksApi: QuickbooksApi = new QuickbooksApi();
export default quickbooksApi;
