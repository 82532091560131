import Icon, {
    FileExcelOutlined,
    FileOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Form, FormInstance, Image, List, Select, Spin } from 'antd';
import axios from 'axios';
import FileSaver from 'file-saver';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import ReactPlayer from 'react-player';
import { RouteComponentProps } from 'react-router-dom';
import mediaApi from '../../apis/MediaApi';
import sportApi from '../../apis/SportApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Page } from '../../model/Elements';
import { Media, Sport, User, UserSport } from '../../model/Entities';
import { MediaType } from '../../model/Types';
import { ReactComponent as addSvg } from '../../resources/images/ico-add.svg';
import avatar from '../../resources/images/profile-placeholder.png';
import ga from '../../services/GoogleAnalytics';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import playerService from '../../services/PlayerService';
import AddMediaComponent from './AddMediaComponent/AddMediaComponent';
import MediaPlayerComponent from './MediaPlayerComponent/MediaPlayerComponent';
import styles from './PlayerMediaPage.module.scss';

class PlayerMediaPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();
    readonly pageSize = 50;

    constructor(props: Props) {
        super(props);
        this.state = { sports: [], userSports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            // get player
            const responses = await Promise.all([
                playerService.getPlayer(this.props),
                mediaApi.list(this.props.match.params.id, this.pageSize, 1),
                sportApi.listAll(),
            ]);
            const player = responses[0];
            const mediasPage = responses[1];
            const sports = responses[2];
            const medias = mediasPage ? mediasPage.items : [];
            const mediaSelected: Media[] = [];
            const selectedSportId = undefined;

            // get permissions
            const editable = playerService.isEditable(player.user, this.context.auth);

            this.setState({
                ...player,
                mediasPage,
                medias,
                mediaSelected,
                isSelecting: false,
                sports,
                selectedSportId,
                editable,
            });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    list = async () => {
        const { mediasPage, user, medias, selectedSportId } = this.state;
        try {
            this.setState({ loading: 'loading' });

            const page = mediasPage?.currentPage! + 1;
            const mediasPageAux = await mediaApi.list(user?.id!, this.pageSize, page, selectedSportId);
            const mediasAux = mediasPageAux && mediasPageAux.items ? medias?.concat(mediasPageAux.items) : [];

            this.setState({ mediasPage: mediasPageAux, medias: mediasAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showModal = (modalVisible: boolean) => {
        this.setState({ modalVisible });
    };

    showPlayerModal = (playerModalVisible: boolean, media?: Media) => {
        this.setState({ playerModalVisible, media });
    };

    changeMediaType = (mediaType?: MediaType) => {
        this.setState({ mediaType });
    };

    refresh = async () => {
        try {
            this.setState({ loading: 'loading' });

            this.showModal(false);
            const mediasPage = await mediaApi.list(this.props.match.params.id, this.pageSize, 1);
            const medias = mediasPage ? mediasPage.items : [];
            const mediaSelectedAux: Media[] = [];
            const selectedSportId = undefined;

            this.setState({ mediasPage, medias, mediaSelected: mediaSelectedAux, selectedSportId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteMedia = async () => {
        const { user, mediaSelected } = this.state;
        try {
            this.setState({ loading: 'loading' });
            await mediaApi.delete(user?.id!, mediaSelected!);
            const mediasPage = await mediaApi.list(this.props.match.params.id, this.pageSize, 1);
            const medias = mediasPage ? mediasPage.items : [];
            const mediaSelectedAux: Media[] = [];
            const selectedSportId = undefined;
            ga.createDefaultEvent('player delete media', 'player delete media - success');
            this.setState({ mediasPage, medias, mediaSelected: mediaSelectedAux, selectedSportId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
            ga.createDefaultEvent('player delete media', 'player delete media - error');
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeIsSelecting = (isSelecting: boolean) => {
        if (isSelecting) {
            this.setState({ isSelecting });
        } else {
            const mediaSelected: Media[] = [];
            this.setState({ isSelecting, mediaSelected });
        }
    };

    selectMedia = (media: Media) => {
        const { medias, mediaSelected } = this.state;
        if (mediaSelected?.includes(media)) {
            const mediaSelectedAux = mediaSelected.filter((m) => m !== media);
            this.setState({ mediaSelected: mediaSelectedAux });
        } else {
            mediaSelected?.push(media);
            this.setState({ mediaSelected });
        }

        const mediasAux = medias;
        this.setState({ medias: mediasAux });
    };

    filterBySport = async (userSportId: number) => {
        const { userSports } = this.state;

        try {
            this.setState({ loading: 'loading' });

            const userSport = userSports.find((u) => u.id === userSportId);
            const mediasPage = await mediaApi.list(this.props.match.params.id, this.pageSize, 1, userSport?.sportId);
            const medias = mediasPage && mediasPage.items ? mediasPage.items : [];

            this.setState({ mediasPage, medias, selectedSportId: userSportId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    downloadFile = async (media: Media) => {
        try {
            this.setState({ loading: `downloading-${media.id}` });
            const response = await axios.get<Blob>(media.url!, {
                responseType: 'blob',
            });
            FileSaver.saveAs(response.data, media.caption);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { user, userSport, isSelecting, editable } = this.state;

        if (editable) {
            if (desktop) {
                return (
                    <Form ref={this.formRef} colon={false} layout="vertical">
                        <div className={styles.header}>
                            <div className={styles.player}>
                                <div className={styles.avatar}>
                                    <Avatar
                                        className={styles.picture}
                                        src={userSport?.photoUrl || avatar}
                                        icon={<UserOutlined />}
                                    />
                                    <h1>
                                        {user?.profile.givenName} {user?.profile.familyName}
                                    </h1>
                                </div>
                                <div className={styles.buttons}>
                                    {isSelecting && (
                                        <>
                                            <Button shape="round" onClick={() => this.changeIsSelecting(false)}>
                                                <FormattedMessage id="player.media.cancel" />
                                            </Button>
                                            <Button shape="round" onClick={this.deleteMedia}>
                                                <FormattedMessage id="player.media.delete" />
                                            </Button>
                                        </>
                                    )}
                                    <Form.Item name="select">
                                        <Button shape="round" onClick={() => this.changeIsSelecting(true)}>
                                            <FormattedMessage id="player.media.select" />
                                        </Button>
                                    </Form.Item>
                                    <Form.Item name="add">
                                        <Button
                                            className={styles.add}
                                            icon={<Icon component={addSvg} />}
                                            shape="round"
                                            onClick={() => this.showModal(true)}
                                        >
                                            <FormattedMessage id="player.media.add" />
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            } else {
                return (
                    <Form ref={this.formRef} colon={false} layout="vertical">
                        <div className={styles.header}>
                            <div className={styles.player}>
                                <div className={styles.buttons}>
                                    {isSelecting && (
                                        <>
                                            <Button shape="round" onClick={() => this.changeIsSelecting(false)}>
                                                <FormattedMessage id="player.media.cancel" />
                                            </Button>
                                            <Button shape="round" onClick={this.deleteMedia}>
                                                <FormattedMessage id="player.media.delete" />
                                            </Button>
                                        </>
                                    )}
                                    <Form.Item name="select">
                                        <Button
                                            shape="round"
                                            className={styles.select}
                                            onClick={() => this.changeIsSelecting(true)}
                                        >
                                            <FormattedMessage id="player.media.select" />
                                        </Button>
                                    </Form.Item>
                                    <Form.Item name="add">
                                        <Button
                                            className={styles.add}
                                            shape="round"
                                            onClick={() => this.showModal(true)}
                                            type={'text'}
                                            icon={<Icon component={addSvg} />}
                                        ></Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                );
            }
        }
    };

    renderLoadMore = (): React.ReactElement | undefined => {
        const { loading, mediasPage, medias } = this.state;
        return !loading && medias?.length! < mediasPage?.totalItems! ? (
            <div className={styles.loadMore}>
                <Button onClick={this.list}>
                    <FormattedMessage id="button.loadMore" />
                </Button>
            </div>
        ) : (
            <></>
        );
    };

    /**
     * Function to change the icon depending of the file type.
     * @param media - the media
     * @returns an icon
     */
    renderIcon = (media: Media): React.ReactElement | undefined => {
        const { loading } = this.state;

        if (loading === `downloading-${media.id}`) {
            return <Spin size="large" className={styles.loader}></Spin>;
        } else if (media.url?.endsWith('.pdf')) {
            return <FilePdfOutlined className={styles.icon} />;
        } else if (media.url?.endsWith('.doc') || media.url?.endsWith('.docx')) {
            return <FileWordOutlined className={styles.icon} />;
        } else if (media.url?.endsWith('.xls') || media.url?.endsWith('.xlsx') || media.url?.endsWith('.csv')) {
            return <FileExcelOutlined className={styles.icon} />;
        } else {
            return <FileOutlined className={styles.icon} />;
        }
    };

    /**
     * Returns a different type of card depending of the media type.
     * @param media - the media
     * @returns a media card
     */
    renderCard = (media: Media): React.ReactElement | undefined => {
        if (media.mediaType === 'Video') {
            return (
                <Card
                    onClick={() => this.showPlayerModal(true, media)}
                    className={`${styles.card} ${styles.cardVideo}`}
                    cover={
                        <div className={styles.image}>
                            <ReactPlayer controls={false} width="10" url={media.url} />
                            <div className={styles.gradient}></div>
                        </div>
                    }
                >
                    <Card.Meta className={styles.meta} title={media.caption} />
                </Card>
            );
        } else if (media.mediaType === 'Photo') {
            return (
                <Card
                    onClick={() => this.showPlayerModal(true, media)}
                    className={`${styles.card} ${styles.cardPicture}`}
                    cover={
                        <div className={styles.image}>
                            <Image src={media.url} className={styles.image} preview={false} />
                            <div className={styles.gradient}></div>
                        </div>
                    }
                >
                    <Card.Meta className={styles.meta} title={media.caption} />
                </Card>
            );
        } else {
            return (
                <Card
                    onClick={() => this.downloadFile(media)}
                    className={`${styles.card} ${styles.cardPicture}`}
                    cover={
                        <div className={styles.image}>
                            {this.renderIcon(media)}
                            <div className={styles.gradient}></div>
                        </div>
                    }
                >
                    <Card.Meta className={styles.meta} title={media.caption} />
                </Card>
            );
        }
    };

    /**
     * Returns a different type of card depending of the media type in the selecting mode.
     * @param media - the media
     * @returns a media card
     */
    renderSelectedCard = (media: Media): React.ReactElement | undefined => {
        const { mediaSelected } = this.state;
        return (
            <Card
                onClick={() => this.selectMedia(media)}
                className={`${mediaSelected?.includes(media) ? styles.cardSelected : styles.card} ${
                    media.mediaType === 'Video' ? styles.cardVideo : styles.cardPicture
                }`}
                cover={
                    <div className={styles.image}>
                        {media.mediaType === 'Photo' && (
                            <Image src={media.url} className={styles.image} preview={false} />
                        )}
                        {media.mediaType === 'Video' && <ReactPlayer controls={false} width="10" url={media.url} />}
                        {media.mediaType !== 'Video' && media.mediaType !== 'Photo' && this.renderIcon(media)}
                    </div>
                }
            >
                <Card.Meta className={styles.meta} title={media.caption} />
            </Card>
        );
    };

    /**
     * Return a card based on the display mode that is selected.
     * @param media - the media
     * @returns a media card
     */
    renderMedia = (media: Media): React.ReactElement | undefined => {
        const { isSelecting } = this.state;

        if (!isSelecting) {
            return this.renderCard(media);
        } else {
            return this.renderSelectedCard(media);
        }
    };

    renderList = (desktop: boolean): React.ReactElement | undefined => {
        const {
            playerModalVisible,
            modalVisible,
            mediaType,
            medias,
            media,
            loading,
            user,
            sports,
            userSport,
            selectedSportId,
            userSports,
        } = this.state;

        let items;
        if (!mediaType) {
            items = medias;
        } else if (mediaType === 'Video') {
            items = medias?.filter((i) => i.mediaType === 'Video');
        } else if (mediaType === 'Photo') {
            items = medias?.filter((i) => i.mediaType === 'Photo');
        } else {
            items = medias?.filter((i) => i.mediaType === 'File');
        }

        let userSportOptions = userSports.map((us) => (
            <Select.Option key={us.id} value={us.id!}>
                {sports?.find((s) => s.id === us.sportId)?.name}
            </Select.Option>
        ));

        sports &&
            (userSportOptions = [
                <Select.Option key="" value="">
                    {<FormattedMessage id="player.events.sports" />}
                </Select.Option>,
                ...userSportOptions!,
            ]);

        return (
            <>
                <div className={styles.media}>
                    <h2>
                        <FormattedMessage id="player.media.allPhotosVideos" />
                    </h2>
                    <div className={styles.selects}>
                        <Select
                            className={styles.sports}
                            value={selectedSportId}
                            onChange={this.filterBySport}
                            placeholder={<FormattedMessage id="player.events.sports" />}
                        >
                            {userSportOptions}
                        </Select>
                        <Select
                            className={styles.sports}
                            value={mediaType}
                            onChange={this.changeMediaType}
                            placeholder={<FormattedMessage id="player.media.all" />}
                        >
                            <Select.Option key="" value="">
                                {<FormattedMessage id="player.media.all" />}
                            </Select.Option>
                            <Select.Option key="Photo" value="Photo">
                                {<FormattedMessage id="player.media.photos" />}
                            </Select.Option>
                            <Select.Option key="Video" value="Video">
                                {<FormattedMessage id="player.media.videos" />}
                            </Select.Option>
                            <Select.Option key="File" value="File">
                                {<FormattedMessage id="player.media.files" />}
                            </Select.Option>
                        </Select>
                    </div>
                </div>
                {user && userSport && modalVisible && (
                    <AddMediaComponent
                        onCancel={() => this.showModal(false)}
                        onUpdate={this.refresh}
                        user={user}
                        userSports={userSports}
                        sports={sports}
                    />
                )}
                {playerModalVisible && media && (
                    <MediaPlayerComponent onCancel={() => this.showPlayerModal(false)} medias={[media]} />
                )}
                <List
                    className={styles.photosList}
                    grid={{
                        gutter: 30,
                    }}
                    loadMore={this.renderLoadMore()}
                    dataSource={items}
                    renderItem={(media) => (
                        <List.Item key={media.id} className={styles.image}>
                            {this.renderMedia(media)}
                        </List.Item>
                    )}
                    loading={loading === 'loading'}
                />
            </>
        );
    };

    render() {
        const { user } = this.state;
        const playerProps = playerService.getProps(this.props, user);
        const fullName =
            user &&
            `${user?.profile?.givenName} ${user?.profile?.familyName} - ${this.props.intl.formatMessage({
                id: 'media',
            })}`;

        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="player-media"
                    content={this.renderList}
                    header={this.renderHeader}
                    {...playerProps}
                />
            </>
        );
    }
}
export default injectIntl(PlayerMediaPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    playerModalVisible?: boolean;
    modalVisible?: boolean;
    mediasPage?: Page<Media>;
    medias?: Media[];
    user?: User;
    userSport?: UserSport;
    media?: Media;
    mediaType?: MediaType;
    mediaSelected?: Media[];
    isSelecting?: boolean;
    editable?: boolean;
    loading?: 'loading' | any;
    searchText?: string;
    sports: Sport[];
    selectedSportId?: number;
    userSports: UserSport[];
}
