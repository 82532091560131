import { OrganizationProfile } from '../model/Entities';
import dateService from '../services/DateService';
import apiAxios from './CustomAxios';

class OrganizationProfileApi {
    get = async (organizationId: string): Promise<OrganizationProfile> => {
        const response = await apiAxios.get<OrganizationProfile>(`/v1/organizations/${organizationId}/profile`);

        response.data.events?.forEach((e) => (e.date = e.date ? dateService.toLocal(e.date * 1000) : undefined));

        return response.data;
    };
}

const organizationProfileApi: OrganizationProfileApi = new OrganizationProfileApi();
export default organizationProfileApi;
