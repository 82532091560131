import { SportCategory } from '../model/Entities';
import apiAxios from './CustomAxios';

class SportMetricApi {
    list = async (sportId: number, leaderboardType?: string): Promise<SportCategory[]> => {
        const response = await apiAxios.get<SportCategory[]>(`/v1/sports/${sportId}/metrics`, {
            params: { leaderboardType },
        });
        return response.data;
    };
}

const sportMetricApi: SportMetricApi = new SportMetricApi();
export default sportMetricApi;
