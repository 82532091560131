import moment from 'moment';

class DateService {
    toLocal = (date: number): number => {
        return moment(date).subtract(moment(date).utcOffset(), 'minutes').valueOf();
    };

    toUtc = (date: number): number => {
        const dateAsMoment = moment(date);
        return moment.utc(dateAsMoment.format().slice(0, -6)).valueOf();
    };
}

const dateService: DateService = new DateService();
export default dateService;
