import { Page } from '../model/Elements';
import { Season } from '../model/Entities';
import apiAxios from './CustomAxios';

class SeasonApi {
    list = async (
        limit: number,
        page: number,
        sportId: number,
        sortField?: string,
        sortOrder?: string,
        search?: string,
    ): Promise<Page<Season>> => {
        const order = sortOrder === 'ascend' ? 'Asc' : 'Desc';
        const field = this.getSortField(sortField);
        const response = await apiAxios.get<Page<Season>>(`/v1/sports/${sportId}/seasons`, {
            params: { limit, page, [field]: order, search },
        });

        response.data.items.forEach((i) => [
            (i.startDate = i.startDate ? i.startDate * 1000 : undefined),
            (i.endDate = i.endDate ? i.endDate * 1000 : undefined),
        ]);

        return response.data;
    };

    listAll = async (sportId: number): Promise<Season[]> => {
        let page = 1;
        let total: number;
        let items: Season[] = [];
        do {
            const itemsPage = await this.list(500, page, sportId);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        return items;
    };

    create = async (sportId: number, season: Season): Promise<Season> => {
        const date = Math.floor(new Date().valueOf() / 1000);
        const response = await apiAxios.post<Season>(`/v1/sports/${sportId}/seasons`, {
            date: date,
            name: season.name,
        });
        return response.data;
    };

    private getSortField(sortField?: string): string {
        let field: string = 'sorting.StartDate';
        return field;
    }
}

const seasonApi: SeasonApi = new SeasonApi();
export default seasonApi;
