import { Avatar, Button, Card, List, Modal } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import CustomContext from '../../../../../context/CustomContext';
import { OrganizationGroupCoach, Team, TeamMemberFromTeam } from '../../../../../model/Entities';
import { DisplayType, GenderType, SortingPlayerType } from '../../../../../model/Types';
import avatar from '../../../../../resources/images/profile-placeholder-bp.png';
import metricService from '../../../../../services/MetricService';
import notificationService from '../../../../../services/NotificationService';
import numericService from '../../../../../services/NumericService';
import stringService from '../../../../../services/StringService';
import styles from '../../../OrganizationsPage.module.scss';
import TeamMemberModal from '../TeamMemberModal/TeamMemberModal';
import Icon from '@ant-design/icons';
import { ReactComponent as removeSvg } from '../../../../../resources/images/ico-thrash.svg';
import organizationGroupApi from '../../../../../apis/OrganizationGroupApi';
import rolesService from '../../../../../services/RolesService';

class TeamComponent extends React.Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.team !== prevProps.team) {
            this.setState({ team: this.props.team });
        }
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });
            const team = this.props.team;
            this.setState({ team });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteCoach = async () => {
        const { team, organizationId } = this.props;
        const { coach } = this.state;
        try {
            this.setState({ loading: 'deleting' });

            await organizationGroupApi.deleteTeamCoach(organizationId, team.sportId!, coach!, team);

            this.showDeleteCoachModal(false);
            this.props.onUpdate();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    listTeamMembers = (team: Team): TeamMemberFromTeam[] => {
        const { genders, grades, positionIds, sortField } = this.props;

        return team?.teamMembers
            ? team.teamMembers
                  .filter((p) => genders?.length === 0 || genders?.includes(p.profile?.gender!))
                  .filter((p) => grades?.length === 0 || grades?.includes(p.profile?.grade!))
                  .filter(
                      (p) =>
                          positionIds?.length === 0 ||
                          p.positions?.map((pa) => pa.id).some((pa) => positionIds.includes(pa)),
                  )
                  .filter((p) =>
                      stringService.search(p.profile?.givenName + ' ' + p.profile?.familyname, this.props.searchText),
                  )
                  .sort((a, b) => this.sort(a, b, sortField))
            : [];
    };

    listCoaches = (coaches: OrganizationGroupCoach[]): OrganizationGroupCoach[] => {
        const { team } = this.state;

        return coaches
            .filter((c) => c.teams?.find((t) => t.teamId === team?.id))
            .filter((c) => stringService.search(c.givenName + ' ' + c.familyName, this.props.searchText));
    };

    sort = (a: TeamMemberFromTeam, b: TeamMemberFromTeam, sortField?: SortingPlayerType): number => {
        const aJerseyNumber = a.jerseyNumber && a.jerseyNumber.length > 0 ? a.jerseyNumber[0] : undefined;
        const bJerseyNumber = b.jerseyNumber && b.jerseyNumber.length > 0 ? b.jerseyNumber[0] : undefined;
        switch (sortField) {
            case 'jerseyAsc':
                return numericService.sort(bJerseyNumber, aJerseyNumber);
            case 'jerseyDesc':
                return numericService.sort(aJerseyNumber, bJerseyNumber);
            case 'firstNameAsc':
                return stringService.sort(a.profile?.givenName, b.profile?.givenName);
            case 'firstNameDesc':
                return stringService.sort(b.profile?.givenName, a.profile?.givenName);
            case 'lastNameAsc':
                return stringService.sort(a.profile?.familyname, b.profile?.familyname);
            case 'lastNameDesc':
                return stringService.sort(b.profile?.familyname, a.profile?.familyname);

            default:
                return stringService.sort(
                    a.profile?.givenName + ' ' + a.profile?.familyname,
                    b.profile?.givenName + ' ' + b.profile?.familyname,
                );
        }
    };

    showModalPlayer = (teamMember?: TeamMemberFromTeam) => {
        this.setState({ teamMember });
    };

    refresh = async () => {
        this.showModalPlayer(undefined);
        await this.props.onUpdate();
    };

    showDeleteCoachModal = (deleteCoachModalVisible: boolean, coach?: OrganizationGroupCoach) => {
        this.setState({ deleteCoachModalVisible, coach });
    };

    /*** COMPONENTS ***/
    renderRemoveModal = (): React.ReactElement | undefined => {
        const { deleteCoachModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="roster.team.deleteCoachModal.title" />}
                visible={deleteCoachModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.deleteCoach}
                okButtonProps={{ loading: loading === 'deleting' }}
                onCancel={() => this.showDeleteCoachModal(false)}
            >
                <p>
                    <FormattedMessage id="roster.team.deleteCoachModal.description" />
                </p>
            </Modal>
        );
    };

    renderCoachCards = (coaches: OrganizationGroupCoach[]): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { loading } = this.state;

        if (coaches.length > 0) {
            return (
                <List
                    grid={{
                        gutter: 30,
                        xs: 2,
                        sm: 3,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 5,
                    }}
                    dataSource={coaches}
                    renderItem={(coach) => (
                        <List.Item key={coach.userId}>
                            <Card
                                className={styles.card}
                                cover={
                                    <div className={styles.imageHigh}>
                                        <img src={coach.photoUrl || avatar} alt={avatar} className={styles.image} />
                                    </div>
                                }
                            >
                                <Card.Meta
                                    title={
                                        <div>
                                            {coach.givenName} {coach.familyName}
                                        </div>
                                    }
                                    description={
                                        <div className={styles.coachTitle}>
                                            {<FormattedMessage id="roster.team.coach" />}
                                            <Button
                                                type="text"
                                                icon={<Icon component={removeSvg} />}
                                                className={styles.deleteButton}
                                                onClick={() => this.showDeleteCoachModal(true, coach)}
                                                hidden={
                                                    !rolesService.hasAnyRole(auth, [
                                                        'ROLE_ADMIN',
                                                        'ROLE_ORGANIZATION_OWNER',
                                                        'ROLE_ORGANIZATION_STAFF',
                                                    ])
                                                }
                                            ></Button>
                                        </div>
                                    }
                                />
                            </Card>
                        </List.Item>
                    )}
                    className={styles.teams}
                    loading={loading === 'loading'}
                />
            );
        } else {
            return <></>;
        }
    };

    renderPlayerCards = (teamMembers: TeamMemberFromTeam[]): React.ReactElement | undefined => {
        const { loading, team } = this.state;

        return (
            <List
                grid={{
                    gutter: 30,
                    xs: 2,
                    sm: 3,
                    md: 2,
                    lg: 3,
                    xl: 4,
                    xxl: 5,
                }}
                dataSource={teamMembers}
                renderItem={(teamMember) => (
                    <List.Item key={teamMember.id}>
                        <Card
                            className={styles.card}
                            cover={
                                <div className={styles.imageHigh}>
                                    <Link to={`/players/${teamMember.userId}?sportId=${team?.sportId}`}>
                                        <img
                                            src={teamMember.headshotUrl || avatar}
                                            alt={avatar}
                                            className={styles.image}
                                        />
                                    </Link>
                                </div>
                            }
                        >
                            <Card.Meta
                                title={
                                    <Link to={`/players/${teamMember.userId}?sportId=${team?.sportId}`}>
                                        <div>
                                            {teamMember.profile?.givenName} {teamMember.profile?.familyname}
                                        </div>
                                    </Link>
                                }
                                description={
                                    <div
                                        onClick={() =>
                                            this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')
                                                ? {}
                                                : this.showModalPlayer(teamMember)
                                        }
                                        className={styles.description}
                                    >
                                        <ul className={styles.stats}>
                                            {teamMember.profile?.grade ? (
                                                <li className={styles.place}>{teamMember.profile?.grade}</li>
                                            ) : (
                                                <></>
                                            )}
                                            {teamMember.profile?.height ? (
                                                <li className={styles.height}>
                                                    {metricService.toFormattedFeets(teamMember.profile?.height)}
                                                </li>
                                            ) : (
                                                <></>
                                            )}
                                            {teamMember.profile?.weight ? (
                                                <li className={styles.weight}>{teamMember.profile?.weight}</li>
                                            ) : (
                                                <></>
                                            )}
                                        </ul>

                                        <div className={styles.misc}>
                                            <div className={styles.number}>
                                                #
                                                {teamMember.jerseyNumber &&
                                                    teamMember.jerseyNumber.length > 0 &&
                                                    teamMember.jerseyNumber[0]}
                                            </div>
                                            <p>{teamMember.positions?.map((p) => p.code).join(',')}</p>
                                        </div>
                                    </div>
                                }
                            />
                        </Card>
                    </List.Item>
                )}
                className={styles.teams}
                loading={loading === 'loading'}
            />
        );
    };

    renderCoachList = (coaches: OrganizationGroupCoach[], desktop?: boolean): React.ReactElement | undefined => {
        const { loading } = this.state;

        if (coaches.length > 0) {
            return (
                <List
                    grid={{
                        gutter: 30,
                        xs: 2,
                        sm: 3,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 5,
                    }}
                    dataSource={coaches}
                    renderItem={(coach) => (
                        <List.Item key={coach.userId} className={styles.list}>
                            <List.Item.Meta
                                className={styles.card}
                                avatar={
                                    <div className={styles.imageHigh}>
                                        <img src={coach.photoUrl || avatar} alt={avatar} className={styles.image} />
                                    </div>
                                }
                            >
                                <Card.Meta
                                    title={
                                        <div>
                                            {coach.givenName} {coach.familyName}
                                            <Button
                                                type="text"
                                                icon={<Icon component={removeSvg} />}
                                                className={styles.deleteButton}
                                                onClick={() => this.showDeleteCoachModal(true, coach)}
                                            ></Button>
                                        </div>
                                    }
                                />
                            </List.Item.Meta>
                        </List.Item>
                    )}
                    className={styles.teams}
                    loading={loading === 'loading'}
                />
            );
        } else {
            return <></>;
        }
    };

    renderPlayerList = (teamMembers: TeamMemberFromTeam[], desktop?: boolean): React.ReactElement | undefined => {
        const { loading, team } = this.state;

        return (
            <List
                dataSource={teamMembers}
                renderItem={(teamMember) => (
                    <>
                        <List.Item key={teamMember.id} className={styles.list}>
                            <List.Item.Meta
                                avatar={
                                    <Avatar className={styles.image} size={84} src={teamMember.headshotUrl || avatar} />
                                }
                                description={
                                    <div className={styles.description}>
                                        <div className={styles.playerName}>
                                            <Link to={`/players/${teamMember.userId}?sportId=${team?.sportId}`}>
                                                <h3>
                                                    {teamMember.profile?.givenName} {teamMember.profile?.familyname}
                                                </h3>
                                            </Link>
                                            <div className={styles.misc}>
                                                <div className={styles.number}>
                                                    #
                                                    {teamMember.jerseyNumber &&
                                                        teamMember.jerseyNumber.length > 0 &&
                                                        teamMember.jerseyNumber[0]}
                                                </div>
                                                <p>{teamMember.positions?.map((p) => p.code).join(',')}</p>
                                            </div>
                                        </div>
                                        <div className={styles.playerStats}>
                                            <ul className={styles.stats}>
                                                <li className={styles.place}>{teamMember.profile?.grade}</li>
                                                <li className={styles.height}>
                                                    {metricService.toFormattedFeets(teamMember.profile?.height)}
                                                </li>
                                                <li className={styles.weight}>{teamMember.profile?.weight}</li>
                                            </ul>
                                            <Button
                                                size="large"
                                                shape="round"
                                                onClick={() => this.showModalPlayer(teamMember)}
                                                hidden={this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')}
                                            >
                                                <FormattedMessage id="roster.team.editPlayer" />
                                            </Button>
                                        </div>
                                    </div>
                                }
                            />
                        </List.Item>
                    </>
                )}
                className={styles.teams}
                loading={loading === 'loading'}
            />
        );
    };

    renderModals = (desktop: boolean): React.ReactElement | undefined => {
        const { teamMember, team } = this.state;
        const { teams, organizationId } = this.props;
        if (team && teamMember) {
            return (
                <TeamMemberModal
                    onCancel={() => this.showModalPlayer(undefined)}
                    team={team}
                    teamMemberFromTeam={teamMember}
                    organizationId={organizationId}
                    onUpdate={this.refresh}
                    teams={teams}
                    desktop={desktop}
                />
            );
        }
    };

    renderContent = (): React.ReactElement | undefined => {
        const { display, desktop, coaches } = this.props;
        const { team } = this.state;
        const filteredTeamMembers = this.listTeamMembers(team!);
        const filteredCoaches = this.listCoaches(coaches);

        if (team) {
            if (
                filteredTeamMembers &&
                /* filteredTeamMembers.length > 0 && */
                display === 'cards'
            ) {
                return (
                    <>
                        {this.renderCoachCards(filteredCoaches)}
                        {this.renderPlayerCards(filteredTeamMembers)}
                        {this.renderModals(desktop)}
                        {this.renderRemoveModal()}
                    </>
                );
            } else if (
                filteredTeamMembers &&
                /* filteredTeamMembers.length > 0 && */
                display === 'list'
            ) {
                return (
                    <>
                        {this.renderCoachList(filteredCoaches)}
                        {this.renderPlayerList(filteredTeamMembers)}
                        {this.renderModals(desktop)}
                        {this.renderRemoveModal()}
                    </>
                );
            }
        } else {
            return <></>;
        }
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(TeamComponent);

interface Props extends WrappedComponentProps {
    id: number;
    organizationId: string;
    team: Team;
    teams: Team[];
    coaches: OrganizationGroupCoach[];
    display: DisplayType;
    positionIds: number[];
    genders: GenderType[];
    grades: number[];
    searchText?: string;
    sortField?: SortingPlayerType;
    onUpdate: () => Promise<void>;
    desktop: boolean;
}

interface State {
    teamMember?: TeamMemberFromTeam;
    team?: Team;
    loading?: 'loading' | 'deleting';
    deleteCoachModalVisible?: boolean;
    coach?: OrganizationGroupCoach;
}
