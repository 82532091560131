import { Menu } from 'antd';
import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import CustomContext from '../../context/CustomContext';
import { CustomAuth } from '../../model/Entities';
import { PageType } from '../../model/Types';
import rolesService from '../../services/RolesService';
import AdminNavigationComponent from './AdminNavigationComponent/AdminNavigationComponent';
import CollegeCoachNavigationComponent from './CollegeCoachNavigationComponent/CollegeCoachNavigationComponent';
import AccountMenuComponent from './Common/AccountMenuComponent/AccountMenuComponent';
import OrganizationNavigationComponent from './OrganizationNavigationComponent/OrganizationNavigationComponent';
import ParentNavigationComponent from './ParentNavigationComponent/ParentNavigationComponent';
import PlayerNavigationComponent from './PlayerNavigationComponent/PlayerNavigationComponent';
import PublicNavigationComponent from './PublicNavigationComponent/PublicNavigationComponent';

class NavigationComponent extends React.Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /*** METHODS ***/

    /**
     * Returns if the player is editable for the current user.
     * @param auth - the current user
     * @param familyId - the family id
     * @returns if the player is editable
     */
    isPlayerEditable = (auth?: CustomAuth, familyId?: number, organizationIds?: string[]): boolean | undefined => {
        let editable: boolean | undefined;

        if (auth && auth.authorities.includes('ROLE_ADMIN')) {
            editable = true;
        } else if (auth && auth.id === this.props.userId) {
            editable = true;
        } else if (auth && auth.authorities.includes('ROLE_PARENT') && auth.familyId === familyId) {
            editable = true;
        } else if (
            auth &&
            rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_OWNER', 'ROLE_ORGANIZATION_STAFF']) &&
            organizationIds?.includes(auth.organizationId!)
        ) {
            editable = true;
        } else {
            editable = false;
        }

        return editable;
    };

    /*** COMPONENTS ***/

    renderNavigationMenu = (): React.ReactNode | undefined => {
        const { auth } = this.context;
        const { renderMenu, collapsed, userId, sportId, familyId, organizationIds } = this.props;

        const playerEditable = this.isPlayerEditable(auth, familyId, organizationIds);
        const menu = renderMenu && renderMenu(collapsed);

        if (auth && auth.authorities.includes('ROLE_ADMIN')) {
            return (
                <AdminNavigationComponent
                    menu={menu}
                    collapsed={collapsed}
                    playerEditable={playerEditable}
                    userId={userId}
                    sportId={sportId}
                />
            );
        } else if (auth && auth.authorities.includes('ROLE_ORGANIZATION')) {
            return (
                <OrganizationNavigationComponent
                    menu={menu}
                    collapsed={collapsed}
                    playerEditable={playerEditable}
                    userId={userId}
                    sportId={sportId}
                    familyId={familyId}
                    organizationIds={organizationIds}
                />
            );
        } else if (auth && auth.authorities.includes('ROLE_COLLEGE_COACH')) {
            return (
                <CollegeCoachNavigationComponent
                    menu={menu}
                    collapsed={collapsed}
                    playerEditable={playerEditable}
                    userId={userId}
                    sportId={sportId}
                    familyId={familyId}
                />
            );
        } else if (auth && auth.authorities.includes('ROLE_PLAYER')) {
            return (
                <PlayerNavigationComponent
                    menu={menu}
                    collapsed={collapsed}
                    playerEditable={playerEditable}
                    userId={userId}
                    sportId={sportId}
                />
            );
        } else if (auth && auth.authorities.includes('ROLE_PARENT')) {
            return (
                <ParentNavigationComponent
                    menu={menu}
                    collapsed={collapsed}
                    playerEditable={playerEditable}
                    userId={userId}
                    sportId={sportId}
                    familyId={familyId}
                />
            );
        } else {
            return (
                <PublicNavigationComponent
                    menu={menu}
                    collapsed={collapsed}
                    playerEditable={playerEditable}
                    userId={userId}
                    sportId={sportId}
                />
            );
        }
    };

    render() {
        const { page, collapsed } = this.props;
        const selectedKey = page ? [page] : undefined;

        return (
            <Menu
                theme="dark"
                defaultSelectedKeys={selectedKey}
                selectedKeys={selectedKey}
                mode="inline"
                inlineCollapsed={collapsed}
            >
                {this.renderNavigationMenu()}
                <AccountMenuComponent collapsed={collapsed} />
            </Menu>
        );
    }
}
export default injectIntl(withRouter(NavigationComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    userId?: string;
    sportId?: number;
    familyId?: number;
    organizationIds?: string[];
    renderMenu?: (collapsed: boolean) => React.ReactNode | undefined;
    page?: PageType;
    collapsed: boolean;
}
