import * as React from 'react';
import { useContext } from 'react';
import { Route, Switch } from 'react-router';
import CustomContext from '../../context/CustomContext';
import { authorities } from '../../model/Types';
import AffiliateCoachEditPage from '../../pages/AffiliateCoachProfilePage/AffiliateCoachEditPage/AffiliateCoachEditPage';
import AffiliateCoachProfilePage from '../../pages/AffiliateCoachProfilePage/AffiliateCoachProfilePage';
import AffiliateOwnerEditPage from '../../pages/AffiliateOwnerProfilePage/AffiliateOwnerEditPage/AffiliateOwnerEditPage';
import AffiliateOwnerProfilePage from '../../pages/AffiliateOwnerProfilePage/AffiliateOwnerProfilePage';
import AffiliateStaffEditPage from '../../pages/AffiliateStaffProfilePage/AffiliateStaffEditPage/AffiliateStaffEditPage';
import AffiliateStaffProfilePage from '../../pages/AffiliateStaffProfilePage/AffiliateStaffProfilePage';
import AdminAwardPage from '../../pages/Backend/AdminAwardsPage/AdminAwardPage/AdminAwardPage';
import AdminAwardsPage from '../../pages/Backend/AdminAwardsPage/AdminAwardsPage';
import AdminCollegePage from '../../pages/Backend/AdminCollegesPage/AdminCollegePage/AdminCollegePage';
import AdminCollegesPage from '../../pages/Backend/AdminCollegesPage/AdminCollegesPage';
import AdminOrganizationPage from '../../pages/Backend/AdminOrganizationsPage/AdminOrganizationPage/AdminOrganizationPage';
import AdminOrganizationsPage from '../../pages/Backend/AdminOrganizationsPage/AdminOrganizationsPage';
import CollegeCoachEditPage from '../../pages/CollegeCoachProfilePage/CollegeCoachEditPage/CollegeCoachEditPage';
import CollegeCoachProfilePage from '../../pages/CollegeCoachProfilePage/CollegeCoachProfilePage';
import CollegeConnectPage from '../../pages/CollegeConnectPage/CollegeConnectPage';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import EventCreationPage from '../../pages/EventsPage/EventCreationPage/EventCreationPage';
import EventLeaderboardPage from '../../pages/EventsPage/EventPage/EventLeaderboardPage/EventLeaderboardPage';
import EventLiveLeaderboardPage from '../../pages/EventsPage/EventPage/EventLiveLeaderboardPage/EventLiveLeaderboardPage';
import EventPage from '../../pages/EventsPage/EventPage/EventPage';
import EventPlayerPage from '../../pages/EventsPage/EventPage/EventPlayersPage/EventPlayerPage/EventPlayerPage';
import EventPlayersPage from '../../pages/EventsPage/EventPage/EventPlayersPage/EventPlayersPage';
import EventQRCodesPage from '../../pages/EventsPage/EventPage/EventQRCodesPage/EventQRCodesPage';
import EventSettingsPage from '../../pages/EventsPage/EventPage/EventSettingsPage/EventSettingsPage';
import EventsPage from '../../pages/EventsPage/EventsPage';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage/ForgotPasswordPage';
import AffiliateGroupPage from '../../pages/GroupsPage/AffiliateGroupPage/AffiliateGroupPage';
import FamilyGroupPage from '../../pages/GroupsPage/FamilyGroupPage/FamilyGroupPage';
import GroupsPage from '../../pages/GroupsPage/GroupsPage';
import InvoicesPage from '../../pages/InvoicesPage/InvoicesPage';
import LeaderboardPage from '../../pages/LeaderboardPage/LeaderboardPage';
import NotificationsPage from '../../pages/NotificationsPage/NotificationsPage';
import ParentEditPage from '../../pages/ParentProfilePage/ParentEditPage/ParentEditPage';
import ParentProfilePage from '../../pages/ParentProfilePage/ParentProfilePage';
import PlayerCodePage from '../../pages/PlayerCodePage/PlayerCodePage';
import PlayerDataPage from '../../pages/PlayerDataPage/PlayerDataPage';
import PlayerEditPage from '../../pages/PlayerEditPage/PlayerEditPage';
import PlayerMediaPage from '../../pages/PlayerMediaPage/PlayerMediaPage';
import PlayerProfilePage from '../../pages/PlayerProfilePage/PlayerProfilePage';
import ProspectsPage from '../../pages/ProspectsPage/ProspectsPage';
import OrganizationPage from '../../pages/OrganizationsPage/OrganizationPage/OrganizationPage';
import ImportUsersPage from '../../pages/OrganizationsPage/RosterPage/ManageRosterPage/ImportUsersPage/ImportUsersPage';
import ManageRosterPage from '../../pages/OrganizationsPage/RosterPage/ManageRosterPage/ManageRosterPage';
import RosterPage from '../../pages/OrganizationsPage/RosterPage/RosterPage';
import OrganizationsPage from '../../pages/OrganizationsPage/OrganizationsPage';
import SeasonsPage from '../../pages/SeasonsPage/SeasonsPage';
import SettingsPage from '../../pages/SettingsPage/SettingsPage';
import SignInPage from '../../pages/SignInPage/SignInPage';
import SignOutPage from '../../pages/SignOutPage/SignOutPage';
import PrivacyPolicyPage from '../../pages/SignUpPage/PrivacyPolicyPage/PrivacyPolicyPage';
import SignUpChildPage from '../../pages/SignUpPage/SignUpChildPage/SignUpChildPage';
import SignUpPage from '../../pages/SignUpPage/SignUpPage';
import SignUpPaymentPage from '../../pages/SignUpPage/SignUpPaymentPage/SignUpPaymentPage';
import TermsAndConditionsPage from '../../pages/SignUpPage/TermsAndConditionsPage/TermsAndConditionsPage';
import DefaultRoute from '../DefaultRoute/DefaultRoute';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';

export const RoutesComponent: React.FC = () => {
    const context = useContext(CustomContext);
    const { auth } = context;
    return (
        <Switch>
            {/*  All  */}
            <DefaultRoute exact path="/" />
            <Route exact path="/leaderboard" component={LeaderboardPage} />
            <Route exact path="/events" render={(props) => <EventsPage key="upcoming" {...props} type="Upcoming" />} />
            <Route
                exact
                path="/events/completed"
                render={(props) => <EventsPage key="completed" {...props} type="Completed" />}
            />
            <Route exact path="/events/live" render={(props) => <EventsPage key="live" {...props} type="Live" />} />
            <Route exact path="/events/:id/summary" component={EventPage} />
            <Route exact path="/events/:id/leaderboard" component={EventLeaderboardPage} />
            <Route exact path="/events/:id/leaderboard/live" component={EventLiveLeaderboardPage} />
            <ProtectedRoute exact path="/colleges" component={CollegeConnectPage} authorities={[...authorities]} />

            {/* College Coach */}
            <ProtectedRoute exact path="/prospects" component={ProspectsPage} authorities={['ROLE_COLLEGE_COACH']} />
            <ProtectedRoute
                exact
                path="/college-coaches/:id"
                component={CollegeCoachProfilePage}
                authorities={['ROLE_COLLEGE_COACH', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/college-coaches/:id/edit"
                component={CollegeCoachEditPage}
                authorities={['ROLE_COLLEGE_COACH', 'ROLE_ADMIN']}
            />

            {/* Parent */}
            <ProtectedRoute
                exact
                path="/parents/:id"
                component={ParentProfilePage}
                authorities={['ROLE_PARENT', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/parents/:id/edit"
                component={ParentEditPage}
                authorities={['ROLE_PARENT', 'ROLE_ADMIN']}
            />
            <ProtectedRoute exact path="/signup/child" component={SignUpChildPage} authorities={['ROLE_PARENT']} />
            <ProtectedRoute exact path="/signup/payment" component={SignUpPaymentPage} authorities={['ROLE_PARENT']} />

            {/*  Player  */}
            <Route
                exact
                path="/players/:id"
                component={
                    auth?.authorities.includes('ROLE_ADMIN') || auth?.authorities.includes('ROLE_COLLEGE_COACH')
                        ? PlayerProfilePage
                        : PlayerDataPage
                }
            />
            <ProtectedRoute
                exact
                path="/players/:id/profile"
                component={PlayerProfilePage}
                authorities={['ROLE_PLAYER', 'ROLE_ORGANIZATION', 'ROLE_ADMIN', 'ROLE_PARENT', 'ROLE_COLLEGE_COACH']}
            />
            <Route exact path="/players/:id/data" component={PlayerDataPage} />
            <ProtectedRoute
                exact
                path="/players/:id/edit"
                component={PlayerEditPage}
                authorities={['ROLE_PLAYER', 'ROLE_ORGANIZATION', 'ROLE_ADMIN', 'ROLE_PARENT']}
            />
            <Route exact path="/players/:id/media" component={PlayerMediaPage} />
            <ProtectedRoute
                exact
                path="/players/:id/code"
                component={PlayerCodePage}
                authorities={['ROLE_PLAYER', 'ROLE_ORGANIZATION', 'ROLE_ADMIN', 'ROLE_PARENT']}
            />

            {/*  Organization, Admin  */}
            <ProtectedRoute
                exact
                path="/organizations"
                component={OrganizationsPage}
                authorities={['ROLE_ADMIN', 'ROLE_COLLEGE_COACH']}
            />
            <ProtectedRoute exact path="/organizations/:id" component={OrganizationPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute
                exact
                path="/rosters/:id"
                component={RosterPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN', 'ROLE_COLLEGE_COACH']}
            />
            <ProtectedRoute
                exact
                path="/rosters/:organizationId/management"
                component={ManageRosterPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/rosters/:organizationId/import"
                component={ImportUsersPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/affiliate-owners/:id"
                component={AffiliateOwnerProfilePage}
                authorities={['ROLE_ORGANIZATION_OWNER', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/affiliate-owners/:id/edit"
                component={AffiliateOwnerEditPage}
                authorities={['ROLE_ORGANIZATION_OWNER', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/affiliate-staffs/:id"
                component={AffiliateStaffProfilePage}
                authorities={['ROLE_ORGANIZATION_STAFF', 'ROLE_ORGANIZATION_OWNER', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/affiliate-staffs/:id/edit"
                component={AffiliateStaffEditPage}
                authorities={['ROLE_ORGANIZATION_STAFF', 'ROLE_ORGANIZATION_OWNER', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/affiliate-coaches/:id"
                component={AffiliateCoachProfilePage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/affiliate-coaches/:id/edit"
                component={AffiliateCoachEditPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/events/:id/players"
                component={EventPlayersPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/events/:id/players/:userId"
                component={EventPlayerPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/events/:id/qr-codes"
                component={EventQRCodesPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/invoices/:id"
                component={InvoicesPage}
                authorities={['ROLE_ORGANIZATION_OWNER', 'ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/notifications"
                component={NotificationsPage}
                authorities={['ROLE_ORGANIZATION']}
            />

            {/*Admin */}
            <ProtectedRoute exact path="/seasons" component={SeasonsPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/groups" component={GroupsPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute
                exact
                path="/groups/families/:id"
                component={FamilyGroupPage}
                authorities={['ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/groups/affiliates/:id"
                component={AffiliateGroupPage}
                authorities={['ROLE_ORGANIZATION', 'ROLE_ADMIN']}
            />
            <ProtectedRoute exact path="/event-creation" component={EventCreationPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute
                exact
                path="/events/:id/settings"
                component={EventSettingsPage}
                authorities={['ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/admin/organizations"
                component={AdminOrganizationsPage}
                authorities={['ROLE_ADMIN']}
            />
            <ProtectedRoute
                exact
                path="/admin/organizations/:id"
                component={AdminOrganizationPage}
                authorities={['ROLE_ADMIN']}
            />
            <ProtectedRoute exact path="/admin/colleges" component={AdminCollegesPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute
                exact
                path="/admin/colleges/:id"
                component={AdminCollegePage}
                authorities={['ROLE_ADMIN']}
            />
            <ProtectedRoute exact path="/admin/awards" component={AdminAwardsPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute exact path="/admin/awards/:id" component={AdminAwardPage} authorities={['ROLE_ADMIN']} />
            <ProtectedRoute
                exact
                path="/admin/notifications"
                component={NotificationsPage}
                authorities={['ROLE_ADMIN']}
            />

            <ProtectedRoute
                exact
                path="/settings"
                component={SettingsPage}
                authorities={['ROLE_ADMIN', 'ROLE_ORGANIZATION']}
            />
            <Route exact path="/signup" component={SignUpPage} />
            <Route exact path="/terms-conditions" component={TermsAndConditionsPage} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route exact path="/signin" component={SignInPage} />
            <Route exact path="/forgot-password" component={ForgotPasswordPage} />
            <ProtectedRoute exact path="/signout" component={SignOutPage} />
            <Route exact path="/error" component={ErrorPage} />
            <Route component={ErrorPage} />
        </Switch>
    );
};
