import { Button, Form, FormInstance, message, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import divisionApi from '../../../../../apis/DivisionApi';
import eventApi from '../../../../../apis/EventApi';
import { Division, Event } from '../../../../../model/Entities';
import notificationService from '../../../../../services/NotificationService';
import styles from './AddGroupsModal.module.scss';

class AddGroupsModal extends Component<Props, State> {
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { divisions: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const divisions = await divisionApi.list(this.props.event.sportId!);

            this.setState({ divisions });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    save = async () => {
        const { event } = this.props;
        try {
            this.setState({ loading: `saving` });

            const values = await this.formRef.current?.validateFields();
            await eventApi.addGroups(event.id!, values.divisions);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderForm = (): React.ReactElement | undefined => {
        const { divisions } = this.state;

        const divisionOptions = divisions.map((division) => (
            <Select.Option key={division.id} value={division.id!}>
                {division.name}
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="events.event.athletes.groupsModal.selectGroup" />}
                    name="divisions"
                    className={styles.form}
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Select mode="multiple" className={styles.select}>{divisionOptions}</Select>
                </Form.Item>
            </Form>
        );
    };

    render() {
        const { loading } = this.state;

        return (
            <Modal
                title={<FormattedMessage id="events.event.athletes.groupsModal.title" />}
                className={styles.modal}
                width={600}
                visible={true}
                onCancel={this.props.onCancel}
                footer={[
                    <Button className={styles.btn} key="cancel" shape={'round'} onClick={this.props.onCancel}>
                        <FormattedMessage id="button.cancel" />
                    </Button>,
                    <Button
                        key="save"
                        shape={'round'}
                        type="primary"
                        className={styles.btn}
                        onClick={this.save}
                        loading={loading === 'saving'}
                    >
                        <FormattedMessage id="button.save" tagName="span" />
                    </Button>,
                ]}
            >
                {this.renderForm()}
            </Modal>
        );
    }
}
export default injectIntl(AddGroupsModal);

interface Props extends WrappedComponentProps {
    event: Event;
    onUpdate: () => void;
    onCancel: () => void;
}

interface State {
    loading?: 'initializing' | 'saving';
    divisions: Division[];
}
