import { Image, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import eventApi from '../../../../apis/EventApi';
import sportApi from '../../../../apis/SportApi';
import sportMetricApi from '../../../../apis/SportMetricApi';
import CustomContext from '../../../../context/CustomContext';
import { Event, LeaderboardLive, Result, Sport, SportCategory } from '../../../../model/Entities';
import { LeaderboardType } from '../../../../model/Types';
import avatar from '../../../../resources/images/profile-placeholder.png';
import metricService from '../../../../services/MetricService';
import notificationService from '../../../../services/NotificationService';
import numericService from '../../../../services/NumericService';
import styles from './EventLiveLeaderboardPage.module.scss';

class EventLiveLeaderboardPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    interval?: NodeJS.Timeout;
    readonly defaultPageSize = 10;

    constructor(props: Props) {
        super(props);
        this.state = { categoryIndex: 0, page: 0, leaders: [], sportCategories: [] };
    }

    componentDidMount() {
        this.init();
        this.interval = setInterval(() => this.refresh(), 10000);
    }

    componentWillUnmount() {
        this.interval && clearInterval(this.interval);
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const params = new URLSearchParams(this.props.location.search);
            const divisionId = params.get('divisionId') ? +params.get('divisionId')! : undefined;
            const leaderboardType = params.get('type') ? (params.get('type')! as LeaderboardType) : undefined;

            const event = await eventApi.get(+this.props.match.params.id);
            const sportCategories = await sportMetricApi.list(event.sportId!, leaderboardType);
            const sport = await sportApi.get(event.sportId);
            this.load(event, sportCategories, 0, leaderboardType!, undefined, divisionId);

            this.setState({ event, sport, divisionId, sportCategories, leaderboardType });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    refresh = async () => {
        const { event, sportCategories, categoryIndex, page, divisionId, leaderboardType } = this.state;
        if (event) {
            this.load(event, sportCategories, categoryIndex, leaderboardType!, page, divisionId);
        }
    };

    load = async (
        event: Event,
        sportCategories: SportCategory[],
        categoryIndex: number,
        leaderboardType: LeaderboardType,
        currentPage?: number,
        divisionId?: number,
    ) => {
        let newPage: number;
        let newCategoryIndex: number;
        const leaderboard = await eventApi.listLiveLeaderboard(event.id!, leaderboardType, divisionId);
        const athletes: LeaderboardLive[] = leaderboard.athletes ? leaderboard.athletes : [];
        const pages = Math.ceil(athletes.length! / this.defaultPageSize);

        if (currentPage === undefined) {
            const results = athletes.flatMap((u) =>
                u.measures.filter((m) =>
                    sportCategories.find((sc) => sc.metrics?.find((metric) => metric.id === m.metricId)),
                ),
            );
            newCategoryIndex = this.calculateNextCategoryIndex(categoryIndex, sportCategories, results);
            newPage = 0;
        }
        // paginate current category
        else if (currentPage < pages - 1) {
            // calculate category index and page
            newCategoryIndex = categoryIndex;
            newPage = currentPage + 1;
        }
        // display next category
        else {
            // calculate category index and page
            const results = athletes.flatMap((u) =>
                u.measures.filter((m) =>
                    sportCategories.find((sc) => sc.metrics?.find((metric) => metric.id === m.metricId)),
                ),
            );
            newCategoryIndex = this.calculateNextCategoryIndex(categoryIndex + 1, sportCategories, results);
            newPage = 0;
        }
        const orgLogo = leaderboard.organizationResponse?.leaderBoardLogo;

        const category = sportCategories[newCategoryIndex];

        const leaders = athletes
            .sort((a, b) => this.sortByRank(a, b, category))
            .slice(newPage * this.defaultPageSize, (newPage + 1) * this.defaultPageSize);

        this.setState({ categoryIndex: newCategoryIndex, leaders, page: newPage, orgLogo, category });
    };

    sortByRank = (a: LeaderboardLive, b: LeaderboardLive, category: SportCategory): number => {
        const scoreA = a.athlete.scores?.find((s) => s.categoryId === category?.id)?.rank || Infinity;
        const scoreB = b.athlete.scores?.find((s) => s.categoryId === category?.id)?.rank || Infinity;

        return numericService.sort(scoreB, scoreA);
    };

    calculateNextCategoryIndex = (
        categoryIndex: number,
        sportCategories: SportCategory[],
        results: Result[],
    ): number => {
        let newCategoryIndex = sportCategories.length === categoryIndex ? 0 : categoryIndex;
        const category = sportCategories[newCategoryIndex];
        const categoryResults = results.filter((r) => category.metrics?.find((m) => m.id === r.metricId!));

        if (results.length === 0) {
            newCategoryIndex = 0;
        } else if (categoryResults.length === 0) {
            newCategoryIndex = this.calculateNextCategoryIndex(newCategoryIndex + 1, sportCategories, results);
        }

        return newCategoryIndex;
    };

    getRank = (leaderboard: LeaderboardLive): number | undefined => {
        const { category } = this.state;

        return leaderboard.athlete.scores?.find((s) => s.categoryId === category?.id)?.rank;
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderLogo()}
                {this.renderTabs()}
            </>
        );
    };

    renderLogo = (): React.ReactElement | undefined => {
        const { orgLogo } = this.state;

        return (
            <div className={styles.head}>
                <div className={styles.orgLogo}>
                    <img src={orgLogo} alt="" />
                </div>
                <h1>
                    <FormattedMessage id="events.event.live.leaderboard.title" />
                </h1>
                <div className={styles.top100}>
                    <img src={process.env.PUBLIC_URL + '/img/top100-logo.png'} alt="" />
                </div>
            </div>
        );
    };

    renderTabs = (): React.ReactElement | undefined => {
        const { categoryIndex, sportCategories } = this.state;
        const sportCategory = sportCategories[categoryIndex];

        if (sportCategory) {
            return (
                <Tabs activeKey={sportCategory.id?.toString()} type="card" className={styles.tabs}>
                    {sportCategories.map((ss) => (
                        <Tabs.TabPane key={ss.id} tab={ss.name} className={styles.tab} disabled={true}>
                            {this.renderTable(ss)}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            );
        }
    };

    renderUnitOfMeasure = (metricId: number, result?: number): string | undefined => {
        const { sport } = this.state;
        if (result) {
            const code = sport?.stations?.flatMap((s) => s.metrics?.filter((m) => m.id === metricId))[0]?.unitOfMeasure
                ?.code;
            return metricService.abbreviate(code);
        }
    };

    renderResult = (
        leaderboard: LeaderboardLive,
        metricId: number,
        comparable?: boolean,
    ): React.ReactElement | undefined => {
        const result = leaderboard.measures.find((r) => r.metricId === metricId)?.result;
        if (result) {
            if (!comparable) {
                const hours = Math.round(result / 60);
                const minutes = Math.round(result % 60);
                return (
                    <>
                        {numericService.zeroPad(hours, 2)}
                        <FormattedMessage id=":" />
                        {numericService.zeroPad(minutes, 2)}
                        {this.renderUnitOfMeasure(metricId, result)}
                    </>
                );
            } else {
                return (
                    <>
                        <FormattedNumber value={result} minimumFractionDigits={2} maximumFractionDigits={2} />
                        {this.renderUnitOfMeasure(metricId, result)}
                    </>
                );
            }
        } else {
            return <></>;
        }
    };

    renderResultColumns = (sportCategory: SportCategory): ColumnsType<any> => {
        return sportCategory.metrics!.map((m) => ({
            title: m.name,
            key: m.id,
            align: 'center',
            sortDirections: ['descend'],
            render: (value: any, leaderboard: LeaderboardLive) => this.renderResult(leaderboard, m.id!, m.comparable),
        }));
    };

    renderTable = (sportCategory: SportCategory): React.ReactElement | undefined => {
        const { leaders, loading, event } = this.state;

        const columns: ColumnsType<LeaderboardLive> = [
            {
                title: <FormattedMessage id="player.rank" />,
                //dataIndex: ['athlete', 'scores', '0', 'rank'],
                key: 'rank',
                width: 102,
                render: (value: string, player: LeaderboardLive) => this.getRank(player),
            },
            {
                dataIndex: ['athlete', 'photoUrl'],
                key: 'photoUrl',
                width: 102,
                render: (value: any, player: LeaderboardLive) => (
                    <Link to={`/players/${player.athlete.userId}?sportId=${event?.sportId}`}>
                        <Image src={value || avatar} fallback={avatar} preview={false} />
                    </Link>
                ),
            },
            {
                title: <FormattedMessage id="player.name" />,
                dataIndex: ['athlete', 'givenName'],
                key: 'givenName',
                render: (value: string, player: LeaderboardLive) => (
                    <Link to={`/players/${player.athlete.userId}?sportId=${event?.sportId}`}>
                        {player.athlete.familyName}, {value}
                    </Link>
                ),
            },
            ...this.renderResultColumns(sportCategory),
        ];

        return (
            <>
                <Table
                    dataSource={leaders}
                    columns={columns}
                    pagination={false}
                    rowKey="id"
                    showSorterTooltip={false}
                    loading={loading}
                    className={styles.table}
                />
            </>
        );
    };

    render() {
        return (
            <div className="eventLiveLeaderboard gradient">
                <div className="bg"></div>
                {this.renderContent()}
            </div>
        );
    }
}
export default injectIntl(EventLiveLeaderboardPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    event?: Event;
    sport?: Sport;
    orgLogo?: string;
    categoryIndex: number;
    leaders: LeaderboardLive[];
    page: number;
    loading?: boolean;
    divisionId?: number;
    sportCategories: SportCategory[];
    leaderboardType?: LeaderboardType;
    category?: SportCategory;
}
