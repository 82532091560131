import * as React from 'react';
import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as eventsSvg } from '../../../resources/images/ico-events.svg';
import { ReactComponent as leaderboardSvg } from '../../../resources/images/ico-leaderboard.svg';
import PlayerMenuComponent from '../Common/PlayerMenuComponent/PlayerMenuComponent';
import CustomContext from '../../../context/CustomContext';

class PublicNavigationComponent extends React.Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /*** METHODS ***/

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    /*** COMPONENTS ***/

    renderPlayerMenu = (): React.ReactNode | undefined => {
        const { collapsed, userId, sportId, playerEditable } = this.props;
        if (userId && sportId) {
            return (
                <PlayerMenuComponent
                    userId={userId}
                    sportId={sportId}
                    divider={true}
                    editable={playerEditable}
                    collapsed={collapsed}
                />
            );
        }
    };

    render() {
        const { menu, collapsed } = this.props;

        if (menu) {
            return menu;
        } else {
            return (
                <>
                    {this.renderPlayerMenu()}

                    <Menu.ItemGroup
                        key="public-top100"
                        title={!collapsed && <FormattedMessage id="navigation.public.top100" />}
                    >
                        <Menu.Item
                            key="leaderboard"
                            icon={<Icon component={leaderboardSvg} />}
                            onClick={() => this.navigate('/leaderboard')}
                        >
                            <Link to="/leaderboard">
                                <FormattedMessage id="navigation.public.top100.leaderboard" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="events"
                            icon={<Icon component={eventsSvg} />}
                            onClick={() => this.navigate('/events')}
                        >
                            <Link to="/events">
                                <FormattedMessage id="navigation.public.top100.events" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </>
            );
        }
    }
}
export default injectIntl(withRouter(PublicNavigationComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    menu?: React.ReactNode;
    collapsed: boolean;
    playerEditable?: boolean;
    userId?: string;
    sportId?: number;
}
