import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, message, Modal, Select, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import mediaApi from '../../../apis/MediaApi';
import CustomContext from '../../../context/CustomContext';
import { Sport, User, UserSport } from '../../../model/Entities';
import ga from '../../../services/GoogleAnalytics';
import notificationService from '../../../services/NotificationService';
import styles from './AddMediaComponent.module.scss';

class AddMediaComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    update = async () => {
        const { user } = this.props;
        const { file } = this.state;
        try {
            this.setState({ loading: true });

            const values = await this.formRef.current?.validateFields();

            if (file) {
                if (/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(file.name)) {
                    await mediaApi.uploadPhoto(user?.id!, file as File, values.sportId);
                    ga.createDefaultEvent('player upload media', 'player upload media - upload photo success');
                } else if (/\.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i.test(file.name)) {
                    await mediaApi.uploadVideoWithoutMetric(user?.id!, file as File, values.sportId);
                    ga.createDefaultEvent('player upload media', 'player upload media - upload video success');
                } else if (/\.(pdf|xls|xlsx|doc|docx|csv)$/i.test(file.name)) {
                    await mediaApi.uploadFile(user?.id!, file as File, values.sportId!);
                    ga.createDefaultEvent('player upload media', 'player upload media - upload file success');
                } else {
                    message.error(this.props.intl.formatMessage({ id: 'status.invalidMedia' }), 6);
                }
            }

            this.formRef.current?.resetFields();
            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
                ga.createDefaultEvent('player upload media', 'player upload media - error');
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    uploadVideo = (file: UploadFile) => {
        this.getBase64(file, (video: string) =>
            this.setState({
                file,
            }),
        );
        return false;
    };

    getBase64 = (img: any, callback?: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    /*** COMPONENTS ***/

    render() {
        const { loading } = this.state;
        const { userSports, sports } = this.props;

        const userSportsOptions = userSports?.map((us) => (
            <Select.Option key={us.id!} value={us.sportId!}>
                {sports?.find((s) => s.id === us.sportId)?.name}
            </Select.Option>
        ));

        return (
            <Modal
                width={790}
                visible={true}
                onCancel={this.cancel}
                onOk={this.update}
                okText={<FormattedMessage id="button.save" tagName="span" />}
                okButtonProps={{ loading: loading }}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item name="upload" className="antUpload">
                        <Upload.Dragger beforeUpload={this.uploadVideo}>
                            <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />}></Button>
                        </Upload.Dragger>
                        {<FormattedMessage id="player.media.upload" />}
                    </Form.Item>
                    <Form.Item
                        name="sportId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select
                            placeholder={<FormattedMessage id="player.media.selectSport" />}
                            className={styles.sports}
                            size="large"
                        >
                            {userSportsOptions}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(AddMediaComponent);

interface Props extends WrappedComponentProps {
    onUpdate: () => Promise<void>;
    onCancel: () => void;
    user?: User;
    userSports?: UserSport[];
    sports?: Sport[];
}

interface State {
    file?: any;
    loading?: boolean;
}
