import { Page } from '../model/Elements';
import { Measure, Metric } from '../model/Entities';
import apiAxios from './CustomAxios';

class MetricApi {
    list = async (
        userId: string,
        limit: number,
        page: number,
        sortField?: string,
        sortOrder?: string,
    ): Promise<Page<Metric>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const sortFieldName = this.getSortField(sortField);
        const response = await apiAxios.get<Page<Metric>>(`/v1/users/${userId}/measures`, {
            params: { userId, limit, page, [sortFieldName]: order },
        });

        return response.data;
    };

    get = async (userId: string, metricId: number): Promise<Metric> => {
        const response = await apiAxios.get<Metric>(`/v1/users/${userId}/measures/${metricId}`);
        response.data.measures?.forEach((i) => (i.date = i.date ? i.date * 1000 : undefined));
        return response.data;
    };

    update = async (measure: Measure, userId: string, sportId: number): Promise<Metric> => {
        measure.date = measure.date ? Math.round(measure.date / 1000) : undefined;
        const response = await apiAxios.put<Metric>(
            `/v1/users/${userId}/sports/${sportId}/results/${measure.id}`,
            measure,
        );
        return response.data;
    };

    delete = async (metric: Metric, userId: string): Promise<void> => {
        await apiAxios.delete(`/v1/users/${userId}/measures/${metric.id}`);
    };

    create = async (measure: Measure, userId: string): Promise<Metric> => {
        measure.date = measure.date ? Math.round(measure.date / 1000) : undefined;
        const response = await apiAxios.post<Metric>(`/v1/users/${userId}/measures/single`, measure);
        return response.data;
    };

    private getSortField(sortField?: string): string {
        let field: string = 'sorting.VerifiedById';
        if (!sortField) {
            return field;
        }
        if (sortField === 'verifiedById') {
            field = 'sorting.VerifiedById';
        } else if (sortField === 'createdById') {
            field = 'sorting.CreatedById';
        } else if (sortField === 'createdAt') {
            field = 'sorting.CreatedAt';
        }
        return field;
    }
}
const metricApi: MetricApi = new MetricApi();
export default metricApi;
