import Icon from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Image, Input, message, Row, Select, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import collegeApi from '../../../../apis/CollegeApi';
import collegeDivisionApi from '../../../../apis/CollegeDivisionApi';
import sportApi from '../../../../apis/SportApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { College, CollegeDivision, Sport } from '../../../../model/Entities';
import defaultCollegeLogo from '../../../../resources/images/College-Connect-Default-School-Icon.png';
import { ReactComponent as DeleteSvg } from '../../../../resources/images/ico-delete.svg';
import { ReactComponent as SaveSvg } from '../../../../resources/images/ico-save.svg';
import HeadMetadata from '../../../../services/HeadMetadata';
import notificationService from '../../../../services/NotificationService';
import numericService from '../../../../services/NumericService';
import CollegeCoachesComponent from './CollegeCoachesComponent/CollegeCoachesComponent';
import styles from './AdminCollegePage.module.scss';

class AdminCollegePage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { collegeDivisions: [], sports: [], coachesSports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            if (this.props.match.params.id === 'new') {
                await this.new();
            } else {
                await this.get(+this.props.match.params.id);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    new = async () => {
        const college: College = {};
        const responses = await Promise.all([collegeDivisionApi.list(), sportApi.listAll()]);
        const collegeDivisions = responses[0];
        const sports = responses[1];

        this.setState({ college, collegeDivisions, sports });
        this.formRef.current!.setFieldsValue(college);
    };

    get = async (id: number) => {
        const responses = await Promise.all([collegeApi.get(id), collegeDivisionApi.list(), sportApi.listAll()]);
        const college = responses[0];
        const collegeDivisions = responses[1];
        const sports = responses[2];
        const photo = college.logo;

        this.setState({ college, photo, collegeDivisions, sports });
        this.formRef.current!.setFieldsValue(college);
    };

    save = async (values: any) => {
        const { file } = this.state;
        try {
            this.setState({ loading: 'saving' });
            let college: College = Object.assign({}, this.state.college, values);
            college = college.id ? await collegeApi.update(college) : await collegeApi.create(college);

            // upload photo
            if (file) {
                await collegeApi.uploadLogo(college.id!, file as File);
            }

            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ college });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'college.status.duplicate' },
            ]);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    delete = async () => {
        const { college } = this.state;
        try {
            this.setState({ loading: 'deleting' });
            await collegeApi.delete(college!);
            this.props.history.push(`/admin/colleges`);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    uploadPhoto = (file: UploadFile) => {
        this.getBase64(file, (photo: string) =>
            this.setState({
                photo,
                file,
            }),
        );
        return false;
    };

    getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    changeCoachesSports = (coachesSports: number[]) => {
        this.setState({ coachesSports });
    };

    refresh = async () => {
        try {
            this.setState({ loading: 'loading' });

            const college = await collegeApi.get(this.state.college?.id);

            this.formRef.current?.setFieldsValue(college);

            this.setState({ college });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { loading, photo, college, collegeDivisions, sports, coachesSports } = this.state;

        const divisionOptions = collegeDivisions
            .sort((a, b) => numericService.sort(b.id, a.id))
            .map((d) => (
                <Select.Option key={d.id} value={d.id!}>
                    {d.name}
                </Select.Option>
            ));

        const sportOptions = sports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!} disabled={coachesSports.some((cs) => cs === sport.id)}>
                {sport.name}
            </Select.Option>
        ));

        return (
            <>
                {this.renderToolbar()}
                <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical">
                    <Row gutter={[28, 0]} className={styles.formRow}>
                        <Col xs={24} md={12}>
                            <div className={styles.photo}>
                                <Form.Item name="logo" className={styles.image}>
                                    {college && (
                                        <Image
                                            src={photo || defaultCollegeLogo}
                                            fallback={defaultCollegeLogo}
                                            preview={false}
                                        />
                                    )}
                                </Form.Item>
                                <Upload.Dragger
                                    beforeUpload={this.uploadPhoto}
                                    showUploadList={false}
                                    className={styles.upload}
                                >
                                    <FormattedMessage id="college.upload" />
                                </Upload.Dragger>
                            </div>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="college.school" />}
                                name="name"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                className={styles.name}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]} className={styles.formRow}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="college.division" />}
                                name="divisions"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select mode="multiple">{divisionOptions}</Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={<FormattedMessage id="college.athleticWebsite" />} name="athletesUrl">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]} className={styles.formRow}>
                        <Col xs={24} md={12}>
                            <Form.Item label={<FormattedMessage id="college.staffDirectory" />} name="campsUrl">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={<FormattedMessage id="college.admissionLink" />} name="admissionsUrl">
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]} className={styles.formRow}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="college.sports" />}
                                name="sportIds"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select mode="multiple">{sportOptions}</Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    {college?.id && (
                        <CollegeCoachesComponent
                            college={college}
                            loading={loading}
                            sports={sports}
                            update={this.changeCoachesSports}
                            updateCollege={this.refresh}
                        />
                    )}

                    <div className={styles.buttons}>
                        <Link to="/admin/colleges">
                            <Button size="large" className={styles.back}>
                                <FormattedMessage id="college.back" />
                            </Button>
                        </Link>
                        {this.state.college?.id && (
                            <Button
                                size="large"
                                icon={<Icon component={DeleteSvg} />}
                                className={styles.delete}
                                onClick={() => this.delete()}
                            >
                                <FormattedMessage id="college.delete" />
                            </Button>
                        )}
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className={styles.save}
                            icon={<Icon component={SaveSvg} />}
                            loading={loading === 'saving'}
                        >
                            <FormattedMessage id="college.save" />
                        </Button>
                    </div>
                </Form>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        if (this.state.college?.id) {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="colleges.edittitle" />
                    </h1>
                </div>
            );
        } else {
            return (
                <div className={styles.toolbar}>
                    <h1>
                        <FormattedMessage id="colleges.addtitle" />
                    </h1>
                </div>
            );
        }
    };

    render() {
        const { loading } = this.state;
        return (
            <>
                <HeadMetadata titleKey="college.meta.title" />
                <LayoutComponent
                    page="admin-colleges"
                    content={this.renderContent}
                    loading={loading === 'initializing'}
                />
            </>
        );
    }
}
export default injectIntl(AdminCollegePage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    college?: College;
    photo?: string;
    file?: any;
    loading?: 'initializing' | 'saving' | 'deleting' | 'loading';
    collegeDivisions: CollegeDivision[];
    sports: Sport[];
    coachesSports: number[];
}
