import { Page } from '../model/Elements';
import { OrganizationUser } from '../model/Entities';
import apiAxios from './CustomAxios';

class OrganizationUserApi {
    list = async (
        limit: number,
        page: number,
        organizationId: string,
        search?: string,
        sportId?: number,
    ): Promise<Page<OrganizationUser>> => {
        const filteringSportId = 'filtering.SportId';

        const response = await apiAxios.get<Page<OrganizationUser>>(`/v1/organizations/${organizationId}/players`, {
            params: { limit, page, [filteringSportId]: sportId, search },
        });

        return response.data;
    };
}
const organizationUserApi: OrganizationUserApi = new OrganizationUserApi();
export default organizationUserApi;
