import { Page } from '../model/Elements';
import { Invoice } from '../model/Entities';
import apiAxios from './CustomAxios';

class InvoiceApi {
    list = async (organizationId: string, limit?: number, page?: number): Promise<Page<Invoice>> => {
        const response = await apiAxios.get<Page<Invoice>>(`/v1/organizations/${organizationId}/invoices`, {
            params: { limit, page },
        });

        response.data.items.forEach((i) => (i.date ? (i.date = i.date * 1000) : undefined));
        response.data.items.forEach((i) => (i.dueDate ? (i.dueDate = i.dueDate * 1000) : undefined));

        return response.data;
    };
}
const invoiceApi: InvoiceApi = new InvoiceApi();
export default invoiceApi;
