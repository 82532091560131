import Icon from '@ant-design/icons';
import { Divider, Menu, message, Modal } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import CustomContext from '../../../../context/CustomContext';
import { AuthorityType } from '../../../../model/Types';
import { ReactComponent as settingsSvg } from '../../../../resources/images/ico-settings.svg';
import { ReactComponent as signinSvg } from '../../../../resources/images/ico-signin.svg';
import { ReactComponent as signoutSvg } from '../../../../resources/images/ico-signout.svg';
import ga from '../../../../services/GoogleAnalytics';
import styles from './AccountMenuComponent.module.scss';

class AccountMenuComponent extends React.Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /*** METHODS ***/

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    showSignOut = () => {
        const { intl } = this.props;

        Modal.confirm({
            title: intl.formatMessage({ id: 'signOut.title' }),
            onOk: this.signOut,
        });
    };

    signOut = async (): Promise<void> => {
        const { intl } = this.props;

        try {
            ga.createDefaultEvent('sign out', 'sign out - signing out');
            this.props.history.push('/signout');
        } catch (error: any) {
            if (error.message) {
                message.error(error.message, 6);
                ga.createDefaultEvent('sign out', `sign out - ${error.message}`);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
                ga.createDefaultEvent('sign out', 'sign out - sign out internal error');
            }
        }
    };

    /*** COMPONENTS ***/

    render() {
        const { auth } = this.context;
        const { collapsed } = this.props;

        return (
            <>
                <Divider className={styles.divider} />

                <Menu.ItemGroup key="account" title={!collapsed && <FormattedMessage id="navigation.account" />}>
                    {auth && auth.authorities.some((a: AuthorityType) => a === 'ROLE_ADMIN') && (
                        <Menu.Item
                            key="settings"
                            icon={<Icon component={settingsSvg} />}
                            onClick={() => this.navigate('/settings')}
                        >
                            <Link to="/settings">
                                <FormattedMessage id="navigation.account.settings" />
                            </Link>
                        </Menu.Item>
                    )}
                    {auth && (
                        <Menu.Item key="signout" icon={<Icon component={signoutSvg} />} onClick={this.showSignOut}>
                            <FormattedMessage id="navigation.account.signOut" />
                        </Menu.Item>
                    )}
                    {!auth && (
                        <Menu.Item
                            key="signin"
                            icon={<Icon component={signinSvg} />}
                            onClick={() => this.navigate('/signin')}
                        >
                            <Link to="/signin">
                                <FormattedMessage id="navigation.account.signIn" />
                            </Link>
                        </Menu.Item>
                    )}
                </Menu.ItemGroup>
            </>
        );
    }
}
export default injectIntl(withRouter(AccountMenuComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    collapsed: boolean;
}
