import Icon from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Menu,
    message,
    Modal,
    Row,
    Select,
    Switch,
} from 'antd';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, RouteComponentProps } from 'react-router-dom';
import eventApi from '../../../../apis/EventApi';
import locationApi from '../../../../apis/LocationApi';
import organizationSportsApi from '../../../../apis/OrganizationSportsApi';
import sportApi from '../../../../apis/SportApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Event, EventRegistration, Location, NewEvent, OrganizationSport, Sport } from '../../../../model/Entities';
import { countries, CountryType, timeZones } from '../../../../model/Types';
import { ReactComponent as backSvg } from '../../../../resources/images/ico-back.svg';
import { ReactComponent as eventSettingsSvg } from '../../../../resources/images/ico-eventSettings.svg';
import { ReactComponent as eventLeaderboardSvg } from '../../../../resources/images/ico-leaderboard.svg';
import { ReactComponent as positionsSvg } from '../../../../resources/images/ico-positions.svg';
import { ReactComponent as eventSummarySvg } from '../../../../resources/images/ico-summary.svg';
import logo from '../../../../resources/images/top100-logo.png';
import HeadMetadata from '../../../../services/HeadMetadata';
import notificationService from '../../../../services/NotificationService';
import stateService from '../../../../services/StateService';
import styles from './EventSettingsPage.module.scss';

class EventSettingsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { sports: [], organizations: [], locations: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const responses = await Promise.all([
                sportApi.listAll(),
                locationApi.list(),
                eventApi.get(+this.props.match.params.id),
            ]);
            const sports = responses[0];
            const locations = responses[1];
            const event = responses[2];
            const startDate = event.startDate ? moment.utc(event.startDate).local() : undefined;
            const endDate = event.endDate ? moment.utc(event.endDate).local() : undefined;

            const sport = sports.find((s) => s.id === event.sportId);
            const organizations = await organizationSportsApi.listAll(undefined, sport!.id!);
            const country = event.country;

            const generalPrice = event.registrationTypes?.find((r) => r.type === 'Global')?.price;
            const catchingPrice = event.registrationTypes?.find((r) => r.type === 'Catching')?.price;
            const pitchingPrice = event.registrationTypes?.find((r) => r.type === 'Pitching')?.price;

            const catching = catchingPrice !== undefined ? true : false;
            const pitching = pitchingPrice !== undefined ? true : false;

            this.formRef.current?.setFieldsValue({
                ...event,
                startDate: startDate,
                endDate: endDate,
                generalPrice: generalPrice,
                catchingPrice: catchingPrice,
                pitchingPrice: pitchingPrice,
            });

            this.setState({ sports, locations, event, sport, organizations, country, catching, pitching });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    save = async (values: any) => {
        const { catching, pitching, event } = this.state;
        try {
            this.setState({ loading: 'saving' });

            if (
                (values.generalPrice > 0 &&
                    ((catching && (!values.catchingPrice || values.catchingPrice === 0)) ||
                        (pitching && (!values.pitchingPrice || values.pitchingPrice === 0)))) ||
                (values.catchingPrice > 0 &&
                    (!values.generalPrice ||
                        values.generalPrice === 0 ||
                        (pitching && (!values.pitchingPrice || values.pitchingPrice === 0)))) ||
                (values.pitchingPrice > 0 &&
                    ((catching && (!values.catchingPrice || values.catchingPrice === 0)) ||
                        !values.generalPrice ||
                        values.generalPrice === 0))
            ) {
                message.error(this.props.intl.formatMessage({ id: 'eventCreation.status.freeEvent' }));
            } else {
                const updatedEvent: NewEvent = Object.assign({}, values);

                const generalRegistration: EventRegistration = { type: 'Global', price: values.generalPrice || 0 };
                const catchingRegistration: EventRegistration = { type: 'Catching', price: values.catchingPrice || 0 };
                const pitchingRegistration: EventRegistration = { type: 'Pitching', price: values.pitchingPrice || 0 };
                updatedEvent.registrationTypes = [];
                updatedEvent.registrationTypes.push(generalRegistration);
                catching && updatedEvent.registrationTypes.push(catchingRegistration);
                pitching && updatedEvent.registrationTypes.push(pitchingRegistration);

                updatedEvent.startDate = values.startDate.valueOf();
                updatedEvent.endDate = values.endDate
                    ? values.endDate.valueOf() === event?.endDate
                        ? event?.endDate
                        : values.endDate.clone().endOf('day').valueOf()
                    : undefined;

                await eventApi.update(event?.id!, updatedEvent);
                message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

                this.props.history.push(`/events/${event?.id}/summary`);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteEvent = async () => {
        const { event } = this.state;
        try {
            this.setState({ loading: 'deleting' });

            await eventApi.delete(event!);
            message.success(this.props.intl.formatMessage({ id: 'status.deleted' }));

            this.props.history.push('/events');
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeSport = async (sportId: number) => {
        const { sports } = this.state;
        const sport = sports.find((s) => s.id === sportId);
        const responses = await Promise.all([organizationSportsApi.listAll(undefined, sport!.id!)]);
        const organizations = responses[0];
        this.formRef.current?.resetFields(['organizationId', 'catchingPrice', 'pitchingPrice', 'timeZone']);
        this.setState({ sport, organizations, catching: false, pitching: false });
    };

    changeLocation = async (locationId: number) => {
        const { locations } = this.state;
        const location = locations.find((l) => l.id === locationId);

        this.formRef.current?.setFieldsValue({
            country: location?.country,
            state: location?.state,
            city: location?.city,
            address: location?.address,
            zipCode: location?.postalCode,
        });

        const country = location?.country;

        this.setState({ country, location });
    };

    changeCountry = (country?: CountryType) => {
        this.formRef.current?.resetFields(['state']);
        this.setState({ country });
    };

    changeCatching = () => {
        const catching = !this.state.catching;
        this.formRef.current?.resetFields(['catchingPrice']);
        this.setState({ catching });
    };

    changePitching = () => {
        const pitching = !this.state.pitching;
        this.formRef.current?.resetFields(['pitchingPrice']);
        this.setState({ pitching });
    };

    changeStartDate = (startDate: Moment | null) => {
        this.formRef.current?.setFieldsValue({ endDate: startDate });
    };

    changeAffiliate = (orgId: string) => {
        const { organizations } = this.state;
        const timeZone = organizations.find((o) => o.organization?.id === orgId)?.organization?.timeZone;
        this.formRef.current?.setFieldsValue({ timeZone: timeZone });
    };

    showDeleteEventModal = (deleteEventModalVisible: boolean) => {
        this.setState({ deleteEventModalVisible });
    };

    /*** COMPONENTS ***/
    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { event, sports } = this.state;

        if (desktop) {
            return (
                <div className={styles.header}>
                    <div className={styles.col}>
                        <div className={styles.sport}>
                            {<img src={sports.find((s) => s.id === event?.sportId)?.iconUrl} alt={logo}></img>}{' '}
                            {sports.find((s) => s.id === event?.sportId)?.name}
                        </div>
                        <h1>{event?.name}</h1>
                    </div>
                    <div className={styles.col}>
                        <h2>
                            <FormattedMessage id="signup.dateTime" />
                        </h2>
                        <p>
                            <FormattedDate value={event?.startDate} weekday="short" />,{' '}
                            <FormattedDate value={event?.startDate} month="short" />{' '}
                            <FormattedDate value={event?.startDate} day="2-digit" />,{' '}
                            <FormattedDate value={event?.startDate} year="numeric" />,{' '}
                            <FormattedDate value={event?.startDate} hour="2-digit" minute="2-digit" />
                        </p>
                    </div>
                </div>
            );
        } else {
            <></>;
        }
    };
    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { auth } = this.context;

        const isAthletesMenuVisible = auth?.authorities.includes('ROLE_ADMIN');
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="event-dashboard"
                        icon={<Icon component={backSvg} />}
                        onClick={() => this.props.history.push('/events')}
                    >
                        <Link to="/events">
                            <FormattedMessage id="events.event.backToEvents" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                <Menu.ItemGroup key="event-menu" title={!collapsed && <FormattedMessage id="events.event.info" />}>
                    <Menu.Item
                        key="event"
                        icon={<Icon component={eventSummarySvg} />}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/summary`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/summary`}>
                            <FormattedMessage id="events.event.summary" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-settings"
                        icon={<Icon component={eventSettingsSvg} />}
                        hidden={!isAthletesMenuVisible}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/settings`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/settings`}>
                            <FormattedMessage id="events.event.settings" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="leaderboard"
                        icon={<Icon component={eventLeaderboardSvg} />}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/leaderboard`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/leaderboard`}>
                            <FormattedMessage id="events.event.leaderboard" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-players"
                        icon={<Icon component={positionsSvg} />}
                        hidden={!isAthletesMenuVisible}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/players`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/players`}>
                            <FormattedMessage id="events.event.athletes" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    };

    renderRemoveModal = (): React.ReactElement | undefined => {
        const { deleteEventModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="eventSettings.modal.title" />}
                visible={deleteEventModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.deleteEvent}
                okButtonProps={{ loading: loading === 'deleting' }}
                onCancel={() => this.showDeleteEventModal(false)}
            >
                <p>
                    <FormattedMessage id="eventSettings.modal.description" />
                </p>
            </Modal>
        );
    };

    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderForm = (): React.ReactElement | undefined => {
        const { loading, sports, sport, organizations, locations, catching, pitching, event } = this.state;

        const sportOptions = sports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));

        const organizationOptions = organizations.map((org) => (
            <Select.Option key={org.organization?.id} value={org.organization?.id!}>
                {org.organization?.name}
            </Select.Option>
        ));

        const locationOptions = locations.map((location) => (
            <Select.Option key={location.id} value={location.id!}>
                {location.name}
            </Select.Option>
        ));

        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));

        const timeZoneOptions = timeZones.map((t) => (
            <Select.Option key={t} value={t}>
                <FormattedMessage id={t} />
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical" className={styles.form}>
                <h1>
                    <FormattedMessage id="eventSettings.eventSettings" />
                </h1>
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={18} md={24}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.name" />}
                            name="name"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.sport" />}
                            name="sportId"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select onChange={(value: number) => this.changeSport(value)} disabled={event?.hasPlayers}>
                                {sportOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={16}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.organization" />}
                            name="organizationId"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                disabled={sport === undefined || event?.hasPlayers}
                                onChange={(value: string) => this.changeAffiliate(value)}
                            >
                                {organizationOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <h2>
                    <FormattedMessage id="eventCreation.location" />
                </h2>
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={18} md={16}>
                        <Form.Item label={<FormattedMessage id="eventCreation.eventLocation" />} name="location">
                            <Select onChange={(value: number) => this.changeLocation(value)} allowClear>
                                {locationOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={18} md={14}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.streetAddress" />}
                            name="address"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.city" />}
                            name="city"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={12} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.country" />}
                            name="country"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select onChange={(value: CountryType) => this.changeCountry(value)}>
                                {countryOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.state" />}
                            name="state"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select>{this.renderStates()}</Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={6}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.zip" />}
                            name="zipCode"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="large" />
                        </Form.Item>
                    </Col>
                </Row>

                <h2>
                    <FormattedMessage id="eventCreation.dateAndTime" />
                </h2>
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={18} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.startDate" />}
                            name="startDate"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            event?.status !== 'Upcoming' ||
                                            !value ||
                                            moment().add(-1, 'second') < value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            <FormattedMessage id="eventSettings.status.startDateInvalid" />,
                                        );
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                size="large"
                                use12Hours={true}
                                showTime={{ format: 'hh:mm A' }}
                                format="MM/DD/YYYY hh:mm A"
                                disabledDate={(current) => current.isBefore(moment().add(-1, 'second'))}
                                onChange={this.changeStartDate}
                                minuteStep={15}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={18} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.timeZone" />}
                            name="timeZone"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select>{timeZoneOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={18} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="eventCreation.endDate" />}
                            name="endDate"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            event?.status === 'Upcoming' ||
                                            !value ||
                                            getFieldValue('startDate') <= value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            <FormattedMessage id="eventSettings.status.endDateInvalid" />,
                                        );
                                    },
                                }),
                            ]}
                        >
                            <DatePicker
                                size="large"
                                format="MM/DD/YYYY"
                                disabledDate={(current) =>
                                    current.isBefore(this.formRef.current?.getFieldValue('startDate'))
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <h2>
                    <FormattedMessage id="eventCreation.registration" />
                </h2>
                <Row gutter={[28, 0]} className={styles.labels}>
                    <Col xs={13} sm={8} md={10}>
                        <label>
                            <FormattedMessage id="eventCreation.registrationType" />
                        </label>
                    </Col>
                    <Col xs={11} sm={8} md={6}>
                        <label>
                            <FormattedMessage id="eventCreation.price" />
                        </label>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={13} sm={8} md={10} className={styles.switcherCol}>
                        <FormattedMessage id="eventCreation.general" />
                        <Switch checked={true} disabled={event?.hasPlayers} />
                    </Col>
                    <Col xs={11} sm={8} md={6}>
                        <Form.Item name="generalPrice">
                            <InputNumber
                                type="number"
                                min={0}
                                maxLength={100}
                                size="large"
                                disabled={event?.hasPlayers}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {sport?.code !== 'LACROSSE' && (
                    <>
                        <Row gutter={[28, 0]}>
                            <Col xs={13} sm={8} md={10} className={styles.switcherCol}>
                                <FormattedMessage id="eventCreation.catching" />
                                <Switch
                                    onChange={() => this.changeCatching()}
                                    disabled={event?.hasPlayers}
                                    checked={catching}
                                />
                            </Col>
                            <Col xs={11} sm={8} md={6}>
                                <Form.Item name="catchingPrice">
                                    <InputNumber
                                        type="number"
                                        min={0}
                                        maxLength={100}
                                        size="large"
                                        className={styles.number}
                                        disabled={!catching || event?.hasPlayers}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[28, 0]}>
                            <Col xs={13} sm={8} md={10} className={styles.switcherCol}>
                                <FormattedMessage id="eventCreation.pitching" />
                                <Switch
                                    onChange={() => this.changePitching()}
                                    disabled={event?.hasPlayers}
                                    checked={pitching}
                                />
                            </Col>
                            <Col xs={11} sm={8} md={6}>
                                <Form.Item name="pitchingPrice">
                                    <InputNumber
                                        type="number"
                                        min={0}
                                        maxLength={100}
                                        size="large"
                                        className={styles.number}
                                        disabled={!pitching || event?.hasPlayers}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                <h2>
                    <FormattedMessage id="eventCreation.eventDetails" />
                </h2>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={24} className={styles.wysiwyg}>
                        <Form.Item name="description" initialValue="">
                            <ReactQuill />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[28, 0]}>
                    <Col xs={24} md={24} className={styles.buttons}>
                        <Link to={`/events/${event?.id}/summary`}>
                            <Button size="large" className={styles.btnSecondary}>
                                <FormattedMessage id="button.cancel" />
                            </Button>
                        </Link>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            className={styles.btnMain}
                            loading={loading === 'saving'}
                        >
                            <FormattedMessage id="eventSettings.button.update" tagName="span" />
                        </Button>
                        <Button
                            size="large"
                            className={styles.btnDelete}
                            onClick={() => this.showDeleteEventModal(true)}
                        >
                            <FormattedMessage id="eventSettings.button.delete" tagName="span" />
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { event, sports } = this.state;
        return (
            <>
                <div className={styles.renderMobile}>
                    <div className={styles.header}>
                        <div className={styles.col}>
                            <div className={styles.sport}>
                                {<img src={sports.find((s) => s.id === event?.sportId)?.iconUrl} alt={logo}></img>}{' '}
                                {sports.find((s) => s.id === event?.sportId)?.name}
                            </div>
                            <h1>{event?.name}</h1>
                        </div>
                        <div className={styles.col}>
                            <h2>
                                <FormattedMessage id="signup.dateTime" />
                            </h2>
                            <p>
                                <FormattedDate value={event?.startDate} weekday="short" />,{' '}
                                <FormattedDate value={event?.startDate} month="short" />{' '}
                                <FormattedDate value={event?.startDate} day="2-digit" />,{' '}
                                <FormattedDate value={event?.startDate} year="numeric" />,{' '}
                                <FormattedDate value={event?.startDate} hour="2-digit" minute="2-digit" />
                            </p>
                        </div>
                    </div>
                </div>
                {this.renderForm()} {this.renderRemoveModal()}
            </>
        );
    };

    render() {
        const { loading } = this.state;
        return (
            <>
                <HeadMetadata titleKey="eventSettings.meta.title" />
                <LayoutComponent
                    page="event-settings"
                    header={this.renderHeader}
                    content={this.renderContent}
                    menu={this.renderMenu}
                    loading={loading === 'initializing'}
                />
            </>
        );
    }
}
export default injectIntl(EventSettingsPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    loading?: 'initializing' | 'saving' | 'deleting';
    sports: Sport[];
    event?: Event;
    sport?: Sport;
    organizations: OrganizationSport[];
    locations: Location[];
    country?: CountryType;
    catching?: boolean;
    pitching?: boolean;
    location?: Location;
    deleteEventModalVisible?: boolean;
}
