import { Button, Col, Form, message, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import collegeDivisionApi from '../../../apis/CollegeDivisionApi';
import eventApi from '../../../apis/EventApi';
import notificationApi from '../../../apis/NotificationApi';
import organizationApi from '../../../apis/OrganizationApi';
import sportApi from '../../../apis/SportApi';
import {
    CollegeDivision,
    CollegeNotification,
    Event,
    EventNotification,
    Notification,
    Organization,
    OrganizationNotification,
    Role,
    Sport,
} from '../../../model/Entities';
import communicationService from '../../../services/CommunicationService';
import notificationService from '../../../services/NotificationService';
import stringService from '../../../services/StringService';
import SummaryModal from '../Components/SummaryModal/SummaryModal';
import DivisionTeamsFormList from '../Components/DivisionTeamsFormList/DivisionTeamsFormList';
import RecipientsAndContentFormItems from '../Components/RecipientsAndContentFormItems/RecipientsAndContentFormItems';
import styles from './AdminNotificationComponent.module.scss';

/**
 * Returns the Admin notification component.
 * @returns the Admin notification component.
 */
const AdminNotificationComponent: React.FunctionComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [sports, setSports] = useState<Sport[]>([]);
    const [sportIds, setSportIds] = useState<number[]>([]);
    const [organizationIds, setOrganizationIds] = useState<string[]>([]);
    const [subscriber, setSubscriber] = useState<SubscriberAdminType>();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [events, setEvents] = useState<Event[]>([]);
    const [collegeDivisions, setCollegeDivisions] = useState<CollegeDivision[]>([]);
    const [loading, setLoading] = useState<'loading'>();
    const [allowedRoles, setAllowedRoles] = useState<number[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [summaryModalVisible, setSummaryModalVisible] = useState<boolean>();

    /*** EFFECTS ***/

    // init
    useEffect(() => {
        const init = () => {
            try {
                form.setFieldsValue({ app: true });
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [form, intl]);

    // load sports
    useEffect(() => {
        const loadSports = async () => {
            try {
                if (subscriber === 'Organizations' || subscriber === 'Colleges') {
                    const sports = await sportApi.listAll();

                    setSports(sports);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }
            form.resetFields(['sportIds', 'event', 'organizations']);
        };
        loadSports();
    }, [form, intl, subscriber]);

    // load organizations
    useEffect(() => {
        const loadOrganizations = async () => {
            try {
                if (subscriber === 'Organizations') {
                    const organizations = await organizationApi.listAll();

                    setOrganizations(organizations);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }
            form.resetFields(['organizations', 'event']);
        };
        loadOrganizations();
    }, [form, intl, subscriber]);

    // load events
    useEffect(() => {
        const loadEvents = async () => {
            try {
                if (subscriber === 'Events') {
                    const eventsPage = await eventApi.list(1000, 1);
                    const events = eventsPage.items;

                    setEvents(events);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }

            form.resetFields(['sportIds', 'event', 'organizations']);
        };
        loadEvents();
    }, [form, intl, subscriber]);

    // load college divisions
    useEffect(() => {
        const loadCollegeDivisions = async () => {
            try {
                if (subscriber === 'Colleges') {
                    const collegeDivisions = await collegeDivisionApi.list();

                    setCollegeDivisions(collegeDivisions);

                    form.setFieldsValue({ roles: [8] });
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }

            form.resetFields(['sportIds', 'event', 'organizations']);
        };
        loadCollegeDivisions();
    }, [form, intl, subscriber]);

    // load allowed users
    useEffect(() => {
        const loadAllowedUsers = async () => {
            const allowedUsers = listAllowedRolesByType(subscriber);
            setAllowedRoles(allowedUsers);
        };
        loadAllowedUsers();
    }, [subscriber]);

    /*** METHODS ***/

    const save = async () => {
        try {
            setLoading('loading');
            const values = await form.validateFields();

            if (values.subscriber === 'Organizations') {
                const notification: OrganizationNotification = {
                    roles: { roleIds: [] },
                    divisions: { division: [] },
                    sports: { sportIds: [] },
                    organizations: { organizationIds: [] },
                };

                // set sports
                if (values.sportIds && values.sportIds.length > 0) {
                    notification.sports.all = false;
                    notification.sports.sportIds = values.sportIds;
                } else {
                    notification.sports.all = true;
                }

                // set division teams
                if (values.divisions && values.divisions.length > 0) {
                    notification.divisions.all = false;
                    notification.divisions.division = values.divisions.map((d: any) => ({
                        divisionId: d.divisionId,
                        teams: {
                            all: !d.teamIds || d.teamIds.length === 0,
                            teamIds: d.teamIds,
                        },
                    }));
                } else {
                    notification.divisions.all = true;
                }

                // set organizations
                if (values.organizations && values.organizations.length > 0) {
                    notification.organizations.all = false;
                    notification.organizations.organizationIds = values.organizations;
                } else {
                    notification.organizations.all = true;
                }

                // set recipients and content
                communicationService.loadNotificationRoles(notification, values);
                communicationService.loadNotificationRecipientsAndContent(notification, values);

                // save notification
                await notificationApi.createOrganizationNotification(notification);
            } else if (values.subscriber === 'Events') {
                const notification: EventNotification = {
                    roles: { roleIds: [] },
                    eventDivisions: { eventDivisionIds: [] },
                };

                // event
                const eventId = values.event;

                // set division (always all)
                notification.eventDivisions.all = true;

                // set recipients and content
                communicationService.loadNotificationRoles(notification, values);
                communicationService.loadNotificationRecipientsAndContent(notification, values);

                // save notification
                await notificationApi.createEventNotification(eventId, notification);
            } else if (values.subscriber === 'Colleges') {
                const notification: CollegeNotification = {
                    sports: { sportIds: [] },
                    collegeDivisions: { collegeDivisionIds: [] },
                };

                // set sports
                if (values.sportIds && values.sportIds.length > 0) {
                    notification.sports.all = false;
                    notification.sports.sportIds = values.sportIds;
                } else {
                    notification.sports.all = true;
                }

                // set college divisions
                if (values.collegeDivisions && values.collegeDivisions.length > 0) {
                    notification.collegeDivisions.all = false;
                    notification.collegeDivisions.collegeDivisionIds = values.collegeDivisions;
                } else {
                    notification.collegeDivisions.all = true;
                }

                // set recipients and content
                communicationService.loadNotificationRecipientsAndContent(notification, values);

                // save notification
                await notificationApi.createCollegeNotification(notification);
            } else {
                const notification: Notification = {};

                // set recipients and content
                communicationService.loadNotificationRecipientsAndContent(notification, values);

                // save notification
                await notificationApi.createNotification(notification);
            }

            // Success messages
            if (form.getFieldValue('scheduleSending')) {
                message.success(intl.formatMessage({ id: 'notifications.scheduled' }));
            } else {
                message.success(intl.formatMessage({ id: 'notifications.sent' }));
            }

            // reset form
            form.resetFields();
            form.setFieldsValue({ subscriber: setSubscriber(undefined), app: true });

            setSummaryModalVisible(false);
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, intl);
            }
        } finally {
            setLoading(undefined);
        }
    };

    const changeSports = async (sportIds: number[]) => {
        setSportIds(sportIds);
        setOrganizationIds([]);
        const organizations = await organizationApi.listAll(sportIds);
        setOrganizations(organizations);
        form.resetFields(['organizations', 'roles']);
    };

    const changeType = async (subscriber: SubscriberAdminType) => {
        setSubscriber(subscriber);
        setSportIds([]);
        setOrganizationIds([]);
    };

    /*** COMPONENTS ***/

    let subscriberOptions = subscribers.map((subscriber) => (
        <Select.Option key={subscriber} value={subscriber}>
            <FormattedMessage id={`adminNotifications.subscribers.${subscriber}`} />
        </Select.Option>
    ));

    let sportOptions = sports.map((s) => (
        <Select.Option key={s.id} value={s.id!}>
            {s.name}
        </Select.Option>
    ));

    let organizationOptions = organizations.map((organization) => (
        <Select.Option key={organization?.id} value={organization?.id!}>
            {organization?.name}
        </Select.Option>
    ));

    let eventsOptions = events.map((e) => (
        <Select.Option key={e.id} value={e.id!}>
            {e.name}
        </Select.Option>
    ));

    let collegeDivisionsOptions = collegeDivisions?.map((collegeDivision) => (
        <Select.Option key={collegeDivision.id} value={collegeDivision.id!}>
            <FormattedMessage id={collegeDivision.name} />
        </Select.Option>
    ));

    return (
        <Form
            form={form}
            onFinish={() => setSummaryModalVisible(true)}
            colon={false}
            layout="vertical"
            className={styles.form}
        >
            <h2>
                <FormattedMessage id="notifications.subscribers" />
            </h2>

            <Row gutter={[28, 0]}>
                <Col xs={24} sm={18} md={12}>
                    <Form.Item label={<FormattedMessage id="notifications.type" />} name="subscriber">
                        <Select
                            allowClear
                            className={`placeholder-select ${styles.select}`}
                            placeholder={<FormattedMessage id="notifications.all" />}
                            onChange={(value: SubscriberAdminType) => changeType(value)}
                        >
                            {subscriberOptions}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {subscriber === 'Organizations' && (
                <>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} sm={18} md={12}>
                            <Form.Item label={<FormattedMessage id="notifications.sport" />} name="sportIds">
                                <Select
                                    mode="multiple"
                                    className={`placeholder-select ${styles.multioption}`}
                                    placeholder={<FormattedMessage id="notifications.all" />}
                                    onChange={(values: number[]) => changeSports(values)}
                                    disabled={!sports || sports.length === 0}
                                >
                                    {sportOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col xs={24} sm={24} md={24}>
                            <Form.Item
                                label={<FormattedMessage id="notifications.organization" />}
                                name="organizations"
                            >
                                <Select
                                    className={`placeholder-select ${styles.multioption}`}
                                    mode="multiple"
                                    placeholder={<FormattedMessage id="notifications.all" />}
                                    onChange={(value: string[]) => setOrganizationIds(value)}
                                    disabled={!organizations || organizations.length === 0}
                                >
                                    {organizationOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}
            {subscriber === 'Organizations' && sportIds.length === 1 && organizationIds.length === 1 && (
                <Row gutter={[28, 0]}>
                    <Col xs={24} span={24}>
                        <DivisionTeamsFormList
                            form={form}
                            sportId={sportIds[0]}
                            organizationId={organizationIds[0]}
                            minimum={0}
                        />
                    </Col>
                </Row>
            )}
            {subscriber === 'Events' && (
                <Row gutter={[28, 0]}>
                    <Col xs={24} sm={18} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="notifications.event" />}
                            name="event"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                className={styles.select}
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="notifications.selectEvent" />}
                                disabled={!events || events.length === 0}
                            >
                                {eventsOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            )}

            {subscriber === 'Colleges' && (
                <>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} sm={18} md={12}>
                            <Form.Item label={<FormattedMessage id="notifications.sport" />} name="sportIds">
                                <Select
                                    className={`placeholder-select ${styles.multioption}`}
                                    mode="multiple"
                                    placeholder={<FormattedMessage id="notifications.all" />}
                                    onChange={(values: number[]) => changeSports(values)}
                                    disabled={!sports || sports.length === 0}
                                >
                                    {sportOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col xs={24} span={24}>
                            <Form.Item label={<FormattedMessage id="notifications.division" />} name="collegeDivisions">
                                <Select
                                    className={`placeholder-select ${styles.multioption}`}
                                    mode="multiple"
                                    placeholder={<FormattedMessage id="notifications.all" />}
                                    disabled={!collegeDivisions || collegeDivisions.length === 0}
                                >
                                    {collegeDivisionsOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            )}

            <RecipientsAndContentFormItems form={form} allowedRoleIds={allowedRoles} updateRoles={setRoles} />

            <Row gutter={[28, 0]} align="middle">
                <Col xs={24} md={24} className={styles.buttons}>
                    <Button type="primary" htmlType="submit" size="large">
                        <FormattedMessage id="button.send" tagName="span" />
                    </Button>
                </Col>
            </Row>

            {summaryModalVisible && (
                <SummaryModal
                    modalType="Admin"
                    loading={loading}
                    save={save}
                    subscriber={subscriber}
                    sportIds={sportIds}
                    organizationIds={organizationIds}
                    eventId={form.getFieldValue('event')}
                    collegeDivisionIds={form.getFieldValue('collegeDivisions')}
                    roles={roles}
                    roleIds={form.getFieldValue('roles')}
                    divisionIds={
                        form.getFieldValue('divisions')
                            ? form.getFieldValue('divisions').flatMap((d: any) => d.divisionId)
                            : []
                    }
                    teamIds={
                        form.getFieldValue('divisions')
                            ? form.getFieldValue('divisions').flatMap((d: any) => d.teamIds)
                            : []
                    }
                    email={form.getFieldValue('email')}
                    scheduleSending={form.getFieldValue('scheduleSending')}
                    subject={form.getFieldValue('subject')}
                    body={form.getFieldValue('body')}
                    sendTime={form.getFieldValue('sendTime')}
                    timeZone={form.getFieldValue('timeZone')}
                    onUpdate={setSummaryModalVisible}
                />
            )}
        </Form>
    );
};

export default AdminNotificationComponent;

export const subscribers = ['Organizations', 'Events', 'Colleges'] as const;
export type SubscriberAdminType = typeof subscribers[number];

/**
 * Returns the list of roles allowed by type
 * @param subscriber the subscriber
 * @returns the list of roles allowed by type
 */
const listAllowedRolesByType = (subscriber?: SubscriberAdminType): number[] => {
    if (subscriber === 'Organizations' || subscriber === 'Events') {
        return [1, 2, 3, 4, 7];
    } else if (subscriber === 'Colleges') {
        return [8];
    } else {
        return [];
    }
};
