class StringService {
    normalize = (text?: string): string => {
        return text
            ? text
                  .normalize('NFD')
                  .replace(/\p{Diacritic}/gu, '')
                  .toLocaleLowerCase()
            : '';
    };

    filterOptions = (inputValue: string, option?: any): boolean => {
        let optionValue: string = option?.children;
        if (option && Array.isArray(option.children)) {
            optionValue = option.children.join();
        }
        const optionLabel = stringService.normalize(optionValue);
        const searchText = stringService.normalize(inputValue);

        return optionLabel.includes(searchText);
    };

    search = (value: string | undefined, searchText: string | undefined): boolean => {
        return !searchText || stringService.normalize(value).includes(stringService.normalize(searchText));
    };

    sort = (a: string | undefined, b: string | undefined): number => {
        const textA = a || '';
        const textB = b || '';
        return stringService.normalize(textA).localeCompare(stringService.normalize(textB));
    };

    sortByNumber = (a: string | undefined, b: string | undefined): number => {
        const numberA: number = a && /^\d+$/.test(a) ? parseInt(a) : -1;
        const numberB: number = b && /^\d+$/.test(b) ? parseInt(b) : -1;
        return numberA - numberB;
    };

    getName = (notAbbreviate: boolean, givenName?: string, familyName?: string): string => {
        let fullName: string;
        if (notAbbreviate) {
            fullName = [givenName, familyName].join(' ');
        } else {
            const abbreviatedFirstName = givenName && givenName.substring(0, 1).concat('.');
            fullName = [abbreviatedFirstName, familyName].join(' ');
        }
        return fullName;
    };
}

const stringService: StringService = new StringService();
export default stringService;
