import { Tabs } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import HeadMetadata from '../../services/HeadMetadata';
import rolesService from '../../services/RolesService';
import AdminNotificationComponent from './AdminNotificationComponent/AdminNotificationComponent';
import HistoryComponent from './HistoryComponent/HistoryComponent';
import styles from './NotificationsPage.module.scss';
import OrganizationCoachNotificationComponent from './OrganizationCoachNotificationComponent/OrganizationCoachNotificationComponent';
import OrganizationOwnerNotificationComponent from './OrganizationOwnerNotificationComponent/OrganizationOwnerNotificationComponent';
import ScheduledNotificationsComponent from './ScheduledNotificationsComponent/ScheduledNotificationsComponent';

/**
 * Returns the notifications page.
 * @returns the notifications page.
 */
const NotificationsPage = (): React.ReactElement => {
    /** HOOKS */

    /** METHODS **/

    /*** COMPONENTS ***/

    return (
        <>
            <HeadMetadata titleKey="notifications.meta.title" />
            <LayoutComponent
                page="notifications"
                content={(desktop: boolean) => <NotificationsTabsComponent desktop={desktop} />}
            />
        </>
    );
};
export default NotificationsPage;

const NotificationsTabsComponent = (props: TabsProps): React.ReactElement => {
    /** HOOKS */
    const [displayMode, setDisplayMode] = useState<'notification' | 'scheduledNotifications'>('notification');

    /*** COMPONENTS ***/
    return (
        <>
            <h1>
                <FormattedMessage id="notifications.notifications" />
            </h1>
            <Tabs
                activeKey={displayMode}
                className={styles.tabs}
                onTabClick={(value: any, e) => setDisplayMode(value)}
                destroyInactiveTabPane
            >
                <Tabs.TabPane
                    tab={<FormattedMessage id="notifications.newNotification" />}
                    key="notification"
                    className={styles.tab}
                >
                    <NotificationComponent />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<FormattedMessage id="notifications.scheduledNotifications" />}
                    key="scheduledNotifications"
                    className={styles.tab}
                >
                    <ScheduledNotificationsComponent />
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<FormattedMessage id="notifications.history" />}
                    key="history"
                    className={styles.tab}
                >
                    <HistoryComponent />
                </Tabs.TabPane>
            </Tabs>
        </>
    );
};

interface TabsProps {
    desktop?: boolean;
}

const NotificationComponent = (): React.ReactElement => {
    /** HOOKS */
    const context = useContext(CustomContext);
    const { auth } = context;

    /*** COMPONENTS ***/
    if (rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
        return <AdminNotificationComponent />;
    } else if (rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_OWNER', 'ROLE_ORGANIZATION_STAFF'])) {
        return <OrganizationOwnerNotificationComponent />;
    } else if (rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_COACH'])) {
        return <OrganizationCoachNotificationComponent />;
    } else {
        return <></>;
    }
};
