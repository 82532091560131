import FileSaver from 'file-saver';
import qs from 'qs';
import { Page } from '../model/Elements';
import { Leaderboard } from '../model/Entities';
import { CountryFilterType, LeaderboardType } from '../model/Types';
import apiAxios from './CustomAxios';

class LeaderboardApi {
    list = async (
        limit: number,
        page: number,
        sportId: number,
        top100Index?: string,
        orderingMetricId?: number,
        positions?: number[],
        gender?: string,
        divisionIds?: number[],
        gradYear?: number,
        sortByGradYear?: string,
        country?: CountryFilterType,
        seasonId?: number,
        type?: LeaderboardType,
        organizationId?: string,
    ): Promise<Page<Leaderboard>> => {
        let byGradYear, top100IndexSorting;
        if (sortByGradYear) {
            byGradYear = sortByGradYear === 'ascend' ? 'Asc' : 'Desc';
        }
        if (top100Index) {
            top100IndexSorting = top100Index === 'ascend' ? 'Asc' : 'Desc';
        }
        const response = await apiAxios.get<Page<Leaderboard>>(`/v1/leaderboards/national`, {
            params: {
                limit,
                page,
                sportId,
                top100IndexSorting,
                orderingMetricId,
                positions,
                gender,
                divisionIds,
                gradYear,
                byGradYear,
                country,
                seasonId,
                type,
                organizationId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        return response.data;
    };

    exportLeaderboardToExcel = async (
        sportId: number,
        top100Index?: string,
        orderingMetricId?: number,
        positions?: number[],
        gender?: string,
        divisionIds?: number[],
        gradYear?: number,
        sortByGradYear?: string,
        country?: CountryFilterType,
        seasonId?: number,
        leaderboardType?: LeaderboardType,
        organizationId?: string,
    ): Promise<void> => {
        let byGradYear, top100IndexSorting;
        if (sortByGradYear) {
            byGradYear = sortByGradYear === 'ascend' ? 'Asc' : 'Desc';
        }
        if (top100Index) {
            top100IndexSorting = top100Index === 'ascend' ? 'Asc' : 'Desc';
        }
        const type = leaderboardType ? leaderboardType : 'global';

        const response = await apiAxios.get<Blob>(`/v1/leaderboards/national/export`, {
            responseType: 'blob',
            params: {
                sportId,
                top100IndexSorting,
                orderingMetricId,
                positions,
                gender,
                divisionIds,
                gradYear,
                byGradYear,
                country,
                seasonId,
                type,
                organizationId,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        FileSaver.saveAs(response.data, 'International-Leaderboard.xlsx');
    };
}

const leaderboardApi: LeaderboardApi = new LeaderboardApi();
export default leaderboardApi;
