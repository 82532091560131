import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Row } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import gameStatisticsApi from '../../../../apis/GameStatisticsApi';
import CustomContext from '../../../../context/CustomContext';
import { ProspectPlayerGame } from '../../../../model/Entities';
import notificationService from '../../../../services/NotificationService';
import styles from './AddGameModal.module.scss';

class AddGameModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            if (this.props.playerGameStatistics) {
                const values: any = Object.assign({}, this.props.playerGameStatistics);
                values.date = moment.utc(values.date).local();
                this.formRef.current!.setFieldsValue(values);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    add = async () => {
        try {
            this.setState({ loading: 'adding' });

            const values = await this.formRef.current?.validateFields();
            const playerGameStatistics: ProspectPlayerGame = Object.assign({}, values);
            playerGameStatistics.date = values.date ? values.date.valueOf() : undefined;
            await gameStatisticsApi.create(this.props.userId, this.props.seasonId, playerGameStatistics);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    update = async () => {
        const { playerGameStatistics } = this.props;
        try {
            this.setState({ loading: 'adding' });

            const values = await this.formRef.current?.validateFields();
            const playerGameStatisticsAux: ProspectPlayerGame = Object.assign({}, playerGameStatistics, values);
            playerGameStatisticsAux.date = values.date ? values.date.valueOf() : undefined;
            await gameStatisticsApi.update(this.props.userId, this.props.seasonId, playerGameStatisticsAux);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    delete = async () => {
        const { playerGameStatistics } = this.props;
        try {
            this.setState({ loading: 'deleting' });

            await gameStatisticsApi.delete(this.props.userId, this.props.seasonId, playerGameStatistics?.id!);
            message.success(this.props.intl.formatMessage({ id: 'status.deleted' }));

            this.props.onUpdate();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { playerGameStatistics } = this.props;
        const { loading } = this.state;
        return (
            <Modal
                title={
                    playerGameStatistics ? (
                        <FormattedMessage id="gameStatistics.editGame" />
                    ) : (
                        <FormattedMessage id="gameStatistics.addGame" />
                    )
                }
                width={800}
                visible={true}
                onCancel={this.props.onCancel}
                footer={[
                    playerGameStatistics && (
                        <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            onClick={this.delete}
                            loading={loading === 'deleting'}
                        >
                            <FormattedMessage id="button.delete" tagName="span" />
                        </Button>
                    ),
                    <Button onClick={this.props.onCancel}>
                        <FormattedMessage id="button.cancel" />
                    </Button>,
                    playerGameStatistics ? (
                        <Button type="primary" onClick={this.update} loading={loading === 'adding'}>
                            <FormattedMessage id="button.update" tagName="span" />
                        </Button>
                    ) : (
                        <Button type="primary" onClick={this.add} loading={loading === 'adding'}>
                            <FormattedMessage id="button.add" tagName="span" />
                        </Button>
                    ),
                ]}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row align="middle" gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={<FormattedMessage id="gameStatistics.date" />}
                                name="date"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <DatePicker
                                    size="small"
                                    format="MM-DD-YYYY"
                                    disabledDate={(current) => current.isAfter(moment())}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={<FormattedMessage id="gameStatistics.opponent" />}
                                name="opponent"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <div className={styles.data}>
                                <Form.Item label={<FormattedMessage id="gameStatistics.ab" />} name="atBats">
                                    <InputNumber type="number" min={0} max={999} className="stepHidden" precision={0} />
                                </Form.Item>

                                <Form.Item label={<FormattedMessage id="gameStatistics.r" />} name="run">
                                    <InputNumber type="number" min={0} max={999} className="stepHidden" precision={0} />
                                </Form.Item>

                                <Form.Item label={<FormattedMessage id="gameStatistics.h" />} name="hits">
                                    <InputNumber type="number" min={0} max={999} className="stepHidden" precision={0} />
                                </Form.Item>

                                <Form.Item label={<FormattedMessage id="gameStatistics.rbi" />} name="runsBattedIn">
                                    <InputNumber type="number" min={0} max={999} className="stepHidden" precision={0} />
                                </Form.Item>

                                <Form.Item label={<FormattedMessage id="gameStatistics.bb" />} name="baseOnBalls">
                                    <InputNumber type="number" min={0} max={999} className="stepHidden" precision={0} />
                                </Form.Item>

                                <Form.Item label={<FormattedMessage id="gameStatistics.k" />} name="strikeouts">
                                    <InputNumber type="number" min={0} max={999} className="stepHidden" precision={0} />
                                </Form.Item>

                                <Form.Item label={<FormattedMessage id="gameStatistics.avg" />} name="battingAverage">
                                    <InputNumber type="number" min={0} max={1} className="stepHidden" precision={3} />
                                </Form.Item>

                                <Form.Item label={<FormattedMessage id="gameStatistics.obp" />} name="onBasePercentage">
                                    <InputNumber type="number" min={0} max={1} className="stepHidden" precision={3} />
                                </Form.Item>

                                <Form.Item label={<FormattedMessage id="gameStatistics.slg" />} name="slugging">
                                    <InputNumber type="number" min={0} max={1} className="stepHidden" precision={3} />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(AddGameModal);

interface Props extends WrappedComponentProps {
    onUpdate: () => void;
    onCancel: () => void;
    userId: string;
    seasonId: number;
    playerGameStatistics?: ProspectPlayerGame;
}

interface State {
    loading?: 'initializing' | 'adding' | 'deleting';
}
