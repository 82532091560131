import { EditOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Menu, message, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import collegeApi from '../../apis/CollegeApi';
import collegeCoachApi from '../../apis/CollegeCoachApi';
import sportApi from '../../apis/SportApi';
import userApi from '../../apis/UserApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import UserBackgroundComponent from '../../components/UserBackgroundComponent/UserBackgroundComponent';
import CustomContext from '../../context/CustomContext';
import { College, CollegeCoach, Sport, UserAccount } from '../../model/Entities';
import avatar from '../../resources/images/profile-placeholder.png';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import stringService from '../../services/StringService';
import CollegeCoachModal from './CollegeCoachModal/CollegeCoachModal';
import styles from './CollegeCoachProfilePage.module.scss';

class CollegeCoachProfilePage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { collegeCoaches: [], sports: [] };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const responses = await Promise.all([
                userApi.getAccount(this.props.match.params.id),
                collegeApi.get(this.context.auth?.colleges?.find(Boolean)?.id),
                collegeCoachApi.list(
                    this.context.auth?.colleges?.find(Boolean)?.id!,
                    this.context.auth?.colleges?.find(Boolean)?.sportId,
                ),
                sportApi.listAll(),
            ]);
            const user = responses[0];
            const college = responses[1];
            const collegeCoaches = responses[2];
            const sports = responses[3];

            this.setState({ user, college, collegeCoaches, sports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    updateCoachTable = async () => {
        try {
            this.setState({ loading: 'loading' });

            const collegeCoaches = await collegeCoachApi.list(this.context.auth?.colleges?.find(Boolean)?.id!);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            this.showCollegeCoachModal(false);

            this.setState({ collegeCoaches });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteCoach = async () => {
        const { coach } = this.state;
        try {
            this.setState({ loading: 'deleting' });

            await collegeCoachApi.delete(this.context.auth?.colleges?.find(Boolean)?.id!, coach?.id!);
            message.success(this.props.intl.formatMessage({ id: 'status.deleted' }));
            this.showDeleteCoachModal(false);

            const collegeCoaches = await collegeCoachApi.list(
                this.context.auth?.colleges?.find(Boolean)?.id!,
                this.context.auth?.colleges?.find(Boolean)?.sportId,
            );

            this.setState({ collegeCoaches });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showCollegeCoachModal = (collegeCoachModalVisible: boolean) => {
        this.setState({ collegeCoachModalVisible });
    };

    showDeleteCoachModal = (deleteCoachModalVisible: boolean, coach?: CollegeCoach) => {
        this.setState({ deleteCoachModalVisible, coach });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { user, sports } = this.state;
        const sport = sports.find((s) => s.id === this.context.auth?.colleges?.find(Boolean)?.sportId);

        if (desktop) {
            return (
                <div className={styles.header}>
                    <UserBackgroundComponent sports={sports} />

                    <div className={styles.buttons}>
                        <div className={styles.avatar}>
                            <Avatar src={user?.photoUrl || avatar} className={styles.image} />
                            <div>
                                <p className={styles.parentName}>
                                    {user?.givenName} {user?.familyName}
                                </p>
                                <p className={styles.email}>{user?.email}</p>
                                <p className={styles.sport}>{sport?.name}</p>
                            </div>
                        </div>

                        {(this.context.auth?.authorities.includes('ROLE_ADMIN') ||
                            this.context.auth?.id === this.props.match.params.id) && (
                            <Link to={`/college-coaches/${this.props.match.params.id}/edit`}>
                                <Button icon={<EditOutlined />}>
                                    <FormattedMessage id="parent.profile.editProfile" tagName="span" />
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={`${styles.header} ${styles.mobile}`}>
                    <Dropdown
                        overlay={
                            <Menu>
                                {(this.context.auth?.authorities.includes('ROLE_ADMIN') ||
                                    this.context.auth?.id === this.props.match.params.id) && (
                                    <Menu.Item key="edit">
                                        <Link to={`/college-coaches/${this.props.match.params.id}/edit`}>
                                            <FormattedMessage id="parent.profile.editProfile" tagName="span" />
                                        </Link>
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                    >
                        <Button className={styles.actions}>
                            <Avatar src={user?.photoUrl || avatar} className={styles.image} />
                        </Button>
                    </Dropdown>
                </div>
            );
        }
    };

    renderCoachesTable = (): React.ReactElement | undefined => {
        const { collegeCoaches } = this.state;

        const columns: ColumnsType<CollegeCoach> = [
            {
                title: <FormattedMessage id="parent.name" />,
                dataIndex: 'givenName',
                key: 'givenName',
                render: (value: string, coach: CollegeCoach) => stringService.getName(true, value, coach.familyName),
            },
            {
                title: <FormattedMessage id="parent.username" />,
                dataIndex: 'userName',
                key: 'userName',
                render: (value: string) => value,
            },
            {
                title: <FormattedMessage id="parent.email" />,
                dataIndex: 'email',
                key: 'email',
                render: (value: string, coach: CollegeCoach) => value,
            },
            {
                title: <FormattedMessage id="parent.actions" />,
                width: 90,
                render: (value: string, coach: CollegeCoach) =>
                    collegeCoaches.length > 1 &&
                    coach.id !== this.context.auth?.id && (
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item key="delete" onClick={() => this.showDeleteCoachModal(true, coach)}>
                                        <FormattedMessage id="parent.profile.delete" />
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button type="text" className={styles.actions}>
                                <EllipsisOutlined />
                            </Button>
                        </Dropdown>
                    ),
            },
        ];

        return (
            <div>
                <div className={styles.flex}>
                    <h2>
                        <FormattedMessage id="collegeCoachProfile.coaches" />
                    </h2>
                    <Button
                        className={styles.addNewCoach}
                        onClick={() => this.showCollegeCoachModal(true)}
                        icon={<PlusOutlined />}
                    >
                        <FormattedMessage id="collegeCoachProfile.addNewCoach" />
                    </Button>
                </div>
                <div className={styles.table}>
                    <Table dataSource={collegeCoaches} columns={columns} pagination={false} rowKey="id" />
                </div>
            </div>
        );
    };

    renderDeleteCoachModal = (): React.ReactElement | undefined => {
        const { deleteCoachModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="collegeCoachProfile.deleteModal.title" />}
                visible={deleteCoachModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.deleteCoach}
                okButtonProps={{ loading: loading === 'deleting' }}
                onCancel={() => this.showDeleteCoachModal(false)}
                className={styles.modal}
            >
                <FormattedMessage id="collegeCoachProfile.deleteModal.description" />
            </Modal>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { college, collegeCoachModalVisible, coach, sports } = this.state;
        const sportId = this.context.auth?.colleges?.find(Boolean)?.sportId;

        return (
            <>
                <div className={styles.collegeName}>{college?.name}</div>
                <div>{this.renderCoachesTable()}</div>
                {collegeCoachModalVisible && college && sportId && (
                    <CollegeCoachModal
                        onCancel={() => this.showCollegeCoachModal(false)}
                        onUpdate={this.updateCoachTable}
                        collegeId={college.id!}
                        sportId={sportId}
                        coachId={coach?.id}
                        sports={sports}
                    />
                )}
                {this.renderDeleteCoachModal()}
            </>
        );
    };

    render() {
        const { loading, user } = this.state;
        const fullName =
            user &&
            `${user?.givenName} ${user?.familyName} - ${this.props.intl.formatMessage({
                id: 'collegeCoachProfile',
            })}`;

        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="college-coach-profile"
                    content={this.renderContent}
                    header={this.renderHeader}
                    loading={loading === 'initializing'}
                    userId={this.props.match.params.id}
                />
            </>
        );
    }
}
export default injectIntl(CollegeCoachProfilePage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    user?: UserAccount;
    loading?: 'initializing' | 'loading' | 'deleting';
    college?: College;
    collegeCoaches: CollegeCoach[];
    collegeCoachModalVisible?: boolean;
    coach?: CollegeCoach;
    sports: Sport[];
    deleteCoachModalVisible?: boolean;
}
