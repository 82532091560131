import { Team } from '../model/Entities';
import apiAxios from './CustomAxios';

class TeamApi {
    listByOrganization = async (
        organizationId: string,
        withMembers?: boolean,
        sportId?: number,
        divisionId?: number,
    ): Promise<Team[]> => {
        const response = await apiAxios.get<Team[]>(`/v1/organizations/${organizationId}/teams`, {
            params: { withMembers, sportId, divisionId },
        });
        return response.data;
    };

    getByOrganization = async (organizationId: string, teamId: string): Promise<Team> => {
        const response = await apiAxios.get<Team>(`/v1/organizations/${organizationId}/teams/${teamId}`);
        return response.data;
    };

    create = async (team: Team, organizationId: string): Promise<Team> => {
        const response = await apiAxios.post<Team>(`/v1/organizations/${organizationId}/teams`, team);
        return response.data;
    };

    update = async (team: Team, organizationId: string): Promise<Team> => {
        const response = await apiAxios.put<Team>(`/v1/organizations/${organizationId}/teams/${team.id}`, team);
        return response.data;
    };

    delete = async (team: Team, organizationId: string): Promise<void> => {
        await apiAxios.delete(`/v1/organizations/${organizationId}/teams/${team.id}`);
    };
}

const teamApi: TeamApi = new TeamApi();
export default teamApi;
