import { Division } from '../model/Entities';
import apiAxios from './CustomAxios';

class DivisionApi {
    list = async (sportId: number): Promise<Division[]> => {
        const response = await apiAxios.get<Division[]>(`/v1/sports/${sportId}/divisions`, {});

        return response.data;
    };
}

const divisionApi: DivisionApi = new DivisionApi();
export default divisionApi;
