import { UserRating } from '../model/Entities';
import apiAxios from './CustomAxios';

class UserRatingApi {
    list = async (userId: string, sportId: number, seasonId: number): Promise<UserRating[]> => {
        const response = await apiAxios.get<UserRating[]>(`/v1/users/${userId}/ratings`, {
            params: { sportId, seasonId },
        });
        return response.data;
    };
}

const userRatingApi: UserRatingApi = new UserRatingApi();
export default userRatingApi;
