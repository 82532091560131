import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, message, Modal, Row, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import divisionApi from '../../apis/DivisionApi';
import organizationGroupApi from '../../apis/OrganizationGroupApi';
import organizationSportsApi from '../../apis/OrganizationSportsApi';
import positionApi from '../../apis/PositionApi';
import sportApi from '../../apis/SportApi';
import {
    Division,
    Organization,
    OrganizationMember,
    OrganizationMemberCoach,
    OrganizationMemberPlayer,
    OrganizationSport,
    Position,
    Sport,
    Team,
} from '../../model/Entities';
import { countries, CountryType, genders } from '../../model/Types';
import metricService from '../../services/MetricService';
import notificationService from '../../services/NotificationService';
import styles from './AddAffiliateUserModal.module.scss';

import { PlusOutlined } from '@ant-design/icons';
import teamApi from '../../apis/TeamApi';
import { ReactComponent as DeleteSvg } from '../../resources/images/ico-delete.svg';
import stateService from '../../services/StateService';

class AddAffiliateUserModal extends Component<Props, State> {
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            sports: [],
            divisions: [],
            positions: [],
            organizationSports: [],
            organizationTeams: [],
            divisionsTeams: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            let sports: Sport[] = [];
            let organizationSports: OrganizationSport[] = [];
            let organizationTeams: Team[] = [];
            if (this.props.userType === 'coach' || this.props.userType === 'player') {
                const responses = await Promise.all([
                    sportApi.listAll(),
                    organizationSportsApi.get(this.props.organization?.id!),
                    teamApi.listByOrganization(this.props.organization?.id!),
                ]);
                const allSports = responses[0];
                organizationSports = responses[1];
                organizationTeams = responses[2];
                sports = allSports.filter((s) => organizationSports.find((os) => os.sportId === s.id));
            }

            this.setState({ sports, organizationSports, organizationTeams });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    saveCoach = async () => {
        const { organization } = this.props;

        try {
            this.setState({ loading: 'adding' });

            const values = await this.formRef.current?.validateFields();
            const sportId = values.sportId;
            const teams = values.teams ? values.teams.map((team: any) => team.teamId) : [];
            const coach: OrganizationMemberCoach = Object.assign({}, values, {
                teams,
            });
            await organizationGroupApi.createCoach(organization?.id!, sportId!, coach);

            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.formRef.current?.resetFields();
            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: 'status.duplicatedUser' },
                ]);
            } else {
                console.log(error);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    saveOwner = async () => {
        const { organization } = this.props;
        try {
            this.setState({ loading: 'adding' });

            const values = await this.formRef.current?.validateFields();
            const owner: OrganizationMember = Object.assign({}, values);
            await organizationGroupApi.createOwner(organization?.id!, owner);

            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.formRef.current?.resetFields();
            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: 'status.duplicatedEmail' },
                ]);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    saveStaff = async () => {
        const { organization } = this.props;
        try {
            this.setState({ loading: 'adding' });

            const values = await this.formRef.current?.validateFields();
            const staff: OrganizationMember = Object.assign({}, values);
            await organizationGroupApi.createStaff(organization?.id!, staff);

            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.formRef.current?.resetFields();
            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: 'status.duplicatedEmail' },
                ]);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    savePlayer = async (invite?: boolean) => {
        const { organization } = this.props;
        const { sportId } = this.state;
        try {
            invite ? this.setState({ loading: 'addingAndInviting' }) : this.setState({ loading: 'adding' });

            const values = await this.formRef.current?.validateFields();
            const player: OrganizationMemberPlayer = Object.assign({}, values);
            player.invite = invite;
            player.height =
                values.height || values.heightInches
                    ? metricService.toTotalInches(values.height, values.heightInches)
                    : undefined;
            player.dateOfBirth = values.dateOfBirth ? values.dateOfBirth.valueOf() : undefined;
            player.email = player.email ? player.email : undefined;
            player.grade = player.grade ? player.grade : undefined;
            player.parentPhoneNumber = player.parentPhoneNumber ? player.parentPhoneNumber : undefined;
            player.phoneNumber = player.phoneNumber ? player.phoneNumber : undefined;
            await organizationGroupApi.createPlayer(organization?.id!, sportId!, player);

            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.formRef.current?.resetFields();
            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: 'status.duplicatedEmail' },
                ]);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeCountry = (country?: CountryType) => {
        this.formRef.current?.resetFields(['state']);
        this.setState({ country });
    };

    changeSport = async (sportId?: number) => {
        this.formRef.current?.resetFields(['divisions']);
        this.formRef.current?.resetFields(['division']);
        this.formRef.current?.resetFields(['positions']);
        const divisions = sportId ? await divisionApi.list(sportId) : [];
        const positions = sportId ? await positionApi.list(sportId) : [];
        this.setState({ divisions, sportId, positions });
    };

    /**
     * Changes a division.
     * @param divisionId - the division id
     */
    changeDivision = async (divisionId: number, index: number) => {
        const { divisionsTeams, organizationTeams } = this.state;

        try {
            if (this.formRef.current) {
                if (divisionsTeams.every((dt) => dt.divisionId !== divisionId)) {
                    const teams = organizationTeams.filter((ot) => ot.divisionId === divisionId);
                    const newDivisionsTeams = [...divisionsTeams, { divisionId, teams }];
                    this.setState({ divisionsTeams: newDivisionsTeams });
                } else {
                    this.setState({ divisionsTeams: [...divisionsTeams] });
                }

                this.formRef.current.resetFields([['teams', index, 'teamId']]);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    chooseSaveFunction = (invite?: boolean) => {
        const { userType } = this.props;
        if (userType === 'coach') {
            this.saveCoach();
        } else if (userType === 'affiliateOwner') {
            this.saveOwner();
        } else if (userType === 'affiliateStaff') {
            this.saveStaff();
        } else if (userType === 'player') {
            this.savePlayer(invite);
        }
    };

    /*** COMPONENTS ***/

    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderTeamOptions = (divisionId?: number): React.ReactElement[] | undefined => {
        const { divisionsTeams } = this.state;
        const divisionTeams = divisionsTeams.find((dt) => dt.divisionId === divisionId);

        if (divisionTeams) {
            return divisionTeams.teams.map((t) => (
                <Select.Option key={t.id} value={t.id!}>
                    {t.name}
                </Select.Option>
            ));
        }
    };

    renderAffiliateMemberInfo = (): React.ReactElement | undefined => {
        const { userType, intl } = this.props;
        const { sports, divisions, loading } = this.state;

        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));

        const sportOptions = sports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));

        const divisionOptions = divisions.map((division) => (
            <Select.Option key={division.id} value={division.id!}>
                {division.name}
            </Select.Option>
        ));

        return (
            <div className={styles.userInfo}>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.firstName" />}
                        name="firstName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.lastName" />}
                        name="lastName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.email" />}
                        name="email"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.phone" />}
                        name="phoneNumber"
                        rules={[
                            {
                                pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                message: <FormattedMessage id="status.phoneNumber" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.address" />} name="address">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.city" />} name="city">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.country" />} name="country">
                                <Select onChange={(value: CountryType) => this.changeCountry(value)} allowClear>
                                    {countryOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.state" />} name="state">
                                <Select>{this.renderStates()}</Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.zip" />} name="zipCode">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    {userType === 'coach' && (
                        <>
                            <Form.Item
                                label={<FormattedMessage id="affiliateGroup.modal.sport" />}
                                name="sportId"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select onChange={(value: number) => this.changeSport(value)} allowClear>
                                    {sportOptions}
                                </Select>
                            </Form.Item>

                            <label className={styles.label}>
                                <FormattedMessage id="affiliateGroup.modal.teams" />
                            </label>
                            <Form.List name="teams">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }, index, array) => {
                                            const divisionId = this.formRef.current?.getFieldValue([
                                                'teams',
                                                index,
                                                'divisionId',
                                            ]);
                                            return (
                                                <Row gutter={[24, 24]} key={name}>
                                                    <Col span={10}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'divisionId']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: <FormattedMessage id="status.mandatory" />,
                                                                },
                                                            ]}
                                                            wrapperCol={{ span: 24 }}
                                                        >
                                                            <Select
                                                                placeholder={intl.formatMessage({
                                                                    id: 'affiliateGroup.modal.teams.division',
                                                                })}
                                                                size="large"
                                                                onChange={(divisionId: number) =>
                                                                    this.changeDivision(divisionId, index)
                                                                }
                                                            >
                                                                {divisionOptions}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'teamId']}
                                                            required={false}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: <FormattedMessage id="status.mandatory" />,
                                                                },
                                                                ({ getFieldValue, getFieldsValue }) => ({
                                                                    validator(_, value) {
                                                                        try {
                                                                            const teams = getFieldsValue().teams.filter(
                                                                                (fv: any) => fv.teamId === value,
                                                                            );
                                                                            if (teams.length > 1) {
                                                                                return Promise.reject(
                                                                                    <FormattedMessage id="affiliateGroup.modal.teams.duplicated" />,
                                                                                );
                                                                            }
                                                                        } catch (error) {
                                                                            // empty error handling
                                                                        }
                                                                        return Promise.resolve();
                                                                    },
                                                                }),
                                                            ]}
                                                            wrapperCol={{ span: 24 }}
                                                        >
                                                            <Select
                                                                placeholder={intl.formatMessage({
                                                                    id: 'affiliateGroup.modal.teams.team',
                                                                })}
                                                                size="large"
                                                                loading={loading === `loadingTeams-${divisionId}`}
                                                            >
                                                                {this.renderTeamOptions(divisionId)}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Button
                                                            icon={<DeleteSvg />}
                                                            size="small"
                                                            type="text"
                                                            onClick={() => remove(name)}
                                                            className={styles.remove}
                                                            hidden={fields.length < 2}
                                                        />
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                size="large"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                <FormattedMessage id="affiliateGroup.modal.teams.add" tagName="span" />
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </>
                    )}
                </Form>
            </div>
        );
    };

    renderPlayerInfo = (): React.ReactElement | undefined => {
        const { sports, divisions, positions } = this.state;
        const genderOptions = genders.map((gender) => (
            <Select.Option key={gender} value={gender}>
                <FormattedMessage id={gender} />
            </Select.Option>
        ));

        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));

        const sportOptions = sports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));

        const divisionOptions = divisions.map((division) => (
            <Select.Option key={division.id} value={division.id!}>
                {division.name}
            </Select.Option>
        ));

        const positionOptions = positions?.map((position) => (
            <Select.Option key={position.id} value={position.id!}>
                {position.code}
            </Select.Option>
        ));

        const minGraduationYear = moment().year() - 19;
        const maxGraduationYear = moment().year() + 19;

        return (
            <div className={styles.userInfo}>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.sport" />}
                        name="sportId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select onChange={(value: number) => this.changeSport(value)} allowClear>
                            {sportOptions}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.division" />}
                        name="division"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select>{divisionOptions}</Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.firstName" />}
                        name="firstName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.lastName" />}
                        name="lastName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.gradYear" />}
                        name="gradYear"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                min: minGraduationYear,
                                type: 'number',
                                max: maxGraduationYear,
                                message: `${this.props.intl.formatMessage({
                                    id: 'status.gradYear',
                                })} ${minGraduationYear} ${this.props.intl.formatMessage({
                                    id: 'and',
                                })} ${maxGraduationYear}`,
                            },
                        ]}
                    >
                        <InputNumber type="number" min={0} max={maxGraduationYear} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.gender" />}
                        name="gender"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select>{genderOptions}</Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.parentFirstName" />}
                        name="parentFirstName"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.parentLastName" />}
                        name="parentLastName"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.parentEmailAddress" />}
                        name="parentEmailAddress"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />,
                            },
                        ]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.parentPhoneNumber" />}
                        name="parentPhoneNumber"
                        rules={[
                            {
                                pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                message: <FormattedMessage id="status.phoneNumber" />,
                            },
                        ]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.email" />}
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />,
                            },
                        ]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="affiliateGroup.modal.dateOfBirth" />} name="dateOfBirth">
                        <DatePicker size="small" format="MM-DD-YYYY" />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="affiliateGroup.modal.school" />} name="school">
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.grade" />}
                        name="grade"
                        rules={[{ pattern: /\b([1-9]|1[0-2])\b/, message: <FormattedMessage id="status.grade" /> }]}
                    >
                        <InputNumber type="number" min={1} max={12} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.phone" />}
                        name="phoneNumber"
                        rules={[
                            {
                                pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                message: <FormattedMessage id="status.phoneNumber" />,
                            },
                        ]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="affiliateGroup.modal.height" />}
                                name="height"
                                rules={[
                                    {
                                        type: 'number',
                                        message: this.props.intl.formatMessage({
                                            id: 'status.height',
                                        }),
                                    },
                                ]}
                            >
                                <InputNumber type="number" min={0} max={7} precision={0} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label={<FormattedMessage id="affiliateGroup.modal.heightInches" />}
                                name="heightInches"
                            >
                                <InputNumber type="number" min={0} max={11} precision={0} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label={<FormattedMessage id="affiliateGroup.modal.weight" />}
                        name="weight"
                        rules={[
                            {
                                type: 'number',
                                message: this.props.intl.formatMessage({
                                    id: 'status.weight',
                                }),
                            },
                        ]}
                    >
                        <InputNumber type="number" min={0} maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="affiliateGroup.modal.position" />} name="positions">
                        <Select mode="multiple">{positionOptions}</Select>
                    </Form.Item>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={12}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.address" />} name="address">
                                <Input maxLength={100} size="small" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.city" />} name="city">
                                <Input maxLength={100} size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.country" />} name="country">
                                <Select onChange={(value: CountryType) => this.changeCountry(value)} allowClear>
                                    {countryOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.state" />} name="state">
                                <Select>{this.renderStates()}</Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="affiliateGroup.modal.zip" />} name="zipCode">
                                <Input maxLength={100} size="small" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    };

    renderModalTitle = (userType: string): React.ReactElement | undefined => {
        const { organization } = this.props;

        if (userType === 'affiliateOwner' && organization?.primary) {
            return <FormattedMessage id="affiliateGroup.addTop100Admin" />;
        } else if (userType === 'affiliateOwner') {
            return <FormattedMessage id="affiliateGroup.addAffiliateOwner" />;
        } else if (userType === 'affiliateStaff' && organization?.primary) {
            return <FormattedMessage id="affiliateGroup.addTop100Staff" />;
        } else if (userType === 'affiliateStaff') {
            return <FormattedMessage id="affiliateGroup.addAffiliateStaff" />;
        } else if (userType === 'coach') {
            return <FormattedMessage id="affiliateGroup.addCoach" />;
        } else if (userType === 'player') {
            return <FormattedMessage id="affiliateGroup.addPlayer" />;
        }
    };

    render() {
        const { loading } = this.state;
        const { userType } = this.props;

        return (
            <Modal
                title={this.renderModalTitle(userType!)}
                className={styles.modal}
                width={600}
                visible={true}
                onCancel={this.cancel}
                footer={[
                    <Button className={styles.btn} key="cancel" shape={'round'} onClick={this.cancel}>
                        <FormattedMessage id="button.cancel" />
                    </Button>,
                    <Button
                        key="add"
                        shape={'round'}
                        type="primary"
                        onClick={() => this.chooseSaveFunction()}
                        className={styles.btn}
                        loading={loading === 'adding'}
                    >
                        <FormattedMessage id="button.add" tagName="span" />
                    </Button>,
                    <Button
                        key="invite"
                        shape={'round'}
                        type="primary"
                        onClick={() => this.chooseSaveFunction(true)}
                        hidden={userType !== 'player'}
                        className={styles.btn}
                        loading={loading === 'addingAndInviting'}
                    >
                        <FormattedMessage id="button.addAndInvite" tagName="span" />
                    </Button>,
                ]}
            >
                {userType === 'player' ? this.renderPlayerInfo() : this.renderAffiliateMemberInfo()}
            </Modal>
        );
    }
}
export default injectIntl(AddAffiliateUserModal);

interface Props extends WrappedComponentProps {
    onUpdate: () => void;
    onCancel: () => void;
    userType?: string;
    organization?: Organization;
}

interface State {
    loading?: 'loading' | 'saving' | 'addingAndInviting' | 'adding';
    sports: Sport[];
    organizationSports: OrganizationSport[];
    organizationTeams: Team[];
    country?: CountryType;
    divisions: Division[];
    sportId?: number;
    positions: Position[];
    divisionsTeams: { divisionId: number; teams: Team[] }[];
}
