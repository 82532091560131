const awsConfig = {
    Auth: {
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
        authenticationFlowType: process.env.REACT_APP_AWS_COGNITO_AUTHENTICATION_FLOW_TYPE,
    }
};


export default awsConfig;
