import axios from 'axios';
import { Page } from '../model/Elements';
import { TeamMember } from '../model/Entities';
import apiAxios from './CustomAxios';

class TeamMemberApi {
    list = async (
        organizationId: string,
        teamId: string,
        limit: number,
        page: number,
        sortField?: string[],
        sortOrder?: string,
    ): Promise<Page<TeamMember>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getSortField(sortField);
        const response = await apiAxios.get<Page<TeamMember>>(
            `/v1/organizations/${organizationId}/teams/${teamId}/members`,
            {
                params: { limit, page, [field]: order },
            },
        );

        return response.data;
    };

    listAll = async (organizationId: string, teamId: string): Promise<TeamMember[]> => {
        let page = 1;
        let total: number;
        let items: TeamMember[] = [];
        do {
            const itemsPage = await this.list(organizationId, teamId, 500, page);
            total = itemsPage.totalItems;
            items = items.concat(itemsPage.items);
            page = page + 1;
        } while (items.length < total);

        return items;
    };

    get = async (organizationId: string, teamId: string, teamMemberId: string): Promise<TeamMember> => {
        const response = await apiAxios.get<TeamMember>(
            `/v1/organizations/${organizationId}/teams/${teamId}/members/${teamMemberId}`,
        );
        return response.data;
    };

    create = async (organizationId: string, teamId: string, teamMember: TeamMember): Promise<TeamMember> => {
        const teamMemberWithUser = this.createTeamMemberWithUser(teamMember);
        const response = await apiAxios.post<TeamMember>(
            `/v1/organizations/${organizationId}/teams/${teamId}/members`,
            teamMemberWithUser,
        );
        return response.data;
    };

    update = async (organizationId: string, teamId: string, teamMember: TeamMember): Promise<TeamMember> => {
        const teamMemberWithUser = this.createTeamMemberWithUser(teamMember);
        const response = await apiAxios.put<TeamMember>(
            `/v1/organizations/${organizationId}/teams/${teamId}/members/${teamMember.id}`,
            teamMemberWithUser,
        );
        return response.data;
    };

    updateTeamFromTeamMembers = async (
        organizationId: string,
        teamId: string,
        destinationTeamId: string,
        members: string[],
        removeFromTheCurrentTeam: boolean,
    ): Promise<void> => {
        await apiAxios.put<void>(`/v1/organizations/${organizationId}/teams/${teamId}/members`, {
            removeFromTheCurrentTeam,
            destinationTeamId,
            members,
        });
    };

    gradeLevelUp = async (
        organizationId: string,
        teamId: string,
        organizationTeamMemberId: string[],
    ): Promise<void> => {
        await apiAxios.put<void>(`/v1/organizations/${organizationId}/teams/${teamId}/members/grade-level-up`, {
            organizationTeamMemberId,
        });
    };

    patch = async (
        organizationId: string,
        teamId: string,
        teamMember: TeamMember,
        newTeamId?: string,
    ): Promise<TeamMember> => {
        const response = await apiAxios.patch<TeamMember>(
            `/v1/organizations/${organizationId}/teams/${teamId}/members/${teamMember.id}`,
            {
                positions: teamMember.positions,
                jerseyNumber: teamMember.jerseyNumber,
                organizationTeamId: newTeamId ? newTeamId : teamMember.teamId,
            },
        );
        return response.data;
    };

    delete = async (id: string, organizationId: string, teamId: string): Promise<void> => {
        await apiAxios.delete(`/v1/organizations/${organizationId}/teams/${teamId}/members/${id}`);
    };

    updatePhoto = async (userId: string, organizationTeamMemberId: string, file: File): Promise<string> => {
        // get s3 upload url
        const initialResponse = await apiAxios.get(`/v1/media/generate-presigned-profile-photo-url/${userId}`);
        const s3UploadUrl = initialResponse.data.url;

        // post photo to s3
        await axios.put(s3UploadUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });

        // confirm new user sport id
        await apiAxios.post<void>(`/v1/media/confirm_uploaded-profile-photo-by-presigned-url`, {
            userId,
            s3Link: s3UploadUrl,
            organizationTeamMemberId,
        });

        return s3UploadUrl;
    };

    private getSortField = (sortField?: string[]): string => {
        let field: string = 'sorting.GivenName';
        if (!sortField) {
            return field;
        }
        if (sortField[1] === 'givenName') {
            field = 'sorting.GivenName';
        } else if (sortField[1] === 'familyName') {
            field = 'sorting.FamilyName';
        }
        return field;
    };

    /**
     * Returns the team member with user (required by update and create endpoints).
     * @param teamMember the team member
     * @returns the team member
     */
    private createTeamMemberWithUser = (teamMember: TeamMember): any => {
        const teamMemberForUpdate: any = Object.assign({}, teamMember, {
            userId: teamMember.userId,
            familyName: teamMember.profile?.familyName,
            gender: teamMember.profile?.gender,
            givenName: teamMember.profile?.givenName,
            grade: teamMember.profile?.grade,
            email: teamMember.profile?.email,
            phone: teamMember.profile?.phone,
            weight: teamMember.profile?.weight,
            height: teamMember.profile?.height,
            school: teamMember.profile?.school,
            coreGpa: teamMember.profile?.coreGpa,
            act: teamMember.profile?.act,
            sat: teamMember.profile?.sat,
            classRankNum: teamMember.profile?.classRankNum,
            classRankOf: teamMember.profile?.classRankOf,
            schoolCredits: teamMember.profile?.schoolCredits,
            gradYear: teamMember.profile?.gradYear,
            ncaaIdNumber: teamMember.profile?.ncaaIdNumber,
            ncaaRegistered: teamMember.profile?.ncaaRegistered,
            twitter: teamMember.profile?.twitter,
            instagram: teamMember.profile?.instagram,
            parentName: teamMember.profile?.parentName,
            parentPhone: teamMember.profile?.parentPhone,
            parentEmail: teamMember.profile?.parentEmail,
            address: teamMember.profile?.address,
            city: teamMember.profile?.city,
            state: teamMember.profile?.state,
            zip: teamMember.profile?.zip,
            facebook: teamMember.profile?.facebook,
            snapchat: teamMember.profile?.snapchat,
        });

        return teamMemberForUpdate;
    };
}

const teamMemberApi: TeamMemberApi = new TeamMemberApi();
export default teamMemberApi;
