import { Page } from '../model/Elements';
import { ProspectPlayerGame } from '../model/Entities';
import apiAxios from './CustomAxios';

class GameStatisticsApi {
    list = async (
        userId: string,
        seasonId: number,
        limit?: number,
        page?: number,
    ): Promise<Page<ProspectPlayerGame>> => {
        const response = await apiAxios.get<Page<ProspectPlayerGame>>(`/v1/users/${userId}/seasons/${seasonId}/games`, {
            params: { limit, page },
        });

        response.data.items.forEach((i) => (i.date ? (i.date = i.date * 1000) : undefined));

        return response.data;
    };

    create = async (
        userId: string,
        seasonId: number,
        playerGameStatistics: ProspectPlayerGame,
    ): Promise<ProspectPlayerGame> => {
        playerGameStatistics.date = playerGameStatistics.date
            ? Math.floor(playerGameStatistics.date / 1000)
            : undefined;
        const response = await apiAxios.post<ProspectPlayerGame>(
            `/v1/users/${userId}/seasons/${seasonId}/games`,
            playerGameStatistics,
        );
        return response.data;
    };

    delete = async (userId: string, seasonId: number, gameId: number): Promise<void> => {
        await apiAxios.delete(`/v1/users/${userId}/seasons/${seasonId}/games/${gameId}`);
    };

    update = async (
        userId: string,
        seasonId: number,
        playerGameStatistics: ProspectPlayerGame,
    ): Promise<ProspectPlayerGame> => {
        playerGameStatistics.date = playerGameStatistics.date
            ? Math.floor(playerGameStatistics.date / 1000)
            : undefined;
        const response = await apiAxios.put<ProspectPlayerGame>(
            `/v1/users/${userId}/seasons/${seasonId}/games/${playerGameStatistics.id}`,
            playerGameStatistics,
        );
        return response.data;
    };
}
const gameStatisticsApi: GameStatisticsApi = new GameStatisticsApi();
export default gameStatisticsApi;
