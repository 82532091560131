import { Button, Col, Form, FormInstance, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { FacebookShareButton } from 'react-share';
import CustomContext from '../../../context/CustomContext';
import { User } from '../../../model/Entities';
import styles from './PlayerShareModal.module.scss';

class PlayerShareModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { url: window.location.href };
    }

    /** METHODS **/

    cancel = async () => {
        this.props.onCancel();
    };

    copy = () => {
        navigator.clipboard.writeText(this.state.url);
    };

    /*** COMPONENTS ***/

    render() {
        const { user } = this.props;
        const { url } = this.state;

        return (
            <Modal
                visible={this.props.visible}
                onCancel={this.cancel}
                footer={[
                    <Button type="primary" onClick={this.copy}>
                        <FormattedMessage id="player.profile.copyLink" />
                    </Button>,
                    <FacebookShareButton className={styles.facebook} url={url!} quote={`${user?.profile.givenName} ${user?.profile.familyName}`}>
                        <Button type="primary">
                            <FormattedMessage id="player.profile.shareFacebook" />
                        </Button>
                    </FacebookShareButton>,
                ]}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row align="middle" gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Form.Item name="share">
                                <div>
                                    <h1>{<FormattedMessage id="player.profile.share" />}</h1>
                                </div>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id="player.profile.shareLink" />} name="shareLink">
                                <TextArea
                                    rows={4}
                                    defaultValue={url}
                                    autoSize={false}
                                    disabled
                                    className={styles.textarea}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(PlayerShareModal);

interface Props extends WrappedComponentProps {
    visible?: boolean;
    user?: User;
    onCancel: () => void;
}

interface State {
    url: string;
}
