import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, List, Modal } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import organizationGroupApi from '../../../../apis/OrganizationGroupApi';
import CustomContext from '../../../../context/CustomContext';
import { Division, OrganizationGroupCoach, Team } from '../../../../model/Entities';
import notificationService from '../../../../services/NotificationService';
import styles from './AddTeamCoachesModal.module.scss';
import avatar from '../../../../resources/images/profile-placeholder.png';

class AddTeamCoachesModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { coaches: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { organizationId, sportId } = this.props;
        try {
            this.setState({ loading: 'loading' });
            const coaches = await organizationGroupApi.listCoaches(organizationId, sportId);
            this.setState({ coaches });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    addCoachToTeam = async (coach: OrganizationGroupCoach) => {
        const { organizationId, sportId, team } = this.props;

        try {
            this.setState({ loading: `saving-${coach.userId}` });

            await organizationGroupApi.addTeamCoach(organizationId, sportId, coach, team);

            this.props.onUpdate();
        } catch (error: any) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderCoaches = (): React.ReactElement | undefined => {
        const { team } = this.props;
        const { coaches, loading } = this.state;
        return (
            <List
                grid={{
                    gutter: 0,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                }}
                className={styles.list}
                dataSource={coaches}
                locale={{
                    emptyText: (
                        <div className={styles.empty}>
                            <FormattedMessage id="roster.addTeamCoaches.empty" />
                        </div>
                    ),
                }}
                renderItem={(coach) => (
                    <List.Item key={coach.userId} className={styles.listItem}>
                        <div className={styles.userList}>
                            <div className={styles.user}>
                                <Avatar className={styles.avatar} size={64} src={coach.photoUrl || avatar} />
                                <p>
                                    {coach.givenName} {coach.familyName}
                                </p>
                            </div>

                            <Button
                                className={styles.addUserButton}
                                type="text"
                                icon={<PlusOutlined />}
                                onClick={() => this.addCoachToTeam(coach)}
                                loading={loading === `saving-${coach.userId}`}
                                disabled={coach.teams?.some((t) => t.teamId === team.id)}
                            >
                                <FormattedMessage id="button.add" tagName="span" />
                            </Button>
                        </div>
                        <Divider></Divider>
                    </List.Item>
                )}
                loading={loading === 'loading'}
            />
        );
    };

    render() {
        return (
            <Modal
                width={600}
                visible={true}
                onCancel={this.props.onCancel}
                cancelText={<FormattedMessage id="button.cancel" />}
                okButtonProps={{ hidden: true }}
                title={<FormattedMessage id="roster.addTeamCoaches.add" />}
            >
                {this.renderCoaches()}
            </Modal>
        );
    }
}
export default injectIntl(AddTeamCoachesModal);

interface Props extends WrappedComponentProps {
    onUpdate: () => Promise<void>;
    onCancel: () => void;
    divisions: Division[];
    organizationId: string;
    sportId: number;
    team: Team;
}

interface State {
    coaches: OrganizationGroupCoach[];
    loading?: 'loading' | string;
}
