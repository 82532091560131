import { Button, Layout, message, Modal, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Content } from 'antd/lib/layout/layout';
import * as React from 'react';
import CustomContext from '../../context/CustomContext';
import styles from './LayoutMarketingComponent.module.scss';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import authService from '../../services/AuthService';
import ga from '../../services/GoogleAnalytics';
import { useIntl } from 'react-intl';

const LayoutMarketingComponent: React.FC<Props> = (props: Props) => {
    const contextType = CustomContext;
    const context = useContext(contextType);

    const intl = useIntl();
    const history = useHistory();

    // calculate screen size
    const screens = useBreakpoint();
    const desktop: boolean = Object.entries(screens)
        .filter((screen) => !!screen[1])
        .some((s) => ['md', 'lg', 'xl', 'xxl'].includes(s[0]));

    const showSignOut = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'signOut.title' }),
            onOk: signOut,
        });
    };

    const signOut = async (): Promise<void> => {
        const { updateAuth } = context;
        try {
            ga.createDefaultEvent('sign out', 'sign out - signing out');
            await authService.signOut();
            updateAuth();
            ga.createDefaultEvent('sign out', 'sign out - sign out success');
            history.push('/');
        } catch (error: any) {
            if (error.message) {
                message.error(error.message, 6);
                ga.createDefaultEvent('sign out', `sign out - ${error.message}`);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
                ga.createDefaultEvent('sign out', 'sign out - sign out internal error');
            }
        }
    };

    /**
     * Returns the header.
     * @returns the header
     */
    const renderHeader = (): React.ReactNode | undefined => {
        return (
            <header className={styles.head}>
                <div className={styles.row}>
                    <div className={styles.logo}>
                        <a href="https://www.top100sports.com">Top 100 Sports</a>
                    </div>

                    <nav className={styles.mainMenu}>
                        <div className={styles.scroll}>
                            <div className={styles.content}>
                                <div className={styles.menuContainer}>
                                    <ul className={styles.menu}>
                                        <li>
                                            <a href="https://www.top100sports.com/become-an-affiliate/">
                                                Become an Affiliate
                                            </a>
                                        </li>
                                        <li className={styles.hasChildren}>
                                            <a href="#0">Products</a>
                                            <ul className={styles.subMenu}>
                                                <li>
                                                    <a href="https://www.top100sports.com/products/for-affiliates/">
                                                        For Affiliates
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.top100sports.com/products/for-athletes-and-parents/">
                                                        For Athletes and Parents
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="https://www.top100sports.com/top-100-games/">TOP 100 Games</a>
                                        </li>
                                        <li className={styles.hasChildren}>
                                            <a href="https://www.top100sports.com/about/">About us</a>
                                            <ul className={styles.subMenu}>
                                                <li>
                                                    <a href="https://www.top100sports.com/ambassador/jason-grilli/">
                                                        Ambassadors
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.top100sports.com/become-an-affiliate/">
                                                        Become an Affiliate
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.top100sports.com/contact/">Contact</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="https://www.top100sports.com/news-media/">News</a>
                                        </li>
                                        <li className={styles.hasChildren}>
                                            <a href="https://www.top100sports.com/leaderboards">Leaderboards</a>
                                            <ul className={styles.subMenu}>
                                                <li>
                                                    <a href="https://www.top100sports.com/leaderboards/?sport=2">
                                                        Baseball
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.top100sports.comleaderboards/?sport=4">
                                                        Softball
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.top100sports.com/leaderboards/?sport=15">
                                                        Lacrosse
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a href="https://www.top100sports.com/events/">Events</a>
                                        </li>
                                        {context.auth && (
                                            <li>
                                                <Button type="link" onClick={showSignOut}>
                                                    Sign Out
                                                </Button>
                                            </li>
                                        )}
                                        {!context.auth && (
                                            <>
                                                <li>
                                                    <a href="https://www.top100sports.com/events/">Register</a>
                                                </li>
                                                <li>
                                                    <Link to="/signin">Log in</Link>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className={styles.mobileTrigger}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </header>
        );
    };

    /**
     * Returns the footer.
     * @returns the footer
     */
    const renderFooter = (): React.ReactNode | undefined => {
        return (
            <>
                <footer className={styles.pageFooter}>
                    <div className={styles.gradient}></div>
                    <div className={styles.row}>
                        <div className={styles.logo}>
                            <div>
                                <a href="#top" className={styles.footerLogo}>
                                    Top 100 Sports
                                </a>
                                <div className={styles.menuSocial}>
                                    <ul className={styles.menu}>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="http://www.facebook.com/top100sports"
                                            >
                                                Facebook
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://instagram.com/top100sportsusa"
                                            >
                                                Instagram
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="http://www.twitter.com/top100sports"
                                            >
                                                Twitter
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.youtube.com/channel/UCKtIcKMm_XVcQLYk1l-j0qw"
                                            >
                                                YouTube
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <nav>
                                <ul>
                                    <li>
                                        Headquarters
                                        <br />
                                        <a
                                            href="https://www.google.com/maps/place/373+Spencer+St,+Syracuse,+NY+13204,+USA/data=!4m2!3m1!1s0x89d9f3cea27cd681:0x4df633e94e8923e4?sa=X&amp;ved=2ahUKEwjti9nXqoT2AhXko4sKHZZGBjcQ8gF6BAgLEAE"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            373 Spencer Street, Syracuse NY 13204
                                        </a>
                                    </li>
                                    <li>
                                        TESTING FACILITY
                                        <br />
                                        <a
                                            href="https://www.google.com/maps/place/99+Churchill+Rd,+Oswego,+NY+13126,+USA/@43.4320566,-76.480945,14.71z/data=!4m5!3m4!1s0x89d762ba20b7c4e1:0x62e09608db2bac31!8m2!3d43.4326677!4d-76.4737443"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            99 Churchill Rd, Oswego NY 13126
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div className={styles.menuFooter}>
                            <ul className={styles.menu}>
                                <li className={styles.menuHasChildren}>
                                    <a href="#0">Products</a>
                                    <ul className={styles.subMenu}>
                                        <li className={styles.menuHasChildren}>
                                            <a href="#0">Player Development System</a>
                                            <ul className={styles.subMenu}>
                                                <li>
                                                    <a href="https://www.top100sports.com/products/for-affiliates/">
                                                        For Affiliates
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.top100sports.com/products/for-athletes-and-parents/">
                                                        For Athletes and Parents
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li className={styles.menuHasChildren}>
                                    <a href="#0">Top 100 Games</a>
                                    <ul className={styles.subMenu}>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.top100sports.com/top-100-games/"
                                            >
                                                2022 Games
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.legendsfields.com"
                                            >
                                                Locations &amp; Fields
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className={styles.menuHasChildren}>
                                    <a href="#0">Events</a>
                                    <ul className={styles.subMenu}>
                                        <li>
                                            <a href="https://www.top100sports.com/events/">Upcoming</a>
                                        </li>
                                        <li>
                                            <a href="/events?type=completed">Results</a>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://app.top100sports.com/signin"
                                            >
                                                Register
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className={styles.menuHasChildren}>
                                    <a href="#0">Leaderboards</a>
                                    <ul className={styles.subMenu}>
                                        <li>
                                            <a href="/leaderboards/?sport=2">Baseball</a>
                                        </li>
                                        <li>
                                            <a href="/leaderboards/?sport=4">Softball</a>
                                        </li>
                                        <li>
                                            <a href="/leaderboards/?sport=15">Lacrosse</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className={styles.menuHasChildren}>
                                    <a href="#0">About</a>
                                    <ul className={styles.subMenu}>
                                        <li>
                                            <a href="https://www.top100sports.com/about/">Who we are</a>
                                        </li>
                                        <li>
                                            <a href="https://www.top100sports.com/become-an-affiliate/">
                                                Become an Affiliate
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.top100sports.com/ambassador/jason-grilli/">
                                                Ambassadors
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.top100sports.com/contact/">Contact</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </footer>
                <footer className={styles.footerLegal}>
                    <div className={styles.row}>
                        <p>© Top 100 Sports. All Rights Reserved.</p>
                        <a href="#0" target="_blank" rel="noreferrer">
                            &nbsp;
                        </a>
                    </div>
                </footer>
            </>
        );
    };

    /**
     * Returns the content page wrapped in the layout template.
     * @param desktop - if the screen size is desktop
     * @returns the content page wrapped in the layout template
     */
    const renderContent = (desktop: boolean): React.ReactNode | undefined => {
        const { content } = props;

        return (
            <div className={styles.pageLayout}>
                <div className={styles.gradient}></div>
                <div className={styles.pageContent}>
                    <div className={styles.line}>
                        <p>TOP 100 SPORTS</p>
                    </div>
                </div>
                <Layout hidden={props.loading}>
                    <Content>{content(desktop)}</Content>
                </Layout>
            </div>
        );
    };

    /**
     * Returns a spinner when the page is initializing.
     * @returns a spinner.
     */
    const renderLoader = (): React.ReactNode | undefined => {
        return (
            <Layout className={styles.main}>
                <Spin size="large" className={styles.spinner}></Spin>
            </Layout>
        );
    };

    return (
        <Layout>
            {renderHeader()}
            {renderContent(desktop)}
            {props.loading && renderLoader()}
            {renderFooter()}
        </Layout>
    );
};
export default LayoutMarketingComponent;

interface Props {
    content: (desktop: boolean) => React.ReactNode | undefined;
    loading?: boolean;
}
