import Icon from '@ant-design/icons';
import { Button, Col, Collapse, message, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import collegeDivisionApi from '../../../apis/CollegeDivisionApi';
import divisionApi from '../../../apis/DivisionApi';
import eventApi from '../../../apis/EventApi';
import notificationApi from '../../../apis/NotificationApi';
import organizationApi from '../../../apis/OrganizationApi';
import sportApi from '../../../apis/SportApi';
import teamApi from '../../../apis/TeamApi';
import CustomContext from '../../../context/CustomContext';
import { Division, Role, ScheduledNotification, Team } from '../../../model/Entities';
import { ReactComponent as removeSvg } from '../../../resources/images/ico-thrash.svg';
import notificationService from '../../../services/NotificationService';
import rolesService from '../../../services/RolesService';
import styles from './ScheduledNotificationComponent.module.scss';

const { Panel } = Collapse;

/**
 * Returns the Scheduled Notification component.
 * @param props the props
 * @returns the Scheduled Notification component.
 */
const ScheduledNotificationComponent: React.FunctionComponent<ScheduledNotificationComponentProps> = (props) => {
    /*** HOOKS ***/
    const { notification, onUpdate, roles } = props;
    const intl = useIntl();
    const context = useContext(CustomContext);
    const { auth } = context;
    const [loading, setLoading] = useState<'loading' | 'deleting'>();
    const [recipients, setRecipients] = useState<JSX.Element>();
    const notificationRoles =
        notification.roleIds && notification.roleIds.length > 0 ? (
            roles
                .filter((role) => notification.roleIds?.includes(role.id!))
                .flatMap((r) => intl.formatMessage({ id: `notifications.${r.name!}` }))
                .join(', ')
        ) : (
            <FormattedMessage id="scheduledNotifications.All" />
        );

    /*** EFFECTS ***/
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                let tooltip: JSX.Element = <></>;

                // Depending of the recipients type of the notification, we display different messages
                if (notification.usersSource === 'All') {
                    tooltip = getEverybodyRecipients();
                } else if (notification.usersSource === 'Organization') {
                    tooltip = await getOrganizationsRecipients(notification);
                } else if (notification.usersSource === 'Event' && notification.eventId !== undefined) {
                    tooltip = await getEventsRecipients(notification);
                } else if (notification.usersSource === 'College') {
                    tooltip = await getCollegesDivisionsRecipients(notification);
                }

                setRecipients(tooltip);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };

        init();
    }, [intl, notification]);

    /*** METHODS ***/
    const deleteNotification = async () => {
        try {
            setLoading('deleting');

            await notificationApi.delete(notification);
            message.success(intl.formatMessage({ id: 'status.deleted' }));

            onUpdate();
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const showDeleteModal = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'scheduledNotifications.deleteScheduledNotificationsModal.title' }),
            content: intl.formatMessage({ id: 'scheduledNotifications.deleteScheduledNotificationsModal.description' }),
            onOk: deleteNotification,
            okButtonProps: { loading: loading === 'deleting' },
        });
    };

    /*** COMPONENTS ***/
    return (
        <Collapse defaultActiveKey={['0']} className={styles.collapse}>
            <Panel
                className={styles.panel}
                header={
                    <Row gutter={[28, 0]}>
                        <Col span={15}>
                            <div className={styles.data}>
                                <h3>{notification.subject ? notification.subject : notification.body}</h3>
                                <p>
                                    <span>
                                        {notification.destinationType === 'All' ? (
                                            <>
                                                <FormattedMessage id="scheduledNotifications.Push" />
                                                {', '}
                                                <FormattedMessage id="scheduledNotifications.Email" />
                                            </>
                                        ) : (
                                            <FormattedMessage
                                                id={`scheduledNotifications.${notification.destinationType}`}
                                            />
                                        )}
                                    </span>
                                    <span>
                                        <FormattedMessage id={`scheduledNotifications.${notification.usersSource}`} />
                                    </span>
                                </p>
                            </div>
                        </Col>
                        <Col span={9} className={styles.date}>
                            <>
                                <span>
                                    {notification.creator?.firstName} {notification.creator?.lastName},{' '}
                                </span>
                                <span>{notification.creator?.organizationName}</span>
                            </>
                            <div>
                                <FormattedDate
                                    value={notification.sendTime}
                                    month="2-digit"
                                    day="2-digit"
                                    year="numeric"
                                    hour="2-digit"
                                    minute="2-digit"
                                />
                                {notification.timeZone && (
                                    <>
                                        , <FormattedMessage id={`scheduledNotifications.${notification.timeZone}`} />
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                }
                key="1"
            >
                <>
                    <Row gutter={[28, 0]} className={styles.row}>
                        <Col span={24}>
                            <span className={styles.sectionTitle}>
                                <FormattedMessage id="scheduledNotifications.roles" />
                            </span>
                            <div className={styles.label}>{notificationRoles}</div>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]} className={styles.row}>
                        <Col span={24}>
                            <span className={styles.sectionTitle}>
                                <FormattedMessage id="scheduledNotifications.subscribers" />
                            </span>
                            <div className={styles.label}>{recipients}</div>
                        </Col>
                    </Row>
                    {(notification.destinationType === 'Email' || notification.destinationType === 'All') && (
                        <Row gutter={[28, 0]} className={styles.row}>
                            <Col span={24}>
                                <span className={styles.sectionTitle}>
                                    <FormattedMessage id="scheduledNotifications.subject" />
                                </span>
                                <div className={styles.label}>{notification.subject}</div>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={[28, 0]} className={styles.row}>
                        <Col span={24}>
                            <span className={styles.sectionTitle}>
                                <FormattedMessage id="scheduledNotifications.body" />
                            </span>
                            <div className={styles.label}>{notification.body}</div>
                        </Col>
                    </Row>
                    {(rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_ORGANIZATION_COACH']) ||
                        (rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_OWNER', 'ROLE_ORGANIZATION_STAFF']) &&
                            auth?.id === notification.creator?.userId)) && (
                        <Row gutter={[28, 10]} justify="space-between" className={styles.row}>
                            <Col span={24}>
                                <Button
                                    size="large"
                                    className={styles.btnDelete}
                                    icon={<Icon component={removeSvg} />}
                                    onClick={showDeleteModal}
                                >
                                    <FormattedMessage id="button.delete" tagName="span" />
                                </Button>
                            </Col>
                        </Row>
                    )}
                </>
            </Panel>
        </Collapse>
    );
};

interface ScheduledNotificationComponentProps {
    notification: ScheduledNotification;
    onUpdate: () => void;
    roles: Role[];
}

export default ScheduledNotificationComponent;

const getEverybodyRecipients = (): JSX.Element => {
    return (
        <Row gutter={[28, 0]}>
            <Col span={24}>
                <div className={styles.labelAux}>
                    · <FormattedMessage id="scheduledNotifications.everybody" />
                </div>
            </Col>
        </Row>
    );
};

const getOrganizationsRecipients = async (notification: ScheduledNotification): Promise<JSX.Element> => {
    const responses = await Promise.all([organizationApi.listAll(), sportApi.listAll()]);
    const organizations = responses[0];
    const sports = responses[1];
    const filteredSports = sports.filter((s) => s.id && notification.sports?.sportIds?.includes(s.id));
    const filteredOrganizations = organizations.filter(
        (o) => o.id && notification.organizations?.organizationIds?.includes(o.id),
    );
    let divisions: Division[] = [];
    let teams: Team[] = [];
    let sportId;
    let organizationId;
    if (
        notification.sports?.sportIds &&
        notification.sports?.sportIds.length === 1 &&
        notification.organizations?.organizationIds &&
        notification.organizations?.organizationIds.length === 1
    ) {
        sportId = notification.sports?.sportIds[0];
        organizationId = notification.organizations?.organizationIds[0];
        divisions = await divisionApi.list(sportId);
        teams = await teamApi.listByOrganization(organizationId, false, sportId);
    }
    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <div className={styles.labelAux}>
                        ·{' '}
                        {notification.sports?.all ? (
                            <FormattedMessage id="scheduledNotifications.allSports" />
                        ) : (
                            filteredSports
                                ?.map((s) => {
                                    return s.name;
                                })
                                .join(', ')
                        )}
                    </div>
                </Col>
            </Row>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <div className={styles.labelAux}>
                        ·{' '}
                        {notification.organizations?.all ? (
                            <FormattedMessage id="scheduledNotifications.allAffiliates" />
                        ) : (
                            filteredOrganizations
                                ?.map((o) => {
                                    return o.name;
                                })
                                .join(', ')
                        )}
                    </div>
                </Col>
            </Row>
            {notification.divisions?.all ? (
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <div className={styles.labelAux}>
                            · <FormattedMessage id="scheduledNotifications.allDivisions" />
                        </div>
                    </Col>
                </Row>
            ) : (
                sportId !== undefined &&
                organizationId &&
                notification.divisions?.division?.map((division) => {
                    const divisionName = divisions.find((d) => d.id === division.divisionId)?.name;
                    return (
                        <Row gutter={[28, 0]}>
                            <Col span={24}>
                                <div className={styles.labelAux}>
                                    · {divisionName}
                                    {': '}
                                    {division.teams?.all ? (
                                        <FormattedMessage id="scheduledNotifications.allTeams" />
                                    ) : (
                                        teams
                                            ?.filter((team) => team.id && division.teams?.teamIds?.includes(team.id))
                                            .map((t) => {
                                                return t.name;
                                            })
                                            .join(', ')
                                    )}
                                </div>
                            </Col>
                        </Row>
                    );
                })
            )}
        </>
    );
};

const getEventsRecipients = async (notification: ScheduledNotification): Promise<JSX.Element> => {
    const event = await eventApi.get(notification.eventId!);
    return (
        <Row gutter={[28, 0]}>
            <Col span={24}>
                <div className={styles.labelAux}>· {event.name}</div>
            </Col>
        </Row>
    );
};

const getCollegesDivisionsRecipients = async (notification: ScheduledNotification): Promise<JSX.Element> => {
    const responses = await Promise.all([collegeDivisionApi.list(), sportApi.listAll()]);
    const collegeDivisions = responses[0];
    const sports = responses[1];
    const filteredSports = sports.filter((s) => s.id && notification.sports?.sportIds?.includes(s.id));
    const filteredCollegeDivisions = collegeDivisions.filter(
        (d) => d.id && notification.collegeDivisions?.collegeDivisionIds?.includes(d.id),
    );
    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <div className={styles.labelAux}>
                        ·{' '}
                        {notification.sports?.all ? (
                            <FormattedMessage id="scheduledNotifications.allSports" />
                        ) : (
                            filteredSports
                                ?.map((s) => {
                                    return s.name;
                                })
                                .join(', ')
                        )}
                    </div>
                </Col>
            </Row>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <div className={styles.labelAux}>
                        ·{' '}
                        {notification.collegeDivisions?.all ? (
                            <FormattedMessage id="scheduledNotifications.allCollegesDivisions" />
                        ) : (
                            filteredCollegeDivisions
                                ?.map((cd) => {
                                    return cd.name;
                                })
                                .join(', ')
                        )}
                    </div>
                </Col>
            </Row>
        </>
    );
};
