import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, List, message, Modal } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import eventApi from '../../../../../apis/EventApi';
import userApi from '../../../../../apis/UserApi';
import { Event, FamilyMember } from '../../../../../model/Entities';
import avatar from '../../../../../resources/images/profile-placeholder.png';
import notificationService from '../../../../../services/NotificationService';
import styles from './AddExistingPlayerModal.module.scss';

class AddExistingPlayerModal extends Component<Props, State> {
    readonly pageSize = 4;

    constructor(props: Props) {
        super(props);
        this.state = { users: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    saveExistingUser = async (user: FamilyMember) => {
        const { event } = this.props;
        try {
            this.setState({ loading: `saving-${user.userId}` });

            await eventApi.addExistingPlayer(event?.id!, user.userId!);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (error.response.status === 409 && error.response.data) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: error.response.data.message as string },
                ]);
            } else {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    search = async (searchText?: string) => {
        const { event } = this.props;
        try {
            this.setState({ loading: 'loading' });

            const usersPage = searchText
                ? await userApi.list(this.pageSize, 1, searchText, 'player', event.sportId)
                : undefined;
            const users = usersPage?.items ? usersPage.items : [];
            this.setState({ users, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderExistingUserList = (): React.ReactElement | undefined => {
        const { users, loading, searchText } = this.state;
        return (
            <>
                <Search
                    placeholder={this.props.intl.formatMessage({ id: 'button.search' })}
                    className={styles.search}
                    enterButton={''}
                    allowClear={true}
                    onSearch={this.search}
                />
                {users.length === 0 && searchText ? (
                    <p className={styles.nofound}>
                        <FormattedMessage id="status.userNotFound" />
                    </p>
                ) : (
                    <List
                        grid={{
                            gutter: 0,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 1,
                            xl: 1,
                            xxl: 1,
                        }}
                        className={styles.list}
                        dataSource={users}
                        locale={{ emptyText: <></> }}
                        renderItem={(user) => (
                            <List.Item key={user.userId} className={styles.listItem}>
                                <div className={styles.userList}>
                                    <div className={styles.user}>
                                        <Avatar className={styles.avatar} size={64} src={user.photoUrl || avatar} />
                                        <p>
                                            <Link to={`/parents/${user.userId}`} target="_blank">
                                                {`${user.givenName} ${user.familyName}`}
                                            </Link>
                                            <span className={styles.email}>{user.userName}</span>
                                        </p>
                                    </div>

                                    <Button
                                        className={styles.addUserButton}
                                        type="text"
                                        icon={<PlusOutlined />}
                                        onClick={() => this.saveExistingUser(user)}
                                        loading={loading === `saving-${user.userId}`}
                                    >
                                        <FormattedMessage id="button.add" tagName="span" />
                                    </Button>
                                </div>
                                <Divider></Divider>
                            </List.Item>
                        )}
                        loading={loading === 'loading'}
                    />
                )}
            </>
        );
    };

    render() {
        return (
            <Modal
                title={<FormattedMessage id="events.event.athletes.existingPlayerModal.title" />}
                className={styles.modal}
                width={600}
                visible={true}
                onCancel={this.props.onCancel}
                footer={[]}
            >
                {<div>{this.renderExistingUserList()}</div>}
            </Modal>
        );
    }
}
export default injectIntl(AddExistingPlayerModal);

interface Props extends WrappedComponentProps {
    event: Event;
    onUpdate: () => void;
    onCancel: () => void;
}

interface State {
    loading?: any;
    users: FamilyMember[];
    searchText?: string;
}
