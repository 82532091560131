import { Config } from '../model/Entities';
import apiAxios from './CustomAxios';

class ConfigApi {
    get = async (): Promise<Config> => {
        const response = await apiAxios.get<Config>(`/v1/configs`);
        return response.data;
    };
}
const configApi: ConfigApi = new ConfigApi();
export default configApi;
