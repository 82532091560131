import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import organizationSportsApi from '../../../../../apis/OrganizationSportsApi';
import { Organization, OrganizationSport, Sport } from '../../../../../model/Entities';
import notificationService from '../../../../../services/NotificationService';
import styles from './OrganizationSportsComponent.module.scss';
import OrganizationSportModal from './OrganizationSportModal/OrganizationSportModal';
import sportApi from '../../../../../apis/SportApi';

class OrganizationSportsComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { organizationSports: [], sports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: this.props.loading });

            const responses = await Promise.all([
                organizationSportsApi.get(this.props.organization.id!),
                sportApi.listAll(),
            ]);
            const organizationSports = responses[0];
            const sports = responses[1];

            this.setState({ organizationSports, sports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    getSportName = (sportId: number): string | undefined => {
        const { sports } = this.state;
        return sports.find((s) => s.id === sportId)?.name;
    };

    showAddSportModal = (addSportModalVisible: boolean, organizationSport?: OrganizationSport) => {
        this.setState({ addSportModalVisible, organizationSport });
    };

    list = async () => {
        const { organization } = this.props;
        try {
            this.setState({ loading: 'loading' });

            const organizationSports = await organizationSportsApi.get(organization?.id!);

            this.showAddSportModal(false);

            this.setState({ organizationSports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderTable = (): React.ReactElement | undefined => {
        const { organizationSports, loading } = this.state;
        const columns: ColumnsType<OrganizationSport> = [
            {
                title: <FormattedMessage id="organization.organizationSportName" />,
                dataIndex: 'name',
                key: 'name',
                render: (value: string, organizationSport: OrganizationSport) => (
                    <span className={styles.link} onClick={() => this.showAddSportModal(true, organizationSport)}>
                        {value}
                    </span>
                ),
            },
            {
                title: <FormattedMessage id="organization.organizationSportSport" />,
                dataIndex: 'sportId',
                key: 'sportId',
                render: (value: number, organizationSport: OrganizationSport) => (
                    <span className={styles.link} onClick={() => this.showAddSportModal(true, organizationSport)}>
                        {this.getSportName(value)}
                    </span>
                ),
            },
        ];

        return (
            <Table
                dataSource={organizationSports}
                columns={columns}
                pagination={false}
                rowKey="id"
                onChange={this.list}
                loading={loading === 'initializing'}
            />
        );
    };

    renderOrganizationSportModal = (): React.ReactElement | undefined => {
        const { organization } = this.props;
        const { organizationSport, addSportModalVisible, sports } = this.state;

        if (addSportModalVisible) {
            return (
                <OrganizationSportModal
                    onUpdate={this.list}
                    onCancel={() => this.showAddSportModal(false)}
                    organizationId={organization?.id!}
                    sports={sports}
                    organizationSport={organizationSport}
                />
            );
        }
    };

    renderContent = (): React.ReactElement | undefined => {
        const { organization } = this.props;
        const { organizationSports, sports } = this.state;
        if (organization?.id) {
            return (
                <div>
                    <div>
                        <Divider></Divider>
                        <span className={styles.sports}>
                            <FormattedMessage id="organization.sports" />
                        </span>
                        <Button
                            size="large"
                            shape="round"
                            icon={<PlusOutlined />}
                            onClick={() => this.showAddSportModal(true)}
                            hidden={sports.length === organizationSports.length}
                            className={styles.button}
                        >
                            <FormattedMessage id="organization.addSport" tagName="span" />
                        </Button>
                    </div>
                    <div>{this.renderTable()}</div>
                </div>
            );
        }
    };

    render() {
        return (
            <>
                {this.renderContent()}
                {this.renderOrganizationSportModal()}
            </>
        );
    }
}
export default injectIntl(OrganizationSportsComponent);

interface Props extends WrappedComponentProps {
    organization: Organization;
    loading?: 'initializing' | 'saving' | 'deleting' | 'loading';
}

interface State {
    loading?: 'initializing' | 'saving' | 'deleting' | 'loading';
    addSportModalVisible?: boolean;
    organizationSport?: OrganizationSport;
    organizationSports: OrganizationSport[];
    sports: Sport[];
}
