import Icon from '@ant-design/icons';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    FormInstance,
    Input,
    InputNumber,
    Menu,
    message,
    Row,
    Select,
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import divisionApi from '../../../../../apis/DivisionApi';
import eventApi from '../../../../../apis/EventApi';
import eventPlayerApi from '../../../../../apis/EventPlayerApi';
import positionApi from '../../../../../apis/PositionApi';
import LayoutComponent from '../../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../../context/CustomContext';
import { Division, Event, EventNewPlayer, Position } from '../../../../../model/Entities';
import { countries, CountryType, genders } from '../../../../../model/Types';
import { ReactComponent as backSvg } from '../../../../../resources/images/ico-back.svg';
import { ReactComponent as eventSettingsSvg } from '../../../../../resources/images/ico-eventSettings.svg';
import { ReactComponent as eventLeaderboardSvg } from '../../../../../resources/images/ico-leaderboard.svg';
import { ReactComponent as positionsSvg } from '../../../../../resources/images/ico-positions.svg';
import { ReactComponent as eventSummarySvg } from '../../../../../resources/images/ico-summary.svg';
import HeadMetadata from '../../../../../services/HeadMetadata';
import metricService from '../../../../../services/MetricService';
import notificationService from '../../../../../services/NotificationService';
import stateService from '../../../../../services/StateService';
import styles from './EventPlayerPage.module.scss';

class EventPlayerPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { divisions: [], positions: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const eventNewPlayer: EventNewPlayer = {};
            const event = await eventApi.get(+this.props.match.params.id);

            const responses = await Promise.all([divisionApi.list(event.sportId!), positionApi.list(event.sportId!)]);
            const divisions = responses[0];
            const positions = responses[1];

            this.formRef.current!.setFieldsValue(eventNewPlayer);
            this.setState({ eventNewPlayer, event, divisions, positions });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    add = async (invite?: boolean) => {
        const eventId = this.props.match.params.id;

        try {
            invite === true ? this.setState({ loading: 'addingAndInviting' }) : this.setState({ loading: 'adding' });

            const values = await this.formRef.current?.validateFields();
            const eventNewPlayer: EventNewPlayer = Object.assign({}, this.state.eventNewPlayer, values);
            eventNewPlayer.dateOfBirth = eventNewPlayer.dateOfBirth ? eventNewPlayer.dateOfBirth.valueOf() : undefined;
            eventNewPlayer.dateOfBirth = Math.round((eventNewPlayer.dateOfBirth as number) / 1000);
            eventNewPlayer.positions =
                eventNewPlayer.positions && eventNewPlayer.positions?.length !== 0
                    ? ((eventNewPlayer.positions as string[]).join(',') as string)
                    : undefined;
            eventNewPlayer.email =
                eventNewPlayer.email && eventNewPlayer.email !== '' ? eventNewPlayer.email?.toLowerCase() : undefined;
            eventNewPlayer.height = metricService.toTotalInches(values.height, values.heightInches);
            eventNewPlayer.parentEmailAddress =
                eventNewPlayer.parentEmailAddress !== '' ? eventNewPlayer.parentEmailAddress : undefined;
            eventNewPlayer.parentPhoneNumber =
                eventNewPlayer.parentPhoneNumber !== '' ? eventNewPlayer.parentPhoneNumber : undefined;
            eventNewPlayer.phoneNumber = eventNewPlayer.phoneNumber !== '' ? eventNewPlayer.phoneNumber : undefined;
            eventNewPlayer.invite = invite;

            await eventPlayerApi.create(+eventId, eventNewPlayer);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            this.props.history.push(`/events/${eventId}/players`);
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: 'player.status.duplicate' },
                ]);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeCountry = (country?: CountryType) => {
        this.formRef.current?.resetFields(['state']);
        this.setState({ country });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { event } = this.state;

        return (
            <>
                <div className={styles.header}>
                    <h1>
                        <FormattedMessage id="player.title" />
                    </h1>
                </div>
                <div className={styles.subheader}>
                    {event && desktop && (
                        <h2>
                            {event?.name} <FormattedDate value={event?.startDate}></FormattedDate>
                        </h2>
                    )}
                </div>
            </>
        );
    };

    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { auth } = this.context;
        const eventId = this.props.match.params.id;
        const { event } = this.state;

        const isMenuVisible = auth?.authorities.includes('ROLE_ADMIN');
        const isAthletesMenuVisible =
            auth?.authorities.includes('ROLE_ADMIN') ||
            (auth?.authorities.includes('ROLE_ORGANIZATION') && event?.organizationId === auth.organizationId);

        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="event-dashboard"
                        icon={<Icon component={backSvg} />}
                        onClick={() => this.props.history.push(`/events/${eventId}/players`)}
                    >
                        <Link to={`/events/${eventId}/players`}>
                            <FormattedMessage id="events.event.backToAthletes" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                <Menu.ItemGroup key="event-menu" title={!collapsed && <FormattedMessage id="events.event.info" />}>
                    <Menu.Item
                        key="event"
                        icon={<Icon component={eventSummarySvg} />}
                        onClick={() => this.props.history.push(`/events/${eventId}/summary`)}
                    >
                        <Link to={`/events/${eventId}/summary`}>
                            <FormattedMessage id="events.event.summary" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-settings"
                        icon={<Icon component={eventSettingsSvg} />}
                        hidden={!isMenuVisible}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/settings`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/settings`}>
                            <FormattedMessage id="events.event.settings" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="leaderboard"
                        icon={<Icon component={eventLeaderboardSvg} />}
                        onClick={() => this.props.history.push(`/events/${eventId}/leaderboard`)}
                    >
                        <Link to={`/events/${eventId}/leaderboard`}>
                            <FormattedMessage id="events.event.leaderboard" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-players"
                        icon={<Icon component={positionsSvg} />}
                        hidden={!isAthletesMenuVisible}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/players`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/players`}>
                            <FormattedMessage id="events.event.athletes" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const eventId = this.props.match.params.id;
        const { loading, divisions, positions } = this.state;

        const gendersOptions = genders.map((gender) => (
            <Select.Option key={gender} value={gender}>
                <FormattedMessage id={gender} />
            </Select.Option>
        ));
        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));
        const divisionOptions = divisions?.map((division) => (
            <Select.Option key={division.id} value={division.name!}>
                {division.name}
            </Select.Option>
        ));
        const positionOptions = positions?.map((position) => (
            <Select.Option key={position.id} value={position.code!}>
                {position.code}
            </Select.Option>
        ));

        const minGraduationYear = moment().year() - 19;
        const maxGraduationYear = moment().year() + 19;

        return (
            <Form ref={this.formRef} colon={false} className={styles.form} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.firstName" />}
                            name="firstName"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                    message: <FormattedMessage id="status.name" />,
                                },
                            ]}
                        >
                            <Input maxLength={200} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.lastName" />}
                            name="lastName"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                    message: <FormattedMessage id="status.name" />,
                                },
                            ]}
                        >
                            <Input maxLength={200} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.email" />}
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: <FormattedMessage id="status.email.invalid" />,
                                },
                            ]}
                        >
                            <Input maxLength={200} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.gradYear" />}
                            name="gradYear"
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id="status.mandatory" />,
                                },
                                {
                                    min: minGraduationYear,
                                    type: 'number',
                                    max: maxGraduationYear,
                                    message: `${this.props.intl.formatMessage({
                                        id: 'status.gradYear',
                                    })} ${minGraduationYear} ${this.props.intl.formatMessage({
                                        id: 'and',
                                    })} ${maxGraduationYear}`,
                                },
                            ]}
                        >
                            <InputNumber type="number" min={0} max={maxGraduationYear} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="player.dateOfBirth" />} name="dateOfBirth">
                            <DatePicker size="small" format="MM-DD-YYYY" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.division" />}
                            name="division"
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id="status.mandatory" />,
                                },
                            ]}
                        >
                            <Select>{divisionOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="player.school" />} name="school">
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.grade" />}
                            name="grade"
                            rules={[{ pattern: /\b([1-9]|1[0-2])\b/, message: <FormattedMessage id="status.grade" /> }]}
                        >
                            <InputNumber type="number" min={1} max={12} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.gender" />}
                            name="gender"
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id="status.mandatory" />,
                                },
                            ]}
                        >
                            <Select>{gendersOptions}</Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.phoneNumber" />}
                            name="phoneNumber"
                            rules={[
                                {
                                    pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                    message: <FormattedMessage id="status.phoneNumber" />,
                                },
                            ]}
                        >
                            <Input type="number" maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="player.address" />} name="address">
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="player.city" />} name="city">
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="player.country" />} name="country">
                            <Select onChange={(value: CountryType) => this.changeCountry(value)}>
                                {countryOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="player.state" />} name="state">
                            <Select>{this.renderStates()}</Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="player.zipCode" />} name="zipCode">
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={12} md={4}>
                        <Form.Item label={<FormattedMessage id="player.heightFt" />} name="height">
                            <InputNumber type="number" min={0} max={7} size="small" precision={0} />
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Item label={<FormattedMessage id="player.heightInches" />} name="heightInches">
                            <InputNumber type="number" min={0} max={11} precision={0} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="player.weight" />} name="weight">
                            <InputNumber type="number" min={0} maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="player.positions" />} name="positions">
                            <Select mode="multiple">{positionOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.parentFirstName" />}
                            name="parentFirstName"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.parentLastName" />}
                            name="parentLastName"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.parentEmailAddress" />}
                            name="parentEmailAddress"
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    type: 'email',
                                    message: <FormattedMessage id="status.email.invalid" />,
                                },
                            ]}
                        >
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label={<FormattedMessage id="player.parentPhoneNumber" />}
                            name="parentPhoneNumber"
                            rules={[
                                {
                                    pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                    message: <FormattedMessage id="status.phoneNumber" />,
                                },
                            ]}
                        >
                            <Input maxLength={100} size="small" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={8}>
                        <Form.Item name="isFreeAgent" valuePropName="checked">
                            <Checkbox>
                                <FormattedMessage id="player.isFreeAgent" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[28, 0]}>
                    <Col xs={24} md={16} className={styles.buttons}>
                        <Link to={`/events/${eventId}/players`}>
                            <Button size="large" className={styles.back}>
                                <FormattedMessage id="button.cancel" />
                            </Button>
                        </Link>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type="primary"
                                    size="large"
                                    className={styles.save}
                                    icon={<PlusOutlined />}
                                    loading={loading === 'adding'}
                                    onClick={() => this.add(undefined)}
                                >
                                    <FormattedMessage id="button.add" />
                                </Button>
                            )}
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type="primary"
                                    size="large"
                                    className={styles.save}
                                    icon={<PlusOutlined />}
                                    loading={loading === 'addingAndInviting'}
                                    onClick={() => this.add(true)}
                                >
                                    <FormattedMessage id="button.addAndInvite" />
                                </Button>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    render() {
        const { loading, event } = this.state;
        const fullName =
            event &&
            `${this.props.intl.formatMessage({
                id: 'event.user.meta.title',
            })} - ${event?.name} - ${this.props.intl.formatMessage({
                id: 'events.event.event.meta.title',
            })}`;
        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="event-players"
                    header={this.renderHeader}
                    menu={this.renderMenu}
                    content={this.renderContent}
                    loading={loading === 'initializing'}
                />
            </>
        );
    }
}
export default injectIntl(EventPlayerPage);

type ParamsType = { id: string; userId: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    loading?: 'initializing' | 'adding' | 'addingAndInviting';
    event?: Event;
    eventNewPlayer?: EventNewPlayer;
    divisions: Division[];
    positions: Position[];
    country?: CountryType;
}
