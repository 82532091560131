import { PlusOutlined } from '@ant-design/icons';
import {
    Avatar,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    FormInstance,
    Input,
    InputNumber,
    List,
    message,
    Modal,
    Radio,
    Row,
    Select,
} from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import divisionApi from '../../apis/DivisionApi';
import familyApi from '../../apis/FamilyApi';
import positionApi from '../../apis/PositionApi';
import sportApi from '../../apis/SportApi';
import userApi from '../../apis/UserApi';
import CustomContext from '../../context/CustomContext';
import { ChildRegistered, Division, FamilyMember, Position, Sport } from '../../model/Entities';
import { countries, CountryType, genders, shirtSizes } from '../../model/Types';
import avatar from '../../resources/images/profile-placeholder.png';
import metricService from '../../services/MetricService';
import notificationService from '../../services/NotificationService';
import stateService from '../../services/StateService';
import styles from './AddUserModal.module.scss';

class AddUserModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();
    readonly pageSize = 4;

    constructor(props: Props) {
        super(props);
        this.state = { users: [], sports: [], userState: 'new', divisions: [], positions: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            let sports: Sport[] = [];
            let moreInfo;
            if (this.props.userType === 'player') {
                sports = await sportApi.listAll();
                moreInfo = false;
            }

            const emailDisabled = true;

            this.setState({ sports, emailDisabled, moreInfo });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeUserState = async () => {
        let userState: 'new' | 'existing' = 'existing';

        if (this.state.userState === 'existing') {
            userState = 'new';
            this.setState({ userState });
        } else if (this.state.userState === 'new') {
            userState = 'existing';
            this.setState({ userState });
        }
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    save = async () => {
        const { familyId, userType } = this.props;
        try {
            this.setState({ loading: 'saving' });

            const values = await this.formRef.current?.validateFields();

            const familyMember: FamilyMember = Object.assign({}, values);
            if (userType === 'parent') {
                await familyApi.addParent(familyId, familyMember);
                message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
                this.formRef.current?.resetFields();
                this.props.onUpdate();
            } else {
                const player = await familyApi.addPlayer(familyId, familyMember);
                message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
                this.formRef.current?.resetFields();
                this.setState({ moreInfo: true, player });
            }
        } catch (error: any) {
            if (!error.errorFields) {
                if (this.context.auth?.authorities.includes('ROLE_ADMIN')) {
                    notificationService.displayError(error, this.props.intl, [
                        { status: 409, message: 'status.duplicatedUserAdmin' },
                    ]);
                } else {
                    notificationService.displayError(error, this.props.intl, [
                        { status: 409, message: 'status.duplicatedUser' },
                    ]);
                }
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    savePlayerMoreInfo = async () => {
        const { familyId } = this.props;
        const { player } = this.state;
        try {
            this.setState({ loading: 'saving' });

            const values = await this.formRef.current?.validateFields();

            const child: ChildRegistered = Object.assign({}, values);
            child.height =
                values.height || values.heightInches
                    ? metricService.toTotalInches(values.height, values.heightInches)
                    : undefined;
            child.dateOfBirth = values.dateOfBirth ? values.dateOfBirth.valueOf() : undefined;

            player && (await familyApi.updatePlayer(familyId, player.userId!, child));
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            this.formRef.current?.resetFields();

            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    saveExistingUser = async (user: FamilyMember) => {
        const { familyId, userType } = this.props;
        try {
            this.setState({ loading: `saving-${user.userId}` });

            const familyMember: FamilyMember = Object.assign({}, user);
            userType === 'parent'
                ? await familyApi.addExistingParent(familyId, familyMember)
                : await familyApi.addExistingPlayer(familyId, familyMember);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    search = async (searchText?: string) => {
        const { userType } = this.props;
        try {
            this.setState({ loading: 'loading' });

            const usersPage = searchText ? await userApi.list(this.pageSize, 1, searchText, userType) : undefined;
            const users = usersPage?.items ? usersPage.items : [];
            this.setState({ users, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    resetEmail = () => {
        let emailDisabled;
        if (this.state.emailDisabled === true) {
            emailDisabled = false;
        } else {
            emailDisabled = true;
        }
        this.formRef.current?.resetFields(['email']);
        this.setState({ emailDisabled });
    };

    changeSport = async (sportId: number) => {
        this.formRef.current?.resetFields(['divisionId']);
        const responses = await Promise.all([divisionApi.list(sportId), positionApi.list(sportId)]);
        const divisions = responses[0];
        const positions = responses[1];
        this.setState({ divisions, positions });
    };

    /**
     * Reset the state when the country is changed in the form
     * @param country
     */
    changeCountry = (country?: CountryType) => {
        this.formRef.current?.resetFields(['state']);
        this.setState({ country });
    };

    /*** COMPONENTS ***/
    renderRadioButtons = (): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { userState, moreInfo } = this.state;
        return (
            <>
                {auth?.authorities.includes('ROLE_ADMIN') && !moreInfo && (
                    <Radio.Group value={userState} onChange={this.changeUserState}>
                        <Radio key="existing" value="existing">
                            <FormattedMessage id="parent.profile.modal.existingUser" />
                        </Radio>
                        <Radio key="new" value="new">
                            <FormattedMessage id="parent.profile.modal.newUser" />
                        </Radio>
                    </Radio.Group>
                )}
            </>
        );
    };

    renderParentInfo = (): React.ReactElement | undefined => {
        return (
            <div className={styles.userInfo}>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.firstName" />}
                        name="givenName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.lastName" />}
                        name="familyName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.username" />}
                        name="userName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[a-z0-9_.-]{4,32}$/,
                                message: <FormattedMessage id="status.characters" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.email" />}
                        name="email"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        );
    };

    renderPlayerInfo = (): React.ReactElement | undefined => {
        const { sports, emailDisabled, divisions } = this.state;

        const genderOptions = genders.map((gender) => (
            <Select.Option key={gender} value={gender}>
                <FormattedMessage id={gender} />
            </Select.Option>
        ));

        const sportOptions = sports.map((sport) => (
            <Select.Option key={sport.id} value={sport.id!}>
                {sport.name}
            </Select.Option>
        ));

        const divisionOptions = divisions.map((division) => (
            <Select.Option key={division.id} value={division.id!}>
                {division.name}
            </Select.Option>
        ));

        return (
            <div className={styles.userInfo}>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.firstName" />}
                        name="givenName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.lastName" />}
                        name="familyName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                message: <FormattedMessage id="status.name" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.username" />}
                        name="userName"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^[a-z0-9_.-]{4,32}$/,
                                message: <FormattedMessage id="status.characters" />,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox onClick={this.resetEmail} defaultChecked>
                            <FormattedMessage id="parent.profile.modal.emailCheckbox" />
                        </Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.email" />}
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />,
                            },
                        ]}
                    >
                        <Input disabled={emailDisabled}></Input>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.gender" />}
                        name="genderType"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select>{genderOptions}</Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.sport" />}
                        name="sportId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select onChange={(value: number) => this.changeSport(value)}>{sportOptions}</Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="parent.profile.modal.division" />}
                        name="divisionId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select>{divisionOptions}</Select>
                    </Form.Item>
                </Form>
            </div>
        );
    };

    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderPlayerMoreInfo = (): React.ReactElement | undefined => {
        const { positions } = this.state;
        const positionOptions = positions.map((p) => (
            <Checkbox key={p.id} value={p.id}>
                <span>{p.code}</span> {p.name}
            </Checkbox>
        ));

        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));

        const shirtSizeOptions = shirtSizes.map((s) => (
            <Select.Option key={s} value={s}>
                {s}
            </Select.Option>
        ));

        const minGraduationYear = moment().year() - 19;
        const maxGraduationYear = moment().year() + 19;

        return (
            <div className={styles.userInfo}>
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="signupChild.phone" />}
                        name="phoneNumber"
                        rules={[
                            {
                                pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                message: <FormattedMessage id="status.phoneNumber" />,
                            },
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.country" />}
                        name="country"
                        className={styles.select}
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select onChange={(value: CountryType) => this.changeCountry(value)}>{countryOptions}</Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.state" />}
                        name="state"
                        className={styles.select}
                    >
                        <Select allowClear>{this.renderStates()}</Select>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="signupChild.streetAddress" />} name="address">
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="signupChild.city" />} name="city">
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="signupChild.zip" />} name="zipCode">
                        <Input size="large" />
                    </Form.Item>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8} className={styles.formRow8}>
                            <Form.Item
                                label={<FormattedMessage id="signupChild.height" />}
                                name="height"
                                rules={[
                                    {
                                        type: 'number',
                                        message: this.props.intl.formatMessage({
                                            id: 'status.height',
                                        }),
                                    },
                                ]}
                            >
                                <InputNumber type="number" min={0} max={7} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8} className={styles.formRow8}>
                            <Form.Item label={<FormattedMessage id="signupChild.heightInches" />} name="heightInches">
                                <InputNumber type="number" min={0} max={11} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label={<FormattedMessage id="signupChild.weight" />}
                        name="weight"
                        rules={[
                            {
                                type: 'number',
                                message: this.props.intl.formatMessage({
                                    id: 'status.weight',
                                }),
                            },
                        ]}
                    >
                        <InputNumber type="number" min={0} maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="signupChild.positions" />} name="positions">
                        <Select mode="multiple">{positionOptions}</Select>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="signupChild.school" />} name="school">
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.grade" />}
                        name="grade"
                        rules={[{ pattern: /\b([1-9]|1[0-2])\b/, message: <FormattedMessage id="status.grade" /> }]}
                    >
                        <InputNumber type="number" min={1} max={12} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.gradYear" />}
                        name="gradYear"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                min: minGraduationYear,
                                type: 'number',
                                max: maxGraduationYear,
                                message: `${this.props.intl.formatMessage({
                                    id: 'status.gradYear',
                                })} ${minGraduationYear} ${this.props.intl.formatMessage({
                                    id: 'and',
                                })} ${maxGraduationYear}`,
                            },
                        ]}
                    >
                        <InputNumber type="number" min={0} max={maxGraduationYear} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.dateOfBirth" />}
                        name="dateOfBirth"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <DatePicker
                            size="small"
                            format="MM-DD-YYYY"
                            disabledDate={(current) => current.isAfter(moment())}
                        />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.shirtSize" />}
                        name="shirtSize"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select>{shirtSizeOptions}</Select>
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="signupChild.team" />} name="clubTeam">
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="signupChild.teamCoach" />} name="clubTeamCoach">
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.teamCoachEmail" />}
                        name="clubTeamCoachEmail"
                        rules={[
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />,
                            },
                        ]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="signupChild.teamCoachPhone" />}
                        name="clubTeamCoachPhone"
                        rules={[
                            {
                                pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                message: <FormattedMessage id="status.phoneNumber" />,
                            },
                        ]}
                    >
                        <Input maxLength={100} size="small" />
                    </Form.Item>
                </Form>
            </div>
        );
    };

    renderExistingUserList = (): React.ReactElement | undefined => {
        const { users, loading, searchText } = this.state;
        return (
            <>
                <Search
                    placeholder={this.props.intl.formatMessage({ id: 'button.search' })}
                    className={styles.search}
                    enterButton={''}
                    allowClear={true}
                    onSearch={this.search}
                />
                {users.length === 0 && searchText ? (
                    <p className={styles.nofound}>
                        <FormattedMessage id="status.userNotFound" />
                    </p>
                ) : (
                    <List
                        grid={{
                            gutter: 0,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 1,
                            xl: 1,
                            xxl: 1,
                        }}
                        className={styles.list}
                        dataSource={users}
                        locale={{ emptyText: <></> }}
                        renderItem={(user) => (
                            <List.Item key={user.userId} className={styles.listItem}>
                                <div className={styles.userList}>
                                    <div className={styles.user}>
                                        <Avatar className={styles.avatar} size={64} src={user.photoUrl || avatar} />
                                        <p>
                                            {this.props.userType === 'player' ? (
                                                <Link
                                                    to={`/players/${user.userId}/profile?sportId=${user.sports?.find(
                                                        Boolean,
                                                    )}`}
                                                    target="_blank"
                                                >
                                                    {`${user.givenName} ${user.familyName}`}
                                                </Link>
                                            ) : (
                                                <Link to={`/parents/${user.userId}`} target="_blank">
                                                    {`${user.givenName} ${user.familyName}`}
                                                </Link>
                                            )}
                                            <span className={styles.email}>{user.email}</span>
                                        </p>
                                    </div>

                                    <Button
                                        className={styles.addUserButton}
                                        type="text"
                                        icon={<PlusOutlined />}
                                        onClick={() => this.saveExistingUser(user)}
                                        loading={loading === `saving-${user.userId}`}
                                        disabled={user.familyId === this.props.familyId}
                                    >
                                        <FormattedMessage id="parent.profile.addUser" tagName="span" />
                                    </Button>
                                </div>
                                <Divider></Divider>
                            </List.Item>
                        )}
                        loading={loading === 'loading'}
                    />
                )}
            </>
        );
    };

    render() {
        const { loading, userState, moreInfo } = this.state;
        const { userType } = this.props;

        return (
            <Modal
                title={
                    userType === 'parent' ? (
                        <FormattedMessage id="parent.profile.modal.parentTitle" />
                    ) : (
                        <FormattedMessage id="parent.profile.modal.playerTitle" />
                    )
                }
                className={styles.modal}
                width={600}
                visible={true}
                onCancel={this.cancel}
                footer={[
                    <Button className={styles.btn} key="cancel" shape={'round'} onClick={this.cancel}>
                        <FormattedMessage id="button.cancel" />
                    </Button>,
                    <Button
                        key="save"
                        shape={'round'}
                        type="primary"
                        className={styles.btn}
                        onClick={moreInfo === true ? this.savePlayerMoreInfo : this.save}
                        loading={loading === 'saving'}
                        hidden={userState !== 'new'}
                    >
                        <FormattedMessage id="button.save" tagName="span" />
                    </Button>,
                ]}
            >
                {this.renderRadioButtons()}
                {<div hidden={userState === 'new'}>{this.renderExistingUserList()}</div>}
                {userType === 'parent' && <div hidden={userState === 'existing'}>{this.renderParentInfo()}</div>}
                {userType === 'player' && !moreInfo && (
                    <div hidden={userState === 'existing'}>{this.renderPlayerInfo()}</div>
                )}
                {userType === 'player' && moreInfo && (
                    <div hidden={userState === 'existing'}>{this.renderPlayerMoreInfo()}</div>
                )}
            </Modal>
        );
    }
}
export default injectIntl(AddUserModal);

interface Props extends WrappedComponentProps {
    onUpdate: () => void;
    onCancel: () => void;
    userType?: string;
    familyId: number;
}

interface State {
    loading?: any;
    userState?: 'existing' | 'new';
    users: FamilyMember[];
    sports: Sport[];
    emailDisabled?: boolean;
    searchText?: string;
    divisions: Division[];
    moreInfo?: boolean;
    positions: Position[];
    country?: CountryType;
    player?: FamilyMember;
}
