import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, WrappedComponentProps } from 'react-intl';

class HeadMetadata extends Component<Props> {
    getTitle = (): string | undefined => {
        let title: string | undefined;
        if (this.props.title) {
            title = `${this.props.title}`;
        } else if (this.props.titleKey) {
            title = `${this.props.intl.formatMessage({ id: this.props.titleKey })}`;
        } else {
            title = undefined;
        }

        return title;
    };

    getDescription = (): string => {
        let description: string;
        if (this.props.description) {
            description = this.props.description;
        } else if (this.props.descriptionKey) {
            description = this.props.intl.formatMessage({ id: this.props.descriptionKey });
        } else {
            description = this.props.intl.formatMessage({ id: 'head.description' });
        }

        return this.truncate(description, 145);
    };

    truncate = (text: string, length: number): string => {
        return text.length > length ? `${text.substring(0, length)}...` : text;
    };

    render() {
        return (
            <Helmet>
                <title>{this.getTitle()}</title>
                <meta name="description" content={this.getDescription()} />
            </Helmet>
        );
    }
}
export default injectIntl(HeadMetadata);

interface Props extends WrappedComponentProps {
    title?: string;
    titleKey?: string;
    description?: string;
    descriptionKey?: string;
}
