import { Page } from '../model/Elements';
import { NeedFeed } from '../model/Entities';
import apiAxios from './CustomAxios';

class NeedFeedApi {
    list = async (limit: number, page: number, sportId?: number, divisionId?: number): Promise<Page<NeedFeed>> => {
        const filtering = 'filtering.SportId';
        const filteringDivisionOperationName = 'filtering.DivisionId.Operation';
        const filteringDivisionOperationValue = divisionId ? 'Equal' : undefined;
        const filteringDivisionValue = 'filtering.DivisionId.Value';
        const response = await apiAxios.get<Page<NeedFeed>>('/v1/needs', {
            params: {
                limit,
                page,
                [filtering]: sportId,
                [filteringDivisionOperationName]: filteringDivisionOperationValue,
                [filteringDivisionValue]: divisionId ? divisionId : undefined,
            },
        });

        return response.data;
    };

    listForCollegeCoach = async (
        limit: number,
        page: number,
        collegeId: number,
        sportId?: number,
    ): Promise<Page<NeedFeed>> => {
        const filtering = 'filtering.SportId';
        const response = await apiAxios.get<Page<NeedFeed>>(`/v1/colleges/${collegeId}/needs`, {
            params: { limit, page, [filtering]: sportId },
        });

        return response.data;
    };

    create = async (collegeId: number, need: NeedFeed): Promise<NeedFeed> => {
        const response = await apiAxios.post<NeedFeed>(`/v1/colleges/${collegeId}/needs`, need);
        return response.data;
    };

    update = async (need: NeedFeed): Promise<NeedFeed> => {
        const response = await apiAxios.put<NeedFeed>(`/v1/needs/${need.id}`, need);
        return response.data;
    };

    delete = async (need: NeedFeed): Promise<void> => {
        const response = await apiAxios.delete<void>(`/v1/needs/${need.id}`);
        return response.data;
    };

    recommendPlayer = async (needId: number, playerId: string, message?: string): Promise<void> => {
        const response = await apiAxios.post<void>(`/v1/needs/${needId}/recommendations`, {
            playerId: playerId,
            message: message,
        });
        return response.data;
    };
}

const needFeedApi: NeedFeedApi = new NeedFeedApi();
export default needFeedApi;
