import { Button, Checkbox, List, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import notificationApi from '../../../apis/NotificationApi';
import organizationApi from '../../../apis/OrganizationApi';
import roleApi from '../../../apis/RoleApi';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/Elements';
import { Organization, Role, ScheduledNotification } from '../../../model/Entities';
import notificationService from '../../../services/NotificationService';
import rolesService from '../../../services/RolesService';
import ScheduledNotificationComponent from '../ScheduledNotificationComponent/ScheduledNotificationComponent';
import styles from './HistoryComponent.module.scss';

/**
 * Returns the history component.
 * @param props the props
 * @returns the history component.
 */
const HistoryComponent: React.FunctionComponent = (): React.ReactElement => {
    /** HOOKS ***/
    const intl = useIntl();
    const context = useContext(CustomContext);
    const { auth } = context;
    const [notifications, setNotifications] = useState<ScheduledNotification[]>([]);
    const [notificationsPage, setNotificationsPage] = useState<Page<ScheduledNotification>>();
    const [roles, setRoles] = useState<Role[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loading, setLoading] = useState<'loading' | 'loadingMore'>();
    const [searchText, setSearchText] = useState<string>();
    const [organizationId, setOrganizationId] = useState<string>();
    const [myNotifications, setMyNotifications] = useState<boolean>(false);
    const pageSize = 100;

    /*** EFFECTS ***/

    // init
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                const responses = await Promise.all([
                    roleApi.list(),
                    notificationApi.listHistory(pageSize, 1, undefined, true, undefined),
                ]);
                const roles = responses[0];
                const notificationsPage = responses[1];
                const notifications = notificationsPage.items ? notificationsPage.items : [];

                setNotificationsPage(notificationsPage);
                setNotifications(notifications);
                setRoles(roles);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };

        init();
    }, [auth, intl]);

    // load organizations
    useEffect(() => {
        const loadOrganizations = async () => {
            try {
                const organizations = await organizationApi.listAll();
                setOrganizations(organizations);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        loadOrganizations();
    }, [intl]);

    /*** METHODS ***/
    const refreshNotifications = async () => {
        try {
            setLoading('loading');

            const notificationsPage = await notificationApi.listHistory(
                pageSize,
                1,
                searchText,
                !myNotifications,
                organizationId,
            );
            const notifications = notificationsPage.items ? notificationsPage.items : [];

            setNotificationsPage(notificationsPage);
            setNotifications(notifications);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const search = async (searchText?: string) => {
        try {
            setLoading('loading');

            const notificationsPage = await notificationApi.listHistory(
                pageSize,
                1,
                searchText,
                !myNotifications,
                organizationId,
            );
            const notifications = notificationsPage.items ? notificationsPage.items : [];

            setNotificationsPage(notificationsPage);
            setNotifications(notifications);
            setSearchText(searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const filterByOrganization = async (organizationId?: string) => {
        try {
            setLoading('loading');

            const notificationsPage = await notificationApi.listHistory(
                pageSize,
                1,
                searchText,
                !myNotifications,
                organizationId,
            );
            const notifications = notificationsPage.items ? notificationsPage.items : [];

            setNotificationsPage(notificationsPage);
            setNotifications(notifications);
            setOrganizationId(organizationId);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const filterByMyNotifications = async (myNotifications: boolean) => {
        try {
            setLoading('loading');

            const notificationsPage = await notificationApi.listHistory(
                pageSize,
                1,
                searchText,
                !myNotifications,
                organizationId,
            );
            const notifications = notificationsPage.items ? notificationsPage.items : [];

            setOrganizationId(undefined);
            setSearchText(undefined);
            setMyNotifications(myNotifications);
            setNotificationsPage(notificationsPage);
            setNotifications(notifications);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const loadMore = async () => {
        try {
            setLoading('loadingMore');

            const page = notificationsPage?.currentPage! + 1;
            const notificationsPageAux = await notificationApi.listHistory(
                pageSize,
                page,
                searchText,
                !myNotifications,
                organizationId,
            );
            const notificationsAux =
                notificationsPageAux && notificationsPageAux.items
                    ? notifications.concat(notificationsPageAux.items)
                    : [];

            setNotificationsPage(notificationsPageAux);
            setNotifications(notificationsAux);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** COMPONENTS ***/

    let organizationOptions = organizations.map((organization) => (
        <Select.Option key={organization.id} value={organization.id!}>
            {organization.name}
        </Select.Option>
    ));
    organizations &&
        (organizationOptions = [
            <Select.Option key="-" value="">
                <FormattedMessage id="scheduledNotifications.allAffiliates" />
            </Select.Option>,
            ...organizationOptions,
        ]);

    return (
        <>
            {rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_ORGANIZATION_OWNER', 'ROLE_ORGANIZATION_STAFF']) && (
                <header className={styles.tableHeader}>
                    <div className={styles.searchPanel}>
                        {!myNotifications && (
                            <Search
                                placeholder={intl.formatMessage({ id: 'button.search' })}
                                className={styles.search}
                                enterButton={''}
                                allowClear={true}
                                onSearch={search}
                            />
                        )}
                    </div>
                    <div className={styles.flex}>
                        {rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) && !myNotifications && (
                            <Select
                                size="large"
                                className={styles.filter}
                                placeholder={<FormattedMessage id="scheduledNotifications.allAffiliates" />}
                                onChange={filterByOrganization}
                                value={organizationId}
                                disabled={myNotifications}
                            >
                                {organizationOptions}
                            </Select>
                        )}
                        <Checkbox
                            className={styles.allNotifications}
                            checked={myNotifications}
                            onChange={() => filterByMyNotifications(!myNotifications)}
                        >
                            <FormattedMessage id="notifications.myNotifications" />
                        </Checkbox>
                    </div>
                </header>
            )}
            <List
                grid={{
                    column: 1,
                }}
                dataSource={notifications}
                loading={loading === 'loading'}
                loadMore={
                    notifications.length < notificationsPage?.totalItems! ? (
                        <div className={styles.loadMore}>
                            <Button onClick={loadMore} loading={loading === 'loadingMore'}>
                                <FormattedMessage id="button.loadMore" tagName="span" />
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )
                }
                locale={{
                    emptyText: (
                        <div className={styles.empty}>
                            <FormattedMessage id="notifications.noNotifications" />
                        </div>
                    ),
                }}
                renderItem={(notification) => {
                    return (
                        <List.Item key={notification.id}>
                            <ScheduledNotificationComponent
                                notification={notification}
                                onUpdate={refreshNotifications}
                                roles={roles}
                            />
                        </List.Item>
                    );
                }}
            />
        </>
    );
};

export default HistoryComponent;
