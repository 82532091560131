import { CollegeCoach } from '../model/Entities';
import apiAxios from './CustomAxios';

class CollegeCoachApi {
    list = async (collegeId: number, sportId?: number): Promise<CollegeCoach[]> => {
        const response = await apiAxios.get<CollegeCoach[]>(`/v1/colleges/${collegeId}/coaches`, {
            params: { sportId },
        });
        return response.data;
    };

    get = async (collegeId: number, coachId: string): Promise<CollegeCoach> => {
        const response = await apiAxios.get<CollegeCoach>(`/v1/colleges/${collegeId}/coaches/${coachId}`);
        return response.data;
    };

    create = async (collegeId: number, coach: CollegeCoach): Promise<CollegeCoach> => {
        const response = await apiAxios.post<CollegeCoach>(`/v1/colleges/${collegeId}/coaches`, coach);
        return response.data;
    };

    update = async (collegeId: number, coach: CollegeCoach): Promise<CollegeCoach> => {
        const response = await apiAxios.put<CollegeCoach>(`/v1/colleges/${collegeId}/coaches/${coach.id}`, coach);
        return response.data;
    };

    delete = async (collegeId: number, coachId: string): Promise<void> => {
        const response = await apiAxios.delete<void>(`/v1/colleges/${collegeId}/coaches/${coachId}`);
        return response.data;
    };
}

const collegeCoachApi: CollegeCoachApi = new CollegeCoachApi();
export default collegeCoachApi;
