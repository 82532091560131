import { State } from '../model/Entities';
import states from '../resources/files/states.json';

class StateService {
    getCoordinates = (stateCode: string): State | undefined => {
        return states.find((s: State) => s.code === stateCode);
    };

    getName = (stateCode: string): string | undefined => {
        return states.find((s: State) => s.code === stateCode)?.name;
    };

    listUsaStates = () => {
        return states.filter((state) => state.country === 'US' && state.isVisible);
    };

    listCanadianStates = () => {
        return states.filter((state) => state.country === 'CA');
    };
}

const stateService: StateService = new StateService();

export default stateService;
