class MetricService {
    abbreviate = (code?: string): string => {
        switch (code) {
            case 'LBS':
                return 'lb';
            case 'INCHES':
                return 'in';
            case 'SECONDS':
                return 's';
            case 'SPEED-MPH':
                return 'mph';
            case 'SPEED-RPM':
                return 'rpm';
            case 'HOURS-MINUTES':
                return 'h-m';
            default:
                return '';
        }
    };

    toFeets = (totalInches?: number): number => {
        let result: number = 0;
        if (totalInches) {
            result = Math.floor(totalInches / 12);
        }

        return result;
    };

    toInches = (totalInches?: number): number => {
        let result: number = 0;
        if (totalInches) {
            result = totalInches % 12;
        }

        return result;
    };

    toTotalInches = (feet?: number, inches?: number): number => {
        let result: number = 0;
        if (feet) {
            result = feet * 12;
        }
        if (inches) {
            result = result + inches;
        }

        return result;
    };

    toFormattedFeets = (totalInches?: number): string => {
        let result = '';
        if (totalInches) {
            result = `${Math.floor(totalInches / 12)}'${Math.round(totalInches % 12)}''`;
        }

        return result;
    };
}

const metricService: MetricService = new MetricService();
export default metricService;
