import { EnvironmentOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Button, Card, Image, List } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import collegeApi from '../../../apis/CollegeApi';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/Elements';
import { College, CollegeDivision } from '../../../model/Entities';
import defaultCollegeLogo from '../../../resources/images/College-Connect-Default-School-Icon.png';
import notificationService from '../../../services/NotificationService';
import stateService from '../../../services/StateService';
import styles from './CollegesComponent.module.scss';
import RecommendPlayerModal from './RecommendPlayerModal/RecommendPlayerModal';

class CollegesComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 100;

    constructor(props: Props) {
        super(props);
        this.state = { colleges: [] };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.searchText !== this.props.searchText) {
            this.search(this.props.searchText);
        }
        if (prevProps.divisionId !== this.props.divisionId) {
            this.filterByDivision(this.props.divisionId);
        }
        if (prevProps.favorite !== this.props.favorite) {
            this.filterByFavorite(this.props.favorite);
        }
        if (prevProps.state !== this.props.state) {
            this.filterByState(this.props.state);
        }
        if (prevProps.sportId !== this.props.sportId) {
            this.filterBySport(this.props.sportId);
        }
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            const collegesPage = await collegeApi.list(this.pageSize, 1);
            const colleges = collegesPage.items ? collegesPage.items : [];

            this.setState({ collegesPage, colleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    list = async () => {
        const { searchText, divisionId, favorite, state, sportId } = this.props;
        const { collegesPage, colleges } = this.state;
        try {
            this.setState({ loading: 'loadingMore' });

            const page = collegesPage?.currentPage! + 1;
            const collegesPageAux = await collegeApi.list(
                this.pageSize,
                page,
                undefined,
                undefined,
                searchText,
                divisionId,
                favorite,
                state,
                sportId,
            );
            const collegesAux = collegesPageAux ? colleges.concat(collegesPageAux.items) : [];

            this.setState({ collegesPage: collegesPageAux, colleges: collegesAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    search = async (searchText?: string) => {
        const { divisionId, favorite, state, sportId } = this.props;
        try {
            this.setState({ loading: 'loading' });

            const collegesPage = await collegeApi.list(
                this.pageSize,
                1,
                undefined,
                undefined,
                searchText,
                divisionId,
                favorite,
                state,
                sportId,
            );
            const colleges = collegesPage.items ? collegesPage.items : [];

            this.setState({ collegesPage, colleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByDivision = async (divisionId?: number) => {
        const { searchText, favorite, state, sportId } = this.props;
        try {
            this.setState({ loading: 'loading' });

            const collegesPage = await collegeApi.list(
                this.pageSize,
                1,
                undefined,
                undefined,
                searchText,
                divisionId,
                favorite,
                state,
                sportId,
            );
            const colleges = collegesPage.items ? collegesPage.items : [];

            this.setState({ collegesPage, colleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByFavorite = async (favorite?: boolean) => {
        const { searchText, divisionId, state, sportId } = this.props;
        try {
            this.setState({ loading: 'loading' });

            const collegesPage = await collegeApi.list(
                this.pageSize,
                1,
                undefined,
                undefined,
                searchText,
                divisionId,
                favorite,
                state,
                sportId,
            );
            const colleges = collegesPage.items ? collegesPage.items : [];

            this.setState({ collegesPage, colleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    setCollegeAsFavorite = async (collegeId: number) => {
        const { searchText, divisionId, favorite, state, sportId } = this.props;
        try {
            this.setState({ loading: 'loading' });

            await collegeApi.createFavorite(collegeId);

            const collegesPage = await collegeApi.list(
                this.pageSize,
                1,
                undefined,
                undefined,
                searchText,
                divisionId,
                favorite,
                state,
                sportId,
            );
            const colleges = collegesPage.items ? collegesPage.items : [];

            this.setState({ collegesPage, colleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteCollegeFavorite = async (collegeId: number) => {
        const { searchText, divisionId, favorite, state, sportId } = this.props;
        try {
            this.setState({ loading: 'loading' });

            await collegeApi.deleteFavorite(collegeId);

            const collegesPage = await collegeApi.list(
                this.pageSize,
                1,
                undefined,
                undefined,
                searchText,
                divisionId,
                favorite,
                state,
                sportId,
            );
            const colleges = collegesPage.items ? collegesPage.items : [];

            this.setState({ collegesPage, colleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterByState = async (state?: string) => {
        const { searchText, divisionId, favorite, sportId } = this.props;
        try {
            this.setState({ loading: 'loading' });

            const collegesPage = await collegeApi.list(
                this.pageSize,
                1,
                undefined,
                undefined,
                searchText,
                divisionId,
                favorite,
                state,
                sportId,
            );
            const colleges = collegesPage.items ? collegesPage.items : [];

            this.setState({ collegesPage, colleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterBySport = async (sportId?: number) => {
        const { searchText, favorite, state, divisionId } = this.props;
        try {
            this.setState({ loading: 'loading' });

            const collegesPage = await collegeApi.list(
                this.pageSize,
                1,
                undefined,
                undefined,
                searchText,
                divisionId,
                favorite,
                state,
                sportId,
            );
            const colleges = collegesPage.items ? collegesPage.items : [];

            this.setState({ collegesPage, colleges });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showRecommendPlayerModal = (recommendPlayerModalVisible: boolean, college?: College) => {
        this.setState({ recommendPlayerModalVisible, college });
    };

    isRecommendPlayerButtonVisible = (): boolean | undefined => {
        const { auth } = this.context;
        return (
            auth?.authorities.includes('ROLE_ADMIN') ||
            (auth?.authorities.includes('ROLE_ORGANIZATION') && auth?.collegeConnectEnabled === 'edit')
        );
    };

    /*** COMPONENTS ***/
    renderLoadMore = (): React.ReactElement | undefined => {
        const { loading, collegesPage, colleges } = this.state;
        return colleges.length < collegesPage?.totalItems! ? (
            <div className={styles.loadMore}>
                <Button onClick={this.list} loading={loading === 'loadingMore'}>
                    <FormattedMessage id="button.loadMore" tagName="span" />
                </Button>
            </div>
        ) : (
            <></>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { collegeDivisions } = this.props;
        const { colleges, loading, collegesPage, recommendPlayerModalVisible, college } = this.state;

        const canadianStates = stateService.listCanadianStates();
        const usaStates = stateService.listUsaStates();

        return (
            <>
                <p className={styles.results}>
                    {collegesPage?.totalItems !== undefined && [
                        collegesPage?.totalItems,
                        ' ',
                        <FormattedMessage id="collegeConnect.results" />,
                    ]}
                </p>
                <List
                    grid={{
                        gutter: 15,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 3,
                        xxl: 4,
                    }}
                    className={styles.list}
                    dataSource={colleges}
                    loading={loading === 'loading'}
                    renderItem={(college) => {
                        const state =
                            canadianStates.find((s) => s.code === college.state)?.name ||
                            usaStates.find((s) => s.code === college.state)?.name ||
                            college.state;

                        return (
                            <List.Item key={college.id}>
                                <Card className={styles.tabContent}>
                                    <Card.Meta
                                        title={
                                            <div className={styles.collegeHeader}>
                                                <Image
                                                    className={styles.image}
                                                    src={college.logo || defaultCollegeLogo}
                                                    fallback={defaultCollegeLogo}
                                                    preview={false}
                                                />{' '}
                                                <h3>{college.name}</h3>
                                                <div className={styles.buttons}>
                                                    {college.favorite === true ? (
                                                        <Button
                                                            type="text"
                                                            icon={<StarFilled />}
                                                            onClick={() => this.deleteCollegeFavorite(college.id!)}
                                                        ></Button>
                                                    ) : (
                                                        <Button
                                                            type="text"
                                                            icon={<StarOutlined />}
                                                            onClick={() => this.setCollegeAsFavorite(college.id!)}
                                                        ></Button>
                                                    )}
                                                    <Button
                                                        type="text"
                                                        icon={<EnvironmentOutlined />}
                                                        onClick={() => this.props.goToMap(college.id)}
                                                    ></Button>
                                                </div>
                                            </div>
                                        }
                                        description={
                                            <div className={styles.description}>
                                                <div className={styles.flex}>
                                                    <div className={styles.misc}>
                                                        <p>{state}</p>
                                                        <p>
                                                            {collegeDivisions
                                                                .filter((d) =>
                                                                    college.divisions?.find(
                                                                        (division) => d.id === division,
                                                                    ),
                                                                )
                                                                .map((d) => d.name)
                                                                .join(', ')}
                                                        </p>
                                                    </div>

                                                    <div className={styles.links}>
                                                        <p>
                                                            {college.athletesUrl && (
                                                                <Link
                                                                    to={{ pathname: college.athletesUrl }}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <FormattedMessage id="collegeConnect.athletics" />
                                                                </Link>
                                                            )}
                                                        </p>
                                                        <p>
                                                            {college.campsUrl && (
                                                                <Link
                                                                    to={{ pathname: college.campsUrl }}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <FormattedMessage id="collegeConnect.staffDirectory" />
                                                                </Link>
                                                            )}
                                                        </p>
                                                        <p>
                                                            {college.admissionsUrl && (
                                                                <Link
                                                                    to={{ pathname: college.admissionsUrl }}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <FormattedMessage id="collegeConnect.admissions" />
                                                                </Link>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={styles.buttons}>
                                                    {this.isRecommendPlayerButtonVisible() && (
                                                        <div>
                                                            <Button
                                                                type="primary"
                                                                onClick={() =>
                                                                    this.showRecommendPlayerModal(true, college)
                                                                }
                                                                disabled={
                                                                    !college.coachSports ||
                                                                    college.coachSports.length === 0
                                                                }
                                                            >
                                                                <FormattedMessage id="needFeed.recommendPlayer" />
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        }
                                    />
                                </Card>
                            </List.Item>
                        );
                    }}
                />
                {recommendPlayerModalVisible && (
                    <RecommendPlayerModal
                        college={college!}
                        onCancel={() => this.showRecommendPlayerModal(false)}
                        onUpdate={() => this.showRecommendPlayerModal(false)}
                    />
                )}
                {this.renderLoadMore()}
            </>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(CollegesComponent);

interface Props extends WrappedComponentProps {
    searchText?: string;
    divisionId?: number;
    favorite?: boolean;
    goToMap: (collegeId?: number) => void;
    collegeDivisions: CollegeDivision[];
    state?: string;
    sportId?: number;
}

interface State {
    collegesPage?: Page<College>;
    colleges: College[];
    loading?: 'loading' | 'loadingMore';
    recommendPlayerModalVisible?: boolean;
    college?: College;
}
