import Icon from '@ant-design/icons';
import { Button, Checkbox, Divider, FormInstance, List, Menu } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import eventApi from '../../../../apis/EventApi';
import eventPlayerApi from '../../../../apis/EventPlayerApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../../context/CustomContext';
import { Page } from '../../../../model/Elements';
import { Event, EventPlayer } from '../../../../model/Entities';
import { ReactComponent as arrowBackSvg } from '../../../../resources/images/ico-arrowBack.svg';
import { ReactComponent as backSvg } from '../../../../resources/images/ico-back.svg';
import { ReactComponent as eventSettingsSvg } from '../../../../resources/images/ico-eventSettings.svg';
import { ReactComponent as eventLeaderboardSvg } from '../../../../resources/images/ico-leaderboard.svg';
import { ReactComponent as positionsSvg } from '../../../../resources/images/ico-positions.svg';
import { ReactComponent as printSvg } from '../../../../resources/images/ico-print.svg';
import { ReactComponent as eventSummarySvg } from '../../../../resources/images/ico-summary.svg';
import HeadMetadata from '../../../../services/HeadMetadata';
import notificationService from '../../../../services/NotificationService';
import styles from './EventQRCodesPage.module.scss';
import logo from '../../../../resources/images/top100-logo-p.png';

class EventQRCodesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();
    readonly pageSize = 100;
    listRef?: any | null;

    constructor(props: Props) {
        super(props);
        this.state = { players: [], checkedPlayers: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });

            const event = await eventApi.get(+this.props.match.params.id);
            const playersPage = await eventPlayerApi.list(this.pageSize, 1, +this.props.match.params.id);
            const players = playersPage.items ? playersPage.items : [];

            this.setState({ event, playersPage, players });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async () => {
        const { playersPage, players } = this.state;
        try {
            this.setState({ loading: true });
            const page = playersPage?.currentPage! + 1;
            const playersPageAux = await eventPlayerApi.list(this.pageSize, page, +this.props.match.params.id);
            const playersAux = playersPageAux ? players.concat(playersPageAux.items) : [];

            this.setState({ playersPage: playersPageAux, players: playersAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    selectQRCode = (userId: string) => {
        const { checkedPlayers } = this.state;
        let updatedCheckedPlayers: string[] = [];
        if (checkedPlayers?.includes(userId)) {
            updatedCheckedPlayers = checkedPlayers.filter((p) => p !== userId);
        } else {
            updatedCheckedPlayers = [...checkedPlayers, userId];
        }
        this.setState({ checkedPlayers: updatedCheckedPlayers });
    };

    checkAll = () => {
        const { checkedPlayers, players } = this.state;
        let updatedCheckedPlayers: string[] = [];
        if (checkedPlayers?.length === players.length) {
            updatedCheckedPlayers = [];
        } else {
            players.map((p) => updatedCheckedPlayers.push(p.id!));
        }

        this.setState({ checkedPlayers: updatedCheckedPlayers });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { event } = this.state;
        if (desktop) {
            return (
                <>
                    <div className={styles.header}>
                        <h1>
                            <FormattedMessage id="events.event.qrcodes.title" />
                        </h1>

                        {this.listRef && (
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button icon={<Icon component={printSvg} />} className={styles.printer}>
                                            <FormattedMessage id="button.print" />
                                        </Button>
                                    );
                                }}
                                bodyClass={styles.print}
                                content={() => this.listRef}
                            />
                        )}
                    </div>
                    <div className={styles.subheader}>
                        {event && (
                            <h2>
                                {event?.name} <FormattedDate value={event?.startDate}></FormattedDate>
                            </h2>
                        )}
                    </div>
                </>
            );
        } else {
            return (
                <div className={styles.header}>
                    <Link to={`/events/${+this.props.match.params.id}/summary`}>
                        <Button
                            type="text"
                            size="small"
                            className={styles.back}
                            icon={<Icon component={arrowBackSvg} />}
                        ></Button>
                    </Link>
                    <h1>
                        <FormattedMessage id="events.event.qrcodes.title" />
                    </h1>
                </div>
            );
        }
    };

    renderUserWristband = (
        player: EventPlayer,
        event: Event,
        checkedPlayers: string[],
    ): React.ReactElement | undefined => {
        return (
            <List.Item
                key={player.id}
                className={`${styles.wristband} ${!checkedPlayers.includes(player.id!) && styles.hidden}`}
            >
                <div className={styles.checkbox}>
                    <Checkbox
                        key={player.id}
                        onClick={() => this.selectQRCode(player.id!)}
                        checked={checkedPlayers.includes(player.id!)}
                    >
                        {' '}
                    </Checkbox>
                </div>
                <div className={styles.xxx}>
                    <img src="/img/watermark.jpg" alt="" />
                </div>
                <img className={styles.logo} src="/img/top100-logo-wristband.png" alt="Top 100 Sports" width={72} />
                <div className={styles.qr}>
                    {
                        <QRCodeSVG
                            value={player.id!}
                            size={72}
                            level={'Q'}
                            includeMargin={true}
                            imageSettings={{ src: `${logo}`, height: 24, width: 24, excavate: false }}
                        />
                    }
                </div>
                <div className={styles.name}>
                    <p>
                        {player.givenName} {player.familyName}
                    </p>
                    <p>{this.renderDateAndPosition(player)}</p>
                    <p>
                        <FormattedMessage id="events.event.qrcodes.website" />
                    </p>
                </div>
                <div className={styles.event}>
                    <p>{event.name}</p>
                    <p>
                        <FormattedDate value={event.startDate} month="short" />{' '}
                        <FormattedDate value={event.startDate} day="2-digit" /> -{' '}
                        <FormattedDate value={event.startDate} weekday="short" />,{' '}
                        <FormattedDate value={event.startDate} hour="2-digit" minute="2-digit" />
                    </p>
                    <p>{this.renderCityAndState(player)}</p>
                </div>
                <div className={styles.xxx}>
                    <img src="/img/watermark.jpg" alt="" />
                </div>
            </List.Item>
        );
    };

    renderDateAndPosition = (player: EventPlayer): React.ReactElement | undefined | string => {
        const positionCodes = player.positions?.map((p) => p.code);

        return [player.gradYear, positionCodes].filter((i) => i).join(' - ');
    };

    renderCityAndState = (player: EventPlayer): React.ReactElement | undefined | string => {
        return [player.city, player.state].filter((i) => i).join(', ');
    };

    renderLoadMore = (): React.ReactElement | undefined => {
        const { loading, playersPage, players } = this.state;
        return !loading && players.length < playersPage?.totalItems! ? (
            <div className={styles.loadMore}>
                <Button onClick={this.list}>
                    <FormattedMessage id="button.loadMore" />
                </Button>
            </div>
        ) : (
            <></>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { event, players, checkedPlayers, loading } = this.state;
        const checkedAll = players.length > 0 && checkedPlayers.length === players.length;
        return (
            <div ref={(el) => (this.listRef = el)}>
                <div className={styles.checkbox}>
                    <Checkbox onClick={() => this.checkAll()} checked={checkedAll}>
                        <FormattedMessage id="button.selectAll" />
                    </Checkbox>
                </div>
                <List
                    dataSource={players}
                    renderItem={(player) => this.renderUserWristband(player, event!, checkedPlayers)}
                    loading={loading}
                    loadMore={this.renderLoadMore()}
                    className={styles.wristbands}
                />
            </div>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { auth } = this.context;
        const eventId = this.props.match.params.id;
        const { event } = this.state;

        const isMenuVisible = auth?.authorities.includes('ROLE_ADMIN');
        const isAthletesMenuVisible =
            auth?.authorities.includes('ROLE_ADMIN') ||
            (auth?.authorities.includes('ROLE_ORGANIZATION') && event?.organizationId === auth.organizationId);
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="event-dashboard"
                        icon={<Icon component={backSvg} />}
                        onClick={() => this.props.history.push(`/events/${eventId}/players`)}
                    >
                        <Link to={`/events/${eventId}/players`}>
                            <FormattedMessage id="events.event.backToAthletes" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                <Menu.ItemGroup key="event-menu" title={!collapsed && <FormattedMessage id="events.event.info" />}>
                    <Menu.Item
                        key="event"
                        icon={<Icon component={eventSummarySvg} />}
                        onClick={() => this.props.history.push(`/events/${eventId}/summary`)}
                    >
                        <Link to={`/events/${eventId}/summary`}>
                            <FormattedMessage id="events.event.summary" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-settings"
                        icon={<Icon component={eventSettingsSvg} />}
                        hidden={!isMenuVisible}
                        onClick={() => this.props.history.push(`/events/${this.props.match.params.id}/settings`)}
                    >
                        <Link to={`/events/${this.props.match.params.id}/settings`}>
                            <FormattedMessage id="events.event.settings" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="leaderboard"
                        icon={<Icon component={eventLeaderboardSvg} />}
                        onClick={() => this.props.history.push(`/events/${eventId}/leaderboard`)}
                    >
                        <Link to={`/events/${eventId}/leaderboard`}>
                            <FormattedMessage id="events.event.leaderboard" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="event-players"
                        icon={<Icon component={positionsSvg} />}
                        hidden={!isAthletesMenuVisible}
                        onClick={() => this.props.history.push(`/events/${eventId}/players`)}
                    >
                        <Link to={`/events/${eventId}/players`}>
                            <FormattedMessage id="events.event.athletes" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    };

    render() {
        const { event } = this.state;
        const fullName =
            event &&
            `${this.props.intl.formatMessage({
                id: 'event.qrcodes.meta.title',
            })} - ${event?.name} - ${this.props.intl.formatMessage({
                id: 'events.event.event.meta.title',
            })}`;
        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="event-players"
                    menu={this.renderMenu}
                    content={this.renderContent}
                    header={this.renderHeader}
                />
            </>
        );
    }
}
export default injectIntl(EventQRCodesPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    event?: Event;
    playersPage?: Page<EventPlayer>;
    players: EventPlayer[];
    checkedPlayers: string[];
    loading?: boolean;
}
