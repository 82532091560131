import FileSaver from 'file-saver';
import { Page } from '../model/Elements';
import {
    Organization,
    OrganizationGroupCoach,
    OrganizationGroupPlayer,
    OrganizationGroupStaff,
    OrganizationMember,
    OrganizationMemberCoach,
    OrganizationMemberPlayer,
    Team,
} from '../model/Entities';
import apiAxios from './CustomAxios';

class OrganizationGroupApi {
    listStaff = async (
        limit: number,
        page: number,
        organizationId: string,
        sortField?: string,
        sortOrder?: string,
    ): Promise<Page<OrganizationGroupStaff>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const sortFieldName = this.getSortFieldStaff(sortField);
        const response = await apiAxios.get<Page<OrganizationGroupStaff>>(`/v1/organizations/${organizationId}/staff`, {
            params: { limit, page, [sortFieldName]: order },
        });

        return response.data;
    };

    listCoaches = async (
        organizationId: string,
        sportId: number,
        divisionId?: number,
    ): Promise<OrganizationGroupCoach[]> => {
        const response = await apiAxios.get<OrganizationGroupCoach[]>(
            `/v1/organizations/${organizationId}/sports/${sportId}/coaches`,
            {
                params: { divisionId },
            },
        );

        return response.data;
    };

    listPlayers = async (
        limit: number,
        page: number,
        organizationId: string,
        sortField?: string,
        sortOrder?: string,
        search?: string,
        sportId?: number,
    ): Promise<Page<OrganizationGroupPlayer>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const sortFieldName = this.getSortFieldPlayers(sortField);
        const sportValue = 'filtering.SportId';
        const response = await apiAxios.get<Page<OrganizationGroupPlayer>>(
            `/v1/organizations/${organizationId}/players`,
            {
                params: {
                    limit,
                    page,
                    [sortFieldName]: order,
                    search,
                    [sportValue]: sportId,
                },
            },
        );

        return response.data;
    };

    createCoach = async (organizationId: string, sportId: number, coach: OrganizationMemberCoach): Promise<void> => {
        await apiAxios.post<void>(`/v1/organizations/${organizationId}/sports/${sportId}/coaches`, coach);
    };

    createOwner = async (organizationId: string, owner: OrganizationMember): Promise<void> => {
        await apiAxios.post<void>(`/v1/organizations/${organizationId}/owners`, owner);
    };

    createStaff = async (organizationId: string, staff: OrganizationMember): Promise<void> => {
        await apiAxios.post<void>(`/v1/organizations/${organizationId}/staff`, staff);
    };

    createPlayer = async (organizationId: string, sportId: number, player: OrganizationMemberPlayer): Promise<void> => {
        player.dateOfBirth = player.dateOfBirth ? Math.round(player.dateOfBirth / 1000) : undefined;
        await apiAxios.post<void>(`/v1/organizations/${organizationId}/sports/${sportId}/players`, player);
    };

    addTeamCoach = async (
        organizationId: string,
        sportId: number,
        coach: OrganizationGroupCoach,
        team: Team,
    ): Promise<void> => {
        await apiAxios.post<void>(
            `/v1/organizations/${organizationId}/sports/${sportId}/coaches/${coach.coachId}/teams/${team.id}`,
        );
    };

    movePlayer = async (
        organizationId: string,
        userId: string,
        sourceOrganizationId: string,
        sportIds: number[],
    ): Promise<void> => {
        await apiAxios.put<void>(`/v1/organizations/${organizationId}/players/${userId}`, {
            sourceOrganizationId: sourceOrganizationId,
            sportIds: sportIds,
        });
    };

    addExistingPlayer = async (organizationId: string, userId: string, teamId: string): Promise<void> => {
        await apiAxios.post<void>(`/v1/organizations/${organizationId}/players/${userId}`, { teamId: teamId });
    };

    deleteTeamCoach = async (
        organizationId: string,
        sportId: number,
        coach: OrganizationGroupCoach,
        team: Team,
    ): Promise<void> => {
        await apiAxios.delete<void>(
            `/v1/organizations/${organizationId}/sports/${sportId}/coaches/${coach.coachId}/teams/${team.id}`,
        );
    };

    exportAffiliateSpreadsheet = async (organization: Organization, sportId?: number): Promise<void> => {
        const response = await apiAxios.get<Blob>(`/v1/organizations/${organization.id}/excel-export`, {
            responseType: 'blob',
            params: { sportId },
        });

        FileSaver.saveAs(response.data, organization.name + '.xlsx');
    };

    private getSortFieldPlayers(sortField?: string): string {
        let field: string;
        if (sortField === 'givenName') {
            field = 'sorting.GivenName';
        } else if (sortField === 'division') {
            field = 'sorting.Division';
        } else if (sortField === 'userName') {
            field = 'sorting.UserName';
        } else if (sortField === 'userStatus') {
            field = 'sorting.UserStatus';
        } else {
            field = 'sorting.GivenName';
        }

        return field;
    }

    private getSortFieldStaff(sortField?: string): string {
        let field: string;
        if (sortField === 'givenName') {
            field = 'sorting.GivenName';
        } else if (sortField === 'roles') {
            field = 'sorting.Role';
        } else if (sortField === 'email') {
            field = 'sorting.Email';
        } else {
            field = 'sorting.GivenName';
        }

        return field;
    }
}

const organizationGroupApi: OrganizationGroupApi = new OrganizationGroupApi();
export default organizationGroupApi;
