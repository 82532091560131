import { Button, Col, List, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import prospectsApi from '../../apis/ProspectsApi';
import seasonApi from '../../apis/SeasonApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Page } from '../../model/Elements';
import { Prospect, Season } from '../../model/Entities';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import ProspectComponent from './ProspectComponent/ProspectComponent';
import styles from './ProspectsPage.module.scss';

class ProspectsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 20;

    constructor(props: Props) {
        super(props);
        this.state = { prospects: [], seasons: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });

            const sportId = this.context.auth?.colleges?.find(Boolean)?.sportId!;
            const seasons = await seasonApi.listAll(sportId);
            const season = seasons.find((s) => s.isActive) || seasons[0];

            const prospectsPage = await prospectsApi.list(this.pageSize, 1);
            const prospects = prospectsPage.items ? prospectsPage.items : [];

            this.setState({ prospectsPage, prospects, seasons, season });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    list = async () => {
        const { prospectsPage, prospects } = this.state;
        try {
            this.setState({ loading: 'loadingMore' });

            const page = prospectsPage?.currentPage! + 1;
            const prospectsPageAux = await prospectsApi.list(this.pageSize, page);
            const prospectsAux = prospectsPageAux ? prospects.concat(prospectsPageAux.items) : [];

            this.setState({ prospectsPage: prospectsPageAux, prospects: prospectsAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeSeason = async (seasonId: number) => {
        const { seasons } = this.state;

        try {
            this.setState({ loading: 'loading' });
            const season = seasons.find((s) => s.id === seasonId) as Season;
            const prospectsPage = await prospectsApi.list(this.pageSize, 1);
            const prospects = prospectsPage.items ? prospectsPage.items : [];

            this.setState({ prospectsPage, prospects, season });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderLoadMore = (): React.ReactElement | undefined => {
        const { loading, prospectsPage, prospects } = this.state;
        return prospects.length < prospectsPage?.totalItems! ? (
            <div className={styles.loadMore}>
                <Button onClick={this.list} loading={loading === 'loadingMore'}>
                    <FormattedMessage id="button.loadMore" tagName="span" />
                </Button>
            </div>
        ) : (
            <></>
        );
    };

    renderList = (desktop: boolean): React.ReactElement | undefined => {
        const { loading, prospects, season } = this.state;

        if (season) {
            return (
                <>
                    <List
                        className={styles.seasonsList}
                        dataSource={prospects}
                        loadMore={this.renderLoadMore()}
                        renderItem={(prospect) => (
                            <List.Item key={`${season.id}-${prospect.id}`}>
                                <Col md={24}>
                                    <ProspectComponent prospectId={prospect.id!} season={season} onUpdate={this.init} />
                                </Col>
                            </List.Item>
                        )}
                        loading={loading === 'loading'}
                    />
                </>
            );
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { seasons, season } = this.state;

        const seasonOptions = seasons.map((season) => (
            <Select.Option key={season.id!} value={season.id!}>
                {season.name}
            </Select.Option>
        ));

        return (
            <>
                <div className={styles.header}>
                    <h1>
                        <FormattedMessage id="prospects.title" />
                    </h1>
                    <div className={styles.selector}>
                        <label hidden={!desktop}>
                            <FormattedMessage id="prospects.season" />
                        </label>
                        <Select className={styles.seasons} size="large" value={season?.id} onChange={this.changeSeason}>
                            {seasonOptions}
                        </Select>
                    </div>
                </div>
                {this.renderList(desktop)}
            </>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="propects.meta.title" />
                <LayoutComponent page="prospects" content={this.renderContent} />
            </>
        );
    }
}
export default injectIntl(ProspectsPage);

interface Props extends WrappedComponentProps {}

interface State {
    loading?: 'loading' | 'loadingMore';
    prospectsPage?: Page<Prospect>;
    prospects: Prospect[];
    seasons: Season[];
    season?: Season;
}
