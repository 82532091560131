import { Button, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import eventRegistrationApi from '../../../../apis/EventRegistrationApi';
import quickbooksApi from '../../../../apis/QuickbooksApi';
import userApi from '../../../../apis/UserApi';
import CustomContext from '../../../../context/CustomContext';
import { Card, Event, EventRegistrationInfo, UserAccount } from '../../../../model/Entities';
import { cards, countries, CountryType, months, PaymentProcessType } from '../../../../model/Types';
import notificationService from '../../../../services/NotificationService';
import paymentService from '../../../../services/PaymentService';
import stateService from '../../../../services/StateService';
import styles from './SignUpPaymentExecutionComponent.module.scss';

class SignUpPaymentExecutionComponent extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/
    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            let user;
            // We can remove this condition in the future
            if (this.context.auth) {
                user = await userApi.getAccount(this.context.auth.id!);
            }

            this.formRef.current?.setFieldsValue({
                card: {
                    address: {
                        country: user?.country,
                        region: user?.state,
                        postalCode: user?.zip,
                    },
                },
            });

            const country = user?.country ? user.country : undefined;

            this.setState({ user, country });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    pay = async (values: any) => {
        const { event, registration } = this.props;
        try {
            this.setState({ loading: 'paying' });

            const card: Card = Object.assign({}, values);
            let token;
            try {
                token = await quickbooksApi.create(card);
            } catch (error) {
                paymentService.displayQBError(error, this.props.intl);
            }
            if (token) {
                await eventRegistrationApi.pay(event.id!, registration.id!, token);
                this.props.onUpdate('success');
            }
        } catch (error) {
            paymentService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /**
     * Reset the state when the country is changed in the form
     * @param country
     */
    changeCountry = (country?: CountryType) => {
        this.formRef.current?.resetFields([['card', 'address', 'region']]);
        this.setState({ country });
    };

    /*** COMPONENTS ***/
    renderStates = (): JSX.Element[] | undefined => {
        const { country } = this.state;

        const usaStates = stateService.listUsaStates();
        const canadianStates = stateService.listCanadianStates();

        if (country === 'US') {
            const stateOptions = usaStates.map((state) => (
                <Select.Option key={state.code} value={state.code}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        } else if (country === 'CA') {
            const stateOptions = canadianStates.map((state) => (
                <Select.Option key={state.name} value={state.name}>
                    {state.name}
                </Select.Option>
            ));
            return stateOptions;
        }
    };

    renderContent = (): React.ReactElement | undefined => {
        const { registration } = this.props;
        const { loading } = this.state;
        const countryOptions = countries.map((c) => (
            <Select.Option key={c} value={c}>
                <FormattedMessage id={c} />
            </Select.Option>
        ));

        const monthOptions = months.map((m) => (
            <Select.Option key={m} value={m}>
                {m}
            </Select.Option>
        ));

        const actualYear = moment().year();
        const futureYear = moment().year() + 19;
        let yearOptions: any = [];

        for (let i = actualYear; i < futureYear; i++) {
            yearOptions.push(
                <Select.Option key={i} value={i.toString()}>
                    {i}
                </Select.Option>,
            );
        }

        const cardOptions = cards.map((c) => (
            <Select.Option key={c} value={c}>
                {c}
            </Select.Option>
        ));

        return (
            <div className={styles.registrationForm}>
                <div className={styles.row}>
                    <div className={styles.flex}>
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <h1>
                                    <FormattedMessage id="signupPayment.payment" />
                                </h1>
                                <h1>
                                    <FormattedMessage id="signupPayment.total" /> <FormattedMessage id="$" />
                                    <FormattedNumber
                                        value={registration.total!}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                    />
                                </h1>
                            </div>
                            <Form ref={this.formRef} onFinish={this.pay} colon={false} layout="vertical">
                                <Row gutter={[28, 0]}>
                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={<FormattedMessage id="signupPayment.country" />}
                                            name={['card', 'address', 'country']}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                            ]}
                                        >
                                            <Select
                                                className={styles.select}
                                                onChange={(value: CountryType) => this.changeCountry(value)}
                                            >
                                                {countryOptions}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={<FormattedMessage id="signupPayment.state" />}
                                            name={['card', 'address', 'region']}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                            ]}
                                        >
                                            <Select className={styles.select} allowClear>
                                                {this.renderStates()}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={10} md={8}>
                                        <Form.Item
                                            label={<FormattedMessage id="signupPayment.zip" />}
                                            name={['card', 'address', 'postalCode']}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                            ]}
                                        >
                                            <Input size="large" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[28, 0]}>
                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={<FormattedMessage id="signupPayment.cardHolderName" />}
                                            name={['card', 'name']}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                            ]}
                                        >
                                            <Input size="large" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[28, 0]}>
                                    <Col xs={12} md={8}>
                                        <Form.Item
                                            label={<FormattedMessage id="signupPayment.type" />}
                                            name={['card', 'cardType']}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                            ]}
                                        >
                                            <Select className={styles.select}>{cardOptions}</Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={8}>
                                        <Form.Item
                                            label={<FormattedMessage id="signupPayment.cardNumber" />}
                                            name={['card', 'number']}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                            ]}
                                        >
                                            <Input size="large" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[28, 0]}>
                                    <Col xs={12} md={5}>
                                        <Form.Item
                                            label={<FormattedMessage id="signupPayment.cardExpirationMonth" />}
                                            name={['card', 'expMonth']}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                            ]}
                                        >
                                            <Select className={styles.select}>{monthOptions}</Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} md={5}>
                                        <Form.Item
                                            label={<FormattedMessage id="signupPayment.cardExpirationYear" />}
                                            name={['card', 'expYear']}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                            ]}
                                        >
                                            <Select className={styles.select}>{yearOptions}</Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={10} md={4}>
                                        <Form.Item
                                            label={<FormattedMessage id="signupPayment.cvvCvc" />}
                                            name={['card', 'cvc']}
                                            rules={[
                                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                            ]}
                                        >
                                            <Input size="large" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div className={styles.buttons}>
                                    <Button
                                        className={styles.btnSecondary}
                                        type="primary"
                                        size="large"
                                        onClick={() => this.props.onUpdate('selection')}
                                    >
                                        <FormattedMessage id="button.back" tagName="span" />
                                    </Button>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className={styles.btnMain}
                                            size="large"
                                            loading={loading === 'paying'}
                                        >
                                            <FormattedMessage id="button.continue" tagName="span" />
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(SignUpPaymentExecutionComponent);

interface Props extends WrappedComponentProps {
    onUpdate: (paymentDisplay: PaymentProcessType) => void;
    event: Event;
    registration: EventRegistrationInfo;
}

interface State {
    country?: CountryType;
    user?: UserAccount;
    loading?: 'initializing' | 'paying';
}
