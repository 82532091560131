import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { Button, Table, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import awardApi from '../../../apis/AwardApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/Elements';
import { Award } from '../../../model/Entities';
import HeadMetadata from '../../../services/HeadMetadata';
import notificationService from '../../../services/NotificationService';
import tableService from '../../../services/TableService';
import styles from './AdminAwardsPage.module.scss';

class AdminAwardsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });
            const awardsPage = await awardApi.list(tableService.pageSize, 1);
            this.setState({ awardsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Award> | SorterResult<Award>[],
    ) => {
        try {
            this.setState({ loading: true });
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            let sortField, sortOrder;
            if (Array.isArray(sorter)) {
            } else {
                sortField = sorter.field as string;
                sortOrder = sorter.order as string;
            }
            const awardsPage = await awardApi.list(pageSize, page, sortField, sortOrder);
            this.setState({ awardsPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderToolbar()}
                <div className={styles.table}>{this.renderTable()}</div>
            </>
        );
    };

    renderToolbar = (): React.ReactElement | undefined => {
        return (
            <div className={styles.toolbar}>
                <h1>
                    <FormattedMessage id="awards.title" />
                </h1>
                <Link to="/admin/awards/new">
                    <Tooltip title={<FormattedMessage id="awards.tooltip" />}>
                        <Button type="primary" size="large" className={styles.add} icon={<PlusOutlined />}>
                            <FormattedMessage id="awards.add" />
                        </Button>
                    </Tooltip>
                </Link>
            </div>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { awardsPage, loading } = this.state;
        const items = awardsPage ? awardsPage.items : [];
        const columns: ColumnsType<Award> = [
            {
                title: <FormattedMessage id="awards.name" />,
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                defaultSortOrder: 'ascend',
                render: (value: string, award: Award) => <Link to={`/admin/awards/${award.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="awards.type" />,
                dataIndex: 'type',
                key: 'type',
                render: (value: string, award: Award) => <Link to={`/admin/awards/${award.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="awards.description" />,
                dataIndex: 'description',
                key: 'description',
                render: (value: string, award: Award) => <Link to={`/admin/awards/${award.id}`}>{value}</Link>,
            },
            {
                title: <FormattedMessage id="awards.url" />,
                dataIndex: 'url',
                key: 'url',
                render: (value: string, award: Award) => <Link to={`/admin/awards/${award.id}`}>{value}</Link>,
            },
        ];

        return (
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(awardsPage)}
                rowKey="id"
                onChange={this.list}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="awards.meta.title" />
                <LayoutComponent page="admin-awards" content={this.renderContent} />
            </>
        );
    }
}
export default injectIntl(AdminAwardsPage);

interface Props extends WrappedComponentProps {}

interface State {
    awardsPage?: Page<Award>;
    loading?: boolean;
}
