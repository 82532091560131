import { Button, Form, FormInstance, Input, message, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import teamApi from '../../../../apis/TeamApi';
import CustomContext from '../../../../context/CustomContext';
import { Division, Team } from '../../../../model/Entities';
import notificationService from '../../../../services/NotificationService';
import Icon from '@ant-design/icons';
import { ReactComponent as removeSvg } from '../../../../resources/images/ico-thrash.svg';
import styles from './AddTeamModal.module.scss';

class AddTeamModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'loading' });
            if (this.props.team) {
                this.get(this.props.team);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    get = async (team: Team) => {
        this.formRef.current?.setFieldsValue(team);
    };

    cancel = async () => {
        this.formRef.current?.resetFields();
        this.props.onCancel();
    };

    update = async () => {
        const { organizationId, sportId, team } = this.props;
        try {
            this.setState({ loading: 'saving' });
            const values = await this.formRef.current?.validateFields();
            const updatedTeam: Team = Object.assign({}, team, values);
            updatedTeam.sportId = sportId;
            updatedTeam.id
                ? await teamApi.update(updatedTeam, organizationId)
                : await teamApi.create(updatedTeam, organizationId);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));
            this.formRef.current?.resetFields();
            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl, [
                    { status: 409, message: 'roster.team.status.duplicate' },
                ]);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    deleteTeam = async (team: Team) => {
        try {
            this.setState({ loading: 'deleting' });
            const { organizationId } = this.props;
            await teamApi.delete(team, organizationId);
            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    render() {
        const { loading } = this.state;
        const { divisions, team } = this.props;
        const divisionsOptions = divisions?.map((division) => (
            <Select.Option key={division.id} value={division.id!}>
                {division.name}
            </Select.Option>
        ));
        const isDeleteVisible = team && team.teamMembers?.length === 0;

        return (
            <Modal
                width={600}
                visible={true}
                onCancel={this.cancel}
                footer={[
                    <div>
                        {isDeleteVisible && (
                            <Button
                                type="text"
                                icon={<Icon component={removeSvg} />}
                                loading={loading === 'deleting'}
                                onClick={() => this.deleteTeam(team)}
                            >
                                <FormattedMessage id="button.remove" tagName="span" />
                            </Button>
                        )}
                        <Button shape={'round'} onClick={this.cancel}>
                            <FormattedMessage id="admin.rosters.roster.cancel" />
                        </Button>
                        <Button shape={'round'} type="primary" onClick={this.update} loading={loading === 'saving'}>
                            <FormattedMessage id="admin.rosters.roster.save" tagName="span" />
                        </Button>
                    </div>,
                ]}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="roster.addTeam.division" />}
                        name="divisionId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Select className={styles.select}>{divisionsOptions}</Select>
                    </Form.Item>
                    <Form.Item
                        label={<FormattedMessage id="roster.addTeam.name" />}
                        name="name"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(AddTeamModal);

interface Props extends WrappedComponentProps {
    onUpdate: () => Promise<void>;
    onCancel: () => void;
    divisions: Division[];
    organizationId: string;
    sportId: number;
    team?: Team;
}

interface State {
    loading?: 'loading' | 'saving' | 'deleting';
}
