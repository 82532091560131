import { Checkbox, Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import roleApi from '../../../../apis/RoleApi';
import { Role } from '../../../../model/Entities';
import { timeZones } from '../../../../model/Types';
import notificationService from '../../../../services/NotificationService';
import styles from './RecipientsAndContentFormItems.module.scss';

/**
 * Returns the recipients and content form items component.
 * @param props the props
 * @returns the recipients and content form items component
 */
const RecipientsAndContentFormItems: React.FC<RecipientsAndContentFormItemsProps> = (props) => {
    const { form, allowedRoleIds, updateRoles } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [roles, setRoles] = useState<Role[]>([]);

    // load roles
    useEffect(() => {
        const loadRoles = async () => {
            try {
                form.setFieldsValue({ roles: [] });
                const roles = await roleApi.list();
                setRoles(roles);
                updateRoles(roles);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        loadRoles();
    }, [allowedRoleIds, form, intl, updateRoles]);

    /*** METHODS ***/

    const changeApp = (event: CheckboxChangeEvent) => {
        if (!event.target.checked) {
            form.setFieldsValue({ email: true });
        }
    };

    const changeEmail = (event: CheckboxChangeEvent) => {
        if (!event.target.checked) {
            form.setFieldsValue({ app: true });
            form.resetFields(['replyTo', 'subject', 'body']);
        }
    };

    const changeScheduleSending = (event: CheckboxChangeEvent) => {
        if (!event.target.checked) {
            form.resetFields(['sendTime', 'timeZone']);
        }
    };

    /*** COMPONENTS ***/

    const roleOptions = roles
        .filter((r) => r.id && allowedRoleIds.includes(r.id))
        .map((role) => (
            <Select.Option key={role.id} value={role.id!}>
                <FormattedMessage id={`notifications.${role.name}`} />
            </Select.Option>
        ));
    const timeZoneOptions = timeZones.map((t) => (
        <Select.Option key={t} value={t}>
            <FormattedMessage id={t} />
        </Select.Option>
    ));

    return (
        <>
            <Row gutter={[28, 0]} hidden={allowedRoleIds.length < 2}>
                <Col xs={24} sm={24} md={24}>
                    <Form.Item label={<FormattedMessage id="notifications.recipients" />} name="roles">
                        <Select
                            className={`placeholder-select ${styles.multioption}`}
                            mode="multiple"
                            placeholder={<FormattedMessage id="notifications.all" />}
                            showSearch={false}
                        >
                            {roleOptions}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <h2>
                <FormattedMessage id="notifications.destination" />
            </h2>
            <Row gutter={[28, 0]} align="middle">
                <Col span={6}>
                    <Form.Item name="app" valuePropName="checked">
                        <Checkbox onChange={changeApp}>
                            <FormattedMessage id="notifications.appNotification" />
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name="email" valuePropName="checked">
                        <Checkbox onChange={changeEmail}>
                            <FormattedMessage id="notifications.email" />
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col span={14}>
                    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.email !== curValues.email}>
                        {() => {
                            return (
                                <Form.Item
                                    label={<FormattedMessage id="notifications.reply" />}
                                    name="replyTo"
                                    rules={
                                        form.getFieldValue('email')
                                            ? [
                                                  {
                                                      required: true,
                                                      message: <FormattedMessage id="status.mandatory" />,
                                                  },
                                                  {
                                                      type: 'email',
                                                      message: <FormattedMessage id="status.email.invalid" />,
                                                  },
                                              ]
                                            : [
                                                  {
                                                      type: 'email',
                                                      message: <FormattedMessage id="status.email.invalid" />,
                                                  },
                                              ]
                                    }
                                >
                                    <Input maxLength={100} disabled={!form.getFieldValue('email')} />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
            </Row>

            <h2>
                <FormattedMessage id="notifications.message" />
            </h2>
            <Row gutter={[28, 0]}>
                <Col xs={24} sm={18} md={24}>
                    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.email !== curValues.email}>
                        {() => {
                            return (
                                <Form.Item
                                    label={<FormattedMessage id="notifications.subject" />}
                                    name="subject"
                                    hidden={!form.getFieldValue('email')}
                                    rules={
                                        form.getFieldValue('email')
                                            ? [
                                                  {
                                                      required: true,
                                                      message: <FormattedMessage id="status.mandatory" />,
                                                  },
                                              ]
                                            : []
                                    }
                                >
                                    <Input maxLength={100} />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[28, 0]}>
                <Col xs={24} sm={18} md={24}>
                    <Form.Item
                        label={<FormattedMessage id="notifications.body" />}
                        name="body"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        extra={<FormattedMessage id="notifications.characterLimitation" />}
                    >
                        <TextArea style={{ resize: 'none' }} showCount />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[28, 0]} align="middle">
                <Col xs={8} sm={6} md={6}>
                    <Form.Item name="scheduleSending" valuePropName="checked">
                        <Checkbox onChange={changeScheduleSending} className={styles.checkbox}>
                            <FormattedMessage id="notifications.schedule" />
                        </Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={11} sm={6} md={8}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.scheduleSending !== curValues.scheduleSending
                        }
                    >
                        {() => {
                            return (
                                <Form.Item
                                    label={<FormattedMessage id="notifications.dateAndTime" />}
                                    name="sendTime"
                                    rules={[
                                        {
                                            required: form.getFieldValue('scheduleSending'),
                                            message: <FormattedMessage id="status.mandatory" />,
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        size="large"
                                        use12Hours={true}
                                        showTime={{ format: 'hh:mm A' }}
                                        format="MM/DD/YYYY hh:mm A"
                                        minuteStep={15}
                                        disabled={!form.getFieldValue('scheduleSending')}
                                        disabledDate={(current) => current.isBefore(moment().add(-1, 'second'))}
                                    />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
                <Col xs={11} sm={6} md={10}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                            prevValues.scheduleSending !== curValues.scheduleSending
                        }
                    >
                        {() => {
                            return (
                                <Form.Item
                                    label={<FormattedMessage id="notifications.timeZone" />}
                                    name="timeZone"
                                    rules={[
                                        {
                                            required: form.getFieldValue('scheduleSending'),
                                            message: <FormattedMessage id="status.mandatory" />,
                                        },
                                    ]}
                                >
                                    <Select disabled={!form.getFieldValue('scheduleSending')}>{timeZoneOptions}</Select>
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
export default RecipientsAndContentFormItems;

interface RecipientsAndContentFormItemsProps {
    form: FormInstance;
    allowedRoleIds: number[];
    updateRoles: (roles: Role[]) => void;
}
