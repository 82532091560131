import positionApi from '../apis/PositionApi';
import sportApi from '../apis/SportApi';
import { Position, Sport } from '../model/Entities';

class PositionService {
    positionsBySport: PositionBySport[] = [];

    constructor() {
        this.init();
    }

    init = async () => {
        const sports = await sportApi.listAll();

        this.positionsBySport = await Promise.all(sports.map((s) => this.loadSportPositions(s)));
    };

    getPositionsBySport = (sportId: number) => {
        return this.positionsBySport.find((ps) => ps.sport.id === sportId)?.positions || [];
    };

    getPositionNamesBySport = (sportId: number) => {
        return this.getPositionsBySport(sportId)
            .map((p) => p.name)
            .join(',');
    };

    getPositionCodesBySport = (sportId: number) => {
        return this.getPositionsBySport(sportId)
            .map((p) => p.code)
            .join(',');
    };

    getPositionCodesBySportFiltered = (sportId: number, positions: number[]) => {
        return this.getPositionsBySport(sportId)
            .filter((p) => positions.find((position) => position === p.id))
            .map((p) => p.code)
            .join(',');
    };

    private loadSportPositions = async (sport: Sport): Promise<PositionBySport> => {
        const positions = await positionApi.list(sport.id!);

        return {
            sport,

            positions,
        };
    };
}

const positionService: PositionService = new PositionService();
export default positionService;

export interface PositionBySport {
    sport: Sport;
    positions: Position[];
}
