import { Page } from '../model/Elements';
import { EventNewPlayer, EventPlayer } from '../model/Entities';
import apiAxios from './CustomAxios';

class EventPlayerApi {
    list = async (
        limit: number,
        page: number,
        eventId: number,
        search?: string,
        participated?: boolean,
        sortField?: string,
        sortOrder?: string,
    ): Promise<Page<EventPlayer>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const field = this.getSortField(sortField);
        const filteringParticipatedStatus = 'filtering.Participated';
        const response = await apiAxios.get<Page<EventPlayer>>(`/v1/events/${eventId}/players`, {
            params: { limit, page, search, [filteringParticipatedStatus]: participated, [field]: order },
        });

        return response.data;
    };

    create = async (eventId: number, eventNewPlayer: EventNewPlayer): Promise<EventNewPlayer> => {
        const response = await apiAxios.post<EventNewPlayer>(`/v1/events/${eventId}/players`, eventNewPlayer);
        return response.data;
    };

    private getSortField(sortField?: string): string {
        let field: string;
        if (sortField === 'givenName') {
            field = 'sorting.GivenName';
        } else if (sortField === 'familyName') {
            field = 'sorting.FamilyName';
        } else if (sortField === 'userName') {
            field = 'sorting.UserName';
        } else if (sortField === 'userStatus') {
            field = 'sorting.UserStatus';
        } else if (sortField === 'division') {
            field = 'sorting.Division';
        } else if (sortField === 'affiliate') {
            field = 'sorting.Affiliate';
        } else {
            field = 'sorting.GivenName';
        }

        return field;
    }
}

const eventPlayerApi: EventPlayerApi = new EventPlayerApi();
export default eventPlayerApi;
