import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Input, InputNumber, message, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import needFeedApi from '../../../../apis/NeedFeedApi';
import CustomContext from '../../../../context/CustomContext';
import { NeedFeed } from '../../../../model/Entities';
import notificationService from '../../../../services/NotificationService';

class PostNeedModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            if (this.props.need) {
                this.formRef.current?.setFieldsValue(this.props.need);
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    create = async () => {
        try {
            this.setState({ loading: 'saving' });

            const values = await this.formRef.current?.validateFields();
            const need = Object.assign({}, values);
            await needFeedApi.create(this.context.auth?.colleges?.find(Boolean)?.id!, need);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    update = async () => {
        const { need } = this.props;
        try {
            this.setState({ loading: 'saving' });

            const values = await this.formRef.current?.validateFields();
            const needAux = Object.assign({}, need, values);
            await needFeedApi.update(needAux);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.props.onUpdate();
        } catch (error: any) {
            if (!error.errorFields) {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: undefined });
        }
    };

    delete = async () => {
        const { need } = this.props;
        try {
            this.setState({ loading: 'deleting' });

            await needFeedApi.delete(need!);
            message.success(this.props.intl.formatMessage({ id: 'status.action' }));

            this.props.onUpdate();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    render() {
        const { loading } = this.state;

        const minGraduationYear = moment().year() - 19;
        const maxGraduationYear = moment().year() + 19;

        return (
            <Modal
                width={600}
                visible={true}
                title={
                    this.props.need ? (
                        <FormattedMessage id="postNeed.title.edit" />
                    ) : (
                        <FormattedMessage id="postNeed.title.post" />
                    )
                }
                onCancel={this.props.onCancel}
                footer={[
                    <div>
                        <div>
                            {this.props.need && (
                                <Button
                                    shape={'round'}
                                    onClick={this.delete}
                                    icon={<DeleteOutlined />}
                                    loading={loading === 'deleting'}
                                >
                                    <FormattedMessage id="button.delete" tagName="span" />
                                </Button>
                            )}
                            <Button shape={'round'} onClick={this.props.onCancel}>
                                <FormattedMessage id="button.cancel" />
                            </Button>
                            {this.props.need ? (
                                <Button
                                    shape={'round'}
                                    type="primary"
                                    onClick={this.update}
                                    loading={loading === 'saving'}
                                >
                                    <FormattedMessage id="button.update" tagName="span" />
                                </Button>
                            ) : (
                                <Button
                                    shape={'round'}
                                    type="primary"
                                    onClick={this.create}
                                    loading={loading === 'saving'}
                                >
                                    <FormattedMessage id="postNeed.button.post" tagName="span" />
                                </Button>
                            )}
                        </div>
                    </div>,
                ]}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={16}>
                            <Form.Item
                                label={<FormattedMessage id="postNeed.positionNeeded" />}
                                name="positionNeeded"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label={<FormattedMessage id="postNeed.gradYear" />}
                                name="gradYear"
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    {
                                        min: minGraduationYear,
                                        type: 'number',
                                        max: maxGraduationYear,
                                        message: `${this.props.intl.formatMessage({
                                            id: 'status.gradYear',
                                        })} ${minGraduationYear} ${this.props.intl.formatMessage({
                                            id: 'and',
                                        })} ${maxGraduationYear}`,
                                    },
                                ]}
                            >
                                <InputNumber type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <FormattedMessage id="postNeed.academic" />
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="postNeed.gpa" />} name="gpa">
                                <InputNumber className="stepHidden" type="number" decimalSeparator="." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="postNeed.sat" />} name="sat">
                                <InputNumber className="stepHidden" type="number" min={400} max={1600} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label={<FormattedMessage id="postNeed.act" />} name="act">
                                <InputNumber className="stepHidden" type="number" min={1} max={36} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col xs={24} md={24}>
                            <Form.Item label={<FormattedMessage id="postNeed.additionalInformation" />} name="details">
                                <TextArea />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(PostNeedModal);

interface Props extends WrappedComponentProps {
    onUpdate: () => void;
    onCancel: () => void;
    need?: NeedFeed;
}

interface State {
    loading?: 'initializing' | 'saving' | 'deleting';
}
