import Icon, { ArrowLeftOutlined, FilterOutlined } from '@ant-design/icons';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import { Button, Divider, Drawer, Menu, message, PageHeader, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import divisionApi from '../../../apis/DivisionApi';
import organizationApi from '../../../apis/OrganizationApi';
import organizationGroupApi from '../../../apis/OrganizationGroupApi';
import organizationSportsApi from '../../../apis/OrganizationSportsApi';
import positionApi from '../../../apis/PositionApi';
import sportApi from '../../../apis/SportApi';
import teamApi from '../../../apis/TeamApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import {
    Division,
    Organization,
    OrganizationGroupCoach,
    OrganizationSport,
    Position,
    Sport,
    Team,
} from '../../../model/Entities';
import { DisplayType, genders, GenderType, sortingPlayers, SortingPlayerType } from '../../../model/Types';
import { ReactComponent as dashboardSvg } from '../../../resources/images/ico-dashboard.svg';
import { ReactComponent as gridSvg } from '../../../resources/images/ico-grid.svg';
import { ReactComponent as listSvg } from '../../../resources/images/ico-list.svg';
import { ReactComponent as gendersSvg } from '../../../resources/images/ico-rosterGenders.svg';
import { ReactComponent as gradesSvg } from '../../../resources/images/ico-rosterGrades.svg';
import { ReactComponent as positionsSvg } from '../../../resources/images/ico-rosterPositions.svg';
import { ReactComponent as sportSvg } from '../../../resources/images/ico-rosterSport.svg';
import { ReactComponent as teamsSvg } from '../../../resources/images/ico-rosterTeams.svg';
import logo from '../../../resources/images/top100-logo.png';
import HeadMetadata from '../../../services/HeadMetadata';
import notificationService from '../../../services/NotificationService';
import rolesService from '../../../services/RolesService';
import stringService from '../../../services/StringService';
import AddTeamModal from './AddTeamModal/AddTeamModal';
import DivisionComponent from './DivisionComponent/DivisionComponent';
import styles from './RosterPage.module.scss';

class RosterPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {
            display: 'cards',
            filteredTeams: [],
            teams: [],
            teamIds: [],
            divisions: [],
            sports: [],
            positionIds: [],
            positions: [],
            grades: [],
            gendersSelected: [],
            gradesSelected: [],
            divisionIds: [],
            coaches: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });
            const params = new URLSearchParams(this.props.location.search);
            const responses = await Promise.all([organizationApi.get(this.props.match.params.id), sportApi.listAll()]);
            const organization = responses[0];
            const sports = responses[1];
            const sportId = params.get('sportId') ? +params.get('sportId')! : sports[0].id!;
            await this.filterBySport(organization.id!, sportId);
            const rostersBySport = await organizationSportsApi.get(this.props.match.params.id, sportId);
            const roster = rostersBySport.length > 0 ? rostersBySport[0] : undefined;

            this.setState({ organization, sports, roster });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filter = (teams: Team[], teamIds: string[]) => {
        const filteredTeams = teams
            .filter((t) => teamIds.length === 0 || teamIds.includes(t.id!))
            .sort((a, b) => stringService.sort(a.name, b.name));
        this.setState({ filteredTeams });
    };

    filterBySport = async (organizationId: string, sportId: number) => {
        const teamsAux = await teamApi.listByOrganization(organizationId!, true, sportId);
        const coaches = await organizationGroupApi.listCoaches(organizationId, sportId);
        const teams = teamsAux.sort((a, b) => stringService.sort(a.name, b.name));
        const teamIds: string[] = [];
        this.filter(teams, teamIds);

        let grades: number[] = [];
        teams.forEach((t) => t.teamMembers?.forEach((p) => p.profile?.grade && grades.push(p.profile?.grade)));
        grades = Array.from(new Set(grades)).sort((a, b) => a - b);

        const positions = await positionApi.list(sportId);
        const positionIds: number[] = [];

        const gradesSelected: number[] = [];
        const gendersSelected: GenderType[] = [];

        const divisions = await divisionApi.list(sportId);
        const divisionIds: number[] = [];

        //TODO
        //this.props.history.push(`/rosters/${organizationId}?sportId=${sportId}`);

        this.setState({
            sportId,
            teams,
            teamIds,
            grades,
            positions,
            positionIds,
            gradesSelected,
            gendersSelected,
            divisions,
            divisionIds,
            coaches,
        });
    };

    filterByDivisionId = (divisionId: number) => {
        const divisionIds = this.state.divisionIds.includes(divisionId)
            ? this.state.divisionIds.filter((id) => id !== divisionId)
            : [...this.state.divisionIds, divisionId];

        this.setState({ divisionIds });
    };

    filterByPositionId = (positionId: number) => {
        const positionIds = this.state.positionIds.includes(positionId)
            ? this.state.positionIds.filter((id) => id !== positionId)
            : [...this.state.positionIds, positionId];

        this.setState({ positionIds });
    };

    filterByGender = (gender: GenderType) => {
        const gendersSelected = this.state.gendersSelected.includes(gender)
            ? this.state.gendersSelected.filter((g) => g !== gender)
            : [...this.state.gendersSelected, gender];

        this.setState({ gendersSelected });
    };

    filterByGrade = (grade: number) => {
        const gradesSelected = this.state.gradesSelected.includes(grade)
            ? this.state.gradesSelected.filter((g) => g !== grade)
            : [...this.state.gradesSelected, grade];

        this.setState({ gradesSelected });
    };

    filterBySearchText = (searchText?: string) => {
        this.filter(this.state.teams, this.state.teamIds);
        this.setState({ searchText });
    };

    sort = (sortField: SortingPlayerType) => {
        this.setState({ sortField });
    };

    switchDisplay = (display: DisplayType) => {
        this.setState({ display });
    };

    collapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    changeAddTeamModalVisible = (addTeamModalVisible: boolean) => {
        this.setState({ addTeamModalVisible });
    };

    refresh = async () => {
        this.changeAddTeamModalVisible(false);
        this.init();
    };

    downloadExcel = async () => {
        const { sportId, organization } = this.state;
        try {
            this.setState({ loading: 'downloadingExcel' });

            if (organization) {
                await organizationGroupApi.exportAffiliateSpreadsheet(organization, sportId);

                message.success(this.props.intl.formatMessage({ id: 'status.downloaded' }));
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderLogo = (): React.ReactElement | undefined => {
        const { auth } = this.context;
        if (rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
            return (
                <Link to={`/organizations/${this.props.match.params.id}`}>
                    <Button type="ghost" icon={<ArrowLeftOutlined />} />
                </Link>
            );
        } else {
            return (
                <Link to={`/`}>
                    <Button type="ghost" icon={<ArrowLeftOutlined />} />
                </Link>
            );
        }
    };

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { sports, sportId, display, organization, loading } = this.state;
        const sortingPlayerOptions = sortingPlayers.map((sort) => (
            <Select.Option key={sort} value={sort}>
                <FormattedMessage id={sort} />
            </Select.Option>
        ));

        if (desktop) {
            const { intl } = this.props;
            return (
                <div className={styles.header}>
                    <div className={styles.headerBackground}>
                        <div className={styles.headerBackgroundGradient}></div>
                        {sportId && sports.length > 0 && (
                            <div
                                className={styles.headerBackgroundImage}
                                style={{
                                    backgroundImage: `url(${sports?.filter((s) => s.id === sportId)[0].backgroundUrl})`,
                                }}
                            ></div>
                        )}
                    </div>
                    <PageHeader
                        className={styles.content}
                        title={
                            <Search
                                size="large"
                                className={styles.search}
                                placeholder={intl.formatMessage({ id: 'button.search' })}
                                onSearch={this.filterBySearchText}
                                enterButton={''}
                                allowClear={true}
                            />
                        }
                        extra={
                            <div className={styles.tools}>
                                <Button
                                    className={styles.addTeamButton}
                                    icon={<PlusOutlined />}
                                    size="large"
                                    onClick={() => this.changeAddTeamModalVisible(true)}
                                    hidden={
                                        !rolesService.hasAnyRole(auth, [
                                            'ROLE_ADMIN',
                                            'ROLE_ORGANIZATION_OWNER',
                                            'ROLE_ORGANIZATION_STAFF',
                                        ])
                                    }
                                >
                                    <FormattedMessage id="roster.team.addTeam" tagName="span" />
                                </Button>
                                <Button
                                    className={styles.addTeamButton}
                                    onClick={this.downloadExcel}
                                    loading={loading === 'downloadingExcel'}
                                    hidden={
                                        !rolesService.hasAnyRole(auth, [
                                            'ROLE_ADMIN',
                                            'ROLE_ORGANIZATION_OWNER',
                                            'ROLE_ORGANIZATION_STAFF',
                                        ])
                                    }
                                >
                                    <FormattedMessage id="affiliateGroup.downloadExcel" />
                                </Button>
                                <Link to={`/rosters/${organization?.id}/import?sportId=${sportId}`}>
                                    <Button
                                        className={styles.addTeamButton}
                                        size="large"
                                        shape="round"
                                        disabled={!organization || !sportId}
                                        hidden={this.context.auth?.authorities.includes('ROLE_COLLEGE_COACH')}
                                    >
                                        <FormattedMessage id="rosters.roster.manageRoster.uploadCSV" />
                                    </Button>
                                </Link>
                                <Button
                                    className={display === 'list' ? styles.selectedView : styles.unselectedView}
                                    shape="round"
                                    icon={<Icon component={listSvg} />}
                                    onClick={() => this.switchDisplay('list')}
                                ></Button>
                                <Button
                                    className={display === 'cards' ? styles.selectedView : styles.unselectedView}
                                    shape="round"
                                    icon={<Icon component={gridSvg} />}
                                    onClick={() => this.switchDisplay('cards')}
                                ></Button>
                                <Select
                                    className={styles.sort}
                                    placeholder={<FormattedMessage id="admin.rosters.roster.sort" />}
                                    onChange={this.sort}
                                >
                                    {sortingPlayerOptions}
                                </Select>
                            </div>
                        }
                    />
                </div>
            );
        } else {
            return <Button type="ghost" icon={<FilterOutlined />} onClick={() => this.collapse(true)} />;
        }
    };

    renderMenuFilters = (collapsed: boolean): React.ReactNode | undefined => {
        const {
            organization,
            sports,
            sportId,
            divisions,
            positions,
            positionIds,
            grades,
            gendersSelected,
            gradesSelected,
            divisionIds,
        } = this.state;
        return (
            <Menu.ItemGroup key="roster" title={!collapsed && <FormattedMessage id="roster.navigation" />}>
                <Menu.SubMenu
                    key="roster-sports"
                    icon={<Icon component={sportSvg} />}
                    title={<FormattedMessage id="roster.navigation.sports" />}
                >
                    {sports?.map((sport) => (
                        <Menu.Item
                            key={sport.id}
                            onClick={() => this.filterBySport(organization?.id!, sport.id!)}
                            className={sportId === sport.id ? styles.selected : styles.unselected}
                        >
                            {sport.name}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="roster-divisions"
                    icon={<Icon component={teamsSvg} />}
                    title={<FormattedMessage id="roster.navigation.divisions" />}
                >
                    {divisions.map((division) => (
                        <Menu.Item
                            key={division.id}
                            onClick={() => this.filterByDivisionId(division.id!)}
                            className={divisionIds.includes(division.id!) ? styles.selected : styles.unselected}
                        >
                            {division.name}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="roster-positions"
                    icon={<Icon component={positionsSvg} />}
                    title={<FormattedMessage id="roster.navigation.positions" />}
                >
                    {positions.map((position) => (
                        <Menu.Item
                            key={position.id}
                            onClick={() => this.filterByPositionId(position.id)}
                            className={positionIds.includes(position.id) ? styles.selected : styles.unselected}
                        >
                            {position.name}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="roster-grades"
                    icon={<Icon component={gradesSvg} />}
                    title={<FormattedMessage id="roster.navigation.grades" />}
                >
                    {grades.map((grade: number, index: number) => (
                        <Menu.Item
                            key={`${grade}-${index}`}
                            onClick={() => this.filterByGrade(grade!)}
                            className={gradesSelected.includes(grade) ? styles.selected : styles.unselected}
                        >
                            {grade}
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="roster-genders"
                    icon={<Icon component={gendersSvg} />}
                    title={<FormattedMessage id="roster.navigation.genders" />}
                >
                    {genders.map((gender) => (
                        <Menu.Item
                            key={gender}
                            onClick={() => this.filterByGender(gender!)}
                            className={gendersSelected.includes(gender) ? styles.selected : styles.unselected}
                        >
                            <FormattedMessage id={gender} />
                        </Menu.Item>
                    ))}
                </Menu.SubMenu>
            </Menu.ItemGroup>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const organizationId = this.props.match.params.id;
        const { auth } = this.context;
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="events-dashboard"
                        icon={<Icon component={dashboardSvg} />}
                        onClick={() =>
                            rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])
                                ? this.props.history.push(`/organizations/${organizationId}`)
                                : this.props.history.push(`/`)
                        }
                    >
                        {rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) ? (
                            <Link to={`/organizations/${organizationId}`}>
                                <FormattedMessage id="navigation.admin.dashboard" />
                            </Link>
                        ) : (
                            <Link to={`/`}>
                                <FormattedMessage id="navigation.admin.dashboard" />
                            </Link>
                        )}
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                {this.renderMenuFilters(collapsed)}
            </>
        );
    };

    renderFilterDrawer = (collapsed: boolean): React.ReactNode | undefined => {
        const sortingPlayerOptions = sortingPlayers.map((sort) => (
            <Menu.Item key={sort} onClick={() => this.sort(sort)}>
                <FormattedMessage id={sort} />
            </Menu.Item>
        ));

        return (
            <>
                <FormattedMessage id="roster.navigation" />

                {this.renderMenuFilters(collapsed)}

                <Divider className="divider" />

                <FormattedMessage id="admin.rosters.roster.sort" />

                <Menu.ItemGroup className={styles.sort}>{sortingPlayerOptions}</Menu.ItemGroup>
            </>
        );
    };

    renderDivisionComponent = (desktop: boolean, division: Division): React.ReactElement | undefined => {
        const {
            display,
            positionIds,
            gendersSelected,
            gradesSelected,
            divisions,
            searchText,
            sortField,
            teams,
            sportId,
            coaches,
        } = this.state;

        const disp = desktop ? display : 'list';
        const teamsByDivision = teams.filter((t) => t.divisionId === division.id);
        const teamMembersByDivision = teamsByDivision.flatMap((t) => t.teamMembers);
        if (teamsByDivision.length > 1 || teamMembersByDivision.length > 0) {
            return (
                <DivisionComponent
                    id={division.id!}
                    key={division.id}
                    division={division}
                    organizationId={this.props.match.params.id}
                    positionIds={positionIds}
                    genders={gendersSelected}
                    grades={gradesSelected}
                    searchText={searchText}
                    sortField={sortField}
                    display={disp}
                    teams={teamsByDivision}
                    coaches={coaches}
                    divisions={divisions}
                    sportId={sportId!}
                    onUpdate={this.init}
                    desktop={desktop}
                />
            );
        }
    };

    renderList = (desktop: boolean): React.ReactElement | undefined => {
        const { organization, divisions, divisionIds, teams, collapsed, addTeamModalVisible, sportId, roster } =
            this.state;

        const divisionsSelected =
            divisionIds.length > 0 ? divisions.filter((d) => divisionIds.includes(d.id!)) : divisions;

        return (
            <>
                {addTeamModalVisible && (
                    <AddTeamModal
                        onCancel={() => this.changeAddTeamModalVisible(false)}
                        divisions={divisions}
                        organizationId={organization?.id!}
                        sportId={sportId!}
                        onUpdate={() => this.refresh()}
                    />
                )}
                <Drawer
                    className={styles.mobileFilter}
                    placement="right"
                    onClose={() => this.collapse(false)}
                    visible={collapsed}
                >
                    <Menu>{this.renderFilterDrawer(collapsed!)}</Menu>
                </Drawer>
                <h2 className={styles.orgName}>
                    <div className={styles.logo}>
                        {organization && <img src={organization?.logo || logo} alt={logo} />}
                    </div>
                    {roster?.name}
                </h2>
                {teams.length > 0 &&
                    divisionsSelected.map((division: Division) => this.renderDivisionComponent(desktop, division))}
            </>
        );
    };

    render() {
        const { loading, roster } = this.state;
        const fullName =
            roster &&
            `${roster?.name} - ${this.props.intl.formatMessage({
                id: 'roster.meta.title',
            })}`;
        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    content={this.renderList}
                    menu={this.renderMenu}
                    header={this.renderHeader}
                    logo={this.renderLogo}
                    loading={loading === 'initializing'}
                    page="roster"
                />
            </>
        );
    }
}
export default injectIntl(RosterPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    display: DisplayType;
    organization?: Organization;
    sports: Sport[];
    sportId?: number;
    teams: Team[];
    teamIds: string[];
    divisions: Division[];
    positionIds: number[];
    positions: Position[];
    filteredTeams: Team[];
    grades: number[];
    gradesSelected: number[];
    gendersSelected: GenderType[];
    divisionIds: number[];
    collapsed?: boolean;
    loading?: 'initializing' | 'loading' | 'downloadingExcel';
    searchText?: string;
    sortField?: SortingPlayerType;
    addTeamModalVisible?: boolean;
    roster?: OrganizationSport;
    coaches: OrganizationGroupCoach[];
}
