import Icon from '@ant-design/icons';
import { Button, Col, DatePicker, Form, FormInstance, Input, message, Row, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import userApi from '../../apis/UserApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { User } from '../../model/Entities';
import { genders } from '../../model/Types';
import { ReactComponent as saveSvg } from '../../resources/images/ico-save.svg';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import styles from './SettingsPage.module.scss';

class SettingsPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });
            const userId = this.context.auth!.id;
            const user = await userApi.get(userId);
            const values: any = Object.assign({}, user);
            values.profile.birthday = moment.utc(values.profile.birthday).local();

            this.setState({ user });
            this.formRef.current!.setFieldsValue(values);
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    save = async (values: any) => {
        try {
            this.setState({ loading: 'saving' });
            let user: User = Object.assign({}, this.state.user, values);
            user.profile.birthday = values.profile?.birthday ? values.profile.birthday.valueOf() : undefined;
            user = await userApi.update(user);
            message.success(this.props.intl.formatMessage({ id: 'status.saved' }));

            this.setState({ user });
        } catch (error) {
            notificationService.displayError(error, this.props.intl, [
                { status: 409, message: 'user.status.duplicate' },
            ]);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/
    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        return (
            <div className={styles.header}>
                <h1>
                    <FormattedMessage id="navigation.account.settings" />
                </h1>
            </div>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        return this.renderForm();
    };

    renderForm = (): React.ReactElement | undefined => {
        const { loading } = this.state;

        const gendersOptions = genders.map((gender) => (
            <Select.Option key={gender} value={gender}>
                <FormattedMessage id={gender} />
            </Select.Option>
        ));

        return (
            <Form ref={this.formRef} onFinish={this.save} colon={false} layout="vertical" className={styles.form}>
                <h1>
                    <FormattedMessage id="navigation.account.settings" />
                </h1>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="settings.firstName" />}
                            name={['profile', 'givenName']}
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                    message: <FormattedMessage id="status.name" />,
                                },
                            ]}
                        >
                            <Input maxLength={64} size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="settings.lastName" />}
                            name={['profile', 'familyName']}
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    pattern: /^[^!@#$%^&*()+=\\?\\/,.:;{}[\]]+$/,
                                    message: <FormattedMessage id="status.name" />,
                                },
                            ]}
                        >
                            <Input maxLength={64} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="settings.email" />}
                            name={['profile', 'email']}
                            rules={[
                                { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                {
                                    type: 'email',
                                    message: <FormattedMessage id="status.email.invalid" />,
                                },
                            ]}
                        >
                            <Input size="large" disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="settings.phone" />}
                            name={['profile', 'phone']}
                            rules={[
                                {
                                    pattern: /^[+]?[\d]{1}[\s]?[\d]{3}-?[\d]{3}-?[\d]{4}$/,
                                    message: <FormattedMessage id="status.phoneNumber" />,
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12}>
                        <Form.Item label={<FormattedMessage id="settings.birth" />} name={['profile', 'birthday']}>
                            <DatePicker size="large" format="ll" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label={<FormattedMessage id="settings.gender" />} name={['profile', 'gender']}>
                            <Select>{gendersOptions}</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="settings.hobbies" />}
                            name={['profile', 'coachHobbies']}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="settings.highSchool" />}
                            name={['profile', 'coachHighSchool']}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="settings.college" />}
                            name={['profile', 'coachCollege']}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="settings.spouseName" />}
                            name={['profile', 'coachSpouse']}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12}>
                        <Form.Item label={<FormattedMessage id="settings.kids" />} name={['profile', 'coachKids']}>
                            <Input size="large" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item className={styles.buttons}>
                    <Link to="/organizations">
                        <Button type="text" size="large">
                            <FormattedMessage id="settings.button.back" tagName="span" />
                        </Button>
                    </Link>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<Icon component={saveSvg} />}
                        className={styles.save}
                        loading={loading === 'saving'}
                    >
                        <FormattedMessage id="settings.button.save" tagName="span" />
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    render() {
        const { loading } = this.state;
        return (
            <>
                <HeadMetadata titleKey="settings.meta.title" />
                <LayoutComponent
                    page="settings"
                    content={this.renderContent}
                    header={this.renderHeader}
                    loading={loading === 'initializing'}
                />
            </>
        );
    }
}
export default injectIntl(SettingsPage);

interface Props extends WrappedComponentProps {}

interface State {
    user?: User;
    loading?: 'loading' | 'saving' | 'initializing';
}
