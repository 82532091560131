import { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Menu, Select } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import familyApi from '../../apis/FamilyApi';
import sportApi from '../../apis/SportApi';
import userApi from '../../apis/UserApi';
import AddUserModal from '../../components/AddUserModal/AddUserModal';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import UserAccountComponent from '../../components/UserAccountComponent/UserAccountComponent';
import CustomContext from '../../context/CustomContext';
import { Family, Sport, UserAccount } from '../../model/Entities';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import styles from './ParentProfilePage.module.scss';
import avatar from '../../resources/images/profile-placeholder.png';
import UserBackgroundComponent from '../../components/UserBackgroundComponent/UserBackgroundComponent';

class ParentProfilePage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { sports: [] };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.init();
        }
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            // get parent and family
            const responses = await Promise.all([userApi.getAccount(this.props.match.params.id), sportApi.listAll()]);
            const user = responses[0];
            const sports = responses[1];
            const family = await familyApi.get(user?.family?.id!);

            this.setState({ user, family, sports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeAddUserModalVisible = (addUserModalVisible: boolean, userType?: string) => {
        this.setState({ addUserModalVisible, userType });
    };

    refresh = async () => {
        const { family } = this.state;
        try {
            this.setState({ loading: 'loading' });

            // get family
            const familyUpdated = await familyApi.get(family?.id!);
            this.changeAddUserModalVisible(false);

            this.setState({ family: familyUpdated });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { user, sports } = this.state;
        if (desktop) {
            return (
                <div className={styles.header}>
                    <UserBackgroundComponent sports={sports} />

                    <div className={styles.buttons}>
                        <div className={styles.avatar}>
                            <Avatar src={user?.photoUrl || avatar} className={styles.image} />
                            <span className={styles.parentName}>
                                {user?.givenName} {user?.familyName}
                            </span>
                        </div>

                        {(this.context.auth?.authorities.includes('ROLE_ADMIN') ||
                            this.context.auth?.id === this.props.match.params.id) && (
                            <Link to={`/parents/${this.props.match.params.id}/edit`}>
                                <Button icon={<EditOutlined />}>
                                    <FormattedMessage id="parent.profile.editProfile" tagName="span" />
                                </Button>
                            </Link>
                        )}
                        {
                            <Select
                                size="large"
                                className={styles.addUser}
                                placeholder={<FormattedMessage id="parent.profile.addUser" />}
                                onSelect={(value: string) => this.changeAddUserModalVisible(true, value)}
                            >
                                <Select.Option value="parent">
                                    <FormattedMessage id="parent.profile.addParent" />
                                </Select.Option>
                                <Select.Option value="player">
                                    <FormattedMessage id="parent.profile.addPlayer" />
                                </Select.Option>
                            </Select>
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div className={`${styles.header} ${styles.mobile}`}>
                    <Dropdown
                        overlay={
                            <Menu>
                                {(this.context.auth?.authorities.includes('ROLE_ADMIN') ||
                                    this.context.auth?.id === this.props.match.params.id) && (
                                    <Menu.Item key="edit">
                                        <Link to={`/parents/${this.props.match.params.id}/edit`}>
                                            <FormattedMessage id="parent.profile.editProfile" tagName="span" />
                                        </Link>
                                    </Menu.Item>
                                )}
                                <Menu.Item key="parent" onClick={() => this.changeAddUserModalVisible(true, 'parent')}>
                                    <FormattedMessage id="parent.profile.addParent" />
                                </Menu.Item>
                                <Menu.Item key="child" onClick={() => this.changeAddUserModalVisible(true, 'player')}>
                                    <FormattedMessage id="parent.profile.addPlayer" />
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button className={styles.actions}>
                            <Avatar src={user?.photoUrl || avatar} className={styles.image} />
                        </Button>
                    </Dropdown>
                </div>
            );
        }
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { user, sports, addUserModalVisible, userType, family } = this.state;

        return (
            <>
                {addUserModalVisible && (
                    <AddUserModal
                        onUpdate={this.refresh}
                        onCancel={() => this.changeAddUserModalVisible(false)}
                        userType={userType}
                        familyId={family?.id!}
                    />
                )}
                {user && <UserAccountComponent user={user} sports={sports} family={family} onUpdate={this.refresh} />}
            </>
        );
    };

    render() {
        const { loading, user } = this.state;
        const fullName =
            user &&
            `${user?.givenName} ${user?.familyName} - ${this.props.intl.formatMessage({
                id: 'parentProfile',
            })}`;

        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="parent-profile"
                    content={this.renderContent}
                    header={this.renderHeader}
                    loading={loading === 'initializing'}
                    userId={this.props.match.params.id}
                />
            </>
        );
    }
}
export default injectIntl(ParentProfilePage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    user?: UserAccount;
    family?: Family;
    loading?: 'initializing' | 'loading';
    sports: Sport[];
    addUserModalVisible?: boolean;
    userType?: string;
}
