import { Page } from '../model/Elements';
import { Position } from '../model/Entities';
import apiAxios from './CustomAxios';

class PositionApi {
    list = async (sportId: number, name?: string, code?: string): Promise<Position[]> => {
        const response = await apiAxios.get<Page<Position>>(`/v1/sports/${sportId}/positions`, {
            params: { sportId, name, code },
        });

        return response.data.items;
    };

    get = async (sportId: number, positionId: number, id: number): Promise<Position> => {
        const response = await apiAxios.get<Position>(`/v1/sports/${sportId}/positions/${positionId}`);
        return response.data;
    };

    create = async (position: Position, sportId: number): Promise<Position> => {
        const response = await apiAxios.post<Position>(`/v1/sports/${sportId}/positions`, position);
        return response.data;
    };

    update = async (position: Position, sportId: number): Promise<Position> => {
        const response = await apiAxios.put<Position>(`/v1/sports/${sportId}/positions/${position.id}`, position);
        return response.data;
    };

    delete = async (position: Position, sportId: number): Promise<void> => {
        await apiAxios.delete(`/v1/sports/${sportId}/positions/${position.id}`);
    };
}
const positionApi: PositionApi = new PositionApi();
export default positionApi;
