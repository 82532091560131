import { Tooltip } from 'antd';
import React, { Component } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import collegeApi from '../../../../apis/CollegeApi';
import { College, CollegeDivision } from '../../../../model/Entities';
import notificationService from '../../../../services/NotificationService';
import styles from './MarkerComponent.module.scss';

class MarkerComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });

            const college = await collegeApi.get(this.props.collegeId);

            this.setState({ college });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    getStyles = (college?: College): string | undefined => {
        const divisionId = college?.divisions?.sort().find(Boolean);

        return styles[`pin${divisionId}`];
    };

    /*** COMPONENTS ***/

    renderContent = (): React.ReactElement | undefined => {
        const { collegeDivisions } = this.props;
        const { college } = this.state;

        return (
            <Tooltip
                overlay={
                    college && (
                        <>
                            {college?.athletesUrl ? (
                                <b>
                                    <Link to={{ pathname: college?.athletesUrl }} target="_blank" rel="noreferrer">
                                        {college?.name}
                                    </Link>
                                </b>
                            ) : (
                                <b>{college?.name}</b>
                            )}
                            <div>
                                {collegeDivisions
                                    .filter((d) => college?.divisions?.find((division) => d.id === division))
                                    .map((d) => d.name)
                                    .join(', ')}
                            </div>
                        </>
                    )
                }
                trigger={['hover', 'click']}
            >
                <span className={`${styles.pin} ${this.getStyles(college)}`} />
            </Tooltip>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(MarkerComponent);

interface Props extends WrappedComponentProps {
    lat?: number;
    lng?: number;
    collegeId?: number;
    collegeDivisions: CollegeDivision[];
}

interface State {
    loading?: boolean;
    college?: College;
}
