import Icon, { EllipsisOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Menu, message, Modal, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import organizationApi from '../../../apis/OrganizationApi';
import organizationGroupApi from '../../../apis/OrganizationGroupApi';
import organizationProfileApi from '../../../apis/OrganizationProfileApi';
import sportApi from '../../../apis/SportApi';
import userApi from '../../../apis/UserApi';
import AddAffiliateUserModal from '../../../components/AddAffiliateUserModal/AddAffiliateUserModal';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/Elements';
import { Organization, OrganizationGroupPlayer, OrganizationGroupStaff, Sport } from '../../../model/Entities';
import HeadMetadata from '../../../services/HeadMetadata';
import notificationService from '../../../services/NotificationService';
import rolesService from '../../../services/RolesService';
import stringService from '../../../services/StringService';
import tableService from '../../../services/TableService';
import styles from './AffiliateGroupPage.module.scss';
import MovePlayerModal from './MovePlayerModal/MovePlayerModal';
import { ReactComponent as dashboardSvg } from '../../../resources/images/ico-dashboard.svg';
import { ReactComponent as usersGroupsSvg } from '../../../resources/images/ico-grid.svg';
import { ReactComponent as invoicesSvg } from '../../../resources/images/ico-list.svg';
import { ReactComponent as playerProfileSvg } from '../../../resources/images/ico-playerProfile.svg';

class AffiliateGroupPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    readonly pageSize = 50;

    constructor(props: Props) {
        super(props);
        this.state = { sports: [], sortFieldPlayers: 'division', allSports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const response = await Promise.all([
                organizationApi.get(this.props.match.params.id),
                organizationGroupApi.listStaff(this.pageSize, 1, this.props.match.params.id),
                organizationGroupApi.listPlayers(
                    tableService.pageSize,
                    1,
                    this.props.match.params.id,
                    this.state.sortFieldPlayers,
                ),
                organizationProfileApi.get(this.props.match.params.id),
                sportApi.listAll(),
            ]);
            const organization = response[0];
            const staffPage = response[1];
            const playersPage = response[2];
            const organizationProfile = response[3];
            const allSports = response[4];
            const sports = allSports.filter((s) => organizationProfile.sports?.find((os) => os.sportId === s.id));

            this.setState({ staffPage, playersPage, organization, sports, allSports });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    listPlayers = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<OrganizationGroupPlayer> | SorterResult<OrganizationGroupPlayer>[],
    ) => {
        const { searchText, sportId, sortFieldPlayers } = this.state;
        try {
            this.setState({ loading: 'loadingPlayers' });

            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            let newSortFieldPlayers, sortOrderPlayers;
            if (Object.keys(sorter).length === 0) {
                newSortFieldPlayers = sortFieldPlayers;
            } else {
                if (Array.isArray(sorter)) {
                } else {
                    newSortFieldPlayers = sorter.field as string;
                    sortOrderPlayers = sorter.order as string;
                }
            }

            const playersPage = await organizationGroupApi.listPlayers(
                pageSize,
                page,
                this.props.match.params.id,
                newSortFieldPlayers,
                sortOrderPlayers,
                searchText,
                sportId,
            );

            this.setState({ playersPage, sortFieldPlayers: newSortFieldPlayers, sortOrderPlayers });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    searchPlayers = async (searchText: string) => {
        const { sortFieldPlayers, sortOrderPlayers, sportId } = this.state;
        try {
            this.setState({ loading: 'loadingPlayers' });

            const playersPage = await organizationGroupApi.listPlayers(
                tableService.pageSize,
                1,
                this.props.match.params.id,
                sortFieldPlayers,
                sortOrderPlayers,
                searchText,
                sportId,
            );

            this.setState({ playersPage, searchText });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    filterBySport = async (sportId?: number) => {
        const { sortFieldPlayers, sortOrderPlayers, searchText } = this.state;
        try {
            this.setState({ loading: 'loadingPlayers' });

            const playersPage = await organizationGroupApi.listPlayers(
                tableService.pageSize,
                1,
                this.props.match.params.id,
                sortFieldPlayers,
                sortOrderPlayers,
                searchText,
                sportId,
            );

            this.setState({ playersPage, sportId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    listStaff = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<OrganizationGroupStaff> | SorterResult<OrganizationGroupStaff>[],
    ) => {
        try {
            this.setState({ loading: 'loadingStaff' });

            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            let sortFieldStaff, sortOrderStaff;
            if (Array.isArray(sorter)) {
            } else {
                sortFieldStaff = sorter.field as string;
                sortOrderStaff = sorter.order as string;
            }
            const staffPage = await organizationGroupApi.listStaff(
                pageSize,
                page,
                this.props.match.params.id,
                sortFieldStaff,
                sortOrderStaff,
            );

            this.setState({ staffPage, sortFieldStaff, sortOrderStaff });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    refresh = async () => {
        const { sortFieldStaff, sortOrderStaff, sortFieldPlayers, sortOrderPlayers, searchText, sportId } = this.state;
        try {
            this.setState({ loading: 'loading' });

            // get staff
            const staffPage = await organizationGroupApi.listStaff(
                tableService.pageSize,
                1,
                this.props.match.params.id,
                sortFieldStaff,
                sortOrderStaff,
            );
            const playersPage = await organizationGroupApi.listPlayers(
                tableService.pageSize,
                1,
                this.props.match.params.id,
                sortFieldPlayers,
                sortOrderPlayers,
                searchText,
                sportId,
            );
            this.changeAddAffiliateUserModalVisible(false);

            this.setState({ staffPage, playersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    invitePlayer = async () => {
        const { sortFieldPlayers, sortOrderPlayers, searchText, sportId, player } = this.state;
        try {
            this.setState({ loading: 'inviting' });

            const players: string[] = [];
            players.push(player!.userId!);
            await userApi.sendInvites(players);

            this.showInviteModal(false);
            message.success(this.props.intl.formatMessage({ id: 'status.invitesSent' }));

            const playersPage = await organizationGroupApi.listPlayers(
                tableService.pageSize,
                1,
                this.props.match.params.id,
                sortFieldPlayers,
                sortOrderPlayers,
                searchText,
                sportId,
            );

            this.setState({ playersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    reInvitePlayer = async () => {
        const { sortFieldPlayers, sortOrderPlayers, searchText, sportId, player } = this.state;
        try {
            this.setState({ loading: 'inviting' });

            const players: string[] = [];
            players.push(player!.userId!);
            await userApi.sendReInvites(players);

            this.showReInviteModal(false);
            message.success(this.props.intl.formatMessage({ id: 'status.invitesSent' }));

            const playersPage = await organizationGroupApi.listPlayers(
                tableService.pageSize,
                1,
                this.props.match.params.id,
                sortFieldPlayers,
                sortOrderPlayers,
                searchText,
                sportId,
            );

            this.setState({ playersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    refreshPlayers = async () => {
        const { sortFieldPlayers, sortOrderPlayers, searchText, sportId } = this.state;
        try {
            this.setState({ loading: 'loadingPlayers' });

            this.showMovePlayerModal(false);
            const playersPage = await organizationGroupApi.listPlayers(
                tableService.pageSize,
                1,
                this.props.match.params.id,
                sortFieldPlayers,
                sortOrderPlayers,
                searchText,
                sportId,
            );

            this.setState({ playersPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    downloadExcel = async () => {
        const { sportId, organization } = this.state;
        try {
            this.setState({ loading: 'downloadingExcel' });

            if (organization) {
                await organizationGroupApi.exportAffiliateSpreadsheet(organization, sportId);

                message.success(this.props.intl.formatMessage({ id: 'status.downloaded' }));
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    showInviteModal = (inviteModalVisible: boolean, player?: OrganizationGroupPlayer) => {
        this.setState({ inviteModalVisible, player });
    };

    showReInviteModal = (reInviteModalVisible: boolean, player?: OrganizationGroupPlayer) => {
        this.setState({ reInviteModalVisible, player });
    };

    showMovePlayerModal = (movePlayerModalVisible: boolean, player?: OrganizationGroupPlayer) => {
        this.setState({ movePlayerModalVisible, player });
    };

    changeAddAffiliateUserModalVisible = (addAffiliateUserModalVisible: boolean, userType?: string) => {
        this.setState({ addAffiliateUserModalVisible, userType });
    };

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { organization, loading } = this.state;
        const { auth } = this.context;

        if (desktop) {
            return (
                <>
                    <div className={styles.header}>
                        <p>
                            <Link to="/groups">
                                <FormattedMessage id="groups.title" />
                            </Link>{' '}
                            / {organization?.name}
                        </p>

                        <span className={styles.headerSelect}>
                            <Button
                                className={styles.downloadExcel}
                                onClick={this.downloadExcel}
                                loading={loading === 'downloadingExcel'}
                                hidden={
                                    !rolesService.hasAnyRole(auth, [
                                        'ROLE_ADMIN',
                                        'ROLE_ORGANIZATION_OWNER',
                                        'ROLE_ORGANIZATION_STAFF',
                                    ])
                                }
                            >
                                <FormattedMessage id="affiliateGroup.downloadExcel" />
                            </Button>
                            <Select
                                size="large"
                                className={styles.addUser}
                                placeholder={<FormattedMessage id="affiliateOwner.addUser" />}
                                onSelect={(value: string) => this.changeAddAffiliateUserModalVisible(true, value)}
                                disabled={!organization}
                            >
                                {rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_ORGANIZATION_OWNER']) && (
                                    <Select.Option value="affiliateOwner">
                                        {!organization?.primary ? (
                                            <FormattedMessage id="affiliateGroup.addAffiliateOwner" />
                                        ) : (
                                            <FormattedMessage id="affiliateGroup.addTop100Admin" />
                                        )}
                                    </Select.Option>
                                )}
                                {rolesService.hasAnyRole(auth, [
                                    'ROLE_ADMIN',
                                    'ROLE_ORGANIZATION_OWNER',
                                    'ROLE_ORGANIZATION_STAFF',
                                ]) && (
                                    <Select.Option value="affiliateStaff">
                                        {!organization?.primary ? (
                                            <FormattedMessage id="affiliateGroup.addAffiliateStaff" />
                                        ) : (
                                            <FormattedMessage id="affiliateGroup.addTop100Staff" />
                                        )}
                                    </Select.Option>
                                )}
                                {!organization?.primary && (
                                    <>
                                        <Select.Option value="coach">
                                            <FormattedMessage id="affiliateGroup.addCoach" />
                                        </Select.Option>
                                        <Select.Option value="player">
                                            <FormattedMessage id="parent.profile.addPlayer" />
                                        </Select.Option>
                                    </>
                                )}
                            </Select>
                        </span>
                    </div>
                </>
            );
        } else {
            return <div className={styles.header}></div>;
        }
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { auth } = this.context;
        const organizationId = this.props.match.params.id;

        if (rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
            return (
                <>
                    <Menu.ItemGroup>
                        <Menu.Item
                            key="organizations-dashboard"
                            icon={<Icon component={dashboardSvg} />}
                            onClick={() => this.navigate('/organizations')}
                        >
                            <Link to="/organizations">
                                <FormattedMessage id="navigation.admin.dashboard" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Divider className="divider" />

                    <Menu.ItemGroup
                        key="organization-menu"
                        title={!collapsed && <FormattedMessage id="navigation.organization.dashboard.organization" />}
                    >
                        <Menu.Item
                            key="organization"
                            icon={<Icon component={playerProfileSvg} />}
                            onClick={() => this.navigate(`/organizations/${organizationId}`)}
                        >
                            <Link to={`/organizations/${organizationId}`}>
                                <FormattedMessage id="navigation.organization.dashboard.profile" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="groups"
                            icon={<Icon component={usersGroupsSvg} />}
                            onClick={() => this.navigate(`/groups/affiliates/${organizationId}`)}
                        >
                            <Link to={`/groups/affiliates/${organizationId}`}>
                                <FormattedMessage id="navigation.admin.dashboard.groups" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="invoices"
                            icon={<Icon component={invoicesSvg} />}
                            onClick={() => this.navigate(`/invoices/${organizationId}`)}
                        >
                            <Link to={`/invoices/${organizationId}`}>
                                <FormattedMessage id="navigation.admin.dashboard.invoices" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </>
            );
        }
    };

    renderStaffNameLink = (staff: OrganizationGroupStaff): React.ReactElement | string | undefined => {
        if (this.context.auth?.authorities.includes('ROLE_ADMIN')) {
            if (staff.roles?.includes('Parent') || staff.roles?.includes('OrganizationOwner')) {
                return (
                    <Link to={`/parents/${staff.userId}`}>
                        {stringService.getName(true, staff.givenName, staff.familyName)}
                    </Link>
                );
            }
        } else {
            return stringService.getName(true, staff.givenName, staff.familyName);
        }
    };

    renderStaffTable = (): React.ReactElement | undefined => {
        const { staffPage, loading } = this.state;
        const items = staffPage && staffPage.items ? staffPage.items : [];

        const columns: ColumnsType<OrganizationGroupStaff> = [
            {
                title: <FormattedMessage id="affiliateGroup.name" />,
                dataIndex: 'givenName',
                key: 'givenName',
                sorter: true,
                width: 300,
                render: (value: string, staff: OrganizationGroupStaff) => (
                    <span className={styles.name}>{this.renderStaffNameLink(staff)}</span>
                ),
            },
            {
                title: <FormattedMessage id="affiliateGroup.role" />,
                dataIndex: 'roles',
                key: 'roles',
                sorter: true,
                width: 300,
                render: (value: string, staff: OrganizationGroupStaff) =>
                    staff.roles?.map((role) => this.props.intl.formatMessage({ id: `roles.${role}` })).join(', '),
            },
            {
                title: <FormattedMessage id="affiliateGroup.family" />,
                dataIndex: 'family',
                key: 'family',
                width: 300,
                render: (value: string, staff: OrganizationGroupStaff) => staff.family?.name,
            },
            {
                title: <FormattedMessage id="affiliateGroup.email" />,
                dataIndex: 'email',
                key: 'email',
                sorter: true,
                render: (value: string, staff: OrganizationGroupStaff) => value,
            },
        ];

        return (
            <>
                <div className={styles.players}>
                    <FormattedMessage id="affiliateGroup.staff" />
                </div>
                <Table
                    dataSource={items}
                    columns={columns}
                    pagination={false}
                    rowKey="userId"
                    loading={loading === 'initializing' || loading === 'loadingStaff' || loading === 'loading'}
                    onChange={this.listStaff}
                    className={styles.table}
                />
            </>
        );
    };

    renderPlayersTableHeader = (): React.ReactElement | undefined => {
        const { playersPage, sports, sportId } = this.state;
        return (
            <>
                <div className={styles.playersTableHeader}>
                    <div className={styles.flex}>
                        <span className={styles.players}>
                            <FormattedMessage id="affiliateGroup.players" />
                        </span>
                        <Search
                            placeholder={this.props.intl.formatMessage({ id: 'button.search' })}
                            className={styles.search}
                            enterButton={''}
                            allowClear={true}
                            onSearch={this.searchPlayers}
                        />
                    </div>

                    <div className={styles.flex}>
                        <span className={styles.filters}>
                            <span className={styles.totalPlayers}>
                                {playersPage?.totalItems} <FormattedMessage id="affiliateGroup.totalPlayers" />
                            </span>
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item key="all" onClick={() => this.filterBySport()}>
                                            <FormattedMessage id="button.all" />
                                        </Menu.Item>
                                        {sports.map((s) => {
                                            return (
                                                <Menu.Item key={s.id} onClick={() => this.filterBySport(s.id)}>
                                                    {s.name}
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu>
                                }
                            >
                                <Button type="text" className={styles.filter} icon={<FilterOutlined />}></Button>
                            </Dropdown>
                            <Link to={`/rosters/${this.props.match.params.id}/import?sportId=${sportId}`}>
                                <Button size="large" shape="round" disabled={!sportId} className={styles.button}>
                                    <FormattedMessage id="rosters.roster.manageRoster.uploadCSV" />
                                </Button>
                            </Link>
                        </span>
                    </div>
                </div>
            </>
        );
    };

    renderInviteModal = (): React.ReactElement | undefined => {
        const { inviteModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="affiliateGroup.invitePlayersModal.title" />}
                visible={inviteModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.invitePlayer}
                okButtonProps={{ loading: loading === 'inviting' }}
                onCancel={() => this.showInviteModal(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="affiliateGroup.invitePlayersModal.description" />
                </p>
            </Modal>
        );
    };

    renderReInviteModal = (): React.ReactElement | undefined => {
        const { reInviteModalVisible, loading } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="affiliateGroup.reinvitePlayersModal.title" />}
                visible={reInviteModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={this.reInvitePlayer}
                okButtonProps={{ loading: loading === 'inviting' }}
                onCancel={() => this.showReInviteModal(false)}
                className={styles.modal}
            >
                <p>
                    <FormattedMessage id="affiliateGroup.reinvitePlayersModal.description" />
                </p>
            </Modal>
        );
    };

    renderPlayersTable = (): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { playersPage, loading } = this.state;
        const items = playersPage && playersPage.items ? playersPage.items : [];

        const columns: ColumnsType<OrganizationGroupPlayer> = [
            {
                title: <FormattedMessage id="affiliateGroup.name" />,
                dataIndex: 'givenName',
                key: 'givenName',
                sorter: true,
                render: (value: string, player: OrganizationGroupPlayer) => (
                    <>
                        <div className={styles.name}>
                            <Link to={`/players/${player.userId}?sportId=${player.sports?.find(Boolean)}`}>
                                {stringService.getName(true, value, player.familyName)}
                            </Link>
                        </div>
                        <div className={styles.email}>{player.email}</div>
                        {this.context.auth?.authorities.includes('ROLE_ADMIN') ? (
                            <Link to={`/groups/families/${player.family?.id}`} className={styles.family}>
                                {player.family?.name}
                            </Link>
                        ) : (
                            player.family?.name
                        )}
                    </>
                ),
            },
            {
                title: <FormattedMessage id="affiliateGroup.division" />,
                dataIndex: 'division',
                key: 'division',
                sorter: true,
                width: 200,
                render: (value: number, player: OrganizationGroupPlayer) => player.division?.name,
            },
            {
                title: <FormattedMessage id="affiliateGroup.username" />,
                dataIndex: 'userName',
                key: 'userName',
                sorter: true,
                width: 250,
                render: (value: string, player: OrganizationGroupPlayer) => value,
            },
            {
                title: <FormattedMessage id="affiliateGroup.status" />,
                dataIndex: 'userStatus',
                key: 'userStatus',
                sorter: true,
                width: 200,
                render: (value: string, player: OrganizationGroupPlayer) =>
                    value && <FormattedMessage id={`userStatuses.${value}`} />,
            },
            {
                title: <FormattedMessage id="affiliateGroup.actions" />,
                width: 90,
                render: (value: string, player: OrganizationGroupPlayer) => (
                    <Dropdown
                        overlay={
                            <Menu>
                                {player.userStatus === 'None' && (
                                    <Menu.Item key="invite" onClick={() => this.showInviteModal(true, player)}>
                                        <FormattedMessage id="affiliateGroup.invite" />
                                    </Menu.Item>
                                )}
                                {(player.userStatus === 'Active' ||
                                    player.userStatus === 'InviteSent' ||
                                    player.userStatus === 'InviteError') && (
                                    <Menu.Item key="reInvite" onClick={() => this.showReInviteModal(true, player)}>
                                        <FormattedMessage id="affiliateGroup.reInvite" />
                                    </Menu.Item>
                                )}
                                <Menu.Item
                                    key="reInvite"
                                    onClick={() => this.showMovePlayerModal(true, player)}
                                    hidden={!rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])}
                                >
                                    <FormattedMessage id="affiliateGroup.movePlayer" />
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type="text" className={styles.actions}>
                            <EllipsisOutlined />
                        </Button>
                    </Dropdown>
                ),
            },
        ];

        return (
            <>
                {this.renderPlayersTableHeader()}
                <Table
                    dataSource={items}
                    columns={columns}
                    pagination={tableService.createPagination(playersPage)}
                    rowKey="userName"
                    loading={loading === 'initializing' || loading === 'loadingPlayers' || loading === 'loading'}
                    onChange={this.listPlayers}
                    className={styles.table}
                />
            </>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { addAffiliateUserModalVisible, userType, movePlayerModalVisible, organization, player, allSports } =
            this.state;

        return (
            <>
                {addAffiliateUserModalVisible && (
                    <AddAffiliateUserModal
                        onUpdate={this.refresh}
                        onCancel={() => this.changeAddAffiliateUserModalVisible(false)}
                        userType={userType}
                        organization={organization}
                    />
                )}
                <h1>{organization?.name}</h1>
                {this.renderStaffTable()}
                {this.renderPlayersTable()}
                {this.renderInviteModal()}
                {this.renderReInviteModal()}
                {movePlayerModalVisible && (
                    <MovePlayerModal
                        sourceOrganizationId={this.props.match.params.id}
                        player={player!}
                        sports={allSports}
                        onCancel={() => this.showMovePlayerModal(false)}
                        onUpdate={this.refreshPlayers}
                    />
                )}
            </>
        );
    };

    render() {
        const { organization } = this.state;
        const fullName =
            organization &&
            `${organization?.name} - ${this.props.intl.formatMessage({
                id: 'affiliates',
            })}`;

        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="groups"
                    header={this.renderHeader}
                    menu={this.renderMenu}
                    content={this.renderContent}
                />
            </>
        );
    }
}
export default injectIntl(AffiliateGroupPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    loading?: 'initializing' | 'loadingPlayers' | 'loadingStaff' | 'loading' | 'inviting' | 'downloadingExcel';
    userType?: string;
    staffPage?: Page<OrganizationGroupStaff>;
    playersPage?: Page<OrganizationGroupPlayer>;
    organization?: Organization;
    searchText?: string;
    sortFieldPlayers?: string;
    sortOrderPlayers?: string;
    sortFieldStaff?: string;
    sortOrderStaff?: string;
    addAffiliateUserModalVisible?: boolean;
    sports: Sport[];
    allSports: Sport[];
    sportId?: number;
    inviteModalVisible?: boolean;
    reInviteModalVisible?: boolean;
    movePlayerModalVisible?: boolean;
    player?: OrganizationGroupPlayer;
}
