import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Divider, List, message, Modal, Row } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import organizationGroupApi from '../../../../apis/OrganizationGroupApi';
import userApi from '../../../../apis/UserApi';
import { FamilyMember } from '../../../../model/Entities';
import avatar from '../../../../resources/images/profile-placeholder.png';
import notificationService from '../../../../services/NotificationService';
import styles from './AddExistingPlayerModal.module.scss';

/**
 * Returns add existing player modal panel.
 * @returns the modal panel.
 */
const AddExistingPlayerModal = (props: ModalProps): React.ReactElement => {
    const { onCancel, organizationId, teamId, onUpdate, sportId } = props;
    const pageSize = 4;

    /** HOOKS **/

    const [loading, setLoading] = useState<any>();
    const intl = useIntl();
    const [users, setUsers] = useState<FamilyMember[]>([]);
    const [searchText, setSearchText] = useState<string>();

    /** METHODS **/

    /** Close modal panel */
    const cancel = async () => {
        onCancel();
    };

    /** Add an existing player to the affiliate */
    const save = async (user: FamilyMember) => {
        try {
            setLoading(`saving-${user.userId}`);

            await organizationGroupApi.addExistingPlayer(organizationId, user.userId!, teamId);
            message.success(intl.formatMessage({ id: 'status.action' }));

            onUpdate();
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const search = async (searchText?: string) => {
        try {
            setLoading('searching');

            const usersPage = searchText ? await userApi.list(pageSize, 1, searchText, 'player') : undefined;
            const allUsers = usersPage?.items ? usersPage.items : [];
            const users = allUsers.filter(
                (u) =>
                    !u.organizationMemberships?.find(
                        (om) => om.organizationId === organizationId && om.sportIds?.includes(sportId),
                    ),
            );

            setUsers(users);
            setSearchText(searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** COMPONENTS ***/

    return (
        <Modal
            width={600}
            visible={true}
            onCancel={cancel}
            //onOk={save}

            title={<FormattedMessage id="rosters.roster.addPlayerModal.title" />}
            footer={[]}
        >
            <Row gutter={[28, 0]}>
                <Search
                    placeholder={intl.formatMessage({ id: 'button.search' })}
                    className={styles.search}
                    enterButton={''}
                    allowClear={true}
                    onSearch={search}
                />
            </Row>
            <Row gutter={[28, 0]} className={styles.newRow}>
                {users.length === 0 && searchText ? (
                    <p className={styles.nofound}>
                        <FormattedMessage id="status.userNotFound" />
                    </p>
                ) : (
                    <List
                        grid={{
                            gutter: 0,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 1,
                            xl: 1,
                            xxl: 1,
                        }}
                        className={styles.list}
                        dataSource={users}
                        locale={{ emptyText: <></> }}
                        renderItem={(user) => (
                            <List.Item key={user.userId} className={styles.listItem}>
                                <div className={styles.userList}>
                                    <div className={styles.user}>
                                        <Avatar className={styles.avatar} size={64} src={user.photoUrl || avatar} />
                                        <p>
                                            <Link
                                                to={`/players/${user.userId}/profile?sportId=${user.sports?.find(
                                                    Boolean,
                                                )}`}
                                                target="_blank"
                                            >
                                                {`${user.givenName} ${user.familyName}`}
                                            </Link>

                                            <span className={styles.email}>{user.email}</span>
                                        </p>
                                    </div>

                                    <Button
                                        className={styles.addUserButton}
                                        type="text"
                                        icon={<PlusOutlined />}
                                        onClick={() => save(user)}
                                        loading={loading === `saving-${user.userId}`}
                                    >
                                        <FormattedMessage id="button.add" tagName="span" />
                                    </Button>
                                </div>
                                <Divider></Divider>
                            </List.Item>
                        )}
                        loading={loading === 'searching'}
                    />
                )}
            </Row>
        </Modal>
    );
};
export default AddExistingPlayerModal;

interface ModalProps {
    organizationId: string;
    divisionId: number;
    teamId: string;
    sportId: number;
    onCancel: () => void;
    onUpdate: () => void;
}
