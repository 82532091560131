import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import CustomContext from '../../../context/CustomContext';
import { ReactComponent as collegeSvg } from '../../../resources/images/ico-collegeDirectory.svg';
import { ReactComponent as eventsSvg } from '../../../resources/images/ico-events.svg';
import { ReactComponent as leaderboardSvg } from '../../../resources/images/ico-leaderboard.svg';
import { ReactComponent as playerProfileSvg } from '../../../resources/images/ico-playerProfile.svg';
import { ReactComponent as rostersSvg } from '../../../resources/images/ico-rosters.svg';
import ParentMenuComponent from '../Common/ParentMenuComponent/ParentMenuComponent';
import PlayerMenuComponent from '../Common/PlayerMenuComponent/PlayerMenuComponent';

class CollegeCoachNavigationComponent extends React.Component<Props> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    /*** METHODS ***/

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    /*** COMPONENTS ***/

    renderPlayerMenu = (): React.ReactNode | undefined => {
        const { collapsed, playerEditable } = this.props;
        const userId = this.props.userId;
        const sportId = this.props.sportId;
        const familyId = this.props.familyId;
        const organizationIds = this.props.organizationIds || [];

        if (userId && sportId) {
            return (
                <PlayerMenuComponent
                    userId={userId}
                    sportId={sportId}
                    familyId={familyId}
                    organizationIds={organizationIds}
                    divider={true}
                    editable={playerEditable}
                    collapsed={collapsed}
                />
            );
        }
    };

    renderParentMenu = (): React.ReactNode | undefined => {
        const { collapsed, userId, sportId } = this.props;
        if (userId && userId !== this.context.auth?.id && !sportId) {
            return <ParentMenuComponent collapsed={collapsed} userId={userId} />;
        }
    };

    render() {
        const { menu, collapsed } = this.props;

        if (menu) {
            return menu;
        } else {
            return (
                <>
                    {this.renderPlayerMenu()}
                    {this.renderParentMenu()}
                    <Menu.ItemGroup
                        key="organization-dashboard"
                        title={!collapsed && <FormattedMessage id="navigation.organization.dashboard" />}
                    >
                        <Menu.Item
                            key="college-coach-profile"
                            icon={<Icon component={playerProfileSvg} />}
                            onClick={() => this.navigate('/')}
                        >
                            <Link to={`/`}>
                                <FormattedMessage id="navigation.organization.dashboard.profile" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="rosters"
                            icon={<Icon component={rostersSvg} />}
                            onClick={() => this.navigate('/organizations')}
                        >
                            <Link to="/organizations">
                                <FormattedMessage id="navigation.admin.dashboard.affiliates" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="events"
                            icon={<Icon component={eventsSvg} />}
                            onClick={() => this.navigate('/events')}
                        >
                            <Link to="/events">
                                <FormattedMessage id="navigation.organization.dashboard.events" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="leaderboard"
                            icon={<Icon component={leaderboardSvg} />}
                            onClick={() => this.navigate('/leaderboard')}
                        >
                            <Link to="/leaderboard">
                                <FormattedMessage id="navigation.organization.dashboard.leaderboard" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="colleges-public"
                            icon={<Icon component={collegeSvg} />}
                            onClick={() => this.navigate('/colleges')}
                        >
                            <Link to="/colleges">
                                <FormattedMessage id="navigation.organization.dashboard.needFeed" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="prospects"
                            icon={<Icon component={collegeSvg} />}
                            onClick={() => this.navigate('/prospects')}
                        >
                            <Link to="/prospects">
                                <FormattedMessage id="navigation.admin.dashboard.prospects" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </>
            );
        }
    }
}
export default injectIntl(withRouter(CollegeCoachNavigationComponent));

interface Props extends WrappedComponentProps, RouteComponentProps {
    menu?: React.ReactNode;
    collapsed: boolean;
    playerEditable?: boolean;
    userId?: string;
    sportId?: number;
    familyId?: number;
    organizationIds?: string[];
}
