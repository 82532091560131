import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import collegeCoachApi from '../../../apis/CollegeCoachApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../../context/CustomContext';
import { CollegeCoach } from '../../../model/Entities';
import HeadMetadata from '../../../services/HeadMetadata';
import notificationService from '../../../services/NotificationService';
import CollegeCoachAccountEditComponent from './CollegeCoachAccountEditComponent/CollegeCoachAccountEditComponent';
import styles from './CollegeCoachEditPage.module.scss';

class CollegeCoachEditPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    /**
     * Initializes the edit page.
     */
    init = async () => {
        try {
            this.setState({ loading: 'loading' });
            const collegeCoach = await collegeCoachApi.get(
                this.context.auth?.colleges?.find(Boolean)?.id!,
                this.props.match.params.id,
            );

            this.setState({ collegeCoach });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /**
     * Refreshes the user account.
     * @param collegeCoach the user account
     */
    refreshCollegeCoach = (collegeCoach: CollegeCoach) => {
        this.setState({ collegeCoach });
    };

    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        return (
            <div className={styles.header}>
                <h1>
                    <FormattedMessage id="parent.edit.title" />
                </h1>
            </div>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { collegeCoach } = this.state;
        const userId = this.props.match.params.id;

        return (
            collegeCoach && (
                <CollegeCoachAccountEditComponent
                    userId={this.props.match.params.id}
                    onSave={this.refreshCollegeCoach}
                    backUrl={`/college-coaches/${userId}`}
                    collegeCoach={collegeCoach}
                />
            )
        );
    };

    render() {
        const { loading, collegeCoach } = this.state;
        const fullName =
            collegeCoach &&
            `${collegeCoach?.givenName} ${collegeCoach?.familyName} - ${this.props.intl.formatMessage({
                id: 'collegeCoachEdit',
            })}`;

        return (
            <>
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="college-coach-profile"
                    content={this.renderContent}
                    header={this.renderHeader}
                    loading={loading === 'loading'}
                    userId={this.props.match.params.id}
                />
            </>
        );
    }
}
export default injectIntl(CollegeCoachEditPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    collegeCoach?: CollegeCoach;
    loading?: 'loading';
}
