import { message } from 'antd';
import { IntlShape } from 'react-intl';

class PaymentService {
    // method for handling QuickBooks api errors
    displayQBError = (error: any, intl: IntlShape) => {
        const errorMessage = error.response.data.errors.find(Boolean);
        if (errorMessage.code) {
            if (errorMessage.code.startsWith('PMT-4') && errorMessage.detail === 'card.number') {
                message.error(intl.formatMessage({ id: 'signupPayment.status.cardNumber' }));
            } else if (errorMessage.code.startsWith('PMT-4') && errorMessage.detail === 'card.cvc') {
                message.error(intl.formatMessage({ id: 'signupPayment.status.cardCvc' }));
            } else if (
                errorMessage.code.startsWith('PMT-4') &&
                errorMessage.detail === 'card.ExpirationMonth/ExpirationYear'
            ) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.cardExpDate' }));
            } else if (errorMessage.code.startsWith('PMT-1')) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.1000generic' }));
            } else if (errorMessage.code.startsWith('PMT-2')) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.2000generic' }));
            } else if (errorMessage.code.startsWith('PMT-3')) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.3000generic' }));
            } else if (errorMessage.code.startsWith('PMT-4')) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.4000generic' }));
            } else if (errorMessage.code.startsWith('PMT-6')) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.6000generic' }));
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }));
            }
        } else {
            message.error(intl.formatMessage({ id: 'status.internalError' }));
        }
    };

    // method for handling payment api errors
    displayError = (error: any, intl: IntlShape) => {
        const errorMessage = error.response.data;
        if (errorMessage.code) {
            if (
                errorMessage.code.startsWith('PMT-5') ||
                errorMessage.code.startsWith('PMT-6') ||
                errorMessage.code === 'DECLINED'
            ) {
                message.error(errorMessage.message);
            } else if (errorMessage.code.startsWith('PMT-4') && errorMessage.message === 'card.number is invalid.') {
                message.error(intl.formatMessage({ id: 'signupPayment.status.cardNumber' }));
            } else if (
                errorMessage.code.startsWith('PMT-4') &&
                errorMessage.message === 'Card security code (CVV/CVC) verification failed.'
            ) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.cardCvc' }));
            } else if (errorMessage.code.startsWith('PMT-1')) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.1000generic' }));
            } else if (errorMessage.code.startsWith('PMT-2')) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.2000generic' }));
            } else if (errorMessage.code.startsWith('PMT-3')) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.3000generic' }));
            } else if (errorMessage.code.startsWith('PMT-4')) {
                message.error(intl.formatMessage({ id: 'signupPayment.status.4000generic' }));
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }));
            }
        } else {
            message.error(intl.formatMessage({ id: 'status.internalError' }));
        }
    };
}

export interface ErrorMessage {
    code?: string;
    type?: string;
    message?: string;
    detail?: string;
    infoLink?: string;
}

const paymentService = new PaymentService();
export default paymentService;
