class NumericService {
    sort = (a: number | undefined, b: number | undefined): number => {
        const numberA = a || 0;
        const numberB = b || 0;
        return numberB - numberA;
    };

    zeroPad = (num: number | undefined, places: number | undefined): string => {
        const numAux = num || 0;
        const placesAux = places || 0;
        return String(numAux).padStart(placesAux, '0');
    };

    zeroRemove = (num: string | undefined): string => {
        const numAux = num || '0.000';

        return String(numAux).replace(/^0+/, '');
    };
}

const numericService: NumericService = new NumericService();
export default numericService;
