import Icon from '@ant-design/icons';
import { Divider, Menu } from 'antd';
import { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { ReactComponent as dashboardSvg } from '../../resources/images/ico-dashboard.svg';
import { ReactComponent as eventsSvg } from '../../resources/images/ico-events.svg';
import { ReactComponent as eventsLiveSvg } from '../../resources/images/ico-eventsLive.svg';
import { ReactComponent as leaderboardSvg } from '../../resources/images/ico-leaderboard.svg';
import { ReactComponent as eventsCompletedSvg } from '../../resources/images/ico-tasks.svg';
import styles from './EventsNavigationComponent.module.scss';

class EventsNavigationComponent extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    /** METHODS **/

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    /*** COMPONENTS ***/

    render() {
        const { collapsed } = this.props;
        return (
            <>
                <Menu.ItemGroup>
                    <Menu.Item
                        key="events-dashboard"
                        icon={<Icon component={dashboardSvg} />}
                        onClick={() => this.props.history.push('/')}
                    >
                        <Link to="/">
                            <FormattedMessage id="navigation.admin.dashboard" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
                <Divider className="divider" />

                {!this.context.auth && (
                    <>
                        <Menu.ItemGroup>
                            <Menu.Item key="leaderboard" icon={<Icon component={leaderboardSvg} />}>
                                <Link to="/leaderboard">
                                    <FormattedMessage id="navigation.admin.dashboard.leaderboard" />
                                </Link>
                            </Menu.Item>
                        </Menu.ItemGroup>
                        <Divider className="divider" />
                    </>
                )}

                <Menu.ItemGroup key="events-menu" title={!collapsed && <FormattedMessage id="events.registration" />}>
                    <Menu.Item
                        key={`events-upcoming`}
                        icon={<Icon component={eventsSvg} />}
                        onClick={() => this.navigate('/events')}
                    >
                        <Link to={`/events`}>
                            <FormattedMessage id="events.events" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={`events-completed`}
                        className={styles.ico24}
                        icon={<Icon component={eventsCompletedSvg} />}
                        onClick={() => this.navigate('/events/completed')}
                    >
                        <Link to={`/events/completed`}>
                            <FormattedMessage id="events.completed" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key={`events-live`}
                        className={styles.ico24}
                        icon={<Icon component={eventsLiveSvg} />}
                        onClick={() => this.navigate('/events/live')}
                    >
                        <Link to={`/events/live`}>
                            <FormattedMessage id="events.live" />
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </>
        );
    }
}
export default injectIntl(withRouter(EventsNavigationComponent));

interface Props extends RouteComponentProps, WrappedComponentProps {
    collapsed?: boolean;
}
