import Icon from '@ant-design/icons';
import { Button, Form, FormInstance, Image, Modal, Select, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import positionApi from '../../../../../apis/PositionApi';
import teamMemberApi from '../../../../../apis/TeamMemberApi';
import CustomContext from '../../../../../context/CustomContext';
import { Position, Team, TeamMember, TeamMemberFromTeam } from '../../../../../model/Entities';
import { ReactComponent as removeSvg } from '../../../../../resources/images/ico-thrash.svg';
import avatar from '../../../../../resources/images/profile-placeholder.png';
import notificationService from '../../../../../services/NotificationService';
import styles from './TeamMemberModal.module.scss';

class TeamMemberModal extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = {
            positions: [],
        };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        const { team, organizationId, teamMemberFromTeam } = this.props;

        try {
            this.setState({ loading: 'loading' });
            const positions = await positionApi.list(team.sportId!);

            const teamMember = await teamMemberApi.get(organizationId, team.id!, teamMemberFromTeam.id!);
            const photo = teamMember.headshotUrl;
            this.setState({ positions, photo });

            this.formRef.current!.setFieldsValue(teamMember);
            this.setState({ teamMember });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    cancel = async () => {
        this.props.onCancel();
    };

    save = async () => {
        const { file, teamMember } = this.state;
        try {
            this.setState({ loading: 'saving' });

            // upload photo
            if (file) {
                await teamMemberApi.updatePhoto(teamMember?.userId!, teamMember?.id!, file as File);
            }

            // save team member
            const values = await this.formRef.current?.validateFields();
            const updatedTeamMember: TeamMember = Object.assign({}, teamMember, values);
            await teamMemberApi.patch(this.props.team.organizationId!, this.props.team.id!, updatedTeamMember);

            await this.props.onUpdate();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    uploadPhoto = (file: UploadFile) => {
        this.getBase64(file, (photo: string) =>
            this.setState({
                photo,
                file,
            }),
        );
        return false;
    };

    getBase64 = (img: any, callback: any) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    deleteTeamMember = async (teamMember: TeamMember) => {
        const { team, organizationId } = this.props;
        try {
            this.setState({ loading: 'deleting' });
            await teamMemberApi.delete(teamMember.id!, organizationId, team.id!);
            this.props.onUpdate();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    /*** COMPONENTS ***/

    render() {
        const { teams, desktop } = this.props;
        const { positions, photo, loading, teamMember } = this.state;
        const positionOptions = positions.map((position) => (
            <Select.Option key={position.id} value={position.id!}>
                {position.code} - {position.name}
            </Select.Option>
        ));
        const teamOptions = teams.map((team) => (
            <Select.Option key={team.id} value={team.id!}>
                {team.name}
            </Select.Option>
        ));

        const jerseyNumbers = Array.from({ length: 100 }, (x, i) => i);

        const jerseyNumbersOptions = jerseyNumbers.map((jn) => (
            <Select.Option key={jn} value={jn}>
                {jn}
            </Select.Option>
        ));

        return (
            <Modal
                visible={true}
                onCancel={this.cancel}
                className={styles.modal}
                width={650}
                footer={[
                    <div className={styles.footer}>
                        <Button
                            type="text"
                            icon={<Icon component={removeSvg} />}
                            className={styles.remove}
                            loading={loading === 'deleting'}
                            onClick={() => this.deleteTeamMember(teamMember!)}
                        >
                            {desktop ? <FormattedMessage id="admin.rosters.roster.remove" /> : ''}
                        </Button>
                        <div className={styles.actionButtons}>
                            <Button shape={'round'} onClick={this.cancel}>
                                <FormattedMessage id="admin.rosters.roster.cancel" />
                            </Button>
                            <Button shape={'round'} type="primary" onClick={this.save} loading={loading === 'saving'}>
                                <FormattedMessage id="admin.rosters.roster.save" tagName="span" />
                            </Button>
                        </div>
                    </div>,
                ]}
            >
                <Form ref={this.formRef} colon={false} layout="vertical">
                    <div className={styles.player}>
                        {desktop && (
                            <div className={styles.photo}>
                                <Form.Item name="currentPhoto" className={styles.image}>
                                    <Image src={photo || avatar} fallback={avatar} preview={false} />
                                </Form.Item>
                                <Upload.Dragger
                                    beforeUpload={this.uploadPhoto}
                                    showUploadList={false}
                                    className={styles.upload}
                                >
                                    <FormattedMessage id="admin.rosters.roster.updatePhoto" />
                                </Upload.Dragger>
                            </div>
                        )}
                        <div className={styles.edit}>
                            <div>
                                <h1>
                                    {teamMember?.profile?.givenName} {teamMember?.profile?.familyName}
                                </h1>
                            </div>
                            <Form.Item
                                className={styles.insideLabel}
                                name="jerseyNumber"
                                label={<FormattedMessage id="admin.rosters.roster.playerNumber" />}
                            >
                                <Select mode="multiple">{jerseyNumbersOptions}</Select>
                            </Form.Item>
                            <Form.Item
                                className={styles.insideLabel}
                                name="positions"
                                label={<FormattedMessage id="admin.rosters.roster.playerPosition" />}
                            >
                                <Select className={styles.multipleSelect} mode="multiple">
                                    {positionOptions}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                className={styles.insideLabel}
                                name="teamId"
                                label={<FormattedMessage id="admin.rosters.roster.playerTeam" />}
                            >
                                <Select className={styles.select}>{teamOptions}</Select>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
        );
    }
}
export default injectIntl(TeamMemberModal);

interface Props extends WrappedComponentProps {
    team: Team;
    organizationId: string;
    teamMemberFromTeam: TeamMemberFromTeam;
    teams: Team[];
    onUpdate: () => Promise<void>;
    onCancel: () => void;
    desktop: boolean;
}

interface State {
    loading?: 'loading' | 'saving' | 'deleting';
    teamMember?: TeamMember;
    photo?: string;
    file?: any;
    positions: Position[];
}
