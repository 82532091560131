import Icon from '@ant-design/icons';
import { Button, Divider, Menu, Table, Tag } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import invoiceApi from '../../apis/InvoiceApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../context/CustomContext';
import { Page } from '../../model/Elements';
import { Invoice } from '../../model/Entities';
import { PaymentStatusType } from '../../model/Types';
import { ReactComponent as dashboardSvg } from '../../resources/images/ico-dashboard.svg';
import { ReactComponent as usersGroupsSvg } from '../../resources/images/ico-grid.svg';
import { ReactComponent as invoicesSvg } from '../../resources/images/ico-list.svg';
import { ReactComponent as playerProfileSvg } from '../../resources/images/ico-playerProfile.svg';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import rolesService from '../../services/RolesService';
import tableService from '../../services/TableService';
import styles from './InvoicesPage.module.scss';

class InvoicesPage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: true });

            const invoicesPage = await invoiceApi.list(this.props.match.params.id, tableService.pageSize, 1);

            this.setState({ invoicesPage });
        } catch (error: any) {
            if (error.response.status === 409) {
            } else {
                notificationService.displayError(error, this.props.intl);
            }
        } finally {
            this.setState({ loading: false });
        }
    };

    list = async (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Invoice> | SorterResult<Invoice>[],
    ) => {
        try {
            this.setState({ loading: true });

            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const invoicesPage = await invoiceApi.list(this.props.match.params.id, pageSize, page);

            this.setState({ invoicesPage });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: false });
        }
    };

    navigate = (path: string) => {
        this.props.history.push(path);
    };

    /*** COMPONENTS ***/

    renderHeader = (): React.ReactElement | undefined => {
        return (
            <div className={styles.toolbar}>
                <h1>
                    <FormattedMessage id="invoices.title" />
                </h1>
                <p>
                    <FormattedMessage id="invoices.subtitle" />
                </p>
            </div>
        );
    };

    renderMenu = (collapsed: boolean): React.ReactNode | undefined => {
        const { auth } = this.context;
        const organizationId = this.props.match.params.id;

        if (rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
            return (
                <>
                    <Menu.ItemGroup>
                        <Menu.Item
                            key="organizations-dashboard"
                            icon={<Icon component={dashboardSvg} />}
                            onClick={() => this.navigate('/organizations')}
                        >
                            <Link to="/organizations">
                                <FormattedMessage id="navigation.admin.dashboard" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Divider className="divider" />

                    <Menu.ItemGroup
                        key="organization-menu"
                        title={!collapsed && <FormattedMessage id="navigation.organization.dashboard.organization" />}
                    >
                        <Menu.Item
                            key="organization"
                            icon={<Icon component={playerProfileSvg} />}
                            onClick={() => this.navigate(`/organizations/${organizationId}`)}
                        >
                            <Link to={`/organizations/${organizationId}`}>
                                <FormattedMessage id="navigation.organization.dashboard.profile" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="groups"
                            icon={<Icon component={usersGroupsSvg} />}
                            onClick={() => this.navigate(`/groups/affiliates/${organizationId}`)}
                        >
                            <Link to={`/groups/affiliates/${organizationId}`}>
                                <FormattedMessage id="navigation.admin.dashboard.groups" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="invoices"
                            icon={<Icon component={invoicesSvg} />}
                            onClick={() => this.navigate(`/invoices/${organizationId}`)}
                        >
                            <Link to={`/invoices/${organizationId}`}>
                                <FormattedMessage id="navigation.admin.dashboard.invoices" />
                            </Link>
                        </Menu.Item>
                    </Menu.ItemGroup>
                </>
            );
        }
    };

    renderStatus = (status: PaymentStatusType): React.ReactElement | undefined => {
        if (status === 'Created' || status === 'Unpaid') {
            return (
                <Tag color="red" className={styles.tagRed}>
                    <FormattedMessage id="invoices.unpaid" />
                </Tag>
            );
        } else if (status === 'Overdue') {
            return (
                <Tag color="orange" className={styles.tagOrange}>
                    <FormattedMessage id="invoices.overdue" />
                </Tag>
            );
        } else if (status === 'OverduePartiallyPaid') {
            return (
                <Tag color="orange" className={styles.tagOrange}>
                    <FormattedMessage id="invoices.overduePartiallyPaid" />
                </Tag>
            );
        } else if (status === 'PartiallyPaid') {
            return (
                <Tag color="yellow" className={styles.tagYellow}>
                    <FormattedMessage id="invoices.partiallyPaid" />
                </Tag>
            );
        } else if (status === 'Paid') {
            return (
                <Tag color="green" className={styles.tagGreen}>
                    <FormattedMessage id="invoices.paid" />
                </Tag>
            );
        }
    };

    renderContent = (): React.ReactElement | undefined => {
        const { invoicesPage, loading } = this.state;
        const items = invoicesPage ? invoicesPage.items : [];
        const columns: ColumnsType<Invoice> = [
            {
                title: <FormattedMessage id="invoices.invoice" />,
                dataIndex: 'docNumber',
                key: 'docNumber',
                defaultSortOrder: 'ascend',
                render: (value: string, invoice: Invoice) => [value, ' ', invoice.type],
            },
            {
                title: <FormattedMessage id="invoices.date" />,
                dataIndex: 'date',
                key: 'date',
                align: 'center',
                width: 120,
                render: (value: number, invoice: Invoice) => <FormattedDate value={value} format="MM/DD/YYYY" />,
            },
            {
                title: <FormattedMessage id="invoices.dueDate" />,
                dataIndex: 'dueDate',
                key: 'dueDate',
                align: 'center',
                width: 120,
                render: (value: number, invoice: Invoice) => <FormattedDate value={value} format="MM/DD/YYYY" />,
            },
            {
                title: <FormattedMessage id="invoices.amount" />,
                dataIndex: 'amount',
                key: 'amount',
                align: 'right',
                width: 90,
                render: (value: number, invoice: Invoice) => {
                    const amount = value ? value : 0;
                    return (
                        <>
                            $<FormattedNumber value={amount} minimumFractionDigits={2} maximumFractionDigits={2} />
                        </>
                    );
                },
            },
            {
                title: <FormattedMessage id="invoices.status" />,
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: 190,
                render: (value: PaymentStatusType, invoice: Invoice) => this.renderStatus(value),
            },
            {
                title: <FormattedMessage id="invoices.dueAmount" />,
                dataIndex: 'dueAmount',
                key: 'dueAmount',
                align: 'right',
                width: 120,
                render: (value: number, invoice: Invoice) => {
                    const amount = value ? value : 0;
                    return (
                        <>
                            $<FormattedNumber value={amount} minimumFractionDigits={2} maximumFractionDigits={2} />
                        </>
                    );
                },
            },
            {
                title: <FormattedMessage id="invoices.paymentOption" />,
                dataIndex: 'paymentLink',
                key: 'paymentLink',
                width: 180,
                align: 'center',
                render: (value: string, invoice: Invoice) => (
                    <a href={value} target="_blank" rel="noreferrer">
                        <Button type="primary" hidden={invoice.status === 'Paid'}>
                            <FormattedMessage id="invoices.payNow" />
                        </Button>
                    </a>
                ),
            },
            {
                title: <FormattedMessage id="invoices.actions" />,
                width: 180,
                align: 'center',
                render: (value: string, invoice: Invoice) => (
                    <a href={invoice.paymentLink} target="_blank" rel="noreferrer">
                        <FormattedMessage id="invoices.viewDetails" />
                    </a>
                ),
            },
        ];

        return (
            <>
                <div className={styles.mobHeader}>
                    <h1>
                        <FormattedMessage id="invoices.title" />
                    </h1>
                    <p>
                        <FormattedMessage id="invoices.subtitle" />
                    </p>
                </div>
                <Table
                    dataSource={items}
                    columns={columns}
                    pagination={tableService.createPagination(invoicesPage)}
                    rowKey="id"
                    onChange={this.list}
                    sortDirections={['ascend', 'descend']}
                    showSorterTooltip={false}
                    loading={loading}
                    className={styles.table}
                />
            </>
        );
    };

    render() {
        return (
            <>
                <HeadMetadata titleKey="invoices.meta.title" />
                <LayoutComponent
                    page="invoices"
                    header={this.renderHeader}
                    menu={this.renderMenu}
                    content={this.renderContent}
                />
            </>
        );
    }
}
export default injectIntl(InvoicesPage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    invoicesPage?: Page<Invoice>;
    loading?: boolean;
    organizationId?: string;
}
