import { Page } from '../model/Elements';
import { Group } from '../model/Entities';
import { UserStatusType, RoleType } from '../model/Types';
import apiAxios from './CustomAxios';

class GroupApi {
    list = async (
        limit?: number,
        page?: number,
        sortField?: string,
        sortOrder?: string,
        search?: string,
        userStatus?: UserStatusType,
        role?: RoleType,
        organizationId?: string,
    ): Promise<Page<Group>> => {
        const order = sortOrder === 'descend' ? 'Desc' : 'Asc';
        const sortFieldName = this.getSortField(sortField);
        const filters = this.getFilters(userStatus, role, organizationId);
        const response = await apiAxios.get<Page<Group>>('/v1/usergroups', {
            params: { limit, page, [sortFieldName]: order, ...filters, search },
        });

        // format dates
        response.data.items.forEach((i) => {
            if (i.profile && i.profile.lastLogin) {
                i.profile.lastLogin = i.profile.lastLogin * 1000;
            }
        });

        return response.data;
    };

    private getSortField(sortField?: string): string {
        let field: string;
        if (sortField === 'familyName') {
            field = 'sorting.FamilyName';
        } else if (sortField === 'roles') {
            field = 'sorting.Role';
        } else if (sortField === 'lastLogin') {
            field = 'sorting.LastLogin';
        } else if (sortField === 'userStatus') {
            field = 'sorting.UserStatus';
        } else {
            field = 'sorting.GivenName';
        }

        return field;
    }

    private getFilters(userStatus?: UserStatusType, role?: RoleType, organizationId?: string) {
        let fields: any = {};

        if (userStatus) {
            fields['filtering.UserStatus.Value'] = userStatus;
            fields['filtering.UserStatus.Operation'] = 'Equal';
        }
        if (role) {
            fields['filtering.Role'] = role;
        }
        if (organizationId) {
            fields['filtering.OrganizationId'] = organizationId;
        }

        return fields;
    }
}

const groupApi: GroupApi = new GroupApi();
export default groupApi;
