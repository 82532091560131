import { Page } from '../model/Elements';
import { Record } from '../model/Entities';
import apiAxios from './CustomAxios';

class RecordApi {
    list = async (userId: string, sportId: number, seasonId?: number): Promise<Record[]> => {
        const response = await apiAxios.get<Page<Record>>(`/v1/users/${userId}/sports/${sportId}/records`, {
            params: { seasonId },
        });

        return response.data.items;
    };
}

const recordApi: RecordApi = new RecordApi();
export default recordApi;
