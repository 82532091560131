import { EditOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Dropdown, Image, Menu, Row, Select, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, RouteComponentProps } from 'react-router-dom';
import familyApi from '../../apis/FamilyApi';
import organizationProfileApi from '../../apis/OrganizationProfileApi';
import sportApi from '../../apis/SportApi';
import userApi from '../../apis/UserApi';
import AddUserModal from '../../components/AddUserModal/AddUserModal';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import OrganizationComponent from '../../components/OrganizationComponent/OrganizationComponent';
import UserAccountComponent from '../../components/UserAccountComponent/UserAccountComponent';
import UserBackgroundComponent from '../../components/UserBackgroundComponent/UserBackgroundComponent';
import CustomContext from '../../context/CustomContext';
import {
    Family,
    OrganizationProfile,
    OrganizationProfileEvent,
    OrganizationProfileSport,
    OrganizationProfileSportBest,
    OrganizationSport,
    Sport,
    UserAccount,
} from '../../model/Entities';
import avatar from '../../resources/images/profile-placeholder.png';
import logo from '../../resources/images/top100-logo.png';
import HeadMetadata from '../../services/HeadMetadata';
import notificationService from '../../services/NotificationService';
import rolesService from '../../services/RolesService';
import OrganizationSportModal from '../Backend/AdminOrganizationsPage/AdminOrganizationPage/OrganizationSportsComponent/OrganizationSportModal/OrganizationSportModal';
import styles from './AffiliateCoachProfilePage.module.scss';

class AffiliateCoachProfilePage extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = { sports: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/

    init = async () => {
        try {
            this.setState({ loading: 'initializing' });

            const params = new URLSearchParams(this.props.location.search);
            const organizationId = params.get('organizationId')
                ? params.get('organizationId')!
                : this.context.auth?.organizationId;

            // get coach profile
            const organizationProfile = await organizationProfileApi.get(organizationId!);
            const pageDisplayed = 'affiliateProfile';

            // get parent and family
            const responses = await Promise.all([userApi.getAccount(this.props.match.params.id), sportApi.listAll()]);
            const user = responses[0];
            const sports = responses[1];
            const family = await familyApi.get(user.family?.id!);

            this.setState({ user, family, sports, organizationProfile, pageDisplayed, organizationId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeAddUserModalVisible = (addUserModalVisible: boolean, userType?: string) => {
        this.setState({ addUserModalVisible, userType });
    };

    refresh = async () => {
        const { family } = this.state;
        try {
            this.setState({ loading: 'loading' });

            // get family
            const familyUpdated = await familyApi.get(family?.id!);
            this.changeAddUserModalVisible(false);

            this.setState({ family: familyUpdated });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    updateOrganizationProfile = async () => {
        const { organizationId } = this.state;
        try {
            this.setState({ loading: 'loading' });

            // update organization profile
            const organizationProfile = await organizationProfileApi.get(organizationId!);
            this.showAddSportModal(false);

            this.setState({ organizationProfile });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changePageDisplayed = (pageDisplayed?: 'affiliateProfile' | 'affiliateEdit') => {
        this.updateOrganizationProfile();
        const pageDisplayedAux = pageDisplayed ? pageDisplayed : 'affiliateProfile';
        this.setState({ pageDisplayed: pageDisplayedAux });
    };

    showAddSportModal = (addSportModalVisible: boolean, organizationSport?: OrganizationSport) => {
        this.setState({ addSportModalVisible, organizationSport });
    };
    /*** COMPONENTS ***/

    renderHeader = (desktop: boolean): React.ReactElement | undefined => {
        const { user, organizationId, sports } = this.state;
        if (desktop) {
            return (
                <div className={styles.header}>
                    <UserBackgroundComponent sports={sports} />

                    <div className={styles.buttons}>
                        <div className={styles.avatar}>
                            <Avatar src={user?.photoUrl || avatar} className={styles.image} />
                            <span className={styles.parentName}>
                                {user?.givenName} {user?.familyName}
                            </span>
                        </div>

                        {(this.context.auth?.authorities.includes('ROLE_ADMIN') ||
                            this.context.auth?.id === this.props.match.params.id) && (
                            <Link
                                to={`/affiliate-coaches/${this.props.match.params.id}/edit?organizationId=${organizationId}`}
                            >
                                <Button icon={<EditOutlined />}>
                                    <FormattedMessage id="parent.profile.editProfile" tagName="span" />
                                </Button>
                            </Link>
                        )}
                        {
                            <Select
                                size="large"
                                className={styles.addUser}
                                placeholder={<FormattedMessage id="button.add" />}
                                onSelect={(value: string) => this.changeAddUserModalVisible(true, value)}
                            >
                                <Select.Option value="parent">
                                    <FormattedMessage id="parent.profile.addParent" />
                                </Select.Option>
                                <Select.Option value="player">
                                    <FormattedMessage id="parent.profile.addPlayer" />
                                </Select.Option>
                            </Select>
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div className={`${styles.header} ${styles.mobile}`}>
                    <Dropdown
                        overlay={
                            <Menu>
                                {(this.context.auth?.authorities.includes('ROLE_ADMIN') ||
                                    this.context.auth?.id === this.props.match.params.id) && (
                                    <Menu.Item key="edit">
                                        <Link
                                            to={`/affiliate-coaches/${this.props.match.params.id}/edit?organizationId=${organizationId}`}
                                        >
                                            <FormattedMessage id="parent.profile.editProfile" tagName="span" />
                                        </Link>
                                    </Menu.Item>
                                )}
                                <Menu.Item key="parent" onClick={() => this.changeAddUserModalVisible(true, 'parent')}>
                                    <FormattedMessage id="parent.profile.addParent" />
                                </Menu.Item>
                                <Menu.Item key="child" onClick={() => this.changeAddUserModalVisible(true, 'player')}>
                                    <FormattedMessage id="parent.profile.addPlayer" />
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button className={styles.actions}>
                            <Avatar src={user?.photoUrl || avatar} className={styles.image} />
                        </Button>
                    </Dropdown>
                </div>
            );
        }
    };

    renderAffiliateInfo = (): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { organizationProfile } = this.state;

        const numberOfSports =
            organizationProfile && organizationProfile.sports ? organizationProfile.sports.length : 0;

        const numberOfEvents =
            organizationProfile && organizationProfile.events
                ? organizationProfile.events.filter((e) => e.status !== 'Completed').length
                : 0;

        return (
            <>
                <Row gutter={[18, 0]} className={styles.cards}>
                    <Col>
                        <Card className={styles.card}>
                            <div className={styles.logo}>
                                <div className={styles.gradient}></div>
                                <Image src={organizationProfile?.logo} fallback={logo} />
                            </div>
                            <Card.Meta
                                title={
                                    <>
                                        <div>
                                            <span>{organizationProfile?.uniquePlayersCount || 0}</span>{' '}
                                            <FormattedMessage id="affiliateOwner.profile.players" />
                                        </div>
                                        <div>
                                            {numberOfSports} <FormattedMessage id="affiliateOwner.profile.sports" />
                                        </div>
                                    </>
                                }
                                description={
                                    <Link to={`/groups/affiliates/${organizationProfile?.id}`}>
                                        <Button className={styles.button}>
                                            <span className={styles.des}>
                                                <FormattedMessage id="affiliateOwner.profile.managePeople" />
                                            </span>
                                            <span className={styles.mob}>
                                                <FormattedMessage id="affiliateOwner.profile.manage" />
                                            </span>
                                        </Button>
                                    </Link>
                                }
                            />
                        </Card>
                    </Col>
                    <Col>
                        <Card className={styles.card}>
                            <div className={styles.logo}>
                                <div className={styles.gradient}></div>
                                <Image src={organizationProfile?.logo} fallback={logo} />
                            </div>
                            <Card.Meta
                                title={
                                    <>
                                        <div>
                                            <span>{numberOfEvents}</span>{' '}
                                            <FormattedMessage id="affiliateOwner.profile.upcomingEvents" />
                                        </div>
                                    </>
                                }
                                description={
                                    <Button className={styles.button} hidden>
                                        <span className={styles.des}>
                                            <FormattedMessage id="affiliateOwner.profile.requestEvent" />
                                        </span>
                                        <span className={styles.mob}>
                                            <FormattedMessage id="affiliateOwner.profile.request" />
                                        </span>
                                    </Button>
                                }
                            />
                        </Card>
                    </Col>
                    <Col>
                        <Card className={styles.card}>
                            <div className={styles.logo}>
                                <div className={styles.gradient}></div>
                                <Image src={organizationProfile?.logo} fallback={logo} />
                            </div>
                            <Card.Meta
                                title={
                                    <>
                                        <div>
                                            <span>{organizationProfile?.playersWithNationalRank}</span>{' '}
                                            <FormattedMessage id="affiliateOwner.profile.playersRank" />
                                        </div>
                                    </>
                                }
                                description={
                                    <Link to={`/leaderboard?organizationId=${this.context.auth?.organizationId}`}>
                                        <Button className={styles.button}>
                                            <FormattedMessage id="affiliateOwner.profile.leaderboard" />
                                        </Button>
                                    </Link>
                                }
                            />
                        </Card>
                    </Col>
                    <Col>
                        <h2>
                            <FormattedMessage id="affiliateOwner.profile.affiliateInfo" />
                            <Button
                                type="text"
                                icon={<EditOutlined />}
                                onClick={() => this.changePageDisplayed('affiliateEdit')}
                                hidden={!rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_ORGANIZATION_OWNER'])}
                            ></Button>
                        </h2>
                        <h3>
                            <FormattedMessage id="affiliateOwner.profile.name" />
                        </h3>
                        <p>{organizationProfile?.name}</p>
                        <Image src={organizationProfile?.logo} fallback={logo} />
                    </Col>
                </Row>
            </>
        );
    };

    renderEventsTable = (): React.ReactElement | undefined => {
        const { organizationProfile, loading } = this.state;
        const events = organizationProfile && organizationProfile.events ? organizationProfile.events : [];

        const columns: ColumnsType<OrganizationProfileEvent> = [
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventName" />,
                dataIndex: 'name',
                key: 'name',
                width: 450,
                render: (value: string, event: OrganizationProfileEvent) => (
                    <Link to={`/events/${event.id}/summary`}>{value}</Link>
                ),
            },
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventDate" />,
                dataIndex: 'date',
                key: 'date',
                width: 200,
                render: (value: number, event: OrganizationProfileEvent) => (
                    <FormattedDate value={value} dateStyle="medium" />
                ),
            },
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventPlayersRegistered" />,
                dataIndex: 'registrations',
                key: 'registrations',
                width: 100,
                render: (value: number, event: OrganizationProfileEvent) => value,
            },
            {
                title: <FormattedMessage id="affiliateOwner.profile.eventStatus" />,
                dataIndex: 'status',
                key: 'status',
                width: 130,
                render: (value: string, event: OrganizationProfileEvent) => value,
            },
        ];

        return (
            <>
                <div className={styles.players}>
                    <FormattedMessage id="affiliateOwner.profile.recentEvents" />
                </div>
                <Table
                    dataSource={events}
                    columns={columns}
                    pagination={false}
                    rowKey="id"
                    loading={loading === 'initializing'}
                    className={styles.table}
                />
            </>
        );
    };

    renderBestPlayer = (player?: OrganizationProfileSportBest): React.ReactElement | undefined => {
        return (
            <>
                <div className={styles.avatar}>{player && <Avatar src={player.photoUrl || avatar} />}</div>
                <div className={styles.playerRank}>{player?.rank}</div>
                <p>
                    {player?.givenName?.substring(0, 1)}. {player?.familyName}
                </p>
            </>
        );
    };

    renderAffiliateSportName = (
        sportId: number,
        s: OrganizationProfileSport,
        organizationProfile: OrganizationProfile,
    ): React.ReactElement | string | undefined => {
        if (sportId === s.sportId) {
            return (
                <Link to={`/rosters/${organizationProfile.id}?sportId=${s.sportId}`} className={styles.title}>
                    {s.name}
                </Link>
            );
        } else {
            return s.name;
        }
    };

    renderSportsList = (): React.ReactElement | undefined => {
        const { auth } = this.context;
        const { organizationProfile, sports } = this.state;

        const numberOfSports =
            organizationProfile && organizationProfile.sports ? organizationProfile.sports.length : 0;

        const sportId = auth?.coaches && auth?.coaches.find(Boolean)?.sportId;

        return (
            <div className={styles.misc}>
                <div className={styles.players}>
                    <FormattedMessage id="affiliateOwner.profile.sports" />
                    <Button
                        onClick={() => this.showAddSportModal(true)}
                        hidden={
                            sports.length === numberOfSports ||
                            rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_COACH'])
                        }
                    >
                        <FormattedMessage id="affiliateOwner.profile.addSport" />
                    </Button>
                </div>

                {organizationProfile?.sports?.map((s) => {
                    const playerRankOne = s.best ? s.best.find((b) => b.rank === 1) : undefined;
                    const playerRankTwo = s.best ? s.best.find((b) => b.rank === 2) : undefined;
                    const playerRankThree = s.best ? s.best.find((b) => b.rank === 3) : undefined;
                    return (
                        <Card key={s.id} className={styles.card}>
                            <Card.Meta
                                className={styles.sportSection}
                                title={
                                    <div className={styles.sport}>
                                        <div className={styles.affiliate}>
                                            <div className={styles.logo}>
                                                <img src={organizationProfile.logo} alt={logo}></img>
                                            </div>

                                            <div className={styles.sportDetails}>
                                                {sportId &&
                                                    this.renderAffiliateSportName(sportId, s, organizationProfile)}
                                                <div className={styles.sportLogo}>
                                                    <img
                                                        src={sports.find((sport) => sport.id === s.sportId)?.iconUrl}
                                                        alt={logo}
                                                    ></img>
                                                    {sports.find((sport) => sport.id === s.sportId)?.name}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.rank}>
                                            <span>{this.renderBestPlayer(playerRankOne)}</span>
                                            <span>{this.renderBestPlayer(playerRankTwo)}</span>
                                            <span>{this.renderBestPlayer(playerRankThree)}</span>
                                        </div>
                                        <Button
                                            onClick={() => this.showAddSportModal(true, s)}
                                            className={styles.manage}
                                            hidden={rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_COACH'])}
                                        >
                                            <FormattedMessage id="affiliateOwner.profile.manage" />
                                        </Button>
                                    </div>
                                }
                            />
                        </Card>
                    );
                })}
            </div>
        );
    };

    renderOrganizationSportModal = (): React.ReactElement | undefined => {
        const { organizationProfile, addSportModalVisible, sports, organizationSport } = this.state;

        if (addSportModalVisible) {
            return (
                <OrganizationSportModal
                    onUpdate={this.updateOrganizationProfile}
                    onCancel={() => this.showAddSportModal(false)}
                    organizationId={organizationProfile?.id!}
                    sports={sports}
                    organizationSport={organizationSport}
                />
            );
        }
    };

    renderAffiliateProfile = (): React.ReactElement | undefined => {
        return (
            <>
                {this.renderAffiliateInfo()}
                {this.renderEventsTable()}
                {this.renderSportsList()}
                {this.renderOrganizationSportModal()}
            </>
        );
    };

    renderContent = (desktop: boolean): React.ReactElement | undefined => {
        const { addUserModalVisible, userType, family, pageDisplayed, organizationProfile, sports, user } = this.state;

        return (
            <>
                {addUserModalVisible && (
                    <AddUserModal
                        onUpdate={this.refresh}
                        onCancel={() => this.changeAddUserModalVisible(false)}
                        userType={userType}
                        familyId={family?.id!}
                    />
                )}
                <Tabs defaultActiveKey="1" type="card" className={styles.tabs}>
                    <Tabs.TabPane
                        tab={<FormattedMessage id="affiliateOwner.profile.affiliateProfile" />}
                        key="affiliate"
                        className={styles.tabAffiliate}
                        forceRender
                    >
                        {pageDisplayed === 'affiliateProfile' && this.renderAffiliateProfile()}
                        {pageDisplayed === 'affiliateEdit' && (
                            <OrganizationComponent
                                organizationId={organizationProfile?.id!}
                                onBack={this.changePageDisplayed}
                                onSave={this.changePageDisplayed}
                            />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={<FormattedMessage id="affiliateOwner.profile.personalProfile" />}
                        key="personal"
                        className={styles.tabPersonal}
                        forceRender
                    >
                        {user && family && (
                            <UserAccountComponent user={user} sports={sports} family={family} onUpdate={this.refresh} />
                        )}
                    </Tabs.TabPane>
                </Tabs>
            </>
        );
    };

    render() {
        const { loading, user } = this.state;
        const fullName =
            user &&
            `${user?.givenName} ${user?.familyName} - ${this.props.intl.formatMessage({
                id: 'affiliateProfile',
            })}`;

        return (
            <div className="affiliateProfile">
                <HeadMetadata title={fullName} />
                <LayoutComponent
                    page="organization-user-profile"
                    content={this.renderContent}
                    header={this.renderHeader}
                    loading={loading === 'initializing'}
                    userId={this.props.match.params.id}
                />
            </div>
        );
    }
}
export default injectIntl(AffiliateCoachProfilePage);

type ParamsType = { id: string };
interface Props extends RouteComponentProps<ParamsType>, WrappedComponentProps {}

interface State {
    family?: Family;
    loading?: 'initializing' | 'loading';
    sports: Sport[];
    addUserModalVisible?: boolean;
    userType?: string;
    user?: UserAccount;
    organizationProfile?: OrganizationProfile;
    pageDisplayed?: 'affiliateProfile' | 'affiliateEdit';
    addSportModalVisible?: boolean;
    organizationSport?: OrganizationSport;
    organizationId?: string;
}
