import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import divisionApi from '../../../../apis/DivisionApi';
import teamApi from '../../../../apis/TeamApi';
import CustomContext from '../../../../context/CustomContext';
import { Division, Team } from '../../../../model/Entities';
import { ReactComponent as DeleteSvg } from '../../../../resources/images/ico-delete.svg';
import notificationService from '../../../../services/NotificationService';
import rolesService from '../../../../services/RolesService';
import styles from './DivisionTeamsFormList.module.scss';

/**
 * Returns the division teams form list component.
 * @param props the props
 * @returns the division teams form list component
 */
const DivisionTeamsFormList: React.FC<DivisionTeamsFormListProps> = (props) => {
    const { form, sportId, organizationId, minimum } = props;

    /*** HOOKS ***/
    const intl = useIntl();
    const context = useContext(CustomContext);
    const { auth } = context;
    const [divisions, setDivisions] = useState<Division[]>([]);
    const [teams, setTeams] = useState<Team[]>([]);

    // init
    useEffect(() => {
        const init = async () => {
            try {
                if (minimum > 0) {
                    form.setFieldsValue({ divisions: Array(minimum).fill({}) });
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [form, intl, minimum]);

    // load divisions
    useEffect(() => {
        const loadDivisions = async () => {
            try {
                form.setFieldsValue({ divisions: Array(minimum).fill({}) });
                const divisions = await divisionApi.list(sportId);
                setDivisions(divisions);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        loadDivisions();
    }, [form, intl, minimum, sportId]);

    // load teams
    useEffect(() => {
        const loadTeams = async () => {
            try {
                const teams = await teamApi.listByOrganization(organizationId);
                setTeams(teams);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        loadTeams();
    }, [intl, organizationId]);

    /*** COMPONENTS */

    const divisionOptions = divisions
        .filter(
            (d) =>
                (auth?.authorities.includes('ROLE_ORGANIZATION_COACH') &&
                    auth.coaches?.find(Boolean)?.teams?.find((t) => t.divisionId === d.id)) ||
                rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_ORGANIZATION_OWNER', 'ROLE_ORGANIZATION_STAFF']),
        )
        .map((division) => (
            <Select.Option key={division.id} value={division.id!}>
                {division.name}
            </Select.Option>
        ));

    return (
        <Form.List name="divisions">
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, ...restField }, index, array) => {
                        return (
                            <Row gutter={[24, 24]} key={name}>
                                <Col span={10}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'divisionId']}
                                        label={<FormattedMessage id="notifications.division" />}
                                        rules={[
                                            {
                                                required: true,
                                                message: <FormattedMessage id="status.mandatory" />,
                                            },
                                            ({ getFieldValue, getFieldsValue }) => ({
                                                validator(_, value) {
                                                    try {
                                                        const divisions = getFieldsValue().divisions.filter(
                                                            (d: any) => d.divisionId === value,
                                                        );
                                                        if (divisions.length > 1) {
                                                            return Promise.reject(
                                                                <FormattedMessage id="coachNotifications.divisionDuplicated" />,
                                                            );
                                                        }
                                                    } catch (error) {
                                                        // empty error handling
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Select
                                            placeholder={intl.formatMessage({
                                                id: 'coachNotifications.selectDivision',
                                            })}
                                            onChange={() => form.resetFields([['divisions', index, 'teamIds']])}
                                        >
                                            {divisionOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item shouldUpdate noStyle>
                                        {() => {
                                            return (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'teamIds']}
                                                    label={<FormattedMessage id="notifications.teams" />}
                                                    rules={
                                                        rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_COACH'])
                                                            ? [
                                                                  {
                                                                      required: true,
                                                                      message: (
                                                                          <FormattedMessage id="status.mandatory" />
                                                                      ),
                                                                  },
                                                              ]
                                                            : []
                                                    }
                                                >
                                                    <Select
                                                        placeholder={
                                                            rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_COACH'])
                                                                ? intl.formatMessage({
                                                                      id: 'notifications.selectTeam',
                                                                  })
                                                                : intl.formatMessage({
                                                                      id: 'notifications.all',
                                                                  })
                                                        }
                                                        mode="multiple"
                                                        className={
                                                            rolesService.hasAnyRole(auth, ['ROLE_ORGANIZATION_COACH'])
                                                                ? styles.multioption
                                                                : `placeholder-select ${styles.multioption}`
                                                        }
                                                        showSearch={false}
                                                    >
                                                        {renderTeamOptions(
                                                            teams,
                                                            form.getFieldValue(['divisions', index, 'divisionId']),
                                                            sportId,
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            );
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Button
                                        icon={<DeleteSvg />}
                                        size="small"
                                        type="text"
                                        onClick={() => remove(name)}
                                        className={styles.remove}
                                        hidden={fields.length === minimum}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                    <Form.Item>
                        <Button
                            type="dashed"
                            size="large"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            className={styles.add}
                        >
                            <FormattedMessage id="coachNotifications.addDivision" tagName="span" />
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );
};
export default DivisionTeamsFormList;

interface DivisionTeamsFormListProps {
    form: FormInstance;
    sportId: number;
    organizationId: string;
    minimum: number;
}

/**
 * Returns the team options.
 * @param teams the teams
 * @param divisionId the division id
 * @returns  the team options
 */
const renderTeamOptions = (teams: Team[], divisionId: number, sportId: number): JSX.Element[] => {
    return teams
        .filter((t) => t.divisionId === divisionId)
        .filter((t) => t.sportId === sportId)
        .map((team) => (
            <Select.Option key={team.id} value={team.id as string}>
                {team.name}
            </Select.Option>
        ));
};
