import { EllipsisOutlined } from '@ant-design/icons';
import {
    Avatar,
    Button,
    Card,
    Col,
    Collapse,
    Divider,
    Dropdown,
    Empty,
    Form,
    FormInstance,
    Input,
    Menu,
    message,
    Modal,
    Rate,
    Row,
    Select,
    Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { Component } from 'react';
import { FormattedDate, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import prospectsApi from '../../../apis/ProspectsApi';
import sportMetricApi from '../../../apis/SportMetricApi';
import { Media, Prospect, ProspectPlayerGame, Season, SportMetric } from '../../../model/Entities';
import avatar from '../../../resources/images/profile-placeholder.png';
import metricService from '../../../services/MetricService';
import notificationService from '../../../services/NotificationService';
import numericService from '../../../services/NumericService';
import positionService from '../../../services/PositionService';
import MediaPlayerComponent from '../../PlayerMediaPage/MediaPlayerComponent/MediaPlayerComponent';
import styles from './ProspectComponent.module.scss';

const { Panel } = Collapse;

class ProspectComponent extends Component<Props, State> {
    formRef = React.createRef<FormInstance>();

    constructor(props: Props) {
        super(props);
        this.state = { sportMetrics: [] };
    }

    componentDidMount() {
        this.init();
    }

    /** METHODS **/
    init = async () => {
        const { season } = this.props;

        try {
            this.setState({ loading: 'initializing' });

            const prospect = await prospectsApi.get(this.props.prospectId, season.id!);
            const sportCategories = await sportMetricApi.list(prospect.playerSport?.sportId!);
            const sportMetrics = sportCategories.flatMap((sc) => sc.metrics!);
            // The sport metrics that this prospect has
            const sportMetricsFiltered = sportMetrics.filter(
                (sm) => prospect.playerSport?.records?.find((r) => r.metricId === sm.id)?.video?.id !== undefined,
            );
            const metricId = prospect?.playerSport?.records?.find(Boolean)?.metricId;

            this.formRef.current?.setFieldsValue(prospect);

            this.setState({ prospect, sportMetrics: sportMetricsFiltered, metricId });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    stopTracking = async (prospect: Prospect) => {
        try {
            this.setState({ loading: 'deleting' });

            await prospectsApi.delete(prospect.userId!, prospect.playerSport?.sportId!);
            message.success(this.props.intl.formatMessage({ id: 'status.action' }));

            this.showDeleteProspectModal(false);

            this.props.onUpdate();
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    rate = async (rate: number) => {
        const { season } = this.props;
        const { prospect } = this.state;

        try {
            if (rate > 0) {
                await prospectsApi.update(prospect?.userId!, prospect?.playerSport?.sportId!, rate, prospect?.note);
                const prospectAux = await prospectsApi.get(prospect?.id!, season.id!);
                this.setState({ prospect: prospectAux });
            }
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        }
    };

    addNote = async (values: any) => {
        const { season } = this.props;
        const { prospect } = this.state;

        try {
            this.setState({ loading: 'addingNote' });

            await prospectsApi.update(prospect?.userId!, prospect?.playerSport?.sportId!, prospect?.rate, values.note);

            const prospectAux = await prospectsApi.get(prospect?.id!, season.id!);

            this.setState({ prospect: prospectAux });
        } catch (error) {
            notificationService.displayError(error, this.props.intl);
        } finally {
            this.setState({ loading: undefined });
        }
    };

    changeMetric = (metricId: number) => {
        this.setState({ metricId });
    };

    showDeleteProspectModal = (deleteProspectModalVisible: boolean) => {
        this.setState({ deleteProspectModalVisible });
    };

    showMediaPlayerModal = (mediaPlayerModalVisible: boolean, media?: Media) => {
        this.setState({ mediaPlayerModalVisible, media });
    };

    /*** COMPONENTS ***/
    renderDeleteParentModal = (): React.ReactElement | undefined => {
        const { deleteProspectModalVisible, loading, prospect } = this.state;
        return (
            <Modal
                title={<FormattedMessage id="prospects.stopTracking" />}
                visible={deleteProspectModalVisible}
                okText={<FormattedMessage id="button.confirm" tagName="span" />}
                onOk={() => this.stopTracking(prospect!)}
                okButtonProps={{ loading: loading === 'deleting' }}
                onCancel={() => this.showDeleteProspectModal(false)}
                className={styles.modal}
            >
                <FormattedMessage id="prospects.modal.description" />
            </Modal>
        );
    };

    renderTable = (): React.ReactElement | undefined => {
        const { intl } = this.props;
        const { loading, prospect } = this.state;
        let items = prospect && prospect.playerSport?.games ? prospect.playerSport?.games : [];
        items = items.sort((a, b) => (b.date || 0) - (a.date || 0)).slice(0, 3);

        const columns: ColumnsType<ProspectPlayerGame> = [
            {
                title: <FormattedMessage id="gameStatistics.date" />,
                dataIndex: 'date',
                key: 'date',
                width: 140,
                render: (value: number) => <FormattedDate value={value} day="2-digit" month="2-digit" year="2-digit" />,
            },
            {
                title: <FormattedMessage id="gameStatistics.opponent" />,
                dataIndex: 'opponent',
                key: 'opponent',
                render: (value: string) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.ab" />,
                dataIndex: 'atBats',
                key: 'atBats',
                align: 'right',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.r" />,
                dataIndex: 'run',
                key: 'run',
                align: 'right',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.h" />,
                dataIndex: 'hits',
                key: 'hits',
                align: 'right',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.rbi" />,
                dataIndex: 'runsBattedIn',
                key: 'runsBattedIn',
                align: 'right',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.bb" />,
                dataIndex: 'baseOnBalls',
                key: 'baseOnBalls',
                align: 'right',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.k" />,
                dataIndex: 'strikeouts',
                key: 'strikeouts',
                align: 'right',
                render: (value: number) => value,
            },
            {
                title: <FormattedMessage id="gameStatistics.avg" />,
                dataIndex: 'battingAverage',
                key: 'battingAverage',
                align: 'right',
                render: (value: number) => {
                    const formattedNumber = value
                        ? intl.formatNumber(value, { maximumFractionDigits: 3, minimumFractionDigits: 3 })
                        : undefined;
                    return numericService.zeroRemove(formattedNumber);
                },
            },
            {
                title: <FormattedMessage id="gameStatistics.obp" />,
                dataIndex: 'onBasePercentage',
                key: 'onBasePercentage',
                align: 'right',
                render: (value: number) => {
                    const formattedNumber = value
                        ? intl.formatNumber(value, { maximumFractionDigits: 3, minimumFractionDigits: 3 })
                        : undefined;
                    return numericService.zeroRemove(formattedNumber);
                },
            },
            {
                title: <FormattedMessage id="gameStatistics.slg" />,
                dataIndex: 'slugging',
                key: 'slugging',
                align: 'right',
                render: (value: number) => {
                    const formattedNumber = value
                        ? intl.formatNumber(value, { maximumFractionDigits: 3, minimumFractionDigits: 3 })
                        : undefined;
                    return numericService.zeroRemove(formattedNumber);
                },
            },
        ];

        return (
            <div>
                <Table
                    dataSource={items}
                    columns={columns}
                    pagination={false}
                    rowKey="id"
                    loading={loading === 'initializing'}
                    locale={{
                        emptyText: (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={<FormattedMessage id="prospects.games.empty" />}
                            />
                        ),
                    }}
                />
            </div>
        );
    };

    renderContent = (): React.ReactElement | undefined => {
        const { season } = this.props;
        const { prospect, loading, mediaPlayerModalVisible, media, sportMetrics, metricId } = this.state;
        let photo = undefined;

        if (prospect) {
            photo = prospect.playerSport?.photoUrl || avatar;
        }

        const sportMetricOptions = sportMetrics.map((sportMetric) => (
            <Select.Option key={sportMetric.id} value={sportMetric.id!}>
                {sportMetric.name}
            </Select.Option>
        ));

        return (
            <>
                <Form ref={this.formRef} onFinish={this.addNote} layout="vertical" className={styles.prospects}>
                    <Collapse defaultActiveKey={['0']} className={styles.collapse}>
                        <Panel
                            className={styles.panel}
                            header={
                                <>
                                    <Avatar className={styles.avatar} size={44} src={photo} />
                                    <div className={styles.data}>
                                        <h3>
                                            <Link
                                                to={`/players/${prospect?.userId}?sportId=${prospect?.playerSport?.sportId}`}
                                            >
                                                {prospect?.playerProfile?.givenName}{' '}
                                                {prospect?.playerProfile?.familyName}
                                                {prospect && season && ` - ${season.name}`}
                                            </Link>
                                            {prospect && season && (
                                                <Link
                                                    to={`/players/${prospect?.userId}?sportId=${prospect?.playerSport?.sportId}`}
                                                    className={styles.link}
                                                >
                                                    <FormattedMessage id="prospects.prospect.link" />
                                                </Link>
                                            )}
                                        </h3>
                                        <p>
                                            <span>
                                                {positionService.getPositionCodesBySportFiltered(
                                                    prospect?.playerSport?.sportId!,
                                                    prospect?.playerSport?.positions!,
                                                )}
                                            </span>
                                            <span>{prospect?.playerProfile?.gradYear}</span>{' '}
                                            <span>
                                                {metricService.toFormattedFeets(prospect?.playerProfile?.height)}
                                            </span>
                                            <span>
                                                {prospect?.playerProfile?.weight !== undefined && (
                                                    <span>
                                                        {prospect.playerProfile?.weight}{' '}
                                                        <FormattedMessage id="prospects.lbs" />
                                                    </span>
                                                )}
                                            </span>
                                        </p>
                                    </div>

                                    {prospect?.playerSport?.commits && [
                                        <p className={styles.committed}>
                                            <FormattedMessage id="prospects.commitedTo" />{' '}
                                            {prospect?.playerSport?.commits}
                                        </p>,
                                    ]}
                                </>
                            }
                            extra={
                                <div
                                    className={styles.extra}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <Rate
                                        className={styles.rate}
                                        value={prospect?.rate}
                                        onChange={(value: number) => this.rate(value)}
                                    />
                                    <Dropdown
                                        overlay={
                                            <Menu>
                                                <Menu.Item
                                                    key="stopTracking"
                                                    onClick={() => this.showDeleteProspectModal(true)}
                                                >
                                                    <FormattedMessage id="prospects.stopTracking" />
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    >
                                        <Button type="text" className={styles.actions}>
                                            <EllipsisOutlined />
                                        </Button>
                                    </Dropdown>
                                </div>
                            }
                            key="1"
                        >
                            <div className={styles.details}>
                                <Row gutter={[28, 0]}>
                                    <Col md={16}>
                                        <div className={styles.stats}>
                                            {prospect?.organizations?.find(Boolean)?.name && (
                                                <p>
                                                    <label>
                                                        <FormattedMessage id="prospects.club" />
                                                    </label>{' '}
                                                    {prospect.organizations?.find(Boolean)?.name}
                                                </p>
                                            )}
                                            {prospect?.playerProfile?.coreGpa !== undefined && (
                                                <p>
                                                    <label>
                                                        <FormattedMessage id="prospects.gpa" />
                                                    </label>{' '}
                                                    {prospect.playerProfile?.coreGpa}
                                                </p>
                                            )}
                                            {prospect?.playerProfile?.school && (
                                                <p>
                                                    <label>
                                                        <FormattedMessage id="prospects.hs" />
                                                    </label>{' '}
                                                    {prospect.playerProfile?.school}
                                                </p>
                                            )}
                                        </div>
                                        <Divider className={styles.hr} />
                                        {prospect?.playerSport?.top100Rank !== undefined && (
                                            <p className={styles.rank}>
                                                <strong>{prospect.playerSport?.top100Rank}</strong>
                                                <span>
                                                    <FormattedMessage id="prospects.top100Rank" />
                                                </span>
                                            </p>
                                        )}
                                        {prospect?.playerSport?.sportId !== 15 && this.renderTable()}
                                        <Divider />

                                        <Row gutter={[28, 0]}>
                                            <Col md={24}>
                                                <div className={styles.notes}>
                                                    <Form.Item name="note">
                                                        <Input
                                                            className={styles.note}
                                                            placeholder={this.props.intl.formatMessage({
                                                                id: 'prospects.addNote',
                                                            })}
                                                        />
                                                    </Form.Item>
                                                    <Button
                                                        type="text"
                                                        className={styles.btn}
                                                        htmlType="submit"
                                                        loading={loading === 'addingNote'}
                                                    >
                                                        <FormattedMessage id="button.add" tagName="span" />
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={8}>
                                        <Card
                                            hidden={sportMetrics.length === 0}
                                            className={`${styles.card} ${styles.cardVideo}`}
                                            onClick={() =>
                                                this.showMediaPlayerModal(
                                                    true,
                                                    prospect?.playerSport?.records?.find((r) => r.metricId === metricId)
                                                        ?.video,
                                                )
                                            }
                                            cover={
                                                <div className={styles.image}>
                                                    <ReactPlayer
                                                        controls={false}
                                                        width="10"
                                                        url={
                                                            prospect?.playerSport?.records?.find(
                                                                (r) => r.metricId === metricId,
                                                            )?.video?.url
                                                        }
                                                    />
                                                    <div className={styles.gradient}></div>
                                                </div>
                                            }
                                        >
                                            <Card.Meta
                                                className={styles.meta}
                                                title={
                                                    <Select
                                                        className={styles.select}
                                                        onChange={(value: number) => this.changeMetric(value)}
                                                        value={metricId}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        {sportMetricOptions}
                                                    </Select>
                                                }
                                            />
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    </Collapse>
                </Form>

                {this.renderDeleteParentModal()}

                {mediaPlayerModalVisible && media && (
                    <MediaPlayerComponent onCancel={() => this.showMediaPlayerModal(false)} medias={[media]} />
                )}
            </>
        );
    };

    render() {
        return this.renderContent();
    }
}
export default injectIntl(ProspectComponent);

interface Props extends WrappedComponentProps {
    prospectId: number;
    season: Season;
    onUpdate: () => void;
}

interface State {
    loading?: 'initializing' | 'deleting' | 'addingNote';
    prospect?: Prospect;
    deleteProspectModalVisible?: boolean;
    mediaPlayerModalVisible?: boolean;
    media?: Media;
    sportMetrics: SportMetric[];
    metricId?: number;
}
