// import authApi from '../api/AuthApi';
import Auth from '@aws-amplify/auth';
import userApi from '../apis/UserApi';
import { CognitoUser, CustomAuth } from '../model/Entities';
import { CognitoUser as AwsCognitoUser } from 'amazon-cognito-identity-js';
import { AuthorityType } from '../model/Types';
import familyApi from '../apis/FamilyApi';

class AuthService {
    auth: CustomAuth | undefined;
    readonly authority = 'authority';

    get = async (): Promise<CustomAuth | undefined> => {
        try {
            const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
            if (user) {
                const currentUser = await userApi.getFromCognito();
                this.auth = await this.createAuth(currentUser);
            }
        } catch {
            this.auth = undefined;
        }

        return this.auth;
    };

    signIn = async (username: string, password: string): Promise<CustomAuth | any> => {
        const formattedUsername = username ? username.trim().toLowerCase() : username;

        const cognitoUser = await Auth.signIn(formattedUsername, password);
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
            return cognitoUser;
        } else {
            const currentUser = await userApi.getFromCognito();
            this.auth = await this.createAuth(currentUser);
            return this.auth;
        }
    };

    signOut = async () => {
        await Auth.signOut();
        this.auth = undefined;
    };

    completeNewPassword = async (awsCognitoAuth: AwsCognitoUser, password: string): Promise<CustomAuth> => {
        await Auth.completeNewPassword(awsCognitoAuth, password);
        const currentUser = await userApi.getFromCognito();
        this.auth = await this.createAuth(currentUser);
        return this.auth;
    };

    forgotPassword = async (username: string) => {
        const formattedUsername = username ? username.trim().toLowerCase() : username;

        await Auth.forgotPassword(formattedUsername);
    };

    resetPassword = async (username: string, code: string, password: string) => {
        const formattedUsername = username ? username.trim().toLowerCase() : username;

        await Auth.forgotPasswordSubmit(formattedUsername, code, password);
        return await this.signIn(formattedUsername, password); // TODO: to be removed
    };

    getAccessToken = async () => {
        const session = await Auth.currentSession();
        return session.getAccessToken().getJwtToken();
    };

    /**
     * Returns the auth from the cognito user.
     * @param user the cognito user
     * @returns the auth
     */
    private async createAuth(user: CognitoUser): Promise<CustomAuth> {
        const authorities = await this.createAuthorities(user);
        const customAuth: CustomAuth = {
            id: user.id!,
            email: user.profile?.email!,
            firstName: user.profile?.givenName,
            lastName: user.profile?.familyName,
            authorities,
        };
        if (authorities.includes('ROLE_PLAYER')) {
            const sportId = user.userSports.find((us) => us.primarySport)?.sportId || user.userSports[0].sportId;
            customAuth.sportId = sportId;
            customAuth.familyId = user.familyId;
            customAuth.organizationIds = user.organizationTeamsMemberships
                ? user.organizationTeamsMemberships.filter((t) => !!t.organizationId).map((t) => t.organizationId!)
                : [];

            if (user.profile.viewCollegeConnect) {
                customAuth.collegeConnectEnabled = 'view';
            }
        }
        if (authorities.includes('ROLE_PARENT')) {
            customAuth.familyId = user.familyId;
            if (user.profile.viewCollegeConnect) {
                customAuth.collegeConnectEnabled = 'view';
            }
        }
        if (authorities.includes('ROLE_COLLEGE_COACH')) {
            customAuth.colleges = user.colleges;
        }
        if (authorities.includes('ROLE_ORGANIZATION')) {
            customAuth.organizationId = user.userOrganization?.organizationId;

            if (user.userOrganization?.collegeConnectEnabled) {
                customAuth.collegeConnectEnabled = 'edit';
            }

            if (authorities.includes('ROLE_ORGANIZATION_COACH')) {
                customAuth.coaches = user.coaches;
            }
        }

        return customAuth;
    }

    private async createAuthorities(user: CognitoUser): Promise<AuthorityType[]> {
        const authorities: AuthorityType[] = [];
        if (
            user.roles.map((r) => r.role).includes('Top100Admin') ||
            user.roles.map((r) => r.role).includes('Top100Staff')
        ) {
            authorities.push('ROLE_ADMIN');
        }
        if (user.roles.map((r) => r.role).includes('OrganizationOwner')) {
            authorities.push('ROLE_ORGANIZATION');
            authorities.push('ROLE_ORGANIZATION_OWNER');
        }
        if (user.roles.map((r) => r.role).includes('OrganizationStaff')) {
            authorities.push('ROLE_ORGANIZATION');
            authorities.push('ROLE_ORGANIZATION_STAFF');
        }
        if (user.roles.map((r) => r.role).includes('TeamCoach')) {
            authorities.push('ROLE_ORGANIZATION');
            authorities.push('ROLE_ORGANIZATION_COACH');
        }
        if (user.roles.map((r) => r.role).includes('Player')) {
            authorities.push('ROLE_PLAYER');
        }
        if (user.roles.map((r) => r.role).includes('Parent')) {
            authorities.push('ROLE_PARENT');
            if (await this.isParentOwner(user)) {
                authorities.push('ROLE_PARENT_OWNER');
            }
        }
        if (user.roles.map((r) => r.role).includes('CollegeCoach')) {
            authorities.push('ROLE_COLLEGE_COACH');
        }

        return authorities;
    }

    /**
     * Returns if the user is a parent owner.
     * @param user - the user
     * @returns if the user is a parent owner
     */
    private async isParentOwner(user: CognitoUser): Promise<boolean> {
        let isParentOwner = false;
        if (user.familyId) {
            const family = await familyApi.get(user.familyId);
            if (family.members?.some((m) => m.email === user.profile.email && m.memberType === 'Owner')) {
                isParentOwner = true;
            }
        }
        return isParentOwner;
    }
}

const authService: AuthService = new AuthService();
export default authService;
